const betStateByCode = (code) => {
    const codes = {
        '0': 'Old iM-DT',
        '1': 'New',
        '2': 'Won',
        '3': 'Lost',
        '5': 'CashOut',
        '6': 'CashBack',
        '7': 'ExpressBonus',
        '10': 'BoreDrawMoneyBack',
        '11': 'UltraCashBack',
        '12': 'MultyBetOfTheDay'
    }
    return codes[code ? code.toString() : '0']
}


export {
    betStateByCode
}
