import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import moment from 'moment';
import ReactJson from 'react-json-view'
import ResponsiveTable from '../Tables/ResponsiveTable';
import { getLogs } from '../../helpers/adminHelpers';
import CopyToClipboard from 'react-copy-to-clipboard';
import ThemeButton from '../../layout/components/ThemeButton'
import { Pagination, Toolbar, Tooltip } from '@mui/material';
import _ from 'lodash';
import ThemeInput from '../../layout/components/ThemeInput';
import ThemeSelect from '../../layout/components/ThemeSelect';
import ThemeDatePicker from '../../layout/components/ThemeDatePicker';
import { BreadCrumbContext, Context } from '../../context/store';
import ThemeFormatTime from '../../layout/components/ThemeFormatTime';
import { tblFormatId, tblFormatTime, tblFormatUserId } from '../../helpers/formatHelpers';
import { useParams } from 'react-router-dom';

const getPreviousMonth = () => {
    let date = new Date();
    let from = new Date(date.getFullYear(), date.getMonth(), 1);
    return from
}
const getLastDayOfCurrentMonth = () => {
    let date = new Date();
    let to = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    return to
}

const formatDetails = (all) => {
    const { meta, req } = all;
    if (_.has(req, 'body.RoundId')) {
        return <span>{req.body.RoundId}</span>
    }
    return <span>
        -

    </span>
}

const formatLog = (value) => {
    // JSON.stringify(value)
    return <ReactJson name={null} style={{ padding: '0.5rem' }} theme="monokai" src={value} />
}

const formatServer = (all) => {
    const { server, env } = all;
    if (!server) return <>!</>
    return <span className={`text-${(server.indexOf('local') > -1 || server.indexOf('Local') > -1 ? 'white' : env == 'stage' ? 'warning' : env == 'prod' ? 'success' : 'white')} pe-2`}>
        {server} <strong>{env}</strong>
    </span>
}

const formatReqResMeta = (value) => {
    return <MDBRow className='small'>
        <MDBCol className='small' size='6'>
            <div>
                <h6 className='text-info d-inline-block me-3'>Request
                </h6>
                <CopyToClipboard text={JSON.stringify(value.req) || '{}'}>
                    <ThemeButton size='small' variant='outlined' className='rounded-pill btn-sm' color='info'>Copy to Clipboard</ThemeButton>
                </CopyToClipboard>
            </div>
            <div className='border border-info'>
                {formatLog(value.req)}
            </div>
        </MDBCol>
        <MDBCol className='small' size='6'>
            <div>
                <h6 className='text-success d-inline-block me-3'>Response
                </h6>
                <CopyToClipboard text={JSON.stringify(value.res) || '{}'}>
                    <ThemeButton size='small' variant='outlined' className='rounded-pill btn-sm' color='success'>Copy to Clipboard</ThemeButton>
                </CopyToClipboard>
            </div>

            <div className='border border-success'>
                {formatLog(value.res)}
            </div>
        </MDBCol>
        <MDBCol className='small mt-3' size='12'>
            <div>
                <h6 className='text-warning d-inline-block me-3'>Meta
                </h6>
                <CopyToClipboard text={JSON.stringify(value.meta) || '{}'}>
                    <ThemeButton size='small' variant='outlined' className='rounded-pill btn-sm' color='warning'>Copy to Clipboard</ThemeButton>
                </CopyToClipboard>
            </div>
            <div className='border border-warning'>
                {formatLog(value.meta)}
            </div>
        </MDBCol>
    </MDBRow >
}

const getColumns = (serverName) => {
    serverName = serverName ? serverName.toLowerCase() : '';
    let columns = [
        tblFormatId(),
        tblFormatTime(),
        tblFormatUserId(),
        {
            id: 'severity',
            label: 'Severity',
            align: 'center',
            style: { width: '3rem', padding: 0 },
            labelStyle: { width: '3rem', padding: 0 },
            format: (severity) => {
                severity = severity ? severity.toLowerCase() : '-';
                return <span className={`text-${(severity == 'error') ? 'danger' : severity == 'alert' ? 'error' : severity}`}>
                    <MDBIcon icon={'circle'}></MDBIcon></span>
            }
        }, {
            id: 'all',
            label: 'Server',
            align: 'left',
            labelAlign: 'left',
            style: { width: '9rem' },
            labelStyle: { width: '9rem' },
            format: (all) => formatServer(all)
        }, {
            id: 'title',
            label: 'Title',
            align: 'left',
            labelAlign: 'left',
            style: { minWidth: '10rem', whiteSpace: 'normal' },
            labelStyle: { width: '10rem' },
            format: (message) => <span className='text-white d-block'>{message}</span>
        }, {
            id: 'message',
            label: 'Message',
            align: 'left',
            labelAlign: 'left',
            style: { minWidth: '50rem', whiteSpace: 'normal', wordBreak: 'break-all' },
            labelStyle: { minWidth: '50rem' },
            format: (message) => <span className='text-white d-block'>{message}</span>
        }, {
            id: 'type',
            label: 'Type',
            align: 'left',
            labelAlign: 'left',
            style: { width: '4rem' },
            labelStyle: { width: '4rem' },
            format: (type) => <span className='text-white'>{type}</span>
        }, {
            id: 'source',
            label: 'Source',
            align: 'left',
            labelAlign: 'left',
            style: { width: '10rem' },
            labelStyle: { width: '10rem' },
            format: (source) => <span className='text-white '>{source}</span>
        }, {
            id: 'all',
            label: 'Details',
            align: 'left',
            labelAlign: 'left',
            style: {
                overflow: 'hidden',
            },
            format: (all) => formatDetails(all)
        }, {
            id: 'all',
            label: 'All',
            collapse: true,
            align: 'left',
            labelAlign: 'left',
            style: {
                overflow: 'hidden',
                padding: '0 100px !important',
                whiteSpace: 'break-spaces !important'
            }, format: value => formatReqResMeta(value)
        }
    ];
    let extra = [];
    switch (serverName) {
        // EVOLUTION
        case 'imoon-api':
            extra = []
            break;
        // EVOLUTION
        case 'imoon-evolution':
            extra = [
                // UserId
                {
                    id: 'req',
                    label: 'User / Currency',
                    align: 'left',
                    style: {
                        maxWidth: '60px',
                        overflow: 'hidden',
                    },
                    format: (req) => <span className='text-white'>
                        {req && req.body && req.body.userId ? req.body.userId : ''}
                        <span className='px-1'>/</span>
                        {req && req.body && req.body.currency ? req.body.currency : ''}
                    </span>
                }]
            break;
        // DIGITAIN
        case 'imoon-digitain':
            extra = [
                // UserId
                {
                    id: 'req',
                    label: 'UserId',
                    align: 'left',
                    style: {
                        maxWidth: '60px',
                        overflow: 'hidden',
                    },
                    format: (req) => <span className='text-white'>{req ? req.userId : ''}</span>
                }]
            break;
        // EveryMatrix
        case 'imoon-digitain':
            extra = [
                // UserId
                {
                    id: 'req',
                    label: 'UserId',
                    align: 'left',
                    style: {
                        maxWidth: '60px',
                        overflow: 'hidden',
                    },
                    format: (req) => <span className='text-white'>{req ? req.userId : ''}</span>
                }]
            break;
        // CryptoPay
        case 'cryptopay-api':
            extra = []
            break;
        // Chat
        case 'imoon-chat':
            extra = []

            break;

        default:
            break;
    }
    columns.push(...extra)
    return columns;
}

const getColor = (status) => {
    status = status ? status.toLowerCase() : 'all';
    switch (status) {
        case 'alert':
            return 'error'
        case 'error':
            return 'danger'
        case 'warning':
            return 'warning'
        case 'info':
            return 'info'
        case 'success':
            return 'success'
        case 'debug':
            return 'white'
        case 'all':
            return 'white'
        default:
            return 'info'
    }
}

const ServersLogs = (props) => {
    const [state, dispatch] = useContext(Context);
    const { serverName } = useParams();
    const [contextBC, setContextBC] = useContext(BreadCrumbContext);
    useLayoutEffect(() => {
        setContextBC([
            {
                href: '/',
                text: 'Home'
            },
            {
                href: '/Servers/List/',
                text: 'Servers'
            },
            {
                text: serverName
            }
        ])
    }, [])
    const [selectedSeverities, setSelectedSeverities] = useState(['alert', 'error', 'info']);
    const [values, setValues] = useState({
        fromDate: getPreviousMonth(),
        toDate: getLastDayOfCurrentMonth(),
        server: '',
        title: '',
        message: '',
        type: '',
        userId: '',
        source: ''

    })
    const [rows, setRows] = useState([]);
    const interval = useRef(null);
    const [updated, setUpdated] = useState(Date.now());
    const [waiting, setWaiting] = useState(false)
    const [filter, setFilter] = useState({
        find: {},
        limit: 20,
        // sort: { updatedAt: -1 },
        skip: 0
    })
    const [stream, setStream] = useState(true);
    useEffect(() => {
        getLogs(state, { setRows, filter, serverName }, setWaiting);
        interval.current = setInterval(() => {
            getLogs(state, { setRows, filter, serverName }, setWaiting);
            setUpdated(Date.now())
        }, 3000);
        return () => {
            clearInterval(interval.current)
        }
    }, [filter])

    const startLogs = (f) => {
        clearInterval(interval.current);
        getLogs(state, { setRows, filter: f, serverName }, setWaiting);
        interval.current = setInterval(() => {
            getLogs(state, { setRows, filter: f, serverName }, setWaiting);
            setUpdated(Date.now())
        }, 3000);
    }

    useEffect(() => {
        clearInterval(interval.current);
        if (stream) {
            startLogs(filter)
        }
    }, [stream])


    const handleSetFilter = () => {
        let findQuery = { ...filter.find, $and: [] };
        if (selectedSeverities.length == 0) {
            findQuery.severity = { $exist: 1 }
        } else {
            findQuery.severity = { $in: selectedSeverities }
        }

        // From Date
        if (values.fromDate) {
            findQuery.$and.push({
                'createdAt': { $gt: values.fromDate }
            })
        }
        // To Date
        if (values.toDate) {
            findQuery.$and.push({
                'createdAt': { $lt: values.toDate }
            })
        }
        // Title
        if (values.title && (values.title.trim() != '')) {
            findQuery.title = { $regex: values.title, $options: "i" }
        } else {
            findQuery = _.omit(findQuery, ['title']);
        }
        // Server
        if (values.server && (values.server.trim() != '')) {
            findQuery.server = { $regex: values.server, $options: "i" }
        } else {
            findQuery = _.omit(findQuery, ['server']);
        }
        // Message
        if (values.message && (values.message.trim() != '')) {
            findQuery.message = { $regex: values.message, $options: "i" }
        } else {
            findQuery = _.omit(findQuery, ['message']);
        }
        // Type
        if (values.type && (values.type.trim() != '')) {
            findQuery.type = { $regex: values.type, $options: "i" }
        } else {
            findQuery = _.omit(findQuery, ['type']);
        }
        // Source
        if (values.source && (values.source.trim() != '')) {
            findQuery.source = { $regex: values.source, $options: "i" }
        } else {
            findQuery = _.omit(findQuery, ['source']);
        }
        // UserId
        if (values.userId && (values.userId.trim() != '')) {
            findQuery.userId = { $regex: values.userId, $options: "i" }
        } else {
            findQuery = _.omit(findQuery, ['userId']);
        }


        findQuery = _.omit(findQuery, [
            'fromDate', 'toDate',
        ]);
        // findQuery = _.omit(findQuery, ['usernameEmail', 'hasBalance', 'walletAddress', 'casino', 'crash', 'sport', 'poker', 'active', 'deposit', 'withdraw', 'topup', 'email', 'mobile', 'telegram']);
        if (findQuery.$and.length == 0) findQuery = _.omit(findQuery, ['$and']);
        setFilter({
            find: findQuery,
            sort: filter.sort,
            limit: filter.limit,
            skip: 0
        })
    }


    const handlePagination = (e, page) => {
        setFilter({
            ...filter,
            skip: (page - 1) * filter.limit
        });
    }


    useEffect(() => {
        handleSetFilter()
    }, [selectedSeverities])

    return <>
        {!serverName ?
            <>

            </> :
            <>
                <Toolbar />
                <MDBRow className='m-0'>
                    <MDBCol size='12' lg='3' className='pb-2 px-1 text-white align-items-center d-flex'>
                        <strong className='text-primary me-2'>{serverName}</strong>
                        <div className="d-inline-block">
                            <MDBIcon className='cursor-pointer ms-2' onClick={() => setStream(!stream)} icon={stream ? 'pause' : 'play'}></MDBIcon>
                        </div>
                        <div className='overflow-hidden d-inline-flex' >
                            <div className="heart-rate overflow-hidden">
                                <svg className='d-inline-block mt-n2' version="1.0" x="0px" y="0px" width="70px" height="25px" viewBox="0 0 150 73" enableBackground="new 0 0 150 73" >
                                    <polyline fill="none" stroke="#26ddff" strokeWidth="3" strokeMiterlimit="10" points="0,45.486 38.514,45.486 44.595,33.324 50.676,45.486 57.771,45.486 62.838,55.622 71.959,9 80.067,63.729 84.122,45.486 97.297,45.486 103.379,40.419 110.473,45.486 150,45.486"
                                    />
                                </svg>
                                {
                                    stream ? <>
                                        <div className="fade-in"></div>
                                        <div className="fade-out"></div>
                                    </> : <></>
                                }
                            </div>
                        </div>
                        <div className='d-inline-block'>
                            {
                                ['alert', 'error', 'warning', 'info', 'success', 'debug'].map((severity, i) =>
                                    <Tooltip key={i} title={severity} >
                                        <span>
                                            <MDBIcon icon='circle'
                                                className={`cursor-pointer mx-1 text-${getColor(severity)} ` +
                                                    (selectedSeverities.indexOf(severity) > -1 ? 'fw-bold' : 'fw-light')}
                                                onClick={() => {
                                                    setSelectedSeverities(_.xor(selectedSeverities, [severity]))
                                                }}
                                            />
                                        </span>
                                    </Tooltip>
                                )
                            }
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow className='m-0 pb-2'>
                    <MDBCol size='12' md='4' xl='3' className='d-flex px-1 justify-content-between'>
                        <div className='pe-1' >
                            <ThemeDatePicker label='From' value={values.fromDate} onChange={fromDate => setValues({ ...values, fromDate })}></ThemeDatePicker>
                        </div>
                        <div className='ps-1' >
                            <ThemeDatePicker className='ps-1' label='To' value={values.toDate} onChange={toDate => setValues({ ...values, toDate })}></ThemeDatePicker>
                        </div>
                    </MDBCol>
                    <MDBCol size='12' md='2' xl='1' className='px-1'>
                        <ThemeInput label='Server' type='search' value={values.server} onChange={e => setValues({ ...values, server: e.target.value })} ></ThemeInput>
                    </MDBCol>
                    <MDBCol size='12' md='2' xl='1' className='px-1'>
                        <ThemeInput label='Title' type='search' value={values.title} onChange={e => setValues({ ...values, title: e.target.value })} ></ThemeInput>
                    </MDBCol>
                    <MDBCol size='12' md='2' xl='1' className='px-1'>
                        <ThemeInput label='Message' type='search' value={values.message} onChange={e => setValues({ ...values, message: e.target.value })} ></ThemeInput>
                    </MDBCol>
                    <MDBCol size='12' md='2' xl='1' className='px-1'>
                        <ThemeInput label='Type' type='search' value={values.type} onChange={e => setValues({ ...values, type: e.target.value })} ></ThemeInput>
                    </MDBCol>
                    <MDBCol size='12' md='2' xl='1' className='px-1'>
                        <ThemeInput label='Source' type='search' value={values.source} onChange={e => setValues({ ...values, source: e.target.value })} ></ThemeInput>
                    </MDBCol>
                    <MDBCol size='12' md='2' xl='1' className='px-1'>
                        <ThemeInput label='UserId' type='search' value={values.userId} onChange={e => setValues({ ...values, userId: e.target.value })} ></ThemeInput>
                    </MDBCol>
                    <MDBCol size='12' md='2' xl='1' className='px-1'>
                        <ThemeButton variant='outlined' className='py-2 px-3 mx-2' onClick={handleSetFilter} color='neon'>Search</ThemeButton>
                    </MDBCol>
                    <MDBCol size='12' md='2' xl='1' className='px-1'>
                    </MDBCol>
                    <MDBCol size='12' md='2' xl='1' className='px-1'>
                        <ThemeSelect style={{ width: '100px' }} value={filter.limit} label='Page Size' onChange={e => setFilter({ ...filter, limit: parseInt(e.target.value) })}>
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </ThemeSelect>
                    </MDBCol>
                </MDBRow>

                <ResponsiveTable collapsible rows={rows} columns={getColumns(serverName)}></ResponsiveTable>

                {
                    Math.floor((rows.count || 10000) / filter.limit) > 0 ?
                        <MDBRow className='m-0'>
                            <MDBCol size='12' className='text-center py-4'>
                                <Pagination onChange={handlePagination} className='d-inline-block' count={Math.ceil((rows.count || 10000) / filter.limit)} variant="outlined" shape="rounded" />
                            </MDBCol>
                        </MDBRow> : <></>
                }
            </>
        }
    </>
}

export default ServersLogs
