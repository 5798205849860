import { AppBar, Checkbox, Collapse, Divider, FormControlLabel, FormGroup, Grid, Toolbar, useMediaQuery } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { beginTime, dayLong, getPlayerReportData, getTimeStamps, weekLong } from '../../../helpers/reportHelper';
import ThemeButton from '../../../layout/components/ThemeButton';
import ThemeTextField from '../../../layout/components/ThemeTextField';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Box } from '@mui/system';
import { MDBIcon } from 'mdb-react-ui-kit';

const PlayerReportFilter = (props) => {
  const { filter, setFilter, values, setValues, setPlayerReportData, noToolbar } = props;
  let navigate = useNavigate();
  const { pathname } = useLocation();

  const handleDateChange = (type, value) => {
    const isValid = moment(value).isValid();
    console.log(isValid);
    setValues({
      ...values,
      [type + 'Text']: value,
      [type + 'Valid']: isValid
    })
  }

  const search = (newValues) => {
    const newFilters = {
      userId: newValues.userId,
      fake: newValues.fake,
      from: new Date(newValues.fromText + ' UTC').getTime(),
      to: new Date(newValues.toText + ' UTC').getTime()
    };
    setFilter(newFilters)
    getPlayerReportData(setPlayerReportData, newFilters)
  }

  useEffect(() => {
    getPlayerReportData(setPlayerReportData, values)
  }, [])

  const isMobile = useMediaQuery('(max-width:600px)');

  const [open, setOpen] = useState(false)
  return <AppBar className=''>
    <Toolbar />
    {!noToolbar && <Toolbar variant='dense' />}


    <Toolbar disableGutters position='fixed' sx={{
      width: ['100vw', 'calc(100vw-3.5rem)'],
      bgcolor: '#000 !important',
      paddingLeft: ['0', noToolbar ? 0 : '3.5rem'],
      alignItems: ['start', 'end'],
      zIndex: 3,
    }}>
      <Box className='w-100' sx={{
        borderBottom: '1px solid #26ddff55'
      }}>
        {isMobile && <Box className='w-100 p-1 py-2 small d-flex align-items-center justify-content-between'>
          {<small className='border border-neon text-neon rounded-pill px-2' onClick={() => setOpen(!open)}><MDBIcon icon={`angle-${open ? 'up' : 'down'}`}></MDBIcon> Filters</small>}
          <span>User: {values.userId}</span>
        </Box>
        }
        <Collapse in={open || !isMobile} sx={{ bgcolor: '#000d' }} className='w-100' unmountOnExit>
          <form noValidate autoComplete="off" className='w-100 px-2 my-3 my-md-0' onSubmit={e => { e.preventDefault(); search(values) }}>
            <Grid container spacing={[2, 1]} columns={16} justifyContent={['space-around', 'space-between']} alignItems='center'>
              <Grid item xs={8} md={2} lg={2}>
                <ThemeTextField inputPropsClassName={values.fromValid ? '' : 'text-danger'} value={values.fromText} onChange={e => handleDateChange('from', e.target.value)} label='From (UTC)' />
              </Grid>
              <Grid item xs={8} md={2} lg={2}>
                <ThemeTextField inputPropsClassName={values.toValid ? '' : 'text-danger'} value={values.toText} onChange={e => handleDateChange('to', e.target.value)} label='To (UTC)' />
              </Grid>
              <Grid item xs={8} md={2} lg={1} >
                <ThemeTextField value={values.userId} onChange={e => setValues({ ...values, userId: e.target.value })} label='UserId' />
              </Grid>
              {/* <Grid item xs={8} md={2} lg={2}>
                <FormGroup>
                  <FormControlLabel control={<Checkbox checked={values.fake} onChange={e => setValues({ ...values, fake: e.target.checked })} color='danger' />} label="Fake TXs" />
                </FormGroup>
              </Grid> */}
              <Grid item xs={8} md={2} lg={5}>
                <ThemeButton type='submit' disabled={!values.userId || !values.fromValid || !values.toValid} onClick={() => search(values)} className='px-3 py-2' variant='outlined' color={values.fake ? 'danger' : 'success'}>Get {values.fake ? 'FAKE ' : ''}Report</ThemeButton>
              </Grid>
              <Grid item xs={16} md={2} lg={6} display='flex' justifyContent='end'>
                {/* <ThemeButton
                  onClick={() => {
                    const newValues = {
                      ...values,
                      fromText: moment.utc((new Date(values.fromText + ' UTC').getTime()) - weekLong).format('YYYY/MM/DD HH:mm:ss'),
                      toText: moment.utc((new Date(values.toText + ' UTC').getTime() - 1) - weekLong).format('YYYY/MM/DD HH:mm:ss')
                    }
                    setValues(newValues);
                    search(newValues);
                  }}
                  className='px-md-3 py-0' variant='outlined' color='white'>
                  <KeyboardDoubleArrowLeftIcon
                    fontSize='small'
                  />
                </ThemeButton> */}
                <ThemeButton
                  onClick={() => {
                    const diff = values.mode == 'week' ? weekLong : dayLong;
                    let newValues = {
                      ...values,
                      fromText: moment.utc((new Date(values.fromText + ' UTC').getTime()) - diff).format('YYYY/MM/DD HH:mm:ss'),
                      toText: moment.utc((new Date(values.toText + ' UTC').getTime()) - diff).format('YYYY/MM/DD HH:mm:ss')
                    }
                    if (values.mode == 'month') {
                      newValues = {
                        ...values,
                        fromText: moment.utc((new Date(values.fromText + ' UTC').getTime())).add(-1, 'month').startOf('month').format('YYYY/MM/DD HH:mm:ss'),
                        toText: moment.utc((new Date(values.toText + ' UTC').getTime())).add(-1, 'month').endOf('month').format('YYYY/MM/DD HH:mm:ss')
                      }
                    }
                    setValues(newValues);
                    search(newValues);
                  }}
                  className='px-md-3 py-0 mx-md-1' variant='outlined' color='white'>
                  <KeyboardArrowLeftIcon
                    fontSize='small'
                  />
                </ThemeButton>
                <ThemeButton
                  onClick={() => {
                    const diff = values.mode == 'week' ? weekLong : dayLong;
                    let newValues = {
                      ...values,
                      fromText: moment.utc((new Date(values.fromText + ' UTC').getTime()) + diff).format('YYYY/MM/DD HH:mm:ss'),
                      toText: moment.utc((new Date(values.toText + ' UTC').getTime()) + diff).format('YYYY/MM/DD HH:mm:ss')
                    }
                    if (values.mode == 'month') {
                      newValues = {
                        ...values,
                        fromText: moment.utc((new Date(values.fromText + ' UTC').getTime())).add(1, 'month').startOf('month').format('YYYY/MM/DD HH:mm:ss'),
                        toText: moment.utc((new Date(values.toText + ' UTC').getTime())).add(1, 'month').endOf('month').format('YYYY/MM/DD HH:mm:ss')
                      }
                    }
                    setValues(newValues);
                    search(newValues);
                  }}
                  className='px-md-3 py-0 mx-md-1' variant='outlined' color='white'>
                  <KeyboardArrowRightIcon
                    fontSize='small'
                  />
                </ThemeButton>
                {/* <ThemeButton
                  onClick={() => {
                    const newValues = {
                      ...values,
                      fromText: moment.utc((new Date(values.fromText + ' UTC').getTime()) + weekLong).format('YYYY/MM/DD HH:mm:ss'),
                      toText: moment.utc((new Date(values.toText + ' UTC').getTime() - 1) + weekLong).format('YYYY/MM/DD HH:mm:ss')
                    }
                    setValues(newValues);
                    search(newValues);
                  }}
                  className='px-md-3 py-0' variant='outlined' color='white'>
                  <KeyboardDoubleArrowRightIcon
                    fontSize='small'
                  />
                </ThemeButton> */}
                <Divider orientation="vertical" sx={{ height: 'auto !important' }} />
                <ThemeButton onClick={() => {
                  const newValues = {
                    ...values,
                    fromText: moment.utc(getTimeStamps().todayStart).format('YYYY/MM/DD HH:mm:ss'),
                    toText: moment.utc(getTimeStamps().todayStart + dayLong - 1).format('YYYY/MM/DD HH:mm:ss'),
                    mode: 'day',
                  }
                  setValues(newValues);
                  search(newValues);
                  navigate(pathname + `?userId=${values.userId}&mode=day`, { replace: true });

                }} className='px-md-3 py-2 mx-md-1' variant='outlined' color={values.mode == 'day' ? 'neon' : 'white'}>Day</ThemeButton>
                <ThemeButton onClick={() => {
                  const newValues = {
                    ...values,
                    fromText: moment.utc(getTimeStamps().previousMondayStart).format('YYYY/MM/DD HH:mm:ss'),
                    toText: moment.utc(getTimeStamps().previousMondayStart + weekLong - 1).format('YYYY/MM/DD HH:mm:ss'),
                    mode: 'week',
                  }
                  setValues(newValues);
                  search(newValues);
                  navigate(pathname + `?userId=${values.userId}&mode=week`, { replace: true });
                }} className='px-md-3 py-2' variant='outlined' color={values.mode == 'week' ? 'neon' : 'white'}>Week</ThemeButton>
                <ThemeButton onClick={() => {
                  const newValues = {
                    ...values,
                    fromText: moment.utc(getTimeStamps().monthStart).format('YYYY/MM/DD HH:mm:ss'),
                    toText: moment.utc(getTimeStamps().monthEnd - 1).format('YYYY/MM/DD HH:mm:ss'),
                    mode: 'month',
                  }
                  setValues(newValues);
                  search(newValues);
                  navigate(pathname + `?userId=${values.userId}&mode=month`, { replace: true });
                }} className='px-md-3 py-2 mx-md-1' variant='outlined' color={values.mode == 'month' ? 'neon' : 'white'}>Month</ThemeButton>
                <ThemeButton onClick={() => {
                  const newValues = {
                    ...values,
                    fromText: moment.utc(beginTime).format('YYYY/MM/DD HH:mm:ss'),
                    toText: moment.utc(getTimeStamps().monthEnd - 1).format('YYYY/MM/DD HH:mm:ss'),
                    mode: 'all',
                  }
                  setValues(newValues);
                  search(newValues);
                  navigate(pathname + `?userId=${values.userId}&mode=all`, { replace: true });
                }} className='px-md-3 py-2 mx-md-1' variant='outlined' color={values.mode == 'all' ? 'neon' : 'white'}>All</ThemeButton>
              </Grid>
            </Grid>
          </form>
        </Collapse>
      </Box>
    </Toolbar>
  </AppBar>
}

export default PlayerReportFilter