import React from 'react';
import ThemeSelect from '../layout/components/ThemeSelect';

const PageSort = (props) => {
    const sorts = ['updatedAt', 'order', 'createdAt', 'title', 'type'];
    return <ThemeSelect
        label='Order'
        options={sorts.map((sort, i) => <option key={i} value={sort}>{<span className={`text- p-1`}>{sort || 'All'}</span>}</option>)}
        {...props}
    />;
}

export default PageSort