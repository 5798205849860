import React from 'react';
import ThemeSelect from '../layout/components/ThemeSelect';

const PageTypes = (props) => {
    const types = ['Legal', 'Public', 'Article'];
    types.unshift('');
    return <ThemeSelect
        label='Type'
        options={types.map((type, i) => <option key={i} value={type}>{<span className={`text- p-1`}>{type || 'All'}</span>}</option>)}
        {...props}
    />;
}

export default PageTypes