import React, { useEffect, useState, useRef } from 'react';
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import moment from 'moment';
import axios from 'axios';
import ResponsiveTable from '../../Tables/ResponsiveTable';
import { Pagination } from '@mui/material';
import numeral from 'numeral';
import ReactJson from 'react-json-view';
import { currencyDetail, currencyIcon } from '../../../helpers/currency';
import CopyToClipboard from 'react-copy-to-clipboard';
import ThemeButton from '../../../layout/components/ThemeButton';
import UAParser from 'ua-parser-js';
import { countries, getBearer } from '../../../helpers/publicHelper';
import ThemeFormatTime from '../../../layout/components/ThemeFormatTime';
import UserQuickViewButton from '../UserQuickViewButton';

const formatUA = (userAgent) => {
    if (!userAgent) return <></>
    const ua = UAParser(userAgent);
    if (!ua) return <></>
    return <div>
        {ua.os && ua.os.name ? <img className='px-1' height='22' src={`/logo/${ua.os.name.toLowerCase().replace(/\s/g, "")}.svg`} /> : ''}
        {ua.device && ua.device.vendor ? <span className='px-1'>{ua.device.vendor}</span> : ''}
        {ua.device && ua.device.model ? <span className='px-1'>{ua.device.model}</span> : ''}
        {ua.device && ua.device.type ? <span>{ua.device.type}</span> : ''}
        {ua.browser && ua.browser.name ? <img className='px-1' height='22' src={`/logo/${ua.browser.name.toLowerCase().replace(/\s/g, "")}.svg`} /> : ''}
    </div>
}

const formatMeta = (meta) => {

    const { deviceInfo, mode } = meta ? meta : { deviceInfo: {}, mode: '' };
    let info = {};
    try {
        info = JSON.parse(deviceInfo);
    } catch (err) {

    }
    return <span>
        {info && info.width ? (info.width + ' x ') : ''}
        {info && info.height ? info.height : ''}
    </span>
}


const formatHeaders = (value) => {
    return <MDBRow className='small'>
        <MDBCol className='small' size='12'>
            <div>
                <h6 className='text-info d-inline-block me-3'>Meta
                </h6>
                <CopyToClipboard text={JSON.stringify(value) || '{}'}>
                    <ThemeButton size='small' variant='outlined' className='rounded-pill btn-sm' color='info'>Copy to Clipboard</ThemeButton>
                </CopyToClipboard>
            </div>
            <div className='border border-info'>
                <ReactJson name={null} style={{ padding: '0.5rem' }} theme="monokai" src={value} />
            </div>
        </MDBCol>
    </MDBRow >
}

const columns = [{
    id: '_id',
    label: '',
    style: { width: '0', padding: 0 },
    labelStyle: { width: '0', padding: 0 },
    format: () => { }
}, {
    id: 'createdAt', // From Logger
    label: 'Time',
    align: 'left',
    style: { width: '100px', padding: 0, fontSize: '0.7em' },
    format: (time) => <ThemeFormatTime timestamp={time} showFromNow></ThemeFormatTime>
}, {
    id: 'userId',
    label: 'User ID',
    align: 'center',
    format: (userId) => <UserQuickViewButton userId={userId}></UserQuickViewButton>
}, {
    id: 'type',
    label: 'Type',
    align: 'center',
    format: (type) => <span className={'text-center text-capitalize text-' + (type == 'login' ? 'success' : type == 'logout' ? 'danger' : 'warning')}>{type}</span>
}, {
    id: 'service',
    label: 'Service',
    align: 'center',
    format: (service) => <span className='text-white text-capitalize'>{service}</span>
}, {
    id: 'ip',
    label: 'Connecting IP',
    align: 'center',
    style: { width: '14rem' },
    labelStyle: { width: '14rem' },
    format: (ip) => <span className='text-white text-capitalize'>{ip}</span>
}, {
    id: 'country',
    label: 'Country',
    labelAlign: 'center',
    align: 'center',
    format: (country) => <span className='text-white text-capitalize'>
        {country && <><img
            src={`https://flagcdn.com/48x36/${country.toLowerCase()}.png`}
            height='16'
            className='me-2'
            alt={country}></img>
            <small>{countries.find(c => c.code == country) && countries.find(c => c.code == country).label}</small>
        </>
        }
    </span >
}, {
    id: 'userAgent',
    label: 'User Agent',
    align: 'center',
    format: userAgent => formatUA(userAgent)
}, {
    id: 'meta',
    label: 'Device Info',
    align: 'center',
    format: meta => formatMeta(meta)
}, {
    id: 'all',
    label: 'all',
    collapse: true,
    align: 'left',
    style: {
        overflow: 'hidden',
        padding: '0 100px !important',
        whiteSpace: 'break-spaces !important'
    }, format: value => formatHeaders(value)
}
];

const UserLogs = (props) => {
    const { _id } = props;
    const [rows, setRows] = useState([])
    const interval = useRef(null);

    const [filter, setFilter] = useState({
        find: { user: _id },
        limit: 25,
        skip: 0,
        sort: { createdAt: -1 }
    })

    const getUserLogs = (setRows, filters) => {
        const { find, skip, limit, sort } = filters;
        axios.post(`${process.env.REACT_APP_API_HOST}/admin/users/logs`,
            { find, skip, limit, sort },
            {
                headers: { 'x-auth-token': getBearer() }
            })
            .then(apiRes => {
                apiRes = apiRes.data;
                if (apiRes.success) {
                    setRows({
                        count: apiRes.result.count,
                        userLogs: apiRes.result.userLogs.map(row => ({ ...row, all: row }))
                    });
                } else {
                    setRows({
                        count: 0,
                        transactions: []
                    });
                }
            })
            .catch(err => {
                console.log(err)
            })
            .then(() => {
            })
    }

    const handlePagination = (e, page) => {
        setFilter({
            ...filter,
            skip: (page - 1) * filter.limit
        });
    }

    useEffect(() => {
        getUserLogs(setRows, filter)
        let updateInterval = setInterval(() => {
            getUserLogs(setRows, filter)
        }, 3000);
        interval.current = updateInterval;
        return () => {
            clearInterval(interval.current)
        }
    }, [filter])

    return (
        <>
            <ResponsiveTable collapsible rows={rows.userLogs} columns={columns}></ResponsiveTable>
            {
                Math.floor(rows.count / filter.limit) > 0 ?
                    <MDBRow className='m-0'>
                        <MDBCol size='12' className='text-center py-4'>
                            <Pagination onChange={handlePagination} className='d-inline-block' count={Math.ceil(rows.count / filter.limit)} variant="outlined" shape="rounded" />
                        </MDBCol>
                    </MDBRow> : <></>

            }
        </>)
}

export default UserLogs
