import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import moment, { utc } from 'moment';
import ReactJson from 'react-json-view'
import ResponsiveTable from '../Tables/ResponsiveTable';
import { getServiceLogs } from '../../helpers/adminHelpers';
import CopyToClipboard from 'react-copy-to-clipboard';
import ThemeButton from '../../layout/components/ThemeButton'
import { AppBar, Collapse, Pagination, Toolbar, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import ThemeInput from '../../layout/components/ThemeInput';
import ThemeSelect from '../../layout/components/ThemeSelect';
import ThemeDatePicker from '../../layout/components/ThemeDatePicker';
import { BreadCrumbContext, Context } from '../../context/store';
import { tblFormatId, tblFormatTime, tblFormatUserId } from '../../helpers/formatHelpers';
import { Box } from '@mui/system';
import numeral from 'numeral';
import $ from 'jquery';
import { useParams, useSearchParams } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import { blue, green, lightGreen, lime, purple, yellow } from '@mui/material/colors';

const resTableTitle = (e) => {
    const tBody = $(e.currentTarget).parents('table').find('> tbody');
    const index = $(e.currentTarget).parent().index();
    $(e.currentTarget).find('span').toggleClass('d-none')
    $(e.currentTarget).find('i').toggleClass('fa-eye fa-eye-slash text-neon')
    $(e.currentTarget).parent().toggleClass('w-2rem');
    $(tBody).find(' tr').each(function () {
        $(this).find('td').eq(index)
            .toggleClass('w-0 overflow-hidden font-0')
    })
    // console.log('x', table)
}
var MS_PER_MINUTE = 60000;

// 'live', '1min', '10min', '30min', '1hour', '1day', '1week'
const getFromTime = (qFrom) => {
    let date = new Date();
    let from = new Date();
    let durationInMinutes = 0;
    // first of current month
    if (qFrom === 'live') {
    } else if (qFrom === '1min') {
        durationInMinutes = 1;
    } else if (qFrom === '10min') {
        durationInMinutes = 10;
    } else if (qFrom === '30min') {
        durationInMinutes = 30;
    } else if (qFrom === '1hour') {
        durationInMinutes = 60;
    } else if (qFrom === '1day') {
        durationInMinutes = 60 * 24;
    } else if (qFrom === '1week') {
        durationInMinutes = 60 * 24 * 7;
    }
    from = new Date(date.getTime() - durationInMinutes * MS_PER_MINUTE);
    return from
}
const getLastDayOfCurrentMonth = () => {
    let date = new Date();
    let to = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    return to
}

const formatDetails = (all) => {
    const { meta, req, res, server, title } = all;
    if (_.has(req, 'body.RoundId')) {
        return <span>{req.body.RoundId}</span>
    }

    // iMoon Crash Bet
    if (title == 'betTimestamp') {
        let { betTimes } = meta;
        if (!betTimes) return <></>
        const newBetTimes = JSON.parse(JSON.stringify(betTimes));
        let totalTime = 0;
        for (let i = 0; i < newBetTimes.length; i++) {
            if (i > 2) {
                totalTime += newBetTimes[i].timestamp - newBetTimes[i - 1].timestamp;
            }
        }

        // newBetTimes.unshift({
        //     server: 'Client',
        //     step: 'Place Bet',
        //     timestamp: ts
        // })
        const result = <>

            <Box className={`mt-1 text-end`}>
                <div className={`d-inline px-3 py-1 rounded-3 border text-${totalTime < 1000 ? 'neon border-neon' : totalTime < 1300 ? 'success border-success' : totalTime < 1500 ? 'warning border-warning' : 'danger border-danger'}`}>{totalTime}<small className='ps-1 opacity-50'>ms</small></div>
            </Box>
            <Box sx={{ fontSize: '0.5rem' }} className='d-flex justify-content-evenly'>
                {newBetTimes.map((obj, i) => {
                    if (!i) return <></>
                    const dif = obj.timestamp - newBetTimes[i - 1].timestamp;
                    return <Box sx={{
                        // flex: '1 1 0',
                        // width: 0
                    }} className='p-1 py-md-0 text-center d-flex flex-column overflow-hidden' key={i}>
                        <div className={`text-${dif < 100 ? 'neon' : dif < 300 ? 'success' : dif < 500 ? 'warning' : 'danger'} d-flex justify-content-around align-items-end`}>{i < (newBetTimes.length) ? numeral(dif).format('0,0.[00]') : ''}<small className='ps-1 opacity-50'>ms</small></div>
                    </Box>
                })}
            </Box>
            <Box sx={{ fontSize: '0.5rem' }} className='d-flex'>
                {newBetTimes.map((obj, i) => <Box sx={{
                    flex: '1 1 0',
                    width: 0
                }} className='p-1 py-md-0 text-center d-flex flex-column overflow-hidden' key={i}>
                    <div>{obj.server}</div>
                    <div>{obj.step}</div>
                    <div>{obj.timestamp}</div>
                </Box>)}
            </Box>
        </>
        return result
    }


    return <span>-</span>
}

const formatLog = (value) => {
    // JSON.stringify(value)
    return <ReactJson name={null} style={{ padding: '0.5rem' }} theme="monokai" src={value} />
}

const formatServer = (all) => {
    const { server, env } = all;
    let textColor = '';
    if (server.includes('core')) {
        textColor = lightGreen[600]
    } else if (server.includes('game')) {
        textColor = purple[400]
    } else if (server.includes('launch')) {
        textColor = yellow[600]
    } else if (server.includes('report')) {
        textColor = blue[400]
    } else if (server.includes('core')) {
        textColor = 'success'
    } else if (server.includes('core')) {
        textColor = 'success'
    } else if (server.includes('core')) {
        textColor = 'success'
    } else if (server.includes('core')) {
        textColor = 'success'
    }

    return <Typography
        variant='subtitle2'
        sx={{
            fontSize: '12px',
            whiteSpace: 'break-spaces',
            color: textColor
        }}
        className={`pe-2`}>
        {server}
    </Typography>
}
const formatTypeTitle = (all) => {
    const { title, type } = all;
    return <Box
        sx={{
            wordBreak: 'break-all',
            whiteSpace: 'break-spaces'
        }}
        className={`pe-2`}>
        <Typography variant='subtitle1'>{type}</Typography>
        <Typography variant='subtitle2'>{title}</Typography>
    </Box>
}
const formatMessage = (all) => {
    const { message, source } = all;
    return <Box
        sx={{
            whiteSpace: 'break-spaces'
        }}
        className={`pe-2`}>
        <Typography variant='subtitle2'>{message}</Typography>
        <Typography variant='subtitle2' sx={{ opacity: 0.4, fontSize: '12px' }}>{source}</Typography>
        {/* <Typography variant='subtitle2'>{title}</Typography> */}
    </Box>
}
const formatQuery = (all) => {
    let { query } = all;

    // query = [
    //     { name: 'data', url: 'https://www.google.com' },
    //     { name: 'data2', url: 'https://www.google.com' },
    //     { name: 'data3', url: 'https://www.google.com' },
    // ]

    if (!query?.length) return <></>
    return <Box
        sx={{
            whiteSpace: 'break-spaces'
        }}
        className={`pe-2`}>
        {query.map((q, i) =>
            <Typography
                key={i}
                variant='subtitle2' sx={{
                    opacity: query ? 1 : 0.1,
                    fontSize: '12px'
                }}>
                <a href={q.url} target='_blank'>
                    <MDBIcon icon='external-link' /> {q.name}
                </a>
            </Typography>
        )}
    </Box>
}
const formatTag = (tag) => {
    // const { message, source } = all;
    return <Typography
        // onClick={() => {
        //     const tagsInput = document.querySelector('#TagWrapper input');
        //     if (tagsInput){
        //         tagsInput.value = 'sss';
        //     }
        // }}
        variant='subtitle1'
        sx={{
            whiteSpace: 'break-spaces',
            wordBreak: 'break-all'
        }}
        className={`pe-2`}>
        {tag}
    </Typography>
}

const formatReqResMeta = (value) => {
    return <MDBRow className='small'>
        <MDBCol className='small' size='6'>
            <div>
                <h6 className='text-info d-inline-block me-3'>Request
                </h6>
                <CopyToClipboard text={JSON.stringify(value.req) || '{}'}>
                    <ThemeButton size='small' variant='outlined' className='rounded-pill btn-sm' color='info'>Copy to Clipboard</ThemeButton>
                </CopyToClipboard>
            </div>
            <div className='border border-info'>
                {formatLog(value.req)}
            </div>
        </MDBCol>
        <MDBCol className='small' size='6'>
            <div>
                <h6 className='text-success d-inline-block me-3'>Response
                </h6>
                <CopyToClipboard text={JSON.stringify(value.res) || '{}'}>
                    <ThemeButton size='small' variant='outlined' className='rounded-pill btn-sm' color='success'>Copy to Clipboard</ThemeButton>
                </CopyToClipboard>
            </div>

            <div className='border border-success'>
                {formatLog(value.res)}
            </div>
        </MDBCol>
        <MDBCol className='small mt-3' size='12'>
            <div>
                <h6 className='text-warning d-inline-block me-3'>Meta
                </h6>
                <CopyToClipboard text={JSON.stringify(value.meta) || '{}'}>
                    <ThemeButton size='small' variant='outlined' className='rounded-pill btn-sm' color='warning'>Copy to Clipboard</ThemeButton>
                </CopyToClipboard>
            </div>
            <div className='border border-warning'>
                {formatLog(value.meta)}
            </div>
        </MDBCol>
    </MDBRow >
}

const getColumns = (serverName, utc) => {
    serverName = serverName ? serverName.toLowerCase() : '';
    let columns = [
        tblFormatId(),
        tblFormatTime({ id: 'timestamp', label: 'Timestamp', ms: true, copy: true, utc: utc }),
        tblFormatUserId(),
        {
            id: 'severity',
            label: '',
            align: 'center',
            style: { width: '1.5rem', padding: 0 },
            labelStyle: { width: '1.5rem', padding: 0 },
            format: (severity) => {
                severity = severity ? severity.toLowerCase() : '-';
                return <span className={`text-${(severity == 'error') ? 'danger' : severity == 'alert' ? 'error' : severity}`}>
                    <MDBIcon icon={'circle'}></MDBIcon></span>
            }
        }, {
            id: 'all',
            label: <span className='ResTableTitle py-2 cursor-pointer'><span>Server - Env</span><MDBIcon className='p-1 py-md-0' icon='eye-slash' /></span>,
            align: 'left',
            labelAlign: 'left',
            style: { width: '10rem' },
            labelStyle: { width: '10rem' },
            format: (all) => formatServer(all)
        }, {
            id: 'all',
            label: <span className='ResTableTitle py-2 cursor-pointer'><span>Type - Title</span><MDBIcon className='p-1 py-md-0' icon='eye-slash' /></span>,
            align: 'left',
            labelAlign: 'left',
            style: { width: '16rem' },
            labelStyle: { width: '16rem' },
            format: (all) => formatTypeTitle(all)
            // }, {
            //     id: 'type',
            //     label: <span className='ResTableTitle py-2 cursor-pointer'><span>Type</span><MDBIcon className='p-1 py-md-0' icon='eye-slash' /></span>,
            //     align: 'left',
            //     labelAlign: 'left',
            //     style: { width: '4rem' },
            //     labelStyle: { width: '4rem' },
            //     format: (type) => <span className='text-white'>{type}</span>
            // }, {
            //     id: 'title',
            //     label: <span className='ResTableTitle py-2 cursor-pointer'><span>Title</span><MDBIcon className='p-1 py-md-0' icon='eye-slash' /></span>,
            //     align: 'left',
            //     labelAlign: 'left',
            //     style: { minWidth: '10rem', whiteSpace: 'normal' },
            //     labelStyle: { width: '10rem' },
            //     format: (message) => <span className='text-white d-block'>{message}</span>

        }, {
            id: 'all',
            label: <span className='ResTableTitle py-2 cursor-pointer'><span>Message/Source</span><MDBIcon className='p-1 py-md-0' icon='eye-slash' /></span>,
            align: 'left',
            labelAlign: 'left',
            style: { minWidth: '10rem', whiteSpace: 'normal', wordBreak: 'break-all' },
            labelStyle: { minWidth: '10rem' },
            format: (all) => formatMessage(all)
        }, {
            id: 'tag',
            label: <span className='ResTableTitle py-2 cursor-pointer'><span>Tag</span><MDBIcon className='p-1 py-md-0' icon='eye-slash' /></span>,
            align: 'left',
            labelAlign: 'left',
            style: { minWidth: '14rem', whiteSpace: 'normal' },
            labelStyle: { width: '14rem' },
            format: (tag) => formatTag(tag)
            // }, {
            //     id: 'source',
            //     label: <span className='ResTableTitle py-2 cursor-pointer'><span>Source</span><MDBIcon className='p-1 py-md-0' icon='eye-slash' /></span>,
            //     align: 'left',
            //     labelAlign: 'left',
            //     style: { fontSize: '0.6rem', minWidth: '10rem', whiteSpace: 'normal', wordBreak: 'break-all' },
            //     labelStyle: { width: '10rem' },
            //     format: (source) => <span className='text-white '>{source}</span>
        }, {
            id: 'all',
            label: <span className='ResTableTitle py-2 cursor-pointer'><span>Link</span><MDBIcon className='p-1 py-md-0' icon='eye-slash' /></span>,
            align: 'left',
            labelAlign: 'left',
            style: { minWidth: '4rem', whiteSpace: 'normal' },
            labelStyle: { width: '4rem' },
            format: (all) => formatQuery(all)
        }, {
            id: 'all',
            label: 'All',
            collapse: true,
            align: 'left',
            labelAlign: 'left',
            style: {
                overflow: 'hidden',
                padding: '0 100px !important',
                whiteSpace: 'break-spaces !important'
            }, format: value => formatReqResMeta(value)
        }
    ];

    let extra = [];
    switch (serverName) {
        // EVOLUTION
        case 'imoon-api':
            extra = []
            break;
        // EVOLUTION
        case 'imoon-evolution':
            extra = [
                // UserId
                {
                    id: 'req',
                    label: 'User / Currency',
                    align: 'left',
                    style: {
                        maxWidth: '60px',
                        overflow: 'hidden',
                    },
                    format: (req) => <span className='text-white'>
                        {req && req.body && req.body.userId ? req.body.userId : ''}
                        <span className='p-1 py-md-0'>/</span>
                        {req && req.body && req.body.currency ? req.body.currency : ''}
                    </span>
                }]
            break;
        // DIGITAIN
        case 'imoon-digitain':
            extra = [
                // UserId
                {
                    id: 'req',
                    label: 'UserId',
                    align: 'left',
                    style: {
                        maxWidth: '60px',
                        overflow: 'hidden',
                    },
                    format: (req) => <span className='text-white'>{req ? req.userId : ''}</span>
                }]
            break;
        // EveryMatrix
        case 'imoon-digitain':
            extra = [
                // UserId
                {
                    id: 'req',
                    label: 'UserId',
                    align: 'left',
                    style: {
                        maxWidth: '60px',
                        overflow: 'hidden',
                    },
                    format: (req) => <span className='text-white'>{req ? req.userId : ''}</span>
                }]
            break;
        // CryptoPay
        case 'cryptopay-api':
            extra = []
            break;
        // Chat
        case 'imoon-chat':
            extra = []

            break;

        default:
            break;
    }
    columns.push(...extra)
    return columns;
}

const getColor = (status) => {
    status = status ? status.toLowerCase() : 'all';
    switch (status) {
        case 'alert':
            return 'error'
        case 'error':
            return 'danger'
        case 'warning':
            return 'warning'
        case 'info':
            return 'info'
        case 'success':
            return 'success'
        case 'debug':
            return 'white'
        case 'all':
            return 'white'
        default:
            return 'info'
    }
}

const Logs = (props) => {
    const [state, dispatch] = useContext(Context);
    const { serverName } = useParams();// props.match.params;
    const [contextBC, setContextBC] = useContext(BreadCrumbContext);
    const [searchParams, setSearchParams] = useSearchParams();

    useLayoutEffect(() => {

        $('.ResTableTitle').on('click', resTableTitle)

        setContextBC([
            {
                href: '/',
                text: 'Home'
            },
            {
                href: '/Servers/List/',
                text: 'Servers'
            },
            {
                text: serverName
            }
        ])
    }, [])
    const [selectedSeverities, setSelectedSeverities] = useState(['alert', 'error', 'warning', 'info']);

    const [values, setValues] = useState({
        fromDate: getFromTime('1min'),
        toDate: getLastDayOfCurrentMonth(),
        server: '',
        title: '',
        message: '',
        type: '',
        userId: '',
        source: ''

    })
    const [rows, setRows] = useState([]);
    const interval = useRef(null);
    const [updated, setUpdated] = useState(Date.now());
    const [waiting, setWaiting] = useState(false)
    const [filter, setFilter] = useState({
        find: {
            severity: { $in: selectedSeverities }
        },
        limit: 20,
        // sort: { updatedAt: -1 },
        skip: 0
    })

    const [stream, setStream] = useState(true);
    const preventFirstFilter = useRef();
    useEffect(() => {
        if (preventFirstFilter.current) {
            clearInterval(interval.current);
            getServiceLogs(state, { setRows, filter, serverName }, setWaiting);
            interval.current = setInterval(() => {
                getServiceLogs(state, { setRows, filter, serverName }, setWaiting);
                setUpdated(Date.now())
            }, 3000);
        }

        return () => {
            preventFirstFilter.current = true;
        }
    }, [filter])

    useEffect(() => {
        return () => {
            // clearInterval(interval.current)
        }
    }, [])


    const startLogs = (f) => {
        clearInterval(interval.current);
        getServiceLogs(state, { setRows, filter: f, serverName }, setWaiting);
        console.log('stream', stream)
        interval.current = setInterval(() => {
            console.log('stream3', stream)
            getServiceLogs(state, { setRows, filter: f, serverName }, setWaiting);
            setUpdated(Date.now())
        }, 3000);
    }

    useEffect(() => {
        clearInterval(interval.current);
        if (stream) {
            startLogs(filter)
        }
    }, [stream])

    const handleSetFilter = () => {
        console.log('selectedSeverities', selectedSeverities)
        let findQuery = { ...filter.find, $and: [] };
        if (selectedSeverities.length == 0) {
            findQuery.severity = { $exist: 1 }
        } else {
            findQuery.severity = { $in: selectedSeverities }
        }

        // From Date
        if (values.fromDate) {
            findQuery.$and.push({
                'timestamp': { $gt: values.fromDate }
            })
        }
        // To Date
        if (values.toDate) {
            findQuery.$and.push({
                'timestamp': { $lt: values.toDate }
            })
        }
        // Title
        if (values.title && (values.title.trim() != '')) {
            findQuery.title = values.title.trim()//{ $regex: values.title, $options: "i" }
        } else {
            findQuery = _.omit(findQuery, ['title']);
        }
        // Server
        if (values.server && (values.server.trim() != '')) {
            findQuery.server = values.server.trim()//{ $regex: values.server, $options: "i" }
        } else {
            findQuery = _.omit(findQuery, ['server']);
        }
        // Tag
        if (values.tag && (values.tag.trim() != '')) {
            findQuery.tag = values.tag.trim()//{ $regex: values.tag, $options: "i" }
        } else {
            findQuery = _.omit(findQuery, ['tag']);
        }
        // Message
        if (values.message && (values.message.trim() != '')) {
            findQuery.message = values.message.trim()//{ $regex: values.message, $options: "i" }
        } else {
            findQuery = _.omit(findQuery, ['message']);
        }
        // Type
        if (values.type && (values.type.trim() != '')) {
            findQuery.type = values.type.trim() //{ $regex: values.type, $options: "i" }
        } else {
            findQuery = _.omit(findQuery, ['type']);
        }
        // Source
        if (values.source && (values.source.trim() != '')) {
            findQuery.source = values.source.trim()//{ $regex: values.source, $options: "i" }
        } else {
            findQuery = _.omit(findQuery, ['source']);
        }
        // UserId
        if (values.userId && (values.userId.trim() != '')) {
            findQuery.userId = values.userId.trim()//{ $regex: values.userId, $options: "i" }
        } else {
            findQuery = _.omit(findQuery, ['userId']);
        }


        findQuery = _.omit(findQuery, [
            'fromDate', 'toDate',
        ]);
        // findQuery = _.omit(findQuery, ['usernameEmail', 'hasBalance', 'walletAddress', 'casino', 'crash', 'sport', 'poker', 'active', 'deposit', 'withdraw', 'topup', 'email', 'mobile', 'telegram']);
        if (findQuery.$and.length == 0) findQuery = _.omit(findQuery, ['$and']);
        setFilter({
            find: findQuery,
            sort: filter.sort,
            limit: filter.limit,
            skip: 0,
            query: searchParams.get('query')
        })
        // console.log('searchParams::', searchParams.get('query'))
    }


    const handlePagination = (e, page) => {
        setFilter({
            ...filter,
            skip: (page - 1) * filter.limit
        });
    }

    const preventFirst = useRef(false);
    useEffect(() => {
        if (preventFirst.current) {
            console.log('severities1', selectedSeverities)
            handleSetFilter()
        }
        return () => {
            console.log('severities2', selectedSeverities)
            preventFirst.current = true;
        }
    }, [selectedSeverities, values])
    const [showFilters, setShowFilters] = useState(!isMobileOnly)

    const [UTC, setUTC] = useState(false)
    const [columns, setColumns] = useState(getColumns(serverName))
    useEffect(() => {
        if (UTC) {
            setColumns(getColumns(serverName, true))
        } else {
            setColumns(getColumns(serverName, false))
        }

        return () => {

        }
    }, [UTC])

    const [selectedInterval, setSelectedInterval] = useState('1min')

    useEffect(() => {
        setValues({
            ...values,
            fromDate: getFromTime(selectedInterval)
        })
    }, [selectedInterval])

    // save filter to localStorage {key: adminfilterServers, value : stringifiedFilter}
    // select with localStorage

    return <>
        <AppBar>
            <Toolbar />
            <Toolbar disableGutters variant='dense' />
            <Toolbar disableGutters variant='dense' sx={{
                marginLeft: 'auto',
                // width: ['100%', 'calc(100%  - 3.5rem)'],
                width: '100vw',
                bgcolor: '#0005',
                paddingLeft: ['0', '3.5rem'],
                alignItems: 'baseline',
            }}>
                <Box className='bg-black'>
                    <MDBRow className='m-0 w-100'>
                        <MDBCol size='12' style={{ backgroundColor: '#000d' }} className='d-md-none p-1 py-2 small d-flex align-items-center justify-content-between box-shadow-neon'>
                            {<small className='border border-neon text-neon rounded-pill px-2' onClick={() => setShowFilters(!showFilters)}><MDBIcon icon={`angle-${showFilters ? 'up' : 'down'}`}></MDBIcon> Filters</small>}
                            <small className='text-neon' style={{ lineHeight: '0.8rem' }}>
                                {/* {numeral(count).format('0,0')} record found. */}
                            </small>
                        </MDBCol>
                        <Collapse in={showFilters} sx={{ bgcolor: '#000d' }}>
                            <MDBCol size='12'>
                                <MDBRow className='m-0 w-100'>
                                    <MDBCol size='12' lg='12' className='p-1 text-white align-items-center d-flex'>
                                        <strong className='text-primary me-2'>{serverName}</strong>
                                        <div className="d-inline-block">
                                            <MDBIcon className='cursor-pointer ms-2' onClick={() => setStream(!stream)} icon={stream ? 'pause' : 'play'}></MDBIcon>
                                        </div>
                                        <div className='overflow-hidden d-inline-flex' >
                                            <div className="heart-rate overflow-hidden">
                                                <svg className='d-inline-block mt-n2' version="1.0" x="0px" y="0px" width="70px" height="25px" viewBox="0 0 150 73" enableBackground="new 0 0 150 73" >
                                                    <polyline fill="none" stroke="#26ddff" strokeWidth="3" strokeMiterlimit="10" points="0,45.486 38.514,45.486 44.595,33.324 50.676,45.486 57.771,45.486 62.838,55.622 71.959,9 80.067,63.729 84.122,45.486 97.297,45.486 103.379,40.419 110.473,45.486 150,45.486"
                                                    />
                                                </svg>
                                                {
                                                    stream ? <>
                                                        <div className="fade-in"></div>
                                                        <div className="fade-out"></div>
                                                    </> : <></>
                                                }
                                            </div>
                                        </div>
                                        <div className='d-inline-block'>
                                            {
                                                ['alert', 'error', 'warning', 'info', 'success', 'debug'].map((severity, i) =>
                                                    <Tooltip key={i} title={severity} >
                                                        <span>
                                                            <MDBIcon icon='circle'
                                                                className={`cursor-pointer mx-1 text-${getColor(severity)} ` +
                                                                    (selectedSeverities.indexOf(severity) > -1 ? 'fw-bold' : 'fw-light')}
                                                                onClick={() => {
                                                                    setSelectedSeverities(_.xor(selectedSeverities, [severity]))
                                                                }}
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                )
                                            }
                                        </div>
                                        <div className=''>
                                            {
                                                ['live', '1min', '10min', '30min', '1hour', '1day', '1week'].map((interval, i) =>
                                                    <Typography
                                                        key={i}
                                                        variant='caption'
                                                        onClick={() => setSelectedInterval(interval)}
                                                        sx={{
                                                            mx: 1,
                                                            cursor: 'pointer',
                                                            fontSize: '12px',
                                                            p: '2px 10px',
                                                            borderRadius: 10,
                                                            border: '1px solid #999',
                                                            color: interval == selectedInterval ? '#0a0' : '#999',
                                                            borderColor: interval == selectedInterval ? '#0a0' : '#999'
                                                        }}
                                                    >
                                                        {interval}
                                                    </Typography>
                                                )
                                            }
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className='m- pb-2'>
                                    <MDBCol size='1' md='2' xl='1' className='p-1 py-md-0 align-items-center d-flex'>
                                        <ThemeButton size='small' className='px-3 py-1' onClick={() => setUTC(!UTC)} color={UTC ? 'success' : 'warning'} variant='outlined'>{UTC ? 'UTC' : 'Local'}</ThemeButton>
                                    </MDBCol>
                                    <MDBCol size='12' md='4' xl='3' className='d-flex p-1 py-md-0 justify-content-between'>
                                        <div className='pe-1' >
                                            <ThemeDatePicker label='From' value={values.fromDate} onChange={fromDate => setValues({ ...values, fromDate })}></ThemeDatePicker>
                                        </div>
                                        <div className='ps-1' >
                                            <ThemeDatePicker className='ps-1' label='To' value={values.toDate} onChange={toDate => setValues({ ...values, toDate })}></ThemeDatePicker>
                                        </div>
                                    </MDBCol>
                                    <MDBCol size='4' md='2' xl='1' className='p-1 py-md-0'>
                                        <ThemeInput label='UserId' type='search' value={values.userId} onChange={e => setValues({ ...values, userId: e.target.value })} ></ThemeInput>
                                    </MDBCol>
                                    <MDBCol size='4' md='2' xl='1' className='p-1 py-md-0'>
                                        <ThemeInput label='Server' labelClass='text-success text-shadow-success' type='search' value={values.server} onChange={e => setValues({ ...values, server: e.target.value })} ></ThemeInput>
                                    </MDBCol>
                                    <MDBCol size='4' md='2' xl='1' className='p-1 py-md-0'>
                                        <ThemeInput label='Type' type='search' value={values.type} onChange={e => setValues({ ...values, type: e.target.value })} ></ThemeInput>
                                    </MDBCol>
                                    <MDBCol size='4' md='2' xl='1' className='p-1 py-md-0'>
                                        <ThemeInput label='Title' type='search' value={values.title} onChange={e => setValues({ ...values, title: e.target.value })} ></ThemeInput>
                                    </MDBCol>
                                    <MDBCol size='6' md='2' xl='1' className='p-1 py-md-0'>
                                        <ThemeInput label='Message' type='search' value={values.message} onChange={e => setValues({ ...values, message: e.target.value })} ></ThemeInput>
                                    </MDBCol>
                                    <MDBCol size='4' md='2' xl='1' className='p-1 py-md-0'>
                                        <ThemeInput label='Source' type='search' value={values.source} onChange={e => setValues({ ...values, source: e.target.value })} ></ThemeInput>
                                    </MDBCol>
                                    <MDBCol id='TagWrapper' size='6' md='2' xl='1' className='p-1 py-md-0'>
                                        <ThemeInput label='Tag' type='search' value={values.tag} onChange={e => setValues({ ...values, tag: e.target.value })} ></ThemeInput>
                                    </MDBCol>



                                    {/* <MDBCol size='12' md='2' xl='1' className='p-1 py-md-0'>
                <ThemeButton variant='outlined' className='py-2 px-3 mx-2' onClick={handleSetFilter} color='neon'>Search</ThemeButton>
            </MDBCol> */}

                                    <MDBCol size='4' md='2' xl='1' className='p-1 py-md-0'>
                                        <ThemeSelect style={{ width: '100px' }} value={filter.limit} label='Page Size' onChange={e => setFilter({ ...filter, limit: parseInt(e.target.value) })}>
                                            <option value={10}>10</option>
                                            <option value={15}>15</option>
                                            <option value={20}>20</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </ThemeSelect>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </Collapse>
                    </MDBRow>

                </Box>
            </Toolbar>
        </AppBar>
        <Toolbar />
        <Toolbar disableGutters variant='dense' />

        <Box sx={{ my: 0 }}>
            <ResponsiveTable collapsible rows={rows} columns={columns}></ResponsiveTable>

            {
                Math.floor((rows.count || 10000) / filter.limit) > 0 ?
                    <MDBRow className='m-0'>
                        <MDBCol size='12' className='text-center py-4'>
                            <Pagination onChange={handlePagination} className='d-inline-block' count={Math.ceil((rows.count || 10000) / filter.limit)} variant="outlined" shape="rounded" />
                        </MDBCol>
                    </MDBRow> : <></>
            }
        </Box>
    </>
}

export default Logs
