import { MDBIcon } from 'mdb-react-ui-kit';
import moment from 'moment';
import numeral from 'numeral';
import React, { Fragment } from 'react'
import _ from 'lodash'
import { currencyDetail, formatAmountCurrency } from '../../../helpers/currency';
import { Tooltip, Zoom } from '@mui/material';

const RenderUserBalance = (props) => {
    const {
        // currency,
        meta, type } = props;

    const currency = 'inUSD';
    if (meta && meta.history) {
        const { history } = meta;
        let formatUserBalanceRender = history.map((step, i) => {
            let result = '';
            if (!step.walletBefore) result = <>?</>
            let walletBalanceBefore = 0, walletBalanceAfter = 0;
            // Deposit 
            if ((type == 'deposit' && step.walletBefore[currency])) {
                walletBalanceBefore = step.walletBefore[currency].balance;
                walletBalanceAfter = step.walletAfter[currency].balance;
            }
            // Withdraw 
            else if ((type == 'withdraw') && step.walletBefore[currency]) {
                walletBalanceBefore = step.walletBefore[currency].balance;
                walletBalanceAfter = step.walletAfter[currency].balance;
                // Topup
            } else if ((type == 'topup' && step.walletBefore[currency])) {
                walletBalanceBefore = step.walletBefore[currency].balance;
                walletBalanceAfter = step.walletAfter[currency].balance;
                // Game
            } else if (type == 'bet' && step.walletBefore[currency]) {
                walletBalanceBefore = step.walletBefore[currency].balance;
                walletBalanceAfter = step.walletAfter[currency].balance;
                // Ref
            } else if (step.walletBefore[currency] && step.walletAfter[currency] && step.walletBefore[currency].play && step.walletAfter[currency].play) {
                walletBalanceBefore = -(step.walletBefore[currency].play.win + step.walletBefore[currency].play.amount);
                walletBalanceAfter = -(step.walletAfter[currency].play.win + step.walletAfter[currency].play.amount);
            } else {
                result = <>-</>
            }

            if (Math.abs(walletBalanceBefore) < 0.000001) walletBalanceBefore = 0;
            if (Math.abs(walletBalanceAfter) < 0.000001) walletBalanceAfter = 0;
            result = <Fragment>
                <Tooltip
                    TransitionComponent={Zoom}
                    classes={{ tooltip: 'bg-black box-shadow-neon rounded-6  border border-neon ' }}
                    placement="right" title={<div className='p-1'>
                        <small className='text-yellow pe-2'>
                            {moment(step.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                        </small>
                        <small>
                            {step.state}
                        </small>

                    </div>}>
                    <small className={`${type == 'bet' ? 'text-gray' : (walletBalanceBefore > walletBalanceAfter) ? 'text-danger' : 'text-success'} WinAmountItem text-center w-100 d-block px-2`}>
                        <div className='d-flex justify-content-around'>
                            <span className='text-end' style={{ flex: 2 }}>
                                {numeral(formatAmountCurrency(walletBalanceBefore, currency)).format('$' + currencyDetail[currency].format)}
                            </span>
                            <span style={{ flex: 1 }}>
                                <MDBIcon icon='chevron-circle-right' className='text-warning mx-1'></MDBIcon>
                            </span>
                            <span className='text-start' style={{ flex: 2 }}>
                                {numeral(formatAmountCurrency(walletBalanceAfter, currency)).format('$' + currencyDetail[currency].format)}
                            </span>
                        </div>
                    </small>
                </Tooltip>
            </Fragment>
            return <Fragment key={i}>{result}</Fragment>
        })
        return formatUserBalanceRender
    }

    let beforeBalance = '', afterBalance = '';

    if (_.has(meta, `walletBefore.${currency}.balance`)) {
        beforeBalance = numeral(meta.walletBefore[currency].balance).format('$' + currencyDetail[currency].format)
    }
    if (_.has(meta, `walletAfter.${currency}.balance`)) {
        afterBalance = numeral(meta.walletAfter[currency].balance).format('$' + currencyDetail[currency].format)
    }
    if (beforeBalance && afterBalance) {
        return <small className={`WinAmountItem text-gray text-center w-100 d-block px-1`}>
            <div className='d-flex justify-content-around'>
                <span className='text-end' style={{ flex: 2 }}>
                    {beforeBalance}
                </span>
                <span style={{ flex: 1 }}>
                    <MDBIcon icon='chevron-circle-right' className='text-warning mx-1'></MDBIcon>
                </span>
                <span className='text-start' style={{ flex: 2 }}>
                    {afterBalance}
                </span>
            </div>
            <div className='d-flex justify-content-around'>
                <span className='text-end' style={{ flex: 2 }}>
                    {beforeBalance}
                </span>
                <span style={{ flex: 1 }}>
                    <MDBIcon icon='chevron-circle-right' className='text-warning mx-1'></MDBIcon>
                </span>
                <span className='text-start' style={{ flex: 2 }}>
                    {afterBalance}
                </span>
            </div>
        </small>
    } else {
        return <></>
    }

}

export default RenderUserBalance