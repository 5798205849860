import React, { useEffect, useState, useRef, useContext, useLayoutEffect } from 'react';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import CopyToClipboard from 'react-copy-to-clipboard';
import axios from 'axios';
import { Pagination, Typography } from '@mui/material';
import ReactJson from 'react-json-view';
import ThemeButton from '../../../layout/components/ThemeButton';
import ThemeFormatTime from '../../../layout/components/ThemeFormatTime';
import ResponsiveTable from '../../Tables/ResponsiveTable';
import CrashReportFilter from './CrashReportFilter';
import { useSnackbar } from 'notistack';
import { getBearer } from '../../../helpers/publicHelper';
import _ from 'lodash';
import ThemeBreadCrumb from '../../../layout/components/ThemeBreadCrumb';
import { Box } from '@mui/system';
import CrashChart from './CrashChart';
import moment from 'moment';
import { tblFormatId, tblFormatTime, tblFormatUserId } from '../../../helpers/formatHelpers';
import { BreadCrumbContext } from '../../../context/store';
import numeral from 'numeral';
import ThemeFormatCurrency from '../../../layout/components/ThemeFormatCurrency';
import ThemeFormatCrashPoint from '../../../layout/components/ThemeFormatCrashPoint';

const FormatCrashPoint = (props) => {
    const { point } = props;
    let cls = 'text-success';
    if (point == '?') cls = 'text-yellow border border-yellow';
    if (point == 0) cls = 'text-neon border border-neon';
    if (point == 1) cls = 'text-warning border border-warning';
    if (point > 1 && point < 2) cls = 'text-danger';
    return <span className={'rounded-3 px-3 py-1 ' + cls}>
        {point}
    </span>
}

const FormatBets = (bets) => {
    if (!bets) return
    console.log(bets)
    let classicCount = 0, overCount = 0, underCount = 0, rangeCount = 0;
    for (const betId in bets) {
        const bet = bets[betId];
        if (bet.mode == 'classic') classicCount++;
        if (bet.mode == 'over') overCount++;
        if (bet.mode == 'under') underCount++;
        if (bet.mode == 'range') rangeCount++;
    }
    // const classicCount = bets.filter(b => b.mode == 'classic').length;
    // const overCount = bets.filter(b => b.mode == 'over').length;
    // const underCount = bets.filter(b => b.mode == 'under').length;
    // const rangeCount = bets.filter(b => b.mode == 'range').length;
    return <MDBRow>
        <MDBCol size='3' className={`small ${classicCount == 0 ? 'opacity-20' : ''}`}>
            <div className='small '>Classic</div>
            <div className='small '>{classicCount}</div>
        </MDBCol>
        <MDBCol size='3' className={`small ${overCount == 0 ? 'opacity-20' : ''}`}>
            <div className='small '>Over</div>
            <div className='small '>{overCount}</div>
        </MDBCol>
        <MDBCol size='3' className={`small ${underCount == 0 ? 'opacity-20' : ''}`}>
            <div className='small '>Under</div>
            <div className='small '>{underCount}</div>
        </MDBCol>
        <MDBCol size='3' className={`small ${rangeCount == 0 ? 'opacity-20' : ''}`}>
            <div className='small '>Range</div>
            <div className='small '>{rangeCount}</div>
        </MDBCol>
    </MDBRow>

}

const FormatDetail = (props) => {
    const { bets } = props;
    if (!bets) return;
    const result = Object.keys(bets).map((betId, i) => {
        const { username, mode, index, amount, odds, oddsRange, win, status } = bets[betId];
        console.log(bets[betId])
        return <Bet key={i} rowIndex={i} {...bets[betId]} crash={{ ...props.crash }} />

        // <MDBRow key={i} size='12' className='m-0 ' style={{ background: !i % 2 ? '#fff2' : 'transparent' }}>
        //     <MDBCol size='2' className='py-2'>{username}</MDBCol>
        //     <MDBCol size='2' className='py-2'>{mode} ({index})</MDBCol>
        //     <MDBCol size='2' className='py-2'>{amount}</MDBCol>
        //     <MDBCol size='2' className='py-2'>{win}</MDBCol>
        //     <MDBCol size='2' className='py-2'>{odds} {mode == 'range' ? ` ~ ${oddsRange}` : ''}</MDBCol>
        //     <MDBCol size='2' className='py-2'>{status}</MDBCol>
        // </MDBRow>
    })
    return <Box className='rounded-3 box-shadow-neon overflow-hidden my-4' sx={{
        // padding: ['0','5px'],
        background: '#fff1'
    }}>
        {/* <MDBRow size='12' className='m-0 py-2 bg-black text-neon fw-bold'>
            <MDBCol size='2'>Username</MDBCol>
            <MDBCol size='2'>Mode (index)</MDBCol>
            <MDBCol size='2'>Bet Amount</MDBCol>
            <MDBCol size='2'>Win Amount</MDBCol>
            <MDBCol size='2'>Odds</MDBCol>
            <MDBCol size='2'>Status</MDBCol>
        </MDBRow> */}
        {result}
    </Box>
}

const columns = [
    tblFormatId(),
    tblFormatTime(),
    tblFormatTime({ id: 'updatedAt', label: 'Updated At' }),
    {
        id: 'all',
        label: 'Crash Point',
        align: 'center',
        style: { width: '5rem' },
        labelStyle: { width: '5rem' },
        format: (all) => all.end ? <FormatCrashPoint point={all.crash.point} /> : <FormatCrashPoint point='?' />
    }, {
        id: 'bets',
        label: 'Bets',
        align: 'center',
        style: { width: '15rem' },
        labelStyle: { width: '15rem' },
        format: bets => FormatBets(bets)
    }, {
        id: 'all',
        label: 'MD5',
        align: 'center',
        style: { width: '15rem' },
        labelStyle: { width: '15rem' },
        format: (all) => <span>
            {all.crash.md5}
        </span>
    }, {
        id: 'all',
        label: 'all',
        collapse: true,
        align: 'left',
        style: {
            overflow: 'hidden',
            padding: '0 100px !important',
            whiteSpace: 'break-spaces !important'
        }, format: all => FormatDetail(all)
    }
];

const CrashReport = (props) => {
    const userId = new URLSearchParams(window.location.search).get('userId') || props.userId;
    const type = new URLSearchParams(window.location.search).get('type') || props.type;

    const [contextBC, setContextBC] = useContext(BreadCrumbContext);
    useLayoutEffect(() => {
        setContextBC([
            {
                href: '/',
                text: 'Home'
            },
            {
                href: '/Crash',
                text: '/Crash'
            },
            {
                text: 'Report'
            }
        ])
    }, [])

    const [rows, setRows] = useState([])
    const interval = useRef(null);
    const [filter, setFilter] = useState({
        find: {
            userId: userId,
            type: type
        },
        limit: 20,
        skip: 0,
        sort: { createdAt: -1 }
    })

    const getTransactions = (setRows, filters) => {
        const { find, skip, limit, sort } = filters;
        axios.post(process.env.REACT_APP_API_HOST + '/admin/games/crash/report',
            {
                find, skip, limit, sort
            },
            {
                headers: { 'x-auth-token': getBearer() }
            })
            .then(apiRes => {
                apiRes = apiRes.data;
                if (apiRes.success) {
                    setRows({
                        count: apiRes.result.count,
                        rounds: apiRes.result.rounds.map(row => ({ ...row, all: row }))
                    });
                } else {
                    // setRows({
                    //     count: 0,
                    //     rounds: []
                    // });
                }
            })
    }

    const handlePagination = (e, page) => {
        setFilter({
            ...filter,
            skip: (page - 1) * filter.limit
        });
    }

    const [tableColumns, setTableColumns] = useState(columns);

    useEffect(() => {
        let updateInterval = setInterval(() => {
            getTransactions(setRows, filter);
        }, 5000);
        interval.current = updateInterval;

        setTableColumns(columns.filter(c => c.label != 'fieldToBeRemoved'))

        return () => {
            clearInterval(interval.current)
        }
    }, [filter])

    return (
        <>
            <MDBRow className='m-0'>
                {/* <CrashChart title={`${moment().format('YYYY/MM/DD')} Report`} dateFrom={moment.utc(moment().startOf('day')).valueOf()} dateTo={moment.utc(moment().endOf('day')).valueOf()} />
                <CrashChart title={`${moment().startOf("month").format('MMMM')} Report`} dateFrom={moment.utc(moment().startOf('month')).valueOf()} dateTo={moment.utc(moment().endOf('month')).valueOf()} />
                <CrashChart title='Total Report' dateFrom={moment.utc(`2021-01-01`).valueOf()} dateTo={moment.utc(`2023-01-01`).valueOf()} /> */}
            </MDBRow>
            <CrashReportFilter setRows={setRows} getTransactions={getTransactions} count={rows.count} setFilter={setFilter} filter={filter}></CrashReportFilter>
            <ResponsiveTable collapsible rows={rows.rounds} columns={tableColumns}></ResponsiveTable>
            {
                Math.floor(rows.count / filter.limit) > 0 &&
                <MDBRow className='m-0'>
                    <MDBCol size='12' className='text-center py-4'>
                        <Pagination onChange={handlePagination} className='d-inline-block' count={Math.ceil(rows.count / filter.limit)} variant="outlined" shape="rounded" />
                    </MDBCol>
                </MDBRow>
            }
        </>)
}

const Bet = (props) => {
    const { open, setOpen, betAmount, originalAmount, timestamp, username, winAmount, currency, mode, index, odds, oddsRange, cashoutOdds, status, insurance, crash, bet, rowIndex } = props;
    return <MDBRow className={`m-0 py-1 bg-black${(rowIndex % 2) ? ' -20' : '-10'}`}>
        <MDBCol lg='3' className='text-start small d-none d-lg-flex align-items-center px-0'>
            <div>
                <MDBIcon onClick={() => setOpen(!open)} icon='chevron-down' className='ps-2 pe-3 cursor-pointer text-success'></MDBIcon>
            </div>
            <div>
                <ThemeFormatTime timestamp={timestamp} showFromNow />
            </div>
            <div className='ps-5 text-capitalize fw-bold'>
                {/* <Title>Mode</Title> */}
                {mode == 'classic' && <MDBIcon className='text-warning pe-1' icon='chart-line'></MDBIcon>}
                {mode == 'over' && <MDBIcon className='text-success pe-1' icon='arrow-up'></MDBIcon>}
                {mode == 'under' && <MDBIcon className='text-danger pe-1' icon='arrow-down'></MDBIcon>}
                {mode == 'range' && <MDBIcon className='text-info pe-1' icon='arrows-alt-h'></MDBIcon>}
                {mode} {index ? (index + 1) : ''}
                {insurance && <MDBIcon className='text-success' icon='shield-alt'></MDBIcon>}
            </div>
        </MDBCol>
        <MDBCol size='4' lg='2' className='text-center p-0 text-white-75'>
            <Title>username</Title>
            {username}
        </MDBCol>
        <MDBCol size='4' lg='2' className='text-center p-0 text-white-75'>
            <Title>Bet</Title>
            <ThemeFormatCurrency iconSize={1} text={betAmount} currency={currency} />
        </MDBCol>
        <MDBCol size='2' lg='1' className='text-center px-0 text-white-75'>
            <Title>Cashout</Title>
            {cashoutOdds && <span>{cashoutOdds}x</span>}
        </MDBCol>
        <MDBCol size='2' lg='1' className='text-center px-0 text-white-75'>
            <Title>Odds</Title>
            {odds}x
            {mode == 'range' && <span> ~ 12</span>}
        </MDBCol>
        <MDBCol size='0' lg='1' className={`d-none align-items-center d-lg-flex text-${status == 'won' || status == 'win' ? 'success' :
            status == 'lost' || status == 'lose' ? 'danger' :
                status == 'cancel' ? 'yellow' :
                    status == 'cashback' ? 'warning' : status}`}>
            <ThemeFormatCrashPoint point={numeral(crash.point).format('0,0.00')} />
        </MDBCol>
        <MDBCol size='4' lg='1' className='ps-3 text-center small text-uppercase'>
            {/* <Title>{status}</Title> */}
            <Typography
                component='div'
                sx={{
                    fontSize: '0.75rem',
                    display: 'inline-block',
                    padding: '1px 8px',
                    minWidth: ['0', '5rem'],
                    width: '100%',
                    fontWeight: 'bold'
                }} className={`rounded-3 ${status == 'won' || status == 'win' ? 'bg-success' :
                    status == 'lost' || status == 'lose' ? 'border border-danger text-danger' :
                        status == 'cancel' ? 'border border-yellow text-yellow' :
                            status == 'cashback' ? 'border border-warning text-warning' : status}`}>
                {/* {status} */}
                <div className='small'>{status == 'cancel' ? 'canceled' : status}</div>
                {numeral(winAmount).format('0,0.00')}
            </Typography>
        </MDBCol>
    </MDBRow>
}

const Title = (props) => {
    return <div style={{
        opacity: 0.4,
        fontSize: '0.6rem',
    }}>
        {props.children}
    </div>
}

export default CrashReport
