import React from 'react';
// import ReactDOM from 'react-dom';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import './styles/index.scss';
import App from './App';
import './context/i18n';
import { socket, SocketContext } from './context/socket';
import { CookiesProvider } from 'react-cookie';
import Store from './context/store';

// ReactDOM.render(
//   <Store>
//     <CookiesProvider>
//       <SocketContext.Provider value={socket}>
//         <App />
//       </SocketContext.Provider>
//     </CookiesProvider>
//   </Store>
//   ,
//   document.getElementById('app')
// );


import { createRoot } from 'react-dom/client';
const container = document.getElementById('app');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Store>
    <CookiesProvider>
      <SocketContext.Provider value={socket}>
        <App />
      </SocketContext.Provider>
    </CookiesProvider>
  </Store>
);