import React, { useContext, useEffect, useRef, useState } from 'react';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import moment from 'moment';
import ReactJson from 'react-json-view'
import ResponsiveTable from '../Tables/ResponsiveTable';
import CopyToClipboard from 'react-copy-to-clipboard';
import ThemeButton from '../../layout/components/ThemeButton'
import axios from 'axios';
import $ from 'jquery';
import ThemeSelect from '../../layout/components/ThemeSelect';
import numeral from 'numeral';
import { betStateByCode } from '../../helpers/digitainHelper';
import { getBearer } from '../../helpers/publicHelper';
import { Context } from '../../context/store';
import ThemeFormatTime from '../../layout/components/ThemeFormatTime';

const formatPL = (all) => {
    console.log(all)
    const { winAmount, betAmount, status } = all;
    const profit = parseFloat(betAmount) - parseFloat(winAmount);
    return <span className={`text-${status == 'New' ? 'warning' : profit > 0 ? 'success' : 'danger'}`}>
        {numeral(profit).format('0,0.00')}
    </span >
}


const jQinit = () => {
    $('body').on("click", '[data-module=SportLogs]', function () {
        const column = $(this).attr('data-column');
        const module = $(this).attr('data-module');
        const text = $(this).text();
        $(`[data-module=${module}]`).removeClass('text-warning').parents('tr').find(' > td').removeClass('border-bottom border-warning text-warning');
        $(`[data-module=${module}][data-column=${column}]:contains(${text})`).addClass('text-warning').parents('tr').find(' > td').addClass('border-bottom border-warning text-warning');
    });
}

const getSportLogs = (state, props) => {
    const { setRows, filter } = props;
    const { find, sort, skip, limit } = filter;
    const headers = { 'x-auth-token': getBearer() };
    axios.post(state.active.host + `/admin/digitain/sportTransactions/list`, filter, {
        headers: headers
    })
        .then(res => {
            res = res.data;
            if (res.success) {
                const rows = res.result.map(row => ({ ...row, all: row }));
                setRows(rows);
                console.log('Logs:', rows);
            } else {
                console.error('Logs:', res.result);
            }
        })
        .catch(error => {
            console.error('Logs:', error);
        })

}

const formatLog = (value) => {
    // JSON.stringify(value)
    return <ReactJson name={null} style={{ padding: '0.5rem' }} theme="monokai" src={value} />
}

const formatReqResMeta = (meta) => {
    const { Order, ...other } = meta;
    return <MDBRow className='small'>
        <MDBCol className='small' size='6'>
            <div>
                <h6 className='text-info d-inline-block me-3'>Order
                </h6>
                <CopyToClipboard text={JSON.stringify(Order) || '{}'}>
                    <ThemeButton size='small' variant='outlined' className='rounded-pill btn-sm' color='info'>Copy to Clipboard</ThemeButton>
                </CopyToClipboard>
            </div>
            <div className='border border-info'>
                {formatLog(Order)}
            </div>
        </MDBCol>
        <MDBCol className='small' size='6'>
            <div>
                <h6 className='text-success d-inline-block me-3'>Meta Other
                </h6>
                <CopyToClipboard text={JSON.stringify(other) || '{}'}>
                    <ThemeButton size='small' variant='outlined' className='rounded-pill btn-sm' color='success'>Copy to Clipboard</ThemeButton>
                </CopyToClipboard>
            </div>

            <div className='border border-success'>
                {formatLog(other)}
            </div>
        </MDBCol>
        {/* <MDBCol className='small mt-3' size='12'>
            <div>
                <h6 className='text-warning d-inline-block me-3'>Meta
                </h6>
                <CopyToClipboard text={JSON.stringify(value.meta) || '{}'}>
                    <ThemeButton size='small' variant='outlined' className='rounded-pill btn-sm' color='warning'>Copy to Clipboard</ThemeButton>
                </CopyToClipboard>
            </div>
            <div className='border border-warning'>
                {formatLog(value.meta)}
            </div>
        </MDBCol> */}
    </MDBRow >
}


const getColumns = () => {
    let columns = [{
        id: '_id',
        label: '',
        style: { width: '0', padding: 0 },
        labelStyle: { width: '0', padding: 0 },
        format: () => { }
    }, {
        id: 'createdAt', // From Logger
        label: 'Time',
        align: 'left',
        style: { width: '100px', padding: 0, fontSize: '0.7em' },
        format: (time) => <ThemeFormatTime timestamp={time} showFromNow></ThemeFormatTime>
    }, {
        id: 'userId',
        label: 'UserId',
        align: 'center',
        style: {
            maxWidth: '50px',
            overflow: 'hidden',
        },
        format: (userId) => <span className='cursor-pointer p-1 round'><span data-column='UserID' data-module='_SportLogs' className=''>{userId}</span></span>


    }, {
        id: 'type',
        label: 'Type',
        align: 'center',
        style: {
            maxWidth: '60px',
            overflow: 'hidden',
        },
        format: (type) => <span className={`text-${type == 'Debit' ? 'success' : 'info'}`}>{type}</span>

    }, {
        id: 'meta',
        label: 'OrderNumber',
        align: 'center',
        style: {
            maxWidth: '60px',
            overflow: 'hidden',
        },
        format: (meta) => <span className='text-decoration-underline cursor-pointer p-1 round text-shadow'><span data-column='OrderNumber' data-module='SportLogs' className=''>{meta.OrderNumber}</span></span>
    }, {
        id: 'meta',
        label: 'TransactionId',
        align: 'center',
        style: {
            maxWidth: '60px',
            overflow: 'hidden',
        },
        format: (meta) => <span className=''>{meta.TransactionId}</span>
    }, {
        id: 'meta',
        label: 'Bet Amount',
        align: 'right',
        labelAlign: 'right',
        style: {
            maxWidth: '60px',
            overflow: 'hidden',
        },
        format: (meta) => <span className=''>{meta.Order && meta.Order.Bets && meta.Order.Bets.length == '1' ? ('$ ' + numeral(meta.Order.Bets[0].BetAmount).format('0,0.00')) : '?'}</span>
    }, {
        id: 'meta',
        label: 'Possible Win',
        align: 'right',
        labelAlign: 'right',
        style: {
            maxWidth: '60px',
            overflow: 'hidden',
        },
        format: (meta) => <span className=''>{meta.PossibleWin ? '$ ' + numeral(meta.PossibleWin).format('0,0.00') : '?'}</span>
        // }, {
        //     id: 'currency',
        //     label: 'currency',
        //     align: 'center',
        //     style: {
        //         maxWidth: '60px',
        //         overflow: 'hidden',
        //     },
        //     format: (source) => <span className=''>{source}</span>
    }, {
        id: 'winAmount',
        label: 'WinAmount',
        align: 'right',
        labelAlign: 'right',
        format: (winAmount) => <span className=''>$ {numeral(winAmount).format('0,0.00')}</span>
    }, {
        id: 'meta',
        label: 'BetState',
        align: 'center',
        style: {
            maxWidth: '60px',
            overflow: 'hidden',
        },
        format: (meta) => {
            const state = betStateByCode(meta.BetState);
            return <span className={`text-${state == 'Won' ? 'success' : state == 'Lost' ? 'danger' : state == 'CashOut' ? 'info' : 'warning'}`}>{state}</span>
        }
    }, {
        id: 'all',
        label: 'P/L',
        align: 'center',
        style: {
            maxWidth: '60px',
            overflow: 'hidden',
        },
        format: (all) => formatPL(all)
    }, {
        id: 'tags',
        label: 'Tags',
        align: 'center',
        style: {
            maxWidth: '60px',
            overflow: 'hidden',
        },
        format: (tags) => {
            return [tags].map((tag, i) => <small key={i}>{tag != '' ? ',' : ''} {tag}</small>)
        }
    }, {
        id: 'deleted',
        label: 'Deleted',
        align: 'center',
        style: {
            maxWidth: '60px',
            overflow: 'hidden',
        },
        format: (deleted) => {
            return <span className={`text-${deleted ? 'danger' : 'white'}`}>{deleted ? <span>Deleted</span> : '-'}</span>
        }
    }, {
        id: 'status',
        label: 'Status',
        align: 'center',
        style: {
            maxWidth: '60px',
            overflow: 'hidden',
        },
        format: (status) => <span className=''>{status == 'new' ? 'OK' : status}</span>
    }, // Request / Response
    {
        id: 'meta',
        label: 'meta',
        collapse: true,
        align: 'left',
        style: {
            overflow: 'hidden',
            padding: '0 100px !important',
            whiteSpace: 'break-spaces !important'
        }, format: value => formatReqResMeta(value)
    }];
    return columns;
}

const SportsLogs = (props) => {
    const [state, dispatch] = useContext(Context);

    const [rows, setRows] = useState([]);
    const interval = useRef(null);
    const [updated, setUpdated] = useState(Date.now());
    const [filter, setfilter] = useState({
        find: {
            type: 'Credit',
            updatedAt: { $gt: Date.now() - (24 * 60 * 60 * 1000) },
            $and: [{

                $or: [
                    // {
                    //     userId: '100004'
                    // },
                    {
                        userId: '100066'
                    }
                ]
            }]
        },
        limit: 50,
        skip: 0
    })
    const [stream, setStream] = useState(true);
    useEffect(() => {
        jQinit();
        getSportLogs(state, { setRows, filter });
        interval.current = setInterval(() => {
            getSportLogs(state, { setRows, filter });
            setUpdated(Date.now())
        }, 3000);
        return () => {
            clearInterval(interval.current)
        }
    }, [])

    const startLogs = (f) => {
        clearInterval(interval.current);
        getSportLogs(state, { setRows, filter: f });
        interval.current = setInterval(() => {
            getSportLogs(state, { setRows, filter: f });
            setUpdated(Date.now())
        }, 3000);
    }

    useEffect(() => {
        clearInterval(interval.current);
        if (stream) {
            startLogs(filter)
        }
    }, [stream])

    return <>

        <MDBRow>
            <MDBCol className='pb-2 text-white'>
                <strong className='text-primary me-2'>Digitain Sport Logs</strong>
                <div className="d-inline-block">
                    <MDBIcon className='cursor-pointer ms-2' onClick={() => setStream(!stream)} icon={stream ? 'pause' : 'play'}></MDBIcon>
                </div>
                <div className='overflow-hidden d-inline-flex' >
                    <div className="heart-rate overflow-hidden">
                        <svg className='d-inline-block mt-n2' version="1.0" x="0px" y="0px" width="70px" height="25px" viewBox="0 0 150 73" enableBackground="new 0 0 150 73" >
                            <polyline fill="none" stroke="#26ddff" strokeWidth="3" strokeMiterlimit="10" points="0,45.486 38.514,45.486 44.595,33.324 50.676,45.486 57.771,45.486 62.838,55.622 71.959,9 80.067,63.729 84.122,45.486 97.297,45.486 103.379,40.419 110.473,45.486 150,45.486"
                            />
                        </svg>
                        {
                            stream ? <>
                                <div className="fade-in"></div>
                                <div className="fade-out"></div>
                            </> : <></>
                        }
                    </div>
                </div>
                <ThemeSelect onChange={e => console.log(e)} options={[
                    { text: '1', value: 1 },
                    { text: '2', value: 2 },
                    { text: '3', value: 3 },
                ]}></ThemeSelect>
            </MDBCol>
        </MDBRow>

        <ResponsiveTable collapsible rows={rows} columns={getColumns('')}></ResponsiveTable>
    </>
}

export default SportsLogs
