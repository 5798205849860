import { Alert, ClickAwayListener, Collapse, Grid, LinearProgress, Modal, Tooltip, Typography, Zoom } from '@mui/material';
import { Box } from '@mui/system';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard';
import ThemeButton from '../../layout/components/ThemeButton';
import ThemeFormatCurrency from '../../layout/components/ThemeFormatCurrency';
import UserQuickViewButton from '../Users/UserQuickViewButton';
import RenderBetAmount from './Renders/RenderBetAmount';
import RenderOdds from './Renders/RenderOdds';
import RenderPL from './Renders/RenderPL';
import RenderService from './Renders/RenderService';
import RenderType from './Renders/RenderType';
import RenderUserBalance from './Renders/RenderUserBalance';
import RenderWinAmount from './Renders/RenderWinAmount';
import CrashTXDetails from './TransactionDetails/CrashTXDetails';
import EveryMatrixTXDetails from './TransactionDetails/EveryMatrixTXDetails';
import ThemeFormatTime from '../../layout/components/ThemeFormatTime';
import BaccaratDetails from './TransactionDetails/EvolutionComponents/BaccaratDetails';
import axios from 'axios';
import { getBearer } from '../../helpers/publicHelper';
import ReactJson from 'react-json-view';
import TransactionIDs from './TransactionIDs';

const getTypeColor = (type, subtype) => {
    let color = 'white';
    if (type == 'deposit') color = 'success';
    if (type == 'withdraw') color = 'danger';
    if (type == 'bet') {
        if (subtype == 'won') color = 'success';
        if (subtype == 'lost') color = 'danger';
        if (subtype == 'cashback') color = 'warning';
        if (subtype == 'open') color = 'yellow';
        if (subtype == 'rollback') color = 'neon';
    };
    return color
}

const TransactionListMobile = (props) => {
    const { transactions } = props;
    const [selectedTransaction, setSelectedTransaction] = useState({})

    return (
        <Box sx={{width: '100%'}}>
            {transactions && transactions.map((tx, key) => <TransactionCard setSelectedTransaction={setSelectedTransaction} index={key} key={key} {...tx} />)}
            <DetailsModal {...selectedTransaction} />
        </Box>
    )
}

const TransactionCard = (props) => {
    const { index, userId, createdAt, service, currency, subtype, setSelectedTransaction, blockchain, type } = props;
    const [openAmounts, setOpenAmounts] = useState(false)

    const getMomentColor = (timestamp) => {
        const text = moment(timestamp).fromNow();
        const color = text.indexOf('seconds') > 1 ? 'neon' :
            text.indexOf('minute') > 1 ? 'success' :
                text.indexOf('hour') > 1 ? 'yellow' :
                    text.indexOf('day ') > 1 ? 'warning' :
                        text.indexOf('days') > 1 ? 'danger' : 'white';
        return color;
    }

    return <Grid container columns={20}
        className='justify-content-center align-items-center'
        sx={{
            width: '100%',
            overflow: 'hidden',
            ' > *': {
            },
            fontSize: '0.8rem',
            borderBottom: '1px solid #666',
            borderTop: !index ? '1px solid #666' : 'none',
            bgcolor: index % 2 ? '#222' : '#000',
        }}>
        <Grid item xs={3} className='ps-1 d-flex flex-column align-items-start justify-content-start'>
            <UserQuickViewButton userId={userId} />
            <Box sx={{ fontSize: '0.7rem' }} className={`text-${getMomentColor(createdAt)}`}>
                {moment(createdAt).fromNow().replace(' ago', '').replace(' minute', ' min')}
            </Box>
        </Grid>
        <Grid item xs={2} className='d-flex flex-column align-self-stretch align-items-center justify-content-around'>
            <RenderService service={service} />
            <ThemeFormatCurrency iconSize={1} blockchain={blockchain} currency={currency} textClassName='d-none' />
        </Grid>
        <Grid item xs={6}>
            <ClickAwayListener onClickAway={() => setOpenAmounts(false)}>
                <Tooltip
                    open={openAmounts}
                    TransitionComponent={Zoom}
                    PopperProps={{
                        sx: {
                            zIndex: 1
                        }
                    }}
                    classes={{ tooltip: 'bg-black box-shadow-neon p-0 m-0 w-100' }}
                    placement="bottom" title={
                        <Box className='text-center px-0 m-0'>
                            <Typography variant='body2' className='px-2 pb-2' component='div'>
                                {(type == 'deposit' || type == 'withdraw' || type == 'topup') ? <>
                                    <TooltipTitle title='Amount' />
                                    <Box className='d-flex justify-content-center w-100 align-items-center'>
                                        <Box className='pe-1'>
                                            <ThemeFormatCurrency iconSize={1} blockchain={blockchain} currency={currency} textClassName='d-none' />
                                        </Box>
                                        <Box>
                                            <RenderPL {...props} />
                                        </Box>
                                    </Box></> :
                                    <Box>
                                        <TooltipTitle title='Bet Amount' />
                                        <RenderBetAmount {...props} />
                                        <TooltipTitle title='Odds' />
                                        <RenderOdds {...props} />
                                        <TooltipTitle title='Win Amount' />
                                        <RenderWinAmount {...props} />
                                    </Box>
                                }
                                <TooltipTitle title='User Balance' />
                                <RenderUserBalance {...props} />
                            </Typography>
                        </Box>
                    }>
                    <Box className='cursor-pointer py-1 d-flex flex-column align-items-start'>
                        <Box onClick={() => setOpenAmounts(!openAmounts)} className='d-flex justify-content-start pb-1 align-items-center'>
                            <RenderType {...props} />
                            <small>
                                <RenderOdds {...props} />
                            </small>
                        </Box>
                        <Box onClick={() => setOpenAmounts(!openAmounts)} >
                            <RenderPL {...props} />
                        </Box>
                    </Box>
                </Tooltip>
            </ClickAwayListener>
        </Grid >
        <Grid item xs={8} className='d-flex flex-column '>
            <RenderUserBalance {...props} />
        </Grid>
        <Grid item xs={1} className='d-flex flex-column '>
            <MDBIcon onClick={() => setSelectedTransaction(props)} icon='list' className='text-neon text-shadow-neon' />
            {/* <TransactionDetails {...props} /> */}
        </Grid>
    </Grid >
}

const DetailsModal = (props) => {
    const { _id, service, type, subtype, userId, setSelectedTransaction } = props;
    let color = getTypeColor(type, subtype);
    let title = type == 'bet' ? 'Bet' : type;
    let subtitle = type == subtype ? '' : subtype;
    return <Modal
        open={_id ? true : false}
        disableScrollLock={true}
        onClose={() => setSelectedTransaction({})}>
        <Box className='box-shadow-neon bg-black rounded-6 overflow-scroll noScrollbar' sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            minHeight: '20vh',
            maxHeight: '80vh',
            width: ['90vw', '80vw', '50vw', '25vw']
        }}>
            <MDBRow className='m-0 bg-black py-2'>
                <MDBCol size='12' className={`d-flex justify-content-between text-uppercase fw-bold text-${color}`}>
                    <strong>
                        {title} {subtitle}
                    </strong>
                    <strong className='text-neon'>
                        <RenderService {...props} />
                    </strong>
                    <strong className='text-neon'>
                        {userId}
                    </strong>
                    {/* {service == 'Crash' && <CrashTXDetails {...props} />}

                    {service == 'EveryMatrix' && <EveryMatrixTXDetails {...props} />}

                    {(service == 'Evolution' || service == 'RedTiger' || service == 'Netent') && <EvolutionTXDetails {...props} />}

                    {service == 'Pragmatic' && <PragmaticTXDetails {...props} />}

                    {service == 'Digitain' && <DigitainTXDetails {...props} />}

                    {service == 'Telegram' && <TelegramTXDetails {...props} />} */}
                </MDBCol>
                <MDBCol size='12'>
                    <hr className='my-2' />
                    {/* <form className='' onSubmit={e => { e.preventDefault(); callEditTransaction(txValues) }} noValidate autoComplete="off">
                        <div className='text-end mt-3'>
                            <ThemeButton className='px-3' type='submit' variant='outlined' color={'success'}>Save</ThemeButton>
                        </div>
                    </form> */}
                </MDBCol>
            </MDBRow>
            {(type == 'deposit' || type == 'withdraw' || type == 'topup') && <FinancialDetails {...props} />}
            {(type == 'bet') && <BetDetails {...props} />}
        </Box>
    </Modal>
}

const FinancialDetails = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const { amount, blockchain, comments, createdAt, currency, isValid, meta, service, status, subtype, tags, txId, type, updatedAt, user, userId, _id } = props;
    let color = type == 'deposit' ? 'success' : type == 'withdraw' ? 'danger' : 'neon';
    if (subtype == 'rollback') color = 'warning';
    let severity = type == 'deposit' ? 'success' : 'error';
    if (subtype == 'rollback') severity = 'warning';
    return <Grid container justifyContent='center' alignItems='center'>
        <Grid item className={`text-center text-${color}`} xs={12}>
            <Typography variant='h5'>
                <ThemeFormatCurrency blockchain={blockchain} iconSize={2} currency={currency} text={amount} unit />
            </Typography>
        </Grid>
        <Grid item className={`text-center opacity-50 text-${color}`} xs={12}>
            <Typography variant='h6'>
                <ThemeFormatCurrency icon={false} currency={currency} text={amount} inUSD />
            </Typography>
        </Grid>
        {(status == 'verified' && subtype !== 'rollback') ?
            <>
                {
                    type != 'topup' && meta.transactionId && <>
                        <hr />
                        <Grid item className='text-center' xs={11}>
                            <Typography className='opacity-50' variant='subtitle2'>
                                Transaction ID (Hash)
                            </Typography>
                            <Typography className='text-break' variant='subtitle1'>
                                {meta.transactionId}
                            </Typography>
                            <Box className='d-flex justify-content-around py-2'>
                                <CopyToClipboard
                                    onCopy={() => { enqueueSnackbar('Copied.', { variant: 'warning', autoHideDuration: 2500 }) }}
                                    text={meta.transactionId}>
                                    <ThemeButton variant='outlined' color='warning' className='px-2'><MDBIcon className='text-warning pe-1 cursor-pointer' icon='copy'></MDBIcon> Copy</ThemeButton>
                                </CopyToClipboard>
                            </Box>
                            <Box className='d-flex justify-content-around py-3'>
                                <ThemeFormatCurrency buttonProps={{ size: '' }} icon={false} text='View in Blockchain' value={meta.transactionId} mode='transaction' blockchain={blockchain} currency={currency} lineBreak></ThemeFormatCurrency>
                            </Box>
                        </Grid>
                        <Grid item className='opacity-50' xs={12}>
                            <hr />
                        </Grid>
                    </>
                }
            </>
            :
            <Alert variant="outlined" severity={severity}>This transaction has been {subtype == 'rollback' ? 'rollbacked' : 'rejected'}!</Alert>

        }
        {
            comments && comments.length && <Grid item className='small text-center my-2' xs={12}>
                {comments.map((comment, i) => <Comment key={i} {...comment} />)}
            </Grid>
        }
        <Grid item className='small text-center' xs={12}>
            <ThemeFormatTime timestamp={createdAt} showFromNow />
        </Grid>
        <Grid item className='opacity-50' xs={12}>
            <hr />
        </Grid>
        <Grid item xs={12} className='d-flex justify-content-around align-items-center text-center'>
            <Box>
                <Typography className='opacity-50' variant='subtitle1'>
                    Is Valid
                </Typography>
                <MDBIcon className={`text-${isValid ? 'success' : 'white bg-danger'}`} icon={isValid ? 'check' : 'times'} />
            </Box>
            <Box>
                <Typography className='opacity-50' variant='subtitle1'>
                    Status
                </Typography>
                <Typography variant='subtitle2' className={`px-3 py-1 rounded-2 text-uppercase fw-bold text-${status == 'verified' ? 'success' : 'white bg-danger'}`}>
                    {status}
                </Typography>
            </Box>
            {meta.result &&
                <Box>
                    <Typography className='opacity-50' variant='subtitle1'>
                        Result
                    </Typography>
                    <Typography variant='subtitle2' className={`px-3 py-1 rounded-2 text-uppercase fw-bold text-${status == 'verified' ? 'success' : 'white bg-danger'}`}>
                        {meta.result}
                    </Typography>
                </Box>
            }
        </Grid>
    </Grid>

}

const Comment = (props) => {
    let { by, text, createdAt } = props;
    by = by || {
        role: '', telegram: '', userId: '', username: ''
    }
    let { role, telegram, userId, username } = by;
    telegram = telegram || {
        first_name: '',
        id: '',
        is_bot: false,
        is_premium: true,
        language_code: '',
        username: ''
    }
    return <Box className='p-2 text-start d-flex flex-column text-gray small' sx={{
        bgcolor: '#fff1',
        borderBottom: '1px solid #333'
    }}>
        <Box>
            By: <UserQuickViewButton userId={userId} /> {username}
        </Box>
        <Box>
            Text: {text || '-'}
        </Box>
    </Box>
}


const BetDetails = (props) => {
    const { service } = props;
    return <Box>
        {service == 'Evolution' && <EvolutionBetDetails {...props} />}
        {service == 'Pragmatic' && <PragmaticBetDetails {...props} />}
        {service == 'Crash' && <CrashTXDetails {...props} />}
        {service == 'EveryMatrix' && <EveryMatrixTXDetails {...props} />}
        < hr />
        <Box className='px-2'>
            <TransactionIDs {...props} />
        </Box>
    </Box>

}

const EvolutionBetDetails = (props) => {
    const { meta, service, type, blockchain, currency, txId, userId, _id } = props;
    const [values, setValues] = useState({
        result: '',
        title: ''
    })
    const title = meta.bet.details[0].title;
    const callDetails = () => {
        axios.post(process.env.REACT_APP_API_HOST + `/admin/games/details`,
            {
                userId,
                provider: service,
                apiName: 'gamehistory',
                bet: meta.bet,
            },
            {
                headers: { 'x-auth-token': getBearer() }
            })
            .then(res => {
                res = res.data;
                setValues({
                    result: res.result,
                    title
                })
            })
    }

    useEffect(() => {
        callDetails()
        return () => {
        }
    }, [])


    return <Box>
        {!values.title && <LinearProgress color="info" />}
        <Collapse in={values.title ? true : false}>
            <Box>
                {values.title == 'baccarat' && <BaccaratDetails gameDetails={values.result} />}
                {values.title !== 'baccarat' && <Box>
                    <Typography className='text-center' variant='h6'>{values.title}</Typography>
                    <Box className='text-center'>
                        <TooltipTitle title='Bet Amount' />
                        <RenderBetAmount {...props} />
                        <TooltipTitle title='Odds' />
                        <RenderOdds {...props} />
                        <TooltipTitle title='Win Amount' />
                        <RenderWinAmount {...props} />
                    </Box>
                    <Box sx={{ fontSize: '0.7rem' }}>
                        <ReactJson collapsed name={null} style={{ padding: '0.5rem' }} theme="monokai" src={values.result.data} />
                    </Box>
                </Box>}
            </Box>
        </Collapse>
    </Box>
}

const PragmaticBetDetails = (props) => {
    const { meta, service, type, blockchain, currency, txId, userId, _id } = props;
    const [values, setValues] = useState({
        html: '',
        title: '',
        url: ''
    })
    const details = meta.bet.details[0] || {}
    const gameId = meta && meta.bet && meta.bet.details && meta.bet.details[0] && meta.bet.details[0].id;
    const title = meta.bet.details[0].title;
    const callDetails = (e) => {
        axios.post(process.env.REACT_APP_API_HOST + `/admin/games/details`,
            {
                userId,
                provider: service,
                apiName: 'openHistoryExtended',
                bet: meta.bet,
            },
            {
                headers: { 'x-auth-token': getBearer() }
            })
            .then(res => {
                res = res.data;
                if (res.success) {
                    const url = res.result.url;
                    setValues({
                        html: res.result,
                        url: url,
                        title
                    })
                }
            })
    }

    useEffect(() => {
        callDetails()
        return () => {
        }
    }, [])

    return <Box>
        <Box>
            {values.html && <iframe style={{
                width: '100%',
                height: '40vh',
            }}
                src={values.url}
            // srcdoc={values.html}
            ></iframe>}
        </Box>
        <Box className='p-3' sx={{ fontSize: '0.6rem' }}>
            <ReactJson name={null} style={{ padding: '0.5rem' }} theme="monokai" src={values.html} />
        </Box>
    </Box>
}

const TooltipTitle = (props) => {
    const { title } = props;
    return <Box className='pt-2 text-white opacity-60 small text-center'>
        {title}
    </Box>

}

export default TransactionListMobile