import { MDBIcon } from 'mdb-react-ui-kit';
import React from 'react'
import _ from 'lodash';
import { Box } from '@mui/system';
import DiscountIcon from '@mui/icons-material/Discount';
import { DateRange } from '@mui/icons-material';

const RenderType = (props) => {
  const { type, status, subtype, isValid } = props;
  let cls = '',
    icon1 = '', // status == rejected
    icon2 = '', // isValid == false
    icon3 = '', // subtype
    text = type;

  switch (status) {
    case 'rejected':
      icon1 = <MDBIcon title='status: rejected' className='text-danger' icon='times-circle' />;
      break;
    case 'pending':
      icon1 = <MDBIcon title='status: pending' spin className='text-yellow' icon='sync' />;
      break;
    case 'verified':
      icon1 = '';//<MDBIcon className='text-danger' icon='times-circle' />;
      break;
    case 'rollback':
      icon1 = <MDBIcon title='status: rollback' style={{ padding: '0.1rem' }} className={`bg-danger text-black rounded-2`} icon={'sync'} />
      break;
    default:
      icon1 = 'Invalid "status' + status
      break;
  }

  switch (isValid) {
    case false:
      icon2 = <MDBIcon title='invalid' className='text-danger' icon='times' />;
      break;
    case true:
      icon2 = '';//<MDBIcon className='text-danger' icon='times-circle' />;
      break;
    default:
      icon2 = 'Invalid "isValid'
      break;
  }

  switch (subtype) {
    case 'bonus':
      icon3 = <MDBIcon className='text-yellow' icon='gift' />
      break;
    case 'deposit':
      icon3 = <MDBIcon className='text-success' icon='download' />
      break;
    case 'withdraw':
      icon3 = <MDBIcon className='text-danger' icon='upload' />
      break;
    case 'topup':
      icon3 = <MDBIcon className='text-success text-shadow-success' icon='donate' />
      break;
    case 'discount':
      icon3 = <DiscountIcon sx={{ fontSize: '0.8rem' }} color='danger' />
      break;
    case 'dailyDiscount':
      icon3 = <DiscountIcon sx={{ fontSize: '0.8rem' }} color='neon' />
      break;
    case 'weeklyDiscount':
      icon3 = <DateRange sx={{ fontSize: '1rem' }} color='neon' />
      break;
    case 'rollback':
      cls = `text-black bg-danger`;
      break;
    case 'rejected':
      icon3 = <MDBIcon title='SubType Rejected' className='text-danger' icon='times-circle' />
      cls = `text-black bg-danger`;
      break;
    case 'won':
      icon3 = '';
      cls = `text-success box-shadow-success`;
      break;
    case 'lost':
      icon3 = '';
      cls = `text-danger box-shadow-danger`;
      break;
    case 'cashback':
      icon3 = '';
      cls = `text-orange box-shadow-orange`;
      break;
    case 'open':
      icon3 = '';
      cls = `text-yellow box-shadow-yellow`;
      break;
    case 'ref':
      icon3 = '';
      cls = `text-teal box-shadow-teal`;
      break;
    default:
      icon3 = <MDBIcon title={`invalid subtype ${subtype}`} className={`bg-warning text-black p-1 rounded-3`} icon={'exclamation'} />
      break;
  }

  if (type == 'deposit') {
    cls = 'bg-success box-shadow-success text-white';
    if (subtype == 'deposit') icon3 = '';
  }

  if (type == 'withdraw' && subtype != 'rollback') {
    cls = 'bg-danger box-shadow-danger text-white';
    icon3 = '';
  } else if (type == 'withdraw' && subtype == 'rollback') {
    text = <span>Withdraw <small><small>Rollback</small></small></span>;
  }

  if (type == 'withdraw' && status == 'pending' && subtype == 'withdraw') {
    cls = 'bg-yellow box-shadow-yellow text-black';
    text = <span>Withdraw <small className={``}>Pending</small></span>;
  }

  if (type == 'bet') {
    text = ((subtype == 'rollback' || subtype == 'rejected') ? 'Bet ' : '') + subtype;
  }

  if (type == 'topup') {
    const color = subtype == 'bonus' ? 'yellow' : subtype == 'deposit' ? 'success' : subtype == 'withdraw' ? 'danger' : 'neon';
    cls = `-text-black bg-info`;
    const subname = (subtype.indexOf('weekly') || subtype.indexOf('daily')) ? subtype.replace('Discount', '') : subtype;
    text = <span>Topup <small className={``}>{subname}</small></span>;
    // icon1 = <MDBIcon className={`text-${color}`} icon={icon} />
  }

  if (subtype == 'rollback') icon3 = <MDBIcon title='Rollbacked' style={{ padding: '0.1rem' }} className={`bg-danger text-black rounded-2`} icon={'sync'} />;

  return <Box className='d-flex align-items-center'>
    <Box sx={{ lineHeight: '1.1rem', minWidth: ['4rem', '5rem'] }} className={`${cls} fw-bold small d-block text-capitalize px-1 rounded-1 text-center ms-md-1 me-1`}>
      {text}
    </Box>
    {icon1}
    {icon2}
    {icon3}
  </Box>
}

export default RenderType