import React from 'react';
import numeral from 'numeral';
import _ from 'lodash';
import ThemeFormatCurrency from '../../../layout/components/ThemeFormatCurrency';
import { getColorByBetState } from '../../../helpers/adminHelpers';
import { currencyDetail } from '../../../helpers/currency';

const RenderBetAmount = (props) => {
    const { amount, currency, blockchain, type, service, meta } = props;

    const icon = <ThemeFormatCurrency icon='png' iconSize={1} blockchain={blockchain} currency={currency} hideText />;
    // return icon;
    // Version 2.0
    if (_.has(meta, 'bet.betAmount')) {
        const { betAmountInUSD, betState } = meta.bet;
        const color = getColorByBetState(betState);
        return <div className='d-flex justify-content-center justify-content-md-between'>
            {icon}
            <span className={`text-capitalize border-bottom border-${color} text-${color}`}>
                <span style={{ marginRight: '2px' }}>$</span>
                {numeral(betAmountInUSD).format(currencyDetail['USD'].format)}
            </span>
        </div>
    } else {
        return <></>
    }

    // let betAmount = 0;

    // if ((service == 'Evolution' || service == 'RedTiger' || service == 'Netent') && meta) {
    //     const { relatedEvoTransactions } = meta;
    //     if (!relatedEvoTransactions) return <span>?</span>
    //     if (relatedEvoTransactions.length != 1) return <span className='bg-danger'>Multi</span>
    //     betAmount = relatedEvoTransactions[0].transaction ? relatedEvoTransactions[0].transaction.amount : '0';
    // }

    // if (service == 'Digitain' && meta) {
    //     const { relatedSportTransactions } = meta;
    //     if (!relatedSportTransactions) return <span>?</span>
    //     if (relatedSportTransactions.length !== 1) return <span>??</span>
    //     if (relatedSportTransactions[0].meta) {
    //         betAmount = relatedSportTransactions[0].meta.Order.Bets[0].BetAmount;
    //     }
    // }

    // if (service == 'CryptoPay-API' ||
    //     type == 'topup' ||
    //     type == 'topupBonus' ||
    //     type == 'withdraw' ||
    //     type == 'withdrawRollback'
    // ) {
    //     return <div className='d-flex justify-content-start'>
    //         {icon}
    //     </div>
    // }

    // return <div className='d-flex justify-content-between'>
    //     {icon}
    //     <span className={`text-capitalize border-bottom border-success text-success`}>
    //         <span style={{ marginRight: '2px' }}>$</span>
    //         {numeral(betAmount).format(currencyDetail[currency].format)}
    //     </span>
    // </div>
}

export default RenderBetAmount