import axios from 'axios';
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../context/store';
import { getBearer } from '../../helpers/publicHelper';
import ThemeInput from '../../layout/components/ThemeInput';
import ThemeMultiSelect from '../../layout/components/ThemeMultiSelect';
import { serviceFinancialStats } from './aggregateHelper';

const getAggregate = (state, values, setValues) => {
    axios.post(state.active.host + '/superadmin/aggregate',
        {
            collection: values.collection,
            aggregate: values.aggregate
        },
        {
            headers: { 'x-auth-token': getBearer() }
        })
        .then(res => {
            res = res.data;
            if (res.success) {
                setValues({ ...values, result: res.result })
            } else {

            }
        })
        .catch(err => {
            console.log(err)
        })
}

const Aggregate = () => {
    const [state, dispatch] = useContext(Context);

    const [values, setValues] = useState({
        services: ['Digitain']
    })

    const [apiValues, setApiValues] = useState({
        collection: 'transaction',
        aggregate: serviceFinancialStats({
            dateFrom: new Date('15 Jan 2022 00:00:00 GMT').getTime(),
            dateTo: new Date('25 Jan 2022 00:00:00 GMT').getTime(),
            service: { $in: ['Digitain', 'Evolution'] }
        }),
        result: []
    })

    useEffect(() => {
        getAggregate(state, apiValues, setApiValues);
    }, [])

    return (

        <MDBRow className='m-0'>
            <MDBCol size='12'>
                <h6>Aggregate</h6>
                <br />
                {apiValues.result.length}
            </MDBCol>
            <MDBCol size='3'>
                <ThemeMultiSelect maxShow={3} label='Services' setValue={e => setValues({ ...values, services: e })} value={values.services} options={['Digitain', 'Evolution', 'Netent', 'RedTiger', 'EveryMatrix']}></ThemeMultiSelect>
            </MDBCol>
            <MDBCol size='3'>
                <ThemeInput type='search' label='Wallet Address' value={'s'}></ThemeInput>
            </MDBCol>
        </MDBRow>
    )
}

export default Aggregate
