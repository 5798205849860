import React, { useState, useEffect, useContext } from 'react';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import { Box, Stack, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import { currencyDetail, currencyIcon } from '../../helpers/currency';
import numeral from 'numeral';
import { Context } from '../../context/store';
import ThemeInput from '../../layout/components/ThemeInput';
import ThemeButton from '../../layout/components/ThemeButton';
import { getBearer } from '../../helpers/publicHelper';
import { useSnackbar } from 'notistack';
import $ from 'jquery';
import ThemeSelect from '../../layout/components/ThemeSelect';

const UserTopupModal = (props) => {
    const { setTopupModal, topupModal, setRows, getUsersFromAPI, filter } = props;
    const [state, dispatch] = useContext(Context);
    const { enqueueSnackbar } = useSnackbar();
    const { selectedUser, open } = topupModal;
    const handleClose = () => {
        setTopupModal({ ...topupModal, open: false });
    }

    const [values, setValues] = useState({
        subtype: 'discount',
        amount: 0,
        comment: '',
        currency: 'USD'
    })

    useEffect(() => {
        setValues({
            // bonus: 0,
            subtype: 'discount',
            amount: 0,
            comment: '',
            currency: 'USD'
        })
    }, [topupModal.selectedUser])

    const topUp = (user, values, handleClose, setRows) => {
        var postData = {
            _id: user._id,
            amount: parseFloat(values.amount),
            // bonus: parseFloat(values.bonus),
            subtype: values.subtype,
            comment: values.comment,
            currency: values.currency
        };
        axios.post(state.active.host + '/admin/users/topup', postData, {
            headers: { 'x-auth-token': getBearer() }
        })
            .then(res => {
                res = res.data;
                enqueueSnackbar(res.message,
                    {
                        variant: res.success ? 'success' : 'error',
                        autoHideDuration: 2500
                    })
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar('Error!', { variant: 'error', autoHideDuration: 2500 })
            }).then(() => {
                getUsersFromAPI(filter);
                handleClose();
            })
    }

    return <Modal
        open={open}
        disableScrollLock={true}
        onClose={handleClose}
    >
        <Box className='box-shadow-neon rounded-6 overflow-hidden' sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
        }}>
            <MDBRow className='m-0 bg-black py-2'>
                <MDBCol size='12'>
                    <Typography className='text-neon' variant='body2'>
                        <strong className='text-neon'>TopUp Player</strong>
                        <MDBIcon onClick={handleClose} className='float-end pt-1 cursor-pointer' icon='times'></MDBIcon>
                    </Typography>
                    <hr className='my-2' />
                    <Typography variant='body2' className='px-2'>
                        <strong className='text-neon'>Userame: </strong>
                        <span className='text-neon'>{selectedUser.username}</span>
                    </Typography>
                    <Typography variant='body2'>
                        <strong className='text-neon'>User Id: </strong>
                        <span className='text-neon'>{selectedUser.userId}</span>
                    </Typography>
                    <hr className='my-2' />
                </MDBCol>
                <MDBCol size='12'>
                    <Stack
                        direction="row"
                        className='p-1'
                        spacing={1}
                    >
                        {
                            state.config.currencyList.map((currency, i) =>
                                <img key={i} className={`cursor-pointer ${currency == values.currency ? '' : 'opacity-40'}`} onClick={() => setValues({ ...values, currency: currency })} style={{ width: '1.5rem' }} src={currencyIcon(currency, 'png')} />
                            )
                        }
                    </Stack>
                </MDBCol>
                <MDBCol size='12'>
                    <Typography variant='subtitle1'>
                        Current Balance:
                        <span className='text-warning ps-2'>
                            {(values.currency == 'USD' || values.currency == 'USDT') ? '$' : ''}
                            {numeral(selectedUser.wallet[values.currency].balance).format(currencyDetail[values.currency].format)}
                            {(values.currency == 'USD' || values.currency == 'USDT') ? '' : <small className='ms-1 fw-bold'>{currencyDetail[values.currency].symbol}</small>}

                            <ThemeButton
                                size='small'
                                className='px-1 ms-2'
                                variant='outlined'
                                color='warning'
                                onClick={() => setValues({ ...values, amount: -parseFloat(numeral(selectedUser.wallet[values.currency].balance).format(currencyDetail[values.currency].format)) })}
                                disabled={selectedUser.wallet[values.currency].balance == 0}>All</ThemeButton>
                        </span>
                    </Typography>
                </MDBCol>
                <MDBCol size='12'>
                    <Typography variant='body2'>
                        After Topup:
                        <span className={`text-${parseFloat(values.amount) > 0 ? 'success' : parseFloat(values.amount) < 0 ? 'danger' : ''} ps-2`}>
                            {(values.currency == 'USD' || values.currency == 'USDT') ? '$' : ''}
                            {/* {parseFloat(selectedUser.wallet[values.currency].balance) + parseFloat(values.amount)} + */}
                            {numeral(parseFloat(selectedUser.wallet[values.currency].balance) + parseFloat(values.amount)).format(currencyDetail[values.currency].format)}
                            {(values.currency == 'USD' || values.currency == 'USDT') ? '' : <small className='ms-1 fw-bold'>{currencyDetail[values.currency].symbol}</small>}
                        </span>
                    </Typography>
                </MDBCol>


                <form className='mt-2' onSubmit={e => { e.preventDefault(); topUp(selectedUser, values, handleClose, setRows); }} noValidate autoComplete="off">
                    <Box>
                        <ThemeInput
                            label='Amount'
                            type='number'
                            value={values.amount}
                            placeholder='0'
                            className='mt-2'
                            onChange={e => setValues({ ...values, amount: e.currentTarget.value })}>
                        </ThemeInput>
                    </Box>
                    <Box>

                        {/* <ThemeInput
                        label='Bonus'
                        type='number'
                        value={values.bonus}
                        placeholder='0'
                        className='mt-2'
                        onChange={e => setValues({ ...values, bonus: e.currentTarget.value })}>
                    </ThemeInput> */}
                        <ThemeSelect className='mt-3' value={values.subtype} label='Topup Type' onChange={e => setValues({ ...values, subtype: e.target.value.trim() })}>
                            {/* <option value='discount'>Discount</option> */}
                            <option value='dailyDiscount'>Daily Discount</option>
                            <option value='weeklyDiscount'>Weekly Discount</option>
                            <option value='bonus'>Bonus</option>
                            <option value='deposit'>Deposit</option>
                            <option value='withdraw'>Withdraw</option>
                        </ThemeSelect>
                    </Box>
                    <Box>

                        <ThemeInput
                            label='Comment'
                            placeholder='Type your commente here ...'
                            multiline
                            rows={4}
                            type='text'
                            className='mt-3'
                            value={values.comment}
                            onChange={e => setValues({ ...values, comment: e.currentTarget.value })}
                        ></ThemeInput>
                    </Box>

                    <div className='text-end mt-3'>
                        <ThemeButton
                            onClick={e => { console.log(e.target); $(e.target).hide() }}
                            className='px-3'
                            type='submit'
                            variant='outlined' color={values.amount < 0 ? 'danger' : 'success'} disabled={values.amount == 0}>{values.amount < 0 ? 'Decrease' : 'Increase'}</ThemeButton>
                    </div>
                </form>
            </MDBRow>
        </Box>
    </Modal>
}

export default UserTopupModal
