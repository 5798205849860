import React, { useEffect, useState, useContext } from 'react';
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import axios from 'axios';
import _ from 'lodash';
import { Context } from '../../context/store';
import { convertByRates, getBearer, getCryptoColor } from '../../helpers/publicHelper';
import ThemeFormatCurrency from '../../layout/components/ThemeFormatCurrency';
import { currencyDetail, currencyFormat } from '../../helpers/currency';
import ThemeChartAndDataCard from '../../layout/components/ThemeChartAndDataCard';
import numeral from 'numeral';
import moment from 'moment';
import QRCode from 'react-qr-code';

const TreasuryWallets = (props) => {
    const [state, dispatch] = useContext(Context);
    const [values, setValues] = useState({
        open: false
    })
    const [chartData, setChartData] = useState({
        ready: false
    });

    const getUsersWalletTotalBalance = () => {
        axios.post(state.active.host + '/accountant/treasury/details',
            {},
            {
                headers: {
                    'x-auth-token': getBearer()
                }
            })
            .then(res => {
                res = res.data;
                if (res.success) {
                    const report = res.result;
                    const totalBalanceInUSD =
                        convertByRates(state, report.BTC.blockchainBalance, 'BTC', 'USD') +
                        convertByRates(state, report.ETH.blockchainBalance, 'ETH', 'USD') +
                        convertByRates(state, report.TRX.blockchainBalance, 'TRX', 'USD') +
                        convertByRates(state, report.DOGE.blockchainBalance, 'DOGE', 'USD') +
                        convertByRates(state, report.TRX.blockchainBalanceUSDT, 'USDT', 'USD') +
                        convertByRates(state, report.ETH.blockchainBalanceUSDT, 'USDT', 'USD');
                    setChartData({
                        ready: true,
                        title: 'Treasury',
                        secondaryTitles:
                            [
                                {
                                    text: 'Total Balance',
                                    amount: numeral(totalBalanceInUSD).format('$0,0.00'),
                                    className: 'text-success',
                                }
                            ],
                        centerChart:
                        {
                            title: 'Total Balance',
                            amount: numeral(totalBalanceInUSD).format('($0.00a)'),
                            className: 'bg-success'
                        },
                        dataTitles: ['Type / Last Update', '$ Amount (Crypto)'],
                        data: [{
                            title: <span className='cursor-pointer'><ThemeFormatCurrency hideText currency='BTC'></ThemeFormatCurrency> BTC
                                <small className='opacity-70 ps-1'>{moment().diff(report.BTC.blockchainBalanceUpdatedAt, 'minutes')}s ago</small></span>,
                            color: getCryptoColor('BTC'),
                            chartTitle: 'BTC',
                            chartAmount: convertByRates(state, report.BTC.blockchainBalance, 'BTC', 'USD'),
                            amount1: numeral(convertByRates(state, report.BTC.blockchainBalance, 'BTC', 'USD')).format('$0,0.00'),
                            amount2: numeral(report.BTC.blockchainBalance).format(currencyDetail['BTC'].format),
                            collapse: <DepositSection report={report} currency='BTC' />
                        },
                        {
                            title: <span className='cursor-pointer'><ThemeFormatCurrency hideText currency='ETH'></ThemeFormatCurrency> ETH
                                <small className='opacity-70 ps-1'>{moment().diff(report.ETH.blockchainBalanceUpdatedAt, 'minutes')}s ago</small></span>,
                            color: getCryptoColor('ETH'),
                            chartTitle: 'ETH',
                            chartAmount: convertByRates(state, report.ETH.blockchainBalance, 'ETH', 'USD'),
                            amount1: numeral(convertByRates(state, report.ETH.blockchainBalance, 'ETH', 'USD')).format('$0,0.00'),
                            amount2: numeral(report.ETH.blockchainBalance).format(currencyDetail['ETH'].format),
                            collapse: <DepositSection report={report} currency='ETH' />
                        },
                        {
                            title: <span className='cursor-pointer'><ThemeFormatCurrency hideText currency='TRX'></ThemeFormatCurrency> TRX
                                <small className='opacity-70 ps-1'>{moment().diff(report.TRX.blockchainBalanceUpdatedAt, 'minutes')}s ago</small></span>,
                            color: getCryptoColor('TRX'),
                            chartTitle: 'TRX',
                            chartAmount: convertByRates(state, report.TRX.blockchainBalance, 'TRX', 'USD'),
                            amount1: numeral(convertByRates(state, report.TRX.blockchainBalance, 'TRX', 'USD')).format('$0,0.00'),
                            amount2: numeral(report.TRX.blockchainBalance).format(currencyDetail['TRX'].format),
                            collapse: <DepositSection report={report} currency='TRX' />
                        },
                        {
                            title: <span className='cursor-pointer'><ThemeFormatCurrency hideText currency='DOGE'></ThemeFormatCurrency> DOGE
                                <small className='opacity-70 ps-1'>{moment().diff(report.DOGE.blockchainBalanceUpdatedAt, 'minutes')}s ago</small></span>,
                            color: getCryptoColor('DOGE'),
                            chartTitle: 'DOGE',
                            chartAmount: convertByRates(state, report.DOGE.blockchainBalance, 'DOGE', 'USD'),
                            amount1: numeral(convertByRates(state, report.DOGE.blockchainBalance, 'DOGE', 'USD')).format('$0,0.00'),
                            amount2: numeral(report.DOGE.blockchainBalance).format(currencyDetail['DOGE'].format),
                            collapse: <DepositSection report={report} currency='DOGE' />
                        },
                        {
                            title: <span className='cursor-pointer'><ThemeFormatCurrency hideText currency='USDT' blockchain='TRX'></ThemeFormatCurrency> USDT
                                <small className='opacity-70 ps-1'>{moment().diff(report.TRX.blockchainBalanceUpdatedAt, 'minutes')}s ago</small></span>,
                            color: getCryptoColor('USDT'),
                            chartTitle: 'USDT TRC-20',
                            chartAmount: convertByRates(state, report.TRX.blockchainBalanceUSDT, 'USDT', 'USD'),
                            amount1: numeral(convertByRates(state, report.TRX.blockchainBalanceUSDT, 'USDT', 'USD')).format('$0,0.00'),
                            amount2: numeral(report.TRX.blockchainBalanceUSDT).format(currencyDetail['USDT'].format),
                            collapse: <DepositSection report={report} currency='TRX' />
                        },
                        {
                            title: <span className='cursor-pointer'><ThemeFormatCurrency hideText currency='USDT' blockchain='ETH'></ThemeFormatCurrency> USDT
                                <small className='opacity-70 ps-1'>{moment().diff(report.ETH.blockchainBalanceUpdatedAt, 'minutes')}m ago</small></span>,
                            color: '#0086b9',
                            chartTitle: 'USDT ERC-20',
                            chartAmount: convertByRates(state, report.ETH.blockchainBalanceUSDT, 'USDT', 'USD'),
                            amount1: numeral(convertByRates(state, report.ETH.blockchainBalanceUSDT, 'USDT', 'USD')).format('$0,0.00'),
                            amount2: numeral(report.ETH.blockchainBalanceUSDT).format(currencyDetail['USDT'].format),
                            collapse: <DepositSection report={report} currency='ETH' />
                        }]
                    })
                } else {

                }
            })
            .catch((err) => {

            })
    }

    useEffect(() => {
        getUsersWalletTotalBalance();
        return () => { }
    }, [state.rates, values.open])


    return <ThemeChartAndDataCard {...chartData}></ThemeChartAndDataCard>
}

const DepositSection = (props) => {
    const { report, currency } = props;
    const details = report[currency];
    const { address } = details;
    return <MDBRow className='m-0'>
        <MDBCol size='12' className='text-center p-0 small'>
            <div className='d-inline-block p-1 rounded-2 m-2' style={{
                border: `1px solid ${getCryptoColor(currency)}99`
            }}>
                <QRCode size={100} level='L' value={address} />
            </div>
            {currency == 'TRX' && <TRXWalletDetails blockchainBalanceFullData={details.blockchainBalanceFullData} />}
            <ThemeFormatCurrency iconClassName='me-1' mode='address' text='View on Blockchain' value={address} currency={currency}></ThemeFormatCurrency>
        </MDBCol>
    </MDBRow>
}


const TRXWalletDetails = (props) => {
    const { accountResource, balance, bandwidth } = props.blockchainBalanceFullData;
    const { expire_time, frozen_balance } = accountResource.frozen_balance_for_energy;
    const { energyLimit, energyPercentage, energyRemaining, energyUsed, freeNetLimit, freeNetPercentage, freeNetRemaining, netUsed, freeNetUsed, netLimit, netPercentage, netRemaining } = bandwidth;
    return <>
        <MDBRow className=''>
            <MDBCol size='6' className='text-start'>Balance</MDBCol>
            <MDBCol size='6' className='text-end'>{numeral(balance / 1000000).format(currencyFormat('TRX'))}</MDBCol>
        </MDBRow>
        <MDBRow>
            <MDBCol size='6' className='text-start'>Bandwidth</MDBCol>
            <MDBCol size='6' className='text-end'>{freeNetUsed + netUsed} / {freeNetLimit + netLimit}</MDBCol>
        </MDBRow>
        <MDBRow>
            <MDBCol size='6' className='text-start'>Energy</MDBCol>
            <MDBCol size='6' className='text-end'>{energyRemaining} / {energyLimit}</MDBCol>
        </MDBRow>
    </>
}

export default TreasuryWallets