import React, { useContext, useEffect, useState } from 'react';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import moment from 'moment';
import axios from 'axios';
import { getBearer } from '../../helpers/publicHelper';
import numeral from 'numeral';
import FinancialReportChart from './FinancialReportChart';
import { Collapse } from '@mui/material';
import _ from 'lodash';
import { Context } from '../../context/store';

const getColorClass = (value) => {
    return value == 0 ? 'gray' : value > 0 ? 'success' : 'danger'
}


const momentFormatBytimeframe = (timeframe, mode) => {
    if (mode == 'item') {
        // return 'MMMM/DD HH:mm'
        // if (timeframe == 'minute') return 'HH:mm';
        if (timeframe == 'hour') return 'HH:mm';
        if (timeframe == 'day') return 'HH:00';
        if (timeframe == 'month') return 'MMM DD';
        if (timeframe == 'year') return 'MMMM';
        if (timeframe == 'all') return 'YYYY';

    }
    if (mode == 'title') {
        if (timeframe == 'minute') return 'YYYY MMMM DD';
        if (timeframe == 'hour') return 'YYYY MMMM DD';
        if (timeframe == 'day') return 'YYYY MMMM';
        if (timeframe == 'month') return 'YYYY';
        if (timeframe == 'year') return 'YYYY';
    }
    return 'YYYY-MM-DD'
}

const FinancialReport = (props) => {
    const [state, dispatch] = useContext(Context);

    const timeframe = props.timeframe || 'month';
    const [data, setData] = useState({
        count: 0,
        report: [],
        totalReport: {}
    })
    const [filters, setFilters] = useState({
        find: {
            timeframe,
            dateFrom: { $gte: moment('2022-01-01').get() },
            dateTo: { $lte: moment('2022-02-02').get() },
        },
        sort: { dateFrom: 1 },
    })

    const getReport = (setData, filters) => {
        const all = filters.find.timeframe == 'all';
        setData({
            count: 0,
            report: [],
            totalReport: {}
        });
        axios.post(state.active.host + '/admin/report',
            {
                find: { ...filters.find, timeframe: all ? 'year' : filters.find.timeframe },
                sort: filters.sort
            },
            {
                headers: {
                    'x-auth-token': getBearer()
                }
            })
            .then(apiRes => {
                apiRes = apiRes.data;
                if (apiRes.success) {
                    setData({
                        count: apiRes.result.count,
                        report: all ? apiRes.result.report : apiRes.result.report[0].children,
                        totalReport: all ? 0 : apiRes.result.report[0].data
                    });
                } else {
                  
                }
            })
            .catch(err => {
                console.log(err)
            })
            .then(() => {
            })
    }

    useEffect(() => {
        getReport(setData, filters)
    }, [filters])

    const [showChart, setShowChart] = useState(false)
    return (
        <MDBRow className='m-0 pt-2'>
            <Collapse in={showChart} timeout="auto" unmountOnExit>
                <MDBCol size='12' className='mb-3'>
                    <FinancialReportChart data={data}></FinancialReportChart>
                </MDBCol>
            </Collapse>
            <MDBCol size='12'>
                <div className='overflow-hidden box-shadow-neon border border-neon rounded-6'>
                    <MDBRow className='mx-0 pt-3 p-2 box-shadow-neon mb-2'>
                        <MDBCol size='11'>
                            <h6 className='text-neon text-start d-inline me-3'>
                                {/* Year */}
                                <span className='cursor-pointer' onClick={() => setFilters({
                                    ...filters,
                                    find: {
                                        ...filters.find,
                                        timeframe: 'all',
                                        dateFrom: { $gte: moment.utc(`2021-01-01`).get() },
                                        dateTo: { $lte: moment.utc(`${moment(filters.find.dateFrom).year() + 10}-01-01`).get() }
                                    }
                                })}><MDBIcon icon='calendar-alt' className=''></MDBIcon></span>
                                <MDBIcon icon='chevron-right' className={`text-gray opacity-40 mx-2 ${['all'].indexOf(filters.find.timeframe) > -1 ? 'd-none' : ''}`}></MDBIcon>
                                {/* Year */}
                                {filters.find.timeframe == 'year' ?
                                    <span className='pe-1 text-white'>{moment(filters.find.dateFrom.$gte).format('YYYY')}</span>
                                    :
                                    <span className={`text-shadow-neon border-bottom border-neon cursor-pointer ${['all'].indexOf(filters.find.timeframe) > -1 ? 'd-none' : ''}`} onClick={() => setFilters({
                                        ...filters,
                                        find: {
                                            ...filters.find,
                                            timeframe: 'year',
                                            dateFrom: { $gte: moment.utc(`${moment(filters.find.dateFrom).year()}-01-01`).get() },
                                            dateTo: { $lte: moment.utc(`${moment(filters.find.dateFrom).year() + 1}-01-01`).get() }
                                        }
                                    })}>{moment(filters.find.dateFrom.$gte).format('YYYY')}</span>
                                }
                                <MDBIcon icon='chevron-right' className={`text-gray opacity-40 mx-2 ${['all', 'year'].indexOf(filters.find.timeframe) > -1 ? 'd-none' : ''}`}></MDBIcon>
                                {/* Month */}
                                {filters.find.timeframe == 'month' ?
                                    <span className='pe-1 text-white'>{moment(filters.find.dateFrom.$gte).format('MMMM')}</span>
                                    :
                                    <span className={`text-shadow-neon border-bottom border-neon cursor-pointer ${['all', 'year'].indexOf(filters.find.timeframe) > -1 ? 'd-none' : ''}`} onClick={() => setFilters({
                                        ...filters,
                                        find: {
                                            ...filters.find,
                                            timeframe: 'month',
                                            dateFrom: { $gte: moment.utc(`${moment(filters.find.dateFrom.$gte).year()}-${moment(filters.find.dateFrom.$gte).month() + 1}-01`).get() },
                                            dateTo: { $lte: moment.utc(`${moment(filters.find.dateTo.$lte).year()}-${moment(filters.find.dateTo.$lte).month() + 1}-01`).add(1, 'month').get() }
                                        }
                                    })}>{moment(filters.find.dateFrom.$gte).format('MMMM')}</span>
                                }
                                {/* Day */}
                                <MDBIcon icon='chevron-right' className={`text-gray opacity-40 mx-2 ${['all', 'year', 'month'].indexOf(filters.find.timeframe) > -1 ? 'd-none' : ''}`}></MDBIcon>
                                {filters.find.timeframe == 'day' ?
                                    <span className='px-1 text-white'>{moment(filters.find.dateFrom.$gte).format('DD')}</span>
                                    :
                                    <span className={`text-shadow-neon border-bottom border-neon cursor-pointer ${['all', 'year', 'month'].indexOf(filters.find.timeframe) > -1 ? 'd-none' : ''}`} onClick={() => setFilters({
                                        ...filters,
                                        find: {
                                            ...filters.find,
                                            timeframe: 'day',
                                            dateFrom: { $gte: moment.utc(`${moment(filters.find.dateFrom.$gte).year()}-${moment(filters.find.dateFrom.$gte).month() + 1}-${moment(filters.find.dateFrom.$gte).format('DD')}`).get() },
                                            dateTo: { $lte: moment.utc(`${moment(filters.find.dateTo.$lte).year()}-${moment(filters.find.dateTo.$lte).month() + 1}-${moment(filters.find.dateTo.$lte).format('DD')}`).add(1, 'days').get() }
                                        }
                                    })}>{moment(filters.find.dateFrom.$gte).format('DD')}</span>
                                }
                            </h6>
                        </MDBCol>
                        <MDBCol size='1' className='text-end'>
                            <MDBIcon className='text-neon cursor-pointer' onClick={() => setShowChart(!showChart)} icon='chart-line'></MDBIcon>
                            {/* <span className='text-neon'>{filters.find.timeframe}</span> */}
                            <strong className='text-white ps-2'>{data.report.length} / {data.count}</strong>
                        </MDBCol>
                    </MDBRow>
                    <ReportTable setFilters={setFilters} filters={filters} data={data}></ReportTable>
                </div>
            </MDBCol>
        </MDBRow>
    )
}

export default FinancialReport


const ReportTable = (props) => {
    const { filters, setFilters, data } = props;
    const { report, count, totalReport } = data;

    return <>
        <MDBRow className='m-0 fw-bold _border-bottom border-info small'>
            <MDBCol size='1' className='text-white text-center'>Time</MDBCol>
            <MDBCol size='2' className='text-center p-0'>
                <span className='text-success'>Deposit</span> / <span className='text-danger'>Withdraw</span>
            </MDBCol>
            <MDBCol size='2' className='text-neon text-center'>TopUp</MDBCol>
            <MDBCol size='1' className='text-yellow text-center'>Bet</MDBCol>
            <MDBCol size='1' className='text-warning text-center'>Cashout</MDBCol>
            <MDBCol size='1' className='text-success text-center'>Win</MDBCol>
            <MDBCol size='1' className='text-neon text-center'>PL</MDBCol>
        </MDBRow>
        {totalReport ?
            <MDBRow className='m-0 pb-2 fw-bold border-bottom border-info small'>
                <MDBCol size='1' className='text-yellow text-center'></MDBCol>
                <MDBCol size='2' className='text-white text-center'>
                    {totalReport.deposit ? <DoubleData amount1={totalReport.deposit.totalAmount} amount2={totalReport.withdraw.totalAmount} count1={totalReport.deposit.count} count2={totalReport.withdraw.count}></DoubleData> : <></>}
                </MDBCol>
                <MDBCol size='2' className='text-neon text-center'>
                    {totalReport.topupDeposit ? <DoubleData amount1={totalReport.topupDeposit.totalAmount} amount2={totalReport.topupWithdraw.totalAmount} count1={totalReport.topupDeposit.count} count2={totalReport.topupWithdraw.count}></DoubleData> : <></>}
                </MDBCol>
                <MDBCol size='1' className='text-yellow text-center'>
                    {totalReport.bet ? <SingleData amount={totalReport.bet.totalAmount} count={totalReport.bet.count}></SingleData> : <></>}
                </MDBCol>
                <MDBCol size='1' className='text-warning text-center'>
                    {totalReport.cashout ? <SingleData amount={totalReport.cashout.totalAmount} count={totalReport.cashout.count}></SingleData> : <></>}
                </MDBCol>
                <MDBCol size='1' className='text-success text-center'>
                    {totalReport.win ? <SingleData amount={totalReport.win.totalAmount} count={totalReport.win.count}></SingleData> : <></>}
                </MDBCol>
                <MDBCol size='1' className='text-neon text-center'>
                    {totalReport.bet ? <SingleData amount={totalReport.bet.totalAmount - totalReport.cashout.totalAmount - totalReport.win.totalAmount} count={totalReport.bet.count}></SingleData> : <></>}
                </MDBCol>
            </MDBRow> : <></>
        }
        <div className='overflow-scroll noScrollbar' style={{ height: 'calc(100vh - 12rem)' }}>

            {
                report.map((dataObj, i) => {
                    return <TableItem setFilters={setFilters} index={i} key={i} filters={filters} dataObj={dataObj}></TableItem>
                })
            }
        </div>
    </>
}

const TableItem = (props) => {
    const { setFilters, filters, index } = props;
    const { timeframe } = filters.find;
    const { dateFrom, dateTo, data } = props.dataObj;
    if (!data) return <span>No Data</span>
    const { deposit, topupDeposit, topupWithdraw, withdraw, bet, win, cashout } = data;
    const momentFormat = momentFormatBytimeframe(timeframe, 'item');
    const weekDay = moment(dateFrom).format('dddd');
    return <MDBRow className='m-0 border-bottom border-info align-items-center' style={{ backgroundColor: index % 2 ? '#161616' : '#000' }}>
        <MDBCol
            onClick={() => {
                if (timeframe == 'hour') return;
                setFilters({
                    ...filters,
                    find: {
                        ...filters.find,
                        timeframe: timeframe == 'all' ? 'year' : timeframe == 'year' ? 'month' : timeframe == 'month' ? 'day' : timeframe == 'day' ? 'hour' : 'day',
                        dateFrom: { $gte: moment(dateFrom).get() },
                        dateTo: { $lte: moment(dateTo).get() },
                    }
                })
            }}
            size='1' className={`${timeframe !== 'hour' ? 'cursor-pointer text-info' : ''} text-center py-1 small d-flex  align-items-center`}>
            <div className={`text-start text-${(timeframe == 'month' && weekDay == 'Sunday') ? 'danger' : ''}`} >
                {timeframe == 'month' ?
                    <div className='opacity-40 small'>
                        <small className={`text-gray`}>{weekDay}</small>
                    </div> : ''
                }
                {moment(dateFrom).format(momentFormat)}
            </div>
            <div className='ps-2'>
                {/* {moment(dateFrom).format('DD HH:mm')}
                <br />
                {moment(dateTo).format('DD HH:mm')}*/}
            </div>
        </MDBCol>
        {/* Deposit / Withdraw */}
        <MDBCol size='2' className='py-1 px-0 text-center small'>
            <DoubleData
                amount1={deposit.totalAmount}
                amount2={withdraw.totalAmount}
                count1={deposit.count}
                count2={withdraw.count}></DoubleData>
        </MDBCol>
        {/* Deposit / Withdraw */}
        <MDBCol size='2' className='py-1 px-0 text-center small'>
            <DoubleData
                amount1={topupDeposit.totalAmount}
                amount2={topupWithdraw.totalAmount}
                count1={topupDeposit.count}
                count2={topupWithdraw.count}></DoubleData>
        </MDBCol>
        <MDBCol size='1' className='py-1 px-0 text-center small'>
            <SingleData amount={bet.totalAmount} count={bet.count}></SingleData>
        </MDBCol>
        <MDBCol size='1' className='py-1 px-0 text-center small'>
            <SingleData amount={cashout.totalAmount} count={cashout.count}></SingleData>
        </MDBCol>
        <MDBCol size='1' className='py-1 px-0 text-center small'>
            <SingleData amount={win.totalAmount} count={win.count}></SingleData>
        </MDBCol>
        <MDBCol size='1' className='py-1 px-0 text-center small'>
            <SingleData colorMode='multi' amount={bet.totalAmount - cashout.totalAmount - win.totalAmount} count={bet.count}></SingleData>
        </MDBCol>
    </MDBRow>
}

const SingleData = (props) => {
    const { amount, count, colorMode } = props;
    let color = 'gray opacity-40';
    if (colorMode == 'multi') {
        color = getColorClass(amount);
    } else {
        color = amount > 0 ? 'white' : amount == 0 ? 'gray opacity-40' : 'danger';
    }
    return <div className='small'>
        <span className={`d-block text-${color}`}>${numeral(amount).format('0,0.00')}</span>
        <small className={`text-gray opacity-40`}>({numeral(count).format('0,0')})</small>
    </div>
}


const DoubleData = (props) => {
    const { amount1, amount2, count1, count2 } = props;
    const diff = amount1 + amount2;
    return <>
        <div className={`${diff == 0 ? 'opacity-40' : 'opacity-60'} small`}>
            <small className={`text-${getColorClass(amount1)} pe-1`}>${numeral(amount1).format('0,0.00')}({count1})</small>
            /
            <small className={`text-${getColorClass(amount2)} ps-1`}>${numeral(amount2).format('0,0.00')} ({count2})</small>
        </div>
        <small className={`${diff == 0 ? 'opacity-40' : ''} text-${getColorClass(diff)}`}>${numeral(diff).format('0,0.00')}</small>
    </>
}
