import { Grid, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import React, { Fragment, useState } from 'react'
import ThemeButton from '../../../layout/components/ThemeButton';
import ThemeFormatCurrency from '../../../layout/components/ThemeFormatCurrency';
import ThemeFormatTime from '../../../layout/components/ThemeFormatTime';
import RenderOdds from '../../Transaction/Renders/RenderOdds';
import RenderPL from '../../Transaction/Renders/RenderPL';
import RenderService from '../../Transaction/Renders/RenderService';
import RenderType from '../../Transaction/Renders/RenderType';
import RenderUserBalance from '../../Transaction/Renders/RenderUserBalance';
import TransactionList from '../../Transaction/TransactionList';
import TransactionListMobile from '../../Transaction/TransactionListMobile';

const PlayerReportTransactions = (props) => {
    const { transactions, count, fake, type, subtype, service, values } = props;
    const { fromText, toText, userId } = values || {};
    const isMobile = useMediaQuery('(max-width:600px)');
    return <Box>
        {transactions && !isMobile && <>
            <TransactionList from={fromText} to={toText} userId={userId} noToolbar mobile scrollFilter />
        </>}
    </Box>
}

export default PlayerReportTransactions