import { Box, Grid, LinearProgress, Modal } from '@mui/material';
import axios from 'axios';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react'
import { getBearer } from '../../../helpers/publicHelper';
import ThemeFormatCurrency from '../../../layout/components/ThemeFormatCurrency';
import UserQuickViewButton from '../../Users/UserQuickViewButton';
import $ from 'jquery';
import ReactJson from 'react-json-view';
import BaccaratDetails from './EvolutionComponents/BaccaratDetails';

const EvolutionDetails = (props) => {
    const { meta, service, type, blockchain, currency, txId, userId, _id } = props;
    const [values, setValues] = useState({
        open: false,
        html: '',
        title: ''
    })
    const details = meta.bet.details[0] || {}
    const gameId = meta && meta.bet && meta.bet.details && meta.bet.details[0] && meta.bet.details[0].id;
    const title = meta.bet.details[0].title;
    const callDetails = (e) => {

        const elem = e.currentTarget;
        $(elem).html('Loading').toggleClass('border-neon border-warning box-shadow-neon box-shadow-warning')
        axios.post(process.env.REACT_APP_API_HOST + `/admin/games/details`,
            {
                userId,
                provider: service,
                apiName: 'gamehistory',
                bet: meta.bet,
            },
            {
                headers: { 'x-auth-token': getBearer() }
            })
            .then(res => {
                res = res.data;
                console.log(res);
                setValues({
                    open: true,
                    html: res.result,
                    title
                })
                console.log(e.currentTarget, 2)
            })
            .then(() => {
                $(elem).html('Details').toggleClass('border-neon border-warning box-shadow-neon box-shadow-warning')
            })
    }
    return <>
        <Box className='d-flex justify-content-between align-items-center small w-100'>
            <div className='small float-start text-start d-inline px-1 py-0' style={{ borderLeft: '2px solid #f70' }}>
                <div className='text-capitalize text-neon'><MDBIcon icon='tag' className='me-1 pb-1 text-white'></MDBIcon> {details.title}</div>
                <div className='text-white'><MDBIcon icon='gamepad' className='me-1'></MDBIcon>Table ID: {details.tableId}</div>
            </div>
            <div>
                <small className='border border-neon box-shadow-neon rounded-3 px-3 py-1 cursor-pointer me-2' onClick={callDetails}>Details</small>
            </div>
        </Box>
        <Modal
            open={values.open}
            disableScrollLock={true}
            onClose={() => setValues({ ...values, open: false })}>
            <Box className='box-shadow-neon bg-black rounded-6 overflow-scroll noScrollbar' sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                height: '60vh',
                width: ['90vw', '80vw', '50vw', '25vw']
            }}>
                {
                    values.title == 'baccarat' && <BaccaratDetails gameDetails={values.html} />
                }
                <Box className='p-3' sx={{ fontSize: '0.6rem' }}>
                    <ReactJson name={null} style={{ padding: '0.5rem' }} theme="monokai" src={values.html} />
                </Box>
            </Box>
        </Modal>
    </>
}



export default EvolutionDetails