import React from 'react'
import CrashTXDetails from './CrashTXDetails';
import EveryMatrixTXDetails from './EveryMatrixTXDetails';
import EvolutionTXDetails from './EvolutionTXDetails';
import PragmaticTXDetails from './PragmaticTXDetails';
import DigitainTXDetails from './DigitainTXDetails';
import TelegramTXDetails from './TelegramTXDetails';
import { MDBIcon } from 'mdb-react-ui-kit';
import { Box } from '@mui/material';
import { getBlockchainColor, getBlockchainUrl } from '../../../helpers/adminHelpers';
import { currencyIcon } from '../../../helpers/currency';
import ThemeButton from '../../../layout/components/ThemeButton';
import ThemeFormatTime from '../../../layout/components/ThemeFormatTime';

const TransactionDetails = (props) => {
    const { meta, service, type, blockchain, currency, comments } = props;
    return <Box className='d-flex justify-content-between w-100'>

        {comments && (comments.length > 0) && <Comment {...props} />}


        {service == 'Crash' && <CrashTXDetails {...props} />}

        {service == 'EveryMatrix' && <EveryMatrixTXDetails {...props} />}

        {(service == 'Evolution' || service == 'RedTiger' || service == 'Netent') && <EvolutionTXDetails {...props} />}

        {service == 'Pragmatic' && <PragmaticTXDetails {...props} />}

        {service == 'Digitain' && <DigitainTXDetails {...props} />}

        {service == 'Telegram' && <TelegramTXDetails {...props} />}

        {/* {type == 'topup' && <Comment {...props} />} */}

        {(type == 'withdraw' || type == 'deposit') && meta && meta.transactionId &&
            <>
                <a className='pe-3' target='_blank' href={getBlockchainUrl(blockchain || currency, 'transaction', meta.transactionId)}>
                    <ThemeButton
                        style={{ textTransform: 'initial', margin: '2px 0' }} color={getBlockchainColor(currency)} variant='outlined' size='xs'>
                        <img className='me-1' style={{ width: '0.7rem' }} src={currencyIcon(currency, 'png')} />
                        {meta.transactionId.substr(0, 5) + ' ..... ' + meta.transactionId.substr(-5)}
                    </ThemeButton>
                </a>
                <small className='text-start pe-2'>
                    <ThemeFormatTime timestamp={meta.txTimestamp} showFromNow />
                </small>
            </>
        }

    </Box>
}

const Comment = (props) => {
    const { comments, service } = props;
    if (!comments || !comments.length || !comments[0]) return <>No Comment!</>
    const { by } = comments[0];
    const user = by;
    if (!user) return <>No User</>
    return <div style={{ borderLeft: '2px solid #26ddff' }}
        className='small text-start text-neon text-shadow-neon ps-1'>
        <small className='text-white opacity-60'>
            {service} Top Up by
        </small>
        <br />
        <MDBIcon icon='donate' className='me-1'></MDBIcon>
        {user.username} | {user.telegram ? user.telegram.username : ''}
    </div>
}

export default TransactionDetails
