import { Box } from '@mui/system';
import React from 'react'
import numeral from 'numeral';

const ThemeFormatCrashPoint = (props) => {
    let { point, sx } = props;
    // const cls = point < 1.8 ? 'text-danger' : 'text-success';
    return (
        <Box sx={{
            ...sx
        }}>
            <strong >
                {numeral(point).format('0,0.00')}
            </strong>
        </Box>
    )
}

export default ThemeFormatCrashPoint