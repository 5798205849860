import React, { useState, useEffect, useContext } from 'react';
import { MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import axios from 'axios';
import { Grow, Typography } from '@mui/material';
import $ from 'jquery'
import validator from 'validator';
import _ from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';
import { Context } from '../../context/store';
import { handleAxiosError } from '../../helpers/publicHelper';
import ThemeButton from '../components/ThemeButton';
import ThemeInput from '../components/ThemeInput';
import { loginSuccess } from '../../helpers/adminHelpers';

const Login = (props) => {
    const [state, dispatch] = useContext(Context);
    const { t } = state;
    const { enqueueSnackbar } = useSnackbar();

    const [values, setValues] = useState({
        phone: '',
        step: 1,
        loginMode: 'username',
        login: '',
        showPassword: false,
        password: '',
        passwordMode: 'password',
        twofa: '-',
        waiting: false,
        message: {
            style: 'warning',
            text: ''
        }
    })

    const handleLoginSuccess = (token, result) => {
        setValues({
            ...values,
            message: {
                style: 'warning',
                text: ''
            },
            waiting: false
        });
        loginSuccess(state, dispatch, result, token);
    }

    const ask2FA = (res) => {
        setValues({
            ...values,
            message: {
                style: 'success',
                text: '',
            },
            step: 3
        })
    }

    const callLogin = (loginValues) => {
        if (window.grecaptcha) {
            window.grecaptcha.ready(() => {
                window.grecaptcha
                    .execute(state.recaptchaSiteKey, { action: "homepage" })
                    .then(captcha => {
                        let postData = _.pick(loginValues, ['loginMode', 'login', 'password', 'twofa']);
                        const headers = { captcha };
                        axios.post(state.active.host + '/api/users/login', postData, { headers })
                            .then((res) => {
                                const token = res.headers['x-auth-token'];
                                res = res.data;
                                console.log('res', res)
                                if (res.success) {
                                    // no 2FA
                                    console.log('res.result', res.result)
                                    if (res.code === 200) {
                                        handleLoginSuccess(token, res.result);
                                    }
                                    // ask for 2FA
                                    else if (res.code === 403) { ask2FA(res); }
                                } else {
                                    enqueueSnackbar(res.message, { variant: 'error', autoHideDuration: 3000 })
                                    setValues({
                                        ...values,
                                        waiting: false,
                                        message: {
                                            style: 'danger',
                                            text: res.result
                                        }
                                    })
                                }
                            })
                            .catch(err => {
                                handleAxiosError(err);
                                setValues({
                                    ...values,
                                    waiting: false,
                                    message: {
                                        style: 'danger',
                                        text: ''
                                    }
                                })
                            })
                    })
            })
        } else {
            enqueueSnackbar('Recaptcha not resolved. Refresh the page.', {
                variant: 'error', autoHideDuration: 3000
            })
        }
    }

    useEffect(() => {
        if (values.step >= 0 && values.step < 4) {
            $('input[name=login]').trigger('focus');
        }
    }, [values.step])

    const validateStep = () => {
        const login = values.login;
        let loginMode = '';

        if (validator.isEmail(login)) {
            loginMode = 'email'
        } else if (validator.isMobilePhone(login) && validator.isLength(login, { min: 8, max: 10 })) {
            loginMode = 'mobile'
        } else if (!validator.contains(login, '@') && !validator.isNumeric(login)) {
            loginMode = 'username'
        } else {
            loginMode = 'error'
            return setValues({
                ...values,
                message: {
                    text: ''
                },
                loginMode: loginMode
            })
        }

        setValues({
            ...values,
            message: {
                text: ''
            },
            waiting: true,
            loginMode: loginMode,
        });

        callLogin({
            ...values,
            waiting: true,
            loginMode: loginMode,
        });
    }

    useEffect(() => {
        console.log('w', values.waiting)
    }, [values.waiting])

    const color = state?.serverEnv == 'prod' ? 'neon' : state?.serverEnv == 'stage' ? 'warning' : 'danger';

    return (
        <MDBRow className='mx-0 justify-content-center pt-5'>
            <MDBCol size='10' md='4' xl='3' className={`px-0 box-shadow-${color} rounded-6 overflow-hidden`}>
                <h6 className='text-center text-neon pt-3' style={{ backgroundColor: '#111' }}>
                    Login
                    {process.env.REACT_APP_API_HOST !== 'https://api.imoon.com' &&
                        <Typography variant='subtitle2' className={`p-1 m-0 text-${color} opacity-60 text-center text-shadow-black small fw-light`}>
                            {process.env.REACT_APP_API_HOST} ({state?.serverEnv})
                        </Typography>
                    }
                </h6>
                <MDBRow className='m-0 py-2'>
                    <MDBCol size='12'>
                        {/* Login */}
                        <form onSubmit={e => { e.preventDefault(); validateStep() }}>
                            <div className='position-relative'>
                                <ThemeInput label='Username, Email or Phone' labelStyle={{ background: '#121212' }} value={values.login} onChange={e => setValues({ ...values, login: e.target.value })}></ThemeInput>
                            </div>
                            <div className={`position-relative my-4`}>
                                <ThemeInput label='Password' labelStyle={{ background: '#121212' }} value={values.password} type={values.showPassword ? 'text' : 'password'} onChange={e => setValues({ ...values, password: e.target.value })}></ThemeInput>
                                <MDBIcon onClick={() => setValues({ ...values, showPassword: !values.showPassword })} className='text-neon position-absolute cursor-pointer' style={{ right: '0.8rem', top: '0.6rem', zIndex: 3 }} icon={values.showPassword ? 'eye' : 'eye-slash'}></MDBIcon>
                            </div>
                            <MDBRow>
                                <MDBCol size='12' className='text-center'>
                                    <ThemeButton type='submit' variant='outlined' color={color} className='text-neon px-3'>{!values.waiting ? t('Sign In') : <CircularProgress color='info' size={18} thickness={5} />}</ThemeButton>
                                </MDBCol>
                            </MDBRow>
                        </form>
                    </MDBCol>
                    <MDBCol size='12' className='pb-1 pt-2 text-center d-block' style={{ minHeight: 15 }}>
                        {values.message.text}
                        {values.message.text ?
                            <Grow in={true} timeout={1000}>
                                <div>
                                    <small className={'py-2 text-' + values.message.style}>{values.message.text}</small>
                                </div>
                            </Grow>
                            : <></>}
                    </MDBCol>
                </MDBRow>
            </MDBCol>
        </MDBRow>
    )
}

export default Login
