import React, { useState, useEffect, useContext } from 'react';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import { Context } from '../../context/store';
import ThemeButton from '../../layout/components/ThemeButton';
import _ from 'lodash'
import { roleColor } from '../../helpers/adminHelpers';
import { getBearer } from '../../helpers/publicHelper';

const availableRoles = ['superadmin', 'admin', 'manager', 'support', 'supervisor', 'accountant', 'user', 'graphist', 'superGraphist', 'test', 'contentwriter'];

const UserRolesModal = (props) => {
    const [state, dispatch] = useContext(Context);
    const { setRolesModal, rolesModal, setRows, getUsersFromAPI } = props;
    const { selectedUser, open } = rolesModal;
    const handleClose = () => {
        setRolesModal({ ...rolesModal, open: false });
    }

    const [roles, setRoles] = useState(selectedUser.role)

    useEffect(() => {
        setRoles(selectedUser.role)
    }, [selectedUser])

    const setNewRoles = (user, roles, handleClose, setRows) => {
        var postData = {
            _id: user._id,
            roles: roles
        };
        axios.post(state.active.host + '/admin/users/roles', postData, {
            headers: { 'x-auth-token': getBearer() }
        })
            .then((res) => {
            })
            .catch((err) => {
                if (err.response) {
                    console.error(err.response.data);
                } else {
                    console.error(err);
                }
            }).then(() => {
                getUsersFromAPI(setRows);
                handleClose();
            })
    }

    return <Modal
        open={open}
        disableScrollLock={true}
        onClose={handleClose}
        disableBackdropClick={true}
    >
        <Box className='box-shadow-neon rounded-6 overflow-hidden' sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
        }}>
            <MDBRow className='m-0 bg-black py-2'>
                <MDBCol size='12'>
                    <Typography className='text-neon' variant='body2'>
                        <strong className='text-neon'>User Roles</strong>
                        <MDBIcon onClick={handleClose} className='float-end pt-1 cursor-pointer' icon='times'></MDBIcon>
                    </Typography>
                    <hr className='my-2' />

                    <Typography variant='body2'>
                        <strong className='text-neon'>Userame: </strong>
                        <span className='text-neon'>{selectedUser.username}</span>
                    </Typography>
                    <Typography variant='body2'>
                        <strong className='text-neon'>User Id: </strong>
                        <span className='text-neon'>{selectedUser.userId}</span>
                    </Typography>
                    <hr className='my-2' />
                </MDBCol>

                <form className='' onSubmit={e => { e.preventDefault(); setNewRoles(selectedUser, roles, handleClose, setRows); }} noValidate autoComplete="off">
                    {/* <ThemeInput
                        label='New Password'
                        value={values.newPassword}
                        placeHolder=''
                        onChange={e => setValues({ ...values, newPassword: e.currentTarget.value })}
                    ></ThemeInput> */}
                    {
                        availableRoles.map((role, i) =>
                            <div key={i}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color={roleColor(role)}
                                            checked={roles.indexOf(role) > -1}
                                            onChange={e => {
                                                const checked = e.target.checked;
                                                if (!checked) {
                                                    setRoles(_.without(roles, role))
                                                }
                                                else {
                                                    let newRoles = _.cloneDeep(roles);
                                                    newRoles.push(role);
                                                    setRoles(newRoles)
                                                }
                                            }
                                            }
                                            name={role} />
                                    }
                                    label={_.capitalize(role)}
                                />
                            </div>
                        )}
                    <div className='text-end mt-3'>
                        <ThemeButton className='px-3' type='submit' variant='outlined' color={'success'} disabled={roles && (roles.length == 0)}>Set Roles</ThemeButton>
                    </div>
                </form>
            </MDBRow>
        </Box>
    </Modal>
}

export default UserRolesModal
