import React, { createContext, useContext, useEffect, useState } from 'react'
import { Modal } from '@mui/material';
import { Box } from '@mui/system';
import { MDBIcon } from 'mdb-react-ui-kit';
import axios from 'axios';
import { getBearer } from '../helpers/publicHelper';
import { Context } from '../context/store';
import UserDetails from '../admin/Tables/UsersTable/UserDetails';

const UserQuickViewProvider = (props) => {
    const [state, dispatch] = useContext(Context);

    const [userId, setUserId] = useState('');
    const [values, setValues] = useState({});
    const getUsersFromAPI = (newValues, nweUserId) => {
        console.log('nweUserId::', nweUserId)
        axios.post(state.active.host + '/admin/users', {
            find: { userId: nweUserId }
        }, {
            headers: { 'x-auth-token': getBearer() }
        })
            .then(apiRes => {
                apiRes = apiRes.data;
                if (apiRes.success) {
                    if (apiRes.result && apiRes.result.users && apiRes.result.users.length) {
                        setValues({ ...newValues, user: apiRes.result.users[0] })
                    } else {
                        setValues({ ...newValues, user: {} })
                    }
                }
            })
    }

    useEffect(() => {
        console.log('userId::', userId)

        if (userId) {
            getUsersFromAPI(values, userId)
        }

        return () => { }
    }, [userId])


    return (
        <UserQuickViewContext.Provider value={[userId, setUserId]}>
            {userId &&
                <Modal
                    key={userId}
                    open={true}
                    disableScrollLock={true}
                    onClose={() => setUserId('')}>
                    <Box className='bg-black overflow-hidden rounded-6' sx={{
                        position: 'absolute',
                        bottom: '0%',
                        left: '0%',
                        width: '100vw',
                        height: 'calc(100vh)',
                        fontSize: '0.8rem'
                    }}>
                        <Box onClick={() => setUserId('')}
                            className='box-shadow-neon rounded-circle text-danger cursor-pointer bg-black align-items-center justify-content-center d-flex'
                            sx={{
                                height: '2.5rem',
                                width: '2.5rem',
                                position: 'absolute',
                                zIndex: 999999,
                                right: '5px',
                                top: '5px',
                                fontSize: '1.2rem'
                            }}>
                            <MDBIcon icon='times'></MDBIcon>
                        </Box>
                        <Box className=' overflow-scroll noScrollbar' sx={{
                            height: '100vh'
                        }}>
                            {values.user?.userId == userId && <UserDetails key={values.user} user={{ ...values.user }} />}
                        </Box>
                    </Box>
                </Modal>
            }
            {props.children}
        </UserQuickViewContext.Provider>
    )
}

export const UserQuickViewContext = createContext('');
export default UserQuickViewProvider;
