import { blue, green, orange, red, yellow } from '@mui/material/colors';
import { Box } from '@mui/system';
import moment from 'moment'
import { useSnackbar } from 'notistack';
import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard';

const ThemeFormatTime = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    let { copy, timestamp, utc } = props;
    return (
        <>
            {
                copy ?
                    <CopyToClipboard
                        onCopy={() => { enqueueSnackbar('Timestamp Copied.', { variant: 'warning', autoHideDuration: 2500 }) }}
                        text={timestamp}>
                        <div>
                            <TimeText {...props} />
                        </div>
                        {/*  */}
                    </CopyToClipboard >
                    :
                    <>
                        <TimeText {...props} />
                    </>

            }
        </>
    )
}


const TimeText = (props) => {
    let { timestamp, showFromNow, format, sx, ms, copy, utc } = props;
    format = format || 'YYYY-MM-DD HH:mm:ss';
    return <Box
        sx={{
            cursor: copy ? 'pointer' : 'default',
            '& *': { color: utc ? '#26ddff' : orange[400] },
            '& .fromNow': { color: '#ccc' },
            ...sx
        }}>
        {utc ?
            <small className='dateTime'>
                {moment.utc(timestamp).format(`YYYY-MM-DD HH:mm:ss`)}
                {ms &&
                    <small className='opacity-50 text-white'>
                        {moment.utc(timestamp).format(`:SSS`)}
                    </small>
                }

                {showFromNow &&
                    <div className='fromNow' title={timestamp}>
                        {moment.utc(timestamp).fromNow()}
                    </div>
                }
            </small>
            :
            <small className='dateTime'>
                {moment(timestamp).format(`YYYY-MM-DD HH:mm:ss`)}
                {ms &&
                    <small className='opacity-50 text-white'>
                        {moment(timestamp).format(`:SSS`)}
                    </small>
                }

                {showFromNow &&
                    <Box className='fromNow' title={timestamp}>
                        {moment(timestamp).fromNow()}
                    </Box>
                }
            </small>
        }
    </Box>
}

export default ThemeFormatTime