import React, { useState, useEffect, useRef, useContext, useLayoutEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AppBar, Avatar, Backdrop, Badge, CircularProgress, ClickAwayListener, Grid, Pagination, Toolbar, Tooltip, Typography, useMediaQuery, Zoom } from '@mui/material';
import axios from 'axios';
import UserFilter from '../Tables/UsersTable/Filter/UserFilter';
import Row from '../Tables/UsersTable/Row';
import UserTopupModal from './UserTopupModal';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import UserChangePasswordModal from './UserChangePasswordModal';
import UserRolesModal from './UserRolesModal';
import { getBearer, getStatus } from '../../helpers/publicHelper';
import { BreadCrumbContext, Context } from '../../context/store';
import { border, Box } from '@mui/system';
import moment from 'moment';
import { roleColor, roleIcon } from '../../helpers/adminHelpers';
import UserQuickViewButton from './UserQuickViewButton';
import numeral from 'numeral';
import { currencyDetail, currencyIcon } from '../../helpers/currency';
import UserWallets from './UserDetails/UserWallets';
import Big from 'big.js';
import TransactionList from '../Transaction/TransactionList';
import { useParams } from 'react-router-dom';


const UserList = (props) => {
    const { userId } = useParams();
    const [state, dispatch] = useContext(Context);
    const [contextBC, setContextBC] = useContext(BreadCrumbContext);
    useLayoutEffect(() => {
        setContextBC([
            {
                href: '/',
                text: 'Home'
            },
            {
                text: 'Users'
            }
        ])
    }, [])
    const [rows, setRows] = useState({
        users: [],
        count: 0
    })
    const interval = useRef(null);

    const [filter, setFilter] = useState({
        find: {
            userId: userId || '',
        },
        limit: 15,
        skip: 0,
        sort: { updatedAt: -1 }
    })
    const filterRef = useRef();
    filterRef.current = filter;

    const getUsersFromAPI = (filters) => {
        axios.post(state.active.host + '/admin/users', filters
            , {
                headers: { 'x-auth-token': getBearer() }
            })
            .then(apiRes => {
                apiRes = apiRes.data;
                if (apiRes.success) {
                    setRows(apiRes.result);
                }
            })
    }

    const [topupModal, setTopupModal] = useState({
        open: false,
        selectedUser: null,
        currency: 'USD',
        amount: 0
    })

    const [rolesModal, setRolesModal] = useState({
        open: false,
        selectedUser: null,
        roles: []
    })

    const [changeUserPasswordModal, setChangeUserPasswordModal] = useState({
        open: false,
        selectedUser: null,
        mode: 'set',
        newPassword: '',
        amount: 0
    })

    const handlePagination = (e, page) => {
        setFilter({
            ...filter,
            skip: (page - 1) * filter.limit
        });
    }

    const UE1 = useRef();
    useEffect(() => {
        getUsersFromAPI(filterRef.current)
        if (!UE1.current) { UE1.current = true } else {
            console.log('filter3:', filter.find)
            getUsersFromAPI(filterRef.current)
            let updateInterval = setInterval(() => {
                getUsersFromAPI(filterRef.current)
            }, 5000);
            interval.current = updateInterval;
        }
        return () => {
            clearInterval(interval.current)
        }
    }, [filter])

    // const [userCardView, setUserCardView] = useState(false);
    const largeScreen = useMediaQuery('(min-width:1200px)');

    return (
        <Box sx={{
            // border: '1px solid #f00',
            width: ['100vw', 'calc(100vw  - 3.5rem)'],
            overflow: 'scroll'
        }}>
            <AppBar position="fixed">
                <Toolbar />
                <Toolbar variant='dense' />
                <Box
                    className='bg-black'
                    sx={{
                        borderBottom: '1px solid #26ddff55',
                        overflow: 'hidden',
                        paddingTop: '0.5rem',
                        zIndex: 999,
                        width: ['100vw', 'calc(100vw  - 3.5rem)'],
                        marginLeft: [0, '3.5rem'],
                        height: ['auto', 'auto', 'auto', '3rem']
                    }}>
                    <UserFilter {...props} setFilter={setFilter} filter={filter} count={rows.count}></UserFilter>
                    {/* <Box className='text-center position-fixed' sx={{ bottom: 5, right: 5, zIndex: 9 }}>
                        <small className='border border-neon px-3 py-1 rounded-pill text-neon' onClick={() => setUserCardView(!userCardView)}>{
                            userCardView ?
                                <MDBIcon icon='table' /> :
                                <MDBIcon icon='list' />
                        }</small>
                    </Box> */}
                </Box>
            </AppBar>
            <Toolbar />

            <Box sx={{
                width: ['100vw', 'calc(100vw  - 3.5rem)'],
                overflow: 'scroll'
            }}>
                {!largeScreen ?
                    <Grid container spacing={[3, 2]} className='px-2 p-md-0'>
                        {rows.users.map((row, rowIndex) => <UserCard {...row} key={rowIndex} />)}
                    </Grid>
                    :
                    <Table
                        sx={{
                            display: 'block',
                            "& td": {
                                color: '#fff',
                                border: 0,
                                borderBottom: '1px solid #333',
                                padding: '2px 0',
                                overflow: 'hidden'
                            },
                        }} padding='none' size='small'>

                        <TableHead sx={{ bgcolor: '#333' }}>
                            <TableRow>
                                <TableCell />
                                <TableCell className='text-center px-2'>UserID</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell className='ps-3 '>Username</TableCell>
                                <TableCell align="center">Referrer</TableCell>
                                <TableCell align="center">Affiliate</TableCell>
                                <TableCell align="left" className='ps-5'>Total Balance</TableCell>
                                <TableCell align="left" className=''>P/L</TableCell>
                                <TableCell align="left">Register</TableCell>
                                <TableCell align="left">Last Activity/Login</TableCell>
                                <TableCell align="center">Info / Play / KYC / Active</TableCell>
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {rows.users.map((row, rowIndex) => {
                                if (rowIndex > 1000) return <Box>Too many items!</Box>
                                return <Row
                                    setRows={setRows}
                                    handleTopup={row => setTopupModal({ ...topupModal, selectedUser: row, open: true })}
                                    handleRoles={row => setRolesModal({ ...rolesModal, selectedUser: row, open: true })}
                                    handleChaneUserPassword={row => setChangeUserPasswordModal({ ...changeUserPasswordModal, selectedUser: row, open: true })}
                                    handleRevokeUserToken={row => setTopupModal({ ...topupModal, selectedUser: row, open: true })}
                                    handleBanUser={row => setTopupModal({ ...topupModal, selectedUser: row, open: true })}
                                    rowIndex={rowIndex}
                                    key={row._id}
                                    row={row} />
                            }
                            )}
                        </TableBody>
                    </Table>
                }
            </Box>

            <Box className='m-0 text-center py-4'>
                <Pagination onChange={handlePagination} className='d-inline-block' count={Math.ceil(rows.count / filter.limit)} variant="outlined" shape="rounded" />
            </Box>

            {topupModal.selectedUser ?
                <UserTopupModal getUsersFromAPI={getUsersFromAPI} filter={filterRef.current} setRows={setRows} topupModal={topupModal} setTopupModal={setTopupModal}></UserTopupModal>
                : <></>}

            {rolesModal.selectedUser ?
                <UserRolesModal getUsersFromAPI={getUsersFromAPI} setRows={setRows} rolesModal={rolesModal} setRolesModal={setRolesModal}></UserRolesModal>
                : <></>}

            {changeUserPasswordModal.selectedUser ?
                <UserChangePasswordModal getUsersFromAPI={getUsersFromAPI} setRows={setRows} changeUserPasswordModal={changeUserPasswordModal} setChangeUserPasswordModal={setChangeUserPasswordModal}></UserChangePasswordModal>
                : <></>}
        </Box>
    );
}


const UserCard = (user) => {
    const { userId, username, createdAt, updatedAt, kyc, profile, role, totalBalance, wallet, _id } = user;
    const { active, affiliate, avatar, country, currency, favorite, firstName, firstNameVerified, lastName, lastNameVerified, language, lastLogin, nickName, unlockCoefficient, walletUpdateRequested, referrer } = profile;
    return <Grid item xs={12} lg={2}>
        <Box sx={{ boxShadow: '0 1px 5px #26ddffaa', border: '1px solid #26ddff77' }} className='rounded-5 overflow-hidden bg-black mt-2- -mb-3 h-100 d-flex flex-column justify-content-between'>
            <UserCardHeader {...user} />
            <Box className='p-2 d-flex justify-content-between align-items-center'>
                <UserRoles roles={user.role} />
                <KYC {...kyc} />
            </Box>
            <Box className='px-2'>
                <Referrer {...referrer} />
                <UserAffiliate affiliate={affiliate} />
            </Box>
            <Box className='px-2'>
            </Box>
            <UserCardFooter {...user} />
        </Box>
    </Grid>
}

const UserCardHeader = (user) => {
    const { userId, username, profile } = user;
    const { avatar } = profile;
    return <Box sx={{ borderBottom: '1px solid #fff3' }} className='p-2 d-flex align-items-center justify-content-between'>
        <Box className='d-flex align-items-center'>
            <Avatar sx={{ width: '2rem', height: '2rem', 'i': { fontSize: '0.7rem' } }} className={`bg-transparent me-2 overflow-hidden border box-shadow-${user.env == 'stage' ? 'warning border-warning' : 'neon border-neon'}`}>
                {avatar ?
                    <img src={avatar} className='img-fluid' />
                    :
                    <MDBIcon className='text-white opacity-30' icon='user'></MDBIcon>
                }
            </Avatar>
            <Box>
                <Typography variant='subtitle1'>
                    <UserQuickViewButton userId={userId} />
                </Typography>
                <Typography variant='subtitle2'>{username}</Typography>
            </Box>
        </Box>
        <Box className='small text-end'>
            <UserBalance {...user} />
            <RegisterationActiveDateTime {...user} />
        </Box>
    </Box>
}

const RegisterationActiveDateTime = (user) => {
    const { createdAt, updatedAt, profile } = user;
    const { lastLogin, country } = profile;

    const [open, setOpen] = useState(false);
    return <Box sx={{ fontSize: '0.8rem' }}>
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div className='d-inline-block'>
                <Tooltip
                    open={open}
                    classes={{ tooltip: ' bg-black box-shadow-neon' }}
                    TransitionComponent={Zoom}
                    placement="bottom" title={
                        <Box>
                            <Box className=''>
                                <Typography className='opacity-40' variant='subtitle2'>
                                    Last Action
                                </Typography>
                                <small className={'text-' + (moment(updatedAt).fromNow().indexOf('seconds') > 1 ? 'success' :
                                    moment(updatedAt).fromNow().indexOf('minute') > 1 ? 'success' :
                                        moment(updatedAt).fromNow().indexOf('hour') > 1 ? 'info' :
                                            moment(updatedAt).fromNow().indexOf('day ') > 1 ? 'info' :
                                                moment(updatedAt).fromNow().indexOf('days') > 1 ? 'warning' : 'danger'
                                )}>{updatedAt ? moment(updatedAt).fromNow() : '-'}
                                </small>
                                {country &&
                                    <img
                                        src={`https://flagcdn.com/48x36/${country.toLowerCase()}.png`}
                                        height='12'
                                        className='ms-1'
                                        alt={country}></img>}
                            </Box>
                            <hr className='my-1' />
                            <Box className=''>
                                <Typography className='opacity-40' variant='subtitle2'>
                                    Last Login
                                </Typography>
                                <div className='text-white opacity-80 small'>
                                    <MDBIcon icon='key'></MDBIcon>
                                    <small className='ps-1'>{lastLogin ? moment(lastLogin).fromNow() : '-'}</small>
                                </div>
                            </Box>
                            <hr className='my-1' />
                            <Box className=''>
                                <Typography className='opacity-40' variant='subtitle2'>
                                    Registration Date
                                </Typography>
                                <div className='text-white opacity-80 small'>
                                    <MDBIcon icon='user'></MDBIcon>
                                    <small className='ps-1'>{moment(createdAt).fromNow()}</small>
                                </div>
                            </Box>
                        </Box>
                    }>
                    <small onClick={() => setOpen(!open)} className='pe-1'>
                        <small className={'text-' + (moment(updatedAt).fromNow().indexOf('seconds') > 1 ? 'success' :
                            moment(updatedAt).fromNow().indexOf('minute') > 1 ? 'success' :
                                moment(updatedAt).fromNow().indexOf('hour') > 1 ? 'info' :
                                    moment(updatedAt).fromNow().indexOf('day ') > 1 ? 'info' :
                                        moment(updatedAt).fromNow().indexOf('days') > 1 ? 'warning' : 'danger'
                        )}>{updatedAt ? moment(updatedAt).fromNow() : '-'}
                        </small>
                        {country &&
                            <img
                                src={`https://flagcdn.com/48x36/${country.toLowerCase()}.png`}
                                height='12'
                                className='ms-1'
                                alt={country}></img>}
                    </small>
                </Tooltip>
            </div>
        </ClickAwayListener>

    </Box >
}

const UserRoles = (props) => {
    const { roles } = props;
    let sortedRoles = [];
    if (roles.indexOf('superadmin') > -1) sortedRoles.push('superadmin');
    if (roles.indexOf('admin') > -1) sortedRoles.push('admin');
    if (roles.indexOf('manager') > -1) sortedRoles.push('manager');
    if (roles.indexOf('support') > -1) sortedRoles.push('support');
    if (roles.indexOf('supervisor') > -1) sortedRoles.push('supervisor');
    if (roles.indexOf('accountant') > -1) sortedRoles.push('accountant');
    if (roles.indexOf('graphist') > -1) sortedRoles.push('graphist');
    if (roles.indexOf('superGraphist') > -1) sortedRoles.push('superGraphist');
    if (roles.indexOf('user') > -1) sortedRoles.push('user');
    const result = sortedRoles.map((role, i) =>
        <RoleItem
            key={i}
            role={role}
        />)
    return <Box>
        {result}
    </Box>
}

const RoleItem = (props) => {
    const { role } = props;
    const [open, setOpen] = useState(false)

    return <ClickAwayListener onClickAway={() => setOpen(false)}>
        <div className='d-inline-block'>
            <Tooltip
                open={open}
                classes={{ tooltip: ' bg-black box-shadow-neon' }}
                TransitionComponent={Zoom}
                placement="bottom" title={<span className={`text-${roleColor(role)}`}>{role}</span>}>
                <small onClick={() => setOpen(!open)} className='pe-1'>
                    {roleIcon(role)}
                </small>
            </Tooltip>
        </div>
    </ClickAwayListener>
}

const Referrer = (referrer) => {
    const [open, setOpen] = useState(false)
    if (!referrer || !referrer.userId) return <></>
    const { from, refId, userId } = referrer;

    return <Typography variant='subtitle2'>
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div className='py-2'>
                <Tooltip
                    open={open}
                    TransitionComponent={Zoom}
                    classes={{ tooltip: 'bg-black box-shadow-neon px-2 m-0' }}
                    placement="right" title={
                        <Box>
                            <Box className='text-center py-1'>
                                <UserQuickViewButton userId={userId} />
                            </Box>
                            <Box className='text-center py-1'>
                                {from}
                            </Box>
                            <Box className='text-center py-1'>
                                {refId}
                            </Box>
                        </Box>
                    }>
                    <span onClick={() => setOpen(!open)} className='border border-neon rounded-3 py-1 px-2 cursor-pointer small text-neon'>
                        <span>Referred By </span>
                        <span className=''>{userId}</span>
                    </span>
                </Tooltip>
            </div>
        </ClickAwayListener>
    </Typography>

}

const UserBalance = (user) => {
    const { profile, wallet } = user;
    const activeCurrency = profile.currency;
    const [state, dispatch] = useContext(Context);
    const [open, setOpen] = useState(false)
    const { currencyList } = state.config;
    let totalBalanceInUSD = 0;
    let toppedUp = false;
    const render = currencyList.map((currency, i) => {
        const currencyWallet = wallet[currency];
        if (!currencyWallet) return <span>No Wallet for {currency}</span>
        const { balance, topup } = currencyWallet;
        if (topup && topup.count) toppedUp = true;
        const rateInUSD = state.rates ? (state.rates[currency.toUpperCase()] ? state.rates[currency.toUpperCase()]['USD'] : 0) : 0;
        const amountInUSD = balance * rateInUSD;
        if (currencyWallet.balance > 0.000001 || currency == 'USD') {
            totalBalanceInUSD += amountInUSD;
            return <span key={i} className='px-0'>
                {activeCurrency != currency ?
                    <img className={`ms-n2`} style={{ width: '1rem', height: '1rem' }} src={currencyIcon(currency, 'png')} />
                    : <></>
                }
            </span>
        } else {
            return null
        }
    })
    const totalBalanceInUSDWhole = new Big(totalBalanceInUSD).round(0).toString();
    const totalBalanceInUSDDecimals = new Big(totalBalanceInUSD).round(2).minus(totalBalanceInUSDWhole).toString();

    return <ClickAwayListener onClickAway={() => setOpen(false)}>
        <div>
            <Tooltip
                open={open}
                TransitionComponent={Zoom}
                classes={{ tooltip: 'bg-black box-shadow-neon px-2 m-0' }}
                placement="right" title={
                    <UserWallets userWallet={{ ...wallet }}></UserWallets>
                }>
                <span onClick={() => setOpen(!open)} className='ps-2 cursor-pointer'>
                    <span className='d-inline-block text-end' style={{ width: '4rem' }}>
                        {render}
                        <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            badgeContent={
                                <MDBIcon className='text-shadow-neon text-neon' style={{ fontSize: '0.4rem' }} icon='check-circle'></MDBIcon>
                            }
                        >
                            <Avatar style={{ width: '1rem', height: '1rem' }} alt="Currency" src={currencyIcon(activeCurrency, 'png')} />
                        </Badge>
                    </span>
                    <span className='ps-1'>
                        <small className='opacity-80' style={{ paddingRight: '1px' }}>$</small>
                        <strong>
                            {numeral(totalBalanceInUSDWhole).format('0,0')}
                        </strong>
                    </span>
                    <small className='opacity-80' style={{ paddingRight: '1px' }}>
                        {numeral(totalBalanceInUSDDecimals).format('.[00]')}
                    </small>
                    <small className='fw-bold'>{toppedUp ? <MDBIcon className='text-small text-neon small ms-1' icon='donate'></MDBIcon> : ''}</small>
                </span>
            </Tooltip>
        </div>
    </ClickAwayListener>
}

const UserAffiliate = (props) => {
    const { affiliate } = props;
    const affIds = Object.keys(affiliate);
    if (!affiliate || !affIds.length) return <></>
    const affItems = affIds.map(id => affiliate[id]);
    const counts = affItems.reduce((total, affItem) => { return total + affItem.count }, 0);
    const activeUserscounts = affItems.reduce((total, affItem) => { return total + affItem.activeUsersCount }, 0);
    return <Box sx={{ fontSize: '0.7rem' }} className='d-flex align-items-center justify-content-between'>
        <small>Aff Links: {affIds.length}</small>
        <small>Registered Users:  {counts}</small>
        <small>Active Aff Users: {activeUserscounts}</small>
    </Box>
}

const KYC = (kyc) => {
    const { address, emails, isRequired, mobiles, score, telegram, verified, documents } = kyc;
    return <Box sx={{ fontSize: '0.9rem' }} className='d-flex align-items-center'>
        <KYCEmail {...emails} />
        <KYCMobile {...mobiles} />
        <KYCTelegram {...telegram} />
        <KYCAddress {...address} />
        <span className='px-1 opacity-40'>|</span>

        <span className='px-1 opacity-40'>|</span>
        <KYCScore {...kyc}></KYCScore>
    </Box>
}

const KYCEmail = (emails) => {
    const [open, setOpen] = useState(false)

    return <Box className='position-relative me-1'>
        {
            emails.default && emails.default.address ?
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <div>
                        <Tooltip
                            open={open}
                            TransitionComponent={Zoom}
                            classes={{ tooltip: 'bg-black box-shadow-neon px-2 m-0' }}
                            placement="bottom-end" title={
                                <Box>
                                    <small className='d-block text-capitalize opacity-40'>Address</small>
                                    {emails.default.address}
                                </Box>
                            }>
                            <span onClick={() => setOpen(!open)} className={`cursor-pointer px-1 text-neon`}>
                                <MDBIcon icon='envelope'></MDBIcon>
                            </span>
                        </Tooltip>
                    </div>
                </ClickAwayListener>
                :
                <span className={`cursor-pointer px-1 opacity-30`}>
                    <MDBIcon icon='envelope'></MDBIcon>
                </span>
        }
        {emails.default && emails.default.verified && <KYCCheck status={emails.default.verified} />}

    </Box>
}

const KYCMobile = (mobile) => {
    const [open, setOpen] = useState(false)

    return <Box className='position-relative me-1'>
        {
            mobile.default && mobile.default.normalized ?
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <div>
                        <Tooltip
                            open={open}
                            TransitionComponent={Zoom}
                            classes={{ tooltip: 'bg-black box-shadow-neon px-2 m-0' }}
                            placement="bottom-end" title={
                                <Box>
                                    <small className='d-block text-capitalize opacity-40'>Number</small>
                                    {mobile.default.normalized}
                                </Box>
                            }>
                            <span onClick={() => setOpen(!open)} className={`cursor-pointer px-1 text-neon text-shadow-neon`}>
                                <MDBIcon icon='mobile-alt'></MDBIcon>
                            </span>
                        </Tooltip>
                    </div>
                </ClickAwayListener>
                :
                <span className={`cursor-pointer px-1 opacity-20`}>
                    <MDBIcon className='text-shadow-neon-0' icon='mobile-alt'></MDBIcon>
                </span>
        }
        {mobile.default && mobile.default.verified && <KYCCheck status={mobile.default.verified} />}
    </Box>
}

const KYCTelegram = (telegram) => {
    const { profile, telegramId, verified } = telegram;

    const [open, setOpen] = useState(false)

    return <Box className='position-relative'>
        {
            telegram && telegram.profile ?
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <div>
                        <Tooltip
                            open={open}
                            TransitionComponent={Zoom}
                            classes={{ tooltip: 'bg-black box-shadow-neon px-2 m-0' }}
                            placement="bottom-end" title={
                                <Box>
                                    {
                                        telegram.profile.username && <Box>
                                            <small className='d-block opacity-40'>Telegram Username</small>
                                            <a target='_blank' className='text-neon' href={`https://t.me/${telegram.profile.username}`}>{telegram.profile.username}</a>
                                        </Box>
                                    }
                                    <Box>
                                        <small className='d-block opacity-40'>Telegram ID</small>
                                        {telegram.profile.id}
                                    </Box>
                                    {
                                        telegram.profile.first_name && <Box>
                                            <small className='d-block opacity-40'>Telegram FirstName</small>
                                            {telegram.profile.first_name}
                                        </Box>
                                    }
                                    {
                                        telegram.profile.last_name && <Box>
                                            <small className='d-block opacity-40'>Telegram LastName</small>
                                            {telegram.profile.last_name}
                                        </Box>
                                    }
                                </Box>
                            }>
                            <span onClick={() => setOpen(!open)} className={`cursor-pointer px-1 text-neon text-shadow-neon`}>
                                <MDBIcon icon='paper-plane'></MDBIcon>
                            </span>
                        </Tooltip>
                    </div>
                </ClickAwayListener>
                :
                <span className={`cursor-pointer px-1 opacity-20`}>
                    <MDBIcon className='text-shadow-neon-0' icon='paper-plane'></MDBIcon>
                </span>
        }
        {telegram && telegram.verified && <KYCCheck status={telegram.verified} />}
    </Box>
}

const KYCAddress = (address) => {
    const { country, state, city, line1, line2, long, lat, zip, status, description, createdAt, updatedAt } = address;
    const [open, setOpen] = useState(false)
    return <Box className='position-relative me-1'>
        {line1 ?
            <ClickAwayListener onClickAway={() => setOpen(false)}>
                <div>
                    <Tooltip
                        open={open}
                        TransitionComponent={Zoom}
                        classes={{ tooltip: 'bg-black box-shadow-neon px-2 m-0' }}
                        placement="bottom-end" title={
                            <Box>
                                {['line1', 'line2', 'city', 'state', 'country', 'long', 'lat', 'zip', 'description'].map((item, i) => {
                                    let result = '';
                                    if (address[item]) {
                                        result = <Box key={i} className='text-start py-1'>
                                            <small className='d-block text-capitalize opacity-40'>{item}</small>
                                            {address[item]}
                                        </Box>
                                    }
                                    return result;
                                }
                                )}
                                <Box className={`text-center fw-bold text-capitalize text-${getStatus(status).color}`}>
                                    {status}
                                </Box>
                            </Box>
                        }>
                        <span onClick={() => setOpen(!open)} className={`cursor-pointer px-1  ${!line1 ? ' opacity-30 ' : 'text-neon '}`}>
                            <MDBIcon icon='map-marker-alt'></MDBIcon>
                        </span>
                    </Tooltip>
                </div>
            </ClickAwayListener>
            :
            <span onClick={() => setOpen(!open)} className={`cursor-pointer px-1  ${!line1 ? ' opacity-30 ' : 'text-neon '}`}>
                <MDBIcon icon='map-marker-alt'></MDBIcon>
            </span>
        }
        <KYCCheck status={status} />
    </Box>
}

const KYCScore = (props) => {
    const { score, documents } = props;
    const color = score <= 10 ? 'danger' :
        score <= 20 ? 'warning' :
            score < 50 ? 'yellow' :
                score <= 80 ? 'info' :
                    score <= 100 ? 'success' : 'white';
    if (documents) {
        let pending = false;
        Object.keys(documents).map((doc, i) => {
            const status = documents[doc].status || null;
            if (status == 'pending') pending = true;
        })
        if (pending) return <small className={`ps-1 text-${color}`}>
            <MDBIcon className='px-0 text-shadow-neon' color='neon' icon='spinner' spin></MDBIcon>
        </small>
    }
    return <small className={`ps-1 text-${color}`}>
        {JSON.stringify(score)}
    </small>
}

const KYCCheck = (props) => {
    const { status } = props;
    if (!status) return <></>
    return <Box className='position-absolute' sx={{
        fontSize: '0.7rem',
        bottom: -2,
        right: -4,
    }}>
        {
            status == 'verified' || status == true || status == 'confirmed' ?
                <MDBIcon className='text-success text-shadow-success' icon='check-circle'></MDBIcon>
                :
                <MDBIcon className='text-warning  text-shadow-warning' spin icon='spinner'></MDBIcon>
        }
    </Box >
}

const UserCardFooter = (user) => {
    const { wallet } = user;
    const { BTC, DOGE, ETH, TRX, USD, USDT } = wallet;
    let counts = {
        deposit: 0,
        withdraw: 0,
        topup: 0,
        play: 0,
    }

    for (const [key, details] of Object.entries(wallet)) {
        counts.deposit += details.deposit && details.deposit.count ? details.deposit.count : 0;
        counts.withdraw += details.withdraw && details.withdraw.count ? details.withdraw.count : 0;
        counts.topup += details.topup && details.topup.count ? details.topup.count : 0;
    }
    const [type, setType] = useState('')
    return <Box>
        <Box sx={{ fontSize: '0.8rem' }} className='d-flex'>
            <UserCardFooterItem type={type} setType={setType} title='deposit' color='success' count={counts.deposit} />
            <UserCardFooterItem type={type} setType={setType} title='withdraw' color='danger' count={counts.withdraw} />
            <UserCardFooterItem type={type} setType={setType} title='topup' color='info' count={counts.topup} />
            <UserCardFooterItem type={type} setType={setType} title='play' color='warning' />
        </Box>
        <Box className='overflow-scroll'>
            {type &&
                <TransactionList view='v2' key={type} userId={user.userId} type={type} />
            }
        </Box>
    </Box>
}

const UserCardFooterItem = (props) => {
    const { title, color, count, setType, type } = props;
    return <Box
        onClick={() => { if (count && type != title) { setType(title) } else { setType('') } }}
        sx={{ flex: 1, lineHeight: '1rem' }}
        className={`${count ? '' : 'opacity-30'} position-relative box-shadow-${type == title ? color : ''} text-center fw-bold py-1 bg-${color}`}>
        <span className='text-capitalize'>
            {title}
        </span>
        <div>
            {count}
        </div>
        {
            type == title &&
            <div style={{ height: '2px', bottom: 0, left: 0 }} className='w-100 position-absolute bg-white d-block'></div>
        }
    </Box>
}

export default UserList
