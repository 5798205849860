import React, { useState, useEffect, useContext } from 'react';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import { Box, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import { Context } from '../../context/store';
import ThemeInput from '../../layout/components/ThemeInput';
import ThemeButton from '../../layout/components/ThemeButton';
import { getBearer } from '../../helpers/publicHelper';
import { useSnackbar } from 'notistack';

const UserChangePasswordModal = (props) => {
    const [state, dispatch] = useContext(Context);
    const { setChangeUserPasswordModal, changeUserPasswordModal, setRows, getUsersFromAPI } = props;
    const { selectedUser, open } = changeUserPasswordModal;
    const { enqueueSnackbar } = useSnackbar();

    const handleClose = () => {
        setChangeUserPasswordModal({ ...changeUserPasswordModal, open: false });
    }

    const [values, setValues] = useState({
        amount: '',
        comment: '',
        currency: 'USD'
    })

    useEffect(() => {
        setValues({
            newPassword: ''
        })
    }, [changeUserPasswordModal.selectedUser])

    const setNewPassword = (user, values, handleClose, setRows, mode) => {
        var postData = {
            _id: user._id,
            newPassword: values.newPassword
        };
        axios.post(state.active.host + (mode == 'admin' ? '/admin/users/changepassword' : '/manager/users/changepassword'), postData, {
            headers: { 'x-auth-token': getBearer() }
        })
            .then(res => {
                res = res.data;
                if (res.success) {
                    enqueueSnackbar(res.message, { variant: 'success' })
                } else {
                    enqueueSnackbar(res.message, { variant: 'error' })
                }
            })
            .catch((err) => {
                if (err.response) {
                    enqueueSnackbar(err.response.data, { variant: 'error' })
                } else {
                    enqueueSnackbar(err, { variant: 'error' })
                }
            }).then(() => {
                getUsersFromAPI(setRows);
                handleClose();
            })
    }

    const setNewAdminPassword = (user, values, handleClose, setRows) => {
        var postData = {
            _id: user._id,
            newPassword: values.newPassword
        };
        axios.post(state.active.host + ('/admin/users/changeAdminPassword'), postData, {
            headers: { 'x-auth-token': getBearer() }
        })
            .then(res => {
                res = res.data;
                if (res.success) {
                    enqueueSnackbar(res.message, { variant: 'success' })
                } else {
                    enqueueSnackbar(res.message, { variant: 'error' })
                }
            })
            .catch((err) => {
                if (err.response) {
                    enqueueSnackbar(err.response.data, { variant: 'error' })
                } else {
                    enqueueSnackbar(err, { variant: 'error' })
                }
            }).then(() => {
                getUsersFromAPI(setRows);
                handleClose();
            })
    }

    return <Modal
        open={open}
        disableScrollLock={true}
        onClose={handleClose}
        disableBackdropClick={true}>
        <Box className='box-shadow-neon rounded-6 overflow-hidden' sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            '.h-100':{
                height: 'auto !important'
            }
        }}>
            <MDBRow className='m-0 bg-black py-2'>
                <MDBCol size='12'>
                    <Typography className='text-neon' variant='body2'>
                        <strong className='text-neon'>Change User Password</strong>
                        <MDBIcon onClick={handleClose} className='float-end pt-1 cursor-pointer' icon='times'></MDBIcon>
                    </Typography>
                    <hr className='my-2' />
                    <Typography variant='body2'>
                        <strong className='text-neon'>Userame: </strong>
                        <span className='text-neon'>{selectedUser.username}</span>
                    </Typography>
                    <Typography variant='body2'>
                        <strong className='text-neon'>User Id: </strong>
                        <span className='text-neon'>{selectedUser.userId}</span>
                    </Typography>
                    <hr className='my-2' />
                </MDBCol>

                <form className='' onSubmit={e => { e.preventDefault(); setNewPassword(selectedUser, values, handleClose, setRows, 'admin') }} noValidate autoComplete="off">
                    <h6 className='text-warning'>User Main Password</h6>
                    <ThemeInput
                        label='New Password'
                        value={values.newPassword}
                        placeHolder=''
                        onChange={e => setValues({ ...values, newPassword: e.currentTarget.value })}
                    ></ThemeInput>

                    <div className='text-end mt-3'>
                        <ThemeButton className='px-3' type='submit' variant='outlined' color={'danger'} disabled={values.newPassword == ''}>Set New Password For User</ThemeButton>
                    </div>
                </form>
                <hr className='my-2' />
                <form className='' onSubmit={e => { e.preventDefault(); setNewAdminPassword(selectedUser, values, handleClose, setRows) }} noValidate autoComplete="off">
                    <h6 className='text-warning'>Admin Password</h6>
                    <ThemeInput
                        label='New Password'
                        value={values.newPassword}
                        placeHolder=''
                        onChange={e => setValues({ ...values, newPassword: e.currentTarget.value })}
                    ></ThemeInput>

                    <div className='text-end mt-3'>
                        <ThemeButton className='px-3' type='submit' variant='outlined' color={'success'} disabled={values.newPassword == ''}>Set New Password For Admin</ThemeButton>
                    </div>
                </form>
                <hr className='my-2' />
                <form className='' onSubmit={e => { e.preventDefault(); setNewPassword(selectedUser, values, handleClose, setRows, 'manager') }} noValidate autoComplete="off">
                    <h6 className='text-warning'>Manager</h6>
                    <div className='text-end mt-3'>
                        <ThemeButton className='px-3' type='submit' variant='outlined' color={'success'}>Generate and send to the user</ThemeButton>
                    </div>
                </form>
            </MDBRow>
        </Box>
    </Modal>
}

export default UserChangePasswordModal
