import { Avatar, Badge, Grid, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Context } from '../../context/store';
import SupportChatBox from './SupportChatBox';
import { useSnackbar } from 'notistack';
import { socket } from '../../context/socket';
import { getBearer } from '../../helpers/publicHelper';

const formatTime = (updatedAt) => {
    const today = new Date().setHours(0, 0, 0, 0);
    const updateDay = new Date(updatedAt).setHours(0, 0, 0, 0);
    const isToday = today == updateDay;
    if (isToday) return <small className='opacity-70 text-neon fw-bold'>{moment(updatedAt).format('h:mm A')}</small>
    return <small className='opacity-70'>{moment(updatedAt).format('MM/DD')}</small>
}

const getChatsList = (state, setChatList, enqueueSnackbar, search) => {
    axios.post(state.active.host + '/support/chats', {
        // sort: {updatedAt: 1},
        find: {
            'messages.0': { $exists: true }
        }
    }, {
        headers: { 'x-auth-token': getBearer() }
    })
        .then(res => {
            res = res.data;
            if (res.success) {
                setChatList(res.result);
                // enqueueSnackbar('Chat list updated.', { variant: 'warning', autoHideDuration: 2500 })
            } else {
                console.log(res.message)
            }
        })
        .catch(err => {
            console.log(err)
        })
}

const ChatList = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const { activeChat, setActiveChat } = props;
    const [state, dispatch] = useContext(Context);

    const [chatList, setChatList] = useState([]);

    const chatRef = useRef();
    chatRef.current = chatList;
    useEffect(() => {
        socket.on('chat', message => {
            console.log('supportchat', 'chat')
            getChatsList(state, setChatList, enqueueSnackbar)
            // enqueueSnackbar('chat!', { variant: 'info', autoHideDuration: 2500 })
        })
        getChatsList(state, setChatList, enqueueSnackbar)
    }, [activeChat])

    return <MDBRow className=''>
        {chatList.map((chat, i) => {
            const { userId, updatedAt, messages, members } = chat;
            const user = members[userId] ? members[userId] : { username: '!', avatar: '!' };
            const { username, avatar } = user;
            const lastText = messages[messages.length - 1] ? messages[messages.length - 1].text : '';
            return <MDBCol
                style={{
                    background: activeChat.userId == userId ? '#004466' : 'transparent'
                }}
                key={i} size='12' className='cursor-pointer' onClick={() => setActiveChat(chat)} >
                <Grid container alignItems='center'>
                    <Grid item className='pe-2'>
                        <Stack direction="row" spacing={2}>
                            <Badge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            // badgeContent={
                            //     <Avatar sx={{
                            //         width: 22,
                            //         height: 22,
                            //         border: '1px solid #000'
                            //     }} alt="admin" src="https://mui.com/static/images/avatar/3.jpg" />
                            // }
                            >
                                <Avatar src={avatar || ''} />
                            </Badge>
                        </Stack>

                        {/* {avatar ? avatar :
                            <Avatar className={activeChat.userId == userId ? 'box-shadow-neon' : ''} sx={{ width: '2rem', height: '2rem' }} alt="admin" src="https://mui.com/static/images/avatar/3.jpg" />
                        } */}
                    </Grid>
                    <Grid item flex='1'>
                        <Typography component='div' variant='body2' className='pb-1'>
                            <strong className={'pt-1 d-inline-block ' + (activeChat.userId == userId ? 'text-neon text-shadow-neon ' : '')}>{username}</strong>
                            <Typography component='span' variant='subtitle2' className='float-end pt-1'>{formatTime(updatedAt)}</Typography>
                        </Typography>
                        <Typography
                            variant='subtitle2'
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                webkitBoxOrient: 'vertical',
                                webkitLineClamp: 1,
                                lineHeight: '0.75rem',
                                maxHeight: '1.5rem'
                            }}>

                            <div>
                                {lastText}
                            </div>
                        </Typography>
                        <hr className='mt-2 mb-0' style={{ backgroundColor: activeChat.userId == userId ? '#004466' : '#0099aa' }} />
                    </Grid>
                </Grid>
            </MDBCol>
        }
        )}

    </MDBRow>
}

const SupportChat = () => {
    const [activeChat, setActiveChat] = useState({})

    useEffect(() => {
        console.log('activeChat', activeChat)
    }, [activeChat])

    return (
        <MDBRow className='m-0' style={{ height: 'calc(100vh - 80px)' }}>
            <MDBCol md='2' className='border-end ps-0  h-100 overflow-scroll'>
                <ChatList activeChat={activeChat} setActiveChat={setActiveChat}></ChatList>
            </MDBCol>
            <MDBCol md='10' className='position-relative h-100 overflow-scroll'>
                {activeChat ?
                    <SupportChatBox activeChat={activeChat}></SupportChatBox>
                    : <></>}
            </MDBCol>
        </MDBRow>
    )
}

export default SupportChat
