import React, { useEffect, useState } from 'react'
import { getReportGraph } from '../../helpers/reportHelper';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement,
} from 'chart.js';
import { Bar, Line, Chart } from 'react-chartjs-2';
import { Box } from '@mui/material';
import numeral from 'numeral';
import moment from 'moment';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    // plugin
);
const ReportGraph = (props) => {
    const { filter } = props;

    const [data, setData] = useState({
        balance: {
            first: [],
            last: [],
        },
        bet: { bet: {}, win: {}, open: {}, cashback: {} },
        deposit: { deposit: {}, bonus: {} },
        labels: [''],
        topup: { withdraw: {}, deposit: {}, discount: {}, bonus: {} },
        withdraw: { withdraw: {}, rollback: {} }
    });
    const [options, setOptions] = useState({
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                grid: {
                    color: '#fff5'
                }
            },
            x: {
                grid: {
                    color: '#fff5'
                }
            }
        },
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: `Report graph ${moment.utc(filter.from).format('YYYY/MM/DD HH:mm:ss UTC')} to  ${moment.utc(filter.to).format('YYYY/MM/DD HH:mm:ss UTC')} per ${filter.step}`,
            },
        }
    });
    const [graphData, setGraphData] = useState({
        labels: [],
        datasets: [],
    })

    useEffect(() => {
        const newDateSets = {
            labels: data.labels,
            datasets: [
                {
                    label: 'Deposit',
                    data: data.deposit.deposit.totalAmountInUSD,
                    backgroundColor: '#00b74a',
                },
                {
                    label: 'Withdraw',
                    data: data.withdraw.withdraw.totalAmountInUSD,
                    backgroundColor: '#f93154',
                },
                {
                    label: 'Topup',
                    data: data.topup.discount.totalAmountInUSD,
                    backgroundColor: '#26ddff',
                }
            ],
        }

        setGraphData(newDateSets);


    }, [data])

    useEffect(() => {
        getReportGraph(setData, filter);
    }, [])



    return (
        <Box sx={{
            height: '400px',
            width: '99%',
            position: 'relative',
        }}
        >
            <Bar data={graphData} options={options} />
        </Box>
    )
}

export default ReportGraph