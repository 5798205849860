import React, { useEffect, useState } from 'react';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import _ from 'lodash'
import ThemeInput from '../../../layout/components/ThemeInput';
import ThemeSelect from '../../../layout/components/ThemeSelect';
import numeral from 'numeral';
import Collapse from '@mui/material/Collapse';
import CopyToClipboard from 'react-copy-to-clipboard';
import ReactJson from 'react-json-view';
import ThemeButton from '../../../layout/components/ThemeButton';
import ThemeDatePicker from '../../../layout/components/ThemeDatePicker';
import ThemeMultiSelect from '../../../layout/components/ThemeMultiSelect';
import { isMobile, isMobileOnly } from 'react-device-detect'
// import ThemeBreadCrumb from '../../../layout/components/ThemeBreadCrumb';


const availableModes = ['sport', 'relatedSport', 'evo', 'relatedEvo', 'em', 'relatedEm', 'fullHistory'];

const getPreviousMonth = () => {
    let date = new Date();
    let from = new Date(date.getFullYear(), date.getMonth(), 1);
    return from
}
const getLastDayOfCurrentMonth = () => {
    let date = new Date();
    let to = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    return to
}

function getFloatValue(value) {
    console.log(value);
    if (value.slice(-1) == '.') {
        const dotCount = value.match(/\./g).length;
        if (dotCount > 1) {
            return value.slice(0, -1)
        } else {
            return value
        }
    }
    if (value == 0) return 0;
    return parseFloat(value.trim())
}

const CrashReportFilter = (props) => {
    const { filter, setFilter, count, getTransactions, setRows } = props;
    const [showMore, setShowMore] = useState({
        filters: false,
        searchQuery: false
    })

    const [find, setFind] = useState({
        ...filter.find,
        mode: [],
        userId: filter.find.userId || '',
        // topupBy: filter.find.topupBy || '',
        // title: filter.find.title || '',
        betId: filter.find.betId || '',
        fromDate: getPreviousMonth(),
        toDate: getLastDayOfCurrentMonth(),
        // type: filter.find.type || '',
        bets: '',
        MD5: '',
        currency: '',
        email: false,
        mobile: false,
        telegram: false,
        deposit: false,
        withdraw: false,
        topup: false,
        sport: false,
        casino: false,
        crash: false,
        poker: false,
        // isValid: 'true',
        // More Fiters
        betAmountMin: 0,
        betAmountMax: '',
        oddsMin: 0,
        oddsMax: '',
    })

    const [sort, setSort] = useState(filter.sort)
    const [limit, setLimit] = useState(filter.limit)

    const handleSetFilter = () => {
        let findQuery = { ...find, $and: [] }
        findQuery.$and.push({
            server: 'iMoon-Crash-API'
        })
        // UserId
        if (find.userId && (find.userId.trim() != '')) {
            findQuery.$and.push({
                $or: [
                    { "bets": { $elemMatch: { playerId: { $regex: find.title || '', $options: "i" } } } },
                ]
            })
        } else {
            findQuery = _.omit(findQuery, ['userId']);
        }

        // Bets
        if (find.bets && (find.bets.trim() != '')) {
            if (find.bets == 'yes') {
                findQuery.$and.push({
                    'bets': { $ne: {} }
                })
            } else {
                findQuery.$and.push({
                    'bets': { $eq: {} }
                })
            }
        } else {
            findQuery = _.omit(findQuery, ['bets']);
        }
        // Currency
        if (find.currency && (find.currency.trim() != '')) {
            findQuery.currency = find.currency
        } else {
            findQuery = _.omit(findQuery, ['currency']);
        }
        // MD5
        if (find.MD5 && (find.MD5.trim() != '')) {
            findQuery.$and.push({
                'crash.md5': { $regex: find.MD5 || '', $options: "i" },
            })
        } else {
            findQuery = _.omit(findQuery, ['MD5']);
        }

        // From Date
        if (find.fromDate) {
            findQuery.$and.push({
                'createdAt': { $gt: find.fromDate }
            })
        }
        // To Date
        if (find.toDate) {
            findQuery.$and.push({
                'createdAt': { $lt: find.toDate }
            })
        }

        // BetAmount
        if (find.betAmountMin > 0) {
            findQuery.$and.push({
                'meta.bet.betAmountInUSD': { $gt: parseFloat(find.betAmountMin) }
            })
        }
        if (find.betAmountMax) {
            findQuery.$and.push({
                'meta.bet.betAmountInUSD': { $lte: parseFloat(find.betAmountMax), $gt: 0 },
            })
            findQuery.$and.push({
                'service': { $nin: ['iM-API', 'CryptoPay-API', 'Telegram'] },
            })
        }

        // isValid
        if (find.isValid == 'true') {
            findQuery.isValid = true;
        }
        if (find.isValid == 'false') {
            findQuery.isValid = false;
        }
        if (find.isValid == 'all') {
            findQuery = _.omit(findQuery, ['isValid'])
        }

        findQuery = _.omit(findQuery, [
            'mode', 'bets', 'MD5',
            'fromDate', 'toDate',
            'betId', 'usernameEmail',
            'hasBalance', 'walletAddress',
            'casino', 'crash', 'sport', 'poker',
            'deposit', 'withdraw', 'topup',
            'email', 'mobile', 'telegram',
            'oddsMin', 'oddsMax',
            'betAmountMax', 'betAmountMin',
        ]);

        if (findQuery.$and.length == 0) findQuery = _.omit(findQuery, ['$and']);
        const modeObject = Object.fromEntries(availableModes.map(m => [[m], find.mode.indexOf(m) > -1 ? true : false]));
        let filterObject = {
            find: findQuery,
            sort: sort,
            limit: limit,
        }
        filterObject.mode = modeObject;
        setFilter(filterObject);
        getTransactions(setRows, filterObject);
    }

    useEffect(() => {
        handleSetFilter()
    }, [find, sort, limit])

    const [showFilters, setShowFilters] = useState(!isMobileOnly);

    return (
        <MDBRow className='m-0'>
            <MDBCol size='12' className='mb-2 d-md-none px-1 small d-flex align-items-center justify-content-between'>
                {<small className='border border-neon text-neon rounded-pill px-2' onClick={() => setShowFilters(!showFilters)}><MDBIcon icon={`angle-${showFilters ? 'up' : 'down'}`}></MDBIcon> Filters</small>}
            </MDBCol>
            <Collapse in={showFilters}>
                <MDBCol size='12 pb-1' style={{ backgroundColor: '#000' }}>
                    <form onSubmit={e => { e.preventDefault(); handleSetFilter() }}>
                        <MDBRow>
                            <MDBCol size='12' md='2' xl='1' className='p-1'>
                                <ThemeInput inputClassName={find.userId != '' ? 'box-shadow-success border-success rounded-3 border' : ''} type='search' label='User Id' value={find.userId} onChange={e => setFind({ ...find, userId: e.target.value.trim() })}></ThemeInput>
                            </MDBCol>
                            <MDBCol size='12' md='2' xl='2' className='p-1'>
                                <ThemeInput inputClassName={find.MD5 != '' ? 'box-shadow-success border-success rounded-3 border' : ''} type='search' label='MD5' value={find.MD5} onChange={e => setFind({ ...find, MD5: e.target.value.trim() })}></ThemeInput>
                            </MDBCol>
                            <MDBCol size='12' md='2' xl='1' className='p-1'>

                            </MDBCol>
                            <MDBCol size='12' md='2' xl='1' className='p-1'>

                            </MDBCol>
                            <MDBCol size='12' md='2' xl='1' className='p-1'>
                                <ThemeSelect className={find.bets ? 'box-shadow-success border border-success ' : ''} value={find.bets} label='Bets' onChange={e => setFind({ ...find, bets: e.target.value.trim() })}>
                                    <option value=''>All</option>
                                    <option value='yes'>Yes</option>
                                    <option value='no'>No</option>
                                </ThemeSelect>
                            </MDBCol>

                            <MDBCol size='12' md='4' xl='3' className='p-1 d-flex justify-content-between'>
                                <ThemeDatePicker className='pe-1' label='From' value={find.fromDate} onChange={fromDate => setFind({ ...find, fromDate })}></ThemeDatePicker>
                                <ThemeDatePicker className='ps-1' label='To' value={find.toDate} onChange={toDate => setFind({ ...find, toDate })}></ThemeDatePicker>
                            </MDBCol>
                            <MDBCol size='12' md='2' xl='1' className='p-1'>
                                <ThemeSelect value={JSON.stringify(sort)} label='Sort' onChange={e => setSort(JSON.parse(e.target.value))}>
                                    <option value={JSON.stringify({ updatedAt: -1 })}>Update At</option>
                                    <option value={JSON.stringify({ createdAt: -1 })}>Created At</option>
                                    <option value={JSON.stringify({ 'crash.point': -1 })}>CrashPoint</option>
                                    {/* <option value={JSON.stringify({ amount: -1 })}>{`Amount (High > Low)`}</option>
                                    <option value={JSON.stringify({ amount: 1 })}>{`Amount (Low > High)`}</option> */}
                                </ThemeSelect>
                            </MDBCol>
                            <MDBCol size='12' md='2' xl='1' className='p-1'>
                                <ThemeSelect value={limit} label='Page Size' onChange={e => setLimit(parseInt(e.target.value))}>
                                    <option value={10}>10</option>
                                    <option value={15}>15</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={200}>200</option>
                                    <option value={500}>500</option>
                                </ThemeSelect>
                            </MDBCol>
                            <MDBCol size='12' md='2' xl='1' className='px-1 text-neon pt-2 text-shadow-neon text-start d-flex justify-content-between align-items-center'>
                                <MDBIcon className='cursor-pointer' onClick={() => setShowMore({ ...showMore, filters: !showMore.filters })} icon={showMore.filters ? 'chevron-up' : 'chevron-down'}></MDBIcon>
                                <small className='' style={{ lineHeight: '0.8rem' }}>
                                    {numeral(count).format('0,0')}
                                    <div>record found.</div>
                                </small>
                            </MDBCol>
                        </MDBRow>
                        <Collapse in={showMore.filters} timeout="auto" unmountOnExit>
                            <MDBRow className='pt-2'>
                                <MDBCol size='12' md='3' xl='1' className='p-1'>
                                    <ThemeInput inputClassName={find.topupBy != '' ? 'box-shadow-success border-success rounded-3 border' : ''} type='search' label='Topup By' value={find.topupBy} onChange={e => setFind({ ...find, topupBy: e.target.value.trim() })}></ThemeInput>
                                </MDBCol>
                                <MDBCol size='12' md='3' xl='1' className='p-1'>
                                    <ThemeInput inputClassName={find.betAmountMin > 0 ? 'box-shadow-success border-success rounded-3 border' : ''} type='search' labelClass='text-warning' label='Bet Amount Min' value={find.betAmountMin} onChange={e => setFind({ ...find, betAmountMin: getFloatValue(e.target.value) })}></ThemeInput>
                                </MDBCol>
                                <MDBCol size='12' md='3' xl='1' className='p-1'>
                                    <ThemeInput inputClassName={find.betAmountMax ? 'box-shadow-success border-success rounded-3 border' : ''} type='search' labelClass='text-warning' label='Bet Amount Max' value={find.betAmountMax} onChange={e => setFind({ ...find, betAmountMax: getFloatValue(e.target.value) })}></ThemeInput>
                                </MDBCol>
                                <MDBCol size='12' md='3' xl='1' className='p-1'>
                                    <ThemeInput inputClassName={find.oddsMin > 0 ? 'box-shadow-success border-success rounded-3 border' : ''} type='search' labelClass='text-danger' label='Odds Min' value={find.oddsMin} onChange={e => setFind({ ...find, oddsMin: getFloatValue(e.target.value) })}></ThemeInput>
                                </MDBCol>
                                <MDBCol size='12' md='3' xl='1' className='p-1'>
                                    <ThemeInput inputClassName={find.oddsMax != '' ? 'box-shadow-success border-success rounded-3 border' : ''} type='search' labelClass='text-danger' label='Odds Max' value={find.oddsMax} onChange={e => setFind({ ...find, oddsMax: getFloatValue(e.target.value) })}></ThemeInput>
                                </MDBCol>
                                <MDBCol size='12' md='3' xl='1' className='p-1'>
                                    <ThemeSelect value={find.isValid} label='IsValid' onChange={e => setFind({ ...find, isValid: e.target.value })}>
                                        <option value='all'>All</option>
                                        <option value='true'>Valid</option>
                                        <option value='false'>Invalid</option>
                                    </ThemeSelect>
                                </MDBCol>
                                <MDBCol size='12' md='3' xl='2' className='p-1'>
                                    <ThemeMultiSelect
                                        label='Meta Fields'
                                        maxShow={3}
                                        value={find.mode}
                                        setValue={e => setFind({ ...find, mode: e })}
                                        options={['sport', 'relatedSport', 'evo', 'relatedEvo', 'em', 'relatedEm', 'fullHistory']}
                                    ></ThemeMultiSelect>
                                </MDBCol>
                                <MDBCol size='12' md='3' xl='1' className='px-1 text-success pt-1 text-shadow-neon text-start d-flex justify-content-start align-items-center'>
                                    <MDBIcon className='cursor-pointer' onClick={() => setShowMore({ ...showMore, searchQuery: !showMore.searchQuery })} icon={showMore.searchQuery ? 'chevron-up' : 'chevron-down'}></MDBIcon>
                                    <small onClick={() => setShowMore({ ...showMore, searchQuery: !showMore.searchQuery })} className='px-1 cursor-pointer' style={{ lineHeight: '0.8rem' }}>
                                        Advanced
                                    </small>
                                </MDBCol>
                            </MDBRow>
                        </Collapse>
                        <Collapse in={showMore.searchQuery} timeout="auto" unmountOnExit>
                            <MDBRow className='small'>
                                <MDBCol className='small' size='12'>
                                    <div>
                                        <h6 className='text-info d-inline-block me-3'>Search Query
                                        </h6>
                                        <CopyToClipboard text={JSON.stringify(filter) || '{}'}>
                                            <ThemeButton size='small' variant='outlined' className='rounded-pill btn-sm' color='info'>Copy to Clipboard</ThemeButton>
                                        </CopyToClipboard>
                                    </div>
                                    <div className='border border-info small'>
                                        <ReactJson name={null} style={{ padding: '0.5rem' }} theme="monokai" src={filter} />
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </Collapse>
                    </form>
                </MDBCol>
            </Collapse>
        </MDBRow>
    );
}

export default CrashReportFilter
