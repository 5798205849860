import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit'
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Stack } from '@mui/material';
import moment from 'moment';
import numeral from 'numeral';
import { getBearer } from '../../helpers/publicHelper';
import { BreadCrumbContext } from '../../context/store';

const callHealthCheck = (servers, setServers) => {
    axios.post('https://imoon-healthcheck.azurewebsites.net/api/reports', {},
        {
            headers: { 'x-auth-token': getBearer() }
        })
        .then(res => {
            res = res.data;
            setServers({ ...servers, ...res.result })
        })
        .catch((err) => {
            console.error("Call Health Check Error: ", err);
        })
}

const defaultServers = {
    'iMoon-API': {
        image: <img height='22' alt='telegram' src='/logo/logo-gold.svg' />
    },
    'iMoon-API-Stage': {
        image: <img height='22' alt='telegram' src='/logo/logo-gold.svg' />
    },
    'CryptoPay-API': {
        image: '💰 CryptoPay'
    },
    'iMoon-Rates': {
        image: 'Rates'
    },
    'iMoon-Chat': {
        image: 'Chat'
    },
    'iMoon-Telegram': {
        image: <strong className='text-info'><img style={{ width: '0.8rem' }} alt='telegram' src='/icons/telegram.svg' /> Telegram </strong>
    },
    // GAMES
    'iMoon-Evolution': {
        image: <img height='16' src='/logo/evolution_logo.svg' />
    },
    'iMoon-Evolution-Stage': {
        image: <img height='16' src='/logo/evolution_logo.svg' />
    },
    'iMoon-Digitain': {
        image: <img height='18' src='/logo/digitain_logo.svg' />
    },
    'iMoon-Digitain-Stage': {
        image: <img height='18' src='/logo/digitain_logo.svg' />
    },
    'iMoon-EveryMatrix': {
        image: <img height='18' src='/logo/everymatrix_logo.svg' />
    },
    'iMoon-EveryMatrix-Stage': {
        image: <img height='18' src='/logo/everymatrix_logo.svg' />
    },
    'iMoon-Pragmatic': {
        image: <img height='35' className='mt-n3' src='/logo/pragmatic_logo.svg' />
    },
    'iMoon-Pragmatic-Stage': {
        image: <img height='35' className='mt-n3' src='/logo/pragmatic_logo.svg' />
    },
    'iMoon-Crash': {
        image: '🚀 Crash'
    },
    'iMoon-Crash-API': {
        image: '🚀 Crash'
    },
    'iMoon-Poker': {
        image: '🃏 Poker'
    }
}

const ServersList = (props) => {
    const [servers, setServers] = useState(defaultServers);
    const [contextBC, setContextBC] = useContext(BreadCrumbContext);
    useLayoutEffect(() => {
        setContextBC([
            {
                href: '/',
                text: 'Home'
            },
            {
                text: 'Servers'
            }
        ])
    }, [])
    useEffect(() => {
        callHealthCheck(servers, setServers)
        setInterval(() => {
            callHealthCheck(servers, setServers)
        }, 5000);
    }, [])

    return (
        <MDBRow className='m-0'>
            <MDBCol size='12'>
                <h5 className='text-neon text-shadow-neon'>Core Servers</h5>
            </MDBCol>
            {Object.keys(servers).map((title, i) => {
                if (servers[title].type !== 'core') return <></>
                return <ServerCard server={servers[title]} image={defaultServers[title].image} key={i}></ServerCard>
            }
            )}
            <MDBCol size='12'>
                <hr />
                <h5 className='text-neon text-shadow-neon'>Game Servers</h5>
            </MDBCol>
            {Object.keys(servers).map((title, i) => {
                if (servers[title].type !== 'game') return <></>
                return <ServerCard server={servers[title]} image={defaultServers[title] && defaultServers[title].image} key={i}></ServerCard>
            }
            )}
            <MDBCol size='12'>
                <hr />
                <h5 className='text-warning text-shadow-warning'>Stage Servers</h5>
            </MDBCol>
            {Object.keys(servers).map((title, i) => {
                if (servers[title].type !== 'stage') return <></>
                return <ServerCard server={servers[title]} image={defaultServers[title].image} key={i}></ServerCard>
            }
            )}
        </MDBRow>
    )
}

const ServerCard = (props) => {
    const { image } = props;
    const { up, ping, title, type } = props.server;
    const meta = props.server.meta || {};
    const success = props.server.success || {};
    const error = props.server.error || {};
    const ip = meta.ip ? meta.ip : '0.0.0.0';
    const startAt = meta.startAt ? meta.startAt : Date.now();
    const log = meta.log ? meta.log : { info: 0, warning: 0, debug: 0, error: 0 };
    const color = (type !== 'stage' && up) ? 'success' : (type == 'stage' && up) ? 'warning' : 'danger';
    return <MDBCol size='12' md='3' lg='2' className='mb-3'>
        <Card className={`box-shadow-${color}`} style={{ background: !up ? '#f001' : '#0f01' }}>
            <LinearProgress color={color} style={{ height: 1, opacity: 0.5 }} />
            <Box sx={{}}>
                <CardContent>
                    <Typography component="div" variant="primary">
                        <span className='fw-bold text-warning'>
                            {image}
                        </span>
                    </Typography>
                    <div className='d-flex align-items-center justify-content-between'>
                        <Typography variant="subtitle1" color="text.secondary" component="span">
                            <MDBIcon color={color} icon='server'></MDBIcon> {title}
                        </Typography>
                        <Typography variant="subtitle2" color="text.secondary" component="span">
                            {ip}
                        </Typography>
                    </div>
                </CardContent>
                <Box sx={{ alignItems: 'center', pb: 1 }}>
                    <ServerButton icon='sync' text='Restart'></ServerButton>
                    <Link to={`/Servers/Logs/${title}`}>
                        <ServerButton icon='clipboard-list' text='Logs'></ServerButton>
                    </Link>
                    <ServerButton icon='pause' text='Stop'></ServerButton>
                </Box>
            </Box>
            <Stack
                direction="row"
                className='p-1'
                // divider={<Divider className='h-auto' orientation="vertical" flexItem />}
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
            >
                <Typography component='span' variant='subtitle2'>
                    <MDBIcon icon='signal' className='pe-1'></MDBIcon>
                    {ping}</Typography>
                <Typography
                    component='span'
                    variant='subtitle2'>
                    {moment(startAt).fromNow(true)}
                </Typography>
            </Stack>
            <hr className='p-0 m-0' />
            <MDBRow className='mx-0'>
                <MDBCol size='2'>
                </MDBCol>
                <MDBCol size='10' className='px-0'>
                    <MDBRow className='mx-0 text-neon'>
                        <MDBCol size='3' className='px-0 text-center'><Typography component='span' variant='subtitle2'>Total</Typography></MDBCol>
                        <MDBCol size='3' className='px-0 text-center'><Typography component='span' variant='subtitle2'>Today</Typography></MDBCol>
                        <MDBCol size='3' className='px-0 text-center'><Typography component='span' variant='subtitle2'>Hour</Typography></MDBCol>
                        <MDBCol size='3' className='px-0 text-center'><Typography component='span' variant='subtitle2'>Minute</Typography></MDBCol>
                    </MDBRow>
                </MDBCol>
            </MDBRow>
            <hr className='p-0 m-0' />
            <MDBRow className='mx-0'>
                <MDBCol size='2'>
                    <Typography className='' component='span' variant='subtitle2'>Logs:</Typography>
                </MDBCol>
                <MDBCol size='10' className='px-0'>
                    <MDBRow className='mx-0'>
                        <MDBCol size='3' className='px-0 text-center'><Typography className={`px-2 rounded ${log.error ? 'bg-danger' : ''}`} component='span' variant='subtitle2'>{numeral(log.error).format('0,0')}</Typography></MDBCol>
                        <MDBCol size='3' className='px-0 text-center'><Typography className={`px-2 rounded ${log.warning ? 'bg-warning' : ''}`} component='span' variant='subtitle2'>{numeral(log.warning).format('0,0')}</Typography></MDBCol>
                        <MDBCol size='3' className='px-0 text-center'><Typography className={`px-2 rounded ${log.info ? 'text-neon' : ''}`} component='span' variant='subtitle2'>{numeral(log.info).format('0,0')}</Typography></MDBCol>
                        <MDBCol size='3' className='px-0 text-center'><Typography className={`px-2 rounded ${log.debug ? 'text-success' : ''}`} component='span' variant='subtitle2'>{numeral(log.debug).format('0,0')}</Typography></MDBCol>
                    </MDBRow>
                </MDBCol>
            </MDBRow>
            <hr className='p-0 m-0' />
            <MDBRow className='mx-0'>
                <MDBCol size='2'>
                    <Typography className='' component='span' variant='subtitle2'>Success:</Typography>
                </MDBCol>
                <MDBCol size='10' className='px-0'>
                    <MDBRow className='mx-0'>
                        <MDBCol size='3' className='px-0 text-center'><Typography className={`px-2 rounded text-success`} component='span' variant='subtitle2'>{success.total}</Typography></MDBCol>
                        <MDBCol size='3' className='px-0 text-center'><Typography className={`px-2 rounded text-success`} component='span' variant='subtitle2'>{success.today}</Typography></MDBCol>
                        <MDBCol size='3' className='px-0 text-center'><Typography className={`px-2 rounded text-success`} component='span' variant='subtitle2'>{success.thisHour}</Typography></MDBCol>
                        <MDBCol size='3' className='px-0 text-center'><Typography className={`px-2 rounded text-success`} component='span' variant='subtitle2'>{success.thisMin}</Typography></MDBCol>
                    </MDBRow>
                </MDBCol>
            </MDBRow>
            <hr className='p-0 m-0' />
            <MDBRow className='mx-0'>
                <MDBCol size='2'>
                    <Typography className='' component='span' variant='subtitle2'>Error:</Typography>
                </MDBCol>
                <MDBCol size='10' className='px-0'>
                    <MDBRow className='mx-0'>
                        <MDBCol size='3' className='px-0 text-center'><Typography className={`px-2 rounded ${error.total ? 'bg-danger' : ''}`} component='span' variant='subtitle2'>{error.total}</Typography></MDBCol>
                        <MDBCol size='3' className='px-0 text-center'><Typography className={`px-2 rounded ${error.today ? 'bg-danger' : ''}`} component='span' variant='subtitle2'>{error.today}</Typography></MDBCol>
                        <MDBCol size='3' className='px-0 text-center'><Typography className={`px-2 rounded ${error.thisHour ? 'bg-danger' : ''}`} component='span' variant='subtitle2'>{error.thisHour}</Typography></MDBCol>
                        <MDBCol size='3' className='px-0 text-center'><Typography className={`px-2 rounded ${error.thisMin ? 'bg-danger' : ''}`} component='span' variant='subtitle2'>{error.thisMin}</Typography></MDBCol>
                    </MDBRow>
                </MDBCol>
            </MDBRow>
        </Card>
    </MDBCol>
}


const ServerButton = (props) => {
    const { icon, text } = props;
    return <span className='text-center d-inline-block cursor-pointer p-2 small rounded-5 text-hover-neon'>
        <MDBIcon icon={icon}></MDBIcon>
        <div className='small'>
            <small>{text}</small>
        </div>
    </span>
}

export default ServersList
