import { Box } from '@mui/system';
import { MDBIcon } from 'mdb-react-ui-kit';
import React from 'react';

const serviceLogos = {
    // 'website': {
    //     x: 0,
    //     y: 0
    // },
    // 'im-api': {
    //     x: 0,
    //     y: 0
    // },
    'crash': {
        x: 0,
        y: -7
    },
    'telegram': {
        x: 0,
        y: -9
    },
    'cryptopay-api': {
        x: 0,
        y: -8
    },
    'imoon': {
        x: 0,
        y: -6
    },
    'everymatrix': {
        x: 0,
        y: 0
    },
    'evolution': {
        x: 0,
        y: -1
    },
    'netent': {
        x: 0,
        y: -2
    },
    'redtiger': {
        x: 0,
        y: -3
    },
    'pragmatic': {
        x: 0,
        y: -4
    },
    'digitain': {
        x: 0,
        y: -5
    }
}

const RenderService = (props) => {
    let { service, size, full, sx } = props;
    if (!service) return <Box sx={sx}>{service}</Box>
    if (!serviceLogos[service.toLowerCase()]) return <Box sx={sx}>{service}</Box>

    size = size || 20;
    const width = full ? (6 * size) : size;
    const bgX = full ? (6 * size) : (serviceLogos[service.toLowerCase()].x * size);
    const bgY = serviceLogos[service.toLowerCase()].y * size;

    return <>
        {serviceLogos[service.toLowerCase()] ? <Box sx={{
            margin: 'auto',
            zIndex: 1,
            width: width + 'px',
            height: size + 'px',
            backgroundImage: 'url("/logo/provider-logo-sprites.png")',
            backgroundPositionX: `${bgX}px`,
            backgroundPositionY: `${bgY}px`,
            backgroundSize: (7 * size) + 'px',
            ...sx
        }}>
        </Box> : <Box sx={sx}>{service}</Box>}
    </>
}

export default RenderService