import BTC from 'cryptocurrency-icons/svg/icon/btc.svg'
import LTC from 'cryptocurrency-icons/svg/icon/ltc.svg'
import ETH from 'cryptocurrency-icons/svg/icon/eth.svg'
import TRX from 'cryptocurrency-icons/svg/icon/trx.svg'
import USD from 'cryptocurrency-icons/svg/icon/usd.svg'
import EUR from 'cryptocurrency-icons/svg/icon/eur.svg'
import CNY from 'cryptocurrency-icons/svg/icon/cny.svg'
import JPY from 'cryptocurrency-icons/svg/icon/jpy.svg'
import GBP from 'cryptocurrency-icons/svg/icon/gbp.svg'
import RUB from 'cryptocurrency-icons/svg/icon/rub.svg'
import DOGE from 'cryptocurrency-icons/svg/icon/doge.svg'
import USDT from 'cryptocurrency-icons/svg/icon/usdt.svg'

import BTC_PNG from 'cryptocurrency-icons/32/icon/btc.png'
import LTC_PNG from 'cryptocurrency-icons/32/icon/ltc.png'
import ETH_PNG from 'cryptocurrency-icons/32/icon/eth.png'
import TRX_PNG from 'cryptocurrency-icons/32/icon/trx.png'
import USD_PNG from 'cryptocurrency-icons/32/icon/usd.png'
import EUR_PNG from 'cryptocurrency-icons/32/icon/eur.png'
import CNY_PNG from 'cryptocurrency-icons/32/icon/cny.png'
import JPY_PNG from 'cryptocurrency-icons/32/icon/jpy.png'
import GBP_PNG from 'cryptocurrency-icons/32/icon/gbp.png'
import RUB_PNG from 'cryptocurrency-icons/32/icon/rub.png'
import DOGE_PNG from 'cryptocurrency-icons/32/icon/doge.png'
import USDT_PNG from 'cryptocurrency-icons/32/icon/usdt.png'

// Available Currencies
const currencyList = ['USDT', 'BTC', 'ETH', 'DOGE', 'TRX', 'USD'];

// Currency Detail
const currencyDetail = {
    BTC: {
        title: 'Bitcoin',
        symbol: 'BTC',
        symbol2: 'BTC',
        format: '0,0.00[000000]',
        icon: BTC,
        pngIcon: BTC_PNG,
        type: 'crypto',
        decimal: 8
    },
    LTC: {
        title: 'Litecoin',
        symbol: 'LTC',
        symbol2: 'LTC',
        format: '0,0.00[000000]',
        icon: LTC,
        pngIcon: LTC_PNG,
        type: 'crypto',
        decimal: 8
    },
    ETH: {
        title: 'Ethereum',
        symbol: 'ETH',
        symbol2: 'ETH',
        format: '0,0.00[0000]',
        icon: ETH,
        pngIcon: ETH_PNG,
        type: 'crypto',
        decimal: 6
    },
    TRX: {
        title: 'Tron',
        symbol: 'TRX',
        symbol2: 'TRX',
        format: '0,0.00',
        icon: TRX,
        pngIcon: TRX_PNG,
        type: 'crypto',
        decimal: 2
    },
    DOGE: {
        title: 'Dogecoin',
        symbol: 'DOGE',
        symbol2: 'DOGE',
        format: '0,0.00',
        icon: DOGE,
        pngIcon: DOGE_PNG,
        type: 'crypto',
        decimal: 2
    },
    USDT: {
        title: 'Tether',
        symbol: 'USDT',
        symbol2: 'USDT',
        format: '0,0.00',
        icon: USDT,
        pngIcon: USDT_PNG,
        type: 'crypto',
        decimal: 2
    },
    USDTE: {
        title: 'Tether (ERC-20)',
        symbol: 'USDT',
        symbol2: 'USDT-E',
        format: '0,0.00',
        icon: USDT,
        pngIcon: USDT_PNG,
        type: 'crypto',
        decimal: 2
    },
    USDTT: {
        title: 'Tether (TRC-20)',
        symbol: 'USDT',
        symbol2: 'USDT-T',
        format: '0,0.00',
        icon: USDT,
        pngIcon: USDT_PNG,
        type: 'crypto',
        decimal: 2
    },
    // Fiats ***************************
    USD: {
        title: 'US Dollar',
        symbol: 'USD',
        symbol2: 'USD',
        format: '0,0.00',
        icon: USD,
        pngIcon: USD_PNG,
        type: 'fiat',
        decimal: 2
    },
    inUSD: {
        title: 'US Dollar',
        symbol: 'USD',
        symbol2: 'USD',
        format: '0,0.00',
        icon: USD,
        pngIcon: USD_PNG,
        type: 'fiat',
        decimal: 2
    },
    EUR: {
        title: 'Euro',
        symbol: 'EUR',
        symbol2: 'EUR',
        format: '0,0.00',
        icon: EUR,
        pngIcon: EUR_PNG,
        type: 'fiat',
        decimal: 2
    },
    IRT: {
        title: 'تومان',
        symbol: 'IRT',
        symbol2: 'IRT',
        format: '0,0',
        icon: '/cryptoicons/irt.svg',
        type: 'fiat',
        decimal: 0
    },
    IRR: {
        title: 'ریال',
        symbol: 'IRR',
        symbol2: 'IRR',
        format: '0,0',
        icon: '/cryptoicons/irr.svg',
        type: 'fiat',
        decimal: 0
    },
    CNY: {
        title: 'Chinese Yuan',
        symbol: 'CNY',
        symbol2: 'CNY',
        format: '0,0.00',
        icon: CNY,
        pngIcon: CNY_PNG,
        type: 'fiat',
        decimal: 2
    },
    JPY: {
        title: 'Japanese Yen',
        symbol: 'JPY',
        symbol2: 'JPY',
        format: '0,0.00',
        icon: JPY,
        pngIcon: JPY_PNG,
        type: 'fiat',
        decimal: 2
    },
    GBP: {
        title: 'Pound Sterling ',
        symbol: 'GBP',
        symbol2: 'GBP',
        format: '0,0.00',
        icon: GBP,
        pngIcon: GBP_PNG,
        type: 'fiat',
        decimal: 2
    },
    RUB: {
        title: 'Russian Ruble',
        symbol: 'RUB',
        symbol2: 'RUB',
        format: '0,0.00',
        icon: RUB,
        pngIcon: RUB_PNG,
        type: 'fiat',
        decimal: 2
    }
}

// Currency Icon
const currencyIcon = (currency, mode) => {
    currency = currency ? currency.toUpperCase() : '';
    if (currencyDetail[currency]) {
        return currencyDetail[currency][(mode && mode.toLowerCase() == 'png' ? 'pngIcon' : 'icon')] || USD
    }
}

// Currency Format
const currencyFormat = (currency) => {
    if (currencyDetail[currency]) {
        return currencyDetail[currency].format || '0,0.00'
    } else {
        return '0,0.00'
    }
}

const formatAmountCurrency = (amount, currency, mode) => {
    mode = mode || 'floor';
    currency = currency || 'USD';
    let result = 0;
    const decimal = Math.pow(10, currencyDetail[currency].decimal);
    result = parseInt(amount * decimal) / decimal;
    return result
}


export {
    currencyList,
    currencyDetail,
    currencyIcon,
    currencyFormat,
    formatAmountCurrency
}
