import { Box, Collapse, Toolbar } from '@mui/material'
import { MDBIcon } from 'mdb-react-ui-kit';
import React, { useState } from 'react'

const ThemeCard = (props) => {
    const { children, collapsed } = props;
    const [open, setOpen] = useState(!collapsed)
    return (
        <Box className='overflow-hidden' sx={{
            border: (theme) => `1px solid ${theme.palette.divider}`,
            borderRadius: 1,
            bgcolor: 'background.paper',
        }}>
            <ThemeCardHeader {...props} open={open} setOpen={setOpen} />
            <Collapse in={open} timeout="auto" unmountOnExit>
                {children}
            </Collapse>
        </Box>
    )
}

const ThemeCardHeader = (props) => {
    const { header, collapsible, open, setOpen, headerProps } = props;
    return <Toolbar disableGutters className='box-shadow-neon- d-flex justify-content-between' style={{
        borderBottom: `1px solid #333`,
    }}  {...headerProps} >
        {header}
        {collapsible && <MDBIcon className='cursor-pointer p-2 pe-3' onClick={() => setOpen(!open)} icon={`chevron-${open ? 'up' : 'down'}`} />}
    </Toolbar >
}

export default ThemeCard