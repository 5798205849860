import numeral from 'numeral';
import React from 'react'
import { currencyDetail } from '../../../helpers/currency';
import _ from 'lodash'
import { getColorByBetState } from '../../../helpers/adminHelpers';

const RenderWinAmount = (props) => {
    const { amount, currency, type, service, meta } = props;
    let betAmount = 0;

    // Vetsion 2
    if (_.has(meta, 'bet.winAmountInUSD')) {
        const { winAmountInUSD, betState } = meta.bet;
        const color = getColorByBetState(betState);
        return <div className='d-flex justify-content-center justify-content-md-end'>
            <span className={`text-${color}`}>
                {betState == 'open' ? '?' :
                    <><span style={{ marginRight: '2px' }}>$</span>
                        {numeral(winAmountInUSD).format(currencyDetail['USD'].format)}
                    </>}
            </span>
        </div>
    }


    if ((service == 'Evolution' || service == 'RedTiger' || service == 'Netent') && meta) {
        const { relatedEvoTransactions } = meta;
        if (!relatedEvoTransactions) return <span>?</span>
        if (relatedEvoTransactions.length != 1) return <span className='bg-danger'>Multi</span>
        betAmount = relatedEvoTransactions[0].transaction ? relatedEvoTransactions[0].transaction.amount : '0';
    }
    if (type == 'topup' || type == 'deposit' || type == 'withdraw') {
        return <></>
    }
    return <span >
        {/* <img className='me-1' style={{ width: '1rem' }} src={currencyIcon(currency, 'png')} /> */}
        <small className={`${type !== 'topup' ? 'WinAmountItem ' : ''} text-${amount > betAmount ? 'success' : 'danger'}`}>
            {numeral(amount).format(currencyDetail[currency].format)} {currency}
        </small>
    </span>
}

export default RenderWinAmount