import { Box } from '@mui/material';
import React from 'react'

const UserTelegram = (props) => {
    const { kyc } = props;
    const { telegram } = kyc;

    return (
        <Box>
            {
                telegram && telegram.profile ?
                    Object.keys(telegram.profile).map((key) => <Box className='px-2 pb-1 opacity-60' sx={{ fontSize: '0.6rem' }} key={key}>{key}: {telegram.profile[key]}</Box>)
                    :
                    <Box>No Telegram Profile</Box>
            }
        </Box>
    )
}

export default UserTelegram
