import React, { useState, Fragment, useContext } from 'react';
import MailIcon from '@mui/icons-material/Mail';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { MDBCard, MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import { Avatar, Badge, Box, Chip, Divider, Stack, Tooltip, Typography, Zoom } from '@mui/material';
import numeral from 'numeral';
import $ from 'jquery';
import { makeStyles } from '@mui/styles';
import UserDetails from './UserDetails';
import moment from 'moment';
import { currencyDetail, currencyIcon } from '../../../helpers/currency';
import { Context } from '../../../context/store';
import UserWallets from '../../Users/UserDetails/UserWallets';
import { roleColor, roleIcon } from '../../../helpers/adminHelpers';
import { Link } from 'react-router-dom';
import ThemeButton from '../../../layout/components/ThemeButton';
import UserQuickViewButton from '../../Users/UserQuickViewButton';



const formatRoles = (roles) => {
    let sortedRoles = [];
    // if (roles.indexOf('test') > -1) sortedRoles.push('test');
    // if (roles.indexOf('user') > -1) sortedRoles.push('user');
    if (roles.indexOf('superadmin') > -1) sortedRoles.push('superadmin');
    if (roles.indexOf('admin') > -1) sortedRoles.push('admin');
    if (roles.indexOf('manager') > -1) sortedRoles.push('manager');
    if (roles.indexOf('support') > -1) sortedRoles.push('support');
    if (roles.indexOf('supervisor') > -1) sortedRoles.push('supervisor');
    if (roles.indexOf('accountant') > -1) sortedRoles.push('accountant');
    if (roles.indexOf('graphist') > -1) sortedRoles.push('graphist');
    if (roles.indexOf('superGraphist') > -1) sortedRoles.push('superGraphist');
    if (roles.indexOf('user') > -1) sortedRoles.push('user');
    const result = sortedRoles.map((role, i) =>
        <Tooltip
            key={i}
            classes={{ tooltip: 'bg-black box-shadow-neon' }}
            TransitionComponent={Zoom}
            placement="bottom" title={<span className={`text-${roleColor(role)}`}>{role}</span>}>
            <small className='pe-1'>
                {roleIcon(role)}
                {/* <MDBIcon
                    title={role}
                    className={`text-${roleColor(role)}`}
                    style={{ paddingLeft: '2px' }}
                    icon={`${roleIcon(role)}`}></MDBIcon> */}
            </small>
        </Tooltip>)
    return result
}

const formatUsername = (user) => {
    return <div className={`d-flex align-items-center`}>
        <div>
            <Tooltip
                classes={{ tooltip: `bg-black box-shadow-${user.env == 'stage' ? 'warning' : '  neon'}` }}
                TransitionComponent={Zoom}
                placement="left" title={<span className={user.env == 'stage' ? 'text-warning' : 'text-neon'}>{user.env}</span>}>
                <Avatar sx={{ width: '1.6rem', height: '1.6rem', 'i': { fontSize: '0.7rem' } }} className={`bg-transparent overflow-hidden border box-shadow-${user.env == 'stage' ? 'warning border-warning' : 'neon border-neon'}`}>
                    {user.profile.avatar ?
                        <img src={user.profile.avatar} className='img-fluid' />
                        :
                        <MDBIcon className='text-white opacity-30' icon='user'></MDBIcon>
                    }
                </Avatar>
            </Tooltip>
        </div>
        <div className='ps-2'>
            <span>
                {user.kyc.verified ? user.username : '-'}
            </span>
            <div>
                {formatRoles(user.role)}
                {user.env == 'stage' && <ThemeButton color='warning' size='small' variant='outlined' className='p-0 ms-2'>Stage</ThemeButton>}
            </div>
        </div>
    </div >
}

const formatTotalBalance = (state, wallets, activeCurrency) => {
    const { currencyList } = state.config;
    let totalBalanceInUSD = 0;
    let toppedUp = false;
    const render = currencyList.map((currency, i) => {
        const wallet = wallets[currency];
        if (!wallet) return <span>No Wallet for {currency}</span>
        const { balance, topup } = wallet;
        if (topup && topup.count) toppedUp = true;
        const rateInUSD = state.rates ? (state.rates[currency.toUpperCase()] ? state.rates[currency.toUpperCase()]['USD'] : 0) : 0;
        const amountInUSD = balance * rateInUSD;
        if (wallet.balance > 0.000001 || currency == 'USD') {
            totalBalanceInUSD += amountInUSD;
            return <span key={i} className='px-0'>
                {activeCurrency != currency ?
                    <img className={`ms-n2`} style={{ width: '1rem', height: '1rem' }} src={currencyIcon(currency, 'png')} />
                    : <></>
                }
            </span>
        } else {
            return null
        }
    })
    return <Tooltip
        TransitionComponent={Zoom}
        classes={{ tooltip: 'bg-black box-shadow-neon px-2 m-0' }}
        placement="right" title={
            <UserWallets userWallet={{ ...wallets }}></UserWallets>
        }>
        <span className='ps-2 cursor-pointer'>
            <span className='d-inline-block text-end' style={{ width: '4rem' }}>
                {render}
                <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={
                        <MDBIcon className='text-shadow-neon text-neon' style={{ fontSize: '0.4rem' }} icon='check-circle'></MDBIcon>
                    }
                >
                    <Avatar style={{ width: '1rem', height: '1rem' }} alt="Currency" src={currencyIcon(activeCurrency, 'png')} />
                </Badge>
            </span>
            <span className='px-1'>${numeral(totalBalanceInUSD).format(currencyDetail['USD'].format)}</span>
            <small className='fw-bold'>{toppedUp ? <MDBIcon className='text-small text-neon small ms-1' icon='donate'></MDBIcon> : ''}</small>
        </span>
    </Tooltip>
}

const formatPL = (state, wallets) => {
    const { currencyList } = state.config;
    let totalInUSD = {
        amount: 0,
        win: 0,
        count: 0,
        status: 'info'
    };
    currencyList.map((currency, i) => {
        const wallet = wallets[currency];
        if (!wallet) return <span>No Wallet for {currency}</span>

        const play = wallet.play || {
            amount: 0, count: 0, win: 0
        };
        const { amount, count, win } = play;
        const rate = state.rates[currency] && state.rates[currency]['USD'] ? state.rates[currency]['USD'] : 0;
        totalInUSD = {
            amount: totalInUSD.amount + (rate * amount),
            win: totalInUSD.win + (rate * (win || 0)),
            count: totalInUSD.count + count
        }
        totalInUSD.status = (totalInUSD.win + totalInUSD.amount) < 0 ? 'danger' : ((totalInUSD.win + totalInUSD.amount) == 0) ? 'warning' : 'success';
    })
    const result = (totalInUSD.win + totalInUSD.amount) > 0.000001 ? Math.abs(totalInUSD.win + totalInUSD.amount) : 0;
    return <div className={`text-end text-${totalInUSD.status}`}>{totalInUSD.win + totalInUSD.amount < 0.000001 ? '-' : ''}${numeral(result).format(currencyDetail['USD'].format)}</div>

}

const formatRegister = (datetime) => {
    return <small style={{ width: '120px' }} className='px-1 d-block text-warning'>{moment(datetime).fromNow()}</small>
}

const useRowStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#232228',
        color: '#fff',
        marginBottom: 100,
        "& td": {
            color: '#fff',
            paddingBottom: 0,
            paddingTop: 0
        },
        "& th": {
            color: '#fff',
        }
    },
    head: {
        backgroundColor: '#37343b',
        color: '#fff',
        "& th": {
            color: '#fff',
        }
    },
    tr: {

    },
    tra: {
        backgroundColor: '#37343b',
    },
    td: {
        color: '#fff',
        // border: 'none'
    },
    container: {
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%'
        }
    },
    badge: {
        "& span": {
            top: 7,
            right: -5,
            fontSize: '0.8em',
            padding: 0,
        }
    }, paper: {
        // position: 'absolute',
        overflow: 'scroll',
        backgroundColor: '#191919',
        color: '#ddd',
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(0),
    }, form: {
        "& label": {
            color: '#ddd',
        },
        "& small": {
            color: '#ddd',
        }
    },
    textField: {
        width: '100%',
        marginBottom: '15px',
        marginTop: '0px',
        '& div': {
            alignItems: 'baseline'
        },
        "& input": {
            color: '#fff',
        },
        "& textarea": {
            color: '#ddd',
            fontSize: '0.8em'
        },
        "& div::before": {
            borderBottomColor: '#ddd',
        },
        "& div:hover::before": {
            borderBottomColor: '#ddd !important',
        },
    }
}));


function Row(props) {
    const [state, dispatch] = useContext(Context);
    const { rowIndex, row, handleTopup, handleRoles, handleChaneUserPassword, handleRevokeUserToken, handleBanUser } = props;
    const rowStyle = rowIndex % 2 ? { backgroundColor: '#181818' } : {}
    // const [texts, setTexts] = useState(createTexts(mode))
    const [open, setOpen] = useState(false);
    const [activeTab, setActiveTab] = useState({
        type: '', index: 0
    });
    const classes = useRowStyles();
    const showHideEmail = (e) => {
        const $Email = $(e.currentTarget).next();
        $(e.currentTarget).toggleClass('fa-eye fa-eye-slash')
        const email = $Email.attr('data-email');
        const hashEmail = $Email.text();
        $Email.attr('data-email', hashEmail);
        $Email.text(email);
    }

    return (
        <Fragment>
            <TableRow style={rowStyle}>
                <TableCell rowSpan='1' width='30' className='px-0 border-0' >
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell >

                <TableCell width='80' className={'text-start px-2 ' + (row.role.indexOf('test') > -1 ? 'text-yellow' : '')}>
                    {row.userId ? <UserQuickViewButton userId={row.userId}></UserQuickViewButton> : '--'}
                </TableCell>
                <TableCell width='80' className={row.role.indexOf('test') > -1 ? 'text-yellow' : ''}>
                    {
                        row.kyc.emails && row.kyc.emails.default ?
                            <div style={{
                                whiteSpace: 'nowrap',
                                width: '200px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                <MDBIcon onClick={(e) => { showHideEmail(e) }} icon='eye' className='me-2 cursor-pointer'></MDBIcon>
                                <span className='UserEmail' data-email={row.kyc && row.kyc.emails && row.kyc.emails.default && row.kyc.emails.default.address ? row.kyc.emails.default.address : '-'}>
                                    {row.kyc.emails.default.address ? row.kyc.emails.default.address : '-'}
                                </span>
                                {row.id <= 10 ? <MDBIcon icon='lock' className='ms-2 text-danger'></MDBIcon> : <></>}
                            </div> : <>-</>
                    }
                </TableCell>
                {/* Username */}
                <TableCell width='200' className={'ps-3 ' + (row.role.indexOf('test') > -1 ? 'text-yellow' : '')}>
                    {formatUsername(row)}
                </TableCell>
                {/* Referrer */}
                <TableCell className='text-center'>
                    {row.profile.referrer ?
                        <UserQuickViewButton userId={row.profile.referrer.userId} />
                        // <Link className='border-bottom border-neon text-neon' to={`/user/list/${row.profile.referrer.userId}`}>
                        //     {row.profile.referrer.userId}
                        // </Link>
                        : ''}
                </TableCell>
                {/* Affiliate */}
                <TableCell className='text-center'>
                    {row.profile.affiliate ? Object.keys(row.profile.affiliate).map((refId, i) => {
                        const count = row.profile.affiliate[refId].count;
                        if (!count) return
                        return <Link target='_blank' className='box-shadow-neon rounded-pill small text-neon py-1 px-1 d-inline-block mx-1' style={{ minWidth: '1.5rem' }} to={`/user/list/?referrer_refId=${refId}`} key={i}>{count}</Link>
                    }) : ''}
                </TableCell>

                <TableCell>
                    <div style={{ width: '200px' }} className='px-1 small d-block'>
                        {row.wallet ? formatTotalBalance(state, row.wallet, row.profile.currency) : ''}
                    </div>
                </TableCell>
                <TableCell className='px-1'>
                    {row.wallet ? formatPL(state, row.wallet) : ''}
                </TableCell>
                <TableCell className='small text-end'>
                    {
                        formatRegister(row.createdAt)
                    }
                </TableCell>
                <TableCell width='140' style={{ width: '120px' }} className='px-1 small text-warning'>
                    {row.profile.country ?
                        <img
                            src={`https://flagcdn.com/48x36/${row.profile.country.toLowerCase()}.png`}
                            height='12'
                            className='me-1'
                            alt={row.profile.country}></img> : <></>
                    }
                    <small className={'text-' + (moment(row.updatedAt).fromNow().indexOf('seconds') > 1 ? 'success' :
                        moment(row.updatedAt).fromNow().indexOf('minute') > 1 ? 'success' :
                            moment(row.updatedAt).fromNow().indexOf('hour') > 1 ? 'info' :
                                moment(row.updatedAt).fromNow().indexOf('day ') > 1 ? 'info' :
                                    moment(row.updatedAt).fromNow().indexOf('days') > 1 ? 'warning' : 'danger'
                    )}>{row.updatedAt ? moment(row.updatedAt).fromNow() : '-'}
                    </small>
                    <div title='Last Login' className='text-white opacity-40 small'>
                        <MDBIcon icon='key'></MDBIcon>
                        <small className='ps-1'>{row.profile.lastLogin ? moment(row.profile.lastLogin).fromNow() : '-'}</small>
                    </div>
                </TableCell>
                <TableCell align="center">
                    <UserRowInfo {...row} setActiveTab={setActiveTab} setOpen={setOpen} />
                </TableCell>
                {/* Actions */}
                <TableCell align="center" className='px-1 small text-warning' >
                    <div className='d-block' style={{ width: '8rem' }}>
                        <MDBIcon className='cursor-pointer pe-2' onClick={() => handleTopup(row)} icon='donate'></MDBIcon>
                        <MDBIcon className='cursor-pointer pe-2' onClick={() => handleChaneUserPassword(row)} icon='key'></MDBIcon>
                        <MDBIcon className='cursor-pointer pe-2' onClick={() => handleRoles(row)} icon='user'></MDBIcon>
                        <MDBIcon className='cursor-pointer pe-2 opacity-40' id='rowActionUsersGroups' icon='users'></MDBIcon>
                        <MDBIcon className='cursor-pointer pe-2 opacity-40' onClick={() => { }} icon='thumbtack'></MDBIcon>
                        {/* <MDBIcon className='cursor-pointer pe-2' onClick={() => handleRevokeUserToken(row)} icon='sign-out-alt'></MDBIcon> */}
                        <MDBIcon className='cursor-pointer pe-2 opacity-40' onClick={() => handleBanUser(row)} icon='ban'></MDBIcon>
                    </div>
                </TableCell>
            </TableRow >
            <TableRow style={rowStyle}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, maxWidth: '90vw' }} colSpan={13}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <MDBCard className='my-0 bg-dark border border-dark  shadow-3-strong'>
                            <UserDetails user={row} activeTab={activeTab} setActiveTab={setActiveTab}></UserDetails>
                        </MDBCard>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
}

const UserRowInfo = (props) => {
    const { kyc, wallet, profile, setActiveTab, setOpen } = props;
    const [state, dispatch] = useContext(Context);
    const { emails, mobiles, telegram } = kyc;
    // Profile
    const tier = profile.tier || 0;
    // Wallet
    if (!wallet) return <span>No Wallet</span>
    const { currencyList } = state.config;
    let deposit = false, withdraw = false, Digitain = false, Evolution = false, Poker = false, Crash = false;
    currencyList.map(currency => {
        if (!wallet[currency]) return <span>No Wallet for {currency}</span>
        if (wallet[currency].deposit && wallet[currency].deposit.count) deposit = true;
        if (wallet[currency].withdraw && wallet[currency].withdraw.count) withdraw = true;
        if (wallet[currency].play && wallet[currency].play.Digitain && wallet[currency].play.Digitain.count > 0) Digitain = true;
        if (wallet[currency].play && wallet[currency].play.Evolution && wallet[currency].play.Evolution.count > 0) Evolution = true;
        if (wallet[currency].play && wallet[currency].play.Poker && wallet[currency].play.Poker.count > 0) Poker = true;
        if (wallet[currency].play && wallet[currency].play.Crash && wallet[currency].play.Crash.count > 0) Crash = true;
    })

    const kycItems = [
        {
            url: emails && emails.default && emails.default.address ? 'mailto:' + emails.default.address : '',
            icon: 'envelope',
            active: emails && emails.default && emails.default.verified ? true : false
        },
        {
            url: mobiles && mobiles.default && mobiles.default.normalized ? 'tel:' + mobiles.default.normalized : '',
            icon: 'mobile-alt',
            active: mobiles && mobiles.default && mobiles.default.verified ? true : false
        },
        {
            url: telegram && telegram.profile ? 'https://t.me/' + telegram.profile.username : '',
            icon: 'paper-plane',
            active: telegram && telegram.verified ? true : false
        }
    ]
    const transactionItems = [
        {
            url: '',
            icon: 'download',
            active: deposit,
            label: 'deposit',
            color: 'success'
        },
        {
            url: '',
            icon: 'upload',
            label: 'withdraw',
            active: withdraw,
            color: 'danger'
        },
    ]

    const playingItem = [
        {
            url: '',
            icon: 'dice',
            active: Evolution
        },
        {
            url: '',
            icon: 'futbol',
            active: Digitain
        },
        {
            url: '',
            icon: 'rocket',
            active: Crash
        },
        {
            url: '',
            icon: 'portrait',
            active: Poker
        }
    ]

    return <Stack
        direction="row"
        className='p-1'
        justifyContent="center"
        alignItems="center"
        spacing={0}>
        {kycItems.map((item, i) => {
            return item.url ?
                <a key={i} target='_blank' href={item.url}>
                    <MDBIcon icon={item.icon} color={item.active ? (item.color || 'neon') : 'black'} className={'mx-1 ' + (!item.active ? 'text-shadow-neon-0' : '')}></MDBIcon>
                </a> :
                <MDBIcon key={i} icon={item.icon} color={item.active ? (item.color || 'neon') : 'black'} className={'mx-1 ' + (!item.active ? 'text-shadow-neon-0' : '')}></MDBIcon>
        })}
        {transactionItems.map((item, i) => {
            return <MDBIcon onClick={() => { setOpen(true); setActiveTab({ index: 3, type: item.label }) }} key={i} icon={item.icon} color={item.active ? (item.color || 'neon') : 'black'} className={'mx-1 ' + (!item.active ? 'text-shadow-neon-0' : 'cursor-pointer')}></MDBIcon>
        })}
        <Divider orientation="vertical" className='h-auto bg-neon' flexItem />
        {playingItem.map((item, i) => <MDBIcon key={i} icon={item.icon} color={item.active ? 'neon' : 'black'} className={'mx-1 ' + (!item.active ? 'text-shadow-neon-0' : '')}></MDBIcon>)}
        <Divider orientation="vertical" className='h-auto bg-neon' flexItem />
        <KYCScore {...kyc}></KYCScore>
        <Divider orientation="vertical" className='h-auto bg-neon ms-1' flexItem />
        <MDBIcon icon='gamepad' color={profile.active ? 'neon' : 'black'} className={'mx-1 ' + (!profile.active ? 'text-shadow-neon-0' : '')}></MDBIcon>
        <Divider orientation="vertical" className='h-auto bg-neon ms-1' flexItem />
        {
            kyc && <MDBIcon icon='check-circle' color={kyc.verified ? 'neon' : 'danger'} className={'mx-1 ' + (!kyc.verified ? 'text-shadow-neon-0' : '')}></MDBIcon>
        }
        <Divider orientation="vertical" className='h-auto bg-neon ms-1' flexItem />
        <Typography className={`px-1 ${tier == 2 ? 'text-yellow' : tier == 3 ? 'text-orange' : tier == 4 ? 'text-success' : tier == 5 ? 'text-neon' : ''}`} variant='subtitle1'>
            {tier}
        </Typography>
    </Stack>
}

const KYCScore = (props) => {
    const { score, documents } = props;
    const color = score <= 10 ? 'danger' :
        score <= 20 ? 'warning' :
            score < 50 ? 'yellow' :
                score <= 80 ? 'info' :
                    score <= 100 ? 'success' : 'white';
    if (documents) {
        let pending = false;
        Object.keys(documents).map((doc, i) => {
            const status = documents[doc].status || null;
            if (status == 'pending') pending = true;
        })
        if (pending) return <small className={`ps-1 text-${color}`}>
            <MDBIcon className='px-0 text-shadow-neon' color='neon' icon='spinner' spin></MDBIcon>
        </small>
    }
    return <small className={`ps-1 text-${color}`}>
        {JSON.stringify(score)}
    </small>
}

export default Row
