import React, { useState } from 'react'
import AdvancedFilter from '../../Transaction/AdvancedFilter/AdvancedFilter'

const UserList2 = () => {
  const api = '/admin/users';
  const [rows, setRows] = useState([]);
  return (
    <div>
      <AdvancedFilter defaultFilter='' setRows={setRows} api={api} />

    </div>
  )
}

export default UserList2