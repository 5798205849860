import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox, Chip, Typography } from '@mui/material';
import _ from 'lodash';
import { MDBIcon } from 'mdb-react-ui-kit';
import ThemeButton from './ThemeButton';

function ConfirmationDialogRaw(props) {
    const { title, onClose, value, setValue, open, ...other } = props;
    const [selectedOptions, setSelectedOptions] = useState(value)
    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        onClose(selectedOptions);
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: '100vh' } }}
            maxWidth="xs"
            open={open}
            {...other}
        >
            <DialogTitle className='bg-black text-neon'>{title}</DialogTitle>
            <DialogContent dividers>
                {
                    props.options && props.options.map((option, i) =>
                        <div key={i}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color='neon'
                                        checked={selectedOptions.indexOf(option) > -1}
                                        onChange={e => {
                                            const checked = e.target.checked;
                                            if (!checked) {
                                                setSelectedOptions(_.without(selectedOptions, option))
                                            }
                                            else {
                                                let newOptions = _.cloneDeep(selectedOptions);
                                                newOptions.push(option);
                                                setSelectedOptions(newOptions)
                                            }
                                        }
                                        }
                                        name={option} />
                                }
                                label={option}
                            />
                        </div>
                    )}
            </DialogContent>
            <DialogActions>
                <ThemeButton className='px-3 m-1' color='danger' variant='outlined' onClick={handleCancel}>
                    Cancel
                </ThemeButton>
                <ThemeButton className='px-4 m-1' color='neon'  variant='outlined' onClick={handleOk}>Ok</ThemeButton>
            </DialogActions>
        </Dialog>
    );
}

ConfirmationDialogRaw.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
};

export default function ThemeMultiSelect(props) {
    const { value, setValue, options,
        className, style,
        labelStyle, labelClass, label, // labelProps
        endStyle, endClassName,
        inputStyle, maxShow
    } = props;

    const [open, setOpen] = useState(false);
    const handleClickListItem = () => {
        setOpen(true);
    };

    const handleClose = (newValue) => {
        setOpen(false);

        if (newValue) {
            setValue(newValue);
        }
    };

    return (
        <span className={"d-block w-100 position-relative noArrow " + className} style={style}>
            {label ? <Typography component='span' className={'rounded-pill position-absolute px-1 mx-2 ' + labelClass} sx={{
                fontSize: ['0.6rem'],
                top: ['-0.3rem'],
                lineHeight: ['0.7rem'],
                backgroundColor: '#000',
                zIndex: 4,
                color: '#26ddff'
            }}
                style={labelStyle}
            >{label} ({value.length})</Typography> : <></>}
            <span className='input-group'>
                <div className={"form-control shadow-none bg-transparent overflow-hidden"}
                    style={{ border: '1px solid #555', fontSize: '0.8rem', color: '#fff', padding: '0.25rem', ...inputStyle }}>
                    {value.map((option, i) => {
                        if (i > maxShow) {
                            return <></>
                        }
                        if (i === maxShow) {
                            return <strong className='ps-2 text-neon'>...</strong>
                        }
                        return <Chip key={i} label={option} style={{ margin: '2px' }} className='border-neon text-neon px-1' size="small" color="info" variant='outlined' />
                    })}
                </div>
                <span style={{ border: '1px solid #555', ...endStyle }} className={"input-group-text " + endClassName}>
                    <MDBIcon onClick={handleClickListItem} className='text-neon cursor-pointer' icon='chevron-down'></MDBIcon>
                </span>
            </span>
            <ConfirmationDialogRaw
                title={label}
                options={options}
                open={open}
                onClose={handleClose}
                value={value}
            />
        </span>
    );
}
