import React, { useContext, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SideNav from './SideNav'
import HeaderMenu from './HeaderMenu';
import { Box, Collapse, Grow, Tooltip, Zoom } from '@mui/material';
import { BreadCrumbContext, Context } from '../../context/store';
import ThemeButton from '../components/ThemeButton';
import { Link } from 'react-router-dom';
import Login from '../Auth/Login';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import { isMobile } from 'react-device-detect';
import { updateRates } from '../../helpers/publicHelper';
import TreasuryWallets from '../../admin/Report/TreasuryWallets';
import RatesReview from '../../admin/Report/RatesReview';
import UsersWalletTotalBalance from '../../admin/Report/UsersWalletTotalBalance';
import ThemeBreadCrumb from '../components/ThemeBreadCrumb';

const drawerWidth = '13rem';

const openedMixin = (theme) => ({
    width: drawerWidth,//drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(3.2rem)`,
    [theme.breakpoints.down('sm')]: {
        width: `0px`,

    }
})

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth})`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexShrink: 0,
        padding: 0,
        margin: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            width: `calc(13.2rem)`,
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const LayoutAppBar = (props) => {
    const [contextBC, setContextBC] = useContext(BreadCrumbContext);
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const [state, dispatch] = useContext(Context);
    const [comp, setComp] = useState(props.children)
    const [showRates, setShowRates] = useState(false)
    const interval = useRef(null);
    const [ratesWidth, setRatesWidth] = useState(0);
    const ratesWidthRef = useRef();
    ratesWidthRef.current = ratesWidth;
    useEffect(() => {
        interval.current = setInterval(() => {
            if (ratesWidthRef.current < 100) {
                setRatesWidth(ratesWidthRef.current + 0.1)
            } else {
                setRatesWidth(0);
                updateRates(state, dispatch)
            }
        }, 100);
        return () => {
            clearInterval(interval.current)
        }

    }, [])

    const color = state?.serverEnv == 'prod' ? 'neon' : state?.serverEnv == 'stage' ? 'warning' : 'danger';

    return (
        <>
            {state.login ?
                <Box sx={{
                    width: '100vw',
                    minHeight: '100vh',
                    height: 'auto'
                }}>
                    <CssBaseline />

                    <AppBar position="fixed" open={open}>
                        <Toolbar 
                        className={`box-shadow-${color}`}
                        sx={{
                            // height: '3rem !important',
                            // minHeight: '3rem !important',
                            background: '#000',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                            <div className='d-flex' style={{ alignItems: 'center' }}>
                                {
                                    state.login &&
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        onClick={handleDrawerOpen}
                                        edge="start"
                                        sx={{
                                            marginRight: '5px',
                                            ...(open && { visibility: 'hidden' }),
                                            // ...(open && { display: 'none' }),
                                        }}>
                                        <MenuIcon />
                                    </IconButton>
                                }
                                <Typography variant="body1" className={`d-none d-md-block text-white`}>
                                    Admin Panel
                                </Typography>
                                <Link to='/User/List' className='ms-2'>
                                    <Tooltip
                                        classes={{ tooltip: `bg-black box-shadow-neon` }}
                                        TransitionComponent={Zoom}
                                        placement="bottom" title={<span className='text-neon'>Users</span>}>
                                        <div className={`text-neon`}>
                                            <MDBIcon icon='users'></MDBIcon>
                                        </div>
                                    </Tooltip>
                                </Link>
                                <Link to='/Transaction/List/' className='ms-2'>
                                    <Tooltip
                                        classes={{ tooltip: `bg-black box-shadow-neon` }}
                                        TransitionComponent={Zoom}
                                        placement="bottom" title={<span className='text-neon'>Transactions</span>}>
                                        <div className={`text-neon`}>
                                            <MDBIcon icon='exchange-alt'></MDBIcon>
                                        </div>
                                    </Tooltip>
                                </Link>
                                <Link to='/Transaction/List2/' className='ms-2'>
                                    <Tooltip
                                        classes={{ tooltip: `bg-black box-shadow-warning` }}
                                        TransitionComponent={Zoom}
                                        placement="bottom" title={<span className='text-neon'>Transactions2</span>}>
                                        <div className={`text-warning`}>
                                            <MDBIcon icon='exchange-alt'></MDBIcon>
                                        </div>
                                    </Tooltip>
                                </Link>
                                <Link to='/Servers/List/' className='ms-2'>
                                    <Tooltip
                                        classes={{ tooltip: `bg-black box-shadow-neon` }}
                                        TransitionComponent={Zoom}
                                        placement="bottom" title={<span className='text-neon'>Servers</span>}>
                                        <div className={`text-neon`}>
                                            <MDBIcon icon='network-wired'></MDBIcon>
                                        </div>
                                    </Tooltip>
                                </Link>
                                <Link to='/Service/Logs' className='ms-2'>
                                    <Tooltip
                                        classes={{ tooltip: `bg-black box-shadow-warning` }}
                                        TransitionComponent={Zoom}
                                        placement="bottom" title={<span className='text-warning'>Logs</span>}>
                                        <div className={`text-warning`}>
                                            <MDBIcon icon='network-wired'></MDBIcon>
                                        </div>
                                    </Tooltip>
                                </Link>
                                <Link to='/Withdraw/WithdrawRequests' className='ms-2'>
                                    <Tooltip
                                        classes={{ tooltip: `bg-black box-shadow-neon` }}
                                        TransitionComponent={Zoom}
                                        placement="bottom" title={<span className='text-neon'>Withdraw Requests</span>}>
                                        <div className={`text-danger`}>
                                            <MDBIcon icon='upload'></MDBIcon>
                                        </div>
                                    </Tooltip>
                                </Link>
                                <Link to='/Financial/UsersWalletReport' className='ms-2'>
                                    <Tooltip
                                        classes={{ tooltip: `bg-black box-shadow-neon` }}
                                        TransitionComponent={Zoom}
                                        placement="bottom" title={<span className='text-neon'>Users Wallets</span>}>
                                        <div className={`text-success`}>
                                            <MDBIcon icon='wallet'></MDBIcon>
                                        </div>
                                    </Tooltip>
                                </Link>
                                <Link to='/TransferToTreasury/TransferToTreasuryRequests' className='mx-2'>
                                    <Tooltip
                                        classes={{ tooltip: `bg-black box-shadow-neon` }}
                                        TransitionComponent={Zoom}
                                        placement="bottom" title={<span className='text-success'>Transfer to Treasury Requests</span>}>
                                        <div className={`text-yellow`}>
                                            <MDBIcon icon='upload' rotate='90'></MDBIcon>
                                        </div>
                                    </Tooltip>
                                </Link>
                                <ThemeButton
                                    sx={{
                                        marginTop: '0px',
                                        padding: '0 !important',
                                        overflow: 'hidden',
                                        position: 'relative'
                                    }}
                                    onClick={() => setShowRates(!showRates)} size='small' variant='outlined' color='success'>
                                    <span
                                        className='position-absolute bg-success py-2'
                                        style={{
                                            width: ratesWidth + '%',
                                            left: 0,
                                            top: 0
                                        }}>
                                    </span>
                                    <span className='p-1 px-3 text-white' style={{ zIndex: 9 }}>
                                        <MDBIcon className='text-white' icon='dollar-sign' />
                                    </span>
                                </ThemeButton>
                                <Typography className='d-none d-md-block ps-2 text-center' variant='subtitle2'>
                                    <div className='opacity-40 small'>
                                        EnvFile:{process.env.REACT_APP_ENV_FILE}
                                    </div>
                                </Typography>
                            </div>
                            {/* <div className={classes.grow} /> */}
                            <Grow in={true} timeout={3000}>
                                <div>
                                    <HeaderMenu></HeaderMenu>
                                </div>
                            </Grow>
                        </Toolbar>
                        <Toolbar variant="dense" disableGutters sx={{ minHeight: '1rem !important', }}>
                            <ThemeBreadCrumb data={contextBC} active='' />
                        </Toolbar>
                    </AppBar>
                    <Drawer variant="permanent" open={open}>
                        {
                            state.login && state.user.profile.role && (state.user.profile.role.indexOf('admin') > -1) &&
                            <SideNav copm={comp} setComp={setComp} drawerOpen={open} handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen}></SideNav>
                        }
                    </Drawer>
                    <Box sx={{ width: '100vw', paddingLeft: [0, '3.5rem'] }}>
                        <Toolbar />
                        <Toolbar variant='dense' />
                        {comp}
                    </Box>
                </Box>
                :   
                   <Login />
          
            }
            <Collapse style={{ position: 'fixed', width: '100vw', height: 'calc(100vh - 80px)', bottom: 0, left: 0, zIndex: 99 }} in={showRates} unmountOnExit>
                <MDBRow className='mx-0 bg-black w-100 py-2 box-shadow-neon' style={{ height: isMobile ? '95vh' : 'auto', overflow: 'scroll' }}>
                    <RatesReview />
                    <TreasuryWallets />
                    <UsersWalletTotalBalance />
                </MDBRow>
            </Collapse>
        </>
    );
}

export default LayoutAppBar
