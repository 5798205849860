import React from 'react'
import { Box, Grid, Typography } from '@mui/material';
import numeral from 'numeral';

const PlayerReportAff = (props) => {
    const { playerReportData } = props;
    const { reportData, currentBalance } = playerReportData;
    const { fromTo } = reportData;
    const { financial, play } = fromTo;

    const totalDepositDeposit = financial?.deposit?.deposit?.total?.totalAmountInUSD;
    const totalDepositBonus = financial?.deposit?.bonus?.total?.totalAmountInUSD;

    const totalWithdrawWithdraw = financial?.withdraw?.withdraw?.total?.totalAmountInUSD;
    const totalWithdrawPending = financial?.withdraw?.pending?.total?.totalAmountInUSD;

    const totalTopupDeposit = financial?.topup?.deposit?.total?.totalAmountInUSD;
    const totalTopupWithdraw = financial?.topup?.withdraw?.total?.totalAmountInUSD;
    const totalTopupDailyDiscount = financial?.topup?.dailyDiscount?.total?.totalAmountInUSD;
    const totalTopupWeeklyDiscount = financial?.topup?.weeklyDiscount?.total?.totalAmountInUSD;
    const totalTopupBonus = financial?.topup?.bonus?.total?.totalAmountInUSD;

    const totalBetAmount = play?.total.total.bet.betAmountInUSD;
    const totalWinAmount = play?.total.total.bet.winAmountInUSD;
    const totalOpenAmount = play?.total.total.open.betAmountInUSD;

    const applicableAffCommissionFormula = `totalDepositDeposit + totalWithdrawWithdraw + totalWithdrawPending - provdierFee - currentBalance - totalOpenAmount`;
    const provdierFee = (0.25 * (totalBetAmount - totalWinAmount));
    const applicableAffCommission = totalDepositDeposit + totalWithdrawWithdraw + totalWithdrawPending - provdierFee - currentBalance - totalOpenAmount;
    const affCommission = 0.5 * applicableAffCommission;

    return <Box className='pb-3 '>

        <Box sx={{
            textAlign: 'center',
            '&  .MuiGrid-item': {
                border: '1px solid #666',
            },
        }}>
            <Grid container columns={20} sx={{
                '& .MuiGrid-item.gridLabel': {
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: ['flex'],
                    fontSize: '0.6rem'
                }
            }}>
                <Grid item md={2} className='gridLabel p-1 fw-bold text-success'>Deposit</Grid>
                <Grid item md={2} className='gridLabel p-1 fw-bold text-teal'>Topup Deposit</Grid>
                <Grid item md={2} className='gridLabel p-1 fw-bold text-danger'>Withdraw</Grid>
                <Grid item md={2} className='gridLabel p-1 fw-bold text-danger'>Topup Withdraw</Grid>
                <Grid item md={2} className='gridLabel p-1 fw-bold text-yellow'>Pending Withdraw</Grid>
                <Grid item md={2} className='gridLabel p-1 fw-bold text-neon'>Daily Cashback</Grid>
                <Grid item md={2} className='gridLabel p-1 fw-bold text-neon'>Weekly Cashback</Grid>
                <Grid item md={2} className='gridLabel p-1 fw-bold text-orange'>Current Balance</Grid>
                <Grid item md={2} className='gridLabel p-1 fw-bold text-yellow'>Open Bets</Grid>
                <Grid item md={2} className='gridLabel p-1 fw-bold text-pink'>Provider Fee</Grid>
            </Grid>
            <Grid container columns={20} sx={{ fontSize: '0.6rem' }}>
                <Grid item xs={10} md={2} className='d-md-none gridLabel p-1 fw-bold text-success'>Deposit</Grid>
                <Grid item xs={10} md={2} className='gridLabel p-1 fw-bold text-success'>{numeral(totalDepositDeposit).format('$0,0.00')}</Grid>
                <Grid item xs={10} md={2} className='d-md-none gridLabel p-1 fw-bold text-teal'>Topup Deposit</Grid>
                <Grid item xs={10} md={2} className='gridLabel p-1 fw-bold text-teal'>{numeral(totalTopupDeposit).format('$0,0.00')}</Grid>
                <Grid item xs={10} md={2} className='d-md-none gridLabel p-1 fw-bold text-danger'>Withdraw</Grid>
                <Grid item xs={10} md={2} className='gridLabel p-1 fw-bold text-danger'>{numeral(totalWithdrawWithdraw).format('$0,0.00')}</Grid>
                <Grid item xs={10} md={2} className='d-md-none gridLabel p-1 fw-bold text-danger'>Topup Withdraw</Grid>
                <Grid item xs={10} md={2} className='gridLabel p-1 fw-bold text-danger'>{numeral(totalTopupWithdraw).format('$0,0.00')}</Grid>
                <Grid item xs={10} md={2} className='d-md-none gridLabel p-1 fw-bold text-yellow'>Pending Withdraw</Grid>
                <Grid item xs={10} md={2} className='gridLabel p-1 fw-bold text-yellow'>{numeral(totalWithdrawPending).format('$0,0.00')}</Grid>
                <Grid item xs={10} md={2} className='d-md-none gridLabel p-1 fw-bold text-neon'>Daily Cashback</Grid>
                <Grid item xs={10} md={2} className='gridLabel p-1 fw-bold text-neon'>{numeral(totalTopupDailyDiscount).format('$0,0.00')}</Grid>
                <Grid item xs={10} md={2} className='d-md-none gridLabel p-1 fw-bold text-neon'>Weekly Cashback</Grid>
                <Grid item xs={10} md={2} className='gridLabel p-1 fw-bold text-neon'>{numeral(totalTopupWeeklyDiscount).format('$0,0.00')}</Grid>
                <Grid item xs={10} md={2} className='d-md-none gridLabel p-1 fw-bold text-orange'>Current Balance</Grid>
                <Grid item xs={10} md={2} className='gridLabel p-1 fw-bold text-orange'>{numeral(currentBalance).format('$0,0.00')}</Grid>
                <Grid item xs={10} md={2} className='d-md-none gridLabel p-1 fw-bold text-yellow'>Open Bets</Grid>
                <Grid item xs={10} md={2} className='gridLabel p-1 fw-bold text-yellow'>{numeral(totalOpenAmount).format('$0,0.00')}</Grid>
                <Grid item xs={10} md={2} className='d-md-none gridLabel p-1 fw-bold text-pink'>Provider Fee</Grid>
                <Grid item xs={10} md={2} className='gridLabel p-1 fw-bold text-pink'>{numeral(provdierFee).format('$0,0.00')}</Grid>
            </Grid>
        </Box>
        <Typography variant='subtitle2' className='p-2'>
            <Box className='text-gray'>{applicableAffCommissionFormula}</Box>
        </Typography>
        {/* <Box className='text-neon'>{numeral(applicableAffCommission).format('$0,0.00')}</Box> */}
        <Typography variant='subtitle1'>
            <Box className='text-neon px-2'>Affiliate Commission (50%):
                <span className='border border-neon px-3 py-1 rounded-3 ms-2'>
                    {numeral(affCommission).format('$0,0.00')}
                </span>
            </Box>
        </Typography>
    </Box>
}

export default PlayerReportAff
