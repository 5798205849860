import React, { useState } from 'react';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import { useSnackbar } from 'notistack';
import numeral from 'numeral';
import CopyToClipboard from 'react-copy-to-clipboard';
import ThemeButton from '../../../layout/components/ThemeButton';
import ThemeInput from '../../../layout/components/ThemeInput';
import axios from 'axios';
import { getBearer } from '../../../helpers/publicHelper';
import _ from 'lodash';

const UserAffiliate = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const { affiliate } = props.user.profile;
    return (
        <>
            <MDBRow className='border-bottom border-neon text-neon py-2 fw-bold text-center'>
                <MDBCol size='2'>
                    AffId
                </MDBCol>
                <MDBCol size='2'>
                    Title
                </MDBCol>
                <MDBCol size='2'>
                    Registerd Players
                </MDBCol>
                <MDBCol size='2'>
                    Active Players
                </MDBCol>
                <MDBCol size='2'>
                    Active Percent
                </MDBCol>
            </MDBRow>
            {Object.keys(affiliate).map((affId, i) => {
                const aff = affiliate[affId];
                return <MDBRow key={i} className='text-center py-2'>
                    <MDBCol size='2'>
                        <a className='text-neon border-bottom border-neon' target='_blank' href={`https://imoon.com/?refId=${affId}`}>{affId}</a>
                        <CopyToClipboard
                            onCopy={() => { enqueueSnackbar('Copied.', { variant: 'success', autoHideDuration: 2500 }) }}
                            text={`https://imoon.com/?refId=${affId}`}>
                            <ThemeButton size='small' variant='outlined' color='warning' className='py-0 px-2 ms-2'>
                                <MDBIcon className='me-1' icon='copy'></MDBIcon> Copy Link
                            </ThemeButton>
                        </CopyToClipboard>
                    </MDBCol>
                    <MDBCol size='2'>
                        {aff.title}
                    </MDBCol>
                    <MDBCol size='2'>
                        <a className='text-neon border-bottom border-neon' target='_blank' href={`/User/List/?referrer_refId=${affId}`}>{aff.count}</a>
                    </MDBCol>
                    <MDBCol size='2'>
                        {aff.activeUsersCount}
                    </MDBCol>
                    <MDBCol size='2'>
                        %{numeral(aff.activeUsersCount / aff.count * 100).format('0,00')}
                    </MDBCol>
                </MDBRow>
            })}
            <hr />
            <AddNewAffId {...props} />
        </>
    )
}

const AddNewAffId = (props) => {
    const _id = props.user._id;
    const { enqueueSnackbar } = useSnackbar();

    const [values, setValues] = useState({
        open: false,
        edit: true,
        _id,
        affId: '',
        title: '',
        description: '',
    })

    const callAddAffiliate = (params) => {
        axios.post(process.env.REACT_APP_API_HOST + '/admin/users/addAffiliateId',
            params,
            {
                headers: { 'x-auth-token': getBearer() }
            })
            .then(res => {
                res = res.data;
                if (res.success) {
                    enqueueSnackbar(res.message, { variant: 'success', autoHideDuration: 2500 })
                    setValues({
                        open: false,
                        edit: true,
                        _id,
                        affId: '',
                        title: '',
                        description: ''
                    })
                } else {
                    enqueueSnackbar(res.message, { variant: 'warning', autoHideDuration: 2500 })
                }
            })
            .catch(err => {
                enqueueSnackbar('Error!', { variant: 'error', autoHideDuration: 2500 })
            })
    }

    return <MDBRow className='border-bottom border-neon text-neon py-2 fw-bold align-items-center'>
        {!values.open ?
            <MDBCol size='12'>
                <ThemeButton onClick={() => setValues({ ...values, open: !values.open })} variant='outlined' color='success'>
                    <MDBIcon icon='plus' className='me-1'></MDBIcon>Add New AffID
                </ThemeButton>
            </MDBCol>
            :
            <>
                <MDBCol size='12' md='2'>
                    <ThemeInput disabled={!values.edit} inputClassName={values.edit ? 'border border-white' : ''} label='AffId (2 ~ 16)' value={values.affId} onChange={e => setValues({ ...values, affId: e.target.value })}></ThemeInput>
                </MDBCol>
                <MDBCol size='12' md='2'>
                    <ThemeInput disabled={!values.edit} inputClassName={values.edit ? 'border border-white' : ''} label='Title' value={values.title} onChange={e => setValues({ ...values, title: e.target.value })}></ThemeInput>
                </MDBCol>
                <MDBCol size='12' md='2'>
                    <ThemeInput disabled={!values.edit} inputClassName={values.edit ? 'border border-white' : ''} label='Description' value={values.description} onChange={e => setValues({ ...values, description: e.target.value })}></ThemeInput>
                </MDBCol>
                <MDBCol size='12' md='2'>
                    <ThemeButton variant='outlined' className='px-4 py-2 text-white' onClick={() => callAddAffiliate(_.pick(values, '_id', 'title', 'affId', 'description'))} color='success'>
                        <MDBIcon icon='check' className='me-1'></MDBIcon>Save
                    </ThemeButton>
                </MDBCol>
            </>
        }
    </MDBRow>

}

export default UserAffiliate
