import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import { useSnackbar } from 'notistack';
import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard';
import ReactJson from 'react-json-view';
import ThemeButton from '../../layout/components/ThemeButton';

const TransactionMeta = (props) => {
    const { meta, _id } = props;
    const { setTxValues, ...tx } = props;
    const { enqueueSnackbar } = useSnackbar();

    return <MDBRow className='small m-0'>
        <MDBCol className='small' size='12'>
            <div className='d-flex py-2'>
                <div>
                    <CopyToClipboard
                        text={JSON.stringify(meta) || '{}'}
                        onCopy={() => { enqueueSnackbar('Copied.', { variant: 'warning', autoHideDuration: 2500 }) }}>
                        <ThemeButton size='small' variant='outlined' className='rounded-pill btn-sm' color='info'>Copy Meta<MDBIcon className='ps-1' icon='copy'></MDBIcon></ThemeButton>
                    </CopyToClipboard>
                    <CopyToClipboard text={_id}
                        onCopy={() => { enqueueSnackbar('Copied.', { variant: 'warning', autoHideDuration: 2500 }) }}>
                        <ThemeButton size='small' variant='outlined' className='rounded-pill btn-sm mx-3' color='warning'>_id: {_id}<MDBIcon className='ps-1' icon='copy'></MDBIcon></ThemeButton>
                    </CopyToClipboard>
                </div>
                <ThemeButton onClick={() => setTxValues(props)} size='small' variant='outlined' className='rounded-pill btn-sm ms-auto' color='danger'>Edit Transaction<MDBIcon className='ps-1' icon='edit'></MDBIcon></ThemeButton>
            </div>
            <div className='border border-info'>
                <ReactJson collapsed={1} name={null} style={{ padding: '0.5rem' }} theme="monokai" src={tx} />
            </div>
        </MDBCol>
    </MDBRow>
}

export default TransactionMeta