import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import { MDBIcon } from 'mdb-react-ui-kit';
import theme from '../DefaultTheme'

const styles = {
    disabled: {
        opacity: 0.4
    }
};

const generateStyledButton = (props) => {
    let { color, variant, size, disabled } = props;
    color = theme.palette[color] ? theme.palette[color] : theme.palette.secondary;
    const outlined = variant === 'outlined';
    const sx = {
        transition: 'all 0.1s ease-in-out',
        fontSize: !size ? '0.7rem !important' : size === 'xs' ? '0.6rem !important' : size === 'small' ? '0.5rem !important' : size === 'medium' ? '0.8rem !important' : '1rem !important',
        lineHeight: !size ? '0.7rem !important' : size === 'xs' ? '0.6rem !important' : size === 'small' ? '0.5rem !important' : size === 'medium' ? '0.8rem !important' : '1rem !important',
        background: (outlined ? 'transparent' : color.main) + ' !important',
        color: (outlined ? color.main : theme.palette.primary.main) + ' !important',
        borderWidth: `1px`,
        borderStyle: 'solid',
        borderColor: color.main,
        minWidth: 'auto',
        boxShadow: `0px 0px 10px ${color.main}`,
        '&:hover': {
            textShadow: `0px 0px 5px ${color.main}`,
            boxShadow: `0px 0px 5px ${color.main}`,
        },
        opacity: disabled ? 0.4 : 1
    }
    return sx
}


function ThemeButton(props) {
    const { classes, children, className, variant, waiting, disabled, color, size, sx, ...other } = props;
    return (
        <Button
            className={'rounded-pill px-1 py-1 ' + className}
            sx={{
                ...generateStyledButton(props),
                ...sx
            }}
            disabled={disabled}
            {...other}>
            {
                waiting ?
                    <MDBIcon style={{ fontSize: '1rem' }
                    } spin icon='spinner' ></MDBIcon > :
                    children || 'Button'
            }
        </Button >
    );
}

ThemeButton.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

export default withStyles(styles)(ThemeButton);
