import Box from '@mui/material/Box';
import { Divider, Grid, Input } from '@mui/material';

import React, { useContext, useEffect, useState } from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener';

import $ from 'jquery'
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import _ from 'lodash';
import { countries } from '../../../helpers/publicHelper';

const getCountryByPhone = (phone) => {
    let selectedCountry = {};
    if (phone) {
        selectedCountry = countries.filter(country => country.phone == phone);
    }
    return (selectedCountry.length > 0 ? selectedCountry[0] : {
        phone: '1',
        code: 'US',
        label: 'United States'
    })
}


export default function CountrySelect(props) {
    const { value, onChange, disabled } = props;
    const [countryObj, setCountryObj] = useState(value ? getCountryByPhone(value) : {
        phone: '1',
        code: 'US',
        label: 'United States'
    })

    useEffect(() => {
        if (onChange) onChange(countryObj.phone)
        // setActive(state, dispatch, {
        //     signUp: {
        //         ...state.active.signUp,
        //         countryCode: countryObj.phone
        //     }
        // });
    }, [countryObj])

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        if (!disabled) {
            if (event) {
                setAnchorEl(event.currentTarget);
            }
            setOpen(!open)
            if (!open) {
                setTimeout(() => {
                    document.getElementById('CountrySelectSearch') && document.getElementById('CountrySelectSearch').focus();
                }, 300);
            }
        }
    };

    const handleClickAway = (e) => {
        if ($(e.target).parents(".PhoneInputWrapper").length == 0) {
            setOpen(false)
        }
    }


    return (
        <>
            <Grid
                onClick={handleClick}
                className='cursor-pointer'
                sx={{
                    zIndex: 3,
                    position: 'absolute',
                    left: '0rem',
                    top: '0.1rem',
                    lineHeight: '2rem',
                    paddingRight: '0.3rem',
                    paddingLeft: '0.4rem',
                    borderRight: '1px solid #fff3',
                }}>
                <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${countryObj.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${countryObj.code.toLowerCase()}.png 2x`}
                    alt=""
                />
                <span className='ps-2' style={{
                    fontSize: countryObj.phone.length > 3 ? '0.6rem' : '0.8rem'
                }}>
                    +{countryObj.phone}
                </span>
            </Grid>
            <ClickAwayListener onClickAway={handleClickAway}>
                <div>
                    <CSelect open={open} setOpen={setOpen} selectedCountryObj={countryObj} setSelectedCountryObj={setCountryObj} anchorEl={anchorEl} handleClick={handleClick}></CSelect>
                </div>
            </ClickAwayListener>
        </>
    );
}


const CSelect = (props) => {
    const { anchorEl, selectedCountryObj, setSelectedCountryObj, open, setOpen } = props;

    const [filteredCountries, setFilteredCountries] = useState(countries)
    const [searchValue, setSearchValue] = useState('')

    const filterCountries = (search) => {
        search = search.toLowerCase();
        var results = _.filter(countries, function (item) {
            const label = item.label.toLowerCase();
            const code = item.code.toLowerCase();
            const phone = item.phone;
            const alt = item.alt;
            let result;
            result = (label.indexOf(search) > -1) || (code.indexOf(search) > -1) || (phone.indexOf(search) > -1) || (alt.indexOf(search) > -1)
            return result;
        });
        let sortedResults = [];
        // Exact Code
        sortedResults = sortedResults.concat(_.filter(results, function (item) {
            return item.code.toLowerCase() == search;
        }))
        // Exact Alt 
        sortedResults = sortedResults.concat(_.filter(results, function (item) {
            return item.code.toLowerCase() !== search && item.alt.indexOf(search) == 0;
        }))
        // Label starts with search
        sortedResults = sortedResults.concat(_.filter(results, function (item) {
            return item.code.toLowerCase() !== search && item.alt.indexOf(search) < 0 && item.label.toLowerCase().indexOf(search) == 0;
        }))
        // Label contains search
        sortedResults = sortedResults.concat(_.filter(results, function (item) {
            return item.code.toLowerCase() !== search && item.alt.indexOf(search) < 0 && item.label.toLowerCase().indexOf(search) > 0;
        }))
        setFilteredCountries(sortedResults)
    }

    useEffect(() => {
        if (open) {
            setSearchValue('');
            setFilteredCountries(countries)
        }
    }, [open])

    return (
        <Box sx={{ width: '3.5rem', zIndex: 9997 }}>
            <Popper style={{ zIndex: 9998 }} open={open} anchorEl={anchorEl} placement='bottom-start' transition >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={200}>
                        <Paper className='mt-1 rounded' sx={{ border: '1px solid #fff8', overflowX: 'hidden', minWidth: '14.5rem', minHeight: '10rem' }}>
                            <Grid>
                                <Input
                                    value={searchValue}
                                    id='CountrySelectSearch'
                                    autoComplete='search'
                                    type='search'
                                    readOnly={true}
                                    onFocus={(e) => e.target.removeAttribute('readonly')}
                                    onChange={(e) => { filterCountries(e.target.value); setSearchValue(e.target.value) }}
                                    sx={{
                                        fontSize: '0.8rem',
                                    }} className='w-100 small text-small px-2 text-neon bg-black' placeholder='search'></Input>
                            </Grid>
                            <Divider></Divider>
                            {filteredCountries.length ?
                                <List className='noScrollbar' sx={{ width: '100%', maxHeight: '20rem', overflow: 'scroll', bgcolor: 'background.paper' }}>
                                    {
                                        filteredCountries.map((country, i) => <ListItem sx={{
                                            borderBottom: '1px solid #fff3'
                                        }} className='cursor-pointer py-0' onClick={() => {
                                            setOpen(false);
                                            setSelectedCountryObj(country)
                                        }
                                        } key={i}>
                                            <ListItemAvatar className='position-relative' style={{ minWidth: '2.5rem' }}>
                                                <div className='position-relative' style={{ height: '2.5rem', display: 'flex', alignItems: 'center' }}>
                                                    <img
                                                        loading="lazy"
                                                        width="20"
                                                        src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                                                        srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                                                        alt=""
                                                    />
                                                </div>
                                            </ListItemAvatar>
                                            <ListItemText sx={{
                                                maxWidth: '10rem'
                                            }} className={'p-0 m-0 ' + selectedCountryObj == country ? 'text-neon' : 'text-white'} primary={<small style={{ fontSize: '0.7rem' }}>(+{country.phone}) {country.label}</small>} />
                                        </ListItem>)
                                    }
                                </List> : <div className='p-2 text-center small text-small'>
                                    Not found.
                                </div>}
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </Box>
    );
}
