import React, { useEffect, useState, useRef, useContext, useLayoutEffect } from 'react';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import axios from 'axios';
import { AppBar, Box, Grid, LinearProgress, Modal, Pagination, Toolbar, Typography } from '@mui/material';
import numeral from 'numeral';
import { currencyDetail } from '../../helpers/currency';
import ThemeButton from '../../layout/components/ThemeButton';
import ResponsiveTable from '../Tables/ResponsiveTable';
import TransactionFilter from './TransactionFilter';
import { useSnackbar } from 'notistack';
import { getColorByBetState, getTransactions } from '../../helpers/adminHelpers';
import { getBearer } from '../../helpers/publicHelper';
import _ from 'lodash';
import ThemeFormatCurrency from '../../layout/components/ThemeFormatCurrency';
import { BreadCrumbContext } from '../../context/store';
import { tblFormatId, tblFormatTime, tblFormatUserId } from '../../helpers/formatHelpers';
import UserQuickViewButton from '../Users/UserQuickViewButton';
import TransactionDetails from './TransactionDetails/TransactionDetails';
import TransactionMeta from './TransactionMeta';
import TransactionIDs from './TransactionIDs';
import TransactionListMobile from './TransactionListMobile';
import useMediaQuery from '@mui/material/useMediaQuery';
import RenderType from './Renders/RenderType';
import RenderService from './Renders/RenderService';
import RenderOdds from './Renders/RenderOdds';
import RenderUserBalance from './Renders/RenderUserBalance';

const FormatBetAmount = (all) => {
    const { subtype, currency, blockchain, type, meta } = all;
    const icon = <ThemeFormatCurrency icon='png' iconSize={1} blockchain={blockchain} currency={currency} hideText />;
    if (type == 'bet') {
        const { bet } = meta;
        const { betAmountInUSD } = bet;
        const color = getColorByBetState(subtype);
        return <div className='d-flex justify-content-center justify-content-md-between'>
            {icon}
            <span className={`text-capitalize border-bottom border-${color} text-${color}`}>
                {numeral(betAmountInUSD).format('$0,0.00')}
            </span>
        </div>
    } else {
        return <div className='d-flex justify-content-center justify-content-md-between'>
            {icon}
        </div>
    }
}

const FormatWinAmount = (all) => {
    const { type, subtype, meta } = all;

    if (type == 'bet') {
        const { bet } = meta;
        const { winAmountInUSD, betAmountInUSD, odds } = bet;
        const color = getColorByBetState(subtype);
        return <div className='d-flex justify-content-center justify-content-md-end'>
            <span className={`text-capitalize border-bottom border-${color} text-${color}`}>
                {subtype == 'open' && odds && numeral(betAmountInUSD * odds).format(currencyDetail['USD'].format)}
                {subtype !== 'open' && numeral(winAmountInUSD).format(currencyDetail['USD'].format)}
            </span>
        </div>
    } else {
        return <></>
    }
}

const FormatPL = (all) => {
    const { amount, status, service, meta, type, currency } = all;
    const { amountInUSD } = meta;
    if (type == 'deposit') {
        let color = amount > 0 ? 'success' : 'danger';
        return <div className='d-flex w-100 justify-content-end align-items-center'>
            <strong className={`text-capitalize small p${meta.bonus ? 'x-1' : 'x-1'} rounded-3 box-shadow-${color} bg-${color}`}>
                <small className='d-block'>
                    {numeral(amount < 0.000001 ? 0 : amount).format(currencyDetail[currency].format)} <span>{currency}</span>
                </small>
                <span style={{ marginRight: '2px' }}>
                    {amount < 0 ? '-$' : '$'}
                </span>
                {numeral(amountInUSD < 0.000001 ? 0 : amountInUSD).format(currencyDetail['USD'].format)}
            </strong>
        </div>
    }

    if (type == 'withdraw') {
        let cls = amount > 0 ? 'box-shadow-success bg-success' : 'box-shadow-danger bg-danger';
        if (status == 'rejected') {
            cls = amount > 0 ? 'text-shadow-success border text-success border-success' : 'text-shadow-danger text-danger border border-danger';
        }

        return <div className='d-flex w-100 justify-content-end align-items-center'>
            <strong className={`text-capitalize small p${meta.bonus ? 'x-1' : 'x-1'} rounded-3 ${cls}`}>
                <small className='d-block'>
                    {numeral(Math.abs(amount) < 0.000001 ? 0 : Math.abs(amount)).format(currencyDetail[currency].format)} <span>{currency}</span>
                </small>
                <span style={{ marginRight: '2px' }}>
                    {amount < 0 ? '-$' : '$'}
                </span>
                {numeral(Math.abs(amountInUSD) < 0.000001 ? 0 : Math.abs(amountInUSD)).format(currencyDetail['USD'].format)}
            </strong>
        </div>
    }

    // Version 2.0
    if (type == 'bet') {
        const { betAmountInUSD, winAmountInUSD, betState } = meta.bet;
        const color = betState == 'open' ? 'yellow' : (winAmountInUSD - betAmountInUSD > 0.000001) ? 'success' : (winAmountInUSD == betAmountInUSD) ? 'orange' : 'danger';
        const PL = winAmountInUSD - betAmountInUSD;
        return <div className='d-flex justify-content-end'>
            <strong className={`text-capitalize border-bottom border-${color} text-${color}`}>
                {betState == 'open' ? '?' :
                    <>
                        {numeral(Math.abs(winAmountInUSD - betAmountInUSD) < 0.000001 ? 0 : Math.abs(winAmountInUSD - betAmountInUSD)).format('$0,0.00')}
                    </>}
            </strong>
        </div>
    }
    // if ((service == 'Evolution' || service == 'RedTiger' || service == 'Netent') && meta) {
    //     const { relatedEvoTransactions } = meta;
    //     if (!relatedEvoTransactions) return <span>?</span>
    //     if (relatedEvoTransactions.length != 1) return <span className='bg-danger'>Multi</span>
    //     // betAmount = relatedEvoTransactions[0].transaction ? relatedEvoTransactions[0].transaction.amount : '0';
    // }

    // if (service == 'Digitain' && meta) {
    //     const { relatedSportTransactions } = meta;
    //     if (!relatedSportTransactions) return <span>?</span>
    //     if (relatedSportTransactions.length !== 1) return <span>??</span>
    //     if (relatedSportTransactions[0].meta) {
    //         // betAmount = relatedSportTransactions[0].meta.Order.Bets[0].BetAmount;
    //     }
    // }

    if (type == 'topup') {
        let color = amount > 0 ? 'success' : 'danger';
        return <div className='d-flex w-100 justify-content-end align-items-center'>
            <strong className={`text-capitalize small p${meta.bonus ? 'x-1' : '-1'} rounded-3 box-shadow-${color} bg-${color}`}>
                {numeral(Math.abs(amountInUSD)).format('$0,0.00')}
                <div className='small'>
                    {numeral(Math.abs(amount)).format(currencyDetail[currency].format)} {currency}
                </div>
            </strong>
        </div>
    }
}

const EditTransactionModal = (extraProps) => {
    const { setTxValues, ...props } = extraProps;
    // const { userId } = txValues;

    const { enqueueSnackbar } = useSnackbar();
    const handleClose = () => {
        setTxValues({
            tag: [],
            subtype: '',
            description: '',
            comment: '',
            isValid: false,
            _id: ''
        })
    }

    const callEditTransaction = (params) => {
        axios.post(process.env.REACT_APP_API_HOST + `/superadmin/transactions/editTransaction`,
            {
                params
            },
            {
                headers: { 'x-auth-token': getBearer() }
            })
            .then(res => {
                res = res.data;
                enqueueSnackbar(res.message, { variant: res.success ? 'success' : 'error', autoHideDuration: 2500 })
            })
    }

    return <Modal
        open={props._id !== ''}
        disableScrollLock={true}
        onClose={handleClose}>
        <Box className='box-shadow-neon rounded-6 overflow-hidden' sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
        }}>
            <MDBRow className='m-0 bg-black py-2'>
                <MDBCol size='12'>
                    <Typography variant='body2'>
                        <Typography className='text-neon' variant='body2'>
                            <strong className='text-neon'>Edit Transaction</strong>
                            <MDBIcon onClick={handleClose} className='float-end pt-1 cursor-pointer' icon='times'></MDBIcon>
                        </Typography>
                        <hr className='my-2' />
                        <Box className='d-flex justify-content-between'>
                            <Box>
                                <strong className='text-neon'>User Id: </strong>
                                <UserQuickViewButton userId={props.userId}></UserQuickViewButton>
                            </Box>
                            <Box>
                                <RenderType {...props} />
                            </Box>
                        </Box>
                        <hr className='my-2' />
                        <Box sx={{ fontSize: '0.8rem', }}>
                            <RenderService full {...props} />
                        </Box>
                        <hr className='my-2' />
                        <Box className='d-flex justify-content-between'>
                            <Box>
                                <div className='text-center opacity-40 small'>Bet Amount</div>
                                <FormatBetAmount {...props} />
                            </Box>
                            <Box>
                                <div className='text-center opacity-40 small'>Win Amount</div>
                                <FormatWinAmount {...props} />
                            </Box>
                            <Box>
                                <div className='text-center opacity-40 small'>P/L</div>
                                <FormatPL {...props} />
                            </Box>
                        </Box>
                    </Typography>
                </MDBCol>
                <MDBCol size='12'>
                    <hr className='my-2' />
                    <form className='' onSubmit={e => { e.preventDefault(); callEditTransaction(props) }} noValidate autoComplete="off">
                        <div className='text-end mt-3'>
                            <ThemeButton className='px-3' type='submit' variant='outlined' color={'success'}>Save</ThemeButton>
                        </div>
                    </form>
                </MDBCol>
            </MDBRow>
        </Box>
    </Modal>
}

const TransactionList = (props) => {
    const userId = new URLSearchParams(window.location.search).get('userId') || props.userId;
    const type = new URLSearchParams(window.location.search).get('type') || props.type;
    const from = new URLSearchParams(window.location.search).get('from') || props.from;
    const to = new URLSearchParams(window.location.search).get('to') || props.to;
    const isMobile = useMediaQuery('(max-width:600px)') || props.mobile;

    const { enqueueSnackbar } = useSnackbar();
    const [contextBC, setContextBC] = useContext(BreadCrumbContext);
    const [txValues, setTxValues] = useState({
        tag: [],
        subtype: '',
        description: '',
        comment: '',
        isValid: false,
        _id: ''
    })

    const columns = [
        tblFormatId(),
        tblFormatTime(),
        tblFormatUserId(),
        {
            id: 'all',
            label: 'Type',
            align: 'center',
            style: { width: '6rem' },
            labelStyle: { width: '6rem' },
            format: (all) => <RenderType {...all} />
            // }, {
            //     id: 'all',
            //     label: 'SubType',
            //     align: 'center',
            //     style: { width: '5rem' },
            //     labelStyle: { width: '5rem' },
            //     format: (all) => <FormatSubType key={all._id} {...all} />
            // }, {
            //     id: 'isValid',
            //     label: 'Valid',
            //     align: 'center',
            //     style: { width: '3rem' },
            //     labelStyle: { width: '3rem' },
            //     format: (isValid) => <span className='text-white'>{isValid ? <MDBIcon className='text-success' icon='check'></MDBIcon> : <MDBIcon className='text-danger' icon='times'></MDBIcon>}</span>
            // }, {
            //     id: 'status',
            //     label: 'Status',
            //     align: 'center',
            //     style: { width: '3rem' },
            //     labelStyle: { width: '3rem' },
            //     format: (status) => formatStatus(status)
        }, {
            id: 'all',
            label: 'SRV',
            align: 'center',
            style: { width: '2rem' },
            labelStyle: { width: '2rem' },
            format: (all) => <RenderService {...all} />
        }, {
            id: 'all',
            label: 'Bet Amount',
            labelAlign: 'right',
            align: 'right',
            style: { width: '7rem' },
            labelStyle: { width: '7rem' },
            format: (all) => <FormatBetAmount {...all} />
        }, {
            id: 'all',
            label: 'Odds',
            labelAlign: 'center',
            align: 'center',
            style: { width: '2rem' },
            labelStyle: { width: '2rem' },
            format: (all) => <RenderOdds {...all} />
        }, {
            id: 'all',
            label: 'Win Amount',
            labelAlign: 'right',
            align: 'right',
            style: { width: '6rem' },
            labelStyle: { width: '6rem' },
            format: (all) => <FormatWinAmount {...all} />
        }, {
            id: 'all',
            label: 'P/L',
            labelAlign: 'right',
            align: 'right',
            style: { width: '7rem' },
            labelStyle: { width: '7rem' },
            format: (all) => <FormatPL {...all} />

        }, {
            id: 'all',
            label: 'User Balance',
            labelAlign: 'center',
            align: 'left',
            style: { width: '9rem' },
            labelStyle: { width: '9rem' },
            format: (all) => <RenderUserBalance {...all} />
        }, {
            id: 'all',
            label: 'BetID / GameId',
            labelAlign: 'left',
            align: 'left',
            style: { width: '16rem' },
            labelStyle: { width: '16rem' },
            format: (all) => <TransactionIDs {...all} />
        }, {
            id: 'all',
            label: 'Details',
            align: 'center',
            style: { width: '18rem' },
            labelStyle: { width: '18rem' },
            format: (all) => <TransactionDetails {...all} />
        }, {
            id: 'all',
            label: 'all',
            collapse: true,
            align: 'left',
            style: {
                overflow: 'hidden',
                padding: '0 100px !important',
                whiteSpace: 'break-spaces !important'
            }, format: all => <TransactionMeta setTxValues={setTxValues} {...all} />
        }
    ];


    useLayoutEffect(() => {
        setContextBC([
            {
                href: '/',
                text: 'Home'
            },
            {
                href: '/financial',
                text: 'Financial'
            },
            {
                text: 'Transactions'
            }
        ])
    }, [])

    const [rows, setRows] = useState([])
    const interval = useRef(null);
    const [filter, setFilter] = useState({
        find: {
            userId: userId,
            type: type
        },
        limit: 20,
        skip: 0,
        sort: { createdAt: -1 }
    })

    const filterRef = useRef();
    filterRef.current = filter;

    const handlePagination = (e, page) => {
        const newFilter = { ...filter, skip: (page - 1) * filter.limit };
        setFilter(newFilter);
        getTransactions(setRows, newFilter, enqueueSnackbar);
    }

    const [tableColumns, setTableColumns] = useState(columns);

    useEffect(() => {
        let updateInterval = setInterval(() => {
            getTransactions(setRows, filter, enqueueSnackbar);
        }, 5000);
        interval.current = updateInterval;

        setTableColumns(columns.filter(c => c.label != 'fieldToBeRemoved'))

        return () => {
            clearInterval(interval.current)
        }
    }, [filter])


    return (
        <React.Fragment>
            {
                !props.scrollFilter ?
                    <>    <AppBar sx={{
                        bgcolor: '#000 !important'
                    }}>
                        <Toolbar />
                        {!props.noToolbar && <Toolbar disableGutters variant='dense' />}
                        <Toolbar disableGutters variant='dense' sx={{
                            marginLeft: 'auto',
                            width: '100%',
                            bgcolor: '#0005',
                            paddingLeft: ['0', props.noToolbar ? 0 : '3.5rem'],
                            alignItems: 'baseline',
                        }}>
                            <TransactionFilter from={from} to={to} scrollFilter={props.scrollFilter} mobile={props.mobile} setRows={setRows} getTransactions={getTransactions} count={rows.count} setFilter={setFilter} filter={filter}></TransactionFilter>
                        </Toolbar>
                    </AppBar>
                        <Toolbar />
                    </>
                    :
                    <TransactionFilter from={from} to={to} scrollFilter={props.scrollFilter} mobile={props.mobile} setRows={setRows} getTransactions={getTransactions} count={rows.count} setFilter={setFilter} filter={filter}></TransactionFilter>
            }

            {props.noToolbar && !props.scrollFilter && <Toolbar disableGutters />}
            <Box sx={{ my: 0 }}>
                <Box
                    className='position-fixed- bg-black'
                    sx={{
                        paddingTop: '0.5rem',
                        zIndex: 999,
                        width: ['100%', 'calc(100%  - 3.5rem)']
                    }}>
                </Box>
                {!rows.transactions && <Box className='p-5'>
                    <LinearProgress color="info" />
                </Box>}
                <Box className='w-100'>
                    {isMobile ?
                        <TransactionListMobile transactions={rows.transactions} columns={tableColumns} />
                        :
                        <ResponsiveTable collapsible rows={rows.transactions} columns={tableColumns}></ResponsiveTable>
                    }
                </Box>
                <Box>
                    {
                        Math.floor(rows.count / filter.limit) > 0 &&
                        <MDBRow className='m-0'>
                            <MDBCol size='12' className='text-center py-4'>
                                <Pagination onChange={handlePagination} className='d-inline-block' count={Math.ceil(rows.count / filter.limit)} variant="outlined" shape="rounded" />
                            </MDBCol>
                        </MDBRow>
                    }
                </Box>
                {rows.transactions && <RenderTotal transactions={rows.transactions} limit={filter.limit} count={rows.count} />}
                {
                    txValues._id && <EditTransactionModal {...txValues} setTxValues={setTxValues} />
                }
            </Box>
        </React.Fragment >
    )
}

const calcTotalAmount = (transactions) => {
    let total = {
        deposit: 0,
        depositCount: 0,
        widthdraw: 0,
        withdrawCount: 0,
        topup: 0,
        topupCount: 0,
        betAmountInUSD: 0,
        amount: 0,
        amountInUSD: 0,
        playPL: 0
    };
    for (const key in transactions) {
        if (Object.hasOwnProperty.call(transactions, key)) {
            const transaction = transactions[key];
            const { type, subtype, meta, isValid, status } = transaction;
            if (true || isValid && (status == 'verified')) {
                const amountInUSD = meta.amountInUSD;
                const amount = meta.amount;
                const betAmountInUSD = meta.bet ? meta.bet.betAmountInUSD : 0;
                const winAmountInUSD = meta.bet ? meta.bet.winAmountInUSD : 0;

                total = {
                    deposit: total.deposit + (type == 'deposit' ? amountInUSD : 0),
                    depositCount: total.depositCount + (type == 'deposit' ? 1 : 0),
                    widthdraw: total.widthdraw + ((type == 'withdraw') ? amountInUSD : 0),
                    withdrawCount: total.withdrawCount + (type == 'withdraw' ? 1 : 0),
                    topup: total.topup + (type == 'topup' ? amountInUSD : 0),
                    topupCount: total.topupCount + (type == 'topup' ? 1 : 0),
                    amount: total.amount + amount,
                    amountInUSD: total.amountInUSD + (['deposit', 'withdraw', 'topup'].includes(type) ? amountInUSD : 0),
                    betAmountInUSD: total.betAmountInUSD + betAmountInUSD,
                    playPL: total.playPL + (['won', 'lost'].includes(subtype) ? (winAmountInUSD - betAmountInUSD) : 0),
                }
            }
        }
    }
    return total
}

const getUserIds = (transactions) => {
    return [...new Set(transactions.map(tx => tx.userId))]
}

const RenderTotal = (props) => {
    const { transactions, limit, count } = props;
    const users = getUserIds(transactions);
    const { amount, amountInUSD, playPL, deposit, depositCount, widthdraw, withdrawCount, topup, topupCount } = calcTotalAmount(transactions);

    return <Box className='small box-shadow-neon m-md-2 rounded-4 overflow-hidden'>
        <Grid container className='p-1' sx={{ bgcolor: '#fff1', color: '#fffn' }}>
            <Grid item xs={12}>
                {(limit < count) && <h6 className='text-center text-danger'>
                    There are more records for specified datetime, Adjust the Page Size.
                </h6>}
            </Grid>
            <Grid item xs={12} md={2}>
                Users
                {users.length > 1 && <>
                    <br />
                    ({users.length})
                </>}
            </Grid>
            <Grid item xs={12} md={2}>
                Deposit
                {users.length > 1 && <>
                    <br />
                    {numeral(deposit).format('$0,0.00')}
                </>}
            </Grid>
            <Grid item xs={12} md={2}>
                Withdraw
                {users.length > 1 && <>
                    <br />
                    {numeral(widthdraw).format('$0,0.00')}</>}
            </Grid>
            <Grid item xs={12} md={2}>
                Cashback
                {users.length > 1 && <>
                    <br />
                    {numeral(topup).format('$0,0.00')}</>}
            </Grid>
            {/* <Grid item xs={12} md={2}>
                Win-Lost
                <br />
                {numeral(playPL).format('$0,0.00')}</>}
            </Grid> */}
            <Grid item xs={12} md={2}>
                D-W (PL - Open)
                {users.length > 1 && <>
                    <br />
                    {numeral(deposit + widthdraw).format('$0,0.00')}</>}
            </Grid>
            {/* <Grid item xs={12} md={2}>
                Available Discount
                {users.length > 1 && <>
                    <br />
                    {numeral(((deposit + widthdraw) * 0.15) - topup).format('$0,0.00')}</>}
            </Grid> */}
        </Grid >
        {
            users.map((userId, i) => {
                const userTransactions = transactions.filter(t => t.userId == userId);
                const { amount, amountInUSD, playPL, deposit, depositCount, widthdraw, withdrawCount, topup, topupCount } = calcTotalAmount(userTransactions);
                return <Grid container key={i} className='p-1' sx={{ bgcolor: i % 2 ? '#000' : '#fff2' }}>
                    <Grid item xs={12} md={2}>
                        <UserQuickViewButton userId={userId} />
                    </Grid>
                    <Grid item xs={12} md={2} className={depositCount ? 'text-success' : 'opacity-20'}>
                        <span className='d-md-none  '>Deposit: </span>
                        {numeral(deposit).format('$0,0.00')}
                        <small className='opacity-50 ms-2'>
                            ({numeral(depositCount).format('0,0')})
                        </small>
                    </Grid>
                    <Grid item xs={12} md={2} className={withdrawCount ? 'text-danger' : 'opacity-20'}>
                        <span className='d-md-none'>Withdraw: </span>
                        {numeral(widthdraw).format('$0,0.00')}
                        <small className='opacity-50 ms-2'>
                            ({numeral(withdrawCount).format('0,0')})
                        </small>
                    </Grid>
                    <Grid item xs={12} md={2} className={topupCount ? 'text-neon' : 'opacity-20'}>
                        <span className='d-md-none'>Cashback: </span>
                        {numeral(topup).format('$0,0.00')}
                        <small className='opacity-50 ms-2'>
                            ({numeral(topupCount).format('0,0')})
                        </small>
                    </Grid>
                    {/* <Grid item xs={12} md={2}>
                        <span className='d-md-none'>Play PL: </span>
                        {numeral(playPL).format('0,0.00')}
                    </Grid> */}
                    <Grid item xs={12} md={2}>
                        <span className='d-md-none'>PL!: </span>
                        {numeral(deposit + widthdraw).format('$0,0.00')}
                    </Grid>
                    {/* <Grid className='text-neon' item xs={12} md={2}>
                        <span className='d-md-none'>PL!: </span>
                        {numeral(((deposit + widthdraw) * 0.15) - topup).format('$0,0.00')}
                    </Grid> */}
                </Grid>
            })
        }
    </Box >
}

export default TransactionList
