import { currencyDetail } from "../helpers/currency";

const axios = require("axios");
const numeral = require('numeral')

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
const countries = [
    { code: 'AD', label: 'Andorra', alt: '', phone: '376' },
    { code: 'AE', label: 'United Arab Emirates', alt: 'uae', phone: '971', },
    { code: 'AF', label: 'Afghanistan', alt: '', phone: '93' },
    { code: 'AG', label: 'Antigua and Barbuda', alt: '', phone: '1-268', },
    { code: 'AI', label: 'Anguilla', alt: '', phone: '1-264' },
    { code: 'AL', label: 'Albania', alt: '', phone: '355' },
    { code: 'AM', label: 'Armenia', alt: '', phone: '374' },
    { code: 'AO', label: 'Angola', alt: '', phone: '244' },
    { code: 'AQ', label: 'Antarctica', alt: '', phone: '672' },
    { code: 'AR', label: 'Argentina', alt: '', phone: '54' },
    { code: 'AS', label: 'American Samoa', alt: '', phone: '1-684' },
    { code: 'AT', label: 'Austria', alt: '', phone: '43' },
    { code: 'AU', label: 'Australia', alt: '', phone: '61', },
    { code: 'AW', label: 'Aruba', alt: '', phone: '297' },
    { code: 'AX', label: 'Alland Islands', alt: '', phone: '358' },
    { code: 'AZ', label: 'Azerbaijan', alt: '', phone: '994' },
    { code: 'BA', label: 'Bosnia and Herzegovina', alt: '', phone: '387', },
    { code: 'BB', label: 'Barbados', alt: '', phone: '1-246' },
    { code: 'BD', label: 'Bangladesh', alt: '', phone: '880' },
    { code: 'BE', label: 'Belgium', alt: '', phone: '32' },
    { code: 'BF', label: 'Burkina Faso', alt: '', phone: '226' },
    { code: 'BG', label: 'Bulgaria', alt: '', phone: '359' },
    { code: 'BH', label: 'Bahrain', alt: '', phone: '973' },
    { code: 'BI', label: 'Burundi', alt: '', phone: '257' },
    { code: 'BJ', label: 'Benin', alt: '', phone: '229' },
    { code: 'BL', label: 'Saint Barthelemy', alt: '', phone: '590' },
    { code: 'BM', label: 'Bermuda', alt: '', phone: '1-441' },
    { code: 'BN', label: 'Brunei Darussalam', alt: '', phone: '673' },
    { code: 'BO', label: 'Bolivia', alt: '', phone: '591' },
    { code: 'BR', label: 'Brazil', alt: '', phone: '55' },
    { code: 'BS', label: 'Bahamas', alt: '', phone: '1-242' },
    { code: 'BT', label: 'Bhutan', alt: '', phone: '975' },
    { code: 'BV', label: 'Bouvet Island', alt: '', phone: '47' },
    { code: 'BW', label: 'Botswana', alt: '', phone: '267' },
    { code: 'BY', label: 'Belarus', alt: '', phone: '375' },
    { code: 'BZ', label: 'Belize', alt: '', phone: '501' },
    { code: 'CA', label: 'Canada', alt: '', phone: '1' },
    { code: 'CC', label: 'Cocos (Keeling) Islands', alt: '', phone: '61', },
    { code: 'CD', label: 'Congo, Democratic Republic of the', alt: '', phone: '243', },
    { code: 'CF', label: 'Central African Republic', alt: '', phone: '236', },
    { code: 'CG', label: 'Congo, Republic of the', alt: '', phone: '242', },
    { code: 'CH', label: 'Switzerland', alt: '', phone: '41' },
    { code: 'CI', label: "Cote d'Ivoire", alt: '', phone: '225' },
    { code: 'CK', label: 'Cook Islands', alt: '', phone: '682' },
    { code: 'CL', label: 'Chile', alt: '', phone: '56' },
    { code: 'CM', label: 'Cameroon', alt: '', phone: '237' },
    { code: 'CN', label: 'China', alt: '', phone: '86' },
    { code: 'CO', label: 'Colombia', alt: '', phone: '57' },
    { code: 'CR', label: 'Costa Rica', alt: '', phone: '506' },
    { code: 'CU', label: 'Cuba', alt: '', phone: '53' },
    { code: 'CV', label: 'Cape Verde', alt: '', phone: '238' },
    { code: 'CW', label: 'Curacao', alt: '', phone: '599' },
    { code: 'CX', label: 'Christmas Island', alt: '', phone: '61' },
    { code: 'CY', label: 'Cyprus', alt: '', phone: '357' },
    { code: 'CZ', label: 'Czech Republic', alt: '', phone: '420' },
    { code: 'DE', label: 'Germany', alt: '', phone: '49' },
    { code: 'DJ', label: 'Djibouti', alt: '', phone: '253' },
    { code: 'DK', label: 'Denmark', alt: '', phone: '45' },
    { code: 'DM', label: 'Dominica', alt: '', phone: '1-767' },
    { code: 'DO', label: 'Dominican Republic', alt: '', phone: '1-809', },
    { code: 'DZ', label: 'Algeria', alt: '', phone: '213' },
    { code: 'EC', label: 'Ecuador', alt: '', phone: '593' },
    { code: 'EE', label: 'Estonia', alt: '', phone: '372' },
    { code: 'EG', label: 'Egypt', alt: '', phone: '20' },
    { code: 'EH', label: 'Western Sahara', alt: '', phone: '212' },
    { code: 'ER', label: 'Eritrea', alt: '', phone: '291' },
    { code: 'ES', label: 'Spain', alt: '', phone: '34' },
    { code: 'ET', label: 'Ethiopia', alt: '', phone: '251' },
    { code: 'FI', label: 'Finland', alt: '', phone: '358' },
    { code: 'FJ', label: 'Fiji', alt: '', phone: '679' },
    { code: 'FK', label: 'Falkland Islands (Malvinas)', alt: '', phone: '500', },
    { code: 'FM', label: 'Micronesia, Federated States of', alt: '', phone: '691', },
    { code: 'FO', label: 'Faroe Islands', alt: '', phone: '298' },
    { code: 'FR', label: 'France', alt: '', phone: '33' },
    { code: 'GA', label: 'Gabon', alt: '', phone: '241' },
    { code: 'GB', label: 'United Kingdom', alt: 'uk, ', phone: '44' },
    { code: 'GD', label: 'Grenada', alt: '', phone: '1-473' },
    { code: 'GE', label: 'Georgia', alt: '', phone: '995' },
    { code: 'GF', label: 'French Guiana', alt: '', phone: '594' },
    { code: 'GG', label: 'Guernsey', alt: '', phone: '44' },
    { code: 'GH', label: 'Ghana', alt: '', phone: '233' },
    { code: 'GI', label: 'Gibraltar', alt: '', phone: '350' },
    { code: 'GL', label: 'Greenland', alt: '', phone: '299' },
    { code: 'GM', label: 'Gambia', alt: '', phone: '220' },
    { code: 'GN', label: 'Guinea', alt: '', phone: '224' },
    { code: 'GP', label: 'Guadeloupe', alt: '', phone: '590' },
    { code: 'GQ', label: 'Equatorial Guinea', alt: '', phone: '240' },
    { code: 'GR', label: 'Greece', alt: '', phone: '30' },
    { code: 'GS', label: 'South Georgia and the South Sandwich Islands', alt: '', phone: '500', },
    { code: 'GT', label: 'Guatemala', alt: '', phone: '502' },
    { code: 'GU', label: 'Guam', alt: '', phone: '1-671' },
    { code: 'GW', label: 'Guinea-Bissau', alt: '', phone: '245' },
    { code: 'GY', label: 'Guyana', alt: '', phone: '592' },
    { code: 'HK', label: 'Hong Kong', alt: '', phone: '852' },
    { code: 'HM', label: 'Heard Island and McDonald Islands', alt: '', phone: '672', },
    { code: 'HN', label: 'Honduras', alt: '', phone: '504' },
    { code: 'HR', label: 'Croatia', alt: '', phone: '385' },
    { code: 'HT', label: 'Haiti', alt: '', phone: '509' },
    { code: 'HU', label: 'Hungary', alt: '', phone: '36' },
    { code: 'ID', label: 'Indonesia', alt: '', phone: '62' },
    { code: 'IE', label: 'Ireland', alt: '', phone: '353' },
    { code: 'IL', label: 'Israel', alt: '', phone: '972' },
    { code: 'IM', label: 'Isle of Man', alt: '', phone: '44' },
    { code: 'IN', label: 'India', alt: '', phone: '91' },
    { code: 'IO', label: 'British Indian Ocean Territory', alt: '', phone: '246', },
    { code: 'IQ', label: 'Iraq', alt: '', phone: '964' },
    { code: 'IR', label: 'Iran, Islamic Republic of', alt: '', phone: '98', },
    { code: 'IS', label: 'Iceland', alt: '', phone: '354' },
    { code: 'IT', label: 'Italy', alt: '', phone: '39' },
    { code: 'JE', label: 'Jersey', alt: '', phone: '44' },
    { code: 'JM', label: 'Jamaica', alt: '', phone: '1-876' },
    { code: 'JO', label: 'Jordan', alt: '', phone: '962' },
    { code: 'JP', label: 'Japan', alt: '', phone: '81' },
    { code: 'KE', label: 'Kenya', alt: '', phone: '254' },
    { code: 'KG', label: 'Kyrgyzstan', alt: '', phone: '996' },
    { code: 'KH', label: 'Cambodia', alt: '', phone: '855' },
    { code: 'KI', label: 'Kiribati', alt: '', phone: '686' },
    { code: 'KM', label: 'Comoros', alt: '', phone: '269' },
    { code: 'KN', label: 'Saint Kitts and Nevis', alt: '', phone: '1-869', },
    { code: 'KP', label: "Korea, Democratic People's Republic of", alt: '', phone: '850', },
    { code: 'KR', label: 'Korea, Republic of', alt: '', phone: '82' },
    { code: 'KW', label: 'Kuwait', alt: '', phone: '965' },
    { code: 'KY', label: 'Cayman Islands', alt: '', phone: '1-345' },
    { code: 'KZ', label: 'Kazakhstan', alt: '', phone: '7' },
    { code: 'LA', label: "Lao People's Democratic Republic", alt: '', phone: '856', },
    { code: 'LB', label: 'Lebanon', alt: '', phone: '961' },
    { code: 'LC', label: 'Saint Lucia', alt: '', phone: '1-758' },
    { code: 'LI', label: 'Liechtenstein', alt: '', phone: '423' },
    { code: 'LK', label: 'Sri Lanka', alt: '', phone: '94' },
    { code: 'LR', label: 'Liberia', alt: '', phone: '231' },
    { code: 'LS', label: 'Lesotho', alt: '', phone: '266' },
    { code: 'LT', label: 'Lithuania', alt: '', phone: '370' },
    { code: 'LU', label: 'Luxembourg', alt: '', phone: '352' },
    { code: 'LV', label: 'Latvia', alt: '', phone: '371' },
    { code: 'LY', label: 'Libya', alt: '', phone: '218' },
    { code: 'MA', label: 'Morocco', alt: '', phone: '212' },
    { code: 'MC', label: 'Monaco', alt: '', phone: '377' },
    { code: 'MD', label: 'Moldova, Republic of', alt: '', phone: '373', },
    { code: 'ME', label: 'Montenegro', alt: '', phone: '382' },
    { code: 'MF', label: 'Saint Martin (French part)', alt: '', phone: '590', },
    { code: 'MG', label: 'Madagascar', alt: '', phone: '261' },
    { code: 'MH', label: 'Marshall Islands', alt: '', phone: '692' },
    { code: 'MK', label: 'Macedonia, the Former Yugoslav Republic of', alt: '', phone: '389', },
    { code: 'ML', label: 'Mali', alt: '', phone: '223' },
    { code: 'MM', label: 'Myanmar', alt: '', phone: '95' },
    { code: 'MN', label: 'Mongolia', alt: '', phone: '976' },
    { code: 'MO', label: 'Macao', alt: '', phone: '853' },
    { code: 'MP', label: 'Northern Mariana Islands', alt: '', phone: '1-670', },
    { code: 'MQ', label: 'Martinique', alt: '', phone: '596' },
    { code: 'MR', label: 'Mauritania', alt: '', phone: '222' },
    { code: 'MS', label: 'Montserrat', alt: '', phone: '1-664' },
    { code: 'MT', label: 'Malta', alt: '', phone: '356' },
    { code: 'MU', label: 'Mauritius', alt: '', phone: '230' },
    { code: 'MV', label: 'Maldives', alt: '', phone: '960' },
    { code: 'MW', label: 'Malawi', alt: '', phone: '265' },
    { code: 'MX', label: 'Mexico', alt: '', phone: '52' },
    { code: 'MY', label: 'Malaysia', alt: '', phone: '60' },
    { code: 'MZ', label: 'Mozambique', alt: '', phone: '258' },
    { code: 'NA', label: 'Namibia', alt: '', phone: '264' },
    { code: 'NC', label: 'New Caledonia', alt: '', phone: '687' },
    { code: 'NE', label: 'Niger', alt: '', phone: '227' },
    { code: 'NF', label: 'Norfolk Island', alt: '', phone: '672' },
    { code: 'NG', label: 'Nigeria', alt: '', phone: '234' },
    { code: 'NI', label: 'Nicaragua', alt: '', phone: '505' },
    { code: 'NL', label: 'Netherlands', alt: '', phone: '31' },
    { code: 'NO', label: 'Norway', alt: '', phone: '47' },
    { code: 'NP', label: 'Nepal', alt: '', phone: '977' },
    { code: 'NR', label: 'Nauru', alt: '', phone: '674' },
    { code: 'NU', label: 'Niue', alt: '', phone: '683' },
    { code: 'NZ', label: 'New Zealand', alt: '', phone: '64' },
    { code: 'OM', label: 'Oman', alt: '', phone: '968' },
    { code: 'PA', label: 'Panama', alt: '', phone: '507' },
    { code: 'PE', label: 'Peru', alt: '', phone: '51' },
    { code: 'PF', label: 'French Polynesia', alt: '', phone: '689' },
    { code: 'PG', label: 'Papua New Guinea', alt: '', phone: '675' },
    { code: 'PH', label: 'Philippines', alt: '', phone: '63' },
    { code: 'PK', label: 'Pakistan', alt: '', phone: '92' },
    { code: 'PL', label: 'Poland', alt: '', phone: '48' },
    { code: 'PM', label: 'Saint Pierre and Miquelon', alt: '', phone: '508', },
    { code: 'PN', label: 'Pitcairn', alt: '', phone: '870' },
    { code: 'PR', label: 'Puerto Rico', alt: '', phone: '1' },
    { code: 'PS', label: 'Palestine, State of', alt: '', phone: '970', },
    { code: 'PT', label: 'Portugal', alt: '', phone: '351' },
    { code: 'PW', label: 'Palau', alt: '', phone: '680' },
    { code: 'PY', label: 'Paraguay', alt: '', phone: '595' },
    { code: 'QA', label: 'Qatar', alt: '', phone: '974' },
    { code: 'RE', label: 'Reunion', alt: '', phone: '262' },
    { code: 'RO', label: 'Romania', alt: '', phone: '40' },
    { code: 'RS', label: 'Serbia', alt: '', phone: '381' },
    { code: 'RU', label: 'Russian Federation', alt: '', phone: '7' },
    { code: 'RW', label: 'Rwanda', alt: '', phone: '250' },
    { code: 'SA', label: 'Saudi Arabia', alt: '', phone: '966' },
    { code: 'SB', label: 'Solomon Islands', alt: '', phone: '677' },
    { code: 'SC', label: 'Seychelles', alt: '', phone: '248' },
    { code: 'SD', label: 'Sudan', alt: '', phone: '249' },
    { code: 'SE', label: 'Sweden', alt: '', phone: '46' },
    { code: 'SG', label: 'Singapore', alt: '', phone: '65' },
    { code: 'SH', label: 'Saint Helena', alt: '', phone: '290' },
    { code: 'SI', label: 'Slovenia', alt: '', phone: '386' },
    { code: 'SJ', label: 'Svalbard and Jan Mayen', alt: '', phone: '47', },
    { code: 'SK', label: 'Slovakia', alt: '', phone: '421' },
    { code: 'SL', label: 'Sierra Leone', alt: '', phone: '232' },
    { code: 'SM', label: 'San Marino', alt: '', phone: '378' },
    { code: 'SN', label: 'Senegal', alt: '', phone: '221' },
    { code: 'SO', label: 'Somalia', alt: '', phone: '252' },
    { code: 'SR', label: 'Suriname', alt: '', phone: '597' },
    { code: 'SS', label: 'South Sudan', alt: '', phone: '211' },
    { code: 'ST', label: 'Sao Tome and Principe', alt: '', phone: '239', },
    { code: 'SV', label: 'El Salvador', alt: '', phone: '503' },
    { code: 'SX', label: 'Sint Maarten (Dutch part)', alt: '', phone: '1-721', },
    { code: 'SY', label: 'Syrian Arab Republic', alt: '', phone: '963', },
    { code: 'SZ', label: 'Swaziland', alt: '', phone: '268' },
    { code: 'TC', label: 'Turks and Caicos Islands', alt: '', phone: '1-649', },
    { code: 'TD', label: 'Chad', alt: '', phone: '235' },
    { code: 'TF', label: 'French Southern Territories', alt: '', phone: '262', },
    { code: 'TG', label: 'Togo', alt: '', phone: '228' },
    { code: 'TH', label: 'Thailand', alt: '', phone: '66' },
    { code: 'TJ', label: 'Tajikistan', alt: '', phone: '992' },
    { code: 'TK', label: 'Tokelau', alt: '', phone: '690' },
    { code: 'TL', label: 'Timor-Leste', alt: '', phone: '670' },
    { code: 'TM', label: 'Turkmenistan', alt: '', phone: '993' },
    { code: 'TN', label: 'Tunisia', alt: '', phone: '216' },
    { code: 'TO', label: 'Tonga', alt: '', phone: '676' },
    { code: 'TR', label: 'Turkey', alt: '', phone: '90' },
    { code: 'TT', label: 'Trinidad and Tobago', alt: '', phone: '1-868', },
    { code: 'TV', label: 'Tuvalu', alt: '', phone: '688' },
    { code: 'TW', label: 'Taiwan, Province of China', alt: '', phone: '886', },
    { code: 'TZ', label: 'United Republic of Tanzania', alt: '', phone: '255', },
    { code: 'UA', label: 'Ukraine', alt: '', phone: '380' },
    { code: 'UG', label: 'Uganda', alt: '', phone: '256' },
    { code: 'US', label: 'United States', alt: 'usa, ', phone: '1' },
    { code: 'UY', label: 'Uruguay', alt: '', phone: '598' },
    { code: 'UZ', label: 'Uzbekistan', alt: '', phone: '998' },
    { code: 'VA', label: 'Holy See (Vatican City State)', alt: '', phone: '379', },
    { code: 'VC', label: 'Saint Vincent and the Grenadines', alt: '', phone: '1-784', },
    { code: 'VE', label: 'Venezuela', alt: '', phone: '58' },
    { code: 'VG', label: 'British Virgin Islands', alt: '', phone: '1-284', },
    { code: 'VI', label: 'US Virgin Islands', alt: '', phone: '1-340', },
    { code: 'VN', label: 'Vietnam', alt: '', phone: '84' },
    { code: 'VU', label: 'Vanuatu', alt: '', phone: '678' },
    { code: 'WF', label: 'Wallis and Futuna', alt: '', phone: '681' },
    { code: 'WS', label: 'Samoa', alt: '', phone: '685' },
    { code: 'XK', label: 'Kosovo', alt: '', phone: '383' },
    { code: 'YE', label: 'Yemen', alt: '', phone: '967' },
    { code: 'YT', label: 'Mayotte', alt: '', phone: '262' },
    { code: 'ZA', label: 'South Africa', alt: '', phone: '27' },
    { code: 'ZM', label: 'Zambia', alt: '', phone: '260' },
    { code: 'ZW', label: 'Zimbabwe', alt: '', phone: '263' },
];

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

const getBearer = () => {
    let token = getCookie("bearer");
    token = token ? token : '';
    return token
}

const logout = (state, dispatch, socket) => {
    const headers = { 'x-auth-token': getBearer() }
    axios.get(state.active.host + '/api/users/logout',
        {
            headers: headers
        }
    )
        .then((res) => {
            res = res.data
            if (res.success) {
                state.removeCookie('bearer', { path: '/' });
                socket.close();
                dispatch({
                    type: 'setAll', payload: {
                        login: false,
                        username: '',
                        config: {
                            ...state.config,
                            profile: {},
                            wallet: {}
                        }
                    }
                });
                setTimeout(() => {
                    window.location.reload();
                }, 200);
            }
        })
        .catch((err) => {
            console.error(err)
        })
}


const getBalanceInFiat = (state, currency, fiat) => {
    console.log(state.rates)
    if (!state.user.wallet[currency]) return '0.00';
    const balance = state.user.wallet[currency].balance;
    const format = currencyDetail[currency].format;
    if (currency == fiat) return numeral(balance).format(format);
    if (!state.rates) return 'Loading';
    if (!state.rates[currency.toUpperCase()]) return 'No Rate';
    const amount = balance * state.rates[currency.toUpperCase()][fiat.toUpperCase()];
    console.log('balance: ', balance)
    console.log(`rate ${fiat}: `, state.rates[currency.toUpperCase()][fiat.toUpperCase()])
    console.log('amount: ', amount)
    return numeral(amount).format('0,0.00');
}

const updateRates = (state, dispatch) => {
    axios.get('https://rates.imoon.com/api/rates',
        {
            headers: { 'x-auth-token': getBearer() }
        })
        .then(apiRes => {
            apiRes = apiRes.data;
            if (apiRes.success) {
                dispatch({
                    type: 'setAll', payload: {
                        rates: apiRes.result
                    }
                });
            }
        })
}

const changeActiveCurrency = (state, dispatch, currency) => {
    console.log('changeActiveCurrency', currency)
    if (state.login) {
        setActive(state, dispatch, {
            currencyUpdating: true,
            currency: currency
        })
        axios.post(state.active.host + '/api/users/setcurrency',
            { currency: currency },
            { headers: { 'x-auth-token': getBearer() } })
            .then(res => {
                res = res.data;
                if (res.success) {
                } else {
                }
            })
    } else {
        console.log('changeActiveCurrency', state.login)
    }
}

const updateStateUser = (state, dispatch) => {
    if (state.login) {
        axios.get(state.active.host + '/api/users/me',
            { headers: { 'x-auth-token': getBearer() } })
            .then(res => {
                res = res.data;
                if (res.success
                ) {
                    dispatch({
                        type: 'setAll', payload: {
                            user: {
                                ...res.result
                            }
                        }
                    });
                } else {

                }
            })
    }
}

const kycDetails = (name) => {
    const kyc = {
        ppt: { title: 'Passport' },
        bc: { title: 'Birth Certificate' },
        pp: { title: 'Selfie Photo' },
        id: { title: 'ID Card' }
    }
    if (kyc[name]) {
        return kyc[name]
    } else {
        return {}
    }
}

const getSelectedFiatCharacter = (fiat) => {
    switch (fiat) {
        case 'USD':
            return '$';
        case 'EUR':
            return '€';
        case 'JPY':
            return '¥';
        case 'GBP':
            return '£';
        case 'RUB':
            return '₽';
        default:
            return '';
    }
}

const setActive = (state, dispatch, activeObject) => {
    dispatch({
        type: 'setAll', payload: {
            active: {
                ...state.active,
                ...activeObject
            }
        }
    })
}

const convertByRates = (state, amount, currency1, currency2) => {
    let result = amount * state.rates[currency1][currency2];
    if (Math.abs(result) < 0.000001) result = 0;
    return result
}
const getStatus = (status) => {
    status = status ? status.toString().toLowerCase() : '';
    switch (status) {
        case 'pending':
            return {
                color: 'warning',
                icon: ''
            }
        case 'confirmed':
            return {
                color: 'success',
                icon: ''
            }
        case 'verified':
            return {
                color: 'success',
                icon: ''
            }
        case 'rejected':
            return {
                color: 'danger',
                icon: ''
            }
        case 'neon':
            return {
                color: 'neon',
                icon: ''
            }
        case 'false':
            return {
                color: 'danger',
                icon: ''
            }
        case 'true':
            return {
                color: 'success',
                icon: ''
            }
        default:
            return {
                color: 'white',
                icon: ''
            }
    }
}

const handleAxiosError = (err) => {
    if (err.response) {
        return err.response.statusText;
    } else {
        return 'Connection error.';
    }
}

const getCryptoColor = (crypto) => {
    let color = '#000'
    switch (crypto) {
        case 'USD':
            color = '#6CDE07'
            break;
        case 'USDT':
            color = '#26A17B'
            break;
        case 'TRX':
            color = '#EF0027'
            break;
        case 'BTC':
            color = '#F7931A'
            break;
        case 'ETH':
            color = '#627EEA'
            break;
        case 'DOGE':
            color = '#C3A634'
            break;
        default:
            break;
    }
    return color
}
export {
    countries,
    getBearer,
    kycDetails,
    logout,
    updateRates,
    getBalanceInFiat,
    updateStateUser,
    changeActiveCurrency,
    getSelectedFiatCharacter,
    setActive,
    getStatus,
    handleAxiosError,
    convertByRates,
    getCryptoColor
}
