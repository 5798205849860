import React, { Fragment, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import { CircularProgress } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    sticky: {
        position: "sticky",
        left: 0,
        zIndex: 9,
        width: '65px',
        boxShadow: "0px 0px 3px #26ddff55",
        // borderRight: "2px solid #ff0",
        // borderRight: "2px solid #ff0",
    },
    row: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    td: {
        color: '#fff',
        padding: '3px 5px'
    },
    container: {
        padding: 0,
        //sticky
        position: "sticky",
        left: 0
    }
}));

function Row(props) {
    const { row, columns, collapsible, openRows, setOpenRows, index } = props;
    const classes = useStyles();

    const toggleOpen = (_id) => {
        setOpenRows({
            ...openRows,
            [_id]: !openRows[_id]
        })
    }

    return (
        <React.Fragment>
            <TableRow className={(collapsible && (index % 2) ? 'bg-black' : '') + ' ' + (collapsible ? classes.row : '')}>
                {collapsible ? <TableCell
                    style={{
                        width: '40px',
                        background: (index % 2) ? '#000' : '#232228',
                    }}>
                    <IconButton className='text-white' size="small" onClick={() => { toggleOpen(row._id); }}>
                        {openRows[row._id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell> : <></>}
                {columns.map((column, i) => <Fragment key={i}>
                    {
                        (!column.collapse &&
                            <TableCell sx={{
                                background: (index % 2) ? '#000' : '#232228',
                                color:'#eee',
                                whiteSpace: 'nowrap',
                                ...column.style,
                            }} className={column.className + ' ' + classes.td + ' ' + (column.id == 'userId' ? classes.sticky : '')} align={column.align}>
                                {column.format && column.format(row[column.id])}
                            </TableCell>
                        )
                    }
                </Fragment>)}
            </TableRow>
            {collapsible ?
                <TableRow>
                    {columns.map((column, i) => <Fragment key={i}>{
                        (column.collapse ?
                            <TableCell
                                style={{ ...column.style, paddingBottom: 0, paddingTop: 0 }}
                                className={column.className + ' ' + classes.td}
                                align={column.align}
                                colSpan={columns.length}>
                                <Collapse in={openRows[row._id]} timeout="auto" unmountOnExit>
                                    <Box margin={1}>
                                        {typeof (row[column.id]) == 'boolean' ?
                                            <span>{column.boolFormat(row[column.id])}</span> :
                                            <span>{column.format && column.format(row[column.id])}</span>}
                                    </Box>
                                </Collapse>
                            </TableCell> : <></>
                        )
                    }</Fragment>)}
                </TableRow> : <></>}
        </React.Fragment>
    );
}


const ResponsiveTable = props => {
    const { rows, columns, collapsible } = props;
    const classes = useStyles();
    const [openRows, setOpenRows] = useState({})
    return (

        <Box sx={{
            display: 'inline-block',
            backgroundColor: '#232228',
            color: '#fff',
            // position: 'absolute',
        }}>
            <MDBRow className='m-0'>
                <MDBCol size='12' className='p-0'>
                    {/* <TestList /> */}
                    {
                        !rows ? <Box className='position-fixed d-flex align-items-center justify-content-center' sx={{ width: '100vw', height: '100vh' }}>
                            <CircularProgress color='info'></CircularProgress>
                        </Box> : <>
                            {!columns ? <>No Column</> : <>
                                {/* <TableContainer className={classes.container} style={{ border: "1px solid yellow" }}>
                                  
                                </TableContainer> */}
                                <Table padding='none' size='small'
                                    //  stickyHeader
                                    sx={{
                                        // display: 'block',
                                        minWidth: '1200px',
                                        maxWidth: '100vw',
                                        tableLayout: 'fixed'
                                    }}
                                    aria-label="simple table"
                                >
                                    <TableHead className={classes.head}>
                                        <TableRow>
                                            {collapsible ? <TableCell
                                                align='center'
                                                style={{ width: '40px' }}
                                                className={'p-1_ ' + classes.head}>
                                            </TableCell> : <></>}
                                            {columns.map((column, i) => <Fragment key={i}>{
                                                (column.collapse ? <></> :
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.labelAlign || 'center'}
                                                        className={'p-1 ' + ' ' + (column.id == 'userId' ? classes.sticky : '')}
                                                        style={column.labelStyle}>
                                                        {column.label}
                                                    </TableCell>
                                                )
                                            }</Fragment>)}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.length ? rows.map((row, i) => (
                                            <Row openRows={openRows} setOpenRows={setOpenRows} key={i} index={i} collapsible={collapsible} row={row} columns={columns} />
                                        )) :
                                            <TableRow>
                                                <TableCell colSpan={columns && columns.length}>
                                                    <div className='py-3 text-center h6 text-neon'>No Data</div>
                                                </TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>

                            </>}
                        </>
                    }
                </MDBCol>
            </MDBRow>
        </Box>
    );
}

export default ResponsiveTable