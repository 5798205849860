import { Box, Typography } from '@mui/material'
import React from 'react'

const getTestUsers = () => { 
    
 }

const CrashTest = () => {
    return (
        <Box>
            <Typography className='text-neon'>iMoon Crash Testing Tool</Typography>
            <Box></Box>
        </Box>
    )
}

export default CrashTest