import moment from 'moment';
import React, { useEffect, useState } from 'react'
import _ from "lodash";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement,
} from 'chart.js';
import { Bar, Line, Chart } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    // plugin
);

const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
        mode: 'index',
        intersect: false
    },
    // animation: {
    //     duration: 0
    // },
    scales: {

        y: {
            grid: {
                color: '#fff3'
            },
            // stacked: true,
        },
        x: {
            grid: {
                color: '#fff3'
            },
            stacked: true
            // ticks: {
            //     callback: function (label, index, labels) {
            //         return label / 1000;
            //     }
            // },
            // scaleLabel: {
            //     display: true,
            //     labelString: '1k = 1000'
            // }
        }
    },
};

const momentFormatByTimeframe = (timeframe) => {
    if (timeframe == 'minute') return 'YYYY MMMM DD';
    if (timeframe == 'hour') return 'YYYY MMMM DD HH:00';
    if (timeframe == 'day') return 'D';
    if (timeframe == 'month') return 'YYYY MMM';
    if (timeframe == 'year') return 'YYYY';
}

const createLabels = (reportData) => {
    let labels = [];
    reportData.forEach((item, i) => {
        const { dateFrom, dateTo, timeframe } = item;
        labels.push(moment(dateFrom).format(momentFormatByTimeframe(timeframe)));
        console.log(item)
    });
    return labels
}


const FinancialReportChart = (props) => {
    const { data } = props;
    const [chartData, setChartData] = useState();

    useEffect(() => {
        if (data && data.report) {
            setChartData({
                labels: createLabels(data.report),
                datasets: [
                    {
                        type: 'bar',
                        label: 'Topop+',
                        lineTension: 0.2,
                        data: data.report.map(r => r.data.topupDeposit.totalAmount),
                        borderColor: '#50a',
                        borderWidth: 1,
                        fill: true,
                        backgroundColor: '#50a',
                        stack: 0
                    },
                    {
                        type: 'bar',
                        label: 'Topop-',
                        lineTension: 0.2,
                        data: data.report.map(r => r.data.topupWithdraw.totalAmount),
                        borderColor: '#50a',
                        borderWidth: 1,
                        fill: true,
                        backgroundColor: '#50a',
                        stack: 0
                    },
                    {
                        type: 'bar',
                        label: 'Deposit',
                        lineTension: 0.2,
                        data: data.report.map(r => r.data.deposit.totalAmount),
                        borderColor: '#060',
                        borderWidth: 1,
                        fill: true,
                        backgroundColor: '#060',
                        stack: 1
                    },
                    {
                        type: 'bar',
                        label: 'Withdraw',
                        lineTension: 0.2,
                        data: data.report.map(r => r.data.withdraw.totalAmount),
                        borderColor: '#04a',
                        borderWidth: 1,
                        fill: true,
                        backgroundColor: '#04a',
                        stack: 1
                    },
                    {
                        type: 'bar',
                        label: 'Bet',
                        lineTension: 0.2,
                        data: data.report.map(r => r.data.bet.totalAmount),
                        borderColor: '#ffeb3b',
                        borderWidth: 1,
                        fill: true,
                        backgroundColor: '#ffeb3b',
                        stack: 2
                    },
                    {
                        type: 'bar',
                        label: 'Won',
                        lineTension: 0.2,
                        data: data.report.map(r => r.data.win.totalAmount),
                        borderColor: '#00f74a',
                        borderWidth: 1,
                        fill: true,
                        backgroundColor: '#00f74a',
                        stack: 3
                    },
                    {
                        type: 'bar',
                        label: 'Cashout',
                        lineTension: 0.2,
                        data: data.report.map(r => r.data.cashout.totalAmount),
                        borderColor: '#ff9940',
                        borderWidth: 1,
                        fill: true,
                        backgroundColor: '#ff9940',
                        stack: 4
                    },
                    // {
                    //     type: 'bar',
                    //     label: 'Balance',
                    //     data: data.report.map(r => r.data.bet.totalAmount > r.data.win.totalAmount ? r.data.bet.totalAmount - r.data.win.totalAmount : 0),
                    //     borderColor: '#fff',
                    //     borderWidth: 1,
                    //     fill: true,
                    //     backgroundColor: '#fff',
                    //     stack: 5
                    // },
                    {
                        type: 'bar',
                        label: 'PL+',
                        data: data.report.map(r => r.data.bet.totalAmount > r.data.win.totalAmount ? r.data.bet.totalAmount - r.data.win.totalAmount : 0),
                        borderColor: '#26ddff',
                        borderWidth: 1,
                        fill: true,
                        backgroundColor: '#26ddff',
                        stack: 5
                    },
                    {
                        type: 'bar',
                        label: 'PL-',
                        data: data.report.map(r => r.data.bet.totalAmount < r.data.win.totalAmount ? r.data.bet.totalAmount - r.data.win.totalAmount : 0),
                        borderColor: '#900',
                        borderWidth: 1,
                        fill: true,
                        backgroundColor: '#900',
                        stack: 5
                    },
                ],
            })
        }
    }, [data])

    return (
        <div className='d-block' style={{
            height: '50vh'
        }}>
            {/* {JSON.stringify(data.report)} */}
            {chartData ? <Chart type='line' height='100%' options={options} data={chartData} /> : <></>}

        </div>
    )
}

export default FinancialReportChart
