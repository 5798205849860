import React, { useState } from 'react'
import { Box, CircularProgress, Collapse } from '@mui/material';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import _ from 'lodash';
ChartJS.register(ArcElement, Tooltip, Legend);

const ThemeChartAndDataCard = (props) => {
    const { title, secondaryTitles, centerChart, dataTitles, data,ready } = props;
    return <MDBCol size='12' md='6' lg='6' xl='4' xxl='4' className='py-2' >
        <MDBRow className='m-0 pb-2 small box-shadow-neon rounded-3 overflow-hidden h-100'>
            <MDBCol style={{ height: '3rem' }} size='12' className='box-shadow-info mb-2 d-flex justify-content-between align-items-center text-neon'>
                <strong className='py-1'>{ready ? title : 'Loading ...'}</strong>
                <div className={`small d-flex text-center align-items-center  ${''}`}>
                    {ready && secondaryTitles.map((st, i) => {
                        const { text, amount, className } = st;
                        return <div key={i} className={`ms-3 ${className}`}>
                            <small className='d-block'>
                                {text}
                            </small>
                            <strong className={`py-1`}>{amount}</strong>
                        </div>
                    })}
                </div>
            </MDBCol>
            <MDBCol size='12' className='p-0'>
                <MDBRow className='m-0 d-flex align-items-center'>
                    <MDBCol size='6' sm='4' md='5' xl='4' className='position-relative small m-auto'>
                        <Box sx={{
                            minHeight: ['200px']
                        }}>
                            <strong className={`position-absolute text-center small`}
                                style={{
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)'
                                }}>
                                <small className='d-block opacity-50 mt-n1'>{ready ? centerChart.title : 'Loading ...'}</small>
                                <div className={` rounded-3 ${centerChart && centerChart.className}`} style={{
                                    padding: '2px 5px 0 5px',
                                }}>
                                    {ready ? centerChart.amount : 0}
                                </div>
                            </strong>
                            {ready ? <Doughnut className='position-absolute' style={{
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)'
                            }} type='doughnut'
                                options={{ plugins: { legend: false }, responsive: true }}
                                data={{
                                    labels: data.map(d => d.chartTitle || d.title),
                                    datasets: [{
                                        data: data.map(d => d.chartAmount),
                                        backgroundColor: data.map(d => d.color),
                                        borderColor: data.map(d => d.color),
                                        borderWidth: 1,
                                    }]
                                }} /> :
                                <Box sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)'
                                }}>
                                    <CircularProgress color='neon' size={150} />
                                </Box>}
                        </Box>
                    </MDBCol>
                    <MDBCol size='12' md='7' xl='8' className='small p-1 ps-md-0'>
                        {ready && dataTitles && <div className='opacity-50 small d-flex justify-content-between'><span>{dataTitles[0]}</span><span>{dataTitles[1]}</span></div>}
                        {ready && data.map((d, i) => <ChartCustomLegend key={i} {...d} />)}
                    </MDBCol>
                </MDBRow>
            </MDBCol>
        </MDBRow>
    </MDBCol>
}


const ChartCustomLegend = (props) => {
    const { color, title, amount1, amount2, collapse } = props;
    const [values, setValues] = useState({
        open: false
    })
    return <>
        <div style={{ color }} className='d-flex justify-content-between align-items-center position-relative'>
            <span onClick={() => setValues({ ...values, open: !values.open })}>{typeof (title) == 'string' && <MDBIcon icon='square' className='me-2'></MDBIcon>} {title}</span>
            <span className='px-1' style={{ flexGrow: 1 }}>
                <hr className='m-0' />
            </span>
            <span>
                {amount1}
                {amount2 !== undefined &&
                    <small className='ms-1 opacity-70'>
                        ({amount2})
                    </small>}
            </span>
        </div>
        {collapse &&
            <Collapse in={values.open} timeout="auto" unmountOnExit>
                {collapse}
            </Collapse>
        }
    </>
}

export default ThemeChartAndDataCard