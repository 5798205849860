import React from 'react';
import ThemeSelect from '../layout/components/ThemeSelect';

const PageStatus = (props) => {
    const statuses = ['pending', 'published'];
    return <ThemeSelect
        label='Publish Status'
        options={statuses.map((status, i) => <option key={i} value={status}>{<span className={`text- p-1 text-capitalize`}>{status}</span>}</option>)}
        {...props}
    />;
}

export default PageStatus