import axios from "axios";
import numeral from "numeral";
import { getBearer } from "./publicHelper";

const gameProviders = ['Evolution', 'EveryMatrix', 'Pragmatic', 'Netent', 'RedTiger'];
const currencyList = ['USD', 'USDT', 'USDTTRX', 'USDTETH', 'BTC', 'ETH', 'TRX', 'DOGE'];


const dayLong = 24 * 60 * 60 * 1000;
const weekLong = dayLong * 7;
const beginTime = 1577836800000;

const lostTierPercentDaily = [
    [0, 0.2, 0.4, 0.6, 0.8, 1],
    [0, 0.4, 0.8, 1.2, 1.6, 2],
    [0, 0.6, 1.2, 1.8, 2.4, 3],
    [0, 1, 2, 3, 4, 5],
    [0, 1.4, 2.8, 4.2, 5.6, 7],
    [0, 2, 4, 6, 8, 10],
    [0, 3, 6, 9, 12, 15]
];

const lostTierPercentWeekly = [
    [0, 0.8, 1.6, 2.4, 3.2, 4],
    [0, 1.2, 2.4, 3.6, 4.8, 6],
    [0, 1.6, 3.2, 4.8, 6.4, 8],
    [0, 2, 4, 6, 8, 10],
    [0, 2.8, 5.6, 8.4, 11.2, 14],
    [0, 3.2, 6.4, 9.6, 12.8, 16],
    [0, 4, 8, 12, 16, 20]
];

const getCashbackPercentByLostTier = (lost, tier) => {
    const result = {
        daily: 0,
        dayly: 0,
        weekly: 0
    };
    if (lost < 1000) return result;
    let index = 0;
    if (lost < 5000) {
        index = 0
    } else if (lost < 10000) {
        index = 1;
    } else if (lost < 25000) {
        index = 2;
    } else if (lost < 50000) {
        index = 3;
    } else if (lost < 100000) {
        index = 4;
    } else if (lost < 500000) {
        index = 5;
    } else {
        index = 6;
    };
    return {
        daily: lostTierPercentDaily[index][tier],
        dayly: lostTierPercentDaily[index][tier],
        weekly: lostTierPercentWeekly[index][tier],
    };

}

const getColorByTitle = (title) => {
    let result = 'white';
    switch (title.toLowerCase()) {
        case 'deposit':
            result = 'success';
            break;
        case 'withdraw':
            result = 'danger';
            break;
        case 'topupbonus':
            result = 'neon';
            break;
        case 'topup daily cashback':
            result = 'neon';
            break;
        case 'topup weekly cashback':
            result = 'neon';
            break;
        case 'topupdiscount':
            result = 'neon';
            break;
        case 'topupdesposit':
            result = 'teal';
            break;
        case 'topupwithdraw':
            result = 'orange';
            break;
        case 'bet':
            result = 'danger';
            break;
        case 'win':
            result = 'success';
            break;
        case 'open':
            result = 'yellow';
            break;
        case 'cashback':
            result = 'warning';
            break;
        case 'topup discount':
            result = 'neon';
            break;
        case 'topup deposit':
            result = 'success';
            break;
        case 'topup withdraw':
            result = 'danger';
            break;
        case 'topup bonus':
            result = 'teal';
            break;
        case 'current balance':
            result = 'yellow';
            break;
        case 'win - bet':
            result = 'neon';
            break;
        case 'bet - win - open':
            result = 'neon';
            break;
        default:
            break;
    }
    return result;
}

const calcTotalfromCurrenciesObject = (obj) => {
    // const currencyList = ['USD', 'USDT', 'USDTTRX', 'USDTETH', 'BTC', 'ETH', 'TRX', 'DOGE'];
    let result = {
        totalAmount: 0,
        totalAmountInUSD: 0,
        count: 0
    };
    if (!obj) return result;
    currencyList.forEach(currency => {
        if (obj[currency]) {
            const { totalAmount, totalAmountInUSD, count } = obj[currency];
            result.totalAmount = result.totalAmount + totalAmount;
            result.totalAmountInUSD = result.totalAmountInUSD + totalAmountInUSD;
            result.count = result.count + count;
        }
    });
    return result
}

const getReport = (state, setData, filter) => {
    const { from, to, userId } = filter;
    setData({
        waiting: true,
    })

    let URL1 = `${state.active.host}/superadmin/users/report`;
    let URL2 = `${state.active.host}/admin/users`;

    const promise1 = axios.post(URL1,
        filter,
        {
            headers: { 'x-auth-token': getBearer() }
        });
    const promise2 = axios.post(URL2,
        {
            find: { userId }
        }, {
        headers: { 'x-auth-token': getBearer() }
    });
    const promise3 = axios.post(process.env.REACT_APP_API_HOST + '/admin/users/transactions',
        {
            find: {
                userId,
                type: 'bet',
                isValid: true,
                status: 'verified',
                $and: [
                    {
                        createdAt: {
                            $gt: new Date(from).toISOString()
                        }
                    },
                    {
                        createdAt: {
                            $lt: new Date(to).toISOString()
                        }
                    }
                ]
            },
            limit: 1,
            sort: {
                createdAt: -1
            },
            mode: {
                sport: false,
                relatedSport: false,
                evo: false,
                relatedEvo: false,
                em: false,
                relatedEm: false,
                fullHistory: true
            }
        },
        {
            headers: { 'x-auth-token': getBearer() }
        });
    const promise4 = axios.post(process.env.REACT_APP_API_HOST + '/admin/users/transactions',
        {
            find: {
                userId,
                type: 'bet',
                isValid: true,
                status: 'verified',
                $and: [
                    {
                        createdAt: {
                            $gt: new Date(from).toISOString()
                        }
                    },
                    {
                        createdAt: {
                            $lt: new Date(to).toISOString()
                        }
                    }
                ]
            },
            limit: 1,
            sort: {
                updatedAt: 1
            },
            mode: {
                sport: false,
                relatedSport: false,
                evo: false,
                relatedEvo: false,
                em: false,
                relatedEm: false,
                fullHistory: true
            }
        },
        {
            headers: { 'x-auth-token': getBearer() }
        });
    Promise.all([promise1, promise2, promise3, promise4]).then(function (values) {
        if (values[0].data.success && values[1].data.success && values[2].data.success && values[3].data.success) {
            const result = {
                ...values[0].data.result,
                currentBalance: values[1].data.result.users[0].wallet.inUSD.balance,
                // balanceBefore: values[2].data.result.transactions[0].meta.history[0].walletBefore.inUSD.balance,
                // balanceAfter: values[3].data.result.transactions[0].meta.history[values[3].data.result.transactions[0].meta.history.length - 1].walletAfter.inUSD.balance,
            }
            setData(result)
        } else {
            setData({
                waiting: false,
            })
        }
    });
}

const getReportGraph = (setData, filter) => {
    // step: hour, day, week, month, year

    const { from, to, step, userId } = filter;
    axios.post(process.env.REACT_APP_API_HOST + '/superadmin/transactions/graph',
        filter,
        {
            headers: { 'x-auth-token': getBearer() }
        }).then(apiRes => {
            apiRes = apiRes.data;
            if (apiRes.success) {
                setData(apiRes.result.report)
            }
        })
}

const getDurationName = (from, to) => {
    let result = '';
    if ((from + dayLong) == to) result = 'daily';
    if ((from + weekLong) == to) result = 'weekly';
    return result
}

const getTimeStamps = (mode) => {
    const today = new Date();
    const todayStart = today.setUTCHours(0, 0, 0, 0,);
    const todayEnd = todayStart + dayLong;
    // Monday Start
    const previousMonday = new Date();
    previousMonday.setDate(today.getDate() - ((today.getDay() + 6) % 7));
    previousMonday.setUTCHours(0, 0, 0, 0,);
    const previousMondayStart = previousMonday.getTime();
    // Month Start
    const month = new Date();
    month.setDate(1);
    month.setUTCHours(0, 0, 0, 0);
    const monthStart = month.getTime();
    month.setMonth(month.getMonth() + 1);
    const monthEnd = month.getTime();

    if (mode) {
        let from = 0, to = 0;
        switch (mode) {
            case 'day':
                from = todayStart;
                to = todayStart + dayLong;
                break;
            case 'week':
                from = previousMondayStart;
                to = previousMondayStart + weekLong;
                break;
            case 'month':
                from = monthStart;
                to = monthEnd;
                break;
            case 'all':
                from = beginTime;
                to = monthEnd;
                break;
            default:
                from = todayStart;
                to = todayStart + dayLong;
                break;
        }
        return {
            from, to
        }
    } else {
        return {
            todayStart,
            previousMondayStart,
            monthStart,
            monthEnd,
        }
    }
}

const getPlayerReportData = (setData, filter) => {
    const { from, to, userId, fake } = filter;
    setData({})
    axios.post(process.env.REACT_APP_API_HOST + '/admin/report/playerReport',
        filter,
        {
            headers: { 'x-auth-token': getBearer() }
        }).then(apiRes => {
            apiRes = apiRes.data;
            if (apiRes.success) {
                setData(apiRes.result);
                {
                    //...apiRes.result,
                    // startTime: new Date('2022/08/08Z00:00:00').getTime(),
                    // startBalance: 10000,
                    //  balance: 0,
                    // openBetsAmount: 0,
                    // totalDepositAmount: 2000,
                    // totalTopupDepositAmount: 0,
                    //  totalWithdrawAmount: 100000,
                    // totalPendingWithdrawAmount: 0,
                    // lastDepositAmount: 0,
                }
            }
        })
}

const addCustomData = (reportData) => {
    const {
        userId,
        tier,
        wager,
        from, // start point of requested report
        to, // end point of requested report
        startTime, // clarify
        startTimeISO, // clarify
        endTime, // clarify
        endTimeISO, // clarify
        // ***************** Balance Data
        balance, // clarify (endBalance)
        startBalance,
        currentBalance,
        // ***************** Deposit Data
        totalDepositAmount,
        lastDepositAmount,
        // ***************** Topup Data
        lastTopupDailyDiscountAmount,
        lastTopupDepositAmount,
        lastTopupDiscountAmount,
        lastTopupWeeklyDiscountAmount,
        // ***************** Withdraw Data
        totalWithdrawAmount,
        totalPendingWithdrawAmount,
        // ***************** Bets Data
        openBetsAmount,
        // ***************** Transactions Data
        transactions, // last 100 transactions
        transactionsCount, // count of all transactions within duration
    } = reportData;
    const result = {
        // startWeek,
        // startTimeIsStartWeek,
    }
    return result
}

export {
    gameProviders,
    currencyList,
    dayLong,
    weekLong,
    beginTime,
    getCashbackPercentByLostTier,
    getColorByTitle,
    calcTotalfromCurrenciesObject,
    getReport,
    getReportGraph,
    getTimeStamps,
    getDurationName,
    getPlayerReportData
}