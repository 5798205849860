import React, { useContext, useEffect, useState } from 'react';
import { MDBCard, MDBCardHeader, MDBCol, MDBIcon, MDBInput, MDBRow } from 'mdb-react-ui-kit';
import axios from 'axios';
import { Box, CardHeader, Collapse, Modal, Switch } from '@mui/material';
import { Context } from '../../context/store';
import ReactJson from 'react-json-view';
import ThemeButton from '../../layout/components/ThemeButton';
import ThemeInput from '../../layout/components/ThemeInput';
import CopyToClipboard from 'react-copy-to-clipboard';
import _ from 'lodash';
import { getBearer } from '../../helpers/publicHelper';
import { useSnackbar } from 'notistack';

const SettingsSystem = () => {
    const [state, dispatch] = useContext(Context);
    const { enqueueSnackbar } = useSnackbar();
    const [settingsData, setSettingsData] = useState([]);
    const [selectedEnv, setSelectedEnv] = useState('stage');
    useEffect(() => {
        getServerSettings(state, setSettingsData)
    }, []);


    const getServerSettings = (state, setSettingsData) => {
        axios.post(state.active.host + '/superadmin/settings', {}, {
            headers: { 'x-auth-token': getBearer() }
        })
            .then(res => {
                res = res.data;
                if (res.success) {
                    setSettingsData(res.result);
                } else {
                    enqueueSnackbar(res.result, { variant: 'error' })
                }
            })
            .catch((err) => {
                enqueueSnackbar(`${err.message}`, { variant: 'error' })
            })
    }

    const updateGlobalSettingsFromDB = () => {
        axios.get(state.active.host + '/superadmin/settings/update', {
            headers: { 'x-auth-token': getBearer() }
        })
            .then(res => {
                res = res.data;
                if (res.success) {
                    enqueueSnackbar(res.message, { variant: 'success' });
                } else {
                    enqueueSnackbar(res.message, { variant: 'error' })
                }
            })
            .catch((err) => {
                console.log('Error on getServerSettings2', err)
                enqueueSnackbar('Error', { variant: 'error' })
            })
    }


    return <MDBRow className='mx-0'>
        <MDBCol size='12' className='py-2 px-0'>
            <ThemeButton onClick={() => setSelectedEnv('stage')} className='me-2 px-3 py-2' color='warning' variant={selectedEnv == 'stage' ? '' : 'outlined'}>Stage</ThemeButton>
            <ThemeButton onClick={() => setSelectedEnv('prod')} className='me-2 px-3 py-2' color='success' variant={selectedEnv == 'prod' ? '' : 'outlined'}>Production</ThemeButton>
            <ThemeButton onClick={updateGlobalSettingsFromDB} className='me-2 px-3 py-2' color='neon' variant='outlined'>Update Global Settings from DB</ThemeButton>
        </MDBCol>
        {
            settingsData.map((settingItemData, i) => {
                // if (settingItemData.env !== selectedEnv) return
                return <SettingsItem key={i} getServerSettings={getServerSettings} setSettingsData={setSettingsData} {...settingItemData}></SettingsItem>
            })
        }
    </MDBRow>
}

const SettingsItem = (props) => {
    const { name, env, value, getServerSettings, setSettingsData, } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [state, dispatch] = useContext(Context);
    const [open, setOpen] = useState(false);
    const [showJson, setShowJson] = useState(false);
    const [jsonValue, setJsonValue] = useState(JSON.stringify(value, null, "\t"));
    const [hasError, setHasError] = useState(false)
    const validateJsonStr = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        setHasError(!validateJsonStr(jsonValue));
    }, [jsonValue])

    const saveChanges = (newValue) => {
        axios.post(state.active.host + '/superadmin/settings/setsetting', {
            name, env, value: newValue
        }, {
            headers: { 'x-auth-token': getBearer() }
        })
            .then(res => {
                res = res.data;
                enqueueSnackbar(res.message, {
                    variant: res.success ? 'success' : 'error',
                    autoHideDuration: 2500
                });
                getServerSettings(state, setSettingsData)
            })
            .catch(err => {
                enqueueSnackbar('Error!', { variant: 'error', autoHideDuration: 2500 })
            })
    }

    return <MDBCol size='12' className={`mt-2 mb-3 overflow-hidden rounded-6 box-shadow-${env == 'prod' ? 'success' : 'warning'} p-0`}>
        <MDBCard className='bg-black'>
            <MDBCardHeader className={`px-2 text-${env == 'prod' ? 'success' : 'warning'}`}>
                <MDBIcon onClick={() => setOpen(!open)} className='cursor-pointer me-2' style={{ left: 0, top: '0.7rem' }} icon={`chevron-circle-${open ? 'down' : 'right'}`}></MDBIcon>
                {name}
                <small onClick={() => setShowJson(!showJson)} className={`cursor-pointer text-uppercase border rounded-pill fw-bold px-3 py-1 ms-2 text-${env == 'stage' ? 'warning border-warning' : 'success border-success'}`}>{`{ }`}</small>
            </MDBCardHeader>
            <Collapse in={showJson} timeout="auto" unmountOnExit>
                <MDBCardHeader style={{ backgroundColor: '#121212' }}>
                    <ThemeInput inputClassName={`text-${hasError ? 'danger' : 'white'} border border-${hasError ? 'danger' : 'success'} box-shadow-${hasError ? 'danger' : 'success'} noScrollbar`} onChange={e => setJsonValue(e.target.value)} rows='15' multiline value={jsonValue}></ThemeInput>
                    <div>
                        <ThemeButton onClick={() => { if (hasError) { enqueueSnackbar('Invalid json.', { variant: 'error', autoHideDuration: 2500 }) } else { saveChanges(JSON.parse(jsonValue)) } }} className='px-3 py-2 ms-4 mt-3' color='success' variant='outlined'>Save Changes</ThemeButton>
                    </div>
                </MDBCardHeader>
            </Collapse>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <MDBCardHeader style={{ backgroundColor: '#121212' }}>
                    <JsonTreeView saveChanges={saveChanges} {...props}></JsonTreeView>
                </MDBCardHeader>
            </Collapse>
        </MDBCard>
    </MDBCol>
}
const JsonTreeView = (props) => {
    const { name, env, value, saveChanges } = props;
    const [editedJson, setEditedJson] = useState(_.cloneDeep(value));

    return <div>
        {Object.keys(value).map((itemKey, i) => {
            const val = value[itemKey];
            const path = itemKey;
            // Render root items.
            return ItemRender(itemKey, val, path, editedJson, setEditedJson)
        })}
        {JSON.stringify(editedJson) !== JSON.stringify(value) ?
            <div>
                <ThemeButton onClick={() => saveChanges(editedJson)} className='px-3 py-2 ms-4 mt-3' color='success' variant='outlined'>Save Changes</ThemeButton>
            </div>
            : <span></span>}
    </div>
}

const ItemRender = (itemTitle, itemValue, path, editedJson, setEditedJson) => {
    const [open, setOpen] = useState(false)
    if (typeof (itemValue) == 'object') {
        const isArray = Array.isArray(itemValue);
        return <div className='my-2 ps-4 position-relative'>
            <MDBIcon onClick={() => setOpen(!open)} className='position-absolute cursor-pointer' style={{ left: 0, top: '0.7rem' }} icon={`chevron-circle-${open ? 'down' : 'right'}`}></MDBIcon>
            <div className='bg-black box-hover-shadow-neon ps-3 py-2 rounded-5'
                style={{ border: '1px solid #26ddff44' }}>
                <strong className='text-white me-1'>
                    {itemTitle}
                </strong>
                <span className='opacity-40'>
                    {isArray ? `[${itemValue.length}]` : `{ ... }`}
                </span>
            </div>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <div className={`ps-4`}>
                    {
                        Object.keys(itemValue).map((title, i) => {
                            const value = itemValue[title];
                            return ItemRender(title, value, isArray ? `${path}[${title}]` : `${path}.${title}`, editedJson, setEditedJson)
                        })
                    }
                </div>
            </Collapse>
        </div>
    } else {
        return <JsonTreeViewItam path={path} itemTitle={itemTitle} itemValue={itemValue} editedJson={editedJson} setEditedJson={setEditedJson}></JsonTreeViewItam>
    }
}

const JsonTreeViewItam = (props) => {
    const { itemTitle, itemValue, path, editedJson, setEditedJson } = props;
    const { enqueueSnackbar } = useSnackbar();

    const [values, setValues] = useState({
        value: itemValue,
        type: 'string',
        editable: false
    })
    const setChanges = (params) => {
        const { value, type, editedJson } = params;
        let newEditedJson = _.cloneDeep(editedJson);
        const x = _.set(newEditedJson, path, value);
        setEditedJson(x);
        setValues({ ...values, editable: false });
    }

    const type = typeof (itemValue);
    const color = type == 'string' ? 'info' :
        type == 'number' ? 'success' :
            type == 'boolean' ? 'white' : 'danger';
    return <div className='my-2 ms-4'>
        <div style={{ border: '1px solid #f4b74044' }} className={`${values.value !== itemValue ? 'border border-neon box-shadow-neon' : ' box-hover-shadow-warning'} bg-black ps-3 py-2 rounded-5`}>
            <div className='d-flex justify-content-between'>
                <div className='d-flex'>
                    <strong className='text-white me-3'>
                        {itemTitle}
                    </strong>
                    <div>
                        <Collapse in={values.editable} timeout="auto" unmountOnExit>
                            <div className='d-flex'>
                                <span className={`text-${color}`}>
                                    <ThemeInput inputStyle={{ width: '500px' }}
                                        value={values.value}
                                        onChange={e =>
                                            setValues({
                                                ...values,
                                                value: e.target.value
                                            })
                                        }></ThemeInput>
                                </span>
                                <div>
                                    <ThemeButton disabled={itemValue == values.value} onClick={() => setValues({ ...values, value: itemValue })} className='m-2 px-2' size='small' color='warning' variant='outlined'>Undo</ThemeButton>
                                    <ThemeButton onClick={() => setChanges({ ...values, editedJson })} className='m-2 px-2' size='small' color='success' variant='outlined'>Set</ThemeButton>
                                </div>
                            </div>
                        </Collapse>
                        <Collapse in={!values.editable} timeout="auto" unmountOnExit>
                            <div className='d-flex'>
                                <span className={`text-${itemValue == values.value ? color : 'neon text-shadow-neon'}`}>
                                    {type == 'string' && <span>"{itemValue && values.value}"</span>}
                                    {type == 'number' && <span>{values.value}</span>}
                                    {type == 'boolean' && <span
                                        onClick={() => {
                                            setChanges({
                                                ...values,
                                                value: !values.value,
                                                editedJson
                                            })
                                            setValues({
                                                ...values,
                                                value: !values.value
                                            });

                                        }}
                                        className={`cursor-pointer text-white bg-${values.value ? 'success' : 'danger'} ${values.value !== values.value ? ' opacity-30' : ''} rounded-pill px-3 small py-1`}>{values.value.toString()}</span>}
                                    {itemValue !== values.value && <ThemeButton className='ms-3' color='neon' size='small' >Edited</ThemeButton>}
                                    {
                                        type !== 'boolean' ?
                                            <>
                                                <CopyToClipboard
                                                    text={itemValue}
                                                    onCopy={() => { enqueueSnackbar('Copied.', { variant: 'success', autoHideDuration: 2500 }) }}>
                                                    <ThemeButton size='small' variant='outlined' color='warning' className='ms-3'>
                                                        <MDBIcon className='pe-1 small cursor-pointer' icon='copy'></MDBIcon>
                                                        Copy
                                                    </ThemeButton>
                                                </CopyToClipboard>
                                            </> : <>
                                                {/* <ThemeButton onClick={() => setChanges({ ...values, editedJson })} className='m-2 px-2' size='small' color='success' variant='outlined'>Set</ThemeButton> */}
                                            </>
                                    }
                                </span>
                            </div>
                        </Collapse>
                    </div>
                </div>
                <div className='small'>
                    <MDBIcon className='px-2 text-success cursor-pointer' onClick={() => setValues({ ...values, editable: !values.editable })} icon='edit'></MDBIcon>
                    <MDBIcon className='opacity-40 px-2 text-danger cursor-pointer' onClick={() => setValues({ ...values, editable: !values.editable })} icon='times'></MDBIcon>
                </div>
            </div>
            {/* <span className={`text-success px-4`}>
                {_.get(editedJson, path)}
            </span> */}


        </div>
    </div >
}




export default SettingsSystem
