import { Box, Grid, LinearProgress, Modal, Typography } from '@mui/material';
import axios from 'axios';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react'
import $ from 'jquery';
import ReactJson from 'react-json-view';
import ThemeFormatCurrency from '../../../../layout/components/ThemeFormatCurrency';
import UserQuickViewButton from '../../../Users/UserQuickViewButton';

const BaccaratDetails = (props) => {
    const { uuid, timestamp, data } = props.gameDetails;
    if (!data) return <Box>No Data</Box>
    const { table, dealer, participants, result, wager, payout } = data;
    const { banker, player, outcome } = result;
    const participant = participants[0];
    const { playerId, screenName, playerGameId, sessionId, casinoSessionId, currency, bets, configOverlays, subType, playMode, sideBetPlayerPair, sideBetBankerPair, chanel, os, device } = participant;

    const status = payout == 0 ? 'LOST' : (payout < wager) ? 'PARTIAL CASHBACK' : (payout == wager) ? 'CASHBACK' : 'WON';
    const statusColor = payout == 0 ? 'danger' : (payout < wager) ? 'warning' : (payout == wager) ? 'info' : 'success';
    const finalStatus = (payout < wager) ? 'LOST' : (payout == wager) ? 'CASHBACK' : 'WON';
    const finalStatusColor = (payout < wager) ? 'danger' : (payout == wager) ? 'info' : 'success';
    return <Box>
        <Box className='small accordion-headertext-center px-2 pb-2 fw-bold border-bottom border-neon d-flex justify-content-between d-flex align-items-center'>
            <span className='text-neon'>
                {table.name}
                <span className='d-block text-warning' style={{ fontSize: '0.6rem' }}>
                    {subType}
                </span>
            </span>

            <span className={`${''} text-neon small border border-neon rounded-4 px-3 py-1`}>
                Participants: {participants.length}
            </span>
        </Box>
        <Box className='text-center text-success pt-1'>
            <Typography variant='subtitle2'>
                Dealer: {dealer.name}
            </Typography>
        </Box>
        <Grid container justifyContent='center' className='py-2'>
            <Grid item className={`border border-${finalStatusColor} text-center px-3 py-1 rounded-3`}>
                <small>Player <UserQuickViewButton userId={playerId} />
                    <strong className={`px-1 text-${finalStatusColor}`}>{finalStatus}</strong>
                </small>
                <strong className={`text-${finalStatusColor}`}>
                    <ThemeFormatCurrency currency={currency} text={Math.abs(payout - wager)} />
                </strong>
            </Grid>
        </Grid>
        <Box className='d-flex align-items-center justify-content-evenly py-3'>
            <Box className={`text-center rounded-6 p-2 border box-shadow-${outcome == 'Player' ? 'success border-success' : 'white border-black opacity-60'}`}>
                <div>Player</div>
                <Box className='d-flex'>
                    {player.cards.map((card, i) => <CasinoCard key={i} value={card} />)}
                </Box>
            </Box>
            {outcome == 'Tie' ? <Box className={`text-center text-neon box-shadow-neon rounded-5 px-3 py-2`}>Tie</Box>
                :
                <Box sx={{ fontSize: '2rem' }}>
                    <span className={`${player.score > banker.score ? 'text-success' : 'opacity-50'}`}>{player.score}</span>
                    <span className='px-2'>:</span>
                    <span className={`${player.score < banker.score ? 'text-success' : 'opacity-50'}`}>{banker.score}</span>
                </Box>
            }
            <Box className={`text-center rounded-6 p-2 border box-shadow-${outcome == 'Banker' ? 'success border-success' : 'white border-black opacity-60'}`}>
                <div>Banker</div>
                <Box className='d-flex'>
                    {banker.cards.map((card, i) => <CasinoCard key={i} value={card} />)}
                </Box>
            </Box>
        </Box>
        <Box className='small box-shadow-neon'>
            <Grid container sx={{ bgcolor: '#222' }} className={`px-2 py-2 px-md-1 text-end  justify-content-between   opacity-60`}>
                <Grid item sm={3} className='text-start'>Bet Type</Grid>
                <Grid item sm={3}>Wager</Grid>
                <Grid item sm={3}>Payout</Grid>
                <Grid item sm={3}>Result</Grid>
            </Grid>
            {bets.map((bet, i) => <BaccaratDetailsBet key={i} bet={bet} participant={participant} />)}
            <Grid container sx={{ bgcolor: '#222' }} className={`px-2 py-2 px-md-1 text-end text-${statusColor} justify-content-between  text-shadow-${statusColor}`}>
                <Grid item sm={3} className='text-start text-white'>Total</Grid>
                <Grid item sm={3}><ThemeFormatCurrency currency={currency} text={wager} /></Grid>
                <Grid item sm={3}><ThemeFormatCurrency currency={currency} text={payout} /> </Grid>
                <Grid item sm={3} className='text-end'>{status}</Grid>
            </Grid>
        </Box>
    </Box>
}

const BaccaratDetailsBet = (props) => {
    const { bet, participant } = props;
    const { code, stake, payout, placedOn, description, transactionId } = bet;
    const { screenName, playerGameId, sessionId, casinoSessionId, currency, bets, configOverlays, subType, playMode, sideBetPlayerPair, sideBetBankerPair, chanel, os, device } = participant;
    const status = payout == 0 ? 'LOST' : (payout < stake) ? 'PARTIAL CASHBACK' : (payout == stake) ? 'CASHBACK' : 'WON';
    const statusColor = payout == 0 ? 'danger' : (payout < stake) ? 'warning' : (payout == stake) ? 'info' : 'success';
    return <Grid sx={{ borderBottom: '1px solid #333', fontSize: '0.7rem' }} container className={`py-2 px-2 px-md-1 text-end justify-content-between text-${statusColor}`}>
        <Grid item sm={3} className='text-start'>{description}</Grid>
        <Grid item sm={3}><ThemeFormatCurrency currency={currency} text={stake} /></Grid>
        <Grid item sm={3}><ThemeFormatCurrency currency={currency} text={payout} /> </Grid>
        <Grid item sm={3} className='text-end'>{status}</Grid>
    </Grid>
}

const CasinoCard = (props) => {
    const { value } = props;
    const amount = value.split('')[0];
    const suit = value.split('')[1];
    return <Box
        className='m-1 py-0 d-flex-block justify-content-center align-items-center flex-column bg-white text-center'
        sx={{
            width: '2rem',
            height: '3rem',
            lineHeight: '1.5rem',
            fontSize: '1.6rem',
            color: suit == 'D' || suit == 'H' ? '#f00' : '#000',
            bgcolor: 'white',
            borderRadius: '0.2rem'
        }}>
        <small className='d-block text-center'>
            {amount == 'T' ? 10 : amount}
        </small>
        {suit == 'D' && <span>♦</span>}
        {suit == 'H' && <span>♥</span>}
        {suit == 'C' && <span>♣</span>}
        {suit == 'S' && <span>♠</span>}
    </Box>
}


export default BaccaratDetails

