import React, { useContext, useEffect, useState } from 'react'
import { Avatar, Collapse, List, ListItem, ListItemText, Modal, useIsFocusVisible } from '@mui/material'
import { MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit'
import moment from 'moment'
import _ from 'lodash'
import axios from 'axios'
import { makeStyles } from '@mui/styles'
import ThemeButton from '../../../layout/components/ThemeButton'
import ThemeInput from '../../../layout/components/ThemeInput'
import ThemePhoneInput from '../../../layout/components/ThemePhoneInput/ThemePhoneInput'
import UserKYC from './UserKYC'
import { getBearer } from '../../../helpers/publicHelper'
import { Context } from '../../../context/store'
import { useSnackbar } from 'notistack'
import { Box } from '@mui/system'

function getModalStyle() {
    return {
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 500,
        maxWidth: '90%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: 0,
    },
}));

const updateProfile = (state, values, enqueueSnackbar) => {
    var postData = _.pick(values, ['_id', 'username', 'firstName', 'firstNameVerified', 'lastName', 'lastNameVerified', 'nickName']);

    axios.post(state.active.host + '/admin/users/profile/setProfile', postData, {
        headers: { 'x-auth-token': getBearer() }
    })
        .then(res => {
            res = res.data;
            if (res.success) {
                enqueueSnackbar(res.message, { variant: 'success' })
            } else {
                enqueueSnackbar(res.message, { variant: 'error' })
            }
        })
        .catch((err) => {
            if (err.response) {
                enqueueSnackbar(err.response.data, { variant: 'error' })
            } else {
                enqueueSnackbar('Error!', { variant: 'error' })
            }
        })
}

const UserProfile = (props) => {
    const { user } = props;
    const classes = useStyles();
    const [state, dispatch] = useContext(Context);
    const { enqueueSnackbar } = useSnackbar();

    const [modalStyle] = useState(getModalStyle);
    const [values, setValues] = useState({
        _id: user._id,
        userId: user.userId,
        username: user.username,
        nickName: user.profile.nickName,
        firstName: user.profile.firstName,
        firstNameVerified: user.profile.firstNameVerified,
        lastName: user.profile.lastName,
        lastNameVerified: user.profile.lastNameVerified,
        fullName: user.profile.fullName,
        avatar: user.profile.avatar || '',
        defaultMobile: _.has(user, 'kyc.mobiles.default') ? user.kyc.mobiles.default : {},
        defaultEmail: _.has(user, 'kyc.emails.default') ? user.kyc.emails.default : {},
        // email: _.has(user, 'kyc.emails.default.address') ? user.kyc.emails.default.address : '',
        telegramUsername: _.has(user, 'kyc.telegram.profile.username') ? user.kyc.telegram.profile.username : '',
        address: _.has(user, 'kyc.address') ? user.kyc.address : '',
        editProfile: false
    });

    const handleChange = (e) => {
        setValues({ ...values, ...e })
    }

    const [modalImage, setModalImage] = useState({
        src: '',
        title: '',
        open: false
    })
    const handleClose = () => {
        setModalImage({ ...modalImage, open: false })
    }
    const openModalImage = (src) => {
        setModalImage({ ...modalImage, src: src, open: true })
    }

    return (
        <MDBRow className='bg-black m-0'>
            <MDBCol size='12' md='4' xl='2' className='pt-1'>
                <List dense className='p-0 border-dark' sx={{
                    height: '3.7rem'
                }}>
                    <ListItem className='p-0'>
                        <Avatar onClick={() => openModalImage(values.avatar)} className='cursor-pointer box-shadow-neon ms-1 me-2' src={values.avatar} alt='me' />
                        <ListItemText
                            primary={
                                <div className='d-flex justify-content-between'>
                                    <small style={{ lineHeight: '1rem' }} className='text-capitalize text-gray'>UserId: <span className='text-white fw-bold'>{values.userId}</span></small>
                                    {!values.editProfile ?
                                        <MDBIcon onClick={() => setValues({ ...values, editProfile: true })} className='text-warning cursor-pointer' icon='edit'></MDBIcon>
                                        : <span>
                                            <ThemeButton className='mt-n1 px-2' style={{ minWidth: 'auto' }} variant='outlined' onClick={() => { updateProfile(state, values, enqueueSnackbar); setValues({ ...values, editProfile: false }) }} color='success' size='small'>Save</ThemeButton>
                                            <ThemeButton className='ms-1 mt-n1 px-0' style={{ minWidth: 'auto' }} variant='outlined' onClick={() => setValues({ ...values, editProfile: false })} color='danger' size='small'>Cancel</ThemeButton>
                                        </span>
                                    }
                                </div>
                            }
                            secondary={<small style={{ lineHeight: '1rem' }} className='text-capitalize text-gray'>
                                {moment(user.createdAt).format('YYYY/MM/DD  HH:mm')}
                                <span className='d-inline-block ps-2 text-white'>{moment(user.createdAt).fromNow()}</span>
                            </small>} />
                    </ListItem>
                </List>
                <Box sx={{ '& input': { color: values.editProfile ? '#fff' : '#999 !important' } }}>
                    <ThemeInput disabled={!values.editProfile} className='mb-3' inputClassName={values.editProfile ? 'border border-white' : ''} label='Username' value={values.username} onChange={e => handleChange({ username: e.target.value })}></ThemeInput>
                    <ThemeInput disabled={!values.editProfile} className='mb-3' inputClassName={values.editProfile ? 'border border-white' : ''} label='NickName' value={values.nickName} onChange={e => handleChange({ nickName: e.target.value })}></ThemeInput>
                    <Box className='d-flex align-items-center mb-3'>
                        <ThemeInput disabled={!values.editProfile} inputClassName={values.editProfile ? 'border border-white' : ''} label='FirstName' value={values.firstName} onChange={e => handleChange({ firstName: e.target.value })}></ThemeInput>
                        <div className='ps-1'>
                            <MDBIcon onClick={() => { if (values.editProfile) setValues({ ...values, firstNameVerified: !values.firstNameVerified }) }} className={'text-' + (values.firstNameVerified ? 'success text-shadow-success' : 'warning')} icon={values.firstNameVerified ? 'check-circle' : 'spinner'} spin={values.firstNameVerified ? false : true}></MDBIcon>
                        </div>
                    </Box>
                    <Box className='d-flex align-items-center mb-3'>
                        <ThemeInput disabled={!values.editProfile} inputClassName={values.editProfile ? 'border border-white' : ''} label='LastName' value={values.lastName} onChange={e => handleChange({ lastName: e.target.value })}></ThemeInput>
                        <div className='ps-1'>
                            <MDBIcon onClick={() => { if (values.editProfile) setValues({ ...values, lastNameVerified: !values.lastNameVerified }) }} className={'text-' + (values.lastNameVerified ? 'success text-shadow-success' : 'warning')} icon={values.lastNameVerified ? 'check-circle' : 'spinner'} spin={values.lastNameVerified ? false : true}></MDBIcon>
                        </div>
                    </Box>
                </Box>
                {/* <ThemeInput disabled={!values.editProfile} className='mb-3' inputClassName={values.editProfile ? 'border border-white' : ''} label='LastName' value={values.lastName} onChange={e => handleChange({ lastName: e.target.value })}></ThemeInput> */}
            </MDBCol>
            <MDBCol size='12' md='4' xl='2' className='pt-2'>
                <KYCMobile _id={values._id} mobile={values.defaultMobile} setMobile={m => setValues({ ...values, defaultMobile: m })}></KYCMobile>
                <KYCEmail _id={values._id} email={values.defaultEmail} setEmail={e => setValues({ ...values, defaultEmail: e })}></KYCEmail>
                {/* <ThemeInput copyButton={true} className='mb-3' inputStyle={{ color: '#999', fontSize: '0.8rem' }} label='Email' disabled value={values.email}></ThemeInput> */}
                <Referrer {...user} />
                <Box>
                    <ThemeInput copyButton={true} endClassName={'px-2'} endStyle={{ fontSize: '0.7rem' }} end={values.telegramUsername ? <a target='_blank' href={`https://t.me/${values.telegramUsername}`}><MDBIcon className='text-info' icon='paper-plane'></MDBIcon></a> : ''} className='mb-3 my-2' inputStyle={{ color: '#999', fontSize: '0.8rem' }} label='Telegram Username' disabled value={values.telegramUsername}></ThemeInput>
                </Box>
                {/* <ThemeInput copyButton={true} className='mb-3' inputStyle={{ color: '#999', fontSize: '0.8rem' }} label='Address' disabled value={values.address}></ThemeInput> */}
            </MDBCol>
            <MDBCol size='12' md='4' xl='2' className='pt-3'>
                <KYCAddress _id={values._id} address={values.address} setAddress={e => setValues({ ...values, address: e })}></KYCAddress>
            </MDBCol>
            <MDBCol size='12' md='6' xl='3' className='px-0'>
                <UserKYC user={user}></UserKYC>
            </MDBCol>
            <Modal
                open={modalImage.open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                <MDBCard style={modalStyle} className={classes.paper + ' box-shadow-neon'}>
                    <MDBCardHeader className='text-neon fw-bold bg-black' style={{ borderBottom: '1px solid #333' }}>
                        {modalImage.title}
                        <MDBIcon onClick={handleClose} className='float-end pt-1 cursor-pointer' icon='times'></MDBIcon>
                    </MDBCardHeader>
                    <MDBCardBody className='p-0'>
                        <MDBRow className='text-center'>
                            <MDBCol size='12'>
                                {
                                    modalImage.src ?
                                        <img height='300' width='300' src={modalImage.src} className='img-fluid m-auto p-1' />
                                        :
                                        <h4 className='text-center'>No Image</h4>
                                }
                            </MDBCol>
                        </MDBRow>
                    </MDBCardBody>
                </MDBCard>
            </Modal>
        </MDBRow>
    )
}

const KYCMobile = (props) => {
    const { mobile, setMobile, _id, userId } = props;
    const [state, dispatch] = useContext(Context);
    const { enqueueSnackbar } = useSnackbar();

    const [values, setValues] = useState(mobile);
    const [showEdit, setShowEdit] = useState(false);

    const save = (params) => {
        const postData = {
            _id,
            mobile: _.pick(params, ['prefix', 'number', 'verified'])
        }
        axios.post(state.active.host + '/admin/users/kyc/editdefaultmobile', postData, {
            headers: { 'x-auth-token': getBearer() }
        })
            .then(res => {
                res = res.data;
                if (res.success) {
                    enqueueSnackbar(res.message, { variant: 'success' })
                    setMobile({
                        ...mobile,
                        ...values
                    })
                    setShowEdit(false)
                } else {
                    enqueueSnackbar(res.message, { variant: 'error' })
                }
            })
            .catch((err) => {
                if (err.response) {
                    enqueueSnackbar(err.response.data, { variant: 'error' })
                } else {
                    enqueueSnackbar('Error!', { variant: 'error' })
                }
            })
    }

    return (<>
        <Box
            sx={{
                '& input': {
                    color: showEdit ? '#fff' : '#999 !important'
                }
            }}
            className='d-flex justify-content-between py-2 align-items-center'>
            <ThemePhoneInput
                disabled={!showEdit}
                label='Phone'
                value={{ phone: values.number, countryCode: values.prefix }}
                onChange={e => setValues({ ...values, prefix: e.countryCode, number: e.phone })}>
            </ThemePhoneInput>
            <div className='ps-1 small'>
                <div>
                    <MDBIcon className='text-warning cursor-pointer' style={{ zIndex: '999' }} onClick={() => setShowEdit(!showEdit)} icon='edit'></MDBIcon>
                </div>
                <MDBIcon className={'text-' + (mobile.verified ? 'success text-shadow-success' : 'warning')} icon={mobile.verified ? 'check-circle' : 'spinner'} spin={mobile.verified ? false : true}></MDBIcon>
            </div>
        </Box>

        <Collapse in={showEdit} timeout="auto" unmountOnExit>
            <div className='pb-2 d-flex justify-content-between'>
                <ThemeButton
                    onClick={e => { setValues({ ...values, verified: !values.verified }) }}
                    className='px-1' color={values.verified ? 'success text-shadow-success' : 'warning'} variant='outlined' size='small'>
                    <MDBIcon className={'me-1'} icon={values.verified ? 'check-circle' : 'times-circle'}></MDBIcon>
                    {values.verified ? 'Verified' : 'Not Verified'}
                </ThemeButton>
                <div>
                    <ThemeButton
                        onClick={e => save(values)}
                        className='px-2' color={'success'} variant='outlined' size='small'>
                        Save
                    </ThemeButton>
                    <ThemeButton
                        onClick={() => setShowEdit(false)}
                        className='ms-2 px-1' color={'danger'} variant='outlined' size='small'>
                        Cancel
                    </ThemeButton>
                </div>
            </div>
        </Collapse>
    </>)
}

const KYCEmail = (props) => {
    const { email, setEmail, _id } = props;
    const [state, dispatch] = useContext(Context);
    const { enqueueSnackbar } = useSnackbar();

    const defaultValue = email.address;
    const [values, setValues] = useState(email);
    const [showEdit, setShowEdit] = useState(false);

    const save = (params) => {
        const postData = {
            _id,
            email: _.pick(params, ['address', 'verified'])
        }
        // var postData = _.pick(values, ['_id', 'username', 'firstName', 'lastName', 'nickName']);

        axios.post(state.active.host + '/admin/users/kyc/editdefaultemail', postData, {
            headers: { 'x-auth-token': getBearer() }
        })
            .then(res => {
                res = res.data;
                if (res.success) {
                    enqueueSnackbar(res.message, { variant: 'success' })
                    setEmail({
                        ...email,
                        ...values
                    })
                    setShowEdit(false)
                } else {
                    enqueueSnackbar(res.message, { variant: 'error' })
                }
            })
            .catch((err) => {
                if (err.response) {
                    enqueueSnackbar(err.response.data, { variant: 'error' })
                } else {
                    enqueueSnackbar('Error!', { variant: 'error' })
                }
            })
    }

    return (<>
        <Box
            sx={{
                '& input': {
                    color: showEdit ? '#fff' : '#999 !important'
                }
            }}
            className='d-flex justify-content-between py-2 align-items-center'>
            <ThemeInput
                disabled={!showEdit}
                onChange={e => setValues({ ...values, address: e.target.value })}
                value={values.address} label='Email'></ThemeInput>
            <div className='ps-1 small'>
                <div>
                    <MDBIcon className='text-warning cursor-pointer' style={{ zIndex: '999' }} onClick={() => setShowEdit(!showEdit)} icon='edit'></MDBIcon>
                </div>
                <MDBIcon className={'text-' + (email.verified ? 'success text-shadow-success' : 'warning')} icon={email.verified ? 'check-circle' : 'spinner'} spin={email.verified ? false : true}></MDBIcon>
            </div>
        </Box>
        <Collapse in={showEdit} timeout="auto" unmountOnExit>
            <div className='pb-2 d-flex justify-content-between'>
                <ThemeButton
                    onClick={e => { setValues({ ...values, verified: !values.verified }) }}
                    className='px-1' color={values.verified ? 'success text-shadow-success' : 'warning'} variant='outlined' size='small'>
                    <MDBIcon className={'me-1'} icon={values.verified ? 'check-circle' : 'times-circle'}></MDBIcon>
                    {values.verified ? 'Verified' : 'Not Verified'}
                </ThemeButton>
                <div>
                    <ThemeButton
                        onClick={e => save(values)}
                        className='px-2' color={'success'} variant='outlined' size='small'>
                        Save
                    </ThemeButton>
                    <ThemeButton
                        onClick={() => {
                            setValues({ ...values, address: defaultValue })
                            setShowEdit(false)
                        }}
                        className='ms-2 px-1' color={'danger'} variant='outlined' size='small'>
                        Cancel
                    </ThemeButton>
                </div>
            </div>
        </Collapse>
    </>)
}

const KYCAddress = (props) => {
    const { _id, address, setAddress } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [state, dispatch] = useContext(Context);
    const defaultValue = address;
    const [values, setValues] = useState(address);
    const [showEdit, setShowEdit] = useState(false);

    useEffect(() => {
        console.log(props)
    }, [])


    const save = (params) => {
        const postData = {
            ...params, //_.pick(params, ['line1', 'status'])
            _id,
        }

        axios.post(state.active.host + '/admin/users/kyc/address', postData, {
            headers: { 'x-auth-token': getBearer() }
        })
            .then(res => {
                res = res.data;
                if (res.success) {
                    enqueueSnackbar(res.message, { variant: 'success' })
                    setAddress({
                        ...address,
                        ...values
                    })
                    setShowEdit(false)
                } else {
                    enqueueSnackbar(res.message, { variant: 'error' })
                }
            })
            .catch((err) => {
                if (err.response) {
                    enqueueSnackbar(err.response.data, { variant: 'error' })
                } else {
                    enqueueSnackbar('Error!', { variant: 'error' })
                }
            })
    }

    return (<>
        <Box sx={{ '& input': { color: showEdit ? '#fff' : '#999 !important' } }}>
            <Box className='d-flex'>
                <ThemeInput inputClassName={`${showEdit ? 'border border-white' : ''} `} disabled={!showEdit} className='mb-3' label='Address 1' value={values.line1} onChange={e => setValues({ ...values, line1: e.target.value })}></ThemeInput>
                <div className='ps-1 small'>
                    <MDBIcon className='text-warning cursor-pointer' style={{
                        zIndex: '999'
                    }} onClick={() => setShowEdit(!showEdit)} icon='edit'></MDBIcon>
                    <div className={`text-${address.status == 'confirmed' ? 'success' : address.status == 'rejected' ? 'danger' : 'warning'}`}>
                        <MDBIcon className={'me-1'} spin={address.status == 'pending'} icon={address.status == 'confirmed' ? 'check-circle' : address.status == 'rejected' ? 'times-circle' : 'spinner'}></MDBIcon>
                    </div>
                </div>
            </Box>
            <Box>
                <ThemeInput className='mb-3' label='Address 2' inputClassName={`${showEdit ? 'border border-white' : ''} `} disabled={!showEdit} value={values.line2} onChange={e => setValues({ ...values, line2: e.target.value })}></ThemeInput>
            </Box>
            <Box>
                <ThemeInput className='mb-3' label='City' inputClassName={`${showEdit ? 'border border-white' : ''} `} disabled={!showEdit} value={values.city} onChange={e => setValues({ ...values, city: e.target.value })}></ThemeInput>
            </Box>
            <Box>
                <ThemeInput className='mb-3' label='State' inputClassName={`${showEdit ? 'border border-white' : ''} `} disabled={!showEdit} value={values.state} onChange={e => setValues({ ...values, state: e.target.value })}></ThemeInput>
            </Box>
            <Box>
                <ThemeInput className='mb-' label='Country' inputClassName={`${showEdit ? 'border border-white' : ''} `} disabled={!showEdit} value={values.country} onChange={e => setValues({ ...values, country: e.target.value })}></ThemeInput>
            </Box>
        </Box>
        <Collapse in={showEdit} timeout="auto" unmountOnExit>
            <div className='pb-2 d-flex justify-content-between'>
                <ThemeButton
                    onClick={e => { setValues({ ...values, status: values.status == 'pending' ? 'confirmed' : values.status == 'rejected' ? 'pending' : 'rejected' }) }}
                    className='px-1' color={values.status == 'confirmed' ? 'success' : values.status == 'rejected' ? 'danger' : 'warning'} variant='outlined' size='small'>
                    <MDBIcon className={'me-1'} spin={values.status == 'pending'} icon={values.status == 'confirmed' ? 'check-circle' : values.status == 'rejected' ? 'times-circle' : 'spinner'}></MDBIcon>
                    {values.status}
                </ThemeButton>
                <div>
                    <ThemeButton
                        onClick={e => save(values)}
                        className='px-2' color={'success'} variant='outlined' size='small'>
                        Save
                    </ThemeButton>
                    <ThemeButton
                        onClick={() => {
                            setValues({ ...values, address: defaultValue })
                            setShowEdit(false)
                        }}
                        className='ms-2 px-1' color={'danger'} variant='outlined' size='small'>
                        Cancel
                    </ThemeButton>
                </div>
            </div>
        </Collapse>
    </>)
}

// const Referrer = (props) => {
//     const { user } = props;
//     const { referrer } = user.profile;

//     useEffect(() => {
//         console.log(user)

//         return () => {
//         }
//     }, [])

//     return (
//         <>
//             {!referrer ?
//                 <Box>
//                     No Referrer
//                 </Box>
//                 :
//                 <Box>
//                     a {referrer.userId}
//                 </Box>
//             }
//         </>
//     )

// }

const Referrer = (props) => {
    const { userId, profile } = props;
    const [state, dispatch] = useContext(Context);
    const { enqueueSnackbar } = useSnackbar();

    const defaultRefId = profile.referrer ? profile.referrer.refId : '';
    const [referrer, setReferrer] = useState({
        refId: defaultRefId,
        userId: profile.referrer ? profile.referrer.userId : '',
        // from: profile.referrer ? profile.referrer.from : '',
        // user: profile.referrer ? profile.referrer.user : '',
    });
    const [showEdit, setShowEdit] = useState(false);

    const save = (newRefId) => {
        const postData = {
            userId,
            refId: newRefId
        }
        // var postData = _.pick(values, ['_id', 'username', 'firstName', 'lastName', 'nickName']);

        axios.post(state.active.host + '/admin/users/editReferrer', postData, {
            headers: { 'x-auth-token': getBearer() }
        })
            .then(res => {
                res = res.data;
                if (res.success) {
                    enqueueSnackbar(res.message, { variant: 'success' })
                    // setEmail({
                    //     ...email,
                    //     ...refId
                    // })
                    setShowEdit(false)
                } else {
                    enqueueSnackbar(res.message, { variant: 'error' })
                }
            })
            .catch((err) => {
                if (err.response) {
                    enqueueSnackbar(err.response.data, { variant: 'error' })
                } else {
                    enqueueSnackbar('Error!', { variant: 'error' })
                }
            })
    }

    return (<>
        <Box
            sx={{
                '& input': {
                    color: showEdit ? '#fff' : '#999 !important'
                }
            }}
            className='d-flex justify-content-between pb-2 align-items-center pt-2'>
            <ThemeInput
                disabled={!showEdit}
                onChange={e => setReferrer({ ...referrer, refId: e.target.value })}
                value={referrer.refId} label={`Referrer${referrer.userId ? ': (' + referrer.userId + ')' : ''} RefId:`}></ThemeInput>
            <div className='ps-1'>
                <div>
                    <MDBIcon className='text-warning cursor-pointer' style={{ zIndex: '999' }} onClick={() => setShowEdit(!showEdit)} icon='edit'></MDBIcon>
                </div>
                {/* <MDBIcon className={'text-' + (email.verified ? 'success text-shadow-success' : 'danger')} icon={email.verified ? 'check-circle' : 'times-circle'}></MDBIcon> */}
            </div>
        </Box>
        <Collapse in={showEdit} timeout="auto" unmountOnExit>
            <div className='pb-2 d-flex justify-content-between'>
                {/* <ThemeButton
                    onClick={e => { setRefId({ ...refId, verified: !refId.verified }) }}
                    className='px-1' color={refId.verified ? 'success text-shadow-success' : 'warning'} variant='outlined' size='small'>
                    <MDBIcon className={'me-1'} icon={refId.verified ? 'check-circle' : 'times-circle'}></MDBIcon>
                    {refId.verified ? 'Verified' : 'Not Verified'}
                </ThemeButton> */}
                <div>
                    <ThemeButton
                        onClick={e => save(referrer.refId)}
                        className='px-2' color={'success'} variant='outlined' size='small'>
                        Save
                    </ThemeButton>
                    <ThemeButton
                        onClick={() => {
                            setReferrer({ ...referrer, refId: defaultRefId })
                            setShowEdit(false)
                        }}
                        className='ms-2 px-1' color={'danger'} variant='outlined' size='small'>
                        Cancel
                    </ThemeButton>
                </div>
            </div>
        </Collapse>
    </>)
}

export default UserProfile
