import { TextField } from '@mui/material'
import React from 'react'
import $ from 'jquery';
const ThemeTextField = (prop) => {
    const { inputPropsClassName, ...props } = prop;
    return (
        <TextField
            autoComplete='off'
            focused
            label='rds'
            InputLabelProps={{
                title: 'asdfasdfasdfasdf',
                sx: {
                    fontSize: ['0.8rem'],
                    textShadow: '0 0 2px #000',
                    backgroundColor: '#000',
                    paddingX: '5px',
                    marginLeft: '-5px',
                    color: '#26ddff !important'
                }
            }}
            sx={{
                '& div:hover': {
                    '& fieldset': {
                        borderColor: '#26ddff !important',
                    }
                },
                padding: 0,
                '& fieldset.MuiOutlinedInput-notchedOutline': {
                    borderWidth: '1px !important',
                    fontSize: ['0.8rem'],
                    borderColor: '#555',
                    transition: 'all 0.2s linear',
                    '& legend': {
                        paddingRight: '0px',
                        '& span': {
                            paddingRight: '0px',
                        }
                    }
                },
            }}
            inputProps={{
                className: 'rounded-1 ' + inputPropsClassName,
                sx: {
                    transition: 'all 0.2s linear',
                    padding: 1,
                    ...props.inputPropsSX
                },
                onFocus: (e) => $(e.target).parent().find('> *').addClass('border-neon box-shadow-neon'),
                onBlur: (e) => $(e.target).parent().find('> *').removeClass('border-neon box-shadow-neon'),
            }}
            className={"form-control shadow-none bg-transparent"}
            {...props}

        />
    )
}

export default ThemeTextField