const getPreviousMonth = () => {
  let date = new Date();
  let from = new Date(date.getFullYear(), date.getMonth(), 1);
  return from.getTime()
}
const getLastDayOfCurrentMonth = () => {
  let date = new Date();
  let to = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return to.getTime()
}

const serviceFinancialStats = (params) => {
  let { dateFrom, dateTo, userId, service, currency, betState } = params;
  dateFrom = dateFrom || getPreviousMonth();
  dateTo = dateTo || getLastDayOfCurrentMonth();
  userId = userId || { $exists: 1 };
  service = service || { $exists: 1 };
  currency = currency || { $exists: 1 };
  betState = betState || ['won', 'lost', 'cashout'];

  const aggQuery = [
    {
      '$match': {
        'service': service,
        'createdAt': {
          $gte: dateFrom,
          // $lt: dateTo
        },
        // '$and': [
        //   {
        //     'createdAt': {
        //       $gte: dateFrom,
        //       $lt: dateTo
        //     }
        //   }],
        // 'type': {
        //   '$eq': 'bet'
        // },
        'status': 'verified',
        'isValid': true,
        // 'meta.bet.betState': {
        //   '$in': betState
        // }
      }
      // }, {
      //   '$group': {
      //     '_id': 'totals',
      //     'winAmountInUSD': {
      //       '$sum': '$meta.bet.winAmountInUSD'
      //     },
      //     'betAmountInUSD': {
      //       '$sum': '$meta.bet.betAmountInUSD'
      //     }
      //   }
      // }, {
      //   '$project': {
      //     'pl': {
      //       '$subtract': [
      //         '$betAmountInUSD', '$winAmountInUSD'
      //       ]
      //     }
      //   }
    }
  ]

  return aggQuery
}

// 615286
const digitainTotalBetAmountinUSD = [
  {
    '$match': {
      'service': 'Digitain',
      'type': {
        '$eq': 'bet'
      },
      'status': 'verified',
      'isValid': true
    }
  }, {
    '$project': {
      'bet': {
        '$arrayElemAt': [
          '$meta.relatedSportTransactions', 0
        ]
      }
    }
  }, {
    '$group': {
      '_id': 'title',
      'count': {
        '$sum': '$bet.meta.Order.OrderAmount'
      }
    }
  }
]

// 559149.46
const digitainTotalWinAmountinUSD = [
  {
    '$match': {
      'service': 'Digitain',
      'type': {
        '$eq': 'bet'
      },
      'status': 'verified',
      'isValid': true
    }
  }, {
    '$project': {
      'bet': {
        '$arrayElemAt': [
          '$meta.relatedSportTransactions', 0
        ]
      }
    }
  }, {
    '$group': {
      '_id': 'title',
      'count': {
        '$sum': '$bet.meta.Order.OrderAmount'
      }
    }
  }
]


const playerStats = [
  {
    '$match': {
      'userId': '100092',
      'isValid': true
    }
  }, {
    '$sort': {
      'createdAt': -1
    }
  }, {
    '$addFields': {
      'totalTopupDepositInUSD': {
        '$cond': [
          {
            '$and': [
              {
                '$eq': [
                  '$type', 'topup'
                ]
              }, {
                '$gt': [
                  '$meta.amountInUSD', 0
                ]
              }
            ]
          }, '$meta.amountInUSD', 0
        ]
      },
      'totalTopupDepositCount': {
        '$cond': [
          {
            '$and': [
              {
                '$eq': [
                  '$type', 'topup'
                ]
              }, {
                '$gt': [
                  '$meta.amountInUSD', 0
                ]
              }
            ]
          }, 1, 0
        ]
      },
      'totalTopupWithdrawInUSD': {
        '$cond': [
          {
            '$and': [
              {
                '$eq': [
                  '$type', 'topup'
                ]
              }, {
                '$lt': [
                  '$meta.amountInUSD', 0
                ]
              }
            ]
          }, '$meta.amountInUSD', 0
        ]
      },
      'totalTopupWithdrawCount': {
        '$cond': [
          {
            '$and': [
              {
                '$eq': [
                  '$type', 'topup'
                ]
              }, {
                '$lt': [
                  '$meta.amountInUSD', 0
                ]
              }
            ]
          }, 1, 0
        ]
      },
      'totalTopupBonus': {
        '$cond': [
          {
            '$and': [
              {
                '$eq': [
                  '$type', 'topup'
                ]
              }, {
                '$gt': [
                  '$meta.amountInUSD', 0
                ]
              }
            ]
          }, '$meta.bonus', 0
        ]
      },
      'totalTopupBonusCount': {
        '$cond': [
          {
            '$and': [
              {
                '$eq': [
                  '$type', 'topup'
                ]
              }, {
                '$gt': [
                  '$meta.bonus', 0
                ]
              }
            ]
          }, 1, 0
        ]
      },
      'totalWithdraw': {
        '$cond': [
          {
            '$and': [
              {
                '$eq': [
                  '$type', 'withdraw'
                ]
              }
            ]
          }, '$amount', 0
        ]
      },
      'totalDeposit': {
        '$cond': [
          {
            '$and': [
              {
                '$eq': [
                  '$type', 'deposit'
                ]
              }
            ]
          }, '$amount', 0
        ]
      },
      'totalBetAmountInUSD': {
        '$cond': [
          {
            '$and': [
              {
                '$eq': [
                  '$type', 'bet'
                ]
              }, {
                '$gt': [
                  '$meta.bet.betAmountInUSD', 0
                ]
              }
            ]
          }, '$meta.bet.betAmountInUSD', 0
        ]
      },
      'totalWinAmountInUSD': {
        '$cond': [
          {
            '$and': [
              {
                '$eq': [
                  '$type', 'bet'
                ]
              }, {
                '$gt': [
                  '$meta.bet.winAmountInUSD', 0
                ]
              }
            ]
          }, '$meta.bet.winAmountInUSD', 0
        ]
      }
    }
  }, {
    '$group': {
      '_id': 'a',
      'totalTopupDepositInUSD': {
        '$sum': '$totalTopupDepositInUSD'
      },
      'totalTopupWithdrawInUSD': {
        '$sum': '$totalTopupWithdrawInUSD'
      },
      'totalTopupDepositCount': {
        '$sum': '$totalTopupDepositCount'
      },
      'totalTopupWithdrawCount': {
        '$sum': '$totalTopupWithdrawCount'
      },
      'totalTopupBonus': {
        '$sum': '$totalTopupBonusCount'
      },
      'totalTopupBonusCount': {
        '$sum': '$totalTopupBonusCount'
      },
      'totalWithdraw': {
        '$sum': '$totalWithdraw'
      },
      'totalDeposit': {
        '$sum': '$totalDeposit'
      },
      'totalBetAmountInUSD': {
        '$sum': '$totalBetAmountInUSD'
      },
      'totalWinAmountInUSD': {
        '$sum': '$totalWinAmountInUSD'
      },
      'count': {
        '$sum': 1
      }
    }
  }
]

export {
  serviceFinancialStats,
  digitainTotalBetAmountinUSD,
  digitainTotalWinAmountinUSD
}
