import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { getPlayerReportData } from '../../../helpers/reportHelper';
import PlayerReportCashbackCard from './PlayerReportCashbackCard';
import PlayerReportTableCard from './PlayerReportTableCard';

const PlayerReportRender = (props) => {
    const [searchParams] = useSearchParams();
    const userId = props.userId || Object.fromEntries([...searchParams]).userId || '';
    let type = props.type || Object.fromEntries([...searchParams]).type || '';
    const from = props.from || Object.fromEntries([...searchParams]).from || '';
    const to = props.to || Object.fromEntries([...searchParams]).to || '';

    let reportType = '';
    if (['dailyCashback', 'weeklyCashback'].indexOf(type) > -1) {
        reportType = 'cashback';
        if (type == 'dailyCashback') {
            type = 'daily';
        } else {
            type = 'weekly';
        }
    } else {
        reportType = 'playReport';
    }

    const [playerReportData, setPlayerReportData] = useState({});
    useEffect(() => {
        const newFilters = {
            userId,
            // fake: newValues.fake,
            from,
            to
        };
        getPlayerReportData(setPlayerReportData, newFilters)
    }, [])


    return <Box className='bg-black px-1 pt-4' sx={{
        '& .tableValue': {
            fontSize: '1rem'
        }
    }}>
        {playerReportData?.transactions ? <>
            {(reportType == 'cashback') &&
                <PlayerReportCashbackCard hideJson playerReportData={playerReportData} type={type} from={from} to={to} />
            }
            {(reportType == 'playReport') &&
                <PlayerReportTableCard playerReportData={playerReportData} type='fromTo' from={from} to={to} />
            }
        </>
            : <Box>Loading</Box>}
    </Box>
}

export default PlayerReportRender