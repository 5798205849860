import { Box } from '@mui/material';
import numeral from 'numeral';
import React from 'react'

const getOddsColor = (odds) => {
    if (odds == null) return;
    const result = odds >= 10 ? 'white bg-danger' :
        odds >= 8 ? 'white bg-warning' :
            odds >= 5 ? 'white bg-neon' :
                odds >= 3 ? 'white bg-success' :
                    odds >= 2 ? 'neon' :
                        odds > 1 ? 'success' :
                            odds == 1 ? 'yellow' :
                                odds == 0 ? 'danger' :
                                    odds <= 1 ? 'orange' :
                                        'white';
    return result;
}

const RenderOdds = (props) => {
    const { meta, subtype } = props;
    const { bet } = meta;
    if (!bet) return <></>
    const { betAmountInUSD, winAmountInUSD } = bet;
    const odds = subtype == 'open' ? bet.odds : winAmountInUSD / betAmountInUSD;
    const cls = getOddsColor(odds);

    return <Box>
        {(odds !== null) && <span className={`ms-md-2 rounded-3 p-1 text-${cls}`} style={{
            boxShadow: '0 0 5px #999'
        }}>
            <strong style={{ paddingRight: '2px' }}>
                {numeral(odds).format('0,0.00')}
            </strong>
            x
        </span>}
    </Box>
}

export default RenderOdds