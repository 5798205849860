import { Collapse, Divider, Grid, IconButton, LinearProgress, Modal, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { MDBIcon } from 'mdb-react-ui-kit';
import { useSnackbar } from 'notistack';
import React, { Fragment, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Link, useSearchParams } from 'react-router-dom';
import SunEditor from 'suneditor-react';
import { BreadCrumbContext } from '../context/store';
import ThemeButton from '../layout/components/ThemeButton';
import ThemeFormatTime from '../layout/components/ThemeFormatTime';
import ThemeInput from '../layout/components/ThemeInput';
import { getPage, savePage } from './pagesHelper';
import PageStatus from './PageStatus';
import PageTypes from './PageTypes';
import * as Icon from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';

const PageEdit = (props) => {
    const [searchParams] = useSearchParams();
    const _id = props._id || Object.fromEntries([...searchParams])._id || '';
    const isMobile = useMediaQuery('(max-width:600px)');

    const { enqueueSnackbar } = useSnackbar();

    const [values, setValues] = useState({});
    const {
        icon,
        deletable,
        type,
        totalRating,
        status,
        title,
        content,
        shortDescription,
        shortURL,
        breadcrumb,
        visible,
        order,
        keywords,
        expireAt,
        adminReviews,
        userReviews,
        media,
        createdBy,
        updatedBy,
        isValid,
        createdAt,
        updatedAt } = values;

    useEffect(() => {
        if (_id) {
            getPage({
                _id,
                setData: setValues
            })
        }

        return () => { }
    }, [])

    const valuesRef = useRef();
    valuesRef.current = values;

    const handleContentChange = (e) => {
        setValues({ ...valuesRef.current, content: e })
    }

    const [contextBC, setContextBC] = useContext(BreadCrumbContext);
    useLayoutEffect(() => {
        setContextBC([
            {
                href: '/',
                text: 'Home'
            },
            {
                href: '/pages/list',
                text: 'Pages'
            },
            {
                text: _id ? `Edit Page: ${title}` : 'New'
            }
        ])
    }, [title])

    return <Box>
        <Collapse in={Boolean(content || !_id)} timeout="auto" unmountOnExit>

            <Box sx={{
                px: 1,
            }}>

                <Box className='py-2'>
                    <Grid container columns={16} spacing={1}>
                        <Grid xs={16} md={2} item>
                            <ThemeInput style={{ height: '100%' }} value={values.title || ''} label='Title' onChange={e => setValues({ ...values, title: e.target.value })} />
                        </Grid>
                        {/* <Grid xs={12} md={2} item>
                        <ThemeInput value={values.type || ''} label='Type' onChange={e => setValues({ ...values, type: e.target.value })} />
                    </Grid> */}
                        <Grid xs={8} md={1} item>
                            <PageTypes value={values.type} onChange={e => setValues({ ...values, type: e.target.value })} />
                        </Grid>
                        <Grid xs={8} md={2} item>
                            <PageStatus value={values.status} onChange={e => setValues({ ...values, status: e.target.value })} />
                        </Grid>
                        <Grid xs={8} md={1} item>
                            <ThemeInput style={{ height: '100%' }} type='number' value={values.order || 1} label='Order' onChange={e => setValues({ ...values, order: parseInt(e.target.value) })} />
                        </Grid>
                        <Grid xs={8} md={1} item className='d-flex align-items-center justify-content-between'>
                            <IconSelector value={values.icon} onChange={i => setValues({ ...values, icon: i })} />
                            <Box sx={{
                                '& svg': {
                                    width: '1rem',
                                    height: '1rem',
                                }
                            }}>
                                <IconButton onClick={() => setValues({ ...values, deletable: !values.deletable })} className={`cursor-pointer text-${values.deletable ? 'danger box-shadow-danger ' : 'gray box-shadow-white '}`} >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </Grid>
                        <Grid xs={16} md={9} item>
                            <Grid container
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    border: (theme) => `1px solid ${theme.palette.divider}`,
                                    borderRadius: 1,
                                    // padding: '1px',
                                    bgcolor: 'background.paper',
                                    '& hr': {
                                        height: 'auto',
                                        mx: 1,
                                        display: isMobile ? 'none' : 'block'
                                    },
                                }}
                            >
                                {/* <Grid item className='d-flex flex-column align-items-center'>
                        <Link to='/pages/list'>
                            <ThemeButton color='neon' variant='outlined' size='small' className='px-3 py-2'>
                                <MDBIcon icon='sign-out' rotate='180' className='me-1'></MDBIcon>
                                Back to Pages List
                            </ThemeButton>
                        </Link>
                    </Grid> */}
                                <Grid xs={8} md='auto' item className='d-flex flex-column align-items-start align-items-md-center justify-content-center'>
                                    <Typography variant='subtitle2' className='text-light' component='span'>
                                        ShortURL
                                    </Typography>
                                    <Typography variant='subtitle2' className='text-success d-flex' component='span'>
                                        <CopyToClipboard
                                            onCopy={() => { enqueueSnackbar('Short URL Copied.', { variant: 'success', autoHideDuration: 2500 }) }}
                                            text={`https://imoon.com/p/${shortURL}`}>
                                            <Box className='text-yellow d-inline cursor-pointer text-center'>
                                                <Box className='text-center'>
                                                    https://imoon.com/p/{shortURL} <MDBIcon className='px-1' icon='copy'></MDBIcon>
                                                </Box>
                                            </Box>
                                        </CopyToClipboard>
                                    </Typography>
                                </Grid>
                                <Divider orientation="vertical" flexItem />
                                <Grid xs={4} md='auto' item className='d-flex flex-column  align-items-start align-items-md-center'>
                                    <Typography variant='subtitle2' className='text-light' component='span'>
                                        Creator
                                    </Typography>
                                    <Typography variant='subtitle1' className='text-success' component='span'>
                                        {createdBy?.userId}
                                    </Typography>
                                </Grid>
                                <Divider orientation="vertical" flexItem />
                                <Grid item className='d-flex flex-column  align-items-start align-items-md-center'>
                                    <Typography variant='subtitle2' className='text-light' component='span'>
                                        Created
                                    </Typography>
                                    <Typography variant='subtitle1' className='text-success' component='span'>
                                        <ThemeFormatTime timestamp={createdAt} showFromNow sx={{ '& .fromNow': { display: 'inline', paddingLeft: '0.5rem', color: '#aaa' } }} />
                                    </Typography>
                                </Grid>
                                <Divider orientation="vertical" flexItem />
                                <Grid item className='d-flex flex-column  align-items-start align-items-md-center'>
                                    <Typography variant='subtitle2' className='text-light' component='span'>
                                        Modified
                                    </Typography>
                                    <Typography variant='subtitle1' className='text-neon' component='span'>
                                        <ThemeFormatTime timestamp={updatedAt} showFromNow sx={{ '& .dateTime': { color: '#26ddff' }, '& .fromNow': { display: 'inline', paddingLeft: '0.5rem', color: '#aaa' } }} />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid xs={16} md={15} item className='pt-md-3'>
                            <ThemeInput rows={isMobile ? 7 : 2} multiline value={values.shortDescription} label='Short Description' onChange={e => setValues({ ...values, shortDescription: e.target.value })} />
                        </Grid>
                        <Grid xs={3} md={1} item className='d-flex justify-content-end align-items-center'>
                            <ThemeButton className='mx-2 px-3 py-1' variant='outlined' onClick={() => savePage({ data: values, enqueueSnackbar })} color='success'>Save</ThemeButton>
                            {/* <ThemeButton className='mx-2 px-3 py-1' variant='outlined' onClick={() => savePage({ data: values, enqueueSnackbar })} color='success'>Send to Review</ThemeButton> */}
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    {(content || !_id) &&
                        <SunEditor
                            defaultValue={valuesRef.current.content}
                            setContents={valuesRef.current.content}
                            onChange={handleContentChange}
                            onSave={() => savePage({ data: valuesRef.current, enqueueSnackbar })}
                            autoFocus={true}
                            setOptions={{
                                buttonList: [
                                    ["undo", "redo"],
                                    ["fontSize", "formatBlock"],// "font",
                                    ["paragraphStyle", "blockquote"],
                                    ["bold", "underline", "italic", "strike", "subscript", "superscript", "link"],
                                    ["fontColor", "hiliteColor", "textStyle", "removeFormat"],
                                    ["outdent", "indent", "align", "horizontalRule", "list", "lineHeight"],
                                    ["table"],
                                    // ["image", "video", "audio", "imageGallery"], // "math",   
                                    [
                                        "fullScreen",
                                        "showBlocks",
                                        "print",
                                        "template",
                                        "codeView",
                                        "preview",
                                        "save",
                                    ]
                                ],
                                previewTemplate: `<div style='width:100%;color: #eee;background-color:#111;padding:2rem;box-shadow: 0 0 15px #fffa'>
                            <h1 style="width: 100%; text-align:center;background-color:#222;color:#26ddff;padding:2rem;">Preview</h1>
                            {{ contents }}
                        </div>`
                            }}
                            height='70vh'
                        />
                    }
                </Box>
                {/* <Box className='bg-white'>
                <iframe style={{
                    width: '100%',
                    height: '40vh',
                }}
                    srcdoc={content}
                ></iframe>
            </Box> */}
            </Box>
        </Collapse >
        <Collapse in={!Boolean(content || !_id)} timeout="auto" unmountOnExit>
            <LinearProgress color='info' />
        </Collapse>
        {/* <Box className='p-3'>
            <ThemeButton className='px-3 py-1' variant='outlined' onClick={() => savePage({ data: values, enqueueSnackbar })} color='success'>Save</ThemeButton>
        </Box> */}
    </Box >
};


const IconSelector = (props) => {
    const { value, onChange } = props;

    const [selectedIcon, setSelectedIcon] = useState(value || <Icon.QuestionMarkSharp />);

    useEffect(() => {
        if (value && Icon[value]) {
            const IconComponent = Icon[value];
            setSelectedIcon(<IconComponent />)
        }
    }, [value])

    const [open, setOpen] = useState(false);

    const [searchText, setSearchText] = useState('');
    const searchTextRef = useRef();
    searchTextRef.current = searchText;

    const [iconsList, setIconsList] = useState([]);
    const handleSearch = () => {
        let iconsList = [];
        console.log('searchTextRef.current', searchTextRef.current)
        if (searchTextRef.current && (searchTextRef.current.length > 2)) {
            iconsList = Object.keys(Icon).filter(i => (i.toLowerCase().indexOf(searchTextRef.current) > -1));
            setIconsList(iconsList);
        } else {
            setIconsList([]);
        }
    }

    const searchDebounsRef = useRef(_.debounce(handleSearch, 500));

    useEffect(() => {
        searchDebounsRef.current();
    }, [searchText])

    return <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            '& svg': {
                width: '1rem',
                height: '1rem',
            }
            // border: '1px solid #555',
            // // p: '0.25rem',
            // borderRadius: '0.25rem',
            // display: 'flex',
            // alignItems: 'center'

        }}>
        <IconButton className='box-shadow-white' onClick={() => setOpen(true)}>
            {selectedIcon}
        </IconButton>

        <Modal
            open={open}
            disableScrollLock={true}
            onClose={() => setOpen(false)}>
            <Box className='box-shadow-neon rounded-6 overflow-scroll' sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: [400, 600],
                height: '70vh'
            }}>
                <Box className='px-3 bg-black h-100 overflow-scroll position-relative'>
                    <Box className='box-shadow-neon position-fixed px-3 py-2 w-100 bg-black d-flex align-items-center justify-content-between' sx={{ left: 0 }}>
                        <Box sx={{ width: '70%' }}>
                            <ThemeInput value={searchText} label='Search' type='search' onChange={e => setSearchText(e.target.value?.toLowerCase())} />
                        </Box>
                        <Box>
                            {selectedIcon}
                        </Box>
                        <Typography variant='subtitle2' className='text-light'>
                            {value || 'Select an Icon'}
                        </Typography>
                    </Box>

                    {/* {Object.keys(Icon).filter(i => (i.toLowerCase().indexOf(searchText) > -1)).map(k => {
                        const I = Icon[k];
                        return <Box key={k} onClick={() => onChange(k)}>
                            <I />
                        </Box>
                    })} */}
                    <Box className='mt-5 pt-3'>
                        <Grid container>
                            {
                                iconsList.map(k => {
                                    const I = Icon[k];
                                    return <Grid xs={6} md={3} item key={k} className='text-center rounded-3 py-2 box-hover-shadow-neon text-hover-neon cursor-pointer' onClick={() => onChange(k)}>
                                        <I />
                                        <Typography variant='subtitle2' className='text-light'>
                                            {k}
                                        </Typography>
                                    </Grid>
                                })}
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Modal >
    </Box >
}

export default PageEdit
