import React, { useState } from 'react';
import { AppBar, Box, Tab, Tabs, Toolbar } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import PropTypes from 'prop-types';
import UserLogs from '../../Users/UserDetails/UserLogs';
import UserAffiliate from '../../Users/UserDetails/UserAffiliate';
import UserCRM from '../../Users/UserDetails/UserCRM';
import UserWallets from '../../Users/UserDetails/UserWallets';
import UserProfile from '../../Users/UserDetails/UserProfile';
import UserPlays from '../../Users/UserDetails/UserPlays';
import UserAssist from '../../Users/UserDetails/UserAssist';
import UserTelegram from '../../Users/UserDetails/UserTelegram';
import { MDBIcon } from 'mdb-react-ui-kit';
// import UserAccounts from '../../Users/UserDetails/UserAccounts';
import TransactionList from '../../Transaction/TransactionList';
import UserReport from '../../Report/UserReport';
import PlayerReport from '../../Report/PlayerReport/PlayerReport';
import ReactJson from 'react-json-view';

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    marginRight: theme.spacing(1),
    fontSize: '0.7rem !important',
    color: '#fff !important',
    padding: '0 1rem !important',
    margin: '0.5rem 0 !important',
    minHeight: '0 !important',
    height: '1.5rem !important',
    border: '1px solid transparent !important',
    '&:hover': {
        color: '#40a9ff',
        opacity: 1,
    },
    '&.Mui-selected': {
        textShadow: '0 0 10px #26ddff !important',
        fontWeight: 'bold',
        borderRadius: '50rem',
        border: '1px solid #26ddff !important',
        color: '#26ddff !important',
        boxShadow: '0 0 0.5rem #26ddff !important',
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff',
    },
}));

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


const UserDetails = (props) => {
    const { user, activeTab } = props;
    const activeTabIndex = activeTab && activeTab.index ? activeTab.index : 0;
    const activeTabType = activeTab && activeTab.type ? activeTab.type : '';
    const [value, setValue] = useState(activeTabIndex);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={'box-shadow-neon overflow-hidden bg-black'}>
            <AppBar position="fixed" color="default" className='box-shadow-neon' sx={{
                minHeight: '3rem',
                zIndex: 99999,
                bgcolor: '#000 !important',
                backgroundImage: 'none !important',
                border: 'none !important'
            }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    TabIndicatorProps={{ className: 'd-none' }}
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    style={{ backgroundColor: '#000', padding: '0 1rem', minHeight: 'auto' }}>
                    <AntTab label='👨🏻‍💻 Profile' {...a11yProps(0)} />
                    <AntTab label='💰 Wallets' {...a11yProps(1)} />
                    <AntTab label='📊 Player Report' {...a11yProps(2)} />
                    <AntTab label='📜 Transactions' {...a11yProps(3)} />
                    <AntTab label='📝 Logs' {...a11yProps(4)} />
                    <AntTab label='📱 Telegram' {...a11yProps(5)} />
                    <AntTab label='💼 Affiliate' {...a11yProps(6)} />
                    <AntTab label='📈 Report (Old)' {...a11yProps(7)} />
                    <AntTab label='📋 Json' {...a11yProps(8)} />
                    {/* <AntTab label='CRM' {...a11yProps(7)} />
                    <AntTab label='Assist' {...a11yProps(8)} />
                    <AntTab label={<span><MDBIcon icon='paper-plane' className='me-1'></MDBIcon>Telegram</span>} {...a11yProps(9)} /> */}
                    {/* <AntTab className={''} label={`Provider Accounts`} {...a11yProps(10)} /> */}
                    {/* <AntTab className={''} label={`Financial Report`} {...a11yProps(7)} /> */}
                </Tabs>
            </AppBar>
            <TabPanel component='div' value={value} index={0}>
                <Toolbar />
                <UserProfile user={user} />
            </TabPanel>
            <TabPanel component='div' value={value} index={1}>
                <Toolbar />
                <UserWallets all={true} _id={user._id} userWallet={{ ...user.wallet }} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <PlayerReport mode="week" userId={user.userId} noToolbar />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <TransactionList userId={user.userId} type={activeTabType} noToolbar />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <Toolbar />
                <UserLogs {...user} />
            </TabPanel>
            <TabPanel value={value} index={5}>
                <Toolbar />
                <UserTelegram  {...user} />
            </TabPanel>
            <TabPanel value={value} index={6}>
                <Toolbar />
                <UserAffiliate user={user} />
            </TabPanel>
            <TabPanel value={value} index={7}>
                <Toolbar />
                <UserReport {...user} />
            </TabPanel>
            <TabPanel value={value} index={8}>
                <Toolbar />
                <ReactJson collapsed={1} name={null} style={{ padding: '0.5rem', fontSize: '0.7rem' }} theme="monokai" src={user} />
            </TabPanel>
            {/* <TabPanel value={value} index={5}>
                <div>
                    Commissions per wallet,
                    Stats (DatePicker),
                    Stats per Month (Users and profit)
                </div>
            </TabPanel>
            <TabPanel value={value} index={6}>
                <UserCRM />
            </TabPanel>
            <TabPanel component='div' value={value} index={7}>
                <UserPlays wallets={user.wallet} />
            </TabPanel>
            <TabPanel value={value} index={8}>
                <UserAssist />
            </TabPanel>
             */}

        </div >
    );
}

export default UserDetails
