import { Alert, ClickAwayListener, Collapse, Grid, Tooltip, Typography, Zoom } from '@mui/material';
import { Box } from '@mui/system';
import { MDBIcon } from 'mdb-react-ui-kit';
import moment from 'moment';
import numeral from 'numeral';
import React, { useState } from 'react'
import ReactJson from 'react-json-view';
import ThemeButton from '../../../layout/components/ThemeButton';

const PlayerReportCashback = (props) => {
    const { type, playerReportData, from } = props;

    const { tier } = playerReportData;
    const playerReportCashbackData = playerReportData[type + 'CashbackData'];
    const { startTime,
        endTime,
        startBalance,
        endBalance,
        // currentBalance,
        overalDepositAmount,
        overalWithdrawAmount,
        totalWithdrawPendingAmount,
        totalWithdrawPendingCount,
        playerNetLoss,
        playerLoss,
        openBetsAmount,
        totalDepositAmount,
        totalWithdrawAmount,
        totalPendingWithdrawAmount,
        lastTopupDailyCashbackAmount,
        lastTopupDailyCashbackDateTime,
        lastTopupWeeklyCashbackAmount,
        lastTopupWeeklyCashbackDateTime,
        profitable,
        balanceAndOpenCheck,
        availableCashbackAmount,
        availableCashbackPercent,
        cashbackApplicableAmount,
        eligible,
    } = playerReportCashbackData;
    const isFirstDailyCashbackInCurrentWeek = ((from == startTime) && (type == 'daily'));
    const rule1 = overalDepositAmount == 0;
    // const rule3 = !balanceAndOpenCheck;//(endBalance + openBetsAmount) > (0.02 * lastDepositAmount);

    const [open, setOpen] = useState(false)

    return <Grid container>
        {/* <StyledGrid xs={12} lg={6} item className='text-white box-shadow-neon' title={`Duration Start Time (${moment(startTime).format('dddd') !== 'Monday' ? 'Last Cashback on ' : 'Week Start'} ${moment(startTime).format('dddd')})`} value={startTime} type='time' /> */}
        {/* <StyledGrid xs={12} lg={6} item className='text-white box-shadow-neon' title='Duration End Time' value={endTime} type='time' /> */}
        {/* <StyledGrid xs={12} item className='bg-neon text-black' sx={{ justifyContent: 'center !important' }} title='The report is valid in the generating time.' /> */}
        <StyledGrid xs={12} lg={4} item className='text-neon' title='Start Balance' value={startBalance} type='numeral' description='Player balance at the start time.' />
        <StyledGrid xs={12} lg={4} item className='text-neon' title='End Balance' value={endBalance} type='numeral' description='Player balance at the end time.' />
        <StyledGrid xs={12} lg={4} item className='text-yellow' title='Open Bets Amount (at end time)' value={openBetsAmount} type='numeral' />
        <StyledGrid xs={12} lg={4} item className='text-success' title='Overal Deposit (within duration)' value={overalDepositAmount} type='numeral' description='totalDepositDepositAmount + totalTopupDepositAmount' />
        <StyledGrid xs={12} lg={4} item className='text-danger' title='Overal Withdraw (within duration)' value={overalWithdrawAmount} type='numeral' description='totalWithdrawWithdrawAmount + totalWithdrawPendingAmount + totalTopupWithdrawAmount' />
        <StyledGrid xs={12} lg={4} item className={`text-${totalWithdrawPendingCount ? 'yellow' : 'light'}`} title='Pending Withdraw (within duration)' value={totalWithdrawPendingAmount} type='numeral' />
        {/* <StyledGrid xs={12} lg={6} item className='text-success' title='Last Deposit Amount' value={lastDepositAmount} type='numeral' /> */}
        {/* <StyledGrid xs={12} lg={6} item className='text-teal' title='Last TopUp Deposit Amount (within duration)' value={lastTopupDepositAmount} type='numeral' /> */}
        {/* <StyledGrid xs={12} lg={6} item className='text-danger opacity-50' title='Last TopUp Withdraw Amount (within duration) (Preview)' value={0} type='numeral' /> */}
        {/* <StyledGrid xs={12} lg={4} item className='text-neon' title='Last TopUp Cashback Amount' value={lastTopupCashbackAmount} type='numeral' /> */}
        {type && <StyledGrid xs={12} lg={4} item className={`text-${lastTopupDailyCashbackAmount ? 'neon' : 'light'}`} title='Last TopUp Daily Cashback (within duration)' value={lastTopupDailyCashbackAmount} type='numeral' description={type == 'daily' ? 'N/A in Daily Cashback.' : moment.utc(lastTopupDailyCashbackDateTime).format('YYYY/MM/DD HH:mm:ss')} />}
        <StyledGrid xs={12} lg={4} item className={`text-${lastTopupWeeklyCashbackAmount ? 'neon' : 'light'}`} title='Last TopUp Weekly Cashback (within duration)' value={lastTopupWeeklyCashbackAmount} type='numeral' description={lastTopupWeeklyCashbackDateTime ? moment.utc(lastTopupWeeklyCashbackDateTime).format('YYYY/MM/DD HH:mm:ss') : 'No Weekly Cashback within duration'} />
        {/* <StyledGrid xs={6} item className='text-neon' title='Last TopUp Cashback Amount (within duration)' value={111111} type='numeral' /> */}
        {/* <StyledGrid xs={12} lg={4} item className={`text-${isFirstOfTheWeek ? 'warning' : 'light'}`} title={`Weekly carried over balance`} value={isFirstOfTheWeek ? startBalance : 0} type='numeral' /> */}
        <StyledGrid xs={12} lg={4} item className={`text-${playerLoss > 0 ? 'danger' : playerLoss == 0 ? 'light' : 'success'}`}
            title={`${playerLoss > 0 ? 'Player Loss (RealMoney)' : playerLoss == 0 ? 'Player Not Active' : 'Player Won'}`}
            description={`startBalance + overalDepositAmount + overalWithdrawAmount - endBalance - openBetsAmount`}
            value={-playerLoss} type='numeral' />
        <StyledGrid xs={12} lg={4} item className={`text-${playerNetLoss > 0 ? 'danger' : playerNetLoss == 0 ? 'light' : 'success'}`}
            title={`${playerNetLoss > 0 ? 'Player Net Loss (RealMoney + BonusMoney)' : playerNetLoss == 0 ? 'Player Not Active' : 'Player Net Won'}`}
            description={`startBalance + overalDepositAmount + overalWithdrawAmount - endBalance - openBetsAmount + totalTopupWeeklyCashbackAmount + totalTopupDailyCashbackAmount`}
            value={-playerNetLoss} type='numeral' />
        <StyledGrid xs={12} lg={4} item valueBorder={cashbackApplicableAmount > 0 ? 'success' : 'danger'} className={`text-${cashbackApplicableAmount > 0 ? 'success' : 'danger'}`} title={`${cashbackApplicableAmount > 0 ? '✅' : '❌'} Cashback Applicable Amount`} value={cashbackApplicableAmount} type='numeral' description='carriedOverBalance + overalDepositAmount + overalWithdrawAmount - endBalance - openBetsAmount - totalTopupDailyCashbackAmount - totalTopupWeeklyCashbackAmount' />
        {/* <StyledGrid xs={12} lg={4} item className='text-orange' title='Current Balance (Now)' value={currentBalance} type='numeral' /> */}

        <StyledGrid xs={12} lg={4} item className={rule1 ? 'text-danger' : 'text-success'} title={rule1 ? '❌ No deposit within duration' : '✅ Player has deposit within duration'} value={rule1 ? 'Not Eligible' : ''} type='text' />
        {/* <StyledGrid xs={12} lg={4} item className={`text-${profitable ? 'success' : 'danger'}`} title={`${profitable ? '✅ Profitable' : '❌ Unprofitable'} player`} value={cashbackApplicableAmount} type='numeral' /> */}
        <StyledGrid xs={12} lg={4} item className={(!balanceAndOpenCheck ? 'text-danger' : 'text-success')} title={(!balanceAndOpenCheck ? '❌ Balance & Open' : '✅ Balance & Open')} value={type == 'weekly' ? 'N/A for weekly.' : numeral(openBetsAmount + endBalance).format('$0,0.00')} type='text' description={'Balance & Open must be less than 2% CashbackApplicableAmount'} />
        <StyledGrid xs={12} lg={type == 'daily' ? 4 : 4} item valueBorder={eligible ? 'neon text-black bg-neon fw-bold' : 'danger'} className={`text-${eligible ? 'neon' : 'danger'}`} title={`${eligible ? `✅ Available ${type} Cashback ${availableCashbackPercent}%` : `❌ ${type} Cashback Not Eligible ${availableCashbackPercent}%`}`} value={availableCashbackAmount} type='numeral' description={`cashbackApplicableAmount: ${numeral(cashbackApplicableAmount).format('$0,0.00')} | Percent: ${availableCashbackPercent}%  | by cashbackApplicableAmount`} />
        {type == 'daily' && <Grid item xs={12} lg={3}>
            {(isFirstDailyCashbackInCurrentWeek ?
                <Alert className='m-2' variant="outlined" severity='success'>
                    <Typography variant='subtitle2'>
                        The first Daily Cashback within the current week.
                    </Typography>
                </Alert>
                :
                <Alert className='m-2' variant="outlined" severity='warning'>
                    <Typography variant='subtitle2'>
                        After last Daily Casback at <span className='fw-bold'>{moment(startTime).format('YYYY/MM/DD HH:mm:ss')}</span>
                    </Typography>
                </Alert>
            )}
        </Grid>
        }
        {!props.hideJson &&
            <Grid item xs={12} lg={2}>
                <Box className='text-center p-3'>
                    <ThemeButton size='small' onClick={() => setOpen(!open)} className='px-3 py-1' variant='outlined' color='neon'>
                        Show JSON
                    </ThemeButton>
                </Box>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box>
                        <ReactJson collapsed={0} name={null} style={{ padding: '0.5rem' }} theme="monokai" src={playerReportCashbackData} />
                    </Box>
                </Collapse>
            </Grid>
        }
    </Grid>
}

const StyledGrid = (props) => {
    const { title, value, sx, className, type, xs, md, description, lg, valueBorder } = props;
    const [open, setOpen] = useState(false)
    return <Grid item xs={xs || 12} md={md || 12} lg={lg || 12} className={`small d-flex flex-column justify-content-between align-items-start p-2 ${className}`} sx={{ padding: '3px', border: '1px solid #333', ...sx }}>
        <Box className=' d-flex justify-content-between align-items-center w-100'>
            <Typography variant='subtitle1' component='span' className='text-capitalize' >
                {title}
                {description && <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <div className='d-inline-block'>
                        <Tooltip
                            open={open}
                            classes={{ tooltip: ' bg-black box-shadow-neon' }}
                            TransitionComponent={Zoom}
                            placement="bottom" title={
                                <Box sx={{ fontSize: '0.5rem' }}>
                                    {description}
                                </Box>
                            }>
                            <small onClick={() => setOpen(!open)}>
                                <MDBIcon className='ms-1 text-neon box-shadow-neon- rounded-circle' icon='question-circle' />
                            </small>
                        </Tooltip>
                    </div>
                </ClickAwayListener>
                }
            </Typography>
            {type && <Typography variant='subtitle1' className={`d-flex justify-content-between align-items-center tableValue  ${valueBorder ? ('border border-' + valueBorder) : ''} px-1 rounded-3`}>
                {type == 'numeral' && <small className='ps-1'>{numeral(value).format('$0,0.00')}</small>}
                {type == 'time' && <small className='ps-1'>{moment.utc(value).format('YYYY/MM/DD HH:mm:ss z')}</small>}
                {type == 'time' && <small className='ps-1 text-neon'>{moment(value).format('YYYY/MM/DD HH:mm:ss')}</small>}
                {type == 'text' && <small className='ps-1'>{value}</small>}
            </Typography>}
        </Box>
    </Grid>
}
export default PlayerReportCashback