import { Divider, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import { useSnackbar } from 'notistack';
import numeral from 'numeral';
import React from 'react'
import { useContext } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import QRCode from 'react-qr-code';
import { Context } from '../../../context/store';
import { currencyDetail, currencyIcon } from '../../../helpers/currency';
import { getBearer } from '../../../helpers/publicHelper';
import ThemeButton from '../../../layout/components/ThemeButton';
import ThemeFormatCurrency from '../../../layout/components/ThemeFormatCurrency';

const tooltipUserWallets = (state, wallets) => {
    const { currencyList } = state.config;
    let totalBalanceInUSD = 0;
    const render = currencyList.map((currency, i) => {
        const wallet = wallets[currency];
        const { balance } = wallet;
        const rateInUSD = state.rates ? (state.rates[currency.toUpperCase()] ? state.rates[currency.toUpperCase()]['USD'] : 0) : 0;
        const amountInUSD = balance * rateInUSD;
        let result = <></>;
        if (wallet.balance > 0.000001 || currency == 'USD') {
            totalBalanceInUSD += amountInUSD;
            result = <div>
                <img className='me-1' style={{ width: '1rem' }} src={currencyIcon(currency, 'png')} />
                {currency.indexOf('USD') !== -1 ? '$' : ''}
                {numeral(wallet.balance).format(currencyDetail[currency].format)}
                <small className='px-1 fw-bold text-uppercase'>
                    {currency}
                </small>
            </div>
        }
        return <div className='pt-1' key={i} >
            {result}
        </div>
    })
    return <div className=''>
        {render}
        <div className='px-1 pt-1 text-warning'>
            <strong>Total: ${numeral(totalBalanceInUSD).format(currencyDetail['USD'].format)}</strong>
        </div>
    </div>
}

const updateWallet = (state, _id, currency, enqueueSnackbar) => {
    axios.post(state.active.host + '/superadmin/users/wallet/update', {
        currency, _id
    }, {
        headers: { 'x-auth-token': getBearer() }
    })
        .then(res => {
            res = res.data;
            if (res.success) {
                enqueueSnackbar('Updating', { variant: 'info', autoHideDuration: 2500 })
            } else {
                console.log(res.message)
            }
        })
        .catch(err => {
            console.log(err)
        })
}

const fullUserBalances = (state, wallets, _id, enqueueSnackbar) => {
    const { currencyList } = state.config;

    let totalBalanceInUSD = 0;
    const render = currencyList.map((currency, i) => {
        const wallet = wallets[currency];
        const { balance, address, topup, deposit, withdraw, totalBonus } = wallet;
        const rateInUSD = state.rates ? (state.rates[currency.toUpperCase()] ? state.rates[currency.toUpperCase()]['USD'] : 0) : 0;
        const amountInUSD = balance * rateInUSD;
        totalBalanceInUSD += amountInUSD;
        return <Grid container key={i} sx={{
            bgcolor: i % 2 ? '#191919' : '#000',
            padding: [1, 0],
            justifyContent: 'space-between',
            opacity: balance == 0 ? 0.5 : 1
        }}>
            <Grid item xs={6} md={3} className='d-flex justify-content-center justify-content-md-start flex-column flex-md-row align-items-center pb-2 pb-md-0 px-md-3'>
                <ThemeFormatCurrency iconSize={2} currency={currency} hideText />
                <Box className='d-flex flex-column  align-items-center '>
                    <Typography
                        sx={{ typography: { xs: 'h6', md: 'body1' } }}
                        component='div' className='p-0 m-0'>
                        <ThemeFormatCurrency icon={false} currency={currency} text={balance} /> <small>{currency}</small>
                        <MDBIcon onClick={() => updateWallet(state, _id, currency, enqueueSnackbar)} icon='sync' className='updateButton border border-success box-shadow-success p-1 rounded-pill text-success ms-2 cursor-pointer'></MDBIcon>
                    </Typography>
                    <Typography variant='body2' component='div' className='opacity-60'>
                        <ThemeFormatCurrency currency={currency} text={balance} icon={false} inUSD />
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={6} md={3} className='d-flex justify-content-center flex-column align-items-center py-2'>
                <Grid container>
                    <Grid item xs={3} className='text-success'>
                        Deposits
                        <small className='ps-1'>({deposit.count})</small>
                    </Grid>
                    <Grid item xs={9} className='text-end text-success'>
                        <ThemeFormatCurrency currency={currency} text={deposit.totalAmount} icon={false} inUSD />
                    </Grid>
                    <Grid item xs={3} className='text-danger'>
                        Withdraw
                        <small className='ps-1'>({withdraw.count})</small>
                    </Grid>
                    <Grid item xs={9} className='text-end text-danger'>
                        <ThemeFormatCurrency currency={currency} text={withdraw.totalAmount} icon={false} inUSD />
                    </Grid>
                    <Grid item xs={3} className='text-neon'>
                        Topup
                        <small className='ps-1'>({topup.count})</small>
                    </Grid>
                    <Grid item xs={9} className='text-end text-neon'>
                        <ThemeFormatCurrency currency={currency} text={topup.totalAmount} icon={false} inUSD />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={5} >
                <Box className='pe-md-3'>
                    {address ?
                        <small className=''>
                            <Box className='d-flex justify-content-between py-1'>
                                <ThemeFormatCurrency currency={currency} text={address} mode='address' />
                                <CopyToClipboard text={address} onCopy={() => { enqueueSnackbar('Copied.', { variant: 'success', autoHideDuration: 2500 }) }}>
                                    <ThemeButton size='small' variant='outlined' className='rounded-pill btn-sm' color='warning'>Copy Address</ThemeButton>
                                </CopyToClipboard>
                            </Box>
                        </small> : currency == 'USDT' && <Box className=''>
                            <Box className='d-flex justify-content-between py-1'>
                                <ThemeFormatCurrency currency='TRX' text={wallets['TRX'].address} mode='address' />
                                <CopyToClipboard text={wallets['TRX'].address} onCopy={() => { enqueueSnackbar('Copied.', { variant: 'success', autoHideDuration: 2500 }) }}>
                                    <ThemeButton size='small' variant='outlined' className='rounded-pill btn-sm' color='warning'>Copy Address</ThemeButton>
                                </CopyToClipboard>
                            </Box>
                            <Box className='d-flex justify-content-between py-1'>
                                <ThemeFormatCurrency currency='ETH' text={wallets['ETH'].address} mode='address' />
                                <CopyToClipboard text={wallets['ETH'].address} onCopy={() => { enqueueSnackbar('Copied.', { variant: 'success', autoHideDuration: 2500 }) }}>
                                    <ThemeButton size='small' variant='outlined' className='rounded-pill btn-sm' color='warning'>Copy Address</ThemeButton>
                                </CopyToClipboard>
                            </Box>
                        </Box>
                    }
                </Box>
            </Grid>
        </Grid>

    })
    return <div className=''>
        {/* <MDBRow className={'d-none d-md-flex mx-0 pt-1'}>
            <MDBCol size='2' className='px-0 fw-bold'>Real Balance</MDBCol>
            <MDBCol size='2' className='px-0 fw-bold'>Balance in USD / Rate</MDBCol>
            <MDBCol size='2' className='px-0 fw-bold'>TopUp (Bonus)</MDBCol>
            <MDBCol size='2' className='px-0 fw-bold'>Deposit</MDBCol>
            <MDBCol size='2' className='px-0 fw-bold'>Withdraw</MDBCol>
            <MDBCol size='2' className='px-0 fw-bold text-center'>Wallet Address</MDBCol>
        </MDBRow> */}
        {render}
        <div className='px-1 pt-1 text-warning'>
            <strong>Total: ${numeral(totalBalanceInUSD).format(currencyDetail['USD'].format)}</strong>
        </div>
    </div>
}

const UserWallets = (props) => {
    const [state, dispatch] = useContext(Context);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { _id, userWallet, all } = props
    return (
        <div>
            {all ? <>{userWallet ? fullUserBalances(state, userWallet, _id, enqueueSnackbar) : '-'}</>
                :
                <>{userWallet ? tooltipUserWallets(state, userWallet, _id) : '-'}</>
            }
        </div>
    )
}

export default UserWallets
