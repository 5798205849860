import { Avatar, Tooltip, Typography, Zoom } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { MDBIcon } from 'mdb-react-ui-kit';
import moment from 'moment';
import React, { useContext, useState } from 'react'
import { Context } from '../../context/store';
import { UserQuickViewContext } from '../../contextProviders/UserQuickViewProvider';
import { getBearer } from '../../helpers/publicHelper';
import ThemeFormatCurrency from '../../layout/components/ThemeFormatCurrency';

const UserQuickViewButton = (props) => {
    const { userId } = props;
    const [state, dispatch] = useContext(Context);

    const [values, setValues] = useState({
        open: false,
        user: {}
    })

    const getUsersFromAPI = (newValues, userId) => {
        axios.post(state.active.host + '/admin/users', {
            find: { userId }
        }, {
            headers: { 'x-auth-token': getBearer() }
        })
            .then(apiRes => {
                apiRes = apiRes.data;
                if (apiRes.success) {
                    if (apiRes.result && apiRes.result.users && apiRes.result.users.length) {
                        setValues({ ...newValues, user: apiRes.result.users[0] })
                    } else {
                        setValues({ ...newValues, user: {} })
                    }
                }
            })
    }

    const [contextUserId, setContextUserId] = useContext(UserQuickViewContext);

    return (<>

        <Tooltip
            key={userId}
            onOpen={() => getUsersFromAPI(values, userId)}
            onClose={() => setValues({ ...values, user: {} })}
            TransitionComponent={Zoom}
            classes={{ tooltip: 'bg-black box-shadow-neon px-0 m-0' }}
            placement="right" title={<Box>{!values.user.username ? <MDBIcon className='p-4' icon='spinner' spin /> : <MiniUser key={userId} {...values.user} />}</Box>}>
            <span onClick={() => setContextUserId(userId)} className='text-neon border-bottom border-neon cursor-pointer'>{userId}</span>
        </Tooltip>

        {/* <span onClick={() =>
            // setValues({ ...values, open: true })
            setUserId(userId)
        } className='text-neon border-bottom border-neon cursor-pointer'>{userId}</span> */}
        {/* <Modal
            open={values.open}
            disableScrollLock={true}
            onClose={() => setValues({ ...values, open: false })}>
            <Box className='box-shadow-neon bg-black overflow-hidden rounded-6' sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '95vw',
                height: 'calc(95vh)',
                fontSize: '0.8rem'
            }}>
                <Box onClick={() => setValues({ ...values, open: false })}
                    className='box-shadow-neon text-danger cursor-pointer bg-black align-items-center justify-content-center'
                    sx={{
                        height: '2.5rem',
                        width: '2rem',
                        position: 'absolute',
                        zIndex: 99999,
                        right: 0,
                        top: 0,
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'center'
                    }}>
                    <MDBIcon icon='times'></MDBIcon>
                </Box>
                <Box className=' overflow-scroll noScrollbar' sx={{
                    height: '95vh'
                }}>
                    {values.user.userId && <UserDetails user={{ ...values.user }} />}
                </Box>
            </Box>
        </Modal> */}
    </>
    )
}

const MiniUser = (user) => {
    const { createdAt, updatedAt, username, profile, kyc, env, wallet } = user;
    const { referrer } = profile;
    return <Box>
        <Box className='text-neon px-2 d-flex align-items-center'>
            <Avatar sx={{ width: '1.2rem', height: '1.2rem', 'i': { fontSize: '0.6rem' } }} className={`me-1 bg-transparent overflow-hidden border box-shadow-${env == 'stage' ? 'warning border-warning' : 'neon border-neon'}`}>
                {profile.avatar ?
                    <img src={profile.avatar} className='img-fluid' />
                    :
                    <MDBIcon className='text-white opacity-30' icon='user'></MDBIcon>
                }
            </Avatar>
            <span>
                {username}
            </span>
        </Box>
        <hr className='my-1' />
        <Box className='px-2'>
            <Typography className='opacity-40' variant='subtitle2'>
                Total Balance
            </Typography>
            <div className='text-white small'>
                <ThemeFormatCurrency currency='USD' text={wallet.inUSD && wallet.inUSD.balance} />
            </div>
        </Box>
        <hr className='my-1' />
        <MiniContactDetails {...kyc} />
        <Box className='px-2'>
            <Typography className='opacity-40' variant='subtitle2'>
                Last Action
            </Typography>
            {profile.country ?
                <img
                    src={`https://flagcdn.com/48x36/${profile.country.toLowerCase()}.png`}
                    height='12'
                    className='me-1'
                    alt={profile.country}></img> : <></>
            }
            <small className={'text-' + (moment(updatedAt).fromNow().indexOf('seconds') > 1 ? 'success' :
                moment(updatedAt).fromNow().indexOf('minute') > 1 ? 'success' :
                    moment(updatedAt).fromNow().indexOf('hour') > 1 ? 'info' :
                        moment(updatedAt).fromNow().indexOf('day ') > 1 ? 'info' :
                            moment(updatedAt).fromNow().indexOf('days') > 1 ? 'warning' : 'danger'
            )}>{updatedAt ? moment(updatedAt).fromNow() : '-'}
            </small>
        </Box>
        <hr className='my-1' />
        <Box className='px-2'>
            <Typography className='opacity-40' variant='subtitle2'>
                Last Login
            </Typography>
            <div className='text-white opacity-80 small'>
                <MDBIcon icon='key'></MDBIcon>
                <small className='ps-1'>{profile.lastLogin ? moment(profile.lastLogin).fromNow() : '-'}</small>
            </div>
        </Box>
        <hr className='my-1' />
        <Box className='px-2'>
            <Typography className='opacity-40' variant='subtitle2'>
                Registration Date
            </Typography>
            <div className='text-white opacity-80 small'>
                <MDBIcon icon='user'></MDBIcon>
                <small className='ps-1'>{moment(createdAt).fromNow()}</small>
            </div>
        </Box>
    </Box>
}

const MiniContactDetails = (kyc) => {
    const { emails, mobiles, telegram } = kyc;
    const kycItems = [
        {
            title: 'Email',
            url: emails && emails.default && emails.default.address ? 'mailto:' + emails.default.address : '',
            icon: 'envelope',
            text: emails && emails.default && emails.default.address ? emails.default.address : '',
            active: emails && emails.default && emails.default.verified ? true : false
        },
        {
            title: 'Mobile',
            url: mobiles && mobiles.default && mobiles.default.normalized ? 'tel:' + mobiles.default.normalized : '',
            icon: 'mobile-alt',
            text: mobiles && mobiles.default && mobiles.default.normalized ? mobiles.default.normalized : '',
            active: mobiles && mobiles.default && mobiles.default.verified ? true : false
        },
        {
            title: 'Telegram',
            url: telegram && telegram.profile ? 'https://t.me/' + telegram.profile.username : '',
            icon: 'paper-plane',
            text: telegram && telegram.profile ? telegram.profile.username : '',
            active: telegram && telegram.verified ? true : false
        }
    ]

    return <Box>
        {kycItems.map((item, i) => {
            const { url, icon, active, title, text } = item;
            return <Box key={i}>
                {
                    active && <>
                        <Box className='px-2' key={i}>
                            <a target='_blank' href={url} className='text-neon small'>
                                <MDBIcon icon={icon}></MDBIcon>
                                <small className='ps-1'>{text}</small>
                            </a>
                        </Box>
                        <hr className='my-1' />
                        {title == 'Telegram' && telegram.profile && Object.keys(telegram.profile).map((key, k) => <Box className='px-2 pb-1 opacity-60' sx={{ fontSize: '0.6rem' }} key={'t' + k}>{key}: {telegram.profile[key]}</Box>)}
                    </>
                }
            </Box>
        }
        )}
    </Box>
}

export default UserQuickViewButton