import { Toolbar, Box, Tooltip, Zoom, Typography, Chip, Modal, Divider, Grid, LinearProgress, Collapse } from '@mui/material';
import { MDBIcon } from 'mdb-react-ui-kit';
import { useSnackbar } from 'notistack';
import React, { Fragment, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import 'suneditor/dist/css/suneditor.min.css';
import ResponsiveTable from '../admin/Tables/ResponsiveTable';
import ThemeButton from '../layout/components/ThemeButton';
import ThemeFormatTime from '../layout/components/ThemeFormatTime';
import { getPages, deletePage, getPageHistory } from './pagesHelper';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import ThemeInput from '../layout/components/ThemeInput';
import _ from 'lodash';
import PageTypes from './PageTypes';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { BreadCrumbContext } from '../context/store';
import PageSort from './PageSort';
import LockIcon from '@mui/icons-material/LockRounded';
const RenderType = (props) => {
    const { type } = props;
    const color = type == 'Legal' ? 'success' : 'info';
    return <Chip className='px-2 mx-1' variant='outlined' size='small' color={color} label={
        <Typography variant='subtitle2'>{type}</Typography>
    }></Chip>
}

const RenderTitle = (props) => {
    const { title } = props;
    return <Typography variant='body2' className='fw-bold ps-2'>{title}</Typography>
}

const RenderDescription = (props) => {
    const { text } = props;
    return <Typography variant='subtitle2' className='w-100 overflow-hidden px-2' sx={{
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    }}>{text}</Typography>
}

const RenderUserTime = (props) => {
    const { time, by } = props;
    const username = by?.user?.username;
    return <Box className='d-flex align-items-center'>
        <ThemeFormatTime timestamp={time} showFromNow></ThemeFormatTime>
        <Typography variant='subtitle2' className='mx-2 text-light'>
            <div>
                By
            </div>
            <span className='text-neon'>
                {username}
            </span>
        </Typography>
    </Box>
}

const RenderStatus = (props) => {
    const { status } = props;
    return <Tooltip
        enterDelay={1000}
        TransitionComponent={Zoom}
        classes={{ tooltip: 'bg-black box-shadow-neon px-0 m-0' }}
        placement="bottom" title={<Typography variant='subtitle2' className='px-2 py-0 text-capitalize'>{status}</Typography>}>
        {status == 'pending' ?
            <PendingActionsIcon className='p-1 m-1 text-yellow rounded-circle' />
            :
            status == 'published' ?
                <CloudDoneIcon className='p-1 m-1 text-success rounded-circle' />
                :
                <span>{status}</span>
        }
    </Tooltip>
}

const PageHistoryItem = (page) => {
    const { _id, title, createdAt, updatedAt, createdBy, updatedBy, status } = page;
    return <Grid container className='justify-content-between align-items-center border-bottom border-light w-100'>
        <Grid xs={12} md={4} item className='d-flex justify-content-between ps-2'>
            <Typography variant='subtitle1'>
                ID: {_id}
            </Typography>
            <Typography variant='subtitle1'>
                {title}
            </Typography>
        </Grid>
        <Grid xs={2} item className='d-flex justify-content-end'>
            <RenderUserTime by={updatedBy} time={updatedAt} />
            <Divider orientation="vertical" className='h-auto bg-white opacity-20' flexItem />
            <RenderStatus status={status} />
        </Grid>
    </Grid>
}

const RenderHistory = (props) => {
    const { _id } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        getPageHistory({ _id, enqueueSnackbar, setData });
        if (open) {
        }
    }, [open])

    return <>
        <Box className='text-center'>
            <Box className='bg-black rounded-1 m-4 box-shadow-neon overflow-hidden'>
                <Typography variant='h6' className='text-start p-2 mb-1 box-shadow-neon'>Edit History</Typography>
                {/* <Box className='text-center bg-light'>
                <PageHistoryItem {...props} />
            </Box> */}
                <Collapse in={!data.length} timeout="auto" unmountOnExit>
                    <LinearProgress color='info' />
                </Collapse>
                <Collapse in={data.length} timeout="auto" unmountOnExit>
                    <Box>
                        {data.length && data.map((page, i) => <PageHistoryItem key={i} {...page} />)}
                    </Box>
                </Collapse>
            </Box>
        </Box>
        {/* <Tooltip
            enterDelay={1000}
            TransitionComponent={Zoom}
            classes={{ tooltip: 'bg-black box-shadow-neon px-0 m-0' }}
            placement="bottom" title={<Typography variant='subtitle2' className='px-2 py-0'>Page History</Typography>}>
            <HistoryIcon onClick={() => setOpen(true)} className='p-1 m-1 text-neon cursor-pointer box-hover-shadow-neon rounded-circle' />
        </Tooltip>

        <Modal
            open={open}
            disableScrollLock={true}
            onClose={() => setOpen(false)}>
            <Box className='box-shadow-neon rounded-6 overflow-hidden bg-black' sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: ['400px', '800px'],
            }}>
                <Box className='text-center'>
                    <Box className='text-center bg-light'>
                        <PageHistoryItem {...props} />
                    </Box>
                    {data.map((page, i) => <PageHistoryItem key={i} {...page} />)}
                </Box>
            </Box>
        </Modal> */}
    </>

}

const RenderEdit = (props) => {
    const { _id } = props;
    return <Link to={`/pages/edit/?_id=${_id}`}>
        <Tooltip
            enterDelay={1000}
            TransitionComponent={Zoom}
            classes={{ tooltip: 'bg-black box-shadow-neon px-0 m-0' }}
            placement="bottom" title={<Typography variant='subtitle2' className='px-2 py-0'>Edit Page</Typography>}>
            <EditIcon className='p-1 m-1 text-warning cursor-pointer box-hover-shadow-warning rounded-circle' />
        </Tooltip>
    </Link>
}

const RenderDelete = (props) => {
    const { _id, title, type, handleSearch, deletable } = props;
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = useState(false)
    return <>
        <Tooltip
            enterDelay={1000}
            TransitionComponent={Zoom}
            classes={{ tooltip: 'bg-black box-shadow-neon px-0 m-0' }}
            placement="bottom" title={<Typography variant='subtitle2' className='px-2 py-0 text-danger'>{deletable ? 'Delete Page' : 'Protected'}</Typography>}>
            <span>
                {
                    deletable ?
                        <CloseIcon onClick={() => setOpen(true)} className='p-1 m-1 text-danger cursor-pointer box-hover-shadow-danger rounded-circle' />
                        :
                        <LockIcon className='p-1 m-1 text-light rounded-circle' />
                }
            </span>
        </Tooltip>
        <Modal
            open={open}
            disableScrollLock={true}
            onClose={() => setOpen(false)}>
            <Box className='box-shadow-neon rounded-6 overflow-hidden' sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
            }}>
                <Box className='py-4 px-3 text-center'>
                    <Typography>Are you sure you want to <span className='text-danger fw-bold'>DELETE</span> ?</Typography>
                    <Typography className='bg-dark d-inline-block py-1 my-2 px-2 rounded-2'>
                        {title} <RenderType type={type} />
                    </Typography>
                    <Box>
                        <ThemeButton color='neon' variant='outlined' className='px-3 mx-2' onClick={() => setOpen(false)}>NO</ThemeButton>
                        <ThemeButton color='danger' variant='outlined' className='px-3 mx-2' onClick={() => { deletePage({ data: { _id }, enqueueSnackbar, handleSearch }); setOpen(false) }}>YES, Delete it!</ThemeButton>
                    </Box>
                </Box>
            </Box>
        </Modal>
    </>

}

const PagesList = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = useState({
        count: -1,
        pages: []
    })

    const [filter, setFilter] = useState({
        find: { isValid: true },
        sort: { updatedAt: -1 },
        limit: 10,
        skip: 0
    })

    const columns = [
        // {
        //     id: '_id',
        //     label: '',
        //     style: { width: '0', padding: 0 },
        //     labelStyle: { width: '0', padding: 0 },
        //     format: () => { }
        // },
        //  {
        //     id: '_id',
        //     label: 'Page ID',
        //     align: 'left',
        //     labelAlign: 'left',
        //     style: { width: '6rem', padding: 0, fontSize: '0.7em', },
        //     labelStyle: { width: '6rem', padding: 0, fontSize: '0.7em', },
        //     format: _id => <small>{_id}</small>
        // }, 
        {
            id: 'type',
            label: 'Type',
            align: 'left',
            labelAlign: 'left',
            style: { width: '3rem', padding: 0, fontSize: '0.7em', },
            labelStyle: { width: '3rem', padding: 0, fontSize: '0.7em', },
            format: (type) => <RenderType type={type} />
        }, {
            id: 'title',
            label: 'Title',
            align: 'left',
            labelAlign: 'left',
            style: { width: '6rem', padding: 0, fontSize: '0.7em', },
            labelStyle: { width: '6rem', padding: 0, fontSize: '0.7em', },
            format: (title) => <RenderTitle title={title} />
        }, {
            id: 'shortDescription',
            label: 'Description',
            align: 'left',
            labelAlign: 'left',
            style: { width: '20rem', padding: 0, fontSize: '0.7em', },
            labelStyle: { width: '20rem', padding: 0, fontSize: '0.7em', },
            format: (text) => <RenderDescription text={text} />

        }, {
            id: 'all',
            label: 'Modified',
            align: 'left',
            labelAlign: 'left',
            style: { width: '6rem', padding: 0, fontSize: '0.7em', },
            labelStyle: { width: '6rem', padding: 0, fontSize: '0.7em', },
            format: (all) => <RenderUserTime by={all.updatedBy} time={all.updatedAt} />
        }, {
            id: 'all',
            label: 'Created',
            align: 'left',
            labelAlign: 'left',
            style: { width: '6rem', padding: 0, fontSize: '0.7em', },
            labelStyle: { width: '6rem', padding: 0, fontSize: '0.7em', },
            format: (all) => <RenderUserTime by={all.createdBy} time={all.createdAt} />
        }, {
            id: 'order',
            label: 'Order',
            align: 'center',
            // labelAlign: 'justify',
            style: { width: '2rem', padding: 0, fontSize: '0.7em', },
            labelStyle: { width: '2rem', padding: 0, fontSize: '0.7em', },
            format: (order) => order
        }, {
            id: 'all',
            label: 'Actions',
            align: 'center',
            labelAlign: 'center',
            style: { width: '6rem', padding: 0, fontSize: '0.7em', },
            labelStyle: { width: '6rem', padding: 0, fontSize: '0.7em', },
            format: (all) => <Box className='d-flex align-items-center justify-content-center'>
                <Divider orientation="vertical" className='h-auto bg-white opacity-20 ' flexItem />
                <RenderEdit {...all} />
                {/* <RenderHistory {...all} /> */}
                <RenderDelete {...all} handleSearch={handleSearch} />
                <Divider orientation="vertical" className='h-auto bg-white opacity-20' flexItem />
                <RenderStatus {...all} />
                <Divider orientation="vertical" className='h-auto bg-white opacity-20' flexItem />
            </Box>
        }, {
            id: 'all',
            label: 'all',
            collapse: true,
            align: 'left',
            style: {
                overflow: 'hidden',
                padding: '0 100px !important',
                whiteSpace: 'break-spaces !important'
            }, format: all => <RenderHistory {...all} />
        }
    ];

    const [searchText, setSearchText] = useState('')
    const searchTextRef = useRef();
    searchTextRef.current = searchText;

    const [type, setType] = useState('');
    const typeRef = useRef();
    typeRef.current = type;

    const [sort, setSort] = useState('updatedAt');
    const sortRef = useRef();
    sortRef.current = sort;

    const handleSearch = () => {
        getPages({
            filter: {
                ...filter,
                find: {
                    ...filter.find,
                    ...(searchTextRef.current && {
                        $or: [
                            { title: { $regex: searchTextRef.current, $options: "i" } },
                            { type: { $regex: searchTextRef.current, $options: "i" } }
                        ]
                    }),
                    ...(typeRef.current && {
                        type: typeRef.current
                    })
                },
                sort: {
                    [sortRef.current]: -1
                }
            },
            setData,
            enqueueSnackbar
        })
    }

    const searchDebounsRef = useRef(_.debounce(handleSearch, 200));

    useEffect(() => {
        searchDebounsRef.current();
    }, [searchText, type, sort])

    // useEffect(() => {
    //     console.log('type::', type)
    //     handleSearch()
    // }, [type])


    const [contextBC, setContextBC] = useContext(BreadCrumbContext);
    useLayoutEffect(() => {
        setContextBC([
            {
                href: '/',
                text: 'Home'
            },
            {
                href: '/pages/list',
                text: 'Pages'
            }
        ])
    }, [])

    return <Box>

        <Fragment>
            {/* {
                !props.scrollFilter ?
                    <>    <AppBar sx={{
                        bgcolor: '#000 !important'
                    }}>
                        <Toolbar />
                        {!props.noToolbar && <Toolbar disableGutters variant='dense' />}
                        <Toolbar disableGutters variant='dense' sx={{
                            marginLeft: 'auto',
                            width: '100%',
                            bgcolor: '#0005',
                            paddingLeft: ['0', props.noToolbar ? 0 : '3.5rem'],
                            alignItems: 'baseline',
                        }}>
                            <TransactionFilter scrollFilter={props.scrollFilter} mobile={props.mobile} setRows={setRows} getTransactions={getTransactions} count={rows.count} setFilter={setFilter} filter={filter}></TransactionFilter>
                        </Toolbar>
                    </AppBar>
                        <Toolbar />
                    </>
                    : <></>
                <TransactionFilter scrollFilter={props.scrollFilter} mobile={props.mobile} setRows={setRows} getTransactions={getTransactions} count={rows.count} setFilter={setFilter} filter={filter}></TransactionFilter>
            } */}
            <Box className='pt-2 px-2 d-flex justify-content-between'>

                <form className='d-flex' onSubmit={e => { e.preventDefault(); handleSearch() }}>
                    <PageTypes className='p-0 me-2' value={type} onChange={e => setType(e.target.value)} style={{
                        minWidth: '10rem'
                    }} />
                    <ThemeInput
                        inputClassName={searchText ? 'box-shadow-neon border-neon rounded-3 border' : ''}
                        type='search'
                        labelClass='text-neon'
                        label='Search'
                        value={searchText}
                        onChange={e => setSearchText(e.target.value)}
                    ></ThemeInput>
                    <PageSort className='p-0 ms-2' value={sort} onChange={e => setSort(e.target.value)} style={{
                        minWidth: '10rem'
                    }} />
                    {/* <IconButton
                        onClick={handleSearch}
                        className='text-hover-neon'
                        edge="end">
                        <SearchIcon />
                    </IconButton> */}
                </form>
                <Link to='/pages/edit'>
                    <ThemeButton color='success' variant='outlined' size='small' className='px-3 py-2'>
                        <MDBIcon icon='plus-circle' className='me-1'></MDBIcon>
                        Create new page
                    </ThemeButton>
                </Link>
            </Box>
            {props.noToolbar && !props.scrollFilter && <Toolbar disableGutters />}
            <Box sx={{ my: 0 }}>
                <Box
                    className='position-fixed- bg-black'
                    sx={{
                        paddingTop: '0.5rem',
                        zIndex: 999,
                        width: ['100%', 'calc(100%  - 3.5rem)']
                    }}>
                </Box>
                {/* {!data.count && <Box className='p-5'>
                    <LinearProgress color="info" />
                </Box>} */}
                <Box className='w-100'>
                    {/* {isMobile ?
                        <TransactionListMobile transactions={rows.transactions} columns={tableColumns} />
                        : */}
                    <Collapse in={data.count !== -1} timeout="auto" unmountOnExit>
                        <ResponsiveTable collapsible rows={data.pages} columns={columns}></ResponsiveTable>
                    </Collapse>
                    <Collapse in={data.count == -1} timeout="auto" unmountOnExit>
                        <LinearProgress color='info' />
                    </Collapse>
                    {/* } */}
                </Box>
                <Box>
                    {
                        // Math.floor(rows.count / filter.limit) > 0 &&
                        // <Pagination onChange={handlePagination} className='d-inline-block' count={Math.ceil(pages.count / filter.limit)} variant="outlined" shape="rounded" />
                        // <MDBRow className='m-0'>
                        //     <MDBCol size='12' className='text-center py-4'>
                        //     </MDBCol>
                        // </MDBRow>
                    }
                </Box>
            </Box>
        </Fragment >

        {/* {pages.map((page, i) => <PageListItem setPage={setPage} {...page} />)} */}
    </Box>
}



export default PagesList