import { MDBIcon } from 'mdb-react-ui-kit';
import numeral from 'numeral';
import React from 'react'
import { currencyDetail } from '../../../helpers/currency';
import _, { subtract } from 'lodash'
import ThemeFormatCurrency from '../../../layout/components/ThemeFormatCurrency';
import { Box } from '@mui/system';

const RenderPL = (props) => {
  const { amount, status, service, meta, type, subtype, currency } = props;
  const { amountInUSD } = meta;
  let cls = '';
  if (type == 'deposit') cls = 'success';
  if (type == 'withdraw') cls = 'danger';
  if (type == 'bet') cls = 'neon';
  if (type == 'topup') {
    const color = subtype == 'bonus' ? 'yellow' : subtype == 'deposit' ? 'success' : subtype == 'withdraw' ? 'danger' : (amount > 0) ? 'success' : 'danger';
    cls = color
  };

  return <Box className='w-100 fw-bold d-flex align-items-center justify-content-start '>
    {
      _.has(meta, 'bet.betAmountInUSD') ?
        <RenderBetPL {...props} />
        :
        <ThemeFormatCurrency textClassName={`text-${cls}`} currency={currency} inUSD icon={false} text={amount} />
    }
  </Box>
}

const RenderBetPL = (props) => {
  const { status, meta, subtype } = props;
  const { bet } = meta;
  const { betAmountInUSD, winAmountInUSD } = bet;
  let pl = winAmountInUSD - betAmountInUSD;
  const cls = status == 'rejected' ? 'danger ' : subtype == 'open' ? 'yellow' : pl > 0 ? 'success' : pl == 0 ? 'warning' : 'danger';

  return <small className={`text-${cls}`}  >{numeral(pl).format('$0,0.00')}</small>

}

export default RenderPL