import ResponsiveTable from '../Tables/ResponsiveTable';
import AdvancedFilter from './AdvancedFilter/AdvancedFilter';
import TransactionDetails from './TransactionDetails/TransactionDetails';
import TransactionIDs from './TransactionIDs';

import React, { useEffect, useState, useRef, Fragment, useContext, useLayoutEffect } from 'react';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import CopyToClipboard from 'react-copy-to-clipboard';
import moment from 'moment';
import axios from 'axios';
import { AppBar, Box, ClickAwayListener, Container, CssBaseline, Grid, Modal, Pagination, Toolbar, Tooltip, Typography, Zoom } from '@mui/material';
import numeral from 'numeral';
import ReactJson from 'react-json-view';
// import UserTransactionDigitain2 from '../Users/UserDetails/UserTransactionDigitain2';
import { currencyDetail, currencyIcon, formatAmountCurrency } from '../../helpers/currency';
import ThemeButton from '../../layout/components/ThemeButton';
import TransactionFilter from './TransactionFilter';
import { useSnackbar } from 'notistack';
import { getBlockchainColor, getBlockchainUrl, getColorByBetState } from '../../helpers/adminHelpers';
import { getBearer } from '../../helpers/publicHelper';
import _ from 'lodash';
import ThemeFormatCurrency from '../../layout/components/ThemeFormatCurrency';
import { BreadCrumbContext } from '../../context/store';
import { tblFormatId, tblFormatTime, tblFormatUserId } from '../../helpers/formatHelpers';
import $ from 'jquery';
import UserQuickViewButton from '../Users/UserQuickViewButton';
import TransactionMeta from './TransactionMeta';
import { BrowserView, MobileView, useDeviceSelectors } from 'react-device-detect';
import TransactionListMobile from './TransactionListMobile';
import useMediaQuery from '@mui/material/useMediaQuery';

const FormatSubType = (all) => {
    const { amount, type, subtype, meta, status, env } = all;
    return <Box>
        {subtype}
    </Box>

}

const FormatType = (all) => {
    const { amount, type, meta, status, env } = all;

    if (status == 'rejected') {
        return <div className={`small text-danger text-shadow-danger border border-danger rounded-1 px-1 text-capitalize`}>
            <small className='d-block'>{type}</small>
            <small className='d-block fw-bold'>{status}</small>
        </div>
    }
    if (type == 'withdrawRollback') {
        return <div className={`small bg-warning rounded-1 px-1 text-capitalize text-black`}>
            <small className='d-block'>Withdraw</small>
            <small className='d-block fw-bold'>Rollback</small>
        </div>
    }

    if (type == 'withdraw') {
        return <strong className={`${env == 'stage' ? 'border border-yellow' : ''}  text-capitalize small  box-shadow-danger p-1 rounded-1 bg-danger`}>
            {type == 'ref' ? 'Referral' : type}
        </strong>
    }

    if (type == 'ref') {
        return <small className={`${env == 'stage' ? 'border-yellow' : ' border-success'} border box-shadow-success text-success border rounded-1 p-1 px-1 text-capitalize`}>Referral</small>
    }

    // Version 2.0
    if (_.has(meta, 'bet.betState')) {
        const betState = meta.bet.betState;
        const cls = betState == 'won' ? 'text-success text-shadow-success' :
            betState == 'lost' ? 'text-danger text-shadow-danger' :
                betState == 'open' ? 'text-yellow border border-yellow small rounded-1 py-1' :
                    betState == 'rollback' ? 'bg-warning box-shadow-warning text-black small rounded-1 p-1 ' :
                        betState == 'cashout' ? 'text-warning text-shadow-warning' :
                            betState == 'cashback' ? 'bg-danger rounded-1 p-1 ' : 'text-info';
        return <strong className={`${env == 'stage' ? 'border border-yellow' : ''} px-1 rounded-1 small text-capitalize ${cls}`}>{betState}</strong>
    }

    if (type == 'topup') {
        const topupColor = amount > 0 ? 'info' : 'danger';
        return <strong className={`${env == 'stage' ? 'border border-yellow' : ''} w-100 text-capitalize small box-shadow-${topupColor} p-1 rounded-1 bg-${topupColor}`}>{type}
        </strong>
    }

    return <>
        <strong className={`${env == 'stage' ? 'border border-yellow' : ''}  text-capitalize small  box-shadow-success p-1 rounded-1 bg-success`}>
            {type == 'ref' ? 'Referral' : type}
            {type && type.indexOf('Bonus') > -1 && <MDBIcon className='ps-1 text-yellow' icon='gift'></MDBIcon>}
        </strong>
    </>
}

const FormatStatus = (all) => {
    const { status } = all;
    return <small className={`${status == 'rejected' ? 'text-danger' : ''} text-capitalize`}>{status}</small>
}

const FormatService = (props) => {
    const { service } = props;
    // const { amount, type } = params;
    let baseUrl = `https://cdn.imoon.com/lobby/sliderlogos/${service}.svg`.toLowerCase();
    if (service == 'Crash') baseUrl = `https://www.imoon.com/logo/logo-mobile.svg`.toLowerCase();
    if (service == 'Telegram' || service == 'telegram') return <><MDBIcon className='text-info' icon='paper-plane' /></>
    if (service == 'Telegram' || service == 'telegram') return <><MDBIcon className='text-info' icon='paper-plane' /></>
    if (service == 'CryptoPay-API') return <><MDBIcon className='text-yellow' icon='dollar-sign' /></>

    return <><img src={baseUrl} style={{ width: '0.7rem' }} /></>
}

const FormatBetAmount = (all) => {
    const { amount, currency, blockchain, type, service, meta } = all;

    const icon = <ThemeFormatCurrency icon='png' iconSize={1} blockchain={blockchain} currency={currency} hideText />;
    // return icon;
    // Version 2.0
    if (_.has(meta, 'bet.betAmount')) {
        const { betAmountInUSD, betState } = meta.bet;
        const color = getColorByBetState(betState);
        return <div className='d-flex justify-content-center justify-content-md-between'>
            {icon}
            <span className={`text-capitalize border-bottom border-${color} text-${color}`}>
                <span style={{ marginRight: '2px' }}>$</span>
                {numeral(betAmountInUSD).format(currencyDetail['USD'].format)}
            </span>
        </div>
    }

    let betAmount = 0;

    if ((service == 'Evolution' || service == 'RedTiger' || service == 'Netent') && meta) {
        const { relatedEvoTransactions } = meta;
        if (!relatedEvoTransactions) return <span>?</span>
        if (relatedEvoTransactions.length != 1) return <span className='bg-danger'>Multi</span>
        betAmount = relatedEvoTransactions[0].transaction ? relatedEvoTransactions[0].transaction.amount : '0';
    }

    if (service == 'Digitain' && meta) {
        const { relatedSportTransactions } = meta;
        if (!relatedSportTransactions) return <span>?</span>
        if (relatedSportTransactions.length !== 1) return <span>??</span>
        if (relatedSportTransactions[0].meta) {
            betAmount = relatedSportTransactions[0].meta.Order.Bets[0].BetAmount;
        }
    }

    if (service == 'CryptoPay-API' ||
        type == 'topup' ||
        type == 'withdraw'
    ) {
        return <div className='d-flex justify-content-start'>
            {icon}
        </div>
    }

    return <div className='d-flex justify-content-between'>
        {icon}
        <span className={`text-capitalize border-bottom border-success text-success`}>
            <span style={{ marginRight: '2px' }}>$</span>
            {numeral(betAmount).format(currencyDetail[currency].format)}
        </span>
    </div>
}

const FormatWinAmount = (all) => {
    const { amount, currency, type, service, meta } = all;
    let betAmount = 0;

    // Vetsion 2
    if (_.has(meta, 'bet.winAmountInUSD')) {
        const { winAmountInUSD, betState } = meta.bet;
        const color = getColorByBetState(betState);
        return <div className='d-flex justify-content-center justify-content-md-end'>
            <span className={`text-capitalize border-bottom border-${color} text-${color}`}>
                {betState == 'open' ? '?' :
                    <><span style={{ marginRight: '2px' }}>$</span>
                        {numeral(winAmountInUSD).format(currencyDetail['USD'].format)}
                    </>}
            </span>
        </div>
    }


    if ((service == 'Evolution' || service == 'RedTiger' || service == 'Netent') && meta) {
        const { relatedEvoTransactions } = meta;
        if (!relatedEvoTransactions) return <span>?</span>
        if (relatedEvoTransactions.length != 1) return <span className='bg-danger'>Multi</span>
        betAmount = relatedEvoTransactions[0].transaction ? relatedEvoTransactions[0].transaction.amount : '0';
    }
    if (type == 'topup' || type == 'deposit' || type == 'withdraw') {
        return <></>
    }
    return <span >
        {/* <img className='me-1' style={{ width: '1rem' }} src={currencyIcon(currency, 'png')} /> */}
        <small className={`${type !== 'topup' ? 'WinAmountItem ' : ''} text-${amount > betAmount ? 'success' : 'danger'}`}>
            {numeral(amount).format(currencyDetail[currency].format)} {currency}
        </small>
    </span>
}

const FormatPL = (all) => {
    const { amount, status, service, meta, type, currency } = all;

    if (type == 'deposit') {
        let color = amount > 0 ? 'success' : 'danger';
        return <div className='d-flex w-100 justify-content-end align-items-center'>
            <strong className={`text-capitalize small p${meta.bonus ? 'x-1' : 'x-1'} rounded-3 box-shadow-${color} bg-${color}`}>
                <small className='d-block'>
                    {numeral(amount < 0.000001 ? 0 : amount).format(currencyDetail[currency].format)} <span>{currency}</span>
                </small>
                <span style={{ marginRight: '2px' }}>
                    {amount < 0 ? '-$' : '$'}
                </span>
                {numeral(meta.amountInUSD < 0.000001 ? 0 : meta.amountInUSD).format(currencyDetail['USD'].format)}
            </strong>
        </div>
    }

    if (type == 'withdraw') {
        let cls = amount > 0 ? 'box-shadow-success bg-success' : 'box-shadow-danger bg-danger';
        if (status == 'rejected') {
            cls = amount > 0 ? 'text-shadow-success border text-success border-success' : 'text-shadow-danger text-danger border border-danger';
        }
        if (type == 'withdrawRollback') {
            cls = 'text-shadow-warning border text-warning border-warning';
        }
        return <div className='d-flex w-100 justify-content-end align-items-center'>
            <strong className={`text-capitalize small p${meta.bonus ? 'x-1' : 'x-1'} rounded-3 ${cls}`}>
                <small className='d-block'>
                    {numeral(Math.abs(amount) < 0.000001 ? 0 : Math.abs(amount)).format(currencyDetail[currency].format)} <span>{currency}</span>
                </small>
                <span style={{ marginRight: '2px' }}>
                    {amount < 0 ? '-$' : '$'}
                </span>
                {numeral(Math.abs(meta.amountInUSD) < 0.000001 ? 0 : Math.abs(meta.amountInUSD)).format(currencyDetail['USD'].format)}
            </strong>
        </div>
    }

    // Version 2.0
    if (_.has(meta, 'bet.betAmountInUSD')) {
        const { betAmountInUSD, winAmountInUSD, betState } = meta.bet;
        const color = betState == 'open' ? 'yellow' : (winAmountInUSD - betAmountInUSD > 0.000001) ? 'success' : (winAmountInUSD == betAmountInUSD) ? 'warning' : 'danger';
        const PL = winAmountInUSD - betAmountInUSD;
        return <div className='d-flex justify-content-end'>
            <strong className={`text-capitalize border-bottom border-${color} text-${color}`}>
                {betState == 'open' ? '?' :
                    <><span style={{ marginRight: '2px' }}>
                        {PL < 0 ? '-$' : '$'}
                    </span>
                        {numeral(Math.abs(winAmountInUSD - betAmountInUSD) < 0.000001 ? 0 : Math.abs(winAmountInUSD - betAmountInUSD)).format(currencyDetail['USD'].format)}
                    </>}
            </strong>
        </div>
    }
    let betAmount = 0;

    if ((service == 'Evolution' || service == 'RedTiger' || service == 'Netent') && meta) {
        const { relatedEvoTransactions } = meta;
        if (!relatedEvoTransactions) return <span>?</span>
        if (relatedEvoTransactions.length != 1) return <span className='bg-danger'>Multi</span>
        betAmount = relatedEvoTransactions[0].transaction ? relatedEvoTransactions[0].transaction.amount : '0';
    }

    if (service == 'Digitain' && meta) {
        const { relatedSportTransactions } = meta;
        if (!relatedSportTransactions) return <span>?</span>
        if (relatedSportTransactions.length !== 1) return <span>??</span>
        if (relatedSportTransactions[0].meta) {
            betAmount = relatedSportTransactions[0].meta.Order.Bets[0].BetAmount;
        }
    }

    if (type == 'topup') {
        let color = amount > 0 ? 'success' : 'danger';
        return <div className='d-flex w-100 justify-content-end align-items-center'>
            <strong className={`text-capitalize small p${meta.bonus ? 'x-1' : '-1'} rounded-3 box-shadow-${color} bg-${color}`}>
                <span style={{ marginRight: '2px' }}>
                    {amount < 0 ? '-$' : '$'}
                </span>
                {numeral(Math.abs(amount)).format(currencyDetail['USD'].format)}
                {meta.bonus && <small className='d-block opacity-70'>
                    ({numeral(Math.abs(meta.bonus)).format(currencyDetail['USD'].format)})
                </small>}
            </strong>
        </div>
    }
}

const FormatOdds = (all) => {
    const { meta, type, amount } = all;
    if (type == 'deposit') return <></>

    // Version 2.0
    if (_.has(meta, 'bet.odds')) {
        const odds = meta.bet.odds;
        const cls = odds > 10 ? 'white fw-bold bg-danger' :
            odds > 8 ? 'danger' :
                odds > 5 ? 'warning' :
                    odds > 3 ? 'yellow' :
                        odds > 2 ? 'neon' :
                            odds > 1 ? 'white' :
                                'danger';
        return <span className={`rounded-3 p-1 text-${cls}`}>
            <strong style={{ paddingRight: '2px' }}>
                {numeral(odds).format('0,0.[00]')}
            </strong>
            x
        </span>
    }
}

const FormatUserBalance = (all) => {
    const { currency, meta, type } = all;

    // if (type == 'deposit') return <>
    // a
    // </>
    // Version 2.0
    if (meta && meta.history) {
        const { history } = meta;
        let formatUserBalanceRender = history.map((step, i) => {
            if (!step.walletBefore) return <>?</>
            let walletBalanceBefore = 0, walletBalanceAfter = 0;
            // Deposit 
            if ((type == 'deposit' && step.walletBefore[currency])) {
                walletBalanceBefore = step.walletBefore[currency].balance;
                walletBalanceAfter = step.walletAfter[currency].balance;
            }
            // Withdraw 
            else if ((type == 'withdraw') && step.walletBefore[currency]) {
                walletBalanceBefore = step.walletBefore[currency].balance;
                walletBalanceAfter = step.walletAfter[currency].balance;
                // Topup
            } else if ((type == 'topup' && step.walletBefore[currency])) {
                walletBalanceBefore = step.walletBefore[currency].balance;
                walletBalanceAfter = step.walletAfter[currency].balance;
                // Game
            } else if (type == 'bet' && step.walletBefore[currency]) {
                walletBalanceBefore = step.walletBefore[currency].balance;
                walletBalanceAfter = step.walletAfter[currency].balance;
                // Ref
            } else if (step.walletBefore[currency] && step.walletAfter[currency] && step.walletBefore[currency].play && step.walletAfter[currency].play) {
                walletBalanceBefore = -(step.walletBefore[currency].play.win + step.walletBefore[currency].play.amount);
                walletBalanceAfter = -(step.walletAfter[currency].play.win + step.walletAfter[currency].play.amount);
            } else {
                return <>-</>
            }

            if (walletBalanceBefore < 0.000001) walletBalanceBefore = 0;
            if (walletBalanceAfter < 0.000001) walletBalanceAfter = 0;
            return <Fragment key={i}>
                <Tooltip
                    TransitionComponent={Zoom}
                    placement="right" title={<div className='p-1 bg-black border border-neon box-shadow-neon rounded-6'>
                        <small className='text-yellow pe-2'>
                            {moment(step.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                        </small>
                        <small>
                            {step.state}
                        </small>

                    </div>}>
                    <small className={`${type == 'bet' ? 'text-gray' : 'text-success'} WinAmountItem text-center w-100 d-block px-2`}>
                        <div className='d-flex justify-content-around'>
                            <span className='text-end' style={{ flex: 2 }}>
                                {numeral(formatAmountCurrency(walletBalanceBefore, currency)).format(currencyDetail[currency].format)}
                            </span>
                            <span style={{ flex: 1 }}>
                                <MDBIcon icon='chevron-circle-right' className='text-warning mx-1'></MDBIcon>
                            </span>
                            <span className='text-start' style={{ flex: 2 }}>
                                {numeral(formatAmountCurrency(walletBalanceAfter, currency)).format(currencyDetail[currency].format)}
                            </span>
                        </div>
                    </small>
                </Tooltip>
            </Fragment>
        })
        return formatUserBalanceRender
    }

    let beforeBalance = '', afterBalance = '';
    if (_.has(meta, `walletBefore.${currency}.balance`)) {
        beforeBalance = numeral(meta.walletBefore[currency].balance).format(currencyDetail[currency].format)
    }
    if (_.has(meta, `walletAfter.${currency}.balance`)) {
        afterBalance = numeral(meta.walletAfter[currency].balance).format(currencyDetail[currency].format)
    }
    if (beforeBalance && afterBalance) {
        return <small className={`WinAmountItem text-gray text-center w-100 d-block px-1`}>
            <div className='d-flex justify-content-around'>
                <span className='text-end' style={{ flex: 2 }}>
                    {beforeBalance}
                </span>
                <span style={{ flex: 1 }}>
                    <MDBIcon icon='chevron-circle-right' className='text-warning mx-1'></MDBIcon>
                </span>
                <span className='text-start' style={{ flex: 2 }}>
                    {afterBalance}
                </span>
            </div>
            <div className='d-flex justify-content-around'>
                <span className='text-end' style={{ flex: 2 }}>
                    {beforeBalance}
                </span>
                <span style={{ flex: 1 }}>
                    <MDBIcon icon='chevron-circle-right' className='text-warning mx-1'></MDBIcon>
                </span>
                <span className='text-start' style={{ flex: 2 }}>
                    {afterBalance}
                </span>
            </div>
        </small >
    } else {
        return <></>
    }

}

const TransactionList2 = () => {
    const api = '/admin/users/transactions';



    const columns = [
        tblFormatId(),
        tblFormatTime(),
        tblFormatUserId(),
        {
            id: 'all',
            label: 'Type',
            align: 'center',
            style: { width: '5rem' },
            labelStyle: { width: '5rem' },
            format: (all) => FormatType(all)
        }, {
            id: 'all',
            label: 'SubType',
            align: 'center',
            style: { width: '5rem' },
            labelStyle: { width: '5rem' },
            format: (all) => <FormatSubType key={all._id} {...all} />
        }, {
            id: 'isValid',
            label: 'Valid',
            align: 'center',
            style: { width: '3rem' },
            labelStyle: { width: '3rem' },
            format: (isValid) => <span className='text-white'>{isValid ? <MDBIcon className='text-success' icon='check'></MDBIcon> : <MDBIcon className='text-danger' icon='times'></MDBIcon>}</span>
        }, {
            id: 'status',
            label: 'Status',
            align: 'center',
            style: { width: '3rem' },
            labelStyle: { width: '3rem' },
            format: (status) => <FormatStatus status={status} />
        }, {
            id: 'service',
            label: 'SRV',
            align: 'center',
            style: { width: '2rem' },
            labelStyle: { width: '2rem' },
            format: (service) => <FormatService service={service} />
        }, {
            id: 'all',
            label: 'Bet Amount',
            labelAlign: 'right',
            align: 'right',
            style: { width: '7rem' },
            labelStyle: { width: '7rem' },
            format: (all) => <FormatBetAmount {...all} />
        }, {
            id: 'all',
            label: 'Odds',
            labelAlign: 'center',
            align: 'center',
            style: { width: '2rem' },
            labelStyle: { width: '2rem' },
            format: (all) => FormatOdds(all)
        }, {
            id: 'all',
            label: 'Win Amount',
            labelAlign: 'right',
            align: 'right',
            style: { width: '6rem' },
            labelStyle: { width: '6rem' },
            format: (all) => <FormatWinAmount {...all} />
        }, {
            id: 'all',
            label: 'P/L',
            labelAlign: 'right',
            align: 'right',
            style: { width: '7rem' },
            labelStyle: { width: '7rem' },
            format: (all) => <FormatPL {...all} />

        }, {
            id: 'all',
            label: 'User Balance',
            labelAlign: 'center',
            align: 'left',
            style: { width: '9rem' },
            labelStyle: { width: '9rem' },
            format: (all) => FormatUserBalance(all)
        }, {
            id: 'all',
            label: 'BetID / GameId',
            labelAlign: 'left',
            align: 'left',
            style: { width: '16rem' },
            labelStyle: { width: '16rem' },
            format: (all) => <TransactionIDs {...all} />
        }, {
            id: 'all',
            label: 'Details',
            align: 'center',
            style: { width: '18rem' },
            labelStyle: { width: '18rem' },
            format: (all) => <TransactionDetails {...all} />
            // }, {
            //     id: 'all',
            //     label: 'Edit',
            //     align: 'center',
            //     style: { width: '2rem' },
            //     labelStyle: { width: '2rem' },
            //     format: (all) => EditTransaction({ all, setTxValues })
            // }, {
            //     id: 'all',
            //     label: 'all',
            //     collapse: true,
            //     align: 'left',
            //     style: {
            //         overflow: 'hidden',
            //         padding: '0 100px !important',
            //         whiteSpace: 'break-spaces !important'
            //     }, format: all => <TransactionMeta setTxValues={setTxValues} {...all} />
        }
    ];

    const [rows, setRows] = useState({
        count: 0,
        transactions: []
    });

    const fields = [
        {
            label: 'isValid',
            id: 'isValid',
            type: 'multi',
            gridSize: [6, 3, 2, 2, 1],
            options: [
                { title: 'valid', value: true },
                { title: 'invalid', value: false },
                { title: 'all', value: null },
            ],
            value: [{ title: 'valid', value: true }],
            //query: value => value, // [..]
            query: value => {
                if (value.length == 1) {
                    return value[0].value
                } else if (value.length > 1) {
                    return ({ $in: value.map(v => v.value) })
                } else {
                    return undefined
                }
            },
        },
        {
            label: 'User Id',
            id: 'userId',
            type: 'text',
            value: '',
            gridSize: [6, 3, 2, 1, 1],
            query: value => ({ $regex: value, $options: "i" }),
        },
        {
            label: 'Referrer',
            id: 'profile.referrer.userId',
            type: 'text',
            gridSize: [6, 3, 2, 1, 1],
            value: '',
            query: value => ({ $regex: value, $options: "i" }),
        },
        {
            label: 'Provider',
            id: 'service',
            type: 'select',
            gridSize: [6, 3, 2, 2, 1],
            options: [
                { title: <strong className='text-center'>All</strong>, value: undefined },
                { title: <img height='16' src='/logo/evolution_logo.svg' />, value: 'Evolution' },
                { title: <img height='30' src='/logo/pragmatic_logo.svg' />, value: 'Pragmatic' },
                { title: <img height='16' src='/logo/everymatrix_logo.svg' />, value: 'EveryMatrix' },
                { title: <img height='24' src='/logo/netent_logo.svg' />, value: 'Netent' },
                { title: <img height='20' src='/logo/redtiger_logo.svg' />, value: 'RedTiger' },
                { title: <img height='18' src='/logo/digitain_logo.svg' />, value: 'Digitain' },
                { title: <strong>🚀 Crash</strong>, value: 'Crash' },
                { title: <strong>💰 CryptoPay</strong>, value: 'CryptoPay-API' },
                { title: <strong className='text-info m-0 p-0 fw-bold h6'><img style={{ width: '0.8rem' }} alt='telegram' src='/icons/telegram.svg' /> Telegram </strong>, value: 'Telegram' },
                { title: <img height='24' src='https://www.imoon.com/logo/logo-neon-gradient.svg' />, value: 'iMoon' },
            ],
            value: { title: 'All', value: undefined },
            query: service => service.value
        },
    ];

    const filters = {
        fields,
        find: {},
        limit: 10,
        skip: 0,
        sort: { createdAt: -1 }
    }

    return (
        <div>
            <Toolbar disableGutters variant='dense' />
            <AdvancedFilter defaultFilters={filters} setRows={setRows} api={api} />
            <ResponsiveTable collapsible rows={rows.transactions} columns={columns}></ResponsiveTable>
        </div>
    )
}

export default TransactionList2