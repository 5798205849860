import UserQuickViewButton from "../admin/Users/UserQuickViewButton";
import ThemeFormatTime from "../layout/components/ThemeFormatTime";

const tblFormatId = (params) => {
    return {
        id: '_id',
        label: '',
        style: { width: '0', padding: 0 },
        labelStyle: { width: '0', padding: 0 },
        format: () => { }
    }
}

const tblFormatTime = (params) => {
    params = params || {};
    let { id, label, ms, copy, utc } = params;
    id = id || 'createdAt';
    label = label || 'Created At';

    return {
        id,
        label,
        align: 'left',
        style: { width: ms ? '6.5rem' : '6rem', padding: 0, fontSize: '0.7em' },
        labelStyle: { width: ms ? '6.5rem' : '6rem', padding: 0, fontSize: '0.7em' },
        format: (time) => <ThemeFormatTime timestamp={time} showFromNow ms={ms} copy={copy} utc={utc}></ThemeFormatTime>
    }
}

const tblFormatUserId = () => {
    return {
        id: 'userId',
        label: 'User Id',
        align: 'center',
        style: { width: '4rem', overflow: 'hidden' },
        labelStyle: { width: '4rem', backgroundColor: '#222' },
        format: (userId) => { return userId ? <UserQuickViewButton userId={userId}></UserQuickViewButton> : '-' }
    }
}

export {
    tblFormatId,
    tblFormatTime,
    tblFormatUserId
}
