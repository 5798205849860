import { AppBar, Divider, Grid, LinearProgress, Modal, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import numeral from 'numeral';
import React, { Fragment, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { dayLong, getCashbackPercentByLostTier, getDurationName, getTimeStamps, } from '../../../helpers/reportHelper';
import ThemeCard from '../../../layout/components/ThemeCard';
import UserQuickViewButton from '../../Users/UserQuickViewButton';
import ReportGraph from '../ReportGraph';
import PlayerReportTransactions from './PlayerReportTransactions';
import PlayerReportFilter from './PlayerReportFilter';
import { MDBIcon } from 'mdb-react-ui-kit';
import ThemeButton from '../../../layout/components/ThemeButton';
import ReactJson from 'react-json-view';
import PlayerReportCashbackCard from './PlayerReportCashbackCard';
import PlayerReportTableCard from './PlayerReportTableCard';
import PlayerReportAffCard from './PlayerReportAffCard';

const PlayerReport = (props) => {
  const { noToolbar } = props;
  const [data, setData] = useState({
    waiting: false,
  });
  const [showJson, setShowJson] = useState(false)

  const [searchParams] = useSearchParams();
  const userId = props.userId || Object.fromEntries([...searchParams]).userId || '';
  const mode = props.mode || Object.fromEntries([...searchParams]).mode || '';

  const [filter, setFilter] = useState({
    userId: userId,
    from: mode ? getTimeStamps(mode).from : getTimeStamps().todayStart,
    to: mode ? getTimeStamps(mode).to : getTimeStamps().todayStart + dayLong,
  })

  const [values, setValues] = useState({
    userId: userId,
    from: mode ? getTimeStamps(mode).from : getTimeStamps().todayStart,
    to: mode ? getTimeStamps(mode).to : getTimeStamps().todayStart + dayLong,
    fromText: moment.utc(mode ? getTimeStamps(mode).from : getTimeStamps().todayStart).format('YYYY/MM/DD HH:mm:ss'),
    toText: moment.utc(mode ? getTimeStamps(mode).to : getTimeStamps().todayStart + dayLong).format('YYYY/MM/DD HH:mm:ss'),
    mode: mode || 'day',
    fromValid: true,
    toValid: true,
    fake: false
  })
  const [playerReportData, setPlayerReportData] = useState({});

  return <Box>
    <PlayerReportFilter noToolbar={noToolbar} filter={filter} setFilter={setFilter} values={values} setValues={setValues} setPlayerReportData={setPlayerReportData} />
    <Toolbar />
    {noToolbar && <Toolbar />}
    <Grid container width='100%'>
      <Grid item sx={{ minHeight: '0.2rem', width: '100%' }}>{!playerReportData.transactions && <LinearProgress color='neon' />}</Grid>
    </Grid>
    {playerReportData.transactions && <Grid container sx={{ width: ['100vw', '100%'] }}>
      <Grid item xs={12} md={4} order={[1, 0]} className='overflow-scroll noScrollbar px-md-0' sx={{ maxHeight: 'calc(100vh - 8rem)' }}>
        <PlayerReportTransactions values={values} transactions={playerReportData.transactions} count={playerReportData.count} fake={playerReportData.fake} />
      </Grid>
      <Grid item xs={12} md={8} className='overflow-hidden'>
        <Box className='my-2 mx-2'
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            border: (theme) => `1px solid ${theme.palette.divider}`,
            borderRadius: 1,
            bgcolor: 'background.paper',
            px: 1,
            '& hr': {
              height: 'auto',
              mx: 1,
            },
          }}>
          <Box className='d-flex flex-column'>
            <Typography variant='subtitle2' className='text-light'>
              <UserQuickViewButton userId={playerReportData.userId} />
            </Typography>
            <Typography variant='subtitle1' className='text-neon'>
              {[0, 1, 2, 3, 4].map(i => <MDBIcon key={i} className={playerReportData.tier > i ? 'text-neon text-shadow-neon' : 'opacity-80 text-neon fw-light'} icon='star' />)}
            </Typography>
          </Box>

          <Divider orientation="vertical" flexItem sx={{ display: 'flex', height: '100% !important' }} />
          <Box className='px-1'>
            <CashbackPolicyModal type={values.mode == 'day' ? 'daily' : 'weekly'} playerReportData={playerReportData} />
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box className='d-flex flex-column'>
            <Typography variant='subtitle2' className='text-light' component='span'>
              Total Wager:
            </Typography>
            <Typography variant='subtitle1' className='text-neon' component='span'>
              {numeral(playerReportData.wager).format('$0,0.00')}
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box className='d-flex flex-column'>
            <Typography variant='subtitle2' className='text-light' component='span'>
              Current Balance:
            </Typography>
            <Typography variant='subtitle1' className='text-warning' component='span'>
              {numeral(playerReportData.currentBalance).format('$0,0.00')}
            </Typography>
          </Box>
        </Box>
        <Box className='mb-3 px-2'>
          <PlayerReportCashbackCard playerReportData={playerReportData} type='daily' from={values.from} to={values.to} />
        </Box>
        <Box className='mb-3 px-2'>
          <PlayerReportCashbackCard playerReportData={playerReportData} type='weekly' from={values.from} to={values.to} />
        </Box>
        <Box className='mb-3 px-2'>
          <PlayerReportTableCard playerReportData={playerReportData} type='daily' from={values.from} to={values.to} />
        </Box>
        <Box className='mb-3 px-2'>
          <PlayerReportTableCard playerReportData={playerReportData} type='weekly' from={values.from} to={values.to} />
        </Box>
        <Box className='mb-3 px-2'>
          <PlayerReportTableCard playerReportData={playerReportData} type='fromTo' from={values.from} to={values.to} />
        </Box>
        <Box className='mb-3 px-2'>
          <PlayerReportAffCard playerReportData={playerReportData} type='fromTo' from={values.from} to={values.to} />
        </Box>
        <Box className='text-start p-3'>
          <ThemeButton onClick={() => setShowJson(!showJson)} variant='outlined' className='px-3 py-1' size='small'>Show JSON</ThemeButton>
          {showJson && <ReactJson collapsed={1} name={null} style={{ padding: '0.5rem' }} theme="monokai" src={playerReportData} />}
        </Box>
      </Grid>
      <Grid item xs={12} md={8} className='overflow-hidden'>
        {(data.report && data.report.length > 0) && <ThemeCard
          collapsible
          header={<Box className='text-neon fw-bold d-flex justify-content-between w-100 align-items-center' sx={{}}>
            <Box>
              <span className='text-capitalize'>{getDurationName(data.from, data.to)} Report </span>
              <UserQuickViewButton userId={userId} />
              <span className='px-3 text-success'>
                {numeral(data.currentBalance).format('$0,0.00')}
              </span>
            </Box>
            <Typography variant='subtitle1'>
              <Box className='d-flex flex-column align-items-start justify-content-between p-2 opacity-70'>
                <Box className='text-neon'>
                  <small className='px-1'>{moment.utc(data.from).format('dddd YYYY/MM/DD HH:mm:ss')} UTC</small>
                  to
                  <small className='px-1'>{moment.utc(data.to).format('dddd YYYY/MM/DD HH:mm:ss')} UTC</small>
                </Box>
                <Box className='text-light '>
                  <small className='px-1'>{moment(data.from).format('dddd YYYY/MM/DD HH:mm:ss')} {moment().format('Z')}</small>
                  to
                  <small className='px-1'>{moment(data.to).format('dddd YYYY/MM/DD HH:mm:ss')} {moment().format('Z')}</small>
                </Box>
              </Box>
            </Typography>
          </Box>}>
          <Box sx={{ height: '400px', width: '100%' }}>
            <ReportGraph filter={{
              ...filter,
              step: 'day'
            }} />
          </Box>
        </ThemeCard>}
      </Grid>
    </Grid>}
  </Box>

}


const CashbackPolicyModal = (props) => {
  const { percent, playerReportData } = props;
  const dailyCashbackApplicableAmount = playerReportData['dailyCashbackData'].cashbackApplicableAmount;
  const weeklyCashbackApplicableAmount = playerReportData['weeklyCashbackData'].cashbackApplicableAmount;
  const { tier } = playerReportData;

  const [open, setOpen] = useState(false);
  const [type, setType] = useState(props.type)

  const MinMax = [
    [0, 1000],
    [1000, 5000],
    [5001, 10000],
    [10001, 25000],
    [25001, 50000],
    [50001, 100000],
    [100001, 500000],
    [500001, 9999999999]
  ];

  const [activeIndex, setActiveIndex] = useState(0)


  useEffect(() => {
    const lossByMode = type == 'daily' ? dailyCashbackApplicableAmount : weeklyCashbackApplicableAmount;
    MinMax.forEach((element, i) => {
      console.log((lossByMode > element[0]) && (lossByMode < element[1]));
      if ((lossByMode > element[0]) && (lossByMode < element[1])) {
        setActiveIndex(i)
      }
    })
    if (lossByMode < 0) {
      setActiveIndex(0)
    }
  }, [type])


  return <>
    <small onClick={() => setOpen(true)} className='cursor-pointer text-success text-capitalize'>{percent}<MDBIcon icon='info-circle' /></small>
    <Modal
      open={open}
      disableScrollLock={true}
      onClose={() => setOpen(false)}>
      <Box className='box-shadow-neon bg-black rounded-6 overflow-scroll noScrollbar' sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minHeight: '20vh',
        maxHeight: '80vh',
        width: ['90vw', '80vw', '60vw', '40vw']
      }}>

        <Toolbar disableGutters className='w-100'>
          <Box className='box-shadow-neon h-100 w-100 align-items-center d-flex px-2 justify-content-between'>
            <Box>
              <Typography>Cashback Policy</Typography>
              <Typography variant='subtitle1' className='text-gray d-none d-md-inline-block'>
                <span className={`me-3 ${(type == 'daily' ? dailyCashbackApplicableAmount : weeklyCashbackApplicableAmount) > 0 ? '' : 'bg-danger text-white p-1 rounded-1'}`}>
                  ApplicableAmount: {numeral(type == 'daily' ? dailyCashbackApplicableAmount : weeklyCashbackApplicableAmount).format('$0,0.00')}
                </span>
                {/* <span className={`me-3 ${weeklyCashbackApplicableAmount < 0 ? 'text-danger' : ''}`}>
                  WeeklyApplicableAmount: {numeral(weeklyCashbackApplicableAmount).format('$0,0.00')}
                </span> */}
              </Typography>
            </Box>
            <Box>
              <ThemeButton className='px-3 ms-2' onClick={() => setType('daily')} variant={type == 'daily' ? '' : 'outlined'} color='neon'>Daily</ThemeButton>
              <ThemeButton className='px-3 ms-2' onClick={() => setType('weekly')} variant={type == 'weekly' ? '' : 'outlined'} color='neon'>Weekly</ThemeButton>
            </Box>
          </Box>
        </Toolbar>
        <Box className='overflow-scroll noScrollbar'>
          <Table className='w-100' size="small">
            <TableHead sx={{ '& th': { textAlign: 'end' } }}>
              <TableRow>
                <TableCell className='text-center fw-bold d-none d-md-table-cell'>Min</TableCell>
                <TableCell className='text-center fw-bold d-none d-md-table-cell'>Max</TableCell>
                <TableCell className={tier == 1 ? 'fw-bold text-shadow-neon text-neon px-0  text-center' : 'px-0 text-center'}>Tier 1</TableCell>
                <TableCell className={tier == 2 ? 'fw-bold text-shadow-neon text-neon px-0  text-center' : 'px-0 text-center'}>Tier 2</TableCell>
                <TableCell className={tier == 3 ? 'fw-bold text-shadow-neon text-neon px-0  text-center' : 'px-0 text-center'}>Tier 3</TableCell>
                <TableCell className={tier == 4 ? 'fw-bold text-shadow-neon text-neon px-0  text-center' : 'px-0 text-center'}>Tier 4</TableCell>
                <TableCell className={tier == 5 ? 'fw-bold text-shadow-neon text-neon px-0  text-center' : 'px-0 text-center'}>Tier 5</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ '& td': { textAlign: 'end' } }}>
              {
                MinMax.map((minmax, rowi) => {
                  return <Fragment key={rowi}>
                    <TableRow className='d-md-none' sx={{
                      ...(activeIndex == rowi ? {
                        '& td': {
                          bgcolor: '#222',
                          color: '#26ddff'
                        }
                      } :
                        {
                          '& td': {
                            bgcolor: '#000',
                            color: '#444'
                          }
                        }
                      )
                    }}>
                      <TableCell colSpan={2} className='text-center fw-bold'>{numeral(minmax[0]).format('$0,0')}</TableCell>
                      <TableCell colSpan={1}> To </TableCell>
                      <TableCell colSpan={2} className='text-center fw-bold'>{minmax[1] !== 9999999999 ? numeral(minmax[1]).format('$0,0') : '∞'}</TableCell>
                    </TableRow>
                    <TableRow sx={{ bgcolor: activeIndex == rowi ? '#222' : '#000' }}>
                      <TableCell sx={{ bgcolor: activeIndex == rowi ? '#222' : '#000' }} className='text-center fw-bold d-none d-md-table-cell'>{numeral(minmax[0]).format('$0,0')}</TableCell>
                      <TableCell sx={{ bgcolor: activeIndex == rowi ? '#222' : '#000' }} className='text-center fw-bold d-none d-md-table-cell'>{minmax[1] !== 9999999999 ? numeral(minmax[1]).format('$0,0') : '∞'}</TableCell>
                      {[1, 2, 3, 4, 5].map(i => <StyledTableCell key={i} eligible={(type == 'daily' && dailyCashbackApplicableAmount > 0) || (type == 'weekly' && weeklyCashbackApplicableAmount > 0)} rowi={rowi} index={i} tier={tier} type={type} minmax={minmax} activeIndex={activeIndex} />)}
                    </TableRow>
                  </Fragment>
                }
                )
              }
            </TableBody>
          </Table>
        </Box>

        <Box className='p-3 text-center w-100'>
          <ThemeButton className='px-3 ms-2' onClick={() => setOpen(false)} variant='outlined' color='neon'>Close</ThemeButton>
        </Box>
      </Box>
    </Modal>
  </>
}

const StyledTableCell = (props) => {
  const { index, tier, type, minmax, activeIndex, rowi, eligible } = props;
  const bgcolor = (((tier == index) || (rowi == activeIndex))) ? '#222' : '#000';
  return <TableCell sx={{ bgcolor }} className={(!eligible && (type == props.type) && (activeIndex == rowi) && (tier == index)) ? 'text-danger text-shadow-danger fw-bold' : (eligible && (type == props.type) && (activeIndex == rowi) && (tier == index)) ? 'text-neon text-shadow-neon fw-bold' : ''}>{getCashbackPercentByLostTier(minmax[0], index)[type]}%</TableCell>
}

export default PlayerReport