import { Grid } from '@mui/material';
import CountrySelect from './CountrySelect';
import React from 'react'
import ThemeInput from '../ThemeInput';

const ThemePhoneInput = (props) => {
    const { value, onChange, label, disabled } = props;
    const { phone, countryCode } = value;

    const handleChangePhone = (e) => {
        console.log(e.target.value)
        let inputValue = e.target.value;
        onChange({
            countryCode: countryCode,
            phone: inputValue.replace(/[^\d ]/g, "")
        })
    }

    return (
        <div className='PhoneInputWrapper rounded-pill bg-black' style={{
            position: 'relative',
        }}>
            <Grid>
                <ThemeInput
                    maxLength={11}
                    autoComplete='off'
                    inputStyle={{
                        // lineHeight: '1.4rem',
                        paddingLeft: (countryCode && (countryCode.length <= 2)) ? '4rem' : '4.5rem',
                        // borderRadius: '100px',
                        backgroundColor: '#000'
                    }}
                    onChange={handleChangePhone}
                    type='tel'
                    value={phone}
                    size='sm'
                    disabled={disabled}
                    className='text-white'
                    label={label}>
                </ThemeInput>
            </Grid>
            <CountrySelect disabled={disabled} value={countryCode ? countryCode : '1'} onChange={(e) => { onChange({ phone: phone, countryCode: e }) }}></CountrySelect>
        </div>)
}

export default ThemePhoneInput
