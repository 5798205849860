import React, { useEffect, useRef, useState } from 'react';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import ThemeInput from '../../../../layout/components/ThemeInput';
import _ from 'lodash'
import { Box, Collapse, Divider, Stack, useMediaQuery } from '@mui/material';
import ThemeSelect from '../../../../layout/components/ThemeSelect';
import numeral from 'numeral';
import { useLocation } from 'react-router-dom';
import { BrowserView, isMobile, MobileView, useDeviceData, useDeviceSelectors, useMobileOrientation } from 'react-device-detect';

const UserFilter = (props) => {
    const { filter, setFilter, count } = props;
    const { userId } = filter.find;
    const [find, setFind] = useState({
        ...filter.find,
        // userId: userId || '',
        referrer: '',
        // referrerUserId: '',
        usernameEmailPhone: '',
        walletAddress: '',
        role: '',
        email: false,
        mobile: false,
        telegram: false,
        deposit: false,
        withdraw: false,
        topup: false,
        sport: false,
        casino: false,
        crash: false,
        poker: false,
        active: false,
        // verified: true
    })

    const [sort, setSort] = useState(filter.sort)
    const [limit, setLimit] = useState(filter.limit)

    const handleSetFilter = () => {
        let findQuery = { ...find, $and: [] }
        // KYC Verified
        if (find.verified) {
            findQuery['kyc.verified'] = true
        } else {
            findQuery = _.omit(findQuery, ['verified']);
        }
        // UserId
        if (find.userId && (find.userId.trim() != '')) {
            findQuery.userId = { $regex: find.userId, $options: "i" }
        } else {
            findQuery = _.omit(findQuery, ['userId']);
        }
        // username or Email
        if (find.usernameEmailPhone && (find.usernameEmailPhone.trim() != '')) {
            findQuery.$and.push({
                $or: [
                    { username: { $regex: find.usernameEmailPhone, $options: "i" } },
                    // { 'kyc.telegram.profile.username': { $regex: find.usernameEmailPhone, $options: "i" } },
                    { 'kyc.emails.default.address': { $regex: find.usernameEmailPhone, $options: "i" } },
                    { 'kyc.mobiles.default.number': { $regex: find.usernameEmailPhone, $options: "i" } },
                    { 'kyc.mobiles.list': { $elemMatch: { number: { $regex: find.usernameEmailPhone, $options: "i" } } } },
                ]
            })
        }
        // Telegram name, username, id
        if (find.telegramProfile && (find.telegramProfile.trim() != '')) {
            findQuery.$and.push({
                $or: [
                    { 'kyc.telegram.profile.username': { $regex: find.telegramProfile, $options: "i" } },
                    { 'kyc.telegram.profile.firstname': { $regex: find.telegramProfile, $options: "i" } },
                    { 'kyc.telegram.profile.lastname': { $regex: find.telegramProfile, $options: "i" } },
                    { 'kyc.telegram.profile.telegramId': { $regex: find.telegramProfile, $options: "i" } },
                    { 'kyc.telegram.profile.language_code': { $regex: find.telegramProfile, $options: "i" } },
                ]
            })
        }
        // Role
        if (find.role && (find.role.trim() != '')) {
            findQuery.role = { $in: [find.role] }
        } else {
            findQuery = _.omit(findQuery, ['role']);
        }
        // Wallet Address
        if (find.walletAddress && (find.walletAddress.trim() != '')) {
            findQuery.$and.push({
                $or: [
                    { 'wallet.BTC.address': { $regex: find.walletAddress, $options: "i" } },
                    { 'wallet.ETH.address': { $regex: find.walletAddress, $options: "i" } },
                    { 'wallet.TRX.address': { $regex: find.walletAddress, $options: "i" } },
                    { 'wallet.DOGE.address': { $regex: find.walletAddress, $options: "i" } },
                ]
            })
        }
        // Referrer
        if (find.referrer && (find.referrer.trim() != '')) {
            findQuery.$and.push({
                'profile.referrer.userId': { $regex: find.referrer, $options: "i" }
            })
        }
        // Email
        if (find.email) {
            findQuery.$and.push({
                'kyc.emails.default.verified': true
            })
        }
        // Mobile
        if (find.mobile) {
            findQuery.$and.push({
                'kyc.mobiles.default.verified': true
            })
        }
        // Telegram 
        if (find.telegram) {
            findQuery.$and.push({
                'kyc.telegram.telegramId': { $exists: 1 }
            })
        }
        // Deposit
        if (find.deposit) {
            findQuery.$and.push({
                $or: [
                    { 'wallet.BTC.deposit.count': { $gt: 0 } },
                    { 'wallet.ETH.deposit.count': { $gt: 0 } },
                    { 'wallet.TRX.deposit.count': { $gt: 0 } },
                    { 'wallet.DOGE.deposit.count': { $gt: 0 } },
                    { 'wallet.USD.deposit.count': { $gt: 0 } },
                    { 'wallet.USDT.deposit.count': { $gt: 0 } },
                ]
            })
        }
        // Withdraw
        if (find.withdraw) {
            findQuery.$and.push({
                $or: [
                    { 'wallet.BTC.withdraw.count': { $gt: 0 } },
                    { 'wallet.ETH.withdraw.count': { $gt: 0 } },
                    { 'wallet.TRX.withdraw.count': { $gt: 0 } },
                    { 'wallet.DOGE.withdraw.count': { $gt: 0 } },
                    { 'wallet.USD.withdraw.count': { $gt: 0 } },
                    { 'wallet.USDT.withdraw.count': { $gt: 0 } },
                ]
            })
        }
        // TopUp
        if (find.topup) {
            findQuery.$and.push({
                $or: [
                    { 'wallet.BTC.topup.count': { $gt: 0 } },
                    { 'wallet.ETH.topup.count': { $gt: 0 } },
                    { 'wallet.TRX.topup.count': { $gt: 0 } },
                    { 'wallet.DOGE.topup.count': { $gt: 0 } },
                    { 'wallet.USD.topup.count': { $gt: 0 } },
                    { 'wallet.USDT.topup.count': { $gt: 0 } },
                ]
            })
        }
        // Sport
        if (find.sport) {
            findQuery.$and.push({
                'wallet.USD.play.Digitain.count': { $gt: 0 }
            })
        }
        // Casino
        if (find.casino) {
            findQuery.$and.push({
                'wallet.USD.play.Evolution.count': { $gt: 0 }
            })
        }
        // Crash
        if (find.crash) {
            findQuery.$and.push({
                'wallet.USD.play.Crash.count': { $gt: 0 }
            })
        }
        // Poker
        if (find.poker) {
            findQuery.$and.push({
                'wallet.USD.play.Poker.count': { $gt: 0 }
            })
        }
        // Active
        if (find.active) {
            findQuery.$and.push({
                'profile.active': true
            })
        }
        findQuery = _.omit(findQuery, ['telegramProfile', 'referrer', 'usernameEmailPhone', 'hasBalance', 'walletAddress', 'casino', 'crash', 'sport', 'poker', 'active', 'deposit', 'withdraw', 'topup', 'email', 'mobile', 'telegram', 'verified']);
        if (findQuery.$and.length == 0) findQuery = _.omit(findQuery, ['$and']);

        setFilter({
            ...filter,
            find: findQuery,
            sort: sort,
            limit: limit
        });
    }

    const toggleFind = (prop) => {
        setFind({
            ...find,
            [prop]: !find[prop]
        })
    }

    const UE1 = useRef();
    useEffect(() => {
        if (!UE1.current) {
            UE1.current = true;
            handleSetFilter();
            if (!find.userId) {
                window.history.pushState({ page: "another" }, "Site", "/user/list");
            }
        } else {
            handleSetFilter();
        }
    }, [find, sort, limit])

    const [showFilters, setShowFilters] = useState(false);

    const largeScreen = useMediaQuery('(min-width:1200px)');


    return (<MDBRow className='m-0'>
        <MDBCol size='12' className='mb-2 d-xl-none px-1 small d-flex align-items-center justify-content-between'>
            {<small className='border border-neon text-neon cursor-pointer rounded-pill px-2' onClick={() => setShowFilters(!showFilters)}><MDBIcon icon={`angle-${showFilters ? 'up' : 'down'}`}></MDBIcon> Filters</small>}
        </MDBCol>
        <Collapse in={showFilters || largeScreen}>
            <MDBCol size='12' style={{ backgroundColor: '#000' }}>
                <form onSubmit={e => { e.preventDefault(); handleSetFilter() }}>
                    <MDBRow className=''>
                        <MDBCol size='12' md='4' xl='1' className='py-2 py-lg-0 px-1'>
                            <ThemeInput type='search' label='User Id' value={find.userId} onChange={e => setFind({ ...find, userId: e.target.value.trim() })}></ThemeInput>
                        </MDBCol>
                        <MDBCol size='12' md='4' xl='1' className='py-2 py-lg-0 px-1'>
                            <ThemeInput type='search' label='Referrer(UserId)' value={find.referrer} onChange={e => setFind({ ...find, referrer: e.target.value.trim() })}></ThemeInput>
                        </MDBCol>
                        {/* <MDBCol size='12' md='3' xl='1' className='p-1'>
                            <ThemeInput type='search' label='Referrer User Id' value={find.referrerUserId} onChange={e => setFind({ ...find, referrerUserId: e.target.value.trim() })}></ThemeInput>
                        </MDBCol> */}
                        <MDBCol size='12' md='4' xl='2' xxl='1' className='py-2 py-lg-0 px-1'>
                            <ThemeInput type='search' label='Username/Email/Phone' value={find.usernameEmailPhone} onChange={e => setFind({ ...find, usernameEmailPhone: e.target.value.trim() })}></ThemeInput>
                        </MDBCol>
                        <MDBCol size='12' md='4' xl='2' xxl='1' className='py-2 py-lg-0 px-1'>
                            <ThemeInput type='search' label='Telegram (Any)' value={find.telegramProfile} onChange={e => setFind({ ...find, telegramProfile: e.target.value.trim() })}></ThemeInput>
                        </MDBCol>
                        <MDBCol size='12' md='4' xl='2' xxl='1' className='py-2 py-lg-0 px-1'>
                            <ThemeInput type='search' label='Wallet Address' value={find.walletAddress} onChange={e => setFind({ ...find, walletAddress: e.target.value.trim() })}></ThemeInput>
                        </MDBCol>
                        <MDBCol size='12' md='4' xl='1' xxl='1' className='py-2 py-lg-0 px-1'>
                            <ThemeSelect value={find.role} label='Role' onChange={e => setFind({ ...find, role: e.target.value })}>
                                <option value={''}>All</option>
                                <option value={'user'}>User</option>
                                <option value={'test'}>Test</option>
                                <option value={'manager'}>Manager</option>
                                <option value={'accountant'}>Accountant</option>
                                <option value={'supervisor'}>Supervisor</option>
                                <option value={'admin'}>Admin</option>
                                <option value={'superadmin'}>Super Admin</option>
                            </ThemeSelect>
                        </MDBCol>

                        <MDBCol size='12' md='5' xl='0' xxl='3' className='d-xl-none d-xxl-flex px-1 d-flex align-items-center'>
                            <MDBIcon className={`px-1 cursor-pointer text-${find.email ? 'neon' : 'white'}`} onClick={() => toggleFind('email')} icon='envelope'></MDBIcon>
                            <MDBIcon className={`px-1 cursor-pointer text-${find.mobile ? 'neon' : 'white'}`} onClick={() => toggleFind('mobile')} icon='mobile-alt'></MDBIcon>
                            <MDBIcon className={`px-1 cursor-pointer text-${find.telegram ? 'neon' : 'white'}`} onClick={() => toggleFind('telegram')} icon='paper-plane'></MDBIcon>
                            <MDBIcon className={`px-1 cursor-pointer text-${find.deposit ? 'neon' : 'white'}`} onClick={() => toggleFind('deposit')} icon='download'></MDBIcon>
                            <MDBIcon className={`px-1 cursor-pointer text-${find.withdraw ? 'neon' : 'white'}`} onClick={() => toggleFind('withdraw')} icon='upload'></MDBIcon>
                            <MDBIcon className={`px-1 cursor-pointer text-${find.topup ? 'neon' : 'white'}`} onClick={() => toggleFind('topup')} icon='donate'></MDBIcon>
                            {/* <Divider orientation="vertical" className='h-auto bg-neon mx-2 py-2 opacity-90' flexItem /> */}
                            <MDBIcon className={`px-1 cursor-pointer text-${find.casino ? 'neon' : 'white'}`} onClick={() => toggleFind('casino')} icon='dice'></MDBIcon>
                            <MDBIcon className={`px-1 cursor-pointer text-${find.sport ? 'neon' : 'white'}`} onClick={() => toggleFind('sport')} icon='futbol'></MDBIcon>
                            <MDBIcon className={`px-1 cursor-pointer text-${find.crash ? 'neon' : 'white'}`} onClick={() => toggleFind('crash')} icon='rocket'></MDBIcon>
                            <MDBIcon className={`px-1 cursor-pointer text-${find.poker ? 'neon' : 'white'}`} onClick={() => toggleFind('poker')} icon='portrait'></MDBIcon>
                            {/* <Divider orientation="vertical" className='h-auto bg-neon mx-2 py-2 opacity-90' flexItem /> */}
                            <MDBIcon title='Active' className={`px-1 cursor-pointer text-${find.active ? 'neon' : 'white'}`} onClick={() => toggleFind('active')} icon='gamepad'></MDBIcon>
                            {/* <Divider orientation="vertical" className='h-auto bg-neon mx-2 py-2 opacity-90' flexItem /> */}
                            <MDBIcon title='KYC Verified' className={`px-1 cursor-pointer text-${find.verified ? 'neon' : 'white'}`} onClick={() => toggleFind('verified')} icon='check-circle'></MDBIcon>
                        </MDBCol>
                        <MDBCol size='12' md='3' xl='1' className='py-2 py-lg-0 px-1'>
                            <ThemeSelect value={JSON.stringify(sort)} label='Sort' onChange={e => setSort(JSON.parse(e.target.value))}>
                                <option value={JSON.stringify({ updatedAt: -1 })}>{`Activity`}</option>
                                <option value={JSON.stringify({ createdAt: -1 })}>{`Registeration`}</option>
                                <option value={JSON.stringify({ 'totalBalance': -1 })}>{`Total Balance`}</option>
                                <option value={JSON.stringify({ 'wallet.USD.balance': -1 })}>{`Balance USD`}</option>
                                <option value={JSON.stringify({ 'wallet.USDT.balance': -1 })}>{`Balance USDT`}</option>
                                <option value={JSON.stringify({ 'wallet.TRX.balance': -1 })}>{`Balance TRX`}</option>
                                <option value={JSON.stringify({ 'wallet.BTC.balance': -1 })}>{`Balance BTC`}</option>
                                <option value={JSON.stringify({ 'wallet.ETH.balance': -1 })}>{`Balance ETH`}</option>
                            </ThemeSelect>
                        </MDBCol>
                        <MDBCol size='12' md='2' xl='1' className='py-2 py-lg-0 px-1'>
                            <ThemeSelect value={limit} label='Page Size' onChange={e => setLimit(parseInt(e.target.value))}>
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </ThemeSelect>
                        </MDBCol>
                        <MDBCol size='12' md='2' xl='1' className='px-1 d-flex justify-content-around align-items-center'>
                            <small className='text-neon text-shadow-neon'>
                                {numeral(count).format('0,0')}
                            </small>
                            <small className='text-neon text-shadow-neon'>
                                <MDBIcon icon='sync' spin />
                            </small>
                        </MDBCol>
                    </MDBRow>
                </form>
            </MDBCol>
        </Collapse>
    </MDBRow>
    );
}
export default UserFilter
