import './styles/App.scss';
import React, { useEffect, useContext, useRef } from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { Context } from './context/store'
import UserLogs from './admin/Users/UserDetails/UserLogs';
import UserList from './admin/Users/UserList';
import { updateStateUser } from './helpers/publicHelper';
// Settings
import SettingsSystem from './admin/Settings/SettingsSystem';
// Servers
import ServersList from './admin/servers/ServersList';
import ServersLogs from './admin/servers/ServersLogs';
import Layout from './layout/Layout';
import SportsLogs from './admin/SportGames/SportLogs';
import SupportChat from './admin/Chat/SupportChat';
import TransactionList from './admin/Transaction/TransactionList';
import { getState } from './helpers/adminHelpers';
import UserSessions from './admin/Users/UserSessions';
import Aggregate from './admin/Report/Aggregate';
import FinancialReport from './admin/Report/FinancialReport';
import UserReport from './admin/Report/UserReport';
import UsersWalletReport from './admin/Financial/UsersWalletReport/UsersWalletReport';
import WithdrawRequests from './admin/Withdraws/WithdrawRequests';
import TransferToTreasuryRequests from './admin/TransferToTreasury/TransferToTreasuryRequests';
import Financial from './admin/Financial/Financial';
import GamesList from './admin/Games/GamesList';
import CrashReport from './admin/Games/Crash/CrashReport';
import Dashboard from './admin/Dashboard';
import TreasuryWallets from './admin/Report/TreasuryWallets';
import $ from 'jquery'
import axios from 'axios';
import Logs from './admin/servers/Logs';
import TestList from './admin/Test/TestList';
import CrashTest from './admin/Test/Crash/CrashTest';
import UserDetailsPage from './admin/Users/UserDetailsPage';
import UserList2 from './admin/Users/UserList/UserList2';
import { Box } from '@mui/system';
import TransactionList2 from './admin/Transaction/TransactionList2';
import UserReport2 from './admin/Report/UserReport2';
import PlayerReport from './admin/Report/PlayerReport/PlayerReport';
import PlayerReportRender from './admin/Report/PlayerReport/PlayerReportRender';
import ThemeLayout from './layout/ThemeLayout';
import PagesList from './pages/PagesList';
import PageEdit from './pages/PageEdit';
import Theme from './layout/Theme';

const telegramWebAppConnect = (getState, state, dispatch) => {
  console.log('telegramWebAppConnect', window.Telegram);
  // $('#testarea').text('testing1')
  if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initData) {
    $('#testarea').text('window.Telegram')
    axios.post(process.env.REACT_APP_API_HOST + '/api/users/getTokenByTelegram', {
      data: window.Telegram.WebApp
    })
      .then(res => {
        res = res.data;
        const token = res.result;
        // $('#testarea').text(JSON.stringify(res))
        getState(state, dispatch, token);
      })
      .catch(err => {
        // $('#testarea').text('window.Telegram catch')
      })
  } else {
    // $('#testarea').text('No window.Telegram')
  }

}

function App() {
  const [state, dispatch] = useContext(Context);
  const stateRef = useRef();
  stateRef.current = state;
  useEffect(() => {
    const token = new URL(window.location.href).searchParams.get('token');
    console.log('token:', token)
    getState(state, dispatch, token);
    setInterval(() => {
      if (state.login) updateStateUser(state, dispatch);
      if (!stateRef.current.login) {
        telegramWebAppConnect(getState, state, dispatch)
      }
    }, 3000);


  }, [])

  return (
    <>
      {state.config.init &&
        <BrowserRouter>
          <Routes>
            <Route element={<Layout state={state} />}>
              {/* <Route path='/Transaction/List/' element={<TransactionList />} /> */}
              <Route path="/" index element={<Dashboard />} />
              <Route path="/Theme" element={<Theme />} />
              <Route path='/Transaction/List' element={<TransactionList />} />
              <Route path='/Transaction/List2' element={<TransactionList2 />} />
              <Route path='/Transaction/List/:type?/:userId?' element={<TransactionList />} />
              <Route path='/UsersLogs' exact element={<UserLogs />} />
              {/* Games */}
              {/* <Route path='/Games' exact element={Games} /> */}
              <Route path='/Games/List' exact element={<GamesList />} />
              <Route path='/Games/Crash/Report' exact element={<CrashReport />} />
              {/* Financial */}
              <Route path='/Financial' exact element={<Financial />} />
              <Route path='/Financial/Treasury' exact element={<TreasuryWallets />} />
              <Route path='/Financial/UsersWalletReport' exact element={<UsersWalletReport />} />
              {/* Users */}
              <Route path='/User/List' exact element={<UserList />} />
              <Route path='/User/List2' exact element={<UserList2 />} />
              <Route path='/User/Details' exact element={<UserDetailsPage />} />
              <Route path='/User/Sessions' exact element={<UserSessions />} />
              <Route path='/User/Sessions/:userId?' exact element={<UserSessions />} />
              {/* Users */}
              <Route path='/Withdraw/WithdrawRequests' exact element={<WithdrawRequests />} />
              <Route path='/TransferToTreasury/TransferToTreasuryRequests' exact element={<TransferToTreasuryRequests />} />
              {/* Settings */}
              <Route path='/Settings/System' exact element={<SettingsSystem />} />
              {/* Servers */}
              <Route path='/Servers/List' exact element={<ServersList />} />
              {/* Pages */}
              <Route path='/Pages/List' exact element={<PagesList />} />
              <Route path='/Pages/Edit' exact element={<PageEdit />} />
              {/* Sport Logs */}
              <Route path='/Sport/Logs' exact element={<SportsLogs />} />
              <Route path='/Report/Aggregate' exact element={<Aggregate />} />
              <Route path='/Report/FinancialReport' exact element={<FinancialReport />} />
              {/* Support */}
              <Route path='/Support/Chat' exact element={<SupportChat />} />
              <Route path='/Servers/List' exact element={<ServersList />} />
              <Route path='/Servers/Logs' exact element={<ServersLogs />} />
              <Route path='/Servers/Logs/:serverName' exact element={<ServersLogs />} />
              <Route path='/Service/Logs' exact element={<Logs />} />
              <Route path='/Service/Logs/:serverName?' exact element={<Logs />} />
              {/* Report */}
              <Route path='/Report/UserReport' exact element={<UserReport />} />
              <Route path='/Player/Report' element={<PlayerReport />} />
              <Route path='/Report/UserReport2' exact element={<UserReport2 />} />
              <Route path='/Report/UserReport/:userId?' exact element={<UserReport />} />
              {/* Test */}
              <Route path='/TestList' exact element={<TestList />} />
              <Route path='/TestList/Crash' exact element={<CrashTest />} />
            </Route>
            <Route element={<ThemeLayout state={state} />}>
              <Route path='/Player/ReportRender' element={<PlayerReportRender />} />
            </Route>
          </Routes>
        </BrowserRouter>
      }
    </>
  );
}
export default App;