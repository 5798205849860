import React, { useEffect, useState, useContext } from 'react';
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import _ from 'lodash';
import { Context } from '../../context/store';
import { convertByRates, getCryptoColor } from '../../helpers/publicHelper';
import ThemeFormatCurrency from '../../layout/components/ThemeFormatCurrency';
import ThemeChartAndDataCard from '../../layout/components/ThemeChartAndDataCard';
import numeral from 'numeral';
import QRCode from 'react-qr-code';


const RatesReview = (props) => {
    const [state, dispatch] = useContext(Context);
    const [values, setValues] = useState({
        open: false
    })
    const [chartData, setChartData] = useState({
        ready: false
    });

    const getUsersWalletTotalBalance = () => {
        setChartData({
            ready: true,
            title: 'Live Rates',
            secondaryTitles:
                [
                    // {
                    //     text: 'Total Balance',
                    //     amount: numeral(totalBalanceInUSD).format('$0,0.00'),
                    //     className: 'text-success',
                    // }
                ],
            centerChart:
            {
                // title: 'Total Balance',
                // amount: numeral(totalBalanceInUSD).format('($0.00a)'),
                // className: 'bg-success'
            },
            dataTitles: ['Currency', '$'],
            data: [{
                title: <span className='cursor-pointer'><ThemeFormatCurrency hideText currency='BTC'></ThemeFormatCurrency> 1 BTC</span>,
                color: getCryptoColor('BTC'),
                chartTitle: 'BTC',
                chartAmount: convertByRates(state, 1, 'BTC', 'USD'),
                amount1: numeral(convertByRates(state, 1, 'BTC', 'USD')).format('$0,0.00'),
                // amount2: numeral(report.BTC.blockchainBalance).format(currencyDetail['BTC'].format),
                // collapse: <DepositSection address={report.BTC.address || 'a'} currency='BTC' />
            },
            {
                title: <span className='cursor-pointer'><ThemeFormatCurrency hideText currency='ETH'></ThemeFormatCurrency> 1 ETH</span>,
                color: getCryptoColor('ETH'),
                chartTitle: 'ETH',
                chartAmount: convertByRates(state, 1, 'ETH', 'USD'),
                amount1: numeral(convertByRates(state, 1, 'ETH', 'USD')).format('$0,0.00'),
                //amount2: numeral(report.ETH.blockchainBalance).format(currencyDetail['ETH'].format),
                // collapse: <DepositSection address={report.ETH.address || 'a'} currency='ETH' />
            },
            {
                title: <span className='cursor-pointer'><ThemeFormatCurrency hideText currency='TRX'></ThemeFormatCurrency> 1 TRX</span>,
                color: getCryptoColor('TRX'),
                chartTitle: 'TRX',
                chartAmount: convertByRates(state, 1, 'TRX', 'USD'),
                amount1: numeral(convertByRates(state, 1, 'TRX', 'USD')).format('$0,0.0000'),
                // amount2: numeral(report.TRX.blockchainBalance).format(currencyDetail['TRX'].format),
                // collapse: <DepositSection address={report.TRX.address || 'a'} currency='TRX' />
            },
            {
                title: <span className='cursor-pointer'><ThemeFormatCurrency hideText currency='DOGE'></ThemeFormatCurrency> 1 DOGE</span>,
                color: getCryptoColor('DOGE'),
                chartTitle: 'DOGE',
                chartAmount: convertByRates(state, 1, 'DOGE', 'USD'),
                amount1: numeral(convertByRates(state, 1, 'DOGE', 'USD')).format('$0,0.0000'),
                //amount2: numeral(report.DOGE.blockchainBalance).format(currencyDetail['DOGE'].format),
                // collapse: <DepositSection address={report.DOGE.address || 'a'} currency='DOGE' />
            },
            {
                title: <span className='cursor-pointer'><ThemeFormatCurrency hideText currency='USDT' blockchain='TRX'></ThemeFormatCurrency> 1 USDT</span>,
                color: getCryptoColor('USDT'),
                chartTitle: 'USDT TRC-20',
                chartAmount: convertByRates(state, 1, 'USDT', 'USD'),
                amount1: numeral(convertByRates(state, 1, 'USDT', 'USD')).format('$0,0.00'),
                //amount2: numeral(report.TRX.blockchainBalanceUSDT).format(currencyDetail['USDT'].format),
                // collapse: <DepositSection address={report.TRX.address || 'a'} currency='TRX' />
            },
            {
                title: <span className='cursor-pointer'><ThemeFormatCurrency hideText currency='USDT' blockchain='ETH'></ThemeFormatCurrency> 1 USDT</span>,
                color: '#0086b9',
                chartTitle: 'USDT ERC-20',
                chartAmount: convertByRates(state, 1, 'USDT', 'USD'),
                amount1: numeral(convertByRates(state, 1, 'USDT', 'USD')).format('$0,0.00'),
                //amount2: numeral(report.ETH.blockchainBalanceUSDT).format(currencyDetail['USDT'].format),
                // collapse: <DepositSection address={report.ETH.address || 'a'} currency='ETH' />
            }]
        })
    }

    useEffect(() => {
        getUsersWalletTotalBalance();
        return () => { }
    }, [state.rates, values.open])


    return <>
        <ThemeChartAndDataCard {...chartData}></ThemeChartAndDataCard>
    </>
}

const DepositSection = (props) => {
    const { address, currency } = props;
    return <MDBRow className='m-0'>
        <MDBCol size='12' className='text-center'>
            <div className='d-inline-block p-1 rounded-2 m-2' style={{
                border: `1px solid ${getCryptoColor(currency)}99`
            }}>
                <QRCode size={100} level='L' value={address} />
            </div>
            <ThemeFormatCurrency iconClassName='me-1' mode='address' text='View on Blockchain' value={address} currency={currency}></ThemeFormatCurrency>
        </MDBCol>
    </MDBRow>
}

export default RatesReview