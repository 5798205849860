import { orange } from '@mui/material/colors';
import { Box } from '@mui/system';
import moment from 'moment'
import numeral from 'numeral';
import React, { useContext } from 'react'
import { Context } from '../../context/store';
import { getBlockchainColor, getBlockchainUrl } from '../../helpers/adminHelpers';
import { currencyFormat, currencyIcon } from '../../helpers/currency';
import { convertByRates } from '../../helpers/publicHelper';
import ThemeButton from './ThemeButton';

const ThemeFormatCurrency = (props) => {
    const [state, dispatch] = useContext(Context);

    let { value, iconSize, buttonClassName, buttonColor, icon, inUSD, ellipsis, unit, text, mode, currency, blockchain, hideText, iconClassName, textClassName, sx, lineBreak, buttonProps } = props;
    icon = icon == undefined ? 'svg' : icon;
    mode = mode || 'amount'; // transaction, address
    unit = unit !== undefined ? unit : false;
    text = text || 0;
    value = value || text;
    currency = currency || 'USD';
    blockchain = blockchain == 'USDT' ? '' : blockchain;
    iconSize = iconSize ? parseFloat(iconSize) : 0.8;
    let url;
    if (mode == 'transaction' || mode == 'address') {
        url = getBlockchainUrl(currency == 'USDT' ? blockchain : currency, mode, value)
    }
    return (
        <Box sx={{
            display: 'inline',
            lineHeight: (iconSize + 'rem'),
            '& img.icon': {
                height: (iconSize + 'rem'),
                width: (iconSize + 'rem'),
            },
            '& img.blockchainIcon': {
                height: ((iconSize * 0.5) + 'rem'),
                width: ((iconSize * 0.5) + 'rem'),
                position: 'absolute',
                bottom: 0,
                left: '50%'
            },
            ...sx
        }}>

            {
                url ?
                    <a className='px-1' target='_blank' href={url}>
                        <ThemeButton
                            className={buttonClassName}
                            style={{
                                textTransform: 'initial',
                                //  margin: '2px 0',
                                lineBreak: lineBreak ? 'anywhere' : 'auto'
                            }} color={buttonColor || getBlockchainColor(currency)} variant='outlined' size='xs'
                            {...buttonProps}>
                            {icon && <div className='position-relative d-inline-block  justify-content-start pe-1' style={{
                                width: iconSize + 'rem'
                            }}>
                                <img className={'icon ' + iconClassName} src={currencyIcon(currency, icon)} />
                                {currency == 'USDT' && blockchain && <img className='blockchainIcon' src={currencyIcon(blockchain, icon)} />}
                            </div>}
                            {text && ellipsis && <span style={{ marginLeft: '3px' }} className={textClassName}>{text.substr(0, 5) + ' ..... ' + text.substr(-5)}</span>}
                            {text && !ellipsis && <span style={{ marginLeft: '3px' }} className={textClassName}>{text}</span>}
                        </ThemeButton>
                    </a>
                    :
                    <small className=''>
                        {icon && <span className={'position-relative d-inline-block ' + ''}
                            style={{
                                width: iconSize + 'rem'
                            }}>
                            <img className={'icon ' + iconClassName} src={currencyIcon(currency, icon)} />
                            {currency == 'USDT' && blockchain && <img className='blockchainIcon' src={currencyIcon(blockchain, icon)} />}

                        </span>
                        }
                        {
                            inUSD ?
                                <span className={textClassName}>
                                    {(mode == 'amount' && !hideText) && numeral(convertByRates(state, text, currency, 'USD')).format('$0,0.00')}
                                </span>
                                :
                                <span style={{ marginLeft: '3px' }} className={textClassName}>

                                    {(mode == 'amount' && !hideText) && numeral(text).format(currencyFormat(currency))} {unit ? <small className='opacity-50'><small>{currency}</small></small> : ''}
                                </span>
                        }
                    </small>
            }
        </Box>
    )
}

export default ThemeFormatCurrency