import axios from "axios";
import _ from "lodash";
import { MDBIcon } from "mdb-react-ui-kit";
import { socket } from "../context/socket";
import { getBearer, updateRates } from "./publicHelper";


const loginSuccess = (state, dispatch, result, token) => {
    if (result.login && result?.user?.profile?.role.some(item => ['admin', 'manager', 'superadmin', 'accountant', 'graphist'].includes(item))) {

        state.setCookie('bearer', token, { path: '/' });
        dispatch({
            type: 'setAll', payload: {
                ...result
            }
        });
        updateRates(state, dispatch)
    }
}

const getState = (state, dispatch, token, callback) => {
    if (token) state.setCookie('bearer', token, { path: '/' });
    axios.post(state.active.host + '/api/state',
        {
            mode: 'mode',
            deviceInfo: JSON.stringify(_.pick(window.screen, ['width', 'height', 'colorDepth', 'pixelDepth', 'availHeight', 'availWidth']))
        },
        {
            headers: { 'x-auth-token': token || getBearer() }
        })
        .then((res) => {
            res = res.data;
            if (res.success) {
                if (res.result.login && (res.result.user.profile.role.indexOf('admin') > -1)) {
                    dispatch({
                        type: 'setAll', payload: {
                            ...res.result,
                        }
                    });
                    socket.close();
                    socket.io.opts.query = { "bearer": getBearer() };
                    socket.open();
                    updateRates(state, dispatch);
                    if (callback) {
                        console.log('callback::');
                        setTimeout(() => {
                            callback()
                        }, 500);
                    }
                } else {
                    console.log('not admin')
                    socket.close();
                    state.removeCookie('bearer', { path: '/' });
                    dispatch({
                        type: 'setAll', payload: {
                            ...res.result,
                            login: false
                        }
                    });
                }
            } else {
                socket.close();
                state.removeCookie('bearer', { path: '/' })
                dispatch({
                    type: 'setAll', payload: {
                        ...res.result,
                        login: false
                    }
                });
            }
        })
        .catch((err) => {
            socket.close();
            console.log('getState Error:', err)
        })
}

const getLogs = (state, props, setWaiting) => {
    setWaiting(true);
    const { setRows, filter, serverName } = props;
    if (serverName) {
        axios.post(process.env.REACT_APP_API_HOST + '/admin/logs/', {
            ...filter,
            serverName: serverName
        },
            {
                headers: { 'x-auth-token': getBearer() }
            })
            .then(res => {
                res = res.data;
                if (res.success) {
                    const rows = res.result.map(row => ({ ...row, all: row }));
                    setRows(rows);
                    console.log('Logs:', rows);
                } else {
                    console.error('Logs:', res.result);
                }
            })
            .catch(error => {
                console.error('Logs:', error);
            }).then(() => {
                setWaiting(false)
            })
    } else {
        // ServerName not provided
    }
}


const getServiceLogs = (state, props, setWaiting) => {
    setWaiting(true);
    const { setRows, filter } = props;
    axios.post('https://imoon-logs.azurewebsites.net/admin/logs', {
        ...filter
    },
        {
            headers: { 'x-auth-token': getBearer() }
        })
        .then(res => {
            res = res.data;
            if (res.success) {
                const rows = res.result.map(row => ({ ...row, all: row }));
                setRows(rows);
                console.log('Logs:', rows);
            } else {
                console.error('Logs:', res.result);
            }
        })
        .catch(error => {
            console.error('Logs:', error);
        }).then(() => {
            setWaiting(false)
        })
}

const getData = (props) => {
    const { find, skip, limit, sort, url, setResult, setWaiting } = props;
    if (setWaiting) setWaiting(true)
    axios.post(url,
        { find, skip, limit, sort },
        {
            headers: { 'x-auth-token': getBearer() }
        })
        .then(apiRes => {
            apiRes = apiRes.data;
            if (apiRes.success) {
                setResult(apiRes.result);
            } else {

            }
        })
        .catch(err => {
            console.log('getData::', err)
        })
        .then(() => {
            if (setWaiting) setWaiting(false)
        })
}


const roleColor = (role) => {
    // == ? 'danger' : role == 'manager' ? 'success' : role == 'support' ? 'neon' : ''}
    switch (role) {
        case 'graphist':
            return 'purple'
        case 'superGraphist':
            return 'purple'
        case 'superadmin':
            return 'danger'
        case 'admin':
            return 'success'
        case 'support':
            return 'warning'
        case 'manager':
            return 'info'
        case 'supervisor':
            return 'orange'
        case 'user':
            return 'white'
        case 'contentWriter':
            return 'pink'
        case 'test':
            return 'yellow'
        default:
            return 'neon'
    }
}
const roleIcon = (role) => {
    // == ? 'danger' : role == 'manager' ? 'success' : role == 'support' ? 'neon' : ''}
    switch (role) {
        case 'graphist':
            return <MDBIcon icon='palette' color='purple'></MDBIcon>
        case 'superGraphist':
            return <MDBIcon icon='palette' color='purple'></MDBIcon>
        case 'superadmin':
            return <MDBIcon icon='crown' color='danger'></MDBIcon>
        case 'admin':
            return <MDBIcon icon='crown' color='orange'></MDBIcon>
        case 'manager':
            return <MDBIcon icon='user-tie' color='success'></MDBIcon>
        case 'support':
            return <MDBIcon icon='comments' color='neon'></MDBIcon>
        case 'supervisor':
            return <MDBIcon icon='user-secret' color='warning'></MDBIcon>
        case 'accountant':
            return <MDBIcon icon='calculator' color='yellow'></MDBIcon>
        case 'user':
            return <MDBIcon icon='user' color='white'></MDBIcon>
        case 'test':
            return <MDBIcon icon='user' color='yellow'></MDBIcon>
        default:
            return <MDBIcon icon='user' color='grey'></MDBIcon>
    }
}

const getBlockchainUrl = (currency, type, value) => {
    let url = '';
    if (type == 'address') {
        switch (currency) {
            case 'BTC':
                url = `https://blockchair.com/bitcoin/address/${value}`
                break;
            case 'ETH':
                url = `https://etherscan.io/address/${value}`
                break;
            case 'TRX':
                url = `https://tronscan.org/#/address/${value}`
                break;
            case 'DOGE':
                url = `https://blockchair.com/dogecoin/address/${value}`
                break;
            default:
                break;
        }
    }
    if (type == 'transaction') {
        switch (currency) {
            case 'BTC':
                url = `https://blockchair.com/bitcoin/transaction/${value}`
                break;
            case 'ETH':
                url = `https://etherscan.io/tx/${value}`
                break;
            case 'TRX':
                url = `https://tronscan.org/#/transaction/${value}`
                break;
            case 'DOGE':
                url = `https://blockchair.com/dogecoin/transaction/${value}`
                break;
            default:
                break;
        }
    }
    return url
}

const getBlockchainColor = (currency) => {
    switch (currency) {
        case 'BTC':
            return 'orange';
        case 'ETH':
            return 'info';
        case 'TRX':
            return 'danger';
        case 'DOGE':
            return 'yellow';
        case 'USDT':
            return 'teal';
        case 'USD':
            return 'success';
        default:
            return 'white';
    }
}

const getColorByBetState = (betState) => {
    const color = betState == 'won' ? 'success' :
        betState == 'lost' ? 'danger' :
            betState == 'cashout' ? 'info' :
                betState == 'cashback' ? 'orange' :
                    betState == 'rollback' ? 'warning' :
                        betState == 'open' ? 'yellow' :
                            'white';
    return color;
}

const getUserByUserId = (userId, setUser) => {
    axios.post(`${process.env.REACT_APP_API_HOST}/admin/users`,
        {
            find: {
                userId
            },
            limit: 1,
            sort: {
                createdAt: 1
            }
        },
        {
            headers: { 'x-auth-token': getBearer() }
        })
        .then(res => {
            res = res.data;
            if (res.success) {
                if (res.result.users[0]) {
                    setUser(res.result.users[0]);
                } else {
                    console.log('Invalid res.result.users[0]')
                }
            }
        })
}

const getTransactions = (setData, filters, enqueueSnackbar) => {
    const { find, skip, limit, sort, mode } = filters;
    axios.post(process.env.REACT_APP_API_HOST + '/admin/users/transactions',
        {
            find, skip, limit, sort, mode
        },
        {
            headers: { 'x-auth-token': getBearer() }
        })
        .then(apiRes => {
            apiRes = apiRes.data;
            if (apiRes.success) {
                setData({
                    count: apiRes.result.count,
                    transactions: apiRes.result.transactions.map(row => ({ ...row, all: row }))
                });
            } else {
                if (enqueueSnackbar) {
                    enqueueSnackbar(apiRes.message, { variant: 'warning', autoHideDuration: 1500 })
                }
                setData({
                    count: 0,
                    transactions: []
                });
            }
        })
        .catch(err => {
            if (enqueueSnackbar) {
                enqueueSnackbar('err', { variant: 'error', autoHideDuration: 1500 })
            }
            console.log(err)
        })
}

const getFakeTransactions = (setData, filters, enqueueSnackbar) => {
    const { find, skip, limit, sort, mode } = filters;
    axios.post(process.env.REACT_APP_API_HOST + '/superadmin/fakeTransactions/list',
        {
            find, skip, limit, sort, mode
        },
        {
            headers: { 'x-auth-token': getBearer() }
        })
        .then(apiRes => {
            apiRes = apiRes.data;
            if (apiRes.success) {
                setData({
                    count: apiRes.result.count,
                    transactions: apiRes.result.transactions.map(row => ({ ...row, all: row }))
                });
            } else {
                if (enqueueSnackbar) {
                    enqueueSnackbar(apiRes.message, { variant: 'warning', autoHideDuration: 1500 })
                }
                setData({
                    count: 0,
                    transactions: []
                });
            }
        })
        .catch(err => {
            if (enqueueSnackbar) {
                enqueueSnackbar('err', { variant: 'error', autoHideDuration: 1500 })
            }
            console.log(err)
        })
}

const duplicateFakeTransaction = (_id) => {
    const promise = axios.post(process.env.REACT_APP_API_HOST + '/superadmin/fakeTransactions/duplicate',
        {
            _id
        },
        {
            headers: { 'x-auth-token': getBearer() }
        });
    return promise;
}
const editFakeTransaction = (json) => {
    const promise = axios.post(process.env.REACT_APP_API_HOST + '/superadmin/fakeTransactions/edit',
        {
            json
        },
        {
            headers: { 'x-auth-token': getBearer() }
        });
    return promise;
}

const deleteFakeTransaction = (_id) => {
    const promise = axios.post(process.env.REACT_APP_API_HOST + '/superadmin/fakeTransactions/delete',
        {
            _id
        },
        {
            headers: { 'x-auth-token': getBearer() }
        });
    return promise;
}


export {
    getLogs,
    getData,
    roleColor,
    roleIcon,
    getBlockchainUrl,
    getBlockchainColor,
    getState,
    loginSuccess,
    getServiceLogs,
    getColorByBetState,
    getUserByUserId,
    getTransactions,
    getFakeTransactions,
    duplicateFakeTransaction,
    editFakeTransaction,
    deleteFakeTransaction
}
