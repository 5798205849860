import React, { useEffect, useState, useRef, useContext, useLayoutEffect } from 'react';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import axios from 'axios';
import { Collapse, Modal, Pagination, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { currencyDetail, currencyIcon } from '../../../helpers/currency';
import ThemeButton from '../../../layout/components/ThemeButton';
import ResponsiveTable from '../../Tables/ResponsiveTable';
import { useParams } from 'react-router-dom';
import { convertByRates, getBearer } from '../../../helpers/publicHelper';
import _ from 'lodash';
import UsersWalletReportFilter from './UsersWalletReportFilter';
import numeral from 'numeral';
import { Box } from '@mui/system';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSnackbar } from 'notistack';
import ReactJson from 'react-json-view';
import ThemeFormatTime from '../../../layout/components/ThemeFormatTime';
import ThemeFormatCurrency from '../../../layout/components/ThemeFormatCurrency';
import { BreadCrumbContext, Context } from '../../../context/store';
import ThemeInput from '../../../layout/components/ThemeInput';
import { tblFormatUserId } from '../../../helpers/formatHelpers';


const FormatResources = (all) => {
    const { currency } = all;
    return <>
        {currency == 'TRX' && <FormatTRXResources key={all.selfAddress} {...all} />}
    </>
}

const x = {
    "frozen": {
        "total": 23000000,
        "balances": [
            {
                "expires": 1651816884000,
                "amount": 23000000
            }
        ]
    },
    "accountResource": {
        "frozen_balance_for_energy": {
            "frozen_balance": 706000000,
            "expire_time": 1651816740000
        }
    },
}

const FormatTRXResources = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const [values, setValues] = useState({
        openConfirmAddResource: false,
        amountToFreeze: 0,
        resource: '',
        ready: true
    })

    // const stakingDetailsFrom = props.stakingDetails ? props.stakingDetails.from : [];
    // const stakingDetailsTo = props.stakingDetails ? props.stakingDetails.to : [];

    const [values2, setValues2] = useState({
        openUnfreezeModal: false,
        ready: true,
        stakingDetails: props.stakingDetails || []
    })

    if (!props.blockchainBalanceFullData) return <>-</>
    const { blockchainBalance, blockchainBalanceUSDT, currency, updatedAt } = props;
    const { address, accountResource, bandwidth, frozen, frozenForEnergy, frozenForBandWidth, delegateFrozenForBandWidth, delegateFrozenForEnergy, acquiredDelegateFrozenForBandWidth, acquiredDelegateFrozenForEnergy } = props.blockchainBalanceFullData;
    if (!bandwidth) return <>-</>
    const { energyLimit, energyRemaining, freeNetLimit, freeNetRemaining, netRemaining, netUsed, freeNetUsed, netLimit } = bandwidth;
    const bw = {
        available: freeNetRemaining + netRemaining,
        limit: freeNetLimit + netLimit,
        frozenForItself: frozenForBandWidth / 1000000,
        expireTime: frozen.balances[0] && frozen.balances[0].expires,
        frozenFromOthers: delegateFrozenForBandWidth / 1000000,
        frozenForOthers: acquiredDelegateFrozenForBandWidth / 1000000,
        cls: (freeNetRemaining + netRemaining) > 500 ? 'text-success' : 'opacity-30'
    }
    const energy = {
        available: energyRemaining,
        limit: energyLimit,
        frozenForItself: frozenForEnergy / 1000000,
        frozenFromOthers: delegateFrozenForEnergy / 1000000,
        frozenForOthers: acquiredDelegateFrozenForEnergy / 1000000,
        expireTime: accountResource.frozen_balance_for_energy.expire_time,
        cls: energyRemaining > 17000 ? 'text-success' : 'opacity-30'
    }

    const callFreeze = (resource, freezeAmount) => {
        setValues({ ...values, ready: false });
        setTimeout(() => {
            axios.post(process.env.REACT_APP_API_HOST + '/accountant/wallets/freezeTRX',
                {
                    // "ownerAddress": "address...",
                    "receiverAddress": address,
                    "frozenBalance": freezeAmount,
                    resource
                },
                {
                    headers: { 'x-auth-token': getBearer() }
                })
                .then(res => {
                    res = res.data;
                    if (res.success) {
                        enqueueSnackbar(res.message, { variant: 'success', autoHideDuration: 2500 })
                    } else {
                        enqueueSnackbar(res.message, { variant: 'error', autoHideDuration: 2500 })
                    }
                })
                .catch(err => {
                    enqueueSnackbar('Error', { variant: 'error', autoHideDuration: 2500 })
                })
                .then(() => {
                    setValues({
                        openConfirmAddResource: false,
                        amountToFreeze: 0,
                        resource: '',
                        ready: true
                    })
                })
        }, 500);

    }

    const callUnfreeze = (expirePercentage, resource) => {
        if (expirePercentage < 100) return enqueueSnackbar('Disabled', { variant: 'success', autoHideDuration: 2500 });
        axios.post(process.env.REACT_APP_API_HOST + '/accountant/wallets/unfreezeTRX',
            {
                // "ownerAddress": "address...",
                "receiverAddress": address,
                resource
            },
            {
                headers: { 'x-auth-token': getBearer() }
            })
            .then(res => {
                res = res.data;
                if (res.success) {
                    enqueueSnackbar(res.message, { variant: 'success', autoHideDuration: 2500 })
                } else {
                    enqueueSnackbar(res.message, { variant: 'error', autoHideDuration: 2500 })
                }
            })
            .catch(err => {
                enqueueSnackbar('Error', { variant: 'error', autoHideDuration: 2500 })
            })

    }

    const unfreezeProgress = (value, expire, resource) => {
        if (!expire) return <>{value}</>
        const remainingSeconds = (expire - Date.now()) / 1000;
        const remainingMinutes = remainingSeconds / 60;
        const remainingHours = remainingMinutes / 60;
        let percentage = 100;
        if (remainingHours > 0) {
            percentage = 100 * ((72 - remainingHours) / 72)
        }
        return <div onClick={() => callUnfreeze(percentage, resource)} className='w-100 cursor-pointer'>
            {value}
            <div className='w-100' style={{ background: '#fff6' }}>
                <Box sx={{
                    width: percentage + '%',
                    backgroundColor: percentage < 100 ? '#26ddff' : '#0c5',
                    height: '2px'
                }}>
                    {/* {remainingHours.toFixed(2)} */}
                </Box>
            </div>
        </div>
    }

    return <Box className='small text-start d-flex justify-content-between align-items-center'>
        <div>
            <div className={'small'}>
                <span className={energy.cls}>
                    <MDBIcon className='pe-1' onClick={() => callFreeze('BANDWIDTH')} icon='exchange-alt'></MDBIcon>
                    <span>{bw.available}</span> / <span>{bw.limit}</span>
                </span>
                <MDBIcon onClick={() => { setValues({ ...values, resource: 'BANDWIDTH', amount: 0, openConfirmAddResource: true, ready: true }) }} className='ps-1 text-neon text-shadow-neon cursor-pointer' icon='plus-circle'></MDBIcon>
                {values2.stakingDetails.length > 0 && <MDBIcon onClick={() => { setValues2({ ...values2, openUnfreezeModal: true, ready: true }) }} className='ps-1 text-warning text-shadow-warning cursor-pointer' icon='minus-circle'></MDBIcon>}
            </div>
            <div className={'small'}>
                <span className={energy.cls}>
                    <MDBIcon className='pe-1' onClick={() => callFreeze('ENERGY')} icon='fire'></MDBIcon>
                    <span>{energy.available}</span> / <span>{energy.limit}</span>
                </span>
                <MDBIcon onClick={() => { setValues({ ...values, resource: 'ENERGY', amount: 0, openConfirmAddResource: true, ready: true }) }} className='ps-1 text-neon text-shadow-neon cursor-pointer' icon='plus-circle'></MDBIcon>
                {values2.stakingDetails.length > 0 && <MDBIcon onClick={() => { setValues2({ ...values2, openUnfreezeModal: true, ready: true }) }} className='ps-1 text-warning text-shadow-warning cursor-pointer' icon='minus-circle'></MDBIcon>}
            </div>
        </div>
        <div>
            <Table sx={{
                padding: 0,
                margin: 0,
                fontSize: '0.5rem',
                lineHeight: '0.7rem',
                textAlign: 'center',
                '& td': {
                    lineHeight: '0.7rem',
                    border: 0,
                    padding: '0 3px',
                    margin: 0,
                    fontSize: '0.5rem',
                    textAlign: 'center',
                },
                '& th': {
                    lineHeight: '0.7rem',
                    border: 0,
                    padding: '0 3px',
                    margin: 0,
                    fontSize: '0.5rem',
                    textAlign: 'center',
                }
            }}>
                <TableHead sx={{ opacity: 0.4 }}>
                    <TableRow>
                        <TableCell>
                            For Self
                        </TableCell>
                        <TableCell>
                            From Others
                        </TableCell>
                        <TableCell>
                            For Others
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell className={bw.frozenForItself > 0 ? 'text-neon' : 'opacity-30'}>
                            {unfreezeProgress(bw.frozenForItself, bw.expireTime, 'BANDWIDTH')}
                        </TableCell>
                        <TableCell className={bw.frozenForOthers > 0 ? 'text-neon' : 'opacity-30'}>
                            {bw.frozenForOthers}
                        </TableCell>
                        <TableCell className={bw.frozenFromOthers > 0 ? 'text-neon' : 'opacity-30'}>
                            {bw.frozenFromOthers}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={energy.frozenForItself > 0 ? 'text-neon' : 'opacity-30'}>
                            {unfreezeProgress(energy.frozenForItself, energy.expireTime, 'ENERGY')}
                        </TableCell>
                        <TableCell className={energy.frozenForOthers > 0 ? 'text-neon' : 'opacity-30'}>
                            {energy.frozenForOthers}
                        </TableCell>
                        <TableCell className={energy.frozenFromOthers > 0 ? 'text-neon' : 'opacity-30'}>
                            {energy.frozenFromOthers}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
        <Modal
            open={values.openConfirmAddResource}
            disableScrollLock={true}
            onClose={() => setValues({ ...values, openConfirmAddResource: false })}>
            <Box className='box-shadow-neon bg-black rounded-6 overflow-scroll noScrollbar' sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '350px',
            }}>
                <MDBRow className='m-0 overflow-hidden small'>
                    <MDBCol size='12' className='p-2 mb-2 box-shadow-neon d-flex justify-content-between align-items-center'>
                        <div>
                            Add {values.resource}
                        </div>
                        <div className='text-success text-center'>
                            <div className='text-white opacity-40' style={{ fontSize: '0.4rem' }}>Current {values.resource.toLowerCase()}</div>
                            <div style={{ fontSize: '0.7rem' }}>
                                {
                                    values.resource == 'BANDWIDTH' ? (bw.available + '/' + bw.limit) : (bw.available + '/' + bw.limit)
                                }
                            </div>

                        </div>
                    </MDBCol>
                    <MDBCol size='12' className='py-1 samll d-flex justify-content-between'>
                        <div className='small'>
                            <div className='small'>
                                <ThemeFormatCurrency currency={currency} text={blockchainBalance}></ThemeFormatCurrency>
                            </div>
                            <div className='small'>
                                <ThemeFormatCurrency currency='USDT' blockchain='TRX' text={blockchainBalanceUSDT}></ThemeFormatCurrency>
                            </div>
                            <div>
                                <div className='text-center py-2'>
                                    {/* <ThemeButton onClick={() => callTTT(currency)} color='success' className='px-2' variant='outlined'>Transfer {numeral(blockchainBalance).format(currencyDetail[currency].format)} {currency} to Treasury</ThemeButton> */}
                                </div>
                            </div>
                        </div>
                        <small>
                            <ThemeFormatTime timestamp={updatedAt} showFromNow />
                        </small>
                    </MDBCol>
                    <MDBCol size='12' className='samll d-flex justify-content-between'>
                        <ThemeInput label='Amount to Freeze (TRX)' type='number' value={values.amountToFreeze} onChange={e => setValues({ ...values, amountToFreeze: e.target.value })} ></ThemeInput>
                    </MDBCol>
                    <MDBCol size='12' className='samll text-center py-3'>
                        <ThemeButton disabled={!values.ready} onClick={() => callFreeze(values.resource, values.amountToFreeze)} color='success' className='px-3' variant='outlined'>Freeze and get {values.resource}</ThemeButton>
                    </MDBCol>
                </MDBRow>
            </Box>
        </Modal>

        <Modal
            open={values2.openUnfreezeModal}
            disableScrollLock={true}
            onClose={() => setValues2({ ...values2, openUnfreezeModal: false })}>
            <Box className='box-shadow-neon bg-black rounded-6 overflow-scroll noScrollbar' sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                maxHeight: '80vh',
                transform: 'translate(-50%, -50%)',
                width: '350px',
            }}>
                <MDBCol size='12' className='p-2 mb-2 box-shadow-neon d-flex justify-content-between align-items-center'>
                    <div>
                        Unfreeze Resouces
                    </div>
                    {/* <div className='text-success text-center'>
                        <div className='text-white opacity-40' style={{ fontSize: '0.4rem' }}>Current {values.resource.toLowerCase()}</div>
                        <div style={{ fontSize: '0.7rem' }}>
                            {
                                values.resource == 'BANDWIDTH' ? (bw.available + '/' + bw.limit) : (bw.available + '/' + bw.limit)
                            }
                        </div>
                    </div> */}
                </MDBCol>
                <MDBCol size='12' className='py-1 samll  text-center'>

                    {values2.stakingDetails.filter(x => x.from == x.to).length > 0 && <MDBRow className='m-0 overflow-hidden small text-neon text-shadow-neon py-2'><MDBCol size='12'>Resources For Itself</MDBCol></MDBRow>}
                    {values2.stakingDetails.filter(x => x.from == x.to).map((item, i) => { return <UnfreezeRow key={i} selfAddress={address} {...item} /> })}

                    {values2.stakingDetails.filter(x => x.from !== x.to && address == x.to).length > 0 && <MDBRow className='m-0 overflow-hidden small text-neon text-shadow-neon py-2'><MDBCol size='12'>Resources From Other</MDBCol></MDBRow>}
                    {values2.stakingDetails.filter(x => x.from !== x.to && address == x.to).map((item, i) => { return <UnfreezeRow key={i} selfAddress={address} {...item} /> })}

                    {values2.stakingDetails.filter(x => x.from !== x.to && address == x.from).length > 0 && <MDBRow className='m-0 overflow-hidden small text-neon text-shadow-neon py-2'><MDBCol size='12'>Resources For Other</MDBCol></MDBRow>}
                    {values2.stakingDetails.filter(x => x.from !== x.to && address == x.from).map((item, i) => { return <UnfreezeRow key={i} selfAddress={address} {...item} /> })}

                </MDBCol>
            </Box>
        </Modal>

    </Box>
}

const UnfreezeRow = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    let { from, to, amount, resource, expireTime, selfAddress } = props;
    // amount = amount || props.frozen_balance_for_energy || props.frozen_balance_for_bandwidth;
    // resource = resourceprops.frozen_balance_for_energy ? 'ENERGY' : props.frozen_balance_for_bandwidth ? 'BANDWIDTH' : '?';
    // expireTime = expireTime || props.expire_time_for_energy || props.expire_time_for_bandwidth;

    const remainingSeconds = (expireTime - Date.now()) / 1000;
    const remainingMinutes = remainingSeconds / 60;
    const remainingHours = remainingMinutes / 60;
    let percentage = 100;
    if (remainingHours > 0) {
        percentage = 100 * ((72 - remainingHours) / 72)
    }
    const unfreezeProgress = () => {
        if (!expireTime) return <>?</>

        return <div
            // onClick={() => callUnfreeze(percentage, resource)}
            className='w-100 cursor-pointer'>
            <div className='w-100' style={{ background: '#fff6' }}>
                <Box sx={{
                    width: percentage + '%',
                    backgroundColor: percentage < 100 ? '#f60' : '#0c5',
                    height: '6px'
                }}>
                    {/* {remainingHours.toFixed(2)} */}
                </Box>
            </div>
        </div>
    }

    const callUnfreeze = () => {
        enqueueSnackbar('Please Wait!', { variant: 'warning', autoHideDuration: 2500 })
        axios.post(process.env.REACT_APP_API_HOST + '/accountant/wallets/unfreezeTRX',
            {
                "ownerAddress": from,
                "receiverAddress": to,
                resource
            },
            {
                headers: { 'x-auth-token': getBearer() }
            })
            .then(res => {
                res = res.data;
                if (res.success) {
                    enqueueSnackbar(res.message, { variant: 'success', autoHideDuration: 2500 })
                } else {
                    enqueueSnackbar(res.message, { variant: 'error', autoHideDuration: 2500 })
                }
            })
            .catch(err => {
                enqueueSnackbar('Error', { variant: 'error', autoHideDuration: 2500 })
            })

    }

    return <MDBRow className='m-0 overflow-hidden small'>
        <MDBCol size='12' className='p-0 d-flex justify-content-between align-items-center'>
            <ThemeFormatCurrency buttonColor={selfAddress == from ? '' : 'white'} currency='TRX' text={from} mode='address' ellipsis />
            <MDBIcon icon='arrow-right'></MDBIcon>
            <ThemeFormatCurrency currency='TRX' buttonColor={selfAddress == to ? '' : 'white'} text={to} mode='address' ellipsis />
        </MDBCol>
        <MDBCol size='12' className=' d-flex justify-content-between align-items-center pt-2 pb-1'>
            {unfreezeProgress()}
        </MDBCol>
        <MDBCol size='12' className=' d-flex justify-content-between align-items-center border-bottom border-neon pb-2 mb-2'>
            <div className='text-start fw-bold'>
                <ThemeFormatCurrency currency='TRX' text={amount / 1000000} unit />
                <br />
                {
                    resource == 'ENERGY' ? <small className='text-warning'><MDBIcon className='me-1' icon='fire'></MDBIcon> {resource}</small>
                        :
                        resource == 'BANDWIDTH' ? <small className='text-success'><MDBIcon className='me-1' icon='exchange-alt'></MDBIcon> {resource}</small> : '?'
                }
            </div>
            <ThemeButton onClick={callUnfreeze} disabled={percentage < 100} color='neon' size='small' variant='outlined'>Unfreeze</ThemeButton>
            <div className='text-end small'>
                <ThemeFormatTime timestamp={expireTime} showFromNow />
            </div>
        </MDBCol>

    </MDBRow>
}

const FormatWalletAddress = (all) => {
    const { address, isTreasury } = all;
    return <Box className={` d-flex justify-content-start align-items-center`}>
        <ThemeFormatCurrency buttonClassName={isTreasury ? 'text-gold border-yellow box-shadow-warning' : ''} text={address} mode='address' {...all}></ThemeFormatCurrency>
        {isTreasury && <MDBIcon className='text-gold' icon='crown'></MDBIcon>}
    </Box>
}

const formatDeposit = (all) => {
    const { deposit, currency } = all;
    const { totalAmount, count, totalUSDT, countUSDT } = deposit || {
        totalAmount: 0, count: 0, totalUSDT: 0, countUSDT: 0
    };
    const amount = <span>{numeral(totalAmount).format(currencyDetail[currency].format)}<small className='ps-1'>{currency}</small></span>
    const amountUSDT = <span>{numeral(totalUSDT).format(currencyDetail['USDT'].format)}<small className='ps-1'>{'USDT'}</small></span>
    return <>
        <div className={`small text-end ${count > 0 ? 'text-success text-shadow-success fw-bold' : 'opacity-20'}`}>
            <div className='d-inline-block'>{amount}</div>
            <div className='text-gray d-inline-block'>
                <img className='mx-1' style={{ width: '0.7rem' }} src={currencyIcon(currency, 'png')} />
                {count}
                <MDBIcon icon='download' className='ps-1'></MDBIcon>
            </div>
        </div>
        <div className={`small text-end ${countUSDT > 0 ? 'text-success text-shadow-success fw-bold' : 'opacity-20'}`}>
            <div className='d-inline-block'>{amountUSDT}</div>
            <div className='text-gray d-inline-block'>
                <img className='mx-1' style={{ width: '0.7rem' }} src={currencyIcon('USDT', 'png')} />
                {countUSDT}
                <MDBIcon icon='download' className='ps-1'></MDBIcon>
            </div>
        </div>
    </>
}

const formatWithdraw = (all) => {
    const { withdraw, currency } = all;
    const { totalAmount, count, totalUSDT, countUSDT } = withdraw || {
        totalAmount: 0, count: 0, totalUSDT: 0, countUSDT: 0
    };
    const amount = <span>{numeral(totalAmount).format(currencyDetail[currency].format)}<small className='ps-1'>{currency}</small></span>
    const amountUSDT = <span>{numeral(totalUSDT).format(currencyDetail['USDT'].format)}<small className='ps-1'>{'USDT'}</small></span>
    return <>
        <div className={`small text-end ${count > 0 ? 'text-danger text-shadow-danger fw-bold' : 'opacity-20'}`}>
            <div className='d-inline-block'>{amount}</div>
            <div className='text-gray d-inline-block'>
                <img className='mx-1' style={{ width: '0.7rem' }} src={currencyIcon(currency, 'png')} />
                {count}
                <MDBIcon icon='download' className='ps-1'></MDBIcon>
            </div>
        </div>
        <div className={`small text-end ${countUSDT > 0 ? 'text-danger text-shadow-danger fw-bold' : 'opacity-20'}`}>
            <div className='d-inline-block'>{amountUSDT}</div>
            <div className='text-gray d-inline-block'>
                <img className='mx-1' style={{ width: '0.7rem' }} src={currencyIcon('USDT', 'png')} />
                {countUSDT}
                <MDBIcon icon='download' className='ps-1'></MDBIcon>
            </div>
        </div>
    </>
}

const toTreasury = (all) => {
    const { transferToTreasury, currency } = all;
    const { totalAmount, count, totalUSDT, countUSDT } = transferToTreasury || {
        totalAmount: 0, count: 0, totalUSDT: 0, countUSDT: 0
    };
    const amount = <span>{numeral(totalAmount).format(currencyDetail[currency].format)}<small className='ps-1'>{currency}</small></span>
    const amountUSDT = <span>{numeral(totalUSDT).format(currencyDetail['USDT'].format)}<small className='ps-1'>{'USDT'}</small></span>
    return <>
        <div className={`small text-end ${count > 0 ? 'text-warning text-shadow-warning fw-bold' : 'opacity-20'}`}>
            <div className='d-inline-block'>{amount}</div>
            <div className='text-gray d-inline-block'>
                <img className='mx-1' style={{ width: '0.7rem' }} src={currencyIcon(currency, 'png')} />
                {count}
                <MDBIcon icon='download' className='ps-1'></MDBIcon>
            </div>
        </div>
        <div className={`small text-end ${countUSDT > 0 ? 'text-warning text-shadow-warning fw-bold' : 'opacity-20'}`}>
            <div className='d-inline-block'>{amountUSDT}</div>
            <div className='text-gray d-inline-block'>
                <img className='mx-1' style={{ width: '0.7rem' }} src={currencyIcon('USDT', 'png')} />
                {countUSDT}
                <MDBIcon icon='download' className='ps-1'></MDBIcon>
            </div>
        </div>
    </>
}

const formatBlockchainBalance = (all) => {
    let { blockchainBalance, blockchainBalanceUSDT, withdraw, deposit, transferToTreasury, currency } = all;
    blockchainBalance = blockchainBalance || 0;
    blockchainBalanceUSDT = blockchainBalanceUSDT || 0;

    return <>
        <div className={`small text-end ${blockchainBalance > 0.01 ? 'text-neon text-shadow-neon fw-bold' : 'opacity-20'}`}>
            <div className='d-inline-block'>{numeral(blockchainBalance).format(currencyDetail[currency].format)}<span className='ps-1'>{currency}</span></div>
            <div className='text-gray d-inline-block'>
                <img className='ms-1' style={{ width: '0.7rem' }} src={currencyIcon(currency, 'png')} />
            </div>
        </div>
        <div className={`small text-end ${blockchainBalanceUSDT > 0.01 ? 'text-neon text-shadow-neon fw-bold' : 'opacity-20'}`}>
            <div className='d-inline-block'>{numeral(blockchainBalanceUSDT).format(currencyDetail[currency].format)}<span className='ps-1'>USDT</span></div>
            <div className='text-gray d-inline-block'>
                <img className='ms-1' style={{ width: '0.7rem' }} src={currencyIcon('USDT', 'png')} />
            </div>
        </div>
    </>
}

const formatTxTimestamp = (currency, transaction) => {
    if (currency != 'TRX') return '-';
    const txTimestamp = transaction.content.raw_data.timestamp;
    return <ThemeFormatTime timestamp={txTimestamp} showFromNow></ThemeFormatTime>
}

const FormatTransactions = (all, enqueueSnackbar) => {
    const [openJson, setOpenJson] = useState(false)
    const { deposit, withdraw, transferToTreasury } = all;
    const depositTransactions = deposit && deposit.transactions || {};
    const depositTransactionsUSDT = deposit && deposit.transactionsUSDT || {};
    const withdrawTransactions = withdraw && withdraw.transactions || {};
    const transferToTreasuryTransactions = transferToTreasury && transferToTreasury.transactions || {};
    // if (deposit && deposit.transactions) {
    //     // return Object.keys(deposit && deposit.transactions).length
    //     return depositTransactions.length + withdrawTransactions.length + transferToTreasuryTransactions.length
    // } else return '!'
    if (Object.keys(depositTransactions).length + Object.keys(withdrawTransactions).length + Object.keys(transferToTreasuryTransactions).length == 0) return <>No transactions object</>

    const allTransactions = {
        ...depositTransactions,
        ...depositTransactionsUSDT,
        ...withdrawTransactions,
        ...transferToTreasuryTransactions
    }

    const txArray = Object.keys(allTransactions);
    if (!txArray || (txArray.length == 0)) return <>Transactions obj is empty</>
    return <Box
        className='rounded-6 box-shadow-neon overflow-hidden'
        sx={{
            background: '#222'
        }}>
        <MDBRow className='mx-0 bg-black py-3 fw-bold text-warning'>
            <MDBCol size='1'>UpdatedAt</MDBCol>
            <MDBCol size='1'>txTimestamp</MDBCol>
            <MDBCol size='6' xl='3'>TransactionId</MDBCol>
            <MDBCol size='3' className='text-end'>Contract</MDBCol>
            <MDBCol size='1' className='text-center'>Explorer</MDBCol>
            <MDBCol size='1' className='text-center'>Type</MDBCol>
            <MDBCol size='1' className='text-end'>Amount</MDBCol>
            <MDBCol size='1' className='text-center'>Verified</MDBCol>
        </MDBRow>
        {
            txArray.map((txId, i) => {
                const transaction = allTransactions[txId];
                const { transactionId, amount, verified, updatedAt, type, txTimestamp, currency } = transaction;
                return <MDBRow key={i} className={`align-items-center border-neon border-top py-1 mx-0 ${i % 2 ? 'bg-black' : ''}`}>
                    {/* Updated At */}
                    <MDBCol size='1'>
                        <ThemeFormatTime timestamp={updatedAt} showFromNow></ThemeFormatTime>
                    </MDBCol>
                    {/* TimeStamp */}
                    <MDBCol size='1'>{formatTxTimestamp(currency, transaction)}</MDBCol>
                    {/* TransactionID */}
                    <MDBCol size='6' xl='3' className='small' >
                        <span className='opacity-60 small pb-1'>
                            {transactionId}
                        </span>
                        <CopyToClipboard
                            onCopy={() => { enqueueSnackbar('Copied.', { variant: 'warning', autoHideDuration: 2500 }) }}
                            text={transactionId}>
                            <MDBIcon className='text-warning ps-1 cursor-pointer' icon='copy'></MDBIcon>
                        </CopyToClipboard>
                    </MDBCol>
                    {/* Contract */}
                    <MDBCol size='3' className='text-end'><small>{currency == 'TRX' || currency == 'USDT' ? formatTRXTransaction(transaction) : ''}</small></MDBCol>
                    {/* View on Blockchain */}
                    <MDBCol size='1' className='text-center px-0'>
                        <ThemeFormatCurrency value={transactionId} text='View on Blockchain' mode='transaction' {...all}></ThemeFormatCurrency>
                    </MDBCol>
                    {/* Type */}
                    <MDBCol size='1' className={`text-center small text-capitalize text-${type == 'deposit' ? 'success' : type == 'withdraw' ? 'danger' : 'warning'}`}>
                        <img className='me-1' style={{ width: '0.7rem' }} src={currencyIcon(currency, 'png')} />{type}
                    </MDBCol>
                    {/* Currency */}
                    <MDBCol size='1' className='text-end'>{amount} <small>{currency}</small></MDBCol>
                    {/* Verified */}
                    <MDBCol size='1' className='text-center'>{verified ? <MDBIcon icon='check-circle' className='text-success'></MDBIcon> : <MDBIcon icon='times-circle' className='text-danger'></MDBIcon>}</MDBCol>
                </MDBRow>
            })
        }

        <div className='border border-info bg-black border-bottom-0 p-2'>
            <ThemeButton onClick={() => setOpenJson(!openJson)} size='small' className='rounded-pill btn-sm me-2' color='neon'><MDBIcon className='pe-1' icon={`${!openJson ? 'chevron-down' : 'chevron-up'}`}></MDBIcon> JSON Transaction</ThemeButton>
            <CopyToClipboard
                onCopy={() => { enqueueSnackbar('Copied.', { variant: 'warning', autoHideDuration: 2500 }) }}
                text={JSON.stringify(all) || '{}'}>
                <ThemeButton size='small' className='rounded-pill btn-sm' color='warning'>Copy Transaction to Clipboard</ThemeButton>
            </CopyToClipboard>
            <Collapse in={openJson} timeout="auto" unmountOnExit>
                <div className='my-2'>
                    <ReactJson name={null} style={{ padding: '0.5rem' }} theme="monokai" src={all} />
                </div>
            </Collapse>
        </div>
    </Box>
}

const formatTRXTransaction = (transaction) => {
    const { content } = transaction;
    if (!content) return '"content" not found.';
    const contract = content?.raw_data?.contract || null;
    if (!contract) return '"contract" not found.';
    if (contract.length !== 1) return '"contract" length is not 1';
    if (!contract[0].parameter) return '"contract[0].parameter" not found.';
    if (!contract[0].type) return '"contract[0].type" not found.';
    if (!contract[0].parameter.value) return '"contract[0].parameter.value" not found.';
    if (!contract[0].parameter.value.asset_name) return <div>
        'contract[0].type' : {contract[0].type}
    </div> //'"contract[0].parameter.value.asset_name" not found.';
    return <div>
        <div>
            'contract[0].type' : {contract[0].type}
        </div>
        <div>
            'contract[0].parameter.value.asset_name' : {contract[0].parameter.value.asset_name}
        </div>
    </div>
}

const TransferToTreasury = (all) => {
    const [state, dispatch] = useContext(Context);
    const { enqueueSnackbar } = useSnackbar();
    const [values, setValues] = useState({ open: false })
    const { currency, address, blockchainBalance, blockchainBalanceUSDT, blockchainBalanceFullData, isTreasury } = all;
    const minTTTamountInUSD = {
        TRX: 1,
        BTC: 10,
        DOGE: 5,
        ETH: 10,
        USDT: 9
    }

    // Check for enough funds
    if (!((convertByRates(state, blockchainBalance, currency, 'USD') >= minTTTamountInUSD[currency]) || (convertByRates(state, blockchainBalanceUSDT, 'USDT', 'USD') >= minTTTamountInUSD['USDT']))) {
        return <small className='text-white opacity-40'>Low Balance</small>
    }

    if (isTreasury) {
        return
    }

    // Check for enough BW and Energy for TRX
    if (currency == 'TRX') {
        if (!_.has(blockchainBalanceFullData, 'bandwidth.energyRemaining')) return <small className='text-white opacity-40'>Low Resource {currency}</small>
        const availableEnergy = blockchainBalanceFullData.bandwidth.energyRemaining;
        const availableBW = blockchainBalanceFullData.bandwidth.freeNetRemaining + blockchainBalanceFullData.bandwidth.netRemaining;
        if (availableBW < 500 || availableEnergy < 17000) {
            return <small className='opacity-60 text-danger'>
                {availableBW < 500 && <div>Low Bandwidth</div>}
                {availableEnergy < 17000 && <div>Low Energy</div>}
            </small>
        }
    }

    const callTTT = (c) => {
        let amount = 0;
        const blockchain = currency;
        const userWalletAddress = address;

        if (c == 'USDT') {
            amount = blockchainBalanceUSDT
        } else {
            amount = blockchainBalance
        }

        const params = {
            currency: c,
            amount,
            blockchain,
            userWalletAddress
        }

        axios.post(process.env.REACT_APP_API_HOST + '/accountant/treasury/transferToTreasury',
            params,
            {
                headers: { 'x-auth-token': getBearer() }
            })
            .then(res => {
                res = res.data;
                if (res.success) {
                    enqueueSnackbar(res.message, { variant: 'success', autoHideDuration: 2500 })
                } else {
                    enqueueSnackbar(res.message, { variant: 'error', autoHideDuration: 2500 })
                }
            })
            .catch(err => {
                enqueueSnackbar('Error', { variant: 'error', autoHideDuration: 2500 })
            })

    }

    if (parseFloat(blockchainBalance + blockchainBalanceUSDT) < 0.0001) return <ThemeButton size='small' variant='outlined' className='p-0 opacity-20' color='white'><MDBIcon icon='external-link-alt'></MDBIcon>
    </ThemeButton>
    return <>
        {
            !values.open ?
                <ThemeButton
                    onClick={() => setValues({ ...values, open: true })}
                    size='small' variant='outlined' className='p-0 cursor-pointer' color='success'><MDBIcon icon='external-link-alt'></MDBIcon>
                </ThemeButton>
                :
                <Modal
                    open={values.open}
                    disableScrollLock={true}
                    onClose={() => setValues({ ...values, open: false })}>
                    <Box className='box-shadow-neon bg-black rounded-6 overflow-scroll noScrollbar' sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '350px',
                    }}>
                        <MDBRow className='m-0 overflow-hidden small'>
                            <MDBCol size='12' className='p-2 mb-2 box-shadow-neon'>
                                Transfer To Treasury
                            </MDBCol>
                            <MDBCol size='12' className='py-1 samll text-center'>
                                blockchainBalance: {numeral(blockchainBalance).format(currencyDetail[currency].format)} {currency}
                                <div className='text-center py-2'>
                                    <ThemeButton onClick={() => callTTT(currency)} color='success' className='px-2' variant='outlined'>Transfer {numeral(blockchainBalance).format(currencyDetail[currency].format)} {currency} to Treasury</ThemeButton>
                                </div>
                            </MDBCol>
                            {
                                (currency == 'TRX' || currency == 'ETH') &&
                                <MDBCol size='12' className='py-1 samll text-center'>
                                    <hr className='m-0 p-0 mb-3' />
                                    blockchainBalanceUSDT: {numeral(blockchainBalanceUSDT).format(currencyDetail[currency].format)} USDT
                                    <div className='text-center py-2'>
                                        <ThemeButton onClick={() => callTTT('USDT')} color='success' className='px-2' variant='outlined'>Transfer {numeral(blockchainBalanceUSDT).format('USDT')} USDT to Treasury</ThemeButton>
                                    </div>
                                </MDBCol>
                            }
                        </MDBRow>
                    </Box>
                </Modal>
        }
    </>
}

const ActivateTRXWallet = (all) => {
    const { deposit, currency, address, blockchainBalance } = all;
    const { enqueueSnackbar } = useSnackbar();
    const { count, countUSDT } = deposit || {
        totalAmount: 0, count: 0, totalUSDT: 0, countUSDT: 0
    };

    const callActivate = () => {
        axios.post(process.env.REACT_APP_API_HOST + '/accountant/wallets/activateTrxWallet',
            { address },
            {
                headers: { 'x-auth-token': getBearer() }
            })
            .then(res => {
                res = res.data;
                if (res.success) {
                    enqueueSnackbar(res.message, { variant: 'success', autoHideDuration: 2500 })
                } else {
                    enqueueSnackbar(res.message, { variant: 'warning', autoHideDuration: 2500 })
                }
            })
            .catch(err => {
                enqueueSnackbar('Api error.', { variant: 'danger', autoHideDuration: 2500 })
            })
    }

    const isActive = (count > 0 || blockchainBalance > 0);
    if (isActive || (currency !== 'TRX')) {
        return <MDBIcon className='text-success' icon='check'></MDBIcon>
    } else {
        if (countUSDT) {
            return <ThemeButton
                onClick={() => callActivate()}
                size='small' variant='outlined' className='p-0 cursor-pointer' color='warning'>Activate
            </ThemeButton>
        } else {
            return <>-</>
        }

    }
}

const UsersWalletReport = (props) => {
    // const { userId, type } = props.match.params;
    const [contextBC, setContextBC] = useContext(BreadCrumbContext);
    useLayoutEffect(() => {
        setContextBC([
            {
                href: '/',
                text: 'Home'
            },
            {
                href: '/Financial',
                text: 'Financial'
            },
            {
                text: 'Users Wallets'
            }
        ])
    }, [])
    const { enqueueSnackbar } = useSnackbar();
    const [rows, setRows] = useState([])
    const interval = useRef(null);
    const [filter, setFilter] = useState({
        currency: 'TRX',
        find: {},
        limit: 20,
        skip: 0,
        sort: { updatedAt: -1 }
    })

    const columns = [{
        id: '_id',
        label: '',
        style: { width: '0', padding: 0 },
        labelStyle: { width: '0', padding: 0 },
        format: () => { }
    }, {
        id: 'updatedAt', // From Logger
        label: 'Updated At',
        align: 'left',
        labelAlign: 'left',
        style: { width: '6rem', padding: 0, fontSize: '0.7em', },
        labelStyle: { width: '6rem', padding: 0, fontSize: '0.7em', },
        format: (updatedAt) => <ThemeFormatTime timestamp={updatedAt} showFromNow></ThemeFormatTime>
        // }, {
        //     id: 'userId',
        //     label: 'User Id',
        //     align: 'center',
        //     style: { width: '70px', overflow: 'hidden' },
        //     labelStyle: { width: '70px' },
        //     format: (userId) => <span>
        //         <Link style={{ maxWidth: '70px', overflow: 'hidden' }} className='text-neon me-2 d-inline-block' to={`/user/list/${userId}`}>{userId}</Link>
        //     </span>
    },
    tblFormatUserId(),
    {
        id: 'all',
        label: 'Address',
        labelAlign: 'left',
        align: 'left',
        style: { width: '15rem' },
        labelStyle: { width: '15rem' },
        format: (all) => FormatWalletAddress(all)
    }, {
        id: 'all',
        label: 'Deposit',
        align: 'right',
        labelAlign: 'right',
        style: { width: '10rem' },
        labelStyle: { width: '10rem' },
        format: (all) => formatDeposit(all)
    }, {
        id: 'all',
        label: 'Withdraw',
        align: 'right',
        labelAlign: 'right',
        style: { width: '150px' },
        labelStyle: { width: '150px' },
        format: (all) => formatWithdraw(all)
    }, {
        id: 'all',
        label: 'To Treasury',
        align: 'right',
        labelAlign: 'right',
        style: { width: '150px' },
        labelStyle: { width: '150px' },
        format: (all) => toTreasury(all, enqueueSnackbar)
    }, {
        id: 'all',
        label: <small>Blockchain (Available)</small>,
        align: 'right',
        labelAlign: 'right',
        style: { width: '150px' },
        labelStyle: { width: '150px', lineHeight: '1rem' },
        format: (all) => formatBlockchainBalance(all)
    }, {
        id: 'all',
        label: 'Transactions',
        collapse: true,
        align: 'left',
        style: {
            width: '150px',
            overflow: 'hidden',
            padding: '0 100px !important',
            whiteSpace: 'break-spaces !important'
        },
        labelStyle: { width: '150px' },
        format: all => FormatTransactions(all, enqueueSnackbar)
    }, {
        id: 'all',
        label: <small>Resouces</small>,
        align: 'left',
        labelAlign: 'left',
        style: { width: '300px', paddingLeft: '1rem' },
        labelStyle: { width: '300px' },
        format: (all) => <FormatResources key={all.address} {...all} />
    }, {
        id: 'all',
        label: <small>Transfer To Treasury</small>,
        align: 'center',
        style: { width: '150px' },
        labelStyle: { width: '150px' },
        format: (all) => TransferToTreasury(all)
    }, {
        id: 'all',
        label: <small>Activate Wallet</small>,
        align: 'center',
        style: { width: '80px' },
        labelStyle: { width: '80px' },
        format: (all) => ActivateTRXWallet(all)
    }, {
        id: 'all',
        label: <small>Blockchain Update</small>,
        align: 'center',
        style: { width: '80px' },
        labelStyle: { width: '80px' },
        format: (all) => <ThemeButton
            onClick={() => updateWalletFromBlockchain(all)}
            size='small' variant='outlined' className='p-0 cursor-pointer' color='info'><MDBIcon icon='sync'></MDBIcon></ThemeButton>
    }
    ];



    const getTransactions = (setRows, filters) => {
        axios.post(process.env.REACT_APP_API_HOST + '/accountant/wallets/report',
            filters,
            {
                headers: { 'x-auth-token': getBearer() }
            })
            .then(apiRes => {
                apiRes = apiRes.data;
                if (apiRes.success) {
                    setRows({
                        count: apiRes.result.count,
                        transactions: apiRes.result.report.map(row => ({ ...row, all: row }))
                    });
                } else {
                    // setRows({
                    //     count: 0,
                    //     transactions: []
                    // });
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const updateWalletFromBlockchain = (all) => {
        axios.post(process.env.REACT_APP_API_HOST + '/superadmin/users/wallet/updateByAddress',
            {
                currency: all.currency,
                address: all.address
                // _id: "61ae596656d3b3d8ae2127aa",
                // userId: "100090"
            },
            {
                headers: { 'x-auth-token': getBearer() }
            })
            .then(apiRes => {
                apiRes = apiRes.data;
                if (apiRes.success) {
                    enqueueSnackbar(apiRes.message, { variant: 'success', autoHideDuration: 2500 })
                } else {
                    enqueueSnackbar(apiRes.message, { variant: 'warning', autoHideDuration: 2500 })
                }
            })
            .catch(err => {
                enqueueSnackbar('Api error.', { variant: 'danger', autoHideDuration: 2500 })
            })

    }

    const handlePagination = (e, page) => {
        setFilter({
            ...filter,
            skip: (page - 1) * filter.limit
        });
    }

    useEffect(() => {
        let updateInterval = setInterval(() => {
            getTransactions(setRows, filter);
        }, 5000);
        interval.current = updateInterval;
        return () => {
            clearInterval(interval.current)
        }
    }, [filter])

    const [tableColumns, setTableColumns] = useState(columns);
    return (
        <>
            <UsersWalletReportFilter query={useParams()} setRows={setRows} getTransactions={getTransactions} count={rows.count} setFilter={setFilter} filter={filter}></UsersWalletReportFilter>
            <ResponsiveTable collapsible rows={rows.transactions} columns={tableColumns}></ResponsiveTable>

            {
                Math.floor(rows.count / filter.limit) > 0 ?

                    <MDBRow className='m-0'>
                        <MDBCol size='12' className='text-center py-4'>
                            <Pagination onChange={handlePagination} className='d-inline-block' count={Math.ceil(rows.count / filter.limit)} variant="outlined" shape="rounded" />
                        </MDBCol>
                    </MDBRow> : <></>

            }

        </>)
}

export default UsersWalletReport