import { Box, Tooltip, Zoom } from '@mui/material';
import { MDBIcon } from 'mdb-react-ui-kit';
import { useSnackbar } from 'notistack';
import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard';

const TransactionIDs = (props) => {
    const { type, meta } = props;
    const { bet } = meta;
    // const BetId = props.meta && props.meta.bet && props.meta.bet.id;
    // const GameId = props.meta && props.meta.bet && props.meta.bet.details && props.meta.bet.details[0] && props.meta.bet.details[0].id;


    return <Box>
        {(type == 'bet' && bet) && <TransactionBetId {...bet} />}
    </Box>
}

const TransactionBetId = (bet) => {
    const { enqueueSnackbar } = useSnackbar();

    const { details, id } = bet;
    const betDetail = details[0];
    const { tableId } = betDetail;
    const iMoonBetId = id;
    const providerBetId = betDetail.id
    return <small className='text-left px-0 d-inline-block' style={{
        width: '100%',
        wordBreak: 'break-all',
        whiteSpace: 'break-spaces'
    }}>
        {iMoonBetId && <Tooltip
            TransitionComponent={Zoom}
            placement="right"
            classes={{ tooltip: 'bg-black px-3 py-1 rounded-3 box-shadow-neon' }}
            title={<Box sx={{ fontSize: ['0.5rem'] }}>
                iMoon Bet Id
            </Box>}>
            <Box sx={{ fontSize: ['0.7rem', '0.5rem'] }}>
                <div className='text-white opacity-50 d-md-none text-center'>iMoon Bet Id</div>
                <CopyToClipboard
                    onCopy={() => { enqueueSnackbar('iMoon Bet Id Copied.', { variant: 'success', autoHideDuration: 2500 }) }}
                    text={iMoonBetId}>
                    <div className='text-yellow d-inline cursor-pointer text-center'>
                        <div className='text-center'>
                            {iMoonBetId} <MDBIcon className='px-1' icon='copy'></MDBIcon>
                        </div>
                    </div>
                </CopyToClipboard>
            </Box>
        </Tooltip>
        }
        {
            providerBetId && <Tooltip
                TransitionComponent={Zoom}
                placement="right"
                classes={{ tooltip: 'bg-black px-3 py-1 rounded-3 box-shadow-neon' }}
                title={<Box sx={{ fontSize: ['0.5rem'] }}>
                    Provider Bet Id
                </Box>}>
                <Box sx={{ fontSize: ['0.7rem', '0.5rem'] }}>
                    <div className='text-white opacity-50 d-md-none text-center'>Provider Bet Id</div>
                    <CopyToClipboard
                        onCopy={() => { enqueueSnackbar('Provider Bet Id Copied.', { variant: 'success', autoHideDuration: 2500 }) }}
                        text={providerBetId}>
                        <div className='text-yellow d-inline cursor-pointer text-center'>
                            <div className='text-center'>
                                {providerBetId} <MDBIcon className='px-1' icon='copy'></MDBIcon>
                            </div>
                        </div>
                    </CopyToClipboard>
                </Box>
            </Tooltip>
        }
        {
            tableId && <Tooltip
                TransitionComponent={Zoom}
                placement="right"
                classes={{ tooltip: 'bg-black px-3 py-1 rounded-3 box-shadow-neon' }}
                title={<Box sx={{ fontSize: ['0.5rem'] }}>
                    Game/Table Id
                </Box>}>
                <Box sx={{ fontSize: ['0.7rem', '0.5rem'] }}>
                    <div className='text-white opacity-50 d-md-none text-center'>Game/Table Id</div>
                    <CopyToClipboard
                        onCopy={() => { enqueueSnackbar('Game Id Copied.', { variant: 'success', autoHideDuration: 2500 }) }}
                        text={tableId}>
                        <div className='text-yellow d-inline cursor-pointer text-center'>
                            <div className='text-center'>
                                {tableId} <MDBIcon className='px-1' icon='copy'></MDBIcon>
                            </div>
                        </div>
                    </CopyToClipboard>
                </Box>
            </Tooltip>
        }
        {/* {GameId && <>
            <Tooltip
                TransitionComponent={Zoom}
                placement="right"
                classes={{ tooltip: 'bg-black px-3 py-1 rounded-3 box-shadow-neon' }}
                title={<Box sx={{ fontSize: '0.5rem' }}>
                    Game Id
                </Box>}>
                <Box className='mt-2' sx={{ fontSize: ['0.7rem', '0.5rem'] }}>
                    <CopyToClipboard
                        onCopy={() => { enqueueSnackbar('GameId Copied.', { variant: 'success', autoHideDuration: 2500 }) }}
                        text={GameId}>
                        <div className='text-orange cursor-pointer'>Gid: {GameId} <MDBIcon className='px-1' icon='copy'></MDBIcon></div>
                    </CopyToClipboard>
                </Box>
            </Tooltip>
        </>} */}
        {/* {tableId && <>
            <hr className='my-1 p-0' />
            <Tooltip
                TransitionComponent={Zoom}
                placement="right"
                classes={{ tooltip: 'bg-black px-3 py-1 rounded-3 box-shadow-neon' }}
                title={<Box sx={{ fontSize: '0.5rem' }}>
                    Table Id
                </Box>}>
                <Box sx={{ fontSize: '0.5rem' }}>
                    <CopyToClipboard
                        onCopy={() => { enqueueSnackbar('tableId Copied.', { variant: 'success', autoHideDuration: 2500 }) }}
                        text={tableId}>
                        <div className='text-orange cursor-pointer'>Tid: {tableId} <MDBIcon className='px-1' icon='copy'></MDBIcon></div>
                    </CopyToClipboard>
                </Box>
            </Tooltip>
        </>} */}
    </small >
}

// const EvolutionBetIdDetails = (bet) => { third }

export default TransactionIDs