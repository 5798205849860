import { Box, List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import { MDBCol, MDBRow } from 'mdb-react-ui-kit'
import React, { useContext, useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'
import { BreadCrumbContext } from '../../context/store'
import ThemeButton from '../../layout/components/ThemeButton'

const data = [
    {
        title: 'Withdraw Requests',
        url: '/Withdraw/WithdrawRequests'
    },
    {
        title: 'Transfer to Treasury Requests',
        url: '/TransferToTreasury/TransferToTreasuryRequests'
    },
    {
        title: 'Treasury',
        url: '/Financial/Treasury'
    }
]

const Financial = () => {
    const [contextBC, setContextBC] = useContext(BreadCrumbContext);
    useLayoutEffect(() => {
        setContextBC([
            {
                href: '/',
                text: 'Home'
            },
            {
                text: 'Financial'
            }
        ])
    }, [])
    return <Box className='d-flex- flex-column px-2 py-3'>
        <Box className='py-2'>
            <Link to={`/Transaction/List/?type=financial`}>
                <ThemeButton color='teal' className='px-3 py-1' variant='outlined'>Financials</ThemeButton>
            </Link>
        </Box>
        <Box className='py-2'>
            <Link to={`/Transaction/List/?type=deposit`}>
                <ThemeButton color='success' className='px-3 py-1' variant='outlined'>Deposits</ThemeButton>
            </Link>
        </Box>
        <Box className='py-2'>
            <Link to={`/Transaction/List/?type=withdraw`}>
                <ThemeButton color='danger' className='px-3 py-1' variant='outlined'>Withdraws</ThemeButton>
            </Link>
        </Box>
        <Box className='py-2'>
            <Link to={`/Transaction/List/?type=topup`}>
                <ThemeButton color='neon' className='px-3 py-1' variant='outlined'>Topups</ThemeButton>
            </Link>
        </Box>
        <Box className='py-2'>
            <Link to={`/Transaction/List/?type=bet`}>
                <ThemeButton color='yellow' className='px-3 py-1' variant='outlined'>Bets</ThemeButton>
            </Link>
        </Box>
        <Box className='py-2'>
            <Link to={`/TransferToTreasury/TransferToTreasuryRequests`}>
                <ThemeButton color='orange' className='px-3 py-1' variant='outlined'>Transfer to Treasury Requests</ThemeButton>
            </Link>
        </Box>
        <Box className='py-2'>
            <Link to={`/Withdraw/WithdrawRequests`}>
                <ThemeButton color='pink' className='px-3 py-1' variant='outlined'>Withdraw Requests</ThemeButton>
            </Link>
        </Box>
        {/* <List>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Trash" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component="a" href="#simple-list">
              <ListItemText primary="Spam" />
            </ListItemButton>
          </ListItem>
        </List> */}
        {/* {data.map((d, i) => {
            return <MDBCol size='12'>
                <Box>
                    <Link className='text-neon' to={d.url}>
                        {d.title}
                    </Link>
                </Box>
            </MDBCol>
        })} */}
    </Box>
}

export default Financial