import { Avatar, Collapse, LinearProgress, List, ListItem, ListItemText, Typography } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit'
import numeral from 'numeral';
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../context/store';
import { currencyDetail, currencyFormat, currencyIcon } from '../../helpers/currency';
import { convertByRates, getBearer, getCryptoColor } from '../../helpers/publicHelper';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import moment from 'moment';
import _ from 'lodash';
import ThemeFormatCurrency from '../../layout/components/ThemeFormatCurrency';
import ThemeChartAndDataCard from '../../layout/components/ThemeChartAndDataCard';
import { useParams } from 'react-router-dom';
import ThemeFormatServiceLogo from '../../layout/components/ThemeFormatServiceLogo';
ChartJS.register(ArcElement, Tooltip, Legend);

// import Chart from "chart.js/auto";
const currencies = ['InUSD', 'USD', 'USDT', 'TRX', 'BTC', 'ETH', 'DOGE'];
const providers = ['EveryMatrix', 'Evolution', 'Pragmatic', 'Crash', 'Digitain', 'Netent', 'RedTiger'];

const getUserData = (state, userId, setUser) => {
    axios.post(state.active.host + '/admin/users',
        {
            find: {
                userId
            },
            limit: 1,
            sort: {
                createdAt: 1
            }
        },
        {
            headers: { 'x-auth-token': getBearer() }
        })
        .then(res => {
            res = res.data;
            if (res.success) {
                if (res.result.users[0]) {
                    setUser(res.result.users[0]);
                } else {
                    console.log('Invalid res.result.users[0]')
                }

            } else {

            }
        })
        .catch(err => {
            console.log(err)
        })
        .then(() => {
        })
}

const getAggregateReport = (state, userId, setReport, from, to) => {

    const match = {
        '$match': {
            'userId': userId,
            'isValid': true,
            'status': 'verified',
            // ...((from || to) && {
            //     'createdAt': {
            //         ...(from) && { $gte: new Date("2022-07-10T20:00:00.000Z").getTime() },
            //     }
            // })

            'createdAt': { $gte: new Date("2022-07-01T00:00:00.000Z").getTime(), $lt: new Date("2022-08-01T00:00:00.000Z").getTime() }
        }
    }
    const playerStats = [
        match
        , {
            '$sort': {
                'createdAt': -1
            }
        }, {
            '$addFields': {
                ...aggAddField('deposit'),
                ...aggAddField('topupDeposit'),
                ...aggAddField('withdraw'),
                ...aggAddField('topupWithdraw'),
                ...aggAddField('bet'),
                ...aggAddField('betPerProvider'),
            }
        }, {
            '$group': {
                ...aggGroup('Deposit'),
                ...aggGroup('TopupDeposit'),
                ...aggGroup('Withdraw'),
                ...aggGroup('TopupWithdraw'),
                ...aggGroup('Increase'),
                ...aggGroupBetPerProvider(),
                '_id': 'a',
                'totalBetAmountInUSD': {
                    '$sum': '$totalBetAmountInUSD'
                },
            }
        }
    ]

    axios.post(state.active.host + '/superadmin/aggregate',
        {
            collection: 'transaction',
            aggregate: playerStats
        },
        {
            headers: { 'x-auth-token': getBearer() }
        })
        .then(res => {
            res = res.data;
            if (res.success) {
                setReport(res.result[0])
            } else {

            }
        })
        .catch(err => {
            console.log(err)
        })
}

const aggAddFieldBetPerProvider = () => {
    const type = 'bet';
    let result = {};
    result[`totalBetAmountInUSD`] = {
        '$cond': [
            {
                '$and': [
                    {
                        '$eq': ['$type', type],
                    },
                    {
                        '$in': ['$subtype', ['won', 'lost', 'cashback']],
                    },
                    {
                        '$gt': [
                            '$meta.bet.betAmountInUSD', 0
                        ]
                    }
                ]
            }, '$meta.bet.betAmountInUSD', 0
        ]
    };
    result[`totalWinAmountInUSD`] = {
        '$cond': [
            {
                '$and': [
                    {
                        '$eq': ['$type', type],
                    },
                    {
                        '$in': ['$subtype', ['won', 'lost', 'cashback']],
                    },
                    {
                        '$gt': [
                            '$meta.bet.winAmountInUSD', 0
                        ]
                    }
                ]
            }, '$meta.bet.winAmountInUSD', 0
        ]
    };
    providers.map((p, i) => {
        result[`BetAmount${p}InUSD`] = {
            '$cond': [
                {
                    '$and': [
                        {
                            '$eq': ['$type', type],
                        },
                        {
                            '$eq': ['$service', p],
                        },
                        {
                            '$in': ['$subtype', ['won', 'lost', 'cashback']],
                        },
                        {
                            '$gt': [
                                '$meta.bet.betAmountInUSD', 0
                            ]
                        }
                    ]
                }, '$meta.bet.betAmountInUSD', 0
            ]
        };
        result[`BetAmount${p}Count`] = {
            '$cond': [
                {
                    '$and': [
                        {
                            '$eq': ['$type', type],
                        },
                        {
                            '$eq': ['$service', p],
                        },
                        {
                            '$in': ['$subtype', ['won', 'lost', 'cashback']],
                        },
                        {
                            '$gt': [
                                '$meta.bet.betAmountInUSD', 0
                            ]
                        }
                    ]
                }, 1, 0
            ]
        };
        result[`WinAmount${p}InUSD`] = {
            '$cond': [
                {
                    '$and': [
                        {
                            '$eq': ['$type', type],
                        },
                        {
                            '$eq': ['$service', p],
                        },
                        {
                            '$in': ['$subtype', ['won', 'lost', 'cashback']],
                        },
                        {
                            '$gt': [
                                '$meta.bet.winAmountInUSD', 0
                            ]
                        }
                    ]
                }, '$meta.bet.winAmountInUSD', 0
            ]
        };
        result[`WinAmount${p}Count`] = {
            '$cond': [
                {
                    '$and': [
                        {
                            '$eq': ['$type', type],
                        },
                        {
                            '$eq': ['$service', p],
                        },
                        {
                            '$in': ['$subtype', ['won', 'lost', 'cashback']],
                        },
                        {
                            '$gt': [
                                '$meta.bet.winAmountInUSD', 0
                            ]
                        }
                    ]
                }, 1, 0
            ]
        };
    })
    return result
}

const aggAddField = (title) => {
    if (title == 'betPerProvider') return aggAddFieldBetPerProvider();
    const type = (title == 'topupDeposit' || title == 'topupWithdraw') ? 'topup' : title;
    let amountSignQuery = title.toLowerCase().indexOf('deposit') > -1 ? {
        '$gt': [
            '$meta.amountInUSD', 0
        ]
    } : title.toLowerCase().indexOf('withdraw') > -1 ? {
        '$lt': [
            '$meta.amountInUSD', 0
        ]
    } : {};

    if (title == 'bet') {
        amountSignQuery = {
            '$gt': [
                '$meta.bet.betAmountInUSD', 0
            ]
        }
    }

    let result = {};
    currencies.map((c, i) => {
        result[`${title}Amount${c}`] = {
            '$cond': [
                {
                    '$and': [
                        {
                            '$eq': [
                                '$type', type
                            ],
                        }, {
                            '$eq': c == 'InUSD' ? ['$type', type] : [
                                '$currency', c,
                            ]
                        }, amountSignQuery
                    ]
                },
                c == 'InUSD' ? '$meta.amountInUSD' : '$amount', 0
            ]
        };
        result[`${title}BonusAmount${c}`] = {
            '$cond': [
                {
                    '$and': [
                        {
                            '$eq': [
                                '$type', type + 'Bonus'
                            ],
                        }, {
                            '$eq': c == 'InUSD' ? ['$type', type + 'Bonus'] : [
                                '$currency', c,
                            ]
                        }, amountSignQuery
                    ]
                },
                c == 'InUSD' ? '$meta.amountInUSD' : '$amount', 0
            ]
        };
        result[`${title}Amount${c}InUSD`] = {
            '$cond': [
                {
                    '$and': [
                        {
                            '$eq': ['$type', type]
                        },
                        {
                            '$eq': c == 'InUSD' ? ['$type', type] : ['$currency', c,]
                        }, amountSignQuery
                    ]
                },
                '$meta.amountInUSD', 0
            ]
        };
        result[`${title}BonusAmount${c}InUSD`] = {
            '$cond': [
                {
                    '$and': [
                        {
                            '$eq': ['$type', type + 'Bonus']
                        },
                        {
                            '$eq': c == 'InUSD' ? ['$type', type + 'Bonus'] : ['$currency', c,]
                        }, amountSignQuery
                    ]
                },
                '$meta.amountInUSD', 0
            ]
        };
        result[`${title}Amount${c}Count`] = {
            '$cond': [
                {
                    '$and': [
                        {
                            '$eq': ['$type', type]
                        },
                        {
                            '$eq': c == 'InUSD' ? ['$type', type] : ['$currency', c,]
                        }, amountSignQuery
                    ]
                },
                1, 0
            ]
        }
        result[`${title}BonusAmount${c}Count`] = {
            '$cond': [
                {
                    '$and': [
                        {
                            '$eq': ['$type', type + 'Bonus']
                        },
                        {
                            '$eq': c == 'InUSD' ? ['$type', type + 'Bonus'] : ['$currency', c,]
                        }, amountSignQuery
                    ]
                },
                1, 0
            ]
        }
    })
    return result
}

const aggGroupBetPerProvider = () => {
    let result = {};
    providers.map((p, i) => {
        result[`totalBetAmount${p}InUSD`] = {
            '$sum': `$BetAmount${p}InUSD`
        }
        result[`totalBetAmount${p}Count`] = {
            '$sum': `$BetAmount${p}Count`
        }
        result[`totalWinAmount${p}InUSD`] = {
            '$sum': `$WinAmount${p}InUSD`
        }
        result[`totalWinAmount${p}Count`] = {
            '$sum': `$WinAmount${p}Count`
        }
    })
    return result
}

const aggGroup = (title) => {
    if (title == 'BetPerProvider') return aggGroupBetPerProvider();
    const firstLetterLowerTitle = title.charAt(0).toLowerCase() + title.slice(1);
    let result = {};
    currencies.map((c, i) => {
        result[`total${title}Amount${c}`] = {
            '$sum': `$${firstLetterLowerTitle}Amount${c}`
        }
        result[`total${title}Amount${c}InUSD`] = {
            '$sum': `$${firstLetterLowerTitle}Amount${c}InUSD`
        }
        result[`total${title}Amount${c}Count`] = {
            '$sum': `$${firstLetterLowerTitle}Amount${c}Count`
        }
        result[`total${title}BonusAmount${c}`] = {
            '$sum': `$${firstLetterLowerTitle}BonusAmount${c}`
        }
        result[`total${title}BonusAmount${c}InUSD`] = {
            '$sum': `$${firstLetterLowerTitle}BonusAmount${c}InUSD`
        }
        result[`total${title}BonusAmount${c}Count`] = {
            '$sum': `$${firstLetterLowerTitle}BonusAmount${c}Count`
        }
    })
    return result
}

const UserReport = (props) => {
    const params = useParams();
    const { userId } = params.userId ? params : props;
    const [state, dispatch] = useContext(Context);
    const [report, setReport] = useState();
    const [user, setUser] = useState();
    useEffect(() => {
        getUserData(state, userId, setUser);
        getAggregateReport(state, userId, setReport);
    }, [])

    return (<>
        {report && (report.totalBetAmountCrashCount !== undefined) ? <>
            <MDBRow className='mx-0 position-relative'>
                {user && <UserMiniProfile user={user}></UserMiniProfile>}
                {/* {props.onClose &&
                    <Box sx={{
                        right: '15px',
                        width: '3rem',
                        position: 'absolute'
                    }}>
                        <MDBIcon onClick={props.onClose} className='text-neon p-3 cursor-pointer' icon='times'></MDBIcon>
                    </Box>} */}
                <hr />
            </MDBRow>
            <MDBRow className='mx-0 pt-3 overflow-hidden'>
                {/* <PLChart2 report={report} user={user} /> */}
                <PLChart report={report} user={user}></PLChart>
                <UserWallet report={report} user={user}></UserWallet>
                {/* <Turnover report={report} user={user}></Turnover> */}
                <BonusChart report={report} user={user}></BonusChart>
            </MDBRow>
            {/* <MDBRow className='mx-0 pt-3 align-items-stretch'>
                <hr />
                <MDBCol size='12' className='pt-2 pb-4'>
                    {user && <UserDetails activeTab={{ index: 3 }} user={user} />}
                </MDBCol> */}
            {/* <ReportCard report={report} title='Deposit'></ReportCard>
                <ReportCard report={report} title='TopupDeposit'></ReportCard>
                <ReportCard report={report} title='Withdraw'></ReportCard>
                <ReportCard report={report} title='TopupWithdraw'></ReportCard>
                <ReportCard report={report} title='Increase'></ReportCard>
                <BetReportCard report={report} title='BetPerProvider'></BetReportCard> */}
            {/* </MDBRow> */}
        </> : <Box className='d-flex h-100 w-100 align-items-center justify-content-center'>
            <div>
                <Typography className='text-neon pb-2 px-4'>
                    Generating User Report
                </Typography>
                <LinearProgress color="neon" />
            </div>
        </Box>
        }
    </>
    )
}

const PLChart = (props) => {
    const { report, user } = props;
    const [chartData, setChartData] = useState({});

    useEffect(() => {
        if (report && user && (report.totalBetAmountInUSD !== undefined)) {
            const ppl = report.totalDepositAmountInUSD + report.totalTopupDepositAmountInUSD + report.totalWithdrawAmountInUSD + report.totalTopupWithdrawAmountInUSD - user.wallet.inUSD.balance;

            setChartData({
                ready: true,
                title: 'PL Chart',
                secondaryTitles:
                    [
                        {
                            text: 'Turnover',
                            amount: numeral(report.totalBetAmountInUSD / (report.totalDepositAmountInUSD + report.totalTopupDepositAmountInUSD)).format('%0,0.00'),
                            className: 'text-warning'
                        },
                        {
                            text: ppl > 0 ? 'Profitable' : 'Unprofitable',
                            amount: numeral(ppl).format('$0,0.00'),
                            className: ppl > 0 ? 'text-success' : 'text-danger',
                        }
                    ],
                centerChart:
                {
                    title: 'Margin',
                    amount: numeral(ppl / (report.totalDepositAmountInUSD + report.totalTopupDepositAmountInUSD)).format('%0,0.00'),
                    className: (ppl / (report.totalDepositAmountInUSD + report.totalTopupDepositAmountInUSD)) > 0 ? 'bg-success' : 'bg-danger'
                },
                dataTitles: ['Type', 'Total (count)'],
                data:
                    [{
                        title: 'Deposit',
                        color: '#00b74a',
                        collapse: <TransactionsQuickView></TransactionsQuickView>,
                        chartAmount: report.totalDepositAmountInUSD,
                        amount1: numeral(report.totalDepositAmountInUSD).format('$0,0.00'),
                        amount2: numeral(report.totalDepositAmountInUSDCount).format('0,0')
                    },
                    {
                        title: 'TopUp',
                        color: '#26ddff',
                        chartAmount: report.totalTopupDepositAmountInUSD,
                        amount1: numeral(report.totalTopupDepositAmountInUSD).format('$0,0.00'),
                        amount2: numeral(report.totalTopupDepositAmountInUSDCount).format('0,0')
                    },
                    {
                        title: 'Withdraw',
                        color: '#f93154',
                        chartAmount: report.totalWithdrawAmountInUSD,
                        amount1: numeral(report.totalWithdrawAmountInUSD).format('$0,0.00'),
                        amount2: numeral(report.totalWithdrawAmountInUSDCount).format('0,0')
                    },
                    {
                        title: 'TopupWithdraw',
                        color: '#ff5500',
                        chartAmount: report.totalTopupWithdrawAmountInUSD,
                        amount1: numeral(report.totalTopupWithdrawAmountInUSD).format('$0,0.00'),
                        amount2: numeral(report.totalTopupWithdrawAmountInUSDCount).format('0,0')
                    },
                    {
                        title: 'Balance',
                        color: '#ddaa00',
                        chartAmount: user.wallet.inUSD.balance,
                        amount1: numeral(user.wallet.inUSD.balance).format('$0,0.00'),
                        // amount2: numeral(user.wallet.inUSD.balance).format('0,0')
                    },
                    {
                        title: 'Locked Balance',
                        color: '#f00',
                        chartAmount: user.wallet.inUSD.lockedBalance,
                        amount1: numeral(user.wallet.inUSD.lockedBalance).format('$0,0.00'),
                    },
                    {
                        title: 'Open Bets (Preview)',
                        color: '#fff5',
                        chartAmount: 0,
                        amount1: 0,
                    }]
            })
        } else {
            console.log('invalidData')
        }
    }, [report, user])

    return <>
        <ThemeChartAndDataCard {...chartData}></ThemeChartAndDataCard>
        {/* <Box>
        {numeral(report.totalDepositAmountInUSD * 0.10).format('0,0.00')}
        </Box> */}
    </>
}

const UserWallet = (props) => {
    const { report, user } = props;
    const [chartData, setChartData] = useState({});
    const [state, dispatch] = useContext(Context);

    useEffect(() => {
        if (report && user && (report.totalBetAmountInUSD !== undefined)) {
            const totalBalanceInUSD = user.wallet.USD.balance +
                convertByRates(state, user.wallet.USDT.balance, 'USDT', 'USD') +
                convertByRates(state, user.wallet.TRX.balance, 'TRX', 'USD') +
                convertByRates(state, user.wallet.BTC.balance, 'BTC', 'USD') +
                convertByRates(state, user.wallet.ETH.balance, 'ETH', 'USD') +
                convertByRates(state, user.wallet.DOGE.balance, 'DOGE', 'USD');
            setChartData({
                ready: true,
                title: 'User Wallet',
                secondaryTitles:
                    [
                        {
                            text: 'Total Balance',
                            amount: numeral(totalBalanceInUSD).format('$0,0.00'),
                            className: 'text-success',
                        }
                    ],
                centerChart:
                {
                    title: 'Balance',
                    amount: numeral(totalBalanceInUSD).format('($0.00a)'),
                    className: 'bg-success'
                },
                dataTitles: ['Currency', 'USD (Crypto)'],
                data:
                    [{
                        title: <span><ThemeFormatCurrency hideText currency='USD'></ThemeFormatCurrency> USD</span>,
                        color: getCryptoColor('USD'),
                        chartTitle: '',
                        chartAmount: user.wallet.USD.balance,
                        amount1: numeral(user.wallet.USD.balance).format('$0,0.00'),
                        amount2: numeral(user.wallet.USD.balance).format('$0,0.00')
                    },
                    {
                        title: <span><ThemeFormatCurrency hideText currency='USDT'></ThemeFormatCurrency> USDT</span>,
                        color: getCryptoColor('USDT'),
                        chartTitle: 'USDT',
                        chartAmount: convertByRates(state, user.wallet.USDT.balance, 'USDT', 'USD'),
                        amount1: numeral(convertByRates(state, user.wallet.USDT.balance, 'USDT', 'USD')).format('$0,0.00'),
                        amount2: numeral(user.wallet.USDT.balance).format(currencyDetail['USDT'].format)
                    },
                    {
                        title: <span><ThemeFormatCurrency hideText currency='TRX'></ThemeFormatCurrency> TRX</span>,
                        color: getCryptoColor('TRX'),
                        chartTitle: 'TRX',
                        chartAmount: convertByRates(state, user.wallet.TRX.balance, 'TRX', 'USD'),
                        amount1: numeral(convertByRates(state, user.wallet.TRX.balance, 'TRX', 'USD')).format('$0,0.00'),
                        amount2: numeral(user.wallet.TRX.balance).format(currencyDetail['TRX'].format)
                    },
                    {
                        title: <span><ThemeFormatCurrency hideText currency='BTC'></ThemeFormatCurrency> BTC</span>,
                        color: getCryptoColor('BTC'),
                        chartTitle: 'BTC',
                        chartAmount: convertByRates(state, user.wallet.BTC.balance, 'BTC', 'USD'),
                        amount1: numeral(convertByRates(state, user.wallet.BTC.balance, 'BTC', 'USD')).format('$0,0.00'),
                        amount2: numeral(user.wallet.BTC.balance).format(currencyDetail['BTC'].format)
                    },
                    {
                        title: <span><ThemeFormatCurrency hideText currency='ETH'></ThemeFormatCurrency> ETH</span>,
                        color: getCryptoColor('ETH'),
                        chartTitle: 'ETH',
                        chartAmount: convertByRates(state, user.wallet.ETH.balance, 'ETH', 'USD'),
                        amount1: numeral(convertByRates(state, user.wallet.ETH.balance, 'ETH', 'USD')).format('$0,0.00'),
                        amount2: numeral(user.wallet.ETH.balance).format(currencyDetail['ETH'].format)
                    },
                    {
                        title: <span><ThemeFormatCurrency hideText currency='DOGE'></ThemeFormatCurrency> DOGE</span>,
                        color: getCryptoColor('DOGE'),
                        chartTitle: 'DOGE',
                        chartAmount: convertByRates(state, user.wallet.DOGE.balance, 'DOGE', 'USD'),
                        amount1: numeral(convertByRates(state, user.wallet.DOGE.balance, 'DOGE', 'USD')).format('$0,0.00'),
                        amount2: numeral(user.wallet.DOGE.balance).format(currencyDetail['DOGE'].format)
                    }]
            })
        } else {
            console.log('invalidData')
        }
    }, [report, user])

    return <ThemeChartAndDataCard {...chartData}></ThemeChartAndDataCard>
}

const Turnover = (props) => {
    const { report, user } = props;
    const [chartData, setChartData] = useState({});
    const [state, dispatch] = useContext(Context);
    useEffect(() => {
        if (report && user && (report.totalBetAmountInUSD !== undefined)) {
            setChartData({
                ready: true,
                title: 'Turnover (All wagers including cashbacks)',
                secondaryTitles:
                    [
                        {
                            text: 'Total Play',
                            amount: numeral(report.totalBetAmountInUSD).format('$0,0.00'),
                            className: 'text-success',
                        }
                    ],
                centerChart:
                {
                    title: 'Total Play',
                    amount: numeral(report.totalBetAmountInUSD).format('($0.00a)'),
                    className: 'bg-success'
                },
                dataTitles: ['Provider', 'Bet (RTP)'],
                data:
                    [{
                        title: <ThemeFormatServiceLogo size='10' service='Crash'></ThemeFormatServiceLogo>,
                        color: '#26ddff',
                        chartTitle: 'Crash',
                        chartAmount: report.totalBetAmountCrashInUSD,
                        amount1: numeral(report.totalBetAmountCrashInUSD).format('$0,0.00'),
                        amount2: numeral(report.totalWinAmountCrashInUSD / report.totalBetAmountCrashInUSD).format('%0,0.00')
                    },
                    {
                        title: <ThemeFormatServiceLogo size='10' service='EveryMatrix'></ThemeFormatServiceLogo>,
                        color: '#2ab351',
                        chartTitle: 'EveryMatrix',
                        chartAmount: report.totalBetAmountEveryMatrixInUSD,
                        amount1: numeral(report.totalBetAmountEveryMatrixInUSD).format('$0,0.00'),
                        amount2: numeral(report.totalWinAmountEveryMatrixInUSD / report.totalBetAmountEveryMatrixInUSD).format('%0,0.00')
                    },
                    {
                        title: <ThemeFormatServiceLogo size='10' service='Evolution'></ThemeFormatServiceLogo>,
                        color: '#ff5500',
                        chartTitle: 'Evolution',
                        chartAmount: report.totalBetAmountEvolutionInUSD,
                        amount1: numeral(report.totalBetAmountEvolutionInUSD).format('$0,0.00'),
                        amount2: numeral(report.totalWinAmountEvolutionInUSD / report.totalBetAmountEvolutionInUSD).format('%0,0.00')
                    },
                    {
                        title: <ThemeFormatServiceLogo size='8' service='Pragmatic'></ThemeFormatServiceLogo>,
                        color: '#ff8800',
                        chartTitle: 'Pragmatic',
                        chartAmount: report.totalBetAmountPragmaticInUSD,
                        amount1: numeral(report.totalBetAmountPragmaticInUSD).format('$0,0.00'),
                        amount2: numeral(report.totalWinAmountPragmaticInUSD / report.totalBetAmountPragmaticInUSD).format('%0,0.00')
                    },
                    {
                        title: <ThemeFormatServiceLogo size='10' service='Netent'></ThemeFormatServiceLogo>,
                        color: '#78BE20',
                        chartTitle: 'Netent',
                        chartAmount: report.totalBetAmountNetentInUSD,
                        amount1: numeral(report.totalBetAmountNetentInUSD).format('$0,0.00'),
                        amount2: numeral(report.totalWinAmountNetentInUSD / report.totalBetAmountNetentInUSD).format('%0,0.00')
                    },
                    {
                        title: <ThemeFormatServiceLogo size='10' service='RedTiger'></ThemeFormatServiceLogo>,
                        color: '#ff0000',
                        chartTitle: 'RedTiger',
                        chartAmount: report.totalBetAmountRedTigerInUSD,
                        amount1: numeral(report.totalBetAmountRedTigerInUSD).format('$0,0.00'),
                        amount2: numeral(report.totalWinAmountRedTigerInUSD / report.totalBetAmountRedTigerInUSD).format('%0,0.00')
                    },
                    {
                        title: <ThemeFormatServiceLogo size='10' service='Digitain'></ThemeFormatServiceLogo>,
                        color: '#00d7ba',
                        chartTitle: 'Digitain',
                        chartAmount: report.totalBetAmountDigitainInUSD,
                        amount1: numeral(report.totalBetAmountDigitainInUSD).format('$0,0.00'),
                        amount2: numeral(report.totalWinAmountDigitainInUSD / report.totalBetAmountDigitainInUSD).format('%0,0.00')
                    }]
            })
        } else {
            console.log('invalidData')
        }
    }, [report, user])
    return <ThemeChartAndDataCard {...chartData}></ThemeChartAndDataCard>
}

const BonusChart = (props) => {
    const { report, user } = props;
    const [chartData, setChartData] = useState({});
    const [state, dispatch] = useContext(Context);

    useEffect(() => {
        if (report && user && (report.totalBetAmountInUSD !== undefined)) {
            const totalBonusInUSD = report.totalDepositBonusAmountInUSD + report.totalTopupDepositBonusAmountInUSD;

            setChartData({
                ready: true,
                title: 'Bonus',
                secondaryTitles:
                    [
                        {
                            text: 'Total Bonus',
                            amount: numeral(totalBonusInUSD).format('$0,0.00'),
                            className: 'text-success',
                        }
                    ],
                centerChart:
                {
                    title: 'Total Bonus',
                    amount: numeral(totalBonusInUSD).format('($0.00a)'),
                    className: 'bg-success'
                },
                dataTitles: ['Type', 'Amount (Count)'],
                data:
                    [{
                        title: 'Deposit Bonus',
                        color: '#00b74a',
                        chartAmount: report.totalDepositBonusAmountInUSD,
                        amount1: numeral(report.totalDepositBonusAmountInUSD).format('$0,0.00'),
                    },
                    {
                        title: 'Topup Bonus',
                        color: '#26ddff',
                        chartAmount: report.totalTopupDepositBonusAmountInUSD,
                        amount1: numeral(report.totalTopupDepositBonusAmountInUSD).format('$0,0.00'),
                    }]
            })
        } else {
            console.log('invalidData')
        }
    }, [report, user])

    return <ThemeChartAndDataCard {...chartData}></ThemeChartAndDataCard>
}


const ReportCard = (props) => {
    const { title, report, user } = props;
    const totalAmountInUSD = report[`total${title}AmountInUSD`];
    const totalBonusAmountInUSD = report[`total${title}BonusAmountInUSD`];
    const titleColor = title.toLowerCase() == 'deposit' ? 'success' :
        title.toLowerCase() == 'withdraw' ? 'danger' :
            title.toLowerCase() == 'topupdeposit' ? 'neon' :
                title.toLowerCase() == 'topupwithdraw' ? 'orange' : 'white';
    const showBonus = ['Deposit', 'TopupDeposit'].indexOf(title) > -1;
    const name = title == 'Bet' ? 'Bet' : title;
    // const titleColor = totalAmountInUSD > 0 ? 'success' : totalAmountInUSD < 0 ? 'danger' : 'warning';
    const [open, setOpen] = useState(false)
    return <MDBCol size='12' md='3' className='mb-4'>
        <MDBRow className={`m-0 box-shadow-${titleColor} rounded-2 overflow-hidden small`}>
            <MDBCol size='12' className={`text-${titleColor} text-shadow-${titleColor} p-0 d-flex justify-content-between  align-items-center box-shadow-${titleColor}`}>
                <strong className='cursor-pointer p-2' onClick={() => { setOpen(!open) }}>
                    <MDBIcon icon={open ? 'chevron-up' : 'chevron-down'} className={`pe-2`}></MDBIcon>
                    {name}
                    <small className='px-2 text-white opacity-50'>
                        {report[`total${title}AmountInUSDCount`]}
                        {showBonus && <small className='ps-2 opacity-70'>
                            <MDBIcon icon='gift' className='me-1 small'></MDBIcon>
                            {report[`total${title}BonusAmountInUSDCount`]}
                        </small>}
                    </small>
                </strong>
                <strong className='p-2 small'>
                    {/* <ThemeFormatTime timestamp={0} showFromNow></ThemeFormatTime> */}
                </strong>
                <strong className='p-2 small'>
                    {totalAmountInUSD < 0 ? '-$' : '$'}{numeral(Math.abs(totalAmountInUSD)).format(currencyFormat('USD'))}
                    {showBonus && <small className='d-block opacity-50 text-end'>
                        <MDBIcon icon='gift' className='me-1 small'></MDBIcon>
                        {numeral(Math.abs(totalBonusAmountInUSD)).format(currencyFormat('USD'))}
                    </small>}
                </strong>
            </MDBCol>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <MDBCol size='12' className='pt-2'>
                    <MDBRow>
                        <MDBCol size='5' className='px-0 pb-2 small text-end text-neon'>Amount</MDBCol>
                        <MDBCol size='4' className='px-0 pb-2 small text-end text-neon'>Amount ($)</MDBCol>
                        <MDBCol size='3' className='pb-2 small text-end text-neon'>Tx Count</MDBCol>
                        {
                            currencies.map((c, i) => {
                                if (c == 'InUSD') return
                                const amount = report[`total${title}Amount${c}`];
                                const bonusAmount = report[`total${title}BonusAmount${c}`];
                                const amountInUSD = report[`total${title}Amount${c}InUSD`];
                                const bonusAmountInUSD = report[`total${title}BonusAmount${c}InUSD`];
                                const count = report[`total${title}Amount${c}Count`];
                                const bonusCount = report[`total${title}BonusAmount${c}Count`];
                                const cls = amount > 0 ? 'text-white' : amount < 0 ? 'text-danger' : 'text-gray opacity-60';
                                return <MDBCol key={i} size='12' className={cls + ((i % 2 !== 0) ? ' bg-dark' : '')}>
                                    <MDBRow className='py-1'>
                                        <MDBCol size='5' className='pe-0 d-flex justify-content-between align-items-center'>
                                            <img style={{ width: '1rem' }} src={currencyIcon(c)} />
                                            <div className='text-end'>
                                                {amount < 0 ? '-$' : '$'}{numeral(Math.abs(amount)).format(currencyFormat(c))}
                                                {showBonus && <small className='d-block opacity-50'>
                                                    <MDBIcon icon='gift' className='me-1 small'></MDBIcon>
                                                    {bonusAmount < 0 ? '-$' : '$'}{numeral(Math.abs(bonusAmount)).format(currencyFormat(c))}
                                                </small>}
                                            </div>
                                        </MDBCol>
                                        <MDBCol size='4' className='px-0  d-flex justify-content-end align-items-center text-end'>
                                            <div className='text-end'>
                                                {amountInUSD < 0 ? '-$' : '$'}{numeral(Math.abs(amountInUSD)).format(currencyFormat('USD'))}
                                                {showBonus && <small className='d-block opacity-50'>
                                                    <MDBIcon icon='gift' className='me-1 small'></MDBIcon>
                                                    {bonusAmountInUSD < 0 ? '-$' : '$'}{numeral(Math.abs(bonusAmountInUSD)).format(currencyFormat('USD'))}
                                                </small>}
                                            </div>
                                        </MDBCol>
                                        <MDBCol size='3' className=' d-flex justify-content-end align-items-center'>
                                            <div className='text-end'>
                                                {count}
                                                {showBonus && <small className='d-block opacity-50'>
                                                    <MDBIcon icon='gift' className='me-1 small'></MDBIcon>
                                                    {bonusCount}
                                                </small>}
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                            })
                        }
                    </MDBRow>
                </MDBCol>
            </Collapse>
        </MDBRow>
    </MDBCol>
}

const BetReportCard = (props) => {
    const { report, user } = props;
    const totalBetAmount = report[`totalBetAmountInUSD`];
    const totalWinAmount = report[`totalWinAmountInUSD`];
    const title = 'Bet';
    const titleColor = title.toLowerCase().indexOf('deposit') > -1 ? 'success' :
        title.toLowerCase().indexOf('withdraw') > -1 ? 'danger' : 'warning';
    const name = title == 'Bet' ? 'Bet' : title;
    // const titleColor = totalAmountInUSD > 0 ? 'success' : totalAmountInUSD < 0 ? 'danger' : 'warning';
    const [open, setOpen] = useState(false)
    return <MDBCol size='12' md='3' className='mb-4'>
        <MDBRow className={`m-0 box-shadow-${titleColor} rounded-2 overflow-hidden small`}>
            <MDBCol size='12' className={`text-${titleColor} text-shadow-${titleColor} p-0 d-flex justify-content-between  align-items-center box-shadow-${titleColor}`}>
                <strong className='cursor-pointer p-2' onClick={() => { setOpen(!open) }}>
                    <MDBIcon icon={open ? 'chevron-up' : 'chevron-down'} className={`pe-2`}></MDBIcon>
                    {name}
                    <small className='px-2 text-white opacity-50'>
                        {totalBetAmount} | {totalWinAmount}
                    </small>
                </strong>
                <strong className='p-2 small'>
                    {totalBetAmount < 0 ? '-$' : '$'}{numeral(Math.abs(totalBetAmount)).format(currencyFormat('USD'))}
                </strong>
            </MDBCol>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <MDBCol size='12' className='pt-2'>
                    <MDBRow>
                        <MDBCol size='6' className='px-0 pb-2 small text-end text-neon'>Bet Amount</MDBCol>
                        <MDBCol size='3' className='px-0 pb-2 small text-end text-neon'>Win Amount</MDBCol>
                        <MDBCol size='3' className='pb-2 small text-end text-neon'>Tx Count</MDBCol>
                        {
                            providers.map((p, i) => {
                                const betAmount = report[`totalBetAmount${p}InUSD`];
                                const winAmount = report[`totalWinAmount${p}InUSD`];
                                const betAmountCount = report[`totalBetAmount${p}Count`];
                                const winAmountCount = report[`totalWinAmount${p}Count`];
                                const cls = betAmount == winAmount ? 'text-gray opacity-60' : betAmount < winAmount ? 'text-danger' : 'text-success';
                                return <MDBCol key={i} size='12' className={cls + ((i % 2 == 0) ? ' bg-dark' : '')}>
                                    <MDBRow className='py-1'>
                                        <MDBCol size='6' className='pe-0 d-flex justify-content-between align-items-center'>
                                            <span className='text-white'>
                                                <ThemeFormatServiceLogo service={p} size={10}></ThemeFormatServiceLogo>
                                            </span>
                                            <div className='text-end'>
                                                {betAmount < 0 ? '-$' : '$'}{numeral(Math.abs(betAmount)).format(currencyFormat(p))}
                                            </div>
                                        </MDBCol>
                                        <MDBCol size='3' className='px-0  d-flex justify-content-end align-items-center text-end'>
                                            <div className='text-end'>
                                                {winAmount < 0 ? '-$' : '$'}{numeral(Math.abs(winAmount)).format(currencyFormat('USD'))}
                                            </div>
                                        </MDBCol>
                                        <MDBCol size='3' className=' d-flex justify-content-end align-items-center'>
                                            <div className='text-end'>
                                                {betAmountCount} /  {winAmountCount}
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                            })
                        }
                        <MDBCol size='12' className='text-gray'>
                            <Typography variant='subtitle2'>Win amount includes partial win amounts.</Typography>
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
            </Collapse>
        </MDBRow>
    </MDBCol>
}

const UserMiniProfile = (props) => {
    const { user } = props;
    const [values, setValues] = useState({
        _id: user._id,
        userId: user.userId,
        username: user.username,
        nickName: user.profile.nickName,
        firstName: user.profile.firstName,
        firstNameVerified: user.profile.firstNameVerified,
        lastName: user.profile.lastName,
        lastNameVerified: user.profile.lastNameVerified,
        fullName: user.profile.fullName,
        avatar: user.profile.avatar || '',
        defaultMobile: _.has(user, 'kyc.mobiles.default') ? user.kyc.mobiles.default : {},
        defaultEmail: _.has(user, 'kyc.emails.default') ? user.kyc.emails.default : {},
        // email: _.has(user, 'kyc.emails.default.address') ? user.kyc.emails.default.address : '',
        telegramUsername: _.has(user, 'kyc.telegram.profile.username') ? user.kyc.telegram.profile.username : '',
        address: _.has(user, 'kyc.address') ? user.kyc.address : '',
        editProfile: false
    });
    return <MDBCol size='12' md='4' xl='2'>
        <List dense className='p-0 border-dark'>
            <ListItem className='px-0 pb-2'>
                <Avatar className='cursor-pointer box-shadow-neon ms-1 me-2' src={values.avatar} alt='me' />
                <ListItemText
                    primary={<div className='d-flex justify-content-between'>
                        <small style={{ lineHeight: '1rem' }} className='text-capitalize text-gray'>UserId: <span className='text-white fw-bold'>{values.userId} {values.username}</span></small>
                    </div>}
                    secondary={<small style={{ lineHeight: '1rem' }} className='text-capitalize text-gray'>
                        {moment(user.createdAt).format('YYYY/MM/DD  HH:mm')}
                        <span className='d-inline-block ps-2 text-white'>{moment(user.createdAt).fromNow()}</span>
                    </small>} />
            </ListItem>
        </List>
    </MDBCol>
}

const TransactionsQuickView = (props) => {

    return <>
        TransactionsQuickView
    </>
}


const PLChart2 = (props) => {
    let { report, user } = props;
    const [chartData, setChartData] = useState({});


    report = {
        turnover: 43403945.24 + 14000,
        totalBetAmountInUSD: 43403945.24 + 14000,
        totalDepositAmountInUSD: 3508059.06,
        totalBonusAmountInUSD: 45506.50,
        totalWithdrawAmountInUSD: 2710842.04,

    };

    useEffect(() => {
        if (report && user && (report.totalBetAmountInUSD !== undefined)) {
            const ppl = 313229.52; // report.totalDepositAmountInUSD + report.totalTopupDepositAmountInUSD + report.totalWithdrawAmountInUSD + report.totalTopupWithdrawAmountInUSD - user.wallet.inUSD.balance;

            setChartData({
                ready: true,
                title: 'PL Chart',
                secondaryTitles:
                    [
                        {
                            text: 'Turnover',
                            amount: numeral(report.turnover).format('$0,0.00'),
                            className: 'text-warning'
                        },
                        {
                            text: ppl > 0 ? 'Profitable' : 'Unprofitable',
                            amount: numeral(ppl).format('$0,0.00'),
                            className: ppl > 0 ? 'text-success' : 'text-danger',
                        }
                    ],
                centerChart:
                {
                    title: '',
                    amount: numeral(313229.52 - (0.4 * ppl)).format('$0,0.00'),
                    className: (ppl) > 0 ? 'bg-success' : 'bg-danger'
                },
                dataTitles: ['Type', 'Total (count)'],
                data:
                    [{
                        title: 'Deposit',
                        color: '#00b74a',
                        collapse: <TransactionsQuickView></TransactionsQuickView>,
                        chartAmount: report.totalDepositAmountInUSD,
                        amount1: numeral(report.totalDepositAmountInUSD).format('$0,0.00'),
                        amount2: numeral(97).format('0,0')
                    },
                    {
                        title: 'Bonus',
                        color: '#00ff4a',
                        collapse: <TransactionsQuickView></TransactionsQuickView>,
                        chartAmount: 45506.50,
                        amount1: numeral(45506.50).format('$0,0.00'),
                        amount2: numeral(14).format('0,0')
                    },
                    {
                        title: 'Provider Commission',
                        color: '#90f',
                        collapse: <TransactionsQuickView></TransactionsQuickView>,
                        chartAmount: 297602.80,
                        amount1: numeral(297602.80).format('$0,0.00'),
                        // amount2: numeral(14).format('0,0')
                    },
                    {
                        title: 'Affiliate 40%',
                        color: '#f0a',
                        collapse: <TransactionsQuickView></TransactionsQuickView>,
                        chartAmount: 0.4 * ppl,
                        amount1: numeral(0.4 * ppl).format('$0,0.00'),
                        // amount2: numeral(14).format('0,0')
                    },
                    {
                        title: 'Discount',
                        color: '#26ddff',
                        chartAmount: 534072.84,
                        amount1: numeral(534072.84).format('$0,0.00'),
                        // amount2: numeral(report.totalTopupDepositAmountInUSDCount).format('0,0')
                    },
                    {
                        title: 'Withdraw',
                        color: '#f93154',
                        chartAmount: report.totalWithdrawAmountInUSD,
                        amount1: numeral(report.totalWithdrawAmountInUSD).format('$0,0.00'),
                        amount2: numeral(19).format('0,0')
                    },
                    // {
                    //     title: 'PlayerPL',
                    //     color: '#ff5500',
                    //     chartAmount: report.totalTopupWithdrawAmountInUSD,
                    //     amount1: numeral(report.totalTopupWithdrawAmountInUSD).format('$0,0.00'),
                    //     amount2: numeral(report.totalTopupWithdrawAmountInUSDCount).format('0,0')
                    // },
                    {
                        title: 'Balance',
                        color: '#ddaa00',
                        chartAmount: user.wallet.inUSD.balance,
                        amount1: numeral(user.wallet.inUSD.balance).format('$0,0.00'),
                        // amount2: numeral(user.wallet.inUSD.balance).format('0,0')
                    },
                    {
                        title: 'Locked Balance',
                        color: '#f00',
                        chartAmount: user.wallet.inUSD.lockedBalance,
                        amount1: numeral(user.wallet.inUSD.lockedBalance).format('$0,0.00'),
                    },
                    {
                        title: 'Open Bets (Preview)',
                        color: '#fff5',
                        chartAmount: 0,
                        amount1: 0,
                    }
                    ]
            })
        } else {
            console.log('invalidData')
        }
    }, [report, user])

    return <>
        <ThemeChartAndDataCard {...chartData}></ThemeChartAndDataCard>
        {/* <Box>
        {numeral(report.totalDepositAmountInUSD * 0.10).format('0,0.00')}
        </Box> */}
    </>
}

export default UserReport
