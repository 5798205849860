import React, { useContext, useEffect, useState } from 'react'
import { Avatar, Button, Checkbox, Collapse, FormControlLabel, LinearProgress, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Modal, Typography } from '@mui/material'
import { MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit'
import moment from 'moment'
import _ from 'lodash'
import { makeStyles } from '@mui/styles'
import ThemeButton from '../../../layout/components/ThemeButton'
import { getBearer, getStatus } from '../../../helpers/publicHelper'
import { useSnackbar } from 'notistack'
import axios from 'axios'
import { Context } from '../../../context/store'
import ThemeInput from '../../../layout/components/ThemeInput'
import theme from '../../../layout/DefaultTheme'


function getModalStyle() {
    return {
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 500,
        maxWidth: '90%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: 0,
    },
    FormField: {
    }
}));


const UserKYC = (props) => {
    const classes = useStyles();
    const [state, dispatch] = useContext(Context);
    const [modalStyle] = useState(getModalStyle);
    const { user } = props;
    const { score } = user.kyc;

    let kycColor =
        score < 10 ? 'danger' :
            score < 20 ? 'orange' :
                score < 40 ? 'warning' :
                    score < 60 ? 'info' :
                        score <= 90 ? 'neon' : 'success';
    const { enqueueSnackbar } = useSnackbar();
    const [files, setFiles] = useState([]);
    const [kycDetailsProgress, setKycDetailsProgress] = useState({})

    useEffect(() => {
        setFiles([{
            title: 'ID Card',
            name: 'id',
            isRequired: _.has(user, 'kyc.isRequired.id') ? user.kyc.isRequired.id : false,
            description: _.has(user, 'kyc.documents.id.description') ? user.kyc.documents.id.description : '',
            icon: <MDBIcon icon='id-card'></MDBIcon>,
            status: _.has(user, 'kyc.documents.id.status') ? user.kyc.documents.id.status : 'No File',
            url: _.has(user, 'kyc.documents.id.url') ? user.kyc.documents.id.url : '',
            createAt: <small className='text-gray'>{moment().format('YYYY-MM-DD HH:mm')}</small>
        }, {
            title: 'Passport',
            name: 'ppt',
            isRequired: _.has(user, 'kyc.isRequired.ppt') ? user.kyc.isRequired.ppt : false,
            description: _.has(user, 'kyc.documents.ppt.description') ? user.kyc.documents.ppt.description : '',
            icon: <MDBIcon icon='passport'></MDBIcon>,
            status: _.has(user, 'kyc.documents.ppt.status') ? user.kyc.documents.ppt.status : 'No File',
            url: _.has(user, 'kyc.documents.ppt.url') ? user.kyc.documents.ppt.url : '',
            createAt: <small className='text-gray'>{moment().format('YYYY-MM-DD HH:mm')}</small>
        }, {
            title: 'Selfie Photo',
            name: 'pp',
            isRequired: _.has(user, 'kyc.isRequired.pp') ? user.kyc.isRequired.pp : false,
            description: _.has(user, 'kyc.documents.pp.description') ? user.kyc.documents.pp.description : '',
            icon: <MDBIcon icon='user-check'></MDBIcon>,
            status: _.has(user, 'kyc.documents.pp.status') ? user.kyc.documents.pp.status : 'No File',
            url: _.has(user, 'kyc.documents.pp.url') ? user.kyc.documents.pp.url : '',
            createAt: <small className='text-gray'>{moment().format('YYYY-MM-DD HH:mm')}</small>
            // }, {
            //     title: 'Birth Certificate',
            //     name: 'bc',
            //     isRequired: _.has(user, 'kyc.isRequired.bc') ? user.kyc.isRequired.bc : false,
            //     description: _.has(user, 'kyc.documents.bc.description') ? user.kyc.documents.bc.description : '',
            //     icon: <MDBIcon icon='baby'></MDBIcon>,
            //     status: _.has(user, 'kyc.documents.bc.status') ? user.kyc.documents.bc.status : 'No File',
            //     url: _.has(user, 'kyc.documents.bc.url') ? user.kyc.documents.bc.url : '',
            //     createAt: <small className='text-gray'>{moment().format('YYYY-MM-DD HH:mm')}</small>
        }]);

        setKycDetailsProgress({
            email: {
                title: 'Email:',
                max: 10,
                value: _.has(user, 'kyc.emails.default.verified') && user.kyc.emails.default.verified ? 10 : 0
            },
            mobile: {
                title: 'Mobile:',
                max: 10,
                value: _.has(user, 'kyc.mobiles.default.verified') && user.kyc.mobiles.default.verified ? 10 : 0
            },
            address: {
                title: 'Address:',
                max: 10,
                value: _.has(user, 'kyc.address.verified') && user.kyc.address.verified ? 10 : 0
            },
            telegram: {
                title: 'Telegram:',
                max: 10,
                value: _.has(user, 'kyc.telegram.verified') && user.kyc.telegram.verified ? 10 : 0
            },
            id: {
                title: 'ID Card:',
                max: 15,
                value: _.has(user, 'kyc.documents.id.status') && user.kyc.documents.id.status == 'confirmed' ? 15 : 0
            },
            ppt: {
                title: 'Passport:',
                max: 20,
                value: _.has(user, 'kyc.documents.ppt.status') && user.kyc.documents.ppt.status == 'confirmed' ? 20 : 0
            },
            pp: {
                title: 'Selfie Photo:',
                max: 20,
                value: _.has(user, 'kyc.documents.pp.status') && user.kyc.documents.pp.status == 'confirmed' ? 20 : 0
            },
            // bc: {
            //     title: 'Birth Cert:',
            //     max: 15,
            //     value: _.has(user, 'kyc.documents.bc.status') && user.kyc.documents.bc.status == 'confirmed' ? 15 : 0
            // }
        })
    }, [user])

    const [open, setOpen] = useState(false);
    const [modalImage, setModalImage] = useState({
        title: '',
        src: '',
        description: ''
    });
    const handleClose = () => {
        setOpen(false)
    }
    const openModalImage = (file) => {
        setModalImage(file);
        setOpen(true)
    }

    const reviewDocument = (_id, name, status, description) => {
        axios.post(state.active.host + '/manager/users/kyc/documents',
            { _id, name, status, description }, {
            headers: { 'x-auth-token': getBearer() }
        })
            .then(res => {
                res = res.data;
                if (res.success) {
                    enqueueSnackbar(res.message, { variant: 'success' });
                    setOpen(false);
                } else {
                    enqueueSnackbar(res.message, { variant: 'error' })
                }
            })
            .catch((err) => {
                if (err.response) {
                    enqueueSnackbar(err.response.data, { variant: 'error' })
                } else {
                    enqueueSnackbar('Error!', { variant: 'error' })
                }
            })
    }

    const handleChangeIsRequired = (fileName, checked) => {
        axios.post(state.active.host + '/admin/users/kyc/isrequires',
            {
                _id: user._id,
                id: files[0].isRequired,
                ppt: files[1].isRequired,
                pp: files[2].isRequired,
                // bc: files[3].isRequired,
                address: false,
                email: true,
                mobile: true,
                [fileName]: checked
            }, {
            headers: { 'x-auth-token': getBearer() }
        })
            .then(res => {
                res = res.data;
                if (res.success) {
                    let newFiles = _.cloneDeep(files);
                    const index = newFiles.findIndex(f => f.name == fileName);
                    newFiles[index].isRequired = checked;
                    setFiles(newFiles)
                    enqueueSnackbar(res.message, { variant: 'success' });
                    setOpen(false);
                } else {
                    enqueueSnackbar(res.message, { variant: 'error' })
                }
            })
            .catch((err) => {
                if (err.response) {
                    enqueueSnackbar(err.response.status, { variant: 'error' })
                } else {
                    enqueueSnackbar('Error!', { variant: 'error' })
                }
            })
    }

    const [showHelp, setShowHelp] = useState(false)

    return (
        <>
            <MDBRow className='justify-content-center pt-3'
                style={{
                    left: 'auto', right: 'auto',
                    margin: 'auto',
                    bottom: 0
                }}>
                <MDBCol size='2'>
                    Score
                </MDBCol>
                <MDBCol size='8' className='pt-2 px-0'>
                    <LinearProgress variant="determinate" color={kycColor} value={score || 0} />
                </MDBCol>
                <MDBCol size='2' className='ps-0 text-start justify-content-between d-flex align-items-center'>
                    <Typography
                        variant='subtitle1'
                        component='small'
                        className='ps-2'
                        sx={{
                            color: theme.palette[kycColor].main
                        }}>{`${score}%`}</Typography>
                    <MDBIcon onClick={() => setShowHelp(!showHelp)} className='cursor-pointer' icon='question-circle'></MDBIcon>
                </MDBCol>
                <Collapse in={showHelp} timeout="auto" unmountOnExit>
                    <MDBCol size='12' className='pt-3'>
                        {Object.keys(kycDetailsProgress).map((name, i) =>
                            <MDBRow key={i} className='mx-0 py-1'>
                                <MDBCol size='3' className='ps-0 text-capitalize'>{kycDetailsProgress[name].title}</MDBCol>
                                <MDBCol size='8' className='pt-1 px-0'><LinearProgress variant="determinate" color={kycDetailsProgress[name].value ? 'success' : 'white'} value={kycDetailsProgress[name].value} /></MDBCol>
                                <MDBCol size='1' className='p-0 text-end'>%{kycDetailsProgress[name].value}</MDBCol>
                            </MDBRow>
                        )}
                    </MDBCol>
                </Collapse>
                <Collapse in={!showHelp} timeout="auto" unmountOnExit>
                    <MDBCol size='12' className='pt-2'>
                        {files.map((file, i) =>
                            <List dense className={`p-0 py-2 py-md-1 border-dark ' + ${i < 3 ? 'border-bottom' : ''}`} key={i}>
                                <ListItem className='p-0'>
                                    <ListItemAvatar>
                                        <Avatar variant='rounded'>{file.icon}</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={<span>
                                            {file.title}{file.isRequired ? <span className='ps-1 text-danger'>*</span> : ''}
                                            {file.status == 'pending' ?
                                                <MDBIcon className='text-warning mx-1' icon='spinner' spin></MDBIcon> :
                                                file.status == 'confirmed' ?
                                                    <MDBIcon className='text-success mx-1' icon='check'></MDBIcon> :
                                                    file.status == 'rejected' ?
                                                        <MDBIcon className='text-danger mx-1' icon='times'></MDBIcon> :
                                                        <></>
                                            }
                                        </span>}
                                        secondary={<span>{file.createAt}</span>} />
                                    <ListItemSecondaryAction sx={{ right: 0 }}>
                                        {file.url ? <ThemeButton onClick={() => openModalImage(file)} style={{ minWidth: 'auto' }} size='small' variant='outlined' color='neon' className='px-2 me-2'>Preview</ThemeButton> : <></>}
                                        <FormControlLabel
                                            sx={{ paddingLeft: 0, margin: 0, fontSize: '1rem', '& label': { paddingLeft: 0, margin: 0, fontSize: '1rem' }, '& span': { paddingRight: 0, marginRight: 0 } }}
                                            label=''
                                            // onChange={e => handleChangeIsRequired(file.name, e.target.checked)}
                                            control={
                                                <ThemeButton onClick={() => handleChangeIsRequired(file.name, !file.isRequired)} style={{ minWidth: 'auto' }} size='small' variant='outlined' color={file.isRequired ? 'success' : 'warning'} className='px-2'>{file.isRequired ? 'Required' : 'Optional'}</ThemeButton>
                                                // <Checkbox
                                                //     color='neon'
                                                //     checked={file.isRequired}
                                                //     sx={{ '& .MuiSvgIcon-root': { paddingLeft: 0, margin: 0, fontSize: '1rem' } }} />
                                            }
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>
                        )}
                    </MDBCol>
                </Collapse>
            </MDBRow>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                <MDBCard style={modalStyle} className={classes.paper + ' box-shadow-neon overflow-hidden'}>
                    <MDBCardHeader className='text-neon fw-bold bg-black' style={{ borderBottom: '1px solid #333' }}>
                        {modalImage.title} <small className={`text-capitalize pe-2 text-${getStatus(modalImage.status).color}`}>({modalImage.status})</small>
                        <MDBIcon onClick={handleClose} className='float-end pt-1 cursor-pointer' icon='times'></MDBIcon>
                    </MDBCardHeader>
                    <MDBCardBody className='p-0'>
                        <MDBRow className='text-center mx-0'>
                            <MDBCol size='12'>
                                <img height='300' width='300' src={modalImage.url} className='img-fluid m-auto p-1' />
                            </MDBCol>
                            <MDBCol size='12' className='text-start'>
                                <ThemeInput onChange={e => setModalImage({ ...modalImage, description: e.target.value })} label='Description' className='my-2' rows='2' multiline value={modalImage.description}></ThemeInput>
                            </MDBCol>
                            <MDBCol size='12' className='bg-black'>
                                <ThemeButton onClick={() => reviewDocument(user._id, modalImage.name, 'confirmed', modalImage.description)} className='px-2 m-1 my-2' color='success' disabled={modalImage.status == 'confirmed' ? true : false} variant={modalImage.status == 'confirmed' ? '' : 'outlined'}><MDBIcon icon='check' className='me-1'></MDBIcon> Confirm</ThemeButton>
                                <ThemeButton onClick={() => reviewDocument(user._id, modalImage.name, 'pending', modalImage.description)} className='px-2 m-1 my-2' color='warning' disabled={modalImage.status == 'pending' ? true : false}  ><MDBIcon icon='check' className='me-1'></MDBIcon> Pending</ThemeButton>
                                <ThemeButton onClick={() => reviewDocument(user._id, modalImage.name, 'rejected', modalImage.description)} className='px-2 m-1 my-2' color='danger' disabled={modalImage.status == 'rejected' ? true : false} ><MDBIcon icon='check' className='me-1'></MDBIcon> Rejected</ThemeButton>
                            </MDBCol>
                        </MDBRow>
                    </MDBCardBody>
                </MDBCard>
            </Modal>
        </>
    )
}

export default UserKYC
