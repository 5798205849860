import { Collapse, Grid, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import { Box } from '@mui/system';
import { MDBIcon } from 'mdb-react-ui-kit';
import numeral from 'numeral';
import React, { useState } from 'react'
import { currencyFormat } from '../../helpers/currency';

const datas = {
    "financial": {
        "deposit": {
            "deposit": {
                "USDTTRX": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "USDTETH": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "BTC": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "ETH": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "TRX": {
                    "count": 9,
                    "totalAmount": 16.7,
                    "totalAmountInUSD": 1.132468
                },
                "DOGE": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                }
            },
            "bonus": {
                "USDTTRX": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "USDTETH": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "BTC": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "ETH": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "TRX": {
                    "count": 3,
                    "totalAmount": 11.21,
                    "totalAmountInUSD": 0.7377133
                },
                "DOGE": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                }
            }
        },
        "withdraw": {
            "USDTTRX": {
                "count": 1,
                "totalAmount": -178292,
                "totalAmountInUSD": -178292
            },
            "USDTETH": {
                "count": 0,
                "totalAmount": 0,
                "totalAmountInUSD": 0
            },
            "BTC": {
                "count": 0,
                "totalAmount": 0,
                "totalAmountInUSD": 0
            },
            "ETH": {
                "count": 0,
                "totalAmount": 0,
                "totalAmountInUSD": 0
            },
            "TRX": {
                "count": 3,
                "totalAmount": -39,
                "totalAmountInUSD": -2.66799
            },
            "DOGE": {
                "count": 0,
                "totalAmount": 0,
                "totalAmountInUSD": 0
            }
        },
        "topup": {
            "discount": {
                "USDT": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "BTC": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "ETH": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "TRX": {
                    "count": 2,
                    "totalAmount": 10,
                    "totalAmountInUSD": 0.6476
                },
                "DOGE": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                }
            },
            "deposit": {
                "USDT": {
                    "count": 1,
                    "totalAmount": 200000,
                    "totalAmountInUSD": 200000
                },
                "BTC": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "ETH": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "TRX": {
                    "count": 1,
                    "totalAmount": 100,
                    "totalAmountInUSD": 6.605999999999999
                },
                "DOGE": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                }
            },
            "withdraw": {
                "USDT": {
                    "count": 1,
                    "totalAmount": -220000,
                    "totalAmountInUSD": -220000
                },
                "BTC": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "ETH": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "TRX": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "DOGE": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                }
            },
            "bonus": {
                "USDT": {
                    "count": 2,
                    "totalAmount": 211000,
                    "totalAmountInUSD": 210992.3
                },
                "BTC": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "ETH": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "TRX": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "DOGE": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                }
            }
        }
    },
    "play": {
        "USD": {
            "Evolution": {
                "bet": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "win": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "open": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "cashback": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                }
            },
            "Netent": {
                "bet": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "win": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "open": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "cashback": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                }
            },
            "RedTiger": {
                "bet": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "win": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "open": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "cashback": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                }
            },
            "EveryMatrix": {
                "bet": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "win": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "open": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "cashback": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                }
            },
            "Pragmatic": {
                "bet": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "win": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "open": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "cashback": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                }
            },
            "Crash": {
                "bet": {
                    "count": 28,
                    "totalAmount": 65,
                    "totalAmountInUSD": 65
                },
                "win": {
                    "count": 19,
                    "totalAmount": 68.2,
                    "totalAmountInUSD": 68.2
                },
                "open": {
                    "count": 10,
                    "totalAmount": 30,
                    "totalAmountInUSD": 30
                },
                "cashback": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                }
            }
        },
        "USDT": {
            "Evolution": {
                "bet": {
                    "count": 11,
                    "totalAmount": 109.07525221631528,
                    "totalAmountInUSD": 109
                },
                "win": {
                    "count": 5,
                    "totalAmount": 79.65458789942035,
                    "totalAmountInUSD": 79.60000000000001
                },
                "open": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "cashback": {
                    "count": 1,
                    "totalAmount": 25.01751225858101,
                    "totalAmountInUSD": 25
                }
            },
            "Netent": {
                "bet": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "win": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "open": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "cashback": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                }
            },
            "RedTiger": {
                "bet": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "win": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "open": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "cashback": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                }
            },
            "EveryMatrix": {
                "bet": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "win": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "open": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "cashback": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                }
            },
            "Pragmatic": {
                "bet": {
                    "count": 4,
                    "totalAmount": 4,
                    "totalAmountInUSD": 4
                },
                "win": {
                    "count": 3,
                    "totalAmount": 4.65,
                    "totalAmountInUSD": 4.65
                },
                "open": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                },
                "cashback": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                }
            },
            "Crash": {
                "bet": {
                    "count": 60,
                    "totalAmount": 678.3235970092182,
                    "totalAmountInUSD": 678
                },
                "win": {
                    "count": 28,
                    "totalAmount": 971.8003299128707,
                    "totalAmountInUSD": 971.3399999999999
                },
                "open": {
                    "count": 25,
                    "totalAmount": 77.04302657737969,
                    "totalAmountInUSD": 77
                },
                "cashback": {
                    "count": 0,
                    "totalAmount": 0,
                    "totalAmountInUSD": 0
                }
            }
        },
        "BTC": {
            "Evolution": {
                "bet": {
                    "count": 0,
                    "totalAmount": 0
                },
                "win": {
                    "count": 0,
                    "totalAmount": 0
                },
                "open": {
                    "count": 0,
                    "totalAmount": 0
                },
                "cashback": {
                    "count": 0,
                    "totalAmount": 0
                }
            },
            "Netent": {
                "bet": {
                    "count": 0,
                    "totalAmount": 0
                },
                "win": {
                    "count": 0,
                    "totalAmount": 0
                },
                "open": {
                    "count": 0,
                    "totalAmount": 0
                },
                "cashback": {
                    "count": 0,
                    "totalAmount": 0
                }
            },
            "RedTiger": {
                "bet": {
                    "count": 0,
                    "totalAmount": 0
                },
                "win": {
                    "count": 0,
                    "totalAmount": 0
                },
                "open": {
                    "count": 0,
                    "totalAmount": 0
                },
                "cashback": {
                    "count": 0,
                    "totalAmount": 0
                }
            },
            "EveryMatrix": {
                "bet": {
                    "count": 0,
                    "totalAmount": 0
                },
                "win": {
                    "count": 0,
                    "totalAmount": 0
                },
                "open": {
                    "count": 0,
                    "totalAmount": 0
                },
                "cashback": {
                    "count": 0,
                    "totalAmount": 0
                }
            },
            "Pragmatic": {
                "bet": {
                    "count": 0,
                    "totalAmount": 0
                },
                "win": {
                    "count": 0,
                    "totalAmount": 0
                },
                "open": {
                    "count": 0,
                    "totalAmount": 0
                },
                "cashback": {
                    "count": 0,
                    "totalAmount": 0
                }
            },
            "Crash": {
                "bet": {
                    "count": 0,
                    "totalAmount": 0
                },
                "win": {
                    "count": 0,
                    "totalAmount": 0
                },
                "open": {
                    "count": 0,
                    "totalAmount": 0
                },
                "cashback": {
                    "count": 0,
                    "totalAmount": 0
                }
            }
        },
        "ETH": {
            "Evolution": {
                "bet": {
                    "count": 0,
                    "totalAmount": 0
                },
                "win": {
                    "count": 0,
                    "totalAmount": 0
                },
                "open": {
                    "count": 0,
                    "totalAmount": 0
                },
                "cashback": {
                    "count": 0,
                    "totalAmount": 0
                }
            },
            "Netent": {
                "bet": {
                    "count": 0,
                    "totalAmount": 0
                },
                "win": {
                    "count": 0,
                    "totalAmount": 0
                },
                "open": {
                    "count": 0,
                    "totalAmount": 0
                },
                "cashback": {
                    "count": 0,
                    "totalAmount": 0
                }
            },
            "RedTiger": {
                "bet": {
                    "count": 0,
                    "totalAmount": 0
                },
                "win": {
                    "count": 0,
                    "totalAmount": 0
                },
                "open": {
                    "count": 0,
                    "totalAmount": 0
                },
                "cashback": {
                    "count": 0,
                    "totalAmount": 0
                }
            },
            "EveryMatrix": {
                "bet": {
                    "count": 0,
                    "totalAmount": 0
                },
                "win": {
                    "count": 0,
                    "totalAmount": 0
                },
                "open": {
                    "count": 0,
                    "totalAmount": 0
                },
                "cashback": {
                    "count": 0,
                    "totalAmount": 0
                }
            },
            "Pragmatic": {
                "bet": {
                    "count": 0,
                    "totalAmount": 0
                },
                "win": {
                    "count": 0,
                    "totalAmount": 0
                },
                "open": {
                    "count": 0,
                    "totalAmount": 0
                },
                "cashback": {
                    "count": 0,
                    "totalAmount": 0
                }
            },
            "Crash": {
                "bet": {
                    "count": 0,
                    "totalAmount": 0
                },
                "win": {
                    "count": 0,
                    "totalAmount": 0
                },
                "open": {
                    "count": 0,
                    "totalAmount": 0
                },
                "cashback": {
                    "count": 0,
                    "totalAmount": 0
                }
            }
        },
        "TRX": {
            "Evolution": {
                "bet": {
                    "count": 27,
                    "totalAmount": 2917.125304481797
                },
                "win": {
                    "count": 14,
                    "totalAmount": 2662.5611955253485
                },
                "open": {
                    "count": 0,
                    "totalAmount": 0
                },
                "cashback": {
                    "count": 1,
                    "totalAmount": 29.86857825567503
                }
            },
            "Netent": {
                "bet": {
                    "count": 0,
                    "totalAmount": 0
                },
                "win": {
                    "count": 0,
                    "totalAmount": 0
                },
                "open": {
                    "count": 0,
                    "totalAmount": 0
                },
                "cashback": {
                    "count": 0,
                    "totalAmount": 0
                }
            },
            "RedTiger": {
                "bet": {
                    "count": 0,
                    "totalAmount": 0
                },
                "win": {
                    "count": 0,
                    "totalAmount": 0
                },
                "open": {
                    "count": 0,
                    "totalAmount": 0
                },
                "cashback": {
                    "count": 0,
                    "totalAmount": 0
                }
            },
            "EveryMatrix": {
                "bet": {
                    "count": 0,
                    "totalAmount": 0
                },
                "win": {
                    "count": 0,
                    "totalAmount": 0
                },
                "open": {
                    "count": 0,
                    "totalAmount": 0
                },
                "cashback": {
                    "count": 0,
                    "totalAmount": 0
                }
            },
            "Pragmatic": {
                "bet": {
                    "count": 2,
                    "totalAmount": 43.07108672766529
                },
                "win": {
                    "count": 2,
                    "totalAmount": 56.50814471917873
                },
                "open": {
                    "count": 0,
                    "totalAmount": 0
                },
                "cashback": {
                    "count": 1,
                    "totalAmount": 28.926815157651145
                }
            },
            "Crash": {
                "bet": {
                    "count": 107,
                    "totalAmount": 6983.876673345868
                },
                "win": {
                    "count": 58,
                    "totalAmount": 8698.846471396959
                },
                "open": {
                    "count": 26,
                    "totalAmount": 378.75282893238483
                },
                "cashback": {
                    "count": 0,
                    "totalAmount": 0
                }
            }
        },
        "DOGE": {
            "Evolution": {
                "bet": {
                    "count": 0,
                    "totalAmount": 0
                },
                "win": {
                    "count": 0,
                    "totalAmount": 0
                },
                "open": {
                    "count": 0,
                    "totalAmount": 0
                },
                "cashback": {
                    "count": 0,
                    "totalAmount": 0
                }
            },
            "Netent": {
                "bet": {
                    "count": 0,
                    "totalAmount": 0
                },
                "win": {
                    "count": 0,
                    "totalAmount": 0
                },
                "open": {
                    "count": 0,
                    "totalAmount": 0
                },
                "cashback": {
                    "count": 0,
                    "totalAmount": 0
                }
            },
            "RedTiger": {
                "bet": {
                    "count": 0,
                    "totalAmount": 0
                },
                "win": {
                    "count": 0,
                    "totalAmount": 0
                },
                "open": {
                    "count": 0,
                    "totalAmount": 0
                },
                "cashback": {
                    "count": 0,
                    "totalAmount": 0
                }
            },
            "EveryMatrix": {
                "bet": {
                    "count": 0,
                    "totalAmount": 0
                },
                "win": {
                    "count": 0,
                    "totalAmount": 0
                },
                "open": {
                    "count": 0,
                    "totalAmount": 0
                },
                "cashback": {
                    "count": 0,
                    "totalAmount": 0
                }
            },
            "Pragmatic": {
                "bet": {
                    "count": 0,
                    "totalAmount": 0
                },
                "win": {
                    "count": 0,
                    "totalAmount": 0
                },
                "open": {
                    "count": 0,
                    "totalAmount": 0
                },
                "cashback": {
                    "count": 0,
                    "totalAmount": 0
                }
            },
            "Crash": {
                "bet": {
                    "count": 0,
                    "totalAmount": 0
                },
                "win": {
                    "count": 0,
                    "totalAmount": 0
                },
                "open": {
                    "count": 0,
                    "totalAmount": 0
                },
                "cashback": {
                    "count": 0,
                    "totalAmount": 0
                }
            }
        }
    }
};

const financialTypes = ['deposit', 'withdraw', 'topup'];
const gameTypes = ['bet'];
const financialSubTypes = ['deposit', 'withdraw', 'discount', 'bonus'];
const gameProviders = ['EveryMatrix', 'Pragmatic', 'Evolution', 'Crash', 'Netent', 'Digitain', 'RedTiger'];
const financialCurrencies = ['USDTTRX', 'USDTETH', 'BTC', 'ETH', 'TRX', 'DOGE'];
const playCurrencies = ['USD', 'USDT', 'BTC', 'TRX', 'ETH', 'DOGE'];

const calcTotalFinancialInUSD = (data) => {
    let result = {
        count: 0,
        totalAmountInUSD: 0,
    };
    for (const currency of financialCurrencies) {
        console.log(currency, data[currency])
        result.totalAmountInUSD = result.totalAmountInUSD + data[currency].totalAmountInUSD;
        result.count = result.count + (data[currency] ? data[currency].count : 0);
    }
    return result
}

const UserReport2 = (props) => {

    return (
        <Grid container className='small'>
            <FinancialCard title='Deposit' data={datas.financial.deposit.deposit} />

            <Grid item xs={12}>User Report 2</Grid>
            <Grid item xs={12}>
                {/* <Financial {...data.financial} /> */}
            </Grid>
            {/* {financialTypes.map((item, i) => <Box key={i}></Box>)} */}

            {/* BET */}
            <hr />
            {/* <Grid container columns={16} className='align-items-center'>
                <Grid item xs={2}></Grid>
                {['bet', 'win', 'open', 'cashback'].map((c, i) => <Grid className='text-center' item xs={2} key={i}>{c}</Grid>)}
                <Grid item xs={2}>Total in USD</Grid>
            </Grid> */}
            {/* {
                gameProviders.map((provider, i) =>
                    <PlaySection key={i} data={data.play} title={provider} color='orange' />
                )
            } */}
        </Grid>
    )
}

const Financial = (props) => {
    const { deposit: deposits, withdraw, topup } = props;
    const { deposit, bonus } = deposits;
    const { discount: topupDiscount, deposit: topupDeposit, withdraw: topupWithdraw, bonus: topupBonus } = topup;
    return <Grid item xs={12}>
        <Grid container columns={18} className='align-items-center'>
            <Grid item xs={2}></Grid>
            {financialCurrencies.map((c, i) => <Grid className='text-center' item xs={2} key={i}>{c}</Grid>)}
            <Grid item xs={2}>Total in USD</Grid>
        </Grid>
    </Grid >
}

const FinancialSection = (props) => {
    const { data, title, color } = props;
    return <Grid container columns={18} className='align-items-center border-top pt-1 mt-1'>
        <Grid item xs={2} className={`text-${color}`}>{title}</Grid>
        {financialCurrencies.map((c, i) => {
            const item = data[c];
            return <Grid item xs={2} key={i}>
                <AAC {...item} currency={c} color={color} />
            </Grid>
        })}
        <Grid item xs={2} className={`fw-bold  text-${Math.abs(calcTotalFinancialInUSD(data)) > 0 ? color : 'gray'}`}>
            {numeral(calcTotalFinancialInUSD(data)).format('$0,0.00')}
        </Grid>
    </Grid>
}


const calsPlays = (play) => {
    const result = { bet: 0, win: 0, open: 0, cashback: 0 };
    for (const currency of playCurrencies) {
        for (const provider of gameProviders) {
            result.bet = result.bet + play[currency][provider].bet.totalAmountInUSD;
            result.win = result.win + play[currency][provider].win.totalAmountInUSD;
            result.open = result.open + play[currency][provider].open.totalAmountInUSD;
            result.cashback = result.cashback + play[currency][provider].cashback.totalAmountInUSD;
        }
    }
    return result
}

const PlaySection = (props) => {
    const { data, title, color } = props;
    const newData = {
        totalAmountInUSD:
            data['USD'].totalAmountInUSD +
            data['USDT'].totalAmountInUSD +
            data['BTC'].totalAmountInUSD +
            data['ETH'].totalAmountInUSD +
            data['TRX'].totalAmountInUSD +
            data['DOGE'].totalAmountInUSD
    }
    return <Grid container columns={16} className='align-items-center border-top pt-1 mt-1'>
        <Grid item xs={2} className={`text-${color}`}>{title}</Grid>
        {['bet', 'win', 'open', 'cashback'].map((c, i) => {
            const item = newData[c];
            return <Grid item xs={2} key={i}>
                {item.totalAmountInUSD}
                {/* <AAC {...item} currency={c} color={color} /> */}
            </Grid>
        })}
        <Grid item xs={2} className={`fw-bold  text-${Math.abs(calcTotalFinancialInUSD(newData)) > 0 ? color : 'gray'}`}>
            {/* {numeral(calcTotalInUSD(data)).format('$0,0.00')} */}
        </Grid>
    </Grid>
}

const AAC = (props) => {
    const { totalAmount, totalAmountInUSD, count, currency, color } = props;
    const realCurrency = currency.substr(0, 4);
    const blockchain = currency.substr(0, 4) == 'USDT' ? currency.substr(4, 6) : currency;
    return <Grid container direction='column' className={`w-100 align-items-center justify-content-center small text-${Math.abs(totalAmount) > 0 ? color : 'gray'}`}>
        <Grid item xs={2}>{numeral(totalAmountInUSD).format('$0,0.00')}</Grid>
        <Grid item xs={2}>{numeral(totalAmount).format(currencyFormat(realCurrency))}</Grid>
        <Grid item xs={2}>{count}</Grid>
    </Grid>
}

const colors = {
    'deposit': green[500],
    'deposit bonus': green[500],
    'withdraw': green[500],
    'topup deposit': green[500],
    'topup bonus': green[500],
    'topup withdraw': green[500],
    'topup discount': green[500]
}

const FinancialCard = (props) => {
    const { title, data, currency } = props;

    const totalAmounInUSD = calcTotalFinancialInUSD(data).totalAmountInUSD;
    const totalCount = calcTotalFinancialInUSD(data).totalAmountInUSD;
    const [open, setOpen] = useState(false);
    return <Grid item xs={12} md={3} className='border border-warning w-100'>
        <Box className='d-flex justify-content-between'>
            <Box sx={{
                bgcolor: '#000',
                color: colors[title.toLowerCase()]
            }}>
                <MDBIcon className='cursor-pointer p-2' onClick={() => setOpen(!open)} icon={open ? 'chevron-up' : 'chevron-down'} />
                {title}
            </Box>
            <Box className='d-flex align-items-center pe-2'>
                <Typography sx={{ color: colors[title.toLowerCase()] }} variant='body2'>
                    {numeral(totalAmounInUSD).format('$0,0.00')}
                </Typography>
                <Typography className='opacity-50 ps-2' variant='subtitle2'>
                    {numeral(totalCount).format('0,0')}
                </Typography>
            </Box>
        </Box>
        <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
                {financialCurrencies.map((c, i) => <FinancialItem key={i} currency={c} {...data[c]} />)}
            </Box>
        </Collapse>
    </Grid>
}

const FinancialItem = (props) => {
    const { count, totalAmount, totalAmountInUSD, currency } = props;
    return <Box className='d-flex justify-content-between'>
        <Box>{currency}</Box>
        <Box></Box>
        <Box></Box>
        <Box></Box>
        {count}
    </Box>
}

export default UserReport2