import React, { useContext, useEffect, useRef, useState } from 'react';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import { Avatar, Box, Chip, Grid, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import moment from 'moment';
import { socket } from '../../context/socket';
import $ from 'jquery';
import { Context } from '../../context/store';
import Sound from './Sound';
import UserDetails from '../Tables/UsersTable/UserDetails';
import axios from 'axios';
import { getBearer } from '../../helpers/publicHelper';

const scrollEnd = () => {
    $('#ChatMessagesWrapper').animate({
        scrollTop: 20000
    }, 50);
}

const SupportChatBox = (props) => {
    const [chat, setChat] = useState(props.activeChat);
    const [state, dispatch] = useContext(Context);

    const addMessage = (message) => {
        socket.emit('chat', {
            ...message
        })
    }

    const chatRef = useRef();
    chatRef.current = chat;
    const [playSound, setPlaySound] = useState(false)
    useEffect(() => {
        console.log('supportchatbox useEffect')
        setChat(props.activeChat)
        socket.on('chat', message => {
            if (message.chatUserId == chatRef.current.userId) {
                if (message.sender && message.sender._id && (message.sender._id !== state.user.profile._id)) {
                    setPlaySound(Date.now());
                }
                let cloneMessages = JSON.parse(JSON.stringify(chatRef.current.messages));
                cloneMessages.push(message);
                setChat({ ...chatRef.current, messages: cloneMessages });
                scrollEnd();
            }
        })
        // socket.on('newChat', message => {
        //     let cloneMessages = JSON.parse(JSON.stringify(chatRef.current.messages));
        //     cloneMessages.push(message);
        //     setChat({ ...chatRef.current, messages: cloneMessages });
        //     scrollEnd();
        // })
        scrollEnd();
        return () => {
            socket.removeAllListeners("chat");
        }
    }, [props.activeChat])


    const [user, setUser] = useState({})
    const getUsersFromAPI = (userId) => {
        axios.post(state.active.host + '/admin/users', {
            find: { userId }
        }, {
            headers: { 'x-auth-token': getBearer() }
        })
            .then(apiRes => {
                apiRes = apiRes.data;
                if (apiRes.success) {
                    if (apiRes.result && apiRes.result.users && apiRes.result.users.length) {
                        setUser(apiRes.result.users[0])
                    } else {
                        setUser({})
                    }
                }
            })
    }

    useEffect(() => {
        console.log(chat.userId)
        if (chat.userId) {
            getUsersFromAPI(chat.userId);
        }

        return () => {
            // setValues({ ...values, user: {} })
        }
    }, [chat.userId])

    return (
        <>
            {/* <Sound play={playSound}></Sound> */}
            <MDBRow className='mx-0 h-50 bg-info- overflow-scroll align-items-baseline'>
                {/* MEMBERS */}
                <MDBCol size='12' className='border-bottom pb-1 '>
                    {
                        chat.members ?
                            Object.keys(chat.members).map((userId, i) => {
                                const user = chat.members[userId];
                                const chatUserId = chat.userId;
                                const isMe = chatUserId == userId;
                                return <Chip
                                    className='me-1'
                                    key={i}
                                    avatar={<Avatar className={isMe ? 'bg-neon box-shadow-neon' : 'bg-success'} alt="admin">
                                        {isMe ?
                                            <MDBIcon icon='moon'></MDBIcon>
                                            :
                                            <MDBIcon icon='user'></MDBIcon>
                                        }
                                    </Avatar>}
                                    label={`${userId} | ${user.username}`}
                                    variant="outlined"
                                />
                            })
                            : <></>
                    }
                </MDBCol>
                <MDBCol size='12' id='ChatMessagesWrapper' className='noScrollbar' style={{ maxHeight: 'calc(100vh - 224px)', overflow: 'auto' }}>
                    {chat.messages && chat.messages.map((message, i) => {
                        const { _id: messageId, createdAt, updatedAt, chatUserId, text, sender } = message;
                        const { _id: sender_id, username, userId: senderUserId, role, avatar } = sender;
                        const isSupport = role.indexOf('support') > -1;
                        const isMe = state.user.profile._id == sender_id;
                        const bgColor = isMe ? blue[400] : isSupport ? '#060' : '#222';
                        let dayChanged = (chat.messages[i - 1] && (moment(createdAt).format('YYYY-MM-DD') !== moment(chat.messages[i - 1].createdAt).format('YYYY-MM-DD'))) ? createdAt : false;
                        if (i == 0) dayChanged = createdAt
                        return <MDBRow key={i}>
                            {dayChanged &&
                                <MDBCol size='12' className='text-center my-2 small'>
                                    <Box className='d-inline box-shadow-neon text-neon text-shadow-neon px-3 py-1 border border-neon small rounded-pill'>
                                        <span>
                                            {moment(createdAt).format('DD MMM YYYY')}
                                        </span>
                                    </Box>
                                </MDBCol>
                            }
                            <MDBCol className={isMe ? 'offset-2_ pe-0' : ''}>
                                <Grid className='my-1' container justifyContent='space-between' alignItems='end'>
                                    {!isMe ?
                                        <Grid item xs='auto' sx={{ zIndex: 99 }}>
                                            <Avatar alt="admin" src={avatar}>{/* <MDBIcon icon='user'></MDBIcon> */}</Avatar>
                                        </Grid>
                                        : <></>
                                    }
                                    <Grid item xs className='p-0 ms-n2  position-relative _overflow-hidden'>
                                        <svg viewBox="0 0 60 42"
                                            style={{
                                                position: 'absolute',
                                                left: !isMe ? 0 : 'auto',
                                                right: isMe ? 0 : 'auto',
                                                bottom: 0,
                                                height: '1.6rem',
                                                zIndex: 0,
                                                transform: isMe ? 'scaleX(-1)' : 'none'
                                            }}>
                                            <g>
                                                <path fill={bgColor} d="M40 0l25 0 0 0c0,22 -19,42 -42,42l-19 0c23,0 30,-20 30,-42l11 0z"></path>
                                            </g>
                                        </svg>
                                        <Typography
                                            component='div'
                                            sx={{
                                                width: 'fit-content',
                                                maxWidth: '80%',
                                                backgroundColor: bgColor,
                                                zIndex: 9,
                                                marginRight: isMe ? '1.05rem' : 'auto',
                                                marginLeft: !isMe ? '1.05rem' : 'auto',
                                                zIndex: 0,
                                                borderBottomLeftRadius: isMe ? '1.5rem !important' : '1rem !important',
                                                borderBottomRightRadius: !isMe ? '1.5rem !important' : '1rem !important'
                                            }}
                                            variant='body2'
                                            className='chatMessage p-2 px-3 rounded-9 position-relative'>
                                            {!isMe ? <span className='text-neon mb-0 d-block'>{username}</span> : <></>}
                                            {text}<small className='small opacity-0 d-list-item float-right px-1' style={{ fontSize: '0.5rem' }}>{moment(updatedAt).format('h:mm')}</small>
                                            <small className='small position-absolute opacity-60 float-right ps-1' style={{
                                                bottom: '0.5rem',
                                                right: '0.6rem',
                                                fontSize: '0.7rem'
                                            }}>{moment(updatedAt).format('h:mm')}</small>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </MDBCol>
                        </MDBRow>
                    }
                    )}
                </MDBCol>
            </MDBRow >
            <MDBRow>
                <SendBox chatUserId={chat.userId} userId={state.user.profile.userId} addMessage={addMessage}></SendBox>
            </MDBRow>
            <MDBRow>
                {user.userId && <UserDetails key={user.userId} user={user} activeTab={{ index: 0, activeTab: '' }} />}
            </MDBRow>

        </>
    )
}

const SendBox = (props) => {
    const [state, dispatch] = useContext(Context);
    const { addMessage, userId, chatUserId } = props;
    const [values, setValues] = useState({
        userId: userId,
        text: '',
        time: Date.now(),
        chatUserId: chatUserId
    })

    useEffect(() => {
        setValues({
            ...values,
            chatUserId: chatUserId
        })
    }, [chatUserId])

    const sendMessage = (e) => {
        e.preventDefault();
        addMessage(values);
        setValues({ ...values, text: '' })
    }

    return <div className='position-absolute- w-100 p-0 pb-2 pt-2 box-shadow-neon' style={{
        backgroundColor: '#222',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 99,
        // width: state.active.layoutDrawer.left ? 'calc(100vw - 13rem)' : '100vw',
    }}>
        <form className='position-relative d-flex' style={{ alignItems: 'center' }} onSubmit={sendMessage}>
            <MDBIcon icon='paperclip' className='px-2 d-inline-block text-white opacity-50' style={{ fontSize: '1.6rem' }}></MDBIcon>
            <div className='position-relative d-inline-block bg-black rounded-2 px-1' style={{ flex: 1 }}>
                <textarea rows='3' onChange={e => setValues({ ...values, text: e.target.value })} value={values.text} className='bg-transparent d-inline-block border-0 p-2 text-white pe-3 w-100' style={{ outline: 'none', resize: 'none' }} />
            </div>
            <button type='submit' className='bg-transparent border-0 p-0' style={{ outline: 'none' }}>
                <MDBIcon icon='arrow-alt-circle-up' size='2x' className={'pt-0 fw-light mx-2 d-inline-block text-neon text-shadow-neon rounded-circle ' + (!values.text ? 'opacity-30' : '')} style={{ fontSize: '1.9rem' }}></MDBIcon>
            </button>

        </form>
    </div>
}


export default SupportChatBox
