import React from 'react'
import { Box } from '@mui/system';
import { MDBIcon } from 'mdb-react-ui-kit';

const TelegramTXDetails = (props) => {
    const { comments } = props;
    if (!comments || !comments.length || !comments[0]) return <>No Comment</>
    return <>
        {/* {comments.map((comment, i) => <Comment key={i} {...comment} />)} */}
    </>
}



export default TelegramTXDetails