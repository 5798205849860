import React, { useEffect, useState, useRef, useContext, useLayoutEffect } from 'react';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import axios from 'axios';
import { Alert, Pagination } from '@mui/material';
import { currencyDetail,  formatAmountCurrency } from '../../helpers/currency';
import ThemeButton from '../../layout/components/ThemeButton';
import ResponsiveTable from '../Tables/ResponsiveTable';
import { convertByRates, getBearer } from '../../helpers/publicHelper';
import _ from 'lodash';
import numeral from 'numeral';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSnackbar } from 'notistack';
import ReactJson from 'react-json-view';
import TransferToTreasuryRequestsFilter from './TransferToTreasuryRequestsFilter.js';
import UserQuickViewButton from '../Users/UserQuickViewButton';
import ThemeFormatTime from '../../layout/components/ThemeFormatTime';
import ThemeFormatCurrency from '../../layout/components/ThemeFormatCurrency';
import { BreadCrumbContext, Context } from '../../context/store';
import { useParams } from 'react-router-dom';

const FormatAmount = (all) => {
    const [state, dispatch] = useContext(Context);
    const { amount, currency } = all;
    return <div className='pe-1'>
        {numeral(formatAmountCurrency(amount, currency)).format(currencyDetail[currency].format)}
        <small className='d-block opacity-50'>
            {numeral(convertByRates(state, amount, currency, 'USD')).format('$0,0.00')}
        </small>
    </div>
}

const FormatFee = (all) => {
    const [state, dispatch] = useContext(Context);
    const { fee, currency } = all;
    return <div className='pe-1'>
        {numeral(formatAmountCurrency(fee, currency)).format(currencyDetail[currency].format)}
        <small className='d-block opacity-50'>
            {numeral(convertByRates(state, fee, currency, 'USD')).format('$0,0.00')}
        </small>
    </div>
}

const formatDateTime = (time) => {
    return <ThemeFormatTime timestamp={time} showFromNow></ThemeFormatTime>
}

const FormatWalletAddress = (all) => {
    const { enqueueSnackbar } = useSnackbar();
    const { userWalletAddress } = all;

    return <div className='d-flex justify-content-start align-items-center'>
        <ThemeFormatCurrency icon='png' mode='address' {...all} text={userWalletAddress} />
        <CopyToClipboard
            onCopy={() => { enqueueSnackbar('Copied.', { variant: 'warning', autoHideDuration: 2500 }) }}
            text={userWalletAddress}>
            <MDBIcon className='text-warning ps-1 cursor-pointer' icon='copy'></MDBIcon>
        </CopyToClipboard>
    </div>
}

const FormatCode = (all) => {
    const { code } = all;
    const { enqueueSnackbar } = useSnackbar();

    return <div className='small'>
        {code}
        <CopyToClipboard
            onCopy={() => { enqueueSnackbar('Copied.', { variant: 'warning', autoHideDuration: 2500 }) }}
            text={code}>
            <MDBIcon className='text-warning ps-1 cursor-pointer' icon='copy'></MDBIcon>
        </CopyToClipboard>
    </div>
}

const formatTransferStatus = (all) => {
    const { transferStatus } = all;
    return <span>
        {transferStatus == 'confirmed' ?
            <ThemeFormatCurrency icon={false} mode='transaction' blockchain={all.blockchain} currency={all.currency} text={<><MDBIcon className='me-1' icon='check-circle'></MDBIcon>Confirmed</>} value={all.transferResult.transaction.transactionId} />
            :
            transferStatus == 'rejected' ?
                <small className='text-danger text-shadow-danger'>
                    <MDBIcon className='me-1' icon='times-circle'></MDBIcon>Rejected
                </small>
                :
                <small className='text-warning text-shadow-warning'>
                    <MDBIcon className='me-1' icon='exclamation'></MDBIcon>{transferStatus}
                </small>
        }
    </span>
}
const formatTransferResult = (all) => {
    // return JSON.stringify(all.transferResult)
    const { transferResult, _id } = all;

    return <MDBRow className='small'>
        <MDBCol className='small' size='12'>

            <div>
                <h6 className='text-info d-inline-block me-3'>Transfer Result
                </h6>
                <CopyToClipboard text={JSON.stringify(transferResult) || '{}'}>
                    <ThemeButton size='small' variant='outlined' className='rounded-pill btn-sm' color='info'>Copy Transfer Result Clipboard</ThemeButton>
                </CopyToClipboard>
                <CopyToClipboard text={_id}>
                    <ThemeButton size='small' variant='outlined' className='rounded-pill btn-sm mx-3' color='warning'>Copy _id to Clipboard</ThemeButton>
                </CopyToClipboard>
            </div>
            {all.comment && <Alert variant='outlined' color='info' className='bg-black mb-2'>
                {all.comment}
            </Alert>
            }
            <div className='border border-info'>
                <h6 className='text-info d-inline-block p-2'>Transfer Result</h6>
                <ReactJson name={null} style={{ padding: '0.5rem' }} theme="monokai" src={transferResult} />
            </div>
        </MDBCol>
    </MDBRow>

}

const TransferToTreasuryRequests = (props) => {
    const [contextBC, setContextBC] = useContext(BreadCrumbContext);
    useLayoutEffect(() => {
        setContextBC([
            {
                href: '/',
                text: 'Home'
            },
            {
                href: '/Financial',
                text: 'Financial'
            },
            {
                text: 'Transfer To Treasury Requests'
            }
        ])
    }, [])
    const { enqueueSnackbar } = useSnackbar();
    const [rows, setRows] = useState([])
    const interval = useRef(null);
    const [filter, setFilter] = useState({
        // currency: '',
        find: {},
        limit: 20,
        skip: 0,
        sort: { updatedAt: -1 }
    })

    const [confirmModal, setConfirmModal] = useState({
        open: false,
        comment: '',
        code: '',
        status: '',
        all: {}
    })

    const columns = [{
        id: '_id',
        label: '',
        style: { width: '0', padding: 0 },
        labelStyle: { width: '0', padding: 0 },
        format: () => { }
    }, {
        id: 'createdAt', // From Logger
        label: 'Created At',
        align: 'left',
        labelAlign: 'left',
        style: { width: '6rem', padding: 0, fontSize: '0.7em', },
        labelStyle: { width: '6rem', padding: 0, fontSize: '0.7em', },
        format: (value) => formatDateTime(value)
    }, {
        id: 'updatedAt', // From Logger
        label: 'Updated At',
        align: 'left',
        labelAlign: 'left',
        style: { width: '6rem', padding: 0, fontSize: '0.7em', },
        labelStyle: { width: '6rem', padding: 0, fontSize: '0.7em', },
        format: (value) => formatDateTime(value)
    }, {
        id: 'userId',
        label: 'User Id',
        align: 'center',
        style: { width: '4rem' },
        labelStyle: { width: '4rem' },
        format: (userId) => <UserQuickViewButton userId={userId}></UserQuickViewButton>
    }, {
        id: 'all',
        label: 'Currency',
        align: 'center',
        style: { width: '60px' },
        format: (all) => <ThemeFormatCurrency icon='png' iconSize='1rem' {...all} hideText />
    }, {
        id: 'all',
        label: 'Amount',
        align: 'right',
        labelAlign: 'right',
        style: { width: '7rem' },
        labelStyle: { width: '7rem' },
        format: (all) => FormatAmount(all)
    }, {
        id: 'all',
        label: 'Fee',
        align: 'right',
        labelAlign: 'right',
        style: { width: '60px' },
        format: (all) => FormatFee(all)
    }, {
        id: 'all',
        label: <span>User Wallet Address</span>,
        labelAlign: 'left',
        align: 'left',
        style: { width: '20rem' },
        labelStyle: { width: '20rem' },
        format: (all) => FormatWalletAddress(all)
    }, {
        id: 'all',
        label: <span>Code</span>,
        labelAlign: 'center',
        align: 'center',
        style: { width: '20rem' },
        labelStyle: { width: '20rem' },
        format: (all) => FormatCode(all)
    }, {
        id: 'by',
        label: <span>Accountant</span>,
        labelAlign: 'center',
        align: 'center',
        style: { width: '100px' },
        format: (by) => <div>
            <UserQuickViewButton userId={by.userId} />
            <small className='small pt-1 d-block opacity-40'>
                {by.username}
            </small>
        </div>
        // }, {
        //     id: 'all',
        //     label: 'Status',
        //     align: 'left',
        //     labelAlign: 'left',
        //     style: { width: '100px' },
        //     format: (all) => formatStatus(all)
        // }, {
        //     id: 'all',
        //     label: 'Confirm/Reject Time',
        //     align: 'left',
        //     labelAlign: 'left',
        //     style: { width: '100px' },
        //     format: (all) => formatConfirmTime(all)
    }, {
        id: 'all',
        label: 'Transfer Status',
        labelAlign: 'left',
        align: 'left',
        style: { width: '200px' },
        format: (all) => formatTransferStatus(all)
        // }, {
        //     id: 'all',
        //     label: 'Deposit',
        //     align: 'right',
        //     labelAlign: 'right',
        //     style: { width: '60px' },
        //     format: (all) => formatDeposit(all)
        // }, {
        //     id: 'all',
        //     label: 'Withdraw',
        //     align: 'right',
        //     labelAlign: 'right',
        //     style: { width: '60px' },
        //     format: (all) => formatWithdraw(all)
        // }, {
        //     id: 'all',
        //     label: 'To Treasury',
        //     align: 'right',
        //     labelAlign: 'right',
        //     style: { width: '60px' },
        //     format: (all) => formatTransferToTreasury(all)
        // }, {
        //     id: 'all',
        //     label: <small>User Balance<div>(D - W)</div></small>,
        //     align: 'right',
        //     labelAlign: 'right',
        //     labelStyle: { lineHeight: '1rem' },
        //     style: { width: '60px' },
        //     format: (all) => formatUserBalance(all)
        // }, {
        //     id: 'all',
        //     label: <small>CP Balance<div>(D - T)</div></small>,
        //     align: 'right',
        //     labelAlign: 'right',
        //     labelStyle: { lineHeight: '1rem' },
        //     style: { width: '60px' },
        //     format: (all) => formatCPBalance(all)
        // }, {
        //     id: 'all',
        //     label: <small>Blockchain<div>Balance</div></small>,
        //     align: 'right',
        //     labelAlign: 'right',
        //     labelStyle: { lineHeight: '1rem' },
        //     style: { width: '60px' },
        //     format: (all) => formatBlockchainBalance(all)

        //  }, {
        //     id: 'all',
        //     label: <small>Transfer<div>To Treasury</div></small>,
        //     align: 'center',
        //     labelStyle: { lineHeight: '1rem' },
        //     style: { width: '60px' },
        //     format: (all) => TransferToTreasury(all)
    }, {
        id: 'all',
        label: <small>Blockchain<div>Update</div></small>,
        align: 'center',
        labelStyle: { lineHeight: '1rem' },
        style: { width: '60px' },
        format: (all) => <ThemeButton
            onClick={() => updateWalletFromBlockchain(all)}
            size='small' variant='outlined' className='p-0 cursor-pointer' color='info'><MDBIcon icon='sync'></MDBIcon></ThemeButton>
    }, {
        id: 'all',
        label: 'Transfer Result',
        collapse: true,
        align: 'left',
        style: {
            overflow: 'hidden',
            padding: '0 100px !important',
            whiteSpace: 'break-spaces !important'
        },
        format: (all) => formatTransferResult(all)
    }];

    const getTransactions = (setRows, filters) => {
        axios.post(process.env.REACT_APP_API_HOST + '/accountant/treasury/getTransferToTreasuryRequests',
            filters,
            {
                headers: { 'x-auth-token': getBearer() }
            })
            .then(apiRes => {
                apiRes = apiRes.data;
                if (apiRes.success) {
                    setRows({
                        count: apiRes.result.count,
                        transactions: apiRes.result.withdrawRequest.map(row => ({ ...row, all: row }))
                    });
                } else {
                    setRows({
                        count: 0,
                        transactions: []
                    });
                }
            })
            .catch(err => {
                console.log(err)
            })
            .then(() => {
            })
    }

    const updateWalletFromBlockchain = (all) => {
        axios.post(process.env.REACT_APP_API_HOST + '/accountant/wallets/transferResult',
            {
                code: all.code,
                // address: all.address
                // _id: "61ae596656d3b3d8ae2127aa",
                // userId: "100090"
            },
            {
                headers: { 'x-auth-token': getBearer() }
            })
            .then(apiRes => {
                apiRes = apiRes.data;
                if (apiRes.success) {
                    enqueueSnackbar(apiRes.message, { variant: 'success', autoHideDuration: 2500 })
                } else {
                    enqueueSnackbar(apiRes.message, { variant: 'warning', autoHideDuration: 2500 })
                }
            })
            .catch(err => {
                enqueueSnackbar('Api error.', { variant: 'danger', autoHideDuration: 2500 })
            })

    }

    const handlePagination = (e, page) => {
        setFilter({
            ...filter,
            skip: (page - 1) * filter.limit
        });
    }

    useEffect(() => {
        let updateInterval = setInterval(() => {
            getTransactions(setRows, filter);
        }, 5000);
        interval.current = updateInterval;
        return () => {
            clearInterval(interval.current)
        }
    }, [filter])

    const [tableColumns, setTableColumns] = useState(columns);

    // const handleClose = () => {
    //     setConfirmModal({
    //         open: false,
    //         comment: '',
    //         code: '',
    //         status: '',
    //         all: {}
    //     })
    // }

    // const callSetWithdrawRequest = (params) => {
    //     params = _.pick(params, 'comment', 'code', 'status');
    //     console.log('params', params)
    //     axios.post(process.env.REACT_APP_API_HOST + '/accountant/users/setWithdrawRequest',
    //         params,
    //         {
    //             headers: { 'x-auth-token': getBearer() }
    //         })
    //         .then(apiRes => {
    //             apiRes = apiRes.data;
    //             if (apiRes.success) {
    //                 enqueueSnackbar(apiRes.message, { variant: 'success', autoHideDuration: 2500 })
    //             } else {
    //                 enqueueSnackbar(apiRes.message, { variant: 'warning', autoHideDuration: 2500 })
    //             }
    //         })
    //         .catch(err => {
    //             enqueueSnackbar('Error!', { variant: 'error', autoHideDuration: 2500 })
    //         })

    // }

    return (
        <>
            <TransferToTreasuryRequestsFilter query={useParams()} setRows={setRows} getTransactions={getTransactions} count={rows.count} setFilter={setFilter} filter={filter}></TransferToTreasuryRequestsFilter>
            <ResponsiveTable collapsible rows={rows.transactions} columns={tableColumns}></ResponsiveTable>

            {Math.floor(rows.count / filter.limit) > 0 &&
                <MDBRow className='m-0'>
                    <MDBCol size='12' className='text-center py-4'>
                        <Pagination onChange={handlePagination} className='d-inline-block' count={Math.ceil(rows.count / filter.limit)} variant="outlined" shape="rounded" />
                    </MDBCol>
                </MDBRow>}

            {/* <Modal
                open={confirmModal.open}
                disableScrollLock={true}
                onClose={handleClose}>
                <Box>
                    <Box className='box-shadow-neon bg-black rounded-6 overflow-scroll noScrollbar' sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '95vw',
                        height: '95vh'
                    }}>
                        <MDBRow className='m-0 bg-black py-2'>
                            <MDBCol size='12'>
                                <Typography className='text-neon' variant='body2'>
                                    <strong className='text-neon'>User Id: {confirmModal.all.userId}</strong>
                                    <MDBIcon onClick={handleClose} className='float-end pt-1 cursor-pointer' icon='times'></MDBIcon>
                                </Typography>
                                <hr className='my-2' />
                                <Typography variant='body2'>
                                    Code: {confirmModal.all.code}
                                </Typography>
                                <hr className='my-2' />
                                <Typography variant='body2'>
                                    <ThemeInput
                                        label='Comment'
                                        placeholder='Type your commente here ...'
                                        multiline
                                        rows={4}
                                        type='text'
                                        className='mt-2'
                                        value={confirmModal.comment}
                                        onChange={e => setConfirmModal({ ...confirmModal, comment: e.currentTarget.value })}
                                    ></ThemeInput>
                                </Typography>
                                <hr className='my-2' />
                            </MDBCol>
                            <MDBCol size='12' className='mb-3'>
                                <div>
                                    Created At: <ThemeFormatTime timestamp={confirmModal.all.createdAt} showFromNow></ThemeFormatTime>
                                </div>
                                <div>
                                    Updated At: <ThemeFormatTime timestamp={confirmModal.all.updatedAt} showFromNow></ThemeFormatTime>
                                </div>
                                <div>
                                    userWalletAddress: {confirmModal.all.userWalletAddress}
                                </div>
                                <div>
                                    destinationWalletAddress: {confirmModal.all.destinationWalletAddress}
                                </div>
                                <div>
                                    Withdraw Request: {confirmModal.all.amount} {confirmModal.all.currency}
                                </div>
                                <div>
                                    Withdraw Fee: {confirmModal.all.fee} {confirmModal.all.currency}
                                </div>
                                <div>
                                    {confirmModal.all.currency == 'USDT' && <span>Withdraw Blockchain: {confirmModal.all.blockchain}</span>}
                                </div>
                            </MDBCol>
                            <MDBCol size='12' className='mb-3'>
                                <ThemeButton onClick={() => callSetWithdrawRequest({ ...confirmModal, status: 'confirmed' })} variant='outlined' className='mx-2 px-2' color='success'><MDBIcon className='me-1' icon='check'></MDBIcon> Confirm</ThemeButton>
                                <ThemeButton onClick={() => callSetWithdrawRequest({ ...confirmModal, status: 'rejected' })} variant='outlined' className='px-2' color='danger'><MDBIcon className='me-1' icon='times'></MDBIcon> Reject</ThemeButton>
                            </MDBCol>
                            <MDBCol size='12'>
                                {confirmModal.open && <UserReport userId={confirmModal.all.userId}></UserReport>}
                            </MDBCol>
                        </MDBRow>
                    </Box>
                </Box>
            </Modal> */}
        </>)
}

export default TransferToTreasuryRequests