import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import React from 'react';
import ThemeCard from '../../../layout/components/ThemeCard';
import PlayerReportCashback from './PlayerReportCashback';

const PlayerReportCashbackCard = (props) => {
    const { type, playerReportData } = props;
    const playerReportCashbackData = playerReportData[type + 'CashbackData'];
    const { startTime, endTime } = playerReportCashbackData;
    return <ThemeCard collapsible
        headerProps={{
            sx: {
                height: 'auto !important'
            }
        }}
        header={
            <Typography variant='subtitle1' className='w-100 px-3'>
                <Box className='w-100 d-flex flex-column justify-content-center align-items-start'>
                    <Box className='text-neon fw-bold text-capitalize'>{type} Cashback Report
                        <small className='text-light px-2'>
                            ({moment(endTime).diff(moment(startTime), 'days') + 1} days)
                        </small>
                    </Box>
                    <small>
                        {moment.utc(startTime).format('YYYY/MM/DD')}
                        <small className='opacity-60 px-1'>
                            {moment.utc(startTime).format('HH:mm:ss UTC')}
                        </small>
                        <span className='pe-1 text-neon'>
                            to
                        </span>
                        {moment.utc(endTime).format('YYYY/MM/DD')}
                        <small className='opacity-60 px-1'>
                            {moment.utc(endTime).format('HH:mm:ss UTC')}
                        </small>
                    </small>
                </Box>
            </Typography>
        }>
        <PlayerReportCashback {...props} />
    </ThemeCard>
}

export default PlayerReportCashbackCard
