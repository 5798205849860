import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { getUserByUserId } from '../../helpers/adminHelpers';
import UserDetails from '../Tables/UsersTable/UserDetails';

const UserDetailsPage = (props) => {
    const search = useLocation().search;
    const qs = {
        userId: '',
        type: ''
    };

    Object.keys(qs).forEach(param => {
        qs[param] = new URLSearchParams(search).get(param);
    });

    const [user, setUser] = useState({})

    useEffect(() => {
        getUserByUserId(qs.userId, setUser);

        return () => {

        }
    }, [])


    return (
        <div className='p-0 m-0'>
            {user.userId && <UserDetails user={user} />}
        </div>
    )
}

export default UserDetailsPage