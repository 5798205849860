import React, { useState } from 'react'
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSnackbar } from 'notistack';
import { Collapse } from '@mui/material';
import numeral from 'numeral';
import ThemeButton from '../../../layout/components/ThemeButton';

const DigitainDetails = (props) => {
    const { details, odds, betAmountInUSD, winAmountInUSD } = props.meta.bet;
    const [showStakes, setShowStakes] = useState(false)
    const { enqueueSnackbar } = useSnackbar();

    return <>{
        details ?
            <MDBRow className='m-0 small'>
                <MDBCol size='12'>
                    <MDBRow className=''>
                        <MDBCol size='12' className='px-0' style={{
                            fontSize: '0.6rem',
                            borderLeft: '2px solid #00d7ba',
                            // backgroundColor: k % 2 ? '#000' : '#111'
                        }} >
                            {details.length > 1 ?
                                <div className='py-1 px-2 d-flex text-neon justify-content-between align-items-center'>
                                    <span>
                                        <small className='d-block text-white opacity-60'>
                                            Multiple
                                        </small>
                                        {details.length} Bets
                                    </span>
                                    <span>
                                        <small className='d-block text-white opacity-60'>
                                            Odds
                                        </small>
                                        <span style={{ padding: '2px' }} className={`px-2 rounded-2 text-${odds > 10 ? 'white fw-bold bg-danger' :
                                            odds > 8 ? 'danger' :
                                                odds > 5 ? 'warning' :
                                                    odds > 3 ? 'yellow' :
                                                        odds > 2 ? 'neon' :
                                                            'white'
                                            }`}>
                                            {numeral(odds).format('0,0.[00]')}
                                        </span>
                                    </span>
                                    <span>
                                        <small className='d-block text-white opacity-60'>
                                            Possible Win
                                        </small>
                                        {numeral(betAmountInUSD * odds).format('0,0.[00]')}
                                    </span>
                                    <ThemeButton size='small' variant='outlined' color='neon' className='p-1' style={{ boxShadow: 'none' }} onClick={() => setShowStakes(!showStakes)}>
                                        <MDBIcon className='pe-1' icon={showStakes ? 'chevron-up' : 'chevron-down'}></MDBIcon>Details
                                    </ThemeButton>
                                </div> : <></>
                            }
                            <Collapse in={showStakes || details.length == 1} timeout="auto" unmountOnExit>
                                <div>
                                    {
                                        details.map((detailsItem, k) => {
                                            const { id, odds, sportName, stake, title, url, gameDate, description } = detailsItem;
                                            return <MDBRow className='m-0' style={{
                                                // fontSize: '0.6rem',
                                                // borderLeft: '1px solid #26ddff',
                                                backgroundColor: k % 2 ? '#000' : '#111'
                                            }} key={k}>
                                                <MDBCol size='12' className='p-1 text-start'>
                                                    <div>
                                                        <a className='text-neon d-inline-block me-2' target='_blank' href={url}>
                                                            {
                                                                sportName == 'Basketball' ? '🏀' :
                                                                    sportName == 'Table Tennis' ? '🏓' :
                                                                        sportName == 'Volleyball' ? '🏐' :
                                                                            sportName == 'Football' ? '⚽️' :
                                                                                sportName + ' - '}
                                                            {title}
                                                        </a>
                                                    </div>
                                                    <div className='d-flex justify-content-between'>
                                                        <strong className='me-2'>
                                                            ({stake})
                                                        </strong>

                                                        <strong className='text-neon'>
                                                            <CopyToClipboard
                                                                onCopy={() => {
                                                                    enqueueSnackbar('Copied', {
                                                                        variant: 'success', autoHideDuration: 2500
                                                                    })
                                                                }}
                                                                text={id}>
                                                                <small className='cursor-pointer text-warning py-0 pe-1'>
                                                                    #{id} <MDBIcon icon='copy'></MDBIcon>
                                                                </small>
                                                            </CopyToClipboard>

                                                            Odds: {odds}
                                                        </strong>
                                                    </div>

                                                </MDBCol>
                                            </MDBRow>
                                        })
                                    }
                                </div>
                            </Collapse>
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
            </MDBRow> : <></>
    }</>
}

export default DigitainDetails