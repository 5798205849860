import React from 'react';
import theme from './DefaultTheme';
import { ThemeProvider } from '@mui/material';
import { Outlet } from 'react-router-dom';

const ThemeLayout = () => {

    return (<>
        <ThemeProvider theme={theme}>
            <Outlet />
        </ThemeProvider>
    </>)
}

export default ThemeLayout
