import React, { useEffect, useState } from 'react';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import _ from 'lodash'
import ThemeInput from '../../../layout/components/ThemeInput';
import ThemeSelect from '../../../layout/components/ThemeSelect';
import numeral from 'numeral';
import Collapse from '@mui/material/Collapse';
import CopyToClipboard from 'react-copy-to-clipboard';
import ThemeButton from '../../../layout/components/ThemeButton';
import ThemeDatePicker from '../../../layout/components/ThemeDatePicker';
import ThemeMultiSelect from '../../../layout/components/ThemeMultiSelect';

// first of this year
const getPreviousMonth = () => {
    let date = new Date();
    let from = new Date(date.getFullYear() - 1, date.getMonth() + 1, 1);
    return from
}
const getLastDayOfCurrentMonth = () => {
    let date = new Date();
    let to = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    return to
}

function getFloatValue(value) {
    console.log(value);
    if (value.slice(-1) == '.') {
        const dotCount = value.match(/\./g).length;
        if (dotCount > 1) {
            return value.slice(0, -1)
        } else {
            return value
        }
    }
    if (value == 0) return 0;
    return parseFloat(value.trim())
}

const UsersWalletReportFilter = (props) => {
    const { filter, setFilter, count, getTransactions, setRows, query } = props;
    const [showMore, setShowMore] = useState({
        filters: false,
        searchQuery: false
    })

    const [find, setFind] = useState({
        ...filter.find,
        userId: filter.find.userId || '',
        topupBy: filter.find.topupBy || '',
        walletAddressPublicKey: filter.find.walletAddressPublicKey || '',
        betId: filter.find.betId || '',
        fromDate: getPreviousMonth(),
        toDate: getLastDayOfCurrentMonth(),
        type: filter.find.type || '',
        currency: filter.currency,
        depositTotalAmount: '',
        depositCount: '',
        withdrawTotalAmount: '',
        withdrawCount: '',
        betAmountMin: 0,
        betAmountMax: '',
        oddsMin: 0,
        oddsMax: '',
    })

    const [sort, setSort] = useState(filter.sort)
    const [limit, setLimit] = useState(filter.limit)

    const handleSetFilter = () => {
        let findQuery = { ...find, $and: [] }
        // UserId
        if (find.userId && (find.userId.trim() != '')) {
            findQuery.userId = { $regex: find.userId, $options: "i" }
        } else {
            findQuery = _.omit(findQuery, ['userId']);
        }
        // Topup By
        if (find.topupBy && (find.topupBy.trim() != '')) {
            findQuery.$and.push({
                $or: [
                    { 'meta.topupBy.user.username': { $regex: find.topupBy, $options: "i" } },
                    { 'meta.topupBy.user.telegram.username': { $regex: find.topupBy, $options: "i" } },
                ]
            })
        } else {
            findQuery = _.omit(findQuery, ['topupBy']);
        }
        // Title
        if (find.walletAddressPublicKey && (find.walletAddressPublicKey.trim() != '')) {
            findQuery.$and.push({
                $or: [
                    { "address": { $regex: find.walletAddressPublicKey || '', $options: "i" } },
                    { "address1": { $regex: find.walletAddressPublicKey || '', $options: "i" } },
                    { "publicKey": { $regex: find.walletAddressPublicKey || '', $options: "i" } },
                    // { "meta.evoTransaction.game.type": { $regex: find.title || '', $options: "i" } },
                    // { 'meta.relatedSportTransactions.meta.Order.Bets[0].BetStakes[0].EventNameOnly': { $regex: find.title, $options: "i" } },
                    // { 'meta.topupBy.user.telegram.username': { $regex: find.title, $options: "i" } },
                ]
            })
        } else {
            findQuery = _.omit(findQuery, ['walletAddressPublicKey']);
        }
        // Deposit Total Amount
        if (find.depositTotalAmount == 'true') {
            findQuery.$and.push({
                $or: [
                    { "deposit.totalAmount": { $gt: 0 } },
                ]
            })
        } else if (find.depositTotalAmount == 'false') {
            findQuery.$and.push({
                $or: [
                    { "deposit.totalAmount": 0 },
                ]
            })
        } else {
            findQuery = _.omit(findQuery, ['depositTotalAmount']);
        }
        // Deposit Total Count
        if (find.depositCount == 'true') {
            findQuery.$and.push({
                $or: [
                    { "deposit.count": { $gt: 0 } },
                ]
            })
        } else if (find.depositCount == 'false') {
            findQuery.$and.push({
                $or: [
                    { "deposit.count": 0 },
                ]
            })
        } else {
            findQuery = _.omit(findQuery, ['depositCount']);
        }
        // Withdraw Total Amount
        if (find.withdrawTotalAmount == 'true') {
            findQuery.$and.push({
                $or: [
                    { "withdraw.totalAmount": { $gt: 0 } },
                ]
            })
        } else if (find.withdrawTotalAmount == 'false') {
            findQuery.$and.push({
                $or: [
                    { "withdraw.totalAmount": 0 },
                ]
            })
        } else {
            findQuery = _.omit(findQuery, ['withdrawTotalAmount']);
        }
        // Withdraw Total Count
        if (find.withdrawCount == 'true') {
            findQuery.$and.push({
                $or: [
                    { "withdraw.count": { $gt: 0 } },
                ]
            })
        } else if (find.withdrawCount == 'false') {
            findQuery.$and.push({
                $or: [
                    { "withdraw.count": 0 },
                ]
            })
        } else {
            findQuery = _.omit(findQuery, ['withdrawCount']);
        }

        // Type
        if (find.type && (find.type.trim() != '')) {
            if (['open', 'lost', 'won', 'cashback', 'cashout', 'rollback'].indexOf(find.type) > -1) {
                findQuery.$and.push({
                    "meta.bet.betState": find.type
                })
                findQuery = _.omit(findQuery, ['type']);
            } else if (find.type == 'allGameStates') {
                findQuery.$and.push({
                    "type": { $ne: 'ref' },
                    "meta.bet.betState": { $exists: 1 }
                })
                findQuery = _.omit(findQuery, ['type']);
            } else {
                findQuery.type = { $regex: find.type, $options: "i" }
            }
        } else {
            findQuery = _.omit(findQuery, ['type']);
        }
        // Service
        if (find.service && (find.service.trim() != '')) {
            if (find.service == 'AllGames') {
                findQuery.service = { $in: ['EveryMatrix', 'Pragmatic', 'Digitain', 'Evolution', 'Poker', 'Crash', 'Netent', 'RedTiger', 'Pragmatic'] }
            } else {
                findQuery.service = { $regex: find.service, $options: "i" }
            }
        } else {
            findQuery = _.omit(findQuery, ['service']);
        }
        // Currency
        // if (find.currency && (find.currency.trim() != '')) {
        //     findQuery.currency = { $regex: find.currency, $options: "i" }
        // } else {
        //     findQuery = _.omit(findQuery, ['currency']);
        // }

        // Wallet Address
        if (find.walletAddress && (find.walletAddress.trim() != '')) {
            findQuery.$and.push({
                $or: [
                    { 'wallet.BTC.address': { $regex: find.walletAddress, $options: "i" } },
                    { 'wallet.ETH.address': { $regex: find.walletAddress, $options: "i" } },
                    { 'wallet.TRX.address': { $regex: find.walletAddress, $options: "i" } },
                    { 'wallet.DOGE.address': { $regex: find.walletAddress, $options: "i" } },
                ]
            })
        }
        // From Date
        // if (find.fromDate) {
        //     findQuery.$and.push({
        //         'updatedAt': { $gt: find.fromDate }
        //     })
        // }
        // // To Date
        // if (find.toDate) {
        //     findQuery.$and.push({
        //         'updatedAt': { $lt: find.toDate }
        //     })
        // }
        // Email

        // Deposit
        if (find.deposit) {
            findQuery.$and.push({
                $or: [
                    { 'wallet.BTC.deposit.count': { $gt: 0 } },
                    { 'wallet.ETH.deposit.count': { $gt: 0 } },
                    { 'wallet.TRX.deposit.count': { $gt: 0 } },
                    { 'wallet.DOGE.deposit.count': { $gt: 0 } },
                    { 'wallet.USD.deposit.count': { $gt: 0 } }
                ]
            })
        }
        // Withdraw
        if (find.withdraw) {
            findQuery.$and.push({
                $or: [
                    { 'wallet.BTC.withdraw.count': { $gt: 0 } },
                    { 'wallet.ETH.withdraw.count': { $gt: 0 } },
                    { 'wallet.TRX.withdraw.count': { $gt: 0 } },
                    { 'wallet.DOGE.withdraw.count': { $gt: 0 } },
                    { 'wallet.USD.withdraw.count': { $gt: 0 } }
                ]
            })
        }
        // TopUp
        if (find.topup) {
            findQuery.$and.push({
                $or: [
                    { 'wallet.BTC.topup.count': { $gt: 0 } },
                    { 'wallet.ETH.topup.count': { $gt: 0 } },
                    { 'wallet.TRX.topup.count': { $gt: 0 } },
                    { 'wallet.DOGE.topup.count': { $gt: 0 } },
                    { 'wallet.USD.topup.count': { $gt: 0 } }
                ]
            })
        }
        // Sport
        if (find.sport) {
            findQuery.$and.push({
                'wallet.USD.play.Digitain.count': { $gt: 0 }
            })
        }
        // Casino
        if (find.casino) {
            findQuery.$and.push({
                'wallet.USD.play.Evolution.count': { $gt: 0 }
            })
        }
        // Crash
        if (find.crash) {
            findQuery.$and.push({
                'wallet.USD.play.Crash.count': { $gt: 0 }
            })
        }
        // Poker
        if (find.poker) {
            findQuery.$and.push({
                'wallet.USD.play.Poker.count': { $gt: 0 }
            })
        }
        // BetAmount
        if (find.betAmountMin > 0) {
            findQuery.$and.push({
                'meta.bet.betAmountInUSD': { $gt: parseFloat(find.betAmountMin) }
            })
        }
        if (find.betAmountMax) {
            findQuery.$and.push({
                'meta.bet.betAmountInUSD': { $lte: parseFloat(find.betAmountMax), $gt: 0 },
            })
            findQuery.$and.push({
                'service': { $nin: ['iM-API', 'CryptoPay-API', 'Telegram'] },
            })
        }
        // Odds
        if (parseFloat(find.oddsMin) > 0) {
            findQuery.$and.push({
                'meta.bet.odds': { $gt: parseFloat(find.oddsMin) }
            })
        }
        if (find.oddsMax) {
            findQuery.$and.push({
                'meta.bet.odds': { $lte: parseFloat(find.oddsMax), $gt: 0 },
            })
            findQuery.$and.push({
                'service': { $nin: ['iM-API', 'CryptoPay-API', 'Telegram'] },
            })
        }
        // isValid
        // if (find.isValid == 'true') {
        //     findQuery.isValid = true;
        // }
        // if (find.isValid == 'false') {
        //     findQuery.isValid = false;
        // }
        // if (find.isValid == 'all') {
        //     findQuery = _.omit(findQuery, ['isValid'])
        // }

        findQuery = _.omit(findQuery, [
            'fromDate', 'toDate',
            'walletAddressPublicKey',
            'depositTotalAmount', 'depositCount',
            'withdrawTotalAmount', 'withdrawCount',
            'betId', 'usernameEmail',
            'hasBalance', 'walletAddress',
            'email', 'mobile', 'telegram',
            'oddsMin', 'oddsMax',
            'betAmountMax', 'betAmountMin',
        ]);

        // findQuery.$and.push({
        //     'meta.countryCode': 'PL',
        // })


        if (findQuery.$and.length == 0) findQuery = _.omit(findQuery, ['$and']);
        // const modeObject = Object.fromEntries(availableModes.map(m => [[m], find.mode.indexOf(m) > -1 ? true : false]));
        let filterObject = {
            currency: findQuery.currency,
            find: findQuery,
            sort: sort,
            limit: limit,
        }
        // filterObject.mode = modeObject;
        setFilter(filterObject);
        getTransactions(setRows, filterObject);
    }

    useEffect(() => {
        handleSetFilter()
    }, [find, sort, limit])

    return (
        <MDBRow className='m-0'>
            <MDBCol size='12 pb-1' style={{ backgroundColor: '#000' }}>
                <form onSubmit={e => { e.preventDefault(); handleSetFilter() }}>
                    <MDBRow className='align-items-center d-flex'>
                        <MDBCol size='12' md='2' xl='1' className='p-1'>
                            <ThemeInput inputClassName={find.userId != '' ? 'box-shadow-success border-success rounded-3 border' : ''} type='search' label='User Id' value={find.userId} onChange={e => setFind({ ...find, userId: e.target.value.trim() })}></ThemeInput>
                        </MDBCol>

                        <MDBCol size='12' md='2' xl='1' className='p-1'>
                            <ThemeInput type='search' label='Address, PubKey' value={find.walletAddressPublicKey} onChange={e => setFind({ ...find, walletAddressPublicKey: e.target.value.trim() })}></ThemeInput>
                        </MDBCol>

                        <MDBCol size='12' md='2' xl='1' className='p-1'>
                            <ThemeSelect className={find.currency ? 'box-shadow-success border border-success ' : ''} value={find.currency} label='Currency' onChange={e => setFind({ ...find, currency: e.target.value.trim() })}>
                                <option value='BTC'>BTC</option>
                                <option value='ETH'>ETH</option>
                                <option value='TRX'>TRX</option>
                                <option value='DOGE'>DOGE</option>
                            </ThemeSelect>
                        </MDBCol>

                        <MDBCol size='12' md='2' xl='3' className='px-0'>
                            <MDBRow className='mx-0'>
                                <MDBCol size='3' className='p-1'>
                                    <ThemeSelect className={find.depositTotalAmount ? 'box-shadow-success border border-success ' : ''} value={find.depositTotalAmount} label='D - Amount' onChange={e => setFind({ ...find, depositTotalAmount: e.target.value.trim() })}>
                                        <option value=''>All</option>
                                        <option value='true'>Yes</option>
                                        <option value='false'>No</option>
                                    </ThemeSelect>
                                </MDBCol>
                                <MDBCol size='3' className='p-1'>
                                    <ThemeSelect className={find.depositCount ? 'box-shadow-success border border-success ' : ''} value={find.depositCount} label='D - Count' onChange={e => setFind({ ...find, depositCount: e.target.value.trim() })}>
                                        <option value=''>All</option>
                                        <option value='true'>Yes</option>
                                        <option value='false'>No</option>
                                    </ThemeSelect>
                                </MDBCol>
                                <MDBCol size='3' className='p-1'>
                                    <ThemeSelect className={find.withdrawTotalAmount ? 'box-shadow-success border border-success ' : ''} value={find.withdrawTotalAmount} label='W - Amount' onChange={e => setFind({ ...find, withdrawTotalAmount: e.target.value.trim() })}>
                                        <option value=''>All</option>
                                        <option value='true'>Yes</option>
                                        <option value='false'>No</option>
                                    </ThemeSelect>
                                </MDBCol>
                                <MDBCol size='3' className='p-1'>
                                    <ThemeSelect className={'' + (find.withdrawCount ? 'box-shadow-success border border-success ' : '')} value={find.withdrawCount} label='W - Count' onChange={e => setFind({ ...find, withdrawCount: e.target.value.trim() })}>
                                        <option value=''>All</option>
                                        <option value='true'>Yes</option>
                                        <option value='false'>No</option>
                                    </ThemeSelect>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol size='12' md='4' xl='3' className='p-1 d-flex justify-content-between'>
                            <ThemeDatePicker className='pe-1' label='From' value={find.fromDate} onChange={fromDate => setFind({ ...find, fromDate })}></ThemeDatePicker>
                            <ThemeDatePicker className='ps-1' label='To' value={find.toDate} onChange={toDate => setFind({ ...find, toDate })}></ThemeDatePicker>
                        </MDBCol>
                        <MDBCol size='12' md='2' xl='1' className='p-1'>
                            <ThemeSelect value={JSON.stringify(sort)} label='Sort' onChange={e => setSort(JSON.parse(e.target.value))}>
                                <option value={JSON.stringify({ updatedAt: -1 })}>Update At</option>
                                <option value={JSON.stringify({ createdAt: -1 })}>Created At</option>
                                <option value={JSON.stringify({ 'deposit.totalAmount': -1 })}>{`Deposit Amount ${find.currency} ↓`}</option>
                                {/* <option value={JSON.stringify({ 'deposit.totalUSDT': -1 })}>{`Deposit Amount USDT ↓`}</option> */}
                                <option value={JSON.stringify({ 'blockchainBalance': -1 })}>{`Blockchain Balance ${find.currency} ↓`}</option>
                                <option value={JSON.stringify({ 'blockchainBalanceUSDT': -1 })}>{`Blockchain Balance USDT ↓`}</option>
                            </ThemeSelect>
                        </MDBCol>
                        <MDBCol size='12' md='2' xl='1' className='p-1'>
                            <ThemeSelect value={limit} label='Page Size' onChange={e => setLimit(parseInt(e.target.value))}>
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={200}>200</option>
                                <option value={500}>500</option>
                            </ThemeSelect>
                        </MDBCol>
                        <MDBCol size='12' md='2' xl='1' className='px-1 text-neon pt-2 text-shadow-neon text-start d-flex justify-content-between align-items-center'>
                            <small className='' style={{ lineHeight: '0.8rem' }}>
                                {numeral(count).format('0,0')}
                                <div>record found.</div>
                            </small>
                        </MDBCol>
                    </MDBRow>
                </form>
            </MDBCol>
        </MDBRow >
    );
}
export default UsersWalletReportFilter
