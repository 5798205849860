import axios from "axios";
import { getBearer } from "../helpers/publicHelper";
import _ from 'lodash';
const getPages = (props) => {
    let { setData, filter, enqueueSnackbar } = props;
    console.log('filterr', filter)
    filter = filter || {
        find: {
            isValid: true
        },
        sort: {
            updatedAt: -1
        },
        limit: 10,
        skip: 0
    }
    axios.post(process.env.REACT_APP_API_HOST + '/contentwriter/page/list',
        filter,
        {
            headers: { 'x-auth-token': getBearer() }
        })
        .then(res => {
            res = res.data;
            if (res.success) {
                if (setData) {
                    setData({
                        count: res.result.count,
                        pages: res.result.pages.map(page => ({ ...page, all: page }))
                    });
                }
            } else {
                if (setData) {
                    setData({
                        count: 0,
                        pages: []
                    });
                }
                if (enqueueSnackbar) {
                    enqueueSnackbar(res.message, { variant: 'error', autoHideDuration: 2500 })
                }
            }
        })
}

const savePage = (props) => {
    const { data, enqueueSnackbar } = props;
    axios.post(process.env.REACT_APP_API_HOST + `/contentwriter/page/${data._id ? 'edit' : 'add'}`,
        _.pick(data, ['_id', 'title', 'type', 'content', 'shortDescription', 'breadcrumb', 'order', 'keywords', 'status', 'icon', 'deletable']),
        {
            headers: { 'x-auth-token': getBearer() }
        })
        .then(res => {
            res = res.data;
            if (res.success) {
                if (enqueueSnackbar) {
                    enqueueSnackbar(res.message, { variant: 'success', autoHideDuration: 2500 });
                }
            } else {
                if (enqueueSnackbar) {
                    enqueueSnackbar(res.message, { variant: 'error', autoHideDuration: 2500 });
                }
            }
        });
};

const deletePage = (props) => {
    const { data, enqueueSnackbar, handleSearch } = props;
    axios.post(process.env.REACT_APP_API_HOST + `/contentwriter/page/delete`,
        _.pick(data, ['_id']),
        {
            headers: { 'x-auth-token': getBearer() }
        })
        .then(res => {
            res = res.data;
            if (res.success) {
                if (enqueueSnackbar) {
                    enqueueSnackbar(res.message, { variant: 'success', autoHideDuration: 2500 });
                    handleSearch()
                }
            } else {
                if (enqueueSnackbar) {
                    enqueueSnackbar(res.message, { variant: 'error', autoHideDuration: 2500 });
                }
            }
        });
};

const getPageHistory = (props) => {
    let { setData, _id, enqueueSnackbar } = props;
    console.log('_id::', _id)
    axios.post(process.env.REACT_APP_API_HOST + '/contentwriter/page/history',
        { _id },
        {
            headers: { 'x-auth-token': getBearer() }
        })
        .then(res => {
            res = res.data;
            if (res.success) {
                if (setData) {
                    setData(res.result);
                }
            } else {
                if (setData) {
                    setData([]);
                }
                if (enqueueSnackbar) {
                    enqueueSnackbar(res.message, { variant: 'error', autoHideDuration: 2500 })
                }
            }
        })
}
const getPage = (props) => {
    let { setData, _id, enqueueSnackbar } = props;
    axios.post(process.env.REACT_APP_API_HOST + '/contentwriter/page/details',
        { _id },
        {
            headers: { 'x-auth-token': getBearer() }
        })
        .then(res => {
            res = res.data;
            if (res.success) {
                if (setData) {
                    setData(res.result);
                }
            } else {
                if (setData) {
                    setData({});
                }
                if (enqueueSnackbar) {
                    enqueueSnackbar(res.message, { variant: 'error', autoHideDuration: 2500 })
                }
            }
        })
}

export {
    getPage,
    getPages,
    savePage,
    deletePage,
    getPageHistory
}