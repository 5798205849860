import { Autocomplete, Box, Chip, Grid, Select, TextField } from '@mui/material';
import axios from 'axios';
import { MDBIcon } from 'mdb-react-ui-kit';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react'
import { getBearer } from '../../../helpers/publicHelper';
import ThemeInput from '../../../layout/components/ThemeInput';
import ThemeSelect from '../../../layout/components/ThemeSelect';
import ThemeTextField from '../../../layout/components/ThemeTextField';

const getTransactions = (setRows, filter, enqueueSnackbar, api) => {
    const { find, skip, limit, sort, mode } = filter;
    axios.post(process.env.REACT_APP_API_HOST + api,
        {
            find, skip, limit, sort, mode
        },
        {
            headers: { 'x-auth-token': getBearer() }
        })
        .then(apiRes => {
            apiRes = apiRes.data;
            if (apiRes.success) {
                setRows({
                    count: apiRes.result.count,
                    transactions: apiRes.result.transactions.map(row => ({ ...row, all: row }))
                });
            } else {
                enqueueSnackbar(apiRes.message, { variant: 'warning', autoHideDuration: 1500 })
                setRows({
                    count: 0,
                    transactions: []
                });
            }
        })
        .catch(err => {
            enqueueSnackbar('err', { variant: 'error', autoHideDuration: 1500 })
        })
}

const AdvancedFilter = (props) => {
    // CONTROLLED VERSION: values, serValues
    const { defaultFilters, setRows, api, values, serValues } = props;
    const { fields, find, limit, skip, sort } = defaultFilters;
    const { enqueueSnackbar } = useSnackbar();
    // contains current localValues of filter fields.
    const defaultValues = {};
    fields.forEach(field => {
        defaultValues[field.id] = field.value;
    });
    const [localValues, setLocalValues] = useState(defaultValues);
    const [filter, setFilter] = useState({ find: find || {}, skip: skip || 0, limit: limit || 10, sort: sort || { createdAt: -1 }, firstExecution: true })

    useEffect(() => {
        let newFind = {};
        console.log('localValuesChanged::', localValues)
        fields.forEach(filterField => {
            const { id, query } = filterField;

            console.log(id, localValues[id])
            if (localValues[id]) {
                newFind[id] = query(localValues[id])
            }
        });
        console.log('newFind', newFind)
        setFilter({
            ...filter,
            firstExecution: false,
            find: newFind
        })
        return () => {

        }
    }, [localValues])

    useEffect(() => {
        if (filter.firstExecution) {
            // return setFilter({ ...filter, firstExecution: false })
        } else {
            getTransactions(setRows, filter, enqueueSnackbar, api)
        }
        return () => { }
    }, [filter])


    return <Box>
        <RenderFilterFields fields={fields} setLocalValues={setLocalValues} localValues={localValues} />
        {/* <div>Fields</div>
        <div>{JSON.stringify(fields)}</div>
        <div>LocalValues</div>
        <div>{JSON.stringify(localValues)}</div>
        <div>Filter</div>
        <div>{JSON.stringify(filter)}</div> */}
    </Box>
}

const RenderFilterFields = (props) => {
    const { fields } = props;
    return <Grid container spacing={1} className='px-1'>
        {fields.map((filterField, i) => <RenderFilterField {...props} {...filterField} key={i} />)}
    </Grid>
}

const RenderFilterField = (props) => {
    const { localValues, setLocalValues, type, label, id, query, cls, options, value, gridSize } = props;
    const size = gridSize || [];
    const sizes = {
        xs: size[0] || 12,
        sm: size[1] || 12,
        md: size[2] || 12,
        lg: size[3] || 12,
        xl: size[4] || 12,
    }
    return <Grid className='border-' item {...sizes}>
        {/* AutoComplete */}
        {type == 'autoComplete' && <Autocomplete
            sx={{
                padding: 0,
                '& .MuiAutocomplete-inputRoot': {
                    padding: '0.25rem',
                    height: 'calc(1.75rem + 0.33rem + 2px)',
                    flexWrap: 'nowrap !important'
                }
            }}
            multiple
            limitTags={2}
            id="multiple-limit-tags"
            options={options}
            getOptionLabel={(option) => option.title}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip
                        sx={{
                            padding: 0,
                            margin: 0,
                            // fontSize: '0.5rem'
                        }}
                        size='small'
                        variant='outlined'
                        color='info'
                        label={option.title}
                        {...getTagProps({ index })}
                    // disabled={fixedOptions.indexOf(option) !== -1}
                    />
                ))
            }
            defaultValue={localValues[id]}
            renderInput={(params) => {
                console.log(params);
                const { InputLabelProps, ...newParams } = params;
                return <ThemeTextField {...newParams} label={label} />
            }
            }
            onChange={(event, newValue) => {
                console.log(newValue);
                setLocalValues({ ...localValues, [id]: newValue })
            }}
        />
        }
        {type == 'select' && <ThemeSelect
            // className={find.type ? 'box-shadow-success border border-success ' : ''} value={find.type} label='Type'
            label={label}
            value={localValues[id].value}
            onChange={e => {
                // const currentSelecteds = localValues[id];
                // const selected = e.target.value.trim();
                console.log(e.target)
                setLocalValues({ ...localValues, [id]: e.target })
            }}>
            {options.map((option, i) =>
                <option key={i} value={option.value}>{option.title}</option>
            )}
        </ThemeSelect>}
        {type == 'multi' && <ThemeSelect
            // className={find.type ? 'box-shadow-success border border-success ' : ''} value={find.type} label='Type'
            label={label}
            value={localValues[id].value}
            onChange={e => {
                // const currentSelecteds = localValues[id];
                // const selected = e.target.value.trim();
                console.log(e.target)
                setLocalValues({ ...localValues, [id]: e.target })
            }}>
            {options.map((option, i) =>
                <option key={i} value={option.value}>{option.title}</option>
            )}
        </ThemeSelect>}
        {/* <ThemeInput
            inputClassName={localValues[id] ? 'box-shadow-success border-success rounded-3 border' : ''}
            type='search'
            label={label}
            value={localValues[id]}
            onChange={e => setLocalValues({ ...localValues, [id]: e.target.value.trim() })}>
        </ThemeInput> */}
        {/* TEXT */}
        {type == 'text' && <ThemeInput
            inputClassName={localValues[id] ? 'box-shadow-success border-success rounded-3 border' : ''}
            type='search'
            label={label}
            value={localValues[id]}
            onChange={e => setLocalValues({ ...localValues, [id]: e.target.value.trim() })}>
        </ThemeInput>}
        {/* <br />
        {type == 'text' && <ThemeTextField
            inputClassName={localValues[id] ? 'box-shadow-success border-success rounded-3 border' : ''}
            type='search'
            label={label + 'asdf asd f asd fasd'}
            value={localValues[id]}
            onChange={e => setLocalValues({ ...localValues, [id]: e.target.value.trim() })}>
        </ThemeTextField>} */}
    </Grid>
}
export default AdvancedFilter