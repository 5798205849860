import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react'
import { getBearer } from '../../helpers/publicHelper';

const GamesList = (props) => {

    // const { userId, type } = props.match.params;
    const { enqueueSnackbar } = useSnackbar();
    const [rows, setRows] = useState([])
    const interval = useRef(null);
    const [filter, setFilter] = useState({
        // currency: '',
        find: {},
        limit: 20,
        skip: 0,
        sort: { updatedAt: -1 },
        select: ['name', 'order', 'type', 'subType', 'live'],
    })

    const getGames = (filters) => {
        axios.post(process.env.REACT_APP_API_HOST + '/admin/games/list',
            filters,
            {
                headers: { 'x-auth-token': getBearer() }
            })
            .then(apiRes => {
                apiRes = apiRes.data;
                if (apiRes.success) {
                    setRows({
                        count: apiRes.result.count,
                        games: apiRes.result.games
                    });
                } else {
                    setRows({
                        count: 0,
                        games: apiRes.result.games
                    });
                }
            })
            .catch(err => {
                console.log(err)
            })
            .then(() => {
            })
    }

    return (
        <div>
            s
        </div>
    )
}

export default GamesList