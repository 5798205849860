import React, { createContext, useReducer, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from 'react-i18next';
import Reducer from './reducer';

const initialState = {
    recaptchaSiteKey: '6LdsCDgcAAAAAGSl2eIx_SqtsUGLDIb45C3pT1-Y',
    config: {
        api: { getConfig: '/api/state', },
        countryAccess: false,
        currencyList: ['USDT', 'BTC', 'ETH', 'DOGE', 'TRX', 'USD'],
        fiatList: ['USD', 'EUR', 'JPY', 'GBP', 'RUB'],
        init: false,
        kycList: ['ppt', 'bc', 'id', 'pp'],
        recaptchaSiteKey: '',
    },
    user: {
        kyc: { documents: {} },
        profile: {},
        settings: {},
        transactions: [],
        wallet: {
            USDT: {},
            USD: {}
        },
    },
    login: false,
    active: {
        host: process.env.REACT_APP_API_HOST,
        // env: process.env.REACT_APP_API_HOST.indexOf('stage') > -1 ? 'stage' : 'prod',
        layoutDrawer: {
            right: false,
            left: false
        },
        currency: 'USDT',
        blockchain: 'TRX',
        fiat: 'USD',
        rightTab: 0,
        modal: { title: '', content: '', open: false },
        withdrawValue: 0,
        withdrawWallet: '',
        leftDrawer: {
            casino: true,
            sport: true
        },
        signUp: {
            _id: '',
            accept: false,
            countryCode: '1',
            mobile: '',
            email: '',
            password: '',
            mode: '',
            otp: '',
            twoFA: '',
            code: '', // SignUp verify code
            waiting: false,
            emailCode: '',
            mobileCode: '',
            emailEdit: true,
            mobileEdit: true,
            verificationMode: 'email-mobile'
        },
        kyc: {
            email: '',
            mobile: '',
            countryCode: '',
            addMobile: false,
            addEmail: false,
            emailWaiting: false,
            mobileWaiting: false,
            emailStatus: '',
            mobileStatus: '',
            emailCode: '',
            mobileCode: '',
            address: ''
        }
    },
    rates: {
        "USDT": {
            "USD": 0,
            "EUR": 0,
            "JPY": 0,
            "GBP": 0,
            "RUB": 0,
            "USDT": 0,
            "BTC": 0,
            "ETH": 0,
            "TRX": 0,
            "DOGE": 0,
        },
        "BTC": {
            "USD": 0,
            "EUR": 0,
            "JPY": 0,
            "GBP": 0,
            "RUB": 0,
            "USDT": 0,
            "BTC": 0,
            "ETH": 0,
            "TRX": 0,
            "DOGE": 0,
        },
        "ETH": {
            "USD": 0,
            "EUR": 0,
            "JPY": 0,
            "GBP": 0,
            "RUB": 0,
            "USDT": 0,
            "BTC": 0,
            "ETH": 0,
            "TRX": 0,
            "DOGE": 0,
        },
        "TRX": {
            "USD": 0,
            "EUR": 0,
            "JPY": 0,
            "GBP": 0,
            "RUB": 0,
            "USDT": 0,
            "BTC": 0,
            "ETH": 0,
            "TRX": 0,
            "DOGE": 0,
        },
        "DOGE": {
            "USD": 0,
            "EUR": 0,
            "JPY": 0,
            "GBP": 0,
            "RUB": 0,
            "USDT": 0,
            "BTC": 0,
            "ETH": 0,
            "TRX": 0,
            "DOGE": 0,
        },
        "USD": {
            "USDT": 0,
            "BTC": 0,
            "ETH": 0,
            "TRX": 0,
            "DOGE": 0,
            "USD": 0,
            "EUR": 0,
            "JPY": 0,
            "GBP": 0,
            "RUB": 0,
        },
        "EUR": {
            "USDT": 0,
            "BTC": 0,
            "ETH": 0,
            "TRX": 0,
            "DOGE": 0,
            "USD": 0,
            "EUR": 0,
            "JPY": 0,
            "GBP": 0,
            "RUB": 0,
        },
        "JPY": {
            "USDT": 0,
            "BTC": 0,
            "ETH": 0,
            "TRX": 0,
            "DOGE": 0,
            "USD": 0,
            "EUR": 0,
            "JPY": 0,
            "GBP": 0,
            "RUB": 0,
        },
        "GBP": {
            "USDT": 0,
            "BTC": 0,
            "ETH": 0,
            "TRX": 0,
            "DOGE": 0,
            "USD": 0,
            "EUR": 0,
            "JPY": 0,
            "GBP": 0,
            "RUB": 0,
        },
        "RUB": {
            "USDT": 0,
            "BTC": 0,
            "ETH": 0,
            "TRX": 0,
            "DOGE": 0,
            "USD": 0,
            "EUR": 0,
            "JPY": 0,
            "GBP": 0,
            "RUB": 0,
        },
        "updatedAt": 0
    },
};

const initialBC = [
    {
        href: '/',
        text: 'Home'
    }
]

const Store = ({ children }) => {
    const { t, i18n } = useTranslation();
    const [cookies, setCookie, removeCookie] = useCookies(['bearer']);
    const [state, dispatch] = useReducer(Reducer, {
        ...initialState,
        t: t,
        i18n: i18n,
        cookies: cookies,
        setCookie: setCookie,
        removeCookie: removeCookie
    });
    const [contextBC, setContextBC] = useState(initialBC)
    return (
        <Context.Provider value={[state, dispatch]}>
            <BreadCrumbContext.Provider value={[contextBC, setContextBC]}>
                {children}
            </BreadCrumbContext.Provider>
        </Context.Provider>
    )
};

export const Context = createContext(initialState);
export const BreadCrumbContext = createContext(initialBC);
export default Store;
