import { Dialog, TextField } from '@mui/material'
import { MDBIcon } from 'mdb-react-ui-kit'
import moment from 'moment'
import React, { useState } from 'react'
import ThemeInput from './ThemeInput';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';

const ThemeDatePicker = (props) => {
    const { onChange, value, label } = props;
    const [open, setOpen] = useState(false)
    return (
        <div>
            <ThemeInput
                disabled
                end={<MDBIcon onClick={() => setOpen(true)} icon='calendar-alt' className='cursor-pointer text-small small text-shadow-neon text-neon'></MDBIcon>}
                inputStyle={{ fontSize: '0.8rem' }}
                onChange={() => { }}
                label={label}
                value={moment(value).format('YYYY/MM/DD HH:mm')}
            ></ThemeInput>
            <Dialog
                onBackdropClick={() => setOpen(false)}
                open={open} className='rounded-6 overflow-hidden calendarDialog'>
                <div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <StaticDateTimePicker
                           
                            label="Choose Time From:"
                            value={value}
                            onChange={newValue => {
                                onChange(newValue);
                            }}
                            disableCloseOnSelect={false}
                            renderInput={(params) => <TextField className='d-none' {...params} />}
                            onClose={() => setOpen(false)}
                        />
                    </LocalizationProvider>
                </div>
            </Dialog>
        </div>
    )
}

export default ThemeDatePicker
