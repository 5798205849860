import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import React, { useContext, useLayoutEffect } from 'react'
import { Link } from 'react-router-dom';
import { BreadCrumbContext } from '../context/store';
import ThemeButton from '../layout/components/ThemeButton';
import RatesReview from './Report/RatesReview';
import TreasuryWallets from './Report/TreasuryWallets';
import UsersWalletTotalBalance from './Report/UsersWalletTotalBalance';

const Dashboard = () => {
  const [contextBC, setContextBC] = useContext(BreadCrumbContext);

  useLayoutEffect(() => {
    setContextBC([
      {
        href: '/',
        text: 'Home'
      },
      {
        text: 'Dashboard'
      }
    ])
  }, [])
  const [value, setValue] = React.useState(null);

  return (
    <MDBRow className='m-0 pt-3'>
      <MDBCol size='12' className='mb-2 d-flex justify-content-between'>
        <Link to={`/Transaction/List/?type=financial`}>
          <ThemeButton size='small' color='white' className='px-2 py-1' variant='outlined'>Financials</ThemeButton>
        </Link>
        <Link to={`/Transaction/List/?type=deposit`}>
          <ThemeButton size='small' color='success' className='px-2 py-1' variant='outlined'>Deposits</ThemeButton>
        </Link>
        <Link to={`/Transaction/List/?type=withdraw`}>
          <ThemeButton size='small' color='danger' className='px-2 py-1' variant='outlined'>Withdraws</ThemeButton>
        </Link>
        <Link to={`/Transaction/List/?type=topup`}>
          <ThemeButton size='small' color='neon' className='px-2 py-1' variant='outlined'>Topups</ThemeButton>
        </Link>
        <Link to={`/Transaction/List/?type=bet`}>
          <ThemeButton size='small' color='yellow' className='px-2 py-1' variant='outlined'>Bets</ThemeButton>
        </Link>
      </MDBCol>
      <RatesReview />
      <TreasuryWallets />
      <UsersWalletTotalBalance />
      <div className='small'>
        Today Report (Demo)
        <br />
        Deposit InUSD/Count
        <br />
        Withdraw InUSD/Count
        <br />
        Topup InUSD/Count
        <br />
        Turnover InUSD/Count
        <br />
        PL InUSD
      </div>
    </MDBRow >
  )
}

// const DashboardCard = (props) => {
//   const { title } = props;
//   return <MDBCol size='12' md='6' lg='4'>
//     <Box className='rounded-6 h-100 box-shadow-neon overflow-hidden'>
//       <Box className='d-flex justify-content-between align-items-center box-shadow-neon mb-3'>
//         <Typography className='text-neon'>{title}</Typography>
//         1
//       </Box>
//       <Box>
//         Content
//         <br />
//         Content
//         <br />
//         Content
//         <br />
//         Content
//         <br />
//         Content
//         <br />
//         Content
//         <br />
//         Content
//         <br />
//         Content
//         <br />
//       </Box>
//     </Box>
//   </MDBCol>
// }

export default Dashboard