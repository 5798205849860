import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { makeStyles } from '@mui/styles';

import Drawer from '@mui/material/Drawer';


import React, { useEffect, Fragment, useContext } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { MDBIcon } from 'mdb-react-ui-kit';
import { Box, List } from '@mui/material';


import $ from 'jquery'
import { Link } from 'react-router-dom';
import { Context } from '../../context/store';

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
    // root: {
    //     display: 'flex'
    // },
    // content: {
    //     flexGrow: 1,
    //     padding: theme.spacing(0),
    //     paddingTop: '10px',
    // },
    // drawer: {
    //     width: drawerWidth,
    //     flexShrink: 0,
    //     whiteSpace: 'nowrap',
    //     position: 'absolute',
    //     [theme.breakpoints.up('sm')]: {
    //         position: 'static'
    //     },
    // },
    // drawerOpen: {
    //     width: drawerWidth,
    //     transition: theme.transitions.create('width', {
    //         easing: theme.transitions.easing.sharp,
    //         duration: theme.transitions.duration.enteringScreen,
    //     }),
    // },
    // drawerClose: {
    //     transition: theme.transitions.create('width', {
    //         easing: theme.transitions.easing.sharp,
    //         duration: theme.transitions.duration.leavingScreen,
    //     }),
    //     overflowX: 'hidden',
    //     width: 0, //theme.spacing(7) + 1,
    //     [theme.breakpoints.up('sm')]: {
    //         width: theme.spacing(7) + 1
    //     },
    // },
    // toolbar: {
    //     display: 'flex',
    //     alignItems: 'center',
    //     justifyContent: 'flex-end',
    //     padding: theme.spacing(0, 1),
    //     color: '#fff',
    //     position: 'fixed',
    //     width: '13rem',
    //     zIndex: 1,
    //     background: '#121212',
    //     borderBottom: '1px solid #999',
    //     ...theme.mixins.toolbar,
    // },
    // chevron: {
    //     color: '#fff',
    //     paddingRight: 5
    // },
    // listItem: {
    //     borderLeft: '0.2rem solid transparent',
    //     '&.active': {
    //         borderLeft: '0.2rem solid #f4b740'
    //     }
    // },
    // listItemIcon: {
    //     fontSize: '1rem',
    //     color: '#fff',
    //     minWidth: '2.2rem !important'
    //     //  "& .MuiSvgIcon-root": { fontSize: "1.2rem" }
    // },
    // listSubItemLink: {
    //     display: 'contents',
    //     color: '#fff !important',
    //     '&:hover': {
    //         color: '#f4b740 !important',
    //     }
    // },
    // listSubItemLine: {
    //     borderTop: '1px solid white',
    //     width: 5,
    // },
    // listSubItemText: {
    //     '& span': {
    //         paddingLeft: 4,
    //         fontSize: '0.8em',
    //         [theme.breakpoints.down('sm')]: {
    //             //       fontSize: '1em',
    //         }
    //     }
    // },
    // listItemText: {
    //     "& span": {
    //         //  fontFamily: 'Noto Sans JP, sans-serif',
    //         fontSize: '0.9em',
    //         [theme.breakpoints.down('sm')]: {
    //             //    fontSize: '1.1rem',
    //         }
    //     }
    // },
    // subListItem: {

    // }
}));


const SideNav = (props) => {
    const { handleDrawerOpen, handleDrawerClose, drawerOpen } = props;
    const classes = useStyles();
    const MenuItemsList = [
        // Financial
        {
            role: 'accountant',
            text: 'Financial',
            icon: 'chart-bar',
            subItems: [{
                text: 'Users Wallet Report',
                url: '/Financial/UsersWalletReport',
            },
            {
                text: 'Transfer To Treasury',
                url: '/TransferToTreasury/TransferToTreasuryRequests',
            },
            {
                text: 'Table Stats',
                url: '',
            },
            {
                text: 'Profit Stats',
            },
            {
                text: 'Commission Stats',
            },
            {
                text: 'Game Stats',
            },
            {
                text: 'TAX Control',
            },
            {
                text: 'Exchanges',
            }]
        },
        // Users
        {
            role: 'manager',
            text: 'Users',
            icon: 'users',
            subItems: [{
                text: 'User List',
                url: '/User/List',
            },
            {
                text: 'User Sessions',
                url: '/User/Sessions',
            },
            {
                text: 'User Logs',
                url: '/Logs',
            },
            {
                text: 'User Activities',
                url: '/User/Activities',
            },
            {
                text: 'User Groups',
            },
            {
                text: 'Chat Logs',
            }]
        },
        // Deposits
        {
            role: 'accountant',
            text: 'Deposits',
            icon: 'credit-card',
            subItems: [{
                text: 'Deposit List',
                url: '/transaction/list/deposit',
            },
                // {
                //     text: 'Deposit Gateways',
                // }
            ]
        },
        // Withdraws
        {
            role: 'accountant',
            text: 'Withdraws',
            icon: 'upload',
            subItems: [{
                text: 'Withdraw List',
                url: '/Withdraw/WithdrawRequests'
            },
            {
                text: 'Withdraw Gateways',
            }]
        },
        // Support
        {
            role: 'support',
            text: 'Support',
            icon: 'life-ring',
            subItems: [
                {
                    text: 'Chat',
                    url: '/Support/Chat',
                },
                {
                    text: 'Tickets',
                },
                {
                    text: 'Templates',
                },
                {
                    text: 'System Tickets',
                },
                {
                    text: 'Announcement',
                }]
        },
        // Risk Managements
        {
            role: 'admin',
            text: 'Risk Managements',
            icon: 'user',
            subItems: [{
                text: 'High Risk Users',
            }, {
                text: 'High Risk Markets',

            }]

        },
        // Casino Games
        {
            role: 'admin',
            text: 'Games',
            icon: 'gamepad',
            subItems: [
                {
                    text: 'Crash Report',
                    url: '/Games/Crash/Report',
                }
            ]
        },
        // Affiliate
        {
            role: 'admin',
            text: 'Affiliate',
            icon: 'user',
            subItems: [{
                text: 'Affiliate List',
            }, {
                text: 'Reports',
            }, {
                text: 'Domains',
            }, {
                text: 'Withdraws',
            }, {
                text: 'Affiliate Settings',
            }, {
                text: 'CPA Settings',
            }]
            // Testimonial Link
            // Mapp
            // Backbone
        },
        // Campaigns
        {
            role: 'admin',
            text: 'Campaigns',
            icon: 'tag',
            subItems: [{
                text: 'URL Generator',
            }, {
                text: 'Referrers Panel',
            }, {
                text: 'SEO Keywords',
            }, {
                text: 'SEO Backlinks',
            }, {
                text: 'Google Analytics',
            }, {
                text: 'Email Marketing',
            }, {
                text: 'SMS Marketing',
            }, {
                text: 'Telegram Bot',
            }, {
                text: 'CRM Results',
            }, {
                text: 'Bonus Campaigns',
            }, {
                text: 'Forum',
            }, {
                text: 'Blog',
            }]
            // Testimonial Link
            // Mapp
            // Backbone
        },
        // Online Shop
        {
            role: 'admin',
            text: 'Online Shop',
            icon: 'shopping-basket',
            subItems: [{
                text: 'GamePlays',
            }, {
                text: 'Themes',
            }]
        },
        // Settings
        {
            role: 'admin',
            text: 'Settings',
            icon: 'cogs',
            subItems: [{
                text: 'Profile',
            }, {
                text: 'Employees List',
            }, {
                text: 'Employees Logs',
            }, {
                text: 'KYC',
            }, {
                text: 'Domains',
            }, {
                text: 'Geo Management',
            }, {
                text: 'Currencies',
            }, {
                text: 'Cryptos',
            }, {
                text: 'System',
                url: '/Settings/System',
            }]
        },
        // Servers
        {
            role: 'admin',
            text: 'Servers',
            icon: 'server',
            subItems: [{
                text: 'Servers List',
                url: '/Servers/List',
            }, {
                //     text: 'Servers Logs',
                //     url: '/Servers/Logs'
                // }, {
                text: 'Proxy Servers',
            }, {
                text: 'Firewalls',
            }, {
                text: 'IP Access',
            }, {
                text: 'Databases',
            }, {
                text: 'CloudFlare',
            }, {
                text: 'CDN',
            }, {
                text: 'Requests',
            }, {
                text: 'Threads',
            }]
        },
        // Reports
        {
            role: 'admin',
            text: 'Reports',
            icon: 'chart-pie',
            subItems: [{
                text: 'Financial',
                url: '/Report/FinancialReport',
            }, {
                text: 'Aggregate',
                url: '/Report/Aggregate',
            }, {
                text: 'User Report',
                url: '/Report/UserReport',
            }]
        },
        // Sport Logs
        {
            role: 'admin',
            text: 'Sport Logs',
            icon: 'trophy',
            subItems: [{
                text: 'Sport Logs',
                url: '/Sport/Logs',
            }]
        },
        // Sport Logs
        {
            role: 'admin',
            text: 'Test',
            icon: 'vials',
            subItems: [{
                text: 'Test Crash',
                url: '/TestList/Crash',
            }]
        },
        // Sport Logs
        {
            role: 'admin',
            text: 'Pages',
            icon: 'file-alt',
            subItems: [{
                text: 'Pages',
                url: '/Pages/List',
            }]
        },
        // Reports
        // {
        //     role: 'admin',
        //     text: 'Reports',
        //     icon: 'chart-pie',
        //     subItems: [{
        //         text: 'Transactions',
        //         url: '/Transaction/TransactionList',
        //     }]
        // }
    ];

    useEffect(() => {
        if (!drawerOpen) {
            $(`#DrawerMenuItems [data-id]`).slideUp();
            $(`#DrawerMenuItems [data-item]`).find('[class*=fa-chevron-]*').addClass('fa-chevron-left').removeClass('fa-chevron-down');
        }
    }, [drawerOpen])

    const toggleDrawerItem = (item) => {
        $(`#DrawerMenuItems [data-id="${item}"]`).slideToggle();
        $(`#DrawerMenuItems [data-item]`).removeClass('active');
        $(`#DrawerMenuItems [data-item="${item}"]`).addClass('active');
        $(`#DrawerMenuItems [data-item="${item}"]`).find('[class*=fa-chevron-]*').toggleClass('fa-chevron-left fa-chevron-down');
        $(`#DrawerMenuItems [datasubitem]`).removeClass('active');
        handleDrawerOpen()
    }

    const setCompFunc = (sub, item) => {
        // setComp(comp);
        console.log(sub)
        $(`#DrawerMenuItems [data-item]`).removeClass('active');
        $(`#DrawerMenuItems [data-item='${item}']`).addClass('active');
        $(`#DrawerMenuItems [datasubitem]`).removeClass('active');
        $(`#DrawerMenuItems [datasubitem='${sub}']`).addClass('active');
    }

    return (
        <Drawer
            variant="permanent"
            sx={{
                zIndex: 9999
            }}

        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    color: '#fff',
                    position: 'fixed',
                    width: '13rem',
                    zIndex: 1,
                    background: '#121212',
                }}>
                <MDBIcon onClick={handleDrawerClose} icon='chevron-left' className='p-3 cursor-pointer'></MDBIcon>
            </Box>
            <Divider />
            <List id='DrawerMenuItems'
                style={{ top: '1.8rem' }}
                className='noScrollbar'>
                <ListItem
                    className='pb-0'
                    sx={{
                        borderLeft: '0.2rem solid transparent',
                    }} data-item='Dashboard' button onClick={() => { toggleDrawerItem('Dashboard') }}>
                    <Link to='/'>
                        <div className='py-2 text-white'>
                            <ListItemText primary={<span style={{fontSize: '0.9em'}}><MDBIcon icon={'home'} className='pe-3'></MDBIcon>Dashboard</span>} />
                        </div>
                    </Link>
                </ListItem>
                {MenuItemsList.map((item, index) => (
                    <Fragment key={index} >
                        <ListItem sx={{
                            borderLeft: '0.2rem solid transparent',
                            '&.active': {
                                borderLeft: '0.2rem solid #f4b740'
                            }
                        }} data-item={item.text} button onClick={() => { toggleDrawerItem(item.text) }}>
                            <ListItemIcon sx={{
                                fontSize: '1rem',
                                color: '#fff',
                                minWidth: '2.2rem !important'
                            }}>
                                <MDBIcon icon={item.icon}></MDBIcon>
                            </ListItemIcon>
                            <ListItemText
                                sx={{ "& span": { fontSize: '0.9em' } }}
                                primary={item.text} />
                            {item.subItems.length > 0 && <MDBIcon className={classes.chevron} icon='chevron-left'></MDBIcon>}
                        </ListItem>
                        <div data-id={item.text} style={{ display: 'none' }}>
                            {(item.subItems).map((subItem, index) => (
                                <Fragment key={index} >
                                    <Link sx={{
                                        display: 'contents',
                                        color: '#fff !important',
                                        '&:hover': {
                                            color: '#f4b740 !important',
                                        }
                                    }}
                                        to={subItem.url || '/'}>
                                        <ListItem sx={{
                                            opacity: '0.7',
                                            color: 'white !important',
                                            borderLeft: '1px solid #fff !important',
                                            marginLeft: '20px !important',
                                            '&:hover': {
                                                // color: '#f4b740',
                                                opacity: '1 !important'
                                            },
                                            '&.active': {
                                                color: '#f4b740 !important',
                                                opacity: '1 !important'
                                            }
                                        }}
                                            className={' px-0 py-0'} datasubitem={subItem.text} datasubitem-item={item.text} button dense
                                            onClick={() => { setCompFunc(subItem.text, item.text) }}>
                                            <Box sx={{
                                                borderTop: '1px solid white',
                                                width: 5
                                            }}>
                                            </Box>
                                            <ListItemText sx={{
                                                '& span': {
                                                    paddingLeft: 1,
                                                    fontSize: '0.8em'
                                                }
                                            }} primary={subItem.text} />
                                        </ListItem>
                                    </Link>
                                </Fragment>
                            ))}
                        </div>
                    </Fragment>
                ))
                }
            </List>
        </Drawer>
    )
}

export default SideNav
