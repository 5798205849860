import React from 'react';
import theme from './DefaultTheme';
import { Slide, ThemeProvider } from '@mui/material';
import LayoutAppBar from './Header/LayoutAppBar';
import { SnackbarProvider } from 'notistack';
import UserQuickViewProvider from '../contextProviders/UserQuickViewProvider';
import { Outlet } from 'react-router-dom';

const Layout = () => {

  return (<>
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        autoHideDuration={1500}
        TransitionComponent={Slide}
        maxSnack={2}>
        <UserQuickViewProvider>
          <LayoutAppBar>
            <Outlet />
          </LayoutAppBar>
        </UserQuickViewProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </>)
}

export default Layout
