import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import React from 'react';
import ThemeCard from '../../../layout/components/ThemeCard';
import PlayerReportTable from './PlayerReportTable';

const PlayerReportTableCard = (props) => {
    const { type, playerReportData, collapsed, collapsible } = props;
    return <ThemeCard collapsible={collapsible} collapsed={collapsed}
        headerProps={{
            sx: {
                height: 'auto !important'
            }
        }}
        header={
            <Typography variant='subtitle1' className='w-100 px-3'>
                <Box className='w-100 d-flex flex-column justify-content-center align-items-start'>
                    <Box className='text-success fw-bold text-capitalize'>{type} Player Report Within
                        <small className='text-light px-2'>
                            ({moment(playerReportData.reportData[type].end).diff(moment(playerReportData.reportData[type].start), 'days') + 1} days)
                        </small>
                    </Box>
                    <small>
                        {moment.utc(playerReportData.reportData[type].start).format('YYYY/MM/DD')}
                        <small className='opacity-60 px-1'>
                            {moment.utc(playerReportData.reportData[type].start).format('HH:mm:ss UTC')}
                        </small>
                        <span className='pe-1 text-neon'>
                            To
                        </span>
                        {moment.utc(playerReportData.reportData[type].end).format('YYYY/MM/DD')}
                        <small className='opacity-60 px-1'>
                            {moment.utc(playerReportData.reportData[type].end).format('HH:mm:ss UTC')}
                        </small>
                    </small>
                </Box>
            </Typography>
        }>
        <PlayerReportTable data={playerReportData.reportData[type]} currentBalance={playerReportData.currentBalance} />
    </ThemeCard>
}

export default PlayerReportTableCard