import React, { useEffect, useState } from 'react';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import _ from 'lodash'
import ThemeInput from '../../layout/components/ThemeInput';
import ThemeSelect from '../../layout/components/ThemeSelect';
import numeral from 'numeral';
import Collapse from '@mui/material/Collapse';
import CopyToClipboard from 'react-copy-to-clipboard';
import ReactJson from 'react-json-view';
import ThemeButton from '../../layout/components/ThemeButton';
import ThemeDatePicker from '../../layout/components/ThemeDatePicker';
import ThemeMultiSelect from '../../layout/components/ThemeMultiSelect';
import moment from 'moment';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import RenderService from './Renders/RenderService';
import ThemeFormatCurrency from '../../layout/components/ThemeFormatCurrency';
import { getBlockchainColor } from '../../helpers/adminHelpers';

const availableModes = ['sport', 'relatedSport', 'evo', 'relatedEvo', 'em', 'relatedEm', 'fullHistory'];

const getPreviousMonth = () => {
    let date = new Date();
    let from = new Date(date.getFullYear(), date.getMonth(), 1);
    return from
}
const getLastDayOfCurrentMonth = () => {
    let date = new Date();
    let to = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    return to
}

function getFloatValue(value) {
    console.log(value);
    if (value.slice(-1) == '.') {
        const dotCount = value.match(/\./g).length;
        if (dotCount > 1) {
            return value.slice(0, -1)
        } else {
            return value
        }
    }
    if (value == 0) return 0;
    return parseFloat(value.trim())
}

const TransactionFilter = (props) => {
    const isMobile = useMediaQuery('(max-width:600px)') || props.mobile;

    const { filter, setFilter, count, getTransactions, setRows, scrollFilter, from, to } = props;
    const [showMore, setShowMore] = useState({
        filters: false,
        searchQuery: false
    })

    const [find, setFind] = useState({
        ...filter.find,
        userId: filter.find.userId || '',
        title: filter.find.title || '',
        betId: filter.find.betId || '',
        type: filter.find.type || '',
        service: '',
        currency: '',
        fromDate: from || getPreviousMonth(),
        toDate: to || getLastDayOfCurrentMonth(),
        // More Fiters
        topupBy: filter.find.topupBy || '',
        betAmountMin: 0,
        betAmountMax: '',
        oddsMin: 0,
        oddsMax: '',
        status: '',
        isValid: 'true',
        mode: [],
    })

    const [sort, setSort] = useState(filter.sort)
    const [limit, setLimit] = useState(filter.limit)

    const handleSetFilter = () => {
        let findQuery = { ...find, $and: [] }
        // UserId

        if (find.userId && (typeof (find.userId) == 'string') && (find.userId.trim() != '')) {
            findQuery.userId = { $regex: find.userId, $options: "i" }
        } else {
            findQuery = _.omit(findQuery, ['userId']);
        }
        // Topup By
        if (find.topupBy && (find.topupBy.trim() != '')) {
            findQuery.$and.push({
                $or: [
                    { 'meta.topupBy.user.username': { $regex: find.topupBy, $options: "i" } },
                    { 'meta.topupBy.user.telegram.username': { $regex: find.topupBy, $options: "i" } },
                ]
            })
        } else {
            findQuery = _.omit(findQuery, ['topupBy']);
        }
        // Title
        if (find.title && (find.title.trim() != '')) {
            findQuery.$and.push({
                $or: [
                    { "meta.relatedSportTransactions.meta.Order.Bets": { $elemMatch: { BetStakes: { $elemMatch: { EventNameOnly: { $regex: find.title || '', $options: "i" } } } } } },
                    { "meta.evoTransaction.game.type": { $regex: find.title || '', $options: "i" } },
                    // { 'meta.relatedSportTransactions.meta.Order.Bets[0].BetStakes[0].EventNameOnly': { $regex: find.title, $options: "i" } },
                    // { 'meta.topupBy.user.telegram.username': { $regex: find.title, $options: "i" } },
                ]
            })
        } else {
            findQuery = _.omit(findQuery, ['title']);
        }
        // Bet/Order Number
        if (find.betId && (find.betId.trim() != '')) {
            findQuery.$and.push({
                $or: [
                    { "meta.bet.normalizedId": { $regex: find.betId || '', $options: "i" } },
                ]
            })
        } else {
            findQuery = _.omit(findQuery, ['betId']);
        }
        // Type
        if (find.type && (find.type.trim() != '')) {
            if (['open', 'lost', 'won', 'cashback', 'cashout', 'rollback'].indexOf(find.type) > -1) {
                findQuery.$and.push({
                    "meta.bet.betState": find.type
                })
                findQuery = _.omit(findQuery, ['type']);
            } else if (find.type == 'allGameStates') {
                findQuery.$and.push({
                    "type": { $ne: 'ref' },
                    "meta.bet.betState": { $exists: 1 }
                })
                findQuery = _.omit(findQuery, ['type']);
            } else if (find.type == 'financial') {
                findQuery.$and.push({
                    'type': { $in: ['deposit', 'withdraw', 'topup'] }
                })
                findQuery = _.omit(findQuery, ['type']);
            } else {
                findQuery.type = find.type//{ $regex: find.type, $options: "i" }
            }
        } else {
            findQuery = _.omit(findQuery, ['type']);
        }
        // subtype
        if (find.subtype && (find.subtype.trim() != '')) {
            findQuery.subtype = find.subtype
        } else {
            findQuery = _.omit(findQuery, ['subtype']);
        }
        // subtype
        if (find.status && (find.status.trim() != '')) {
            findQuery.status = find.status
        } else {
            findQuery = _.omit(findQuery, ['status']);
        }
        // Service
        if (find.service && (find.service.trim() != '')) {
            if (find.service == 'AllGames') {
                findQuery.service = { $in: ['EveryMatrix', 'Pragmatic', 'Digitain', 'Evolution', 'Poker', 'Crash', 'Netent', 'RedTiger', 'Pragmatic'] }
            } else {
                findQuery.service = { $regex: find.service, $options: "i" }
            }
        } else {
            findQuery = _.omit(findQuery, ['service']);
        }
        // Currency
        if (find.currency && (find.currency.trim() != '')) {
            findQuery.currency = find.currency
        } else {
            findQuery = _.omit(findQuery, ['currency']);
        }

        // Wallet Address
        if (find.walletAddress && (find.walletAddress.trim() != '')) {
            findQuery.$and.push({
                $or: [
                    { 'wallet.BTC.address': { $regex: find.walletAddress, $options: "i" } },
                    { 'wallet.ETH.address': { $regex: find.walletAddress, $options: "i" } },
                    { 'wallet.TRX.address': { $regex: find.walletAddress, $options: "i" } },
                    { 'wallet.DOGE.address': { $regex: find.walletAddress, $options: "i" } },
                ]
            })
        }
        // From Date
        if (find.fromDate) {
            findQuery.$and.push({
                'createdAt': { $gt: find.fromDate }
            })
        }
        // To Date
        if (find.toDate) {
            findQuery.$and.push({
                'createdAt': { $lt: find.toDate }
            })
        }
        // BetAmount
        if (find.betAmountMin > 0) {
            findQuery.$and.push({
                'meta.bet.betAmountInUSD': { $gt: parseFloat(find.betAmountMin) }
            })
        }
        if (find.betAmountMax) {
            findQuery.$and.push({
                'meta.bet.betAmountInUSD': { $lte: parseFloat(find.betAmountMax), $gt: 0 },
            })
            findQuery.$and.push({
                'service': { $nin: ['iM-API', 'CryptoPay-API', 'Telegram'] },
            })
        }
        // Odds
        if (parseFloat(find.oddsMin) > 0) {
            findQuery.$and.push({
                'meta.bet.odds': { $gt: parseFloat(find.oddsMin) }
            })
        }
        if (find.oddsMax) {
            findQuery.$and.push({
                'meta.bet.odds': { $lte: parseFloat(find.oddsMax), $gt: 0 },
            })
            findQuery.$and.push({
                'service': { $nin: ['iM-API', 'CryptoPay-API', 'Telegram'] },
            })
        }
        // isValid
        if (find.isValid == 'true') {
            findQuery.isValid = true;
        }
        if (find.isValid == 'false') {
            findQuery.isValid = false;
        }
        if (find.isValid == 'all') {
            findQuery = _.omit(findQuery, ['isValid'])
        }

        findQuery = _.omit(findQuery, [
            'mode',
            'fromDate', 'toDate',
            'betId',
            'deposit', 'withdraw', 'topup',
            'oddsMin', 'oddsMax',
            'betAmountMax', 'betAmountMin',
        ]);

        if (findQuery.$and.length == 0) findQuery = _.omit(findQuery, ['$and']);
        const modeObject = Object.fromEntries(availableModes.map(m => [[m], find.mode.indexOf(m) > -1 ? true : false]));
        let filterObject = {
            find: findQuery,
            sort: sort,
            limit: limit,
        }
        filterObject.mode = modeObject;
        setFilter(filterObject);
        getTransactions(setRows, filterObject);
    }

    useEffect(() => {
        handleSetFilter();
    }, [find, sort, limit])

    const [showFilters, setShowFilters] = useState(!isMobile)
    const [daysRange, setDaysRange] = useState(0);

    useEffect(() => {
        setDaysRange(moment(find.toDate).diff(moment(find.fromDate), 'days'));
    }, [find.toDate, find.fromDate])

    return (
        <Grid container className='w-100'>
            {isMobile && <Grid item xs={12} style={{ backgroundColor: '#000d' }} className='p-1 py-2 small d-flex align-items-center justify-content-between box-shadow-neon'>
                {<small className='cursor-pointer border border-neon text-neon rounded-pill px-2' onClick={() => setShowFilters(!showFilters)}><MDBIcon icon={`angle-${showFilters ? 'up' : 'down'}`}></MDBIcon> Filters</small>}
                <small className='text-neon' style={{ lineHeight: '0.8rem' }}>
                    {numeral(count).format('0,0')} record found.
                </small>
            </Grid>
            }
            <Collapse in={showFilters || !isMobile} className='w-100' sx={{ bgcolor: '#000d' }}>
                <Box>
                    <form className='pb-2 py-md-1' onSubmit={e => { e.preventDefault(); handleSetFilter() }}>
                        <Grid container columns={scrollFilter ? 6 : 20}>
                            <Grid item xs={6} md={2} xl={1} className='p-1'>
                                <ThemeInput inputClassName={find.userId != '' ? 'box-shadow-success border-success rounded-3 border' : ''} type='search' label='User Id' value={find.userId} onChange={e => setFind({ ...find, userId: e.target.value.trim() })}></ThemeInput>
                            </Grid>
                            <Grid item xs={6} md={2} xl={1} className='p-1'>
                                <ThemeInput type='search' label='Bet ID' value={find.betId} onChange={e => setFind({ ...find, betId: e.target.value.trim() })}></ThemeInput>
                            </Grid>
                            <Grid item xs={6} md={2} xl={2} className='p-1'>
                                <Types find={find} setFind={setFind} />
                            </Grid>
                            <Grid item xs={6} md={2} xl={2} className='p-1'>
                                <SubTypes find={find} setFind={setFind} />
                            </Grid>
                            <Grid item xs={6} md={2} xl={2} className='p-1'>
                                <Services find={find} setFind={setFind} />
                            </Grid>
                            <Grid item xs={6} md={2} xl={1} className='p-1'>
                                <Statuses find={find} setFind={setFind} />
                            </Grid>
                            <Grid item xs={6} md={2} xl={1} className='p-1'>
                                <Currencies find={find} setFind={setFind} />
                            </Grid>
                            <Grid item xs={12} md={4} xl={5} className='p-1 d-flex justify-content-between align-items-center'>
                                <ThemeDatePicker className='pe-1' label='From' value={find.fromDate} onChange={fromDate => setFind({ ...find, fromDate })}></ThemeDatePicker>
                                <span className={`small rounded-circle p-1 text-shadow-${daysRange < 1 ? 'danger text-danger' : daysRange < 7 ? 'warning text-warning' : 'success text-success'}`}>{daysRange}</span>
                                <ThemeDatePicker className='ps-1' label='To' value={find.toDate} onChange={toDate => setFind({ ...find, toDate })}></ThemeDatePicker>
                            </Grid>
                            <Grid item xs={4} md={2} xl={2} className='p-1'>
                                <ThemeSelect value={JSON.stringify(sort)} label='Sort' onChange={e => setSort(JSON.parse(e.target.value))}>
                                    <option value={JSON.stringify({ updatedAt: -1 })}><small className='ps-1'>Update At</small></option>
                                    <option value={JSON.stringify({ createdAt: -1 })}><small className='ps-1'>Created At</small></option>
                                    <option value={JSON.stringify({ amount: -1 })}><small className='ps-1'>Amount <i className='fa fa-sort-amount-asc' /></small></option>
                                    <option value={JSON.stringify({ amount: 1 })}><small className='ps-1'>Amount <i className='fa fa-sort-amount-desc' /></small></option>
                                </ThemeSelect>
                            </Grid>
                            <Grid item xs={4} md={2} xl={3} className='p-1 d-flex'>
                                <Box className='w-50'>
                                    <ThemeSelect value={limit} label='Page Size' onChange={e => setLimit(parseInt(e.target.value))}>
                                        <option value={10}><span className='ps-1'>10</span></option>
                                        <option value={15}><span className='ps-1'>15</span></option>
                                        <option value={20}><span className='ps-1'>20</span></option>
                                        <option value={50}><span className='ps-1'>50</span></option>
                                        <option value={100}><span className='ps-1'>100</span></option>
                                        <option value={200}><span className='ps-1'>200</span></option>
                                        <option value={500}><span className='ps-1'>500</span></option>
                                    </ThemeSelect>
                                </Box>
                                <Box className='d-flex align-items-center'>
                                    <MDBIcon className='cursor-pointer px-2' onClick={() => setShowMore({ ...showMore, filters: !showMore.filters })} icon={showMore.filters ? 'chevron-up' : 'chevron-down'}></MDBIcon>
                                    <Typography variant='subtitle2' className={` px-2 py-1  ${count > limit ? 'box-shadow-danger rounded-3 text-danger' : 'text-neon'}`} style={{ lineHeight: '0.8rem' }}>
                                        {numeral(count).format('0,0')} records
                                    </Typography>
                                </Box>
                            </Grid>
                            {/* <Grid item xs={4} md={2} xl={2} className='px-1 text-neon text-shadow-neon text-start d-flex justify-content-between align-items-center'>
                                <MDBIcon className='cursor-pointer' onClick={() => setShowMore({ ...showMore, filters: !showMore.filters })} icon={showMore.filters ? 'chevron-up' : 'chevron-down'}></MDBIcon>
                                <Typography variant='subtitle2' className={` px-2 py-1  ${count > limit ? 'box-shadow-danger rounded-3 text-danger' : 'text-neon'}`} style={{ lineHeight: '0.8rem' }}>
                                    {numeral(count).format('0,0')} records
                                </Typography>
                            </Grid> */}
                        </Grid>
                        <Collapse in={showMore.filters} timeout="auto" unmountOnExit>
                            <Grid container className='pt-2'>
                                <Grid item xs={6} md={2} xl={1} className='p-1'>
                                    <ThemeInput type='search' label='Team, Casino Table' value={find.title} onChange={e => setFind({ ...find, title: e.target.value.trim() })}></ThemeInput>
                                </Grid>
                                <Grid item xs={12} md={3} xl={1} className='p-1'>
                                    <ThemeInput inputClassName={find.topupBy != '' ? 'box-shadow-success border-success rounded-3 border' : ''} type='search' label='Topup By' value={find.topupBy} onChange={e => setFind({ ...find, topupBy: e.target.value.trim() })}></ThemeInput>
                                </Grid>
                                <Grid item xs={6} md={3} xl={1} className='p-1'>
                                    <ThemeInput inputClassName={find.betAmountMin > 0 ? 'box-shadow-success border-success rounded-3 border' : ''} type='search' labelClass='text-warning' label='Bet Amount Min' value={find.betAmountMin} onChange={e => setFind({ ...find, betAmountMin: getFloatValue(e.target.value) })}></ThemeInput>
                                </Grid>
                                <Grid item xs={6} md={3} xl={1} className='p-1'>
                                    <ThemeInput inputClassName={find.betAmountMax ? 'box-shadow-success border-success rounded-3 border' : ''} type='search' labelClass='text-warning' label='Bet Amount Max' value={find.betAmountMax} onChange={e => setFind({ ...find, betAmountMax: getFloatValue(e.target.value) })}></ThemeInput>
                                </Grid>
                                <Grid item xs={6} md={3} xl={1} className='p-1'>
                                    <ThemeInput inputClassName={find.oddsMin > 0 ? 'box-shadow-success border-success rounded-3 border' : ''} type='search' labelClass='text-danger' label='Odds Min' value={find.oddsMin} onChange={e => setFind({ ...find, oddsMin: getFloatValue(e.target.value) })}></ThemeInput>
                                </Grid>
                                <Grid item xs={6} md={3} xl={1} className='p-1'>
                                    <ThemeInput inputClassName={find.oddsMax != '' ? 'box-shadow-success border-success rounded-3 border' : ''} type='search' labelClass='text-danger' label='Odds Max' value={find.oddsMax} onChange={e => setFind({ ...find, oddsMax: getFloatValue(e.target.value) })}></ThemeInput>
                                </Grid>
                                <Grid item xs={4} md={3} xl={1} className='p-1'>
                                    <ThemeSelect value={find.isValid} label='IsValid' onChange={e => setFind({ ...find, isValid: e.target.value })}>
                                        <option value='all'>All</option>
                                        <option value='true'>Valid</option>
                                        <option value='false'>Invalid</option>
                                    </ThemeSelect>
                                </Grid>
                                <Grid item xs={4} md={3} xl={2} className='p-1'>
                                    <ThemeMultiSelect
                                        label='Meta Fields'
                                        maxShow={3}
                                        value={find.mode}
                                        setValue={e => setFind({ ...find, mode: e })}
                                        options={['sport', 'relatedSport', 'evo', 'relatedEvo', 'em', 'relatedEm', 'fullHistory']}
                                    ></ThemeMultiSelect>
                                </Grid>
                                <Grid item xs={4} md={3} xl={1} className='px-1 text-success pt-1 text-shadow-neon text-start d-flex justify-content-end align-items-center'>
                                    <MDBIcon className='cursor-pointer' onClick={() => setShowMore({ ...showMore, searchQuery: !showMore.searchQuery })} icon={showMore.searchQuery ? 'chevron-up' : 'chevron-down'}></MDBIcon>
                                    <small onClick={() => setShowMore({ ...showMore, searchQuery: !showMore.searchQuery })} className='px-1 cursor-pointer' style={{ lineHeight: '0.8rem' }}>
                                        Advanced
                                    </small>
                                </Grid>
                            </Grid>
                        </Collapse>
                        <Collapse in={showMore.searchQuery} timeout="auto" unmountOnExit>
                            <Grid container className='small'>
                                <Grid item className='small' xs={12}>
                                    <div>
                                        <h6 className='text-info d-inline-block me-3'>Search Query
                                        </h6>
                                        <CopyToClipboard text={JSON.stringify(filter) || '{}'}>
                                            <ThemeButton size='small' variant='outlined' className='rounded-pill btn-sm' color='info'>Copy to Clipboard</ThemeButton>
                                        </CopyToClipboard>
                                    </div>
                                    <div className='border border-info small'>
                                        <ReactJson name={null} style={{ padding: '0.5rem' }} theme="monokai" src={filter} />
                                    </div>
                                </Grid>
                            </Grid>
                        </Collapse>
                    </form>
                </Box>
            </Collapse>
        </Grid>
    );
}

const Currencies = (props) => {

    const { find, setFind } = props;
    const currencies = ['', 'USDT', 'BTC', 'TRX', 'ETH', 'DOGE', 'USD'];
    return <ThemeSelect
        className={find.currency ? 'box-shadow-success border border-success ' : ''}
        value={find.currency}
        label='Currency'
        onChange={e => setFind({ ...find, currency: e.target.value.trim() })}
        options={currencies.map((currency, i) => <option key={i} value={currency}>{currency ?
            <span className={`text-${getBlockchainColor(currency)} p-1`}> <ThemeFormatCurrency icon='png' iconSize={1} currency={currency} hideText /><span>{currency}</span> </span>
            : <strong className='px-2 py-1'>All</strong>}</option>)} />
}

const Services = (props) => {
    const { find, setFind } = props;
    const services = ['', 'Evolution', 'EveryMatrix', 'Pragmatic', 'Crash', 'Netent', 'RedTiger', 'CryptoPay-API', 'iMoon', 'Telegram'
        // , 'website', 'iM-API'
    ];
    return <ThemeSelect
        className={find.service ? 'box-shadow-success border border-success ' : ''}
        value={find.service}
        label='Service'
        onChange={e => setFind({ ...find, service: e.target.value.trim() })}
        options={services.map((service, i) => <option key={i} value={service}>{service ? <RenderService size={16} sx={{ margin: 1 }} service={service} full /> : <strong className='px-2 py-1'>All</strong>}</option>)} />
}

const Types = (props) => {
    const { find, setFind } = props;
    const types = [
        { value: '', text: 'All', cls: 'text-white', types: ['bet'] },
        { value: 'bet', text: <strong className='text-yellow'><MDBIcon icon='gamepad' /> Bet</strong>, cls: '' },
        { value: 'financial', text: <strong className='text-teal'><MDBIcon icon='dollar-sign' /> Financials</strong>, cls: '' },
        { value: 'deposit', text: <span className='text-success'><MDBIcon icon='download' /> Deposit</span>, cls: '' },
        { value: 'withdraw', text: <span className='text-danger'><MDBIcon icon='upload' /> Withdraw</span>, cls: '' },
        { value: 'topup', text: <span className='text-neon'><MDBIcon icon='donate' /> Topup</span>, cls: '' },
        { value: 'ref', text: <span className='text-neon'><MDBIcon icon='money-bill-alt' /> Ref</span>, cls: '' },
    ];
    return <ThemeSelect options={types.map((type, i) => <option key={i} disabled={type.disabled} value={type.value}><strong className={`px-2 py-1 small ${type.cls}`}>{type.text}</strong></option>)} className={find.type ? 'small box-shadow-success border border-success ' : 'small'} value={find.type} label='Type' onChange={e => setFind({ ...find, type: e.target.value.trim() })} />
}

const SubTypes = (props) => {
    const { find, setFind } = props;
    const subtypesList = [
        { value: '', text: 'All', cls: 'text-white', types: ['bet'] },
        { value: 'open', text: 'Open', cls: 'text-yellow', types: ['bet'] },
        { value: 'won', text: 'Won', cls: 'text-success', types: ['bet'] },
        { value: 'lost', text: 'Lost', cls: 'text-danger', types: ['bet'] },
        { value: 'rollback', text: 'Rollback', cls: 'text-neon', types: ['bet', 'withdraw', 'deposit', 'topup'] },
        { value: 'cashback', text: 'Cashback', cls: 'text-warning', types: ['bet'] },
        { value: 'cashout', text: 'Cashout', cls: 'text-pink', types: ['bet'] },
        { value: 'cancel', text: 'Cancel', cls: 'text-purple', types: ['bet'] },
        { value: 'bet', text: 'Bet (Old TXs)', cls: 'text-gray', types: ['bet'], disabled: true },
        { value: 'deposit', text: 'Deposit', cls: 'text-success', types: ['bet'] },
        { value: 'withdraw', text: 'Withdraw', cls: 'text-danger', types: ['bet'] },
        { value: 'topup', text: 'Topup', cls: 'text-neon', types: ['bet'] },
        { value: 'bonus', text: 'Bonus', cls: 'text-yellow', types: ['bet'] },
        { value: 'discount', text: 'Discount', cls: 'text-neon' },
        { value: 'dailyDiscount', text: 'Daily Discount', cls: 'text-neon' },
        { value: 'weeklyDiscount', text: 'Weekly Discount', cls: 'text-neon' },
        { value: 'ref', text: 'Ref', cls: 'text-teal', types: ['bet'] },
        { value: 'rejected', text: 'Rejected', cls: 'text-pink', types: ['bet'] }
    ];
    return <ThemeSelect options={subtypesList.map((subtype, i) => <option key={i} disabled={subtype.disabled} value={subtype.value}><strong className={`px-2 py-1 small ${subtype.cls}`}>{subtype.text}</strong></option>)} className={find.subtype ? 'box-shadow-success border border-success ' : ''} value={find.subtype} label='Subtype' onChange={e => setFind({ ...find, subtype: e.target.value.trim() })} />
}

const Statuses = (props) => {
    const { find, setFind } = props;
    const statusesList = [
        { value: '', text: 'All', cls: 'text-white', types: ['bet'] },
        { value: 'verified', text: 'Verified', cls: 'text-success', types: ['bet'] },
        { value: 'rejected', text: 'Rejected', cls: 'text-danger', types: ['bet'] },
        { value: 'rollback', text: 'rollback', cls: 'text-warning', types: ['bet'] },
    ];
    return <ThemeSelect options={statusesList.map((status, i) => <option key={i} disabled={status.disabled} value={status.value}><strong className={`px-2 py-1 small ${status.cls}`}>{status.text}</strong></option>)} className={find.status ? 'box-shadow-success border border-success ' : ''} value={find.status} label='Status' onChange={e => setFind({ ...find, status: e.target.value.trim() })} />
}

export default TransactionFilter
