import { Box, Collapse, Tooltip, Zoom } from '@mui/material';
import axios from 'axios';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import numeral from 'numeral';
import React, { useRef, useState, useEffect, useContext } from 'react'
import { UAParser } from 'ua-parser-js';
import { getBearer } from '../../helpers/publicHelper';
import ThemeInput from '../../layout/components/ThemeInput';
import WorldMap from 'react-svg-worldmap';
import ThemeButton from '../../layout/components/ThemeButton';
import { Context } from '../../context/store';
import UserQuickViewButton from '../Users/UserQuickViewButton';
import jwt_decode from 'jwt-decode'
import ReactJson from 'react-json-view';
import ThemeFormatTime from '../../layout/components/ThemeFormatTime';
const formatDateTime = (datetime) => {
    const fromNow = moment(datetime).fromNow();
    return <small className={'text-' + (fromNow.indexOf('seconds') > 1 ? 'success' :
        fromNow.indexOf('minute') > 1 ? 'success' :
            fromNow.indexOf('hour') > 1 ? 'info' :
                fromNow.indexOf('day ') > 1 ? 'info' :
                    fromNow.indexOf('days') > 1 ? 'warning' : 'danger'
    )}>
        {moment(datetime).format('YYYY/MM/DD HH:mm:ss')}
        <div className='text-gray'>
            {moment(datetime).fromNow()}
        </div>
    </small>
}


const formatUA = (userAgent) => {
    if (!userAgent) return <></>
    const ua = UAParser(userAgent);
    if (!ua) return <></>
    return <span>
        {ua.os && ua.os.name ? <img className='px-1' height='12' src={`/logo/${ua.os.name.toLowerCase().replace(/\s/g, "")}.svg`} /> : ''}
        {/* {ua.device && ua.device.vendor ? <span className='px-1'>{ua.device.vendor}</span> : ''} */}
        {/* {ua.device && ua.device.model ? <span className='px-1'>{ua.device.model}</span> : ''} */}
        {/* {ua.device && ua.device.type ? <span>{ua.device.type}</span> : ''} */}
        {ua.browser && ua.browser.name ? <img className='px-1' height='12' src={`/logo/${ua.browser.name.toLowerCase().replace(/\s/g, "")}.svg`} /> : ''}
    </span>
}

const UserSessions = (props) => {
    const { _id } = props;
    const [state, dispatch] = useContext(Context);

    const interval = useRef(null);
    const { enqueueSnackbar } = useSnackbar();
    const [userSessions, setUserSessions] = useState({
        socketConnections: {},
        activeTokens: {},
        revokedTokens: {},
    });

    const [filter, setFilter] = useState({
        activeTokens: {
            userId: ''
        },
        revokedTokens: {
            userId: ''
        },
        socketConnections: {}
    })

    const getUserSessions = (filters) => {
        const { find, skip, limit, sort } = filters;
        axios.post(state.active.host + '/admin/users/usersessions',
            {
                // find, skip, limit, sort 
                socketConnections: true,
                activeTokens: true,
                revokedTokens: true
            },
            {
                headers: { 'x-auth-token': getBearer() }
            })
            .then(apiRes => {
                apiRes = apiRes.data;
                if (apiRes.success) {
                    setUserSessions(apiRes.result)
                } else {
                }
            })
    }

    const revoke = (params) => {
        axios.post(state.active.host + '/admin/users/revoke', params,
            {
                headers: { 'x-auth-token': getBearer() }
            })
            .then(apiRes => {
                apiRes = apiRes.data;
                if (apiRes.success) {
                } else {
                }
            })
    }

    useEffect(() => {
        getUserSessions(filter)
        let updateInterval = setInterval(() => {
            getUserSessions(filter)
        }, 3000);
        interval.current = updateInterval;
        return () => {
            clearInterval(interval.current)
        }
    }, [filter])

    const [showState, setShowState] = useState({
        revokedTokensHelp: false,
        map: false,
        mapSize: 'xl'
    })

    const [mapData, setMapDate] = useState([])

    const updateMapData = (userIds) => {
        let countryList = {};
        let result = [];
        for (const userId in userIds) {
            const sockets = userIds[userId];
            for (const socket of sockets) {
                const { country } = socket;
                countryList[country] = countryList[country] ? countryList[country] + 1 : 1;
            }
        }
        for (const country in countryList) {
            const value = countryList[country];
            result.push({
                country,
                value
            })
        }
        setMapDate(result)
        return result
    }

    useEffect(() => {
        if (Object.keys(userSessions.socketConnections).length) {
            updateMapData(userSessions.socketConnections)
        }
    }, [userSessions])

    return (
        <MDBRow className='mx-0 pt-2'>
            <Collapse in={showState.map} timeout="auto" unmountOnExit>
                <MDBCol size='12' className='text-center'>
                    <div className='position-relative border border-warning box-shadow-warning rounded-4 mb-4 overflow-hidden'>
                        <MDBIcon onClick={() => setShowState({ ...showState, mapSize: showState.mapSize == 'xl' ? 'xxl' : 'xl' })} className='rounded-circle box-shadow-warning p-2 position-absolute cursor-pointer' style={{ left: '1rem', top: '1rem' }} icon={showState.mapSize == 'xl' ? 'expand-arrows-alt' : 'compress-arrows-alt'}></MDBIcon>
                        {JSON.stringify(mapData)}
                        {userSessions && mapData ?
                            <WorldMap
                                strokeOpacity='0.3'
                                backgroundColor='#000'
                                borderColor='#26ddff'
                                color="#26ddff"
                                title=""
                                value-suffix="people"
                                size={showState.mapSize}
                                data={mapData}
                            /> : <></>
                        }
                    </div>
                </MDBCol>
            </Collapse>
            <MDBCol size='12' md='4'>
                <div className='border border-success box-shadow-success rounded-4 overflow-hidden'>
                    <div className='justify-content-between d-flex align-items-center px-2 pb-2 pt-1 box-shadow-success'>
                        <h6 className='px-2 text-shadow-success text-success my-0 pb-2 pt-2 d-inline-block'>Active Sockets ({Object.keys(userSessions.socketConnections).length})
                            <ThemeButton onClick={() => setShowState({ ...showState, map: !showState.map })} color='warning' variant='outlined' size='small' className='px-2 mx-2'>
                                <MDBIcon className='me-2 cursor-pointer' icon='map-marker-alt'></MDBIcon>
                                Live Map
                            </ThemeButton>
                        </h6>
                    </div>
                    <MDBRow className='m-0 py-2 small'>
                        <MDBCol size='2'>UserId</MDBCol>
                        <MDBCol size='4'>Connections</MDBCol>
                        <MDBCol size='4'>CreatedAt</MDBCol>
                    </MDBRow>
                    <div style={{ height: '75vh', overflow: 'scroll' }} className='noScrollbar'>
                        {Object.keys(userSessions.socketConnections).reverse().map((userId, i) => <ActiveSocketItem key={i} connections={userSessions.socketConnections[userId]} userId={userId}></ActiveSocketItem>)}
                    </div>
                </div>

            </MDBCol>
            <MDBCol size='12' md='4'>
                <div className='border border-neon box-shadow-neon rounded-4 overflow-hidden'>
                    <div className='justify-content-between d-flex align-items-center px-2 pb-2 pt-1 box-shadow-neon'>
                        <h6 className='px-2 text-shadow-neon text-neon py-0 my-0 d-inline-block'>ActiveTokens ({Object.keys(userSessions.activeTokens).length})</h6>
                        <div className='pt-1'>
                            <ThemeInput type='search' label='UserId' size='small' onChange={e => setFilter({ ...filter, activeTokens: { ...filter.activeTokens, userId: e.target.value } })} value={filter.activeTokens.userId}></ThemeInput>
                        </div>
                    </div>
                    <MDBRow className='m-0 py-2 small'>
                        <MDBCol size='2'>UserId</MDBCol>
                        <MDBCol size='4'>UserAgent</MDBCol>
                        <MDBCol size='4'>CreatedAt (iat)</MDBCol>
                    </MDBRow>
                    <div style={{ height: '77vh', overflow: 'scroll' }} className='noScrollbar'>
                        {Object.keys(userSessions.activeTokens).reverse().map((token, i) => {
                            const activeTokenObj = userSessions.activeTokens[token];
                            if (!filter.activeTokens.userId || filter.activeTokens.userId && (activeTokenObj.userId.indexOf(filter.activeTokens.userId) > -1))
                                return <ActiveTokenItem revoke={revoke} key={i} activeTokenObj={activeTokenObj} token={token}></ActiveTokenItem>
                        })}
                    </div>
                </div>
            </MDBCol>
            <MDBCol size='12' md='4'>
                <div className='border border-danger box-shadow-danger rounded-4 overflow-hidden'>
                    <div className='justify-content-between d-flex align-items-center px-2 pb-2 pt-1 box-shadow-danger'>
                        <h6 className='px-2 text-shadow-danger text-danger py-0 my-0 d-inline-block'>Revoked Tokens ({Object.keys(userSessions.revokedTokens).length})
                            <MDBIcon className='text-white mx-2 cursor-pointer' onClick={() => setShowState({ ...showState, revokedTokensHelp: !showState.revokedTokensHelp })} icon='question-circle'></MDBIcon>
                        </h6>
                        <div className='pt-1'>
                            <ThemeInput type='search' labelClass='text-danger' label='UserId' size='small' onChange={e => setFilter({ ...filter, revokedTokens: { ...filter.revokedTokens, userId: e.target.value } })} value={filter.revokedTokens.userId}></ThemeInput>
                        </div>
                    </div>
                    <MDBRow className='m-0 py-2 small'>
                        <MDBCol size='2'>UserId</MDBCol>
                        <MDBCol size='7' className='text-center'>Start - End</MDBCol>
                        <MDBCol size='2'>Duration</MDBCol>
                    </MDBRow>
                    <div style={{ height: '80vh', overflow: 'scroll' }} className='noScrollbar'>
                        <Collapse in={showState.revokedTokensHelp} timeout="auto" unmountOnExit>
                            <MDBRow className='m-0 text-start align-items-center'>
                                <MDBCol size='2'>UserId</MDBCol>
                                <MDBCol size='4'>Create</MDBCol>
                                <MDBCol size='3'>Revoke</MDBCol>
                                <MDBCol size='3' className='small p-0'>
                                    <div className='text-success'>Less than 10 Minute</div>
                                    <div className='text-yellow'>Less than 1 Hour</div>
                                    <div className='text-warning'>Less than 12 Hours</div>
                                    <div className='text-info'>Less than 24 Hours</div>
                                    <div className='text-danger'>More than 24 Hours</div>
                                </MDBCol>
                            </MDBRow>
                        </Collapse>
                        {Object.keys(userSessions.revokedTokens).reverse().map((token, i) => {
                            const revokedTokenObj = userSessions.revokedTokens[token];
                            if (!filter.revokedTokens.userId || filter.revokedTokens.userId && (revokedTokenObj.userId && (revokedTokenObj.userId.indexOf(filter.revokedTokens.userId) > -1)))
                                return <RevokedTokenItem key={i} revokedTokenObj={revokedTokenObj} token={token}></RevokedTokenItem>
                        })}
                    </div>
                </div>
            </MDBCol>

        </MDBRow>
    )
}

const ActiveSocketItem = (props) => {
    const { userId, connections } = props;
    const [showMore, setShowMore] = useState(false);
    return <MDBRow className='mx-0 border-top border-success py-1 small'>
        <MDBCol size='6'>
            <UserQuickViewButton userId={userId} />
        </MDBCol>
        <MDBCol size='5'>{connections.length}</MDBCol>
        <MDBCol size='1'>
            <MDBIcon className='cursor-pointer text-success' onClick={() => setShowMore(!showMore)} icon={showMore ? 'chevron-up' : 'chevron-down'}></MDBIcon>
        </MDBCol>
        <Collapse in={showMore} timeout="auto" unmountOnExit>
            <MDBCol size='12'>
                {connections.map((connection, i) => {
                    const { socketId, token, createdAt, country, ip, userAgent } = connection;
                    return <MDBRow className='py-2 small align-items-center' style={{ borderTop: '1px solid #333' }} key={i}>
                        <MDBCol size='4' className='small text-neon'>
                            <div className='small'>socketId</div>
                            {socketId}
                        </MDBCol>
                        <MDBCol size='4'>
                            <div className='small'>userAgent</div>
                            <small className='text-warning d-flex justify-content-between'>
                                <div>
                                    {country ?
                                        <img
                                            src={`https://flagcdn.com/48x36/${country.toLowerCase()}.png`}
                                            height='12'
                                            className='me-2'
                                            alt={country}></img>
                                        : <></>}
                                    {formatUA(userAgent)}
                                    <small>{ip}</small>
                                </div>
                            </small>
                        </MDBCol>
                        <MDBCol size='4' className='text-end'>
                            <div className='small'>createdAt</div>
                            <small className='text-warning'>
                                {formatDateTime(createdAt)}
                            </small>
                        </MDBCol>
                        <MDBCol size='12'>
                            <TokenViewer token={token} />
                        </MDBCol>
                    </MDBRow>
                })}
            </MDBCol>
        </Collapse>
    </MDBRow>
}

const ActiveTokenItem = (props) => {
    const { token, activeTokenObj, revoke } = props;
    const { createdAt, userId, country, ip, userAgent } = activeTokenObj;
    const [showMore, setShowMore] = useState(false);

    return <MDBRow className='mx-0 border-top border-neon py-1 overflow-hidden align-items-center small'>
        <MDBCol size='2' className='d-flex  align-items-center'>
            <UserQuickViewButton userId={userId}></UserQuickViewButton>
        </MDBCol>
        <MDBCol size='4' className='small d-flex  align-items-center'>
            <div>
                {country ?
                    <img
                        src={`https://flagcdn.com/48x36/${country.toLowerCase()}.png`}
                        height='12'
                        className='me-2'
                        alt={country}></img>
                    : <></>}
                {formatUA(userAgent)}
                <small>{ip}</small>
            </div>
        </MDBCol>
        <MDBCol size='4' className='small p-0'>
            {formatDateTime(createdAt)}
        </MDBCol>
        <MDBCol size='2' className='ps-0 text-end'>
            <Tooltip
                TransitionComponent={Zoom}
                placement="right"
                classes={{ tooltip: 'bg-black text-neon border border-neon box-shadow-neon px-3 py-1 m-0' }}
                title={<Box>
                    Revoke User (All Tokens)
                </Box>}>
                <span>
                    <MDBIcon className='cursor-pointer text-danger me-2' onClick={() => revoke({ userId: userId })} icon='times-circle'></MDBIcon>
                </span>
            </Tooltip>
            <Tooltip
                TransitionComponent={Zoom}
                placement="right"
                classes={{ tooltip: 'bg-black text-neon border border-neon box-shadow-neon px-3 py-1 m-0' }}
                title={<Box>
                    Revoke Token
                </Box>}>
                <span>
                    <MDBIcon className='cursor-pointer text-danger me-2' onClick={() => revoke({ token: token })} icon='times'></MDBIcon>
                </span>
            </Tooltip>
            <MDBIcon className='cursor-pointer text-neon px-1' onClick={() => setShowMore(!showMore)} icon={showMore ? 'chevron-up' : 'chevron-down'}></MDBIcon>
        </MDBCol>
        <Collapse in={showMore} timeout="auto" unmountOnExit>
            <MDBCol size='12'>
                <TokenViewer token={token} />
            </MDBCol>
        </Collapse>
    </MDBRow>
}

const RevokedTokenItem = (props) => {
    const { token, revokedTokenObj } = props;
    const { revokedAt, userId, country, ip, userAgent, generateInfo } = revokedTokenObj;
    const [showMore, setShowMore] = useState(false);

    var a = moment(revokedAt)
    var b = moment(generateInfo ? generateInfo.createdAt : Date.now())

    return <MDBRow className='mx-0 border-top border-danger py-1 overflow-hidden small'>
        <MDBCol size='2' className='d-flex  align-items-center'>
            <UserQuickViewButton userId={userId} />
        </MDBCol>
        <MDBCol size='7' className='small p-0'>
            <MDBRow>
                <MDBCol size='6'>
                    <div>

                        {country ?
                            <img
                                src={`https://flagcdn.com/48x36/${country.toLowerCase()}.png`}
                                height='12'
                                className='me-2'
                                alt={country}></img>
                            : <></>}
                        {formatUA(userAgent)}
                        <small>{ip}</small>

                    </div>
                    {generateInfo ? formatDateTime(generateInfo.createdAt) : <></>}
                </MDBCol>
                <MDBCol size='6'>
                    <div>
                        {country ?
                            <img
                                src={`https://flagcdn.com/48x36/${country.toLowerCase()}.png`}
                                height='12'
                                className='me-2'
                                alt={country}></img>
                            : <></>}
                        {formatUA(userAgent)}
                        <small>{ip}</small>
                    </div>
                    {formatDateTime(revokedAt)}
                </MDBCol>
            </MDBRow>
        </MDBCol>
        <MDBCol size='2' className='d-flex align-items-center text-center'>
            {generateInfo ?
                <small className={`text-${a.diff(b) < 1000 * 60 * 10 ? 'success' :// less than 10 min
                    a.diff(b) < 1000 * 60 * 60 ? 'yellow' :// less than 1hour
                        a.diff(b) < 1000 * 60 * 60 * 12 ? 'warning' :// less than 12hours
                            a.diff(b) < 1000 * 60 * 60 * 24 ? 'info' :// less than 1 day
                                'danger'
                    }`}>
                    {a.diff(b, 'days') ?
                        <div>
                            {a.diff(b, 'days')}Day {`&`}
                        </div> : <></>
                    }

                    {numeral(a.diff(b, 'hours') % 24).format('00')}
                    :
                    {numeral(a.diff(b, 'minutes') % 60).format('00')}
                    :
                    {numeral(a.diff(b, 'seconds') % 60).format('00')}
                </small>
                : <></>}
        </MDBCol>
        <MDBCol size='1' className='d-flex align-items-center'>
            <MDBIcon className='cursor-pointer text-danger' onClick={() => setShowMore(!showMore)} icon={showMore ? 'chevron-up' : 'chevron-down'}></MDBIcon>
        </MDBCol>
        <Collapse in={showMore} timeout="auto" unmountOnExit>
            <MDBCol size='12'>
                <TokenViewer token={token} />
            </MDBCol>
        </Collapse>
    </MDBRow>
}

const TokenViewer = (props) => {
    const { token } = props;
    var decodedToken = jwt_decode(token);
    const { expire, iat, ...other } = decodedToken;
    const [open, setOpen] = useState(false)
    return <Box sx={{ fontSize: '0.6rem', padding: '5px', bgcolor: '#333' }}>
        <small style={{ wordBreak: 'break-all' }}>
            {token}
        </small>
        <Box className=''>
            <Box className='bg-black d-flex justify-content-between p-1 mb-1'>
                <Box>iat: <ThemeFormatTime timestamp={decodedToken['iat'] * 1000} showFromNow ms /></Box>
                <Box>expire: <ThemeFormatTime timestamp={decodedToken['expire']} showFromNow ms /></Box>
            </Box>
            {Object.keys(other).map((key, i) => {
                return <Box className='p-1 d-flex justify-content-between'>
                    <span>{key} : {decodedToken[key]}</span>
                </Box>
            })}
        </Box>
        <Box className='text-end'>
            <ThemeButton onClick={() => setOpen(!open)} className='px-3 py-1 small' size='small' variant='outlined' color='neon'>
                Show JSON
            </ThemeButton>
        </Box>
        <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
                <ReactJson name={null} style={{ padding: '0.5rem' }} theme="monokai" src={decodedToken} />
            </Box>
        </Collapse>
    </Box>
}

export default UserSessions
