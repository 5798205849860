import { Box, LinearProgress, Modal } from '@mui/material';
import axios from 'axios';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react'
import { getBearer } from '../../../helpers/publicHelper';

const CrashDetails = (props) => {
    const { meta } = props;

    const { enqueueSnackbar } = useSnackbar();
    const [values, setValues] = useState({
        details: {
            crash: {
                point: 0
            },
            bets: []
        }, open: false
    })
    if (!meta.bet || !meta.bet.details) { return 'Bet details not found.' }
    const { details } = meta.bet;
    if (details.length < 1) { return 'Bet details length == 0' }
    const { mode, odds, oddsRange, index, crash } = details[0];
    if (!crash) { return 'Crash obj not found.' }
    const { point } = crash;
    const status = meta.bet.betState;
    const logPoint = 100 * Math.log(point) / Math.log(100) + 0.1;
    const logOdds = 100 * Math.log(odds) / Math.log(100) + 0.1;

    const getCrashTransaction = () => {
        axios.post(process.env.REACT_APP_API_HOST + '/admin/games/crash/report',
            {
                find: { 'crash.md5': details[0].crash.md5 },
                skip: 0,
                limit: 5,
                sort: { createdAt: -1 }
            },
            {
                headers: { 'x-auth-token': getBearer() }
            })
            .then(res => {
                res = res.data;
                if (res.success) {
                    setValues({
                        open: true,
                        details: res.result.rounds[0]
                    });
                } else {
                }
            })
    }


    return <Box sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%'
    }}>
        <div className='px-1'>
            <small className='d-block opacity-60'>{mode}</small>
            <small className='d-block opacity-20'>{index}</small>
        </div>
        <Box sx={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%', m: 0, p: 0, position: 'relative', height: '1.5rem'
        }}>
            <div className='w-100'>
                <LinearProgress color={status == 'won' ? 'success' : 'danger'} variant="determinate" value={logPoint <= 1 ? 100 : logPoint} />
            </div>
            <span
                className={`position-absolute small`}
                style={{
                    top: '-4px',
                    left: point == 0 ? 0 : logPoint + '%'
                }}>🚀 <small>{point || 'Hidden'}</small></span>
            <small
                className={`position-absolute small`}
                style={{
                    bottom: '-4px',
                    left: `calc(${logOdds}% - 0.5rem)`
                }}>☝️ <small>{odds}</small></small>

        </Box>
        <div
            className={`text-center text-${point == 1 ? 'warning' : point == 0 ? 'neon' : point >= 1.8 ? 'success' : 'danger'}`}
            style={{ width: '1.8rem' }}>
            <MDBIcon className='mb-2' onClick={() => { getCrashTransaction(); setValues({ open: true, }); }} icon='circle'></MDBIcon>
        </div>
        <Modal
            open={values.open}
            disableScrollLock={true}
            onClose={() => setValues({ ...values, open: false })}>
            <Box className='box-shadow-neon bg-black rounded-6 overflow-scroll noScrollbar' sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                height: '30vh',
                width: ['90vw', '30vw']
            }}>
                <MDBRow className='m-0 p-3'>
                    <MDBCol size='12'>
                        Crash Point:  {(values.details && values.details.crash.point) ? values.details.crash.point : <MDBIcon spin color='neon' icon='spinner'></MDBIcon>}
                    </MDBCol>
                    <MDBCol size='12'>
                        Bets:  {values.details && values.details.bets && values.details.bets.length}
                    </MDBCol>
                </MDBRow>
            </Box>
        </Modal>
    </Box>
}

export default CrashDetails
