import React, { useEffect, useState, useRef, useContext, useLayoutEffect } from 'react';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import moment from 'moment';
import axios from 'axios';
import { Alert, Modal, Pagination, Typography } from '@mui/material';
import { currencyDetail, formatAmountCurrency } from '../../helpers/currency';
import ThemeButton from '../../layout/components/ThemeButton';
import ResponsiveTable from '../Tables/ResponsiveTable';
import { getBlockchainUrl } from '../../helpers/adminHelpers';
import { getBearer } from '../../helpers/publicHelper';
import _ from 'lodash';
import numeral from 'numeral';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSnackbar } from 'notistack';
import ReactJson from 'react-json-view';
import WithdrawRequestsFilter from './WithdrawRequestsFilter.js';
import UserQuickViewButton from '../Users/UserQuickViewButton';
import ThemeFormatTime from '../../layout/components/ThemeFormatTime';
import ThemeFormatCurrency from '../../layout/components/ThemeFormatCurrency';
import { Box } from '@mui/system';
import ThemeInput from '../../layout/components/ThemeInput';
import { BreadCrumbContext } from '../../context/store';
import { useParams } from 'react-router-dom';

const formatWalletAddress = (all) => {
    const { userWalletAddress, destinationWalletAddress, currency, blockchain, treasuryWalletAddress } = all;
    return <div className='d-flex justify-content-between align-items-center'>
        <ThemeFormatCurrency iconClassName='me-1' mode='address' text={treasuryWalletAddress} blockchain={blockchain} currency={currency}></ThemeFormatCurrency>
        <MDBIcon icon='arrow-right' className='px-3'></MDBIcon>
        <ThemeFormatCurrency iconClassName='me-1' mode='address' text={destinationWalletAddress} blockchain={blockchain} currency={currency}></ThemeFormatCurrency>
    </div>
}


const formatTransferResult = (all) => {
    // return JSON.stringify(all.transferResult)
    const { transferResult, _id } = all;

    return <MDBRow className='small'>
        <MDBCol className='small' size='12'>

            <div>
                <h6 className='text-info d-inline-block me-3'>Transfer Result
                </h6>
                <CopyToClipboard text={JSON.stringify(transferResult) || '{}'}>
                    <ThemeButton size='small' variant='outlined' className='rounded-pill btn-sm' color='info'>Copy Transfer Result Clipboard</ThemeButton>
                </CopyToClipboard>
                <CopyToClipboard text={_id}>
                    <ThemeButton size='small' variant='outlined' className='rounded-pill btn-sm mx-3' color='warning'>Copy _id to Clipboard</ThemeButton>
                </CopyToClipboard>
            </div>
            {all.comment && <Alert variant='outlined' color='info' className='bg-black mb-2'>
                {all.comment}
            </Alert>
            }
            <div className='border border-info'>
                <h6 className='text-info d-inline-block p-2'>Transfer Result</h6>
                <ReactJson name={null} style={{ padding: '0.5rem' }} theme="monokai" src={transferResult} />
            </div>
        </MDBCol>
    </MDBRow>

}

const WithdrawRequests = (props) => {
    const [contextBC, setContextBC] = useContext(BreadCrumbContext);
    useLayoutEffect(() => {
        setContextBC([
            {
                href: '/',
                text: 'Home'
            },
            {
                href: '/Financial',
                text: 'Financial'
            },
            {
                text: 'Withdraw Requests'
            }
        ])
    }, [])
    const { enqueueSnackbar } = useSnackbar();
    const [rows, setRows] = useState([])
    const interval = useRef(null);
    const [filter, setFilter] = useState({
        // currency: '',
        find: {},
        limit: 20,
        skip: 0,
        sort: { updatedAt: -1 }
    })

    const [values, setValues] = useState({
        open: false,
        comment: '',
        code: '',
        status: '',
        all: {}
    })

    const formatStatus = (all) => {
        const { status, _id } = all;
        const { reviewers } = all;
        return <div className='small'>
            {status == 'confirmed' && <span onClick={() => setValues({ ...values, all, code: all.code, open: true, state: 'rejected' })} className='text-success text-shadow-success'>
                <div>
                    <span className='text-success text-shadow-success'>
                        <MDBIcon className='me-1' icon='check-circle'></MDBIcon>Confirmed
                    </span>
                </div>
            </span>}
            {status == 'rejected' && <span onClick={() => setValues({ ...values, all, code: all.code, open: true, state: 'confirmed' })}>
                <div>
                    <span className='text-danger text-shadow-danger'>
                        <MDBIcon className='me-1' icon='times-circle'></MDBIcon>Rejected
                    </span>
                </div>

            </span>}
            <small className='text-white opacity-50'>
                {reviewers && (reviewers.length > 0) ? <span>
                    By {reviewers[0].username} ({reviewers.length})
                </span> : <span></span>
                }</small>
            {(status == 'pending') && <ThemeButton onClick={() => setValues({ ...values, all, code: all.code, open: true, state: 'rejected' })} variant='outlined' size='small' className='px-2' color='warning'> <MDBIcon className='me-1' spin icon='spinner'></MDBIcon> Pending (demo)</ThemeButton>}
        </div>
    }
    const formatConfirmTime = (all) => {
        const { updatedAt } = all;
        if (all.status == 'pending') return <span></span>
        return <div className='small'>
            <small className={`text-${all.status == 'confirmed' ? 'success' : 'danger'}`}>
                {moment(updatedAt).format('YYYY-MM-DD HH:mm:ss')}
                <div className='text-gray' title={updatedAt}>
                    {moment(updatedAt).fromNow()}
                </div>
            </small>
        </div>
    }

    const columns = [{
        id: '_id',
        label: '',
        style: { width: '0', padding: 0 },
        labelStyle: { width: '0', padding: 0 },
        format: () => { }
    }, {
        id: 'createdAt', // From Logger
        label: 'Created At',
        align: 'left',
        labelAlign: 'left',
        style: { width: '120px', padding: 0, fontSize: '0.7em' },
        labelStyle: { width: '120px', padding: 0, fontSize: '0.7em' },
        format: (createdAt) => <ThemeFormatTime timestamp={createdAt} showFromNow></ThemeFormatTime>
    }, {
        id: 'updatedAt', // From Logger
        label: 'Updated At',
        align: 'left',
        labelAlign: 'left',
        style: { width: '120px', padding: 0, fontSize: '0.7em' },
        labelStyle: { width: '120px', padding: 0, fontSize: '0.7em' },
        format: (updatedAt) => <ThemeFormatTime timestamp={updatedAt} showFromNow></ThemeFormatTime>
    }, {
        id: 'userId',
        label: 'User Id',
        align: 'center',
        style: { width: '70px' },
        labelStyle: { width: '70px' },
        format: (userId) => <UserQuickViewButton userId={userId}></UserQuickViewButton>
    }, {
        id: 'all',
        label: '',
        align: 'center',
        style: { width: '30px' },
        labelStyle: { width: '30px' },
        format: (all) => <ThemeFormatCurrency iconSize={1} icon='png' hideText currency={all.currency} />//<img className={`mx-1`} style={{ width: '1rem', height: '1rem' }} src={currencyIcon(all.currency)} />
    }, {
        id: 'all',
        label: 'Amount',
        align: 'right',
        style: { width: '100px' },
        labelStyle: { width: '100px' },
        format: (all) => <span className='pe-1'>{numeral(formatAmountCurrency(all.amount, all.currency)).format(currencyDetail[all.currency].format)}</span>
    }, {
        id: 'all',
        label: 'Fee',
        align: 'right',
        style: { width: '100px' },
        labelStyle: { width: '100px' },
        format: (all) => <span className='pe-1'>{numeral(formatAmountCurrency(all.fee, all.currency)).format(currencyDetail[all.currency].format)} <small className='opacity-50'>{all.currency}</small></span>
    }, {
        id: 'all',
        label: <span>Treasury Wallet Address <MDBIcon className='px-3' icon='arrow-right'></MDBIcon> Destination Address</span>,
        labelAlign: 'center',
        align: 'left',
        style: { width: '650px' },
        labelStyle: { width: '650px' },
        format: (all) => formatWalletAddress(all)
    }, {
        id: 'all',
        label: 'Approval Status',
        align: 'left',
        labelAlign: 'left',
        style: { width: '100px' },
        labelStyle: { width: '100px' },
        format: (all) => formatStatus(all)
    }, {
        id: 'all',
        label: 'Approval Time',
        align: 'left',
        labelAlign: 'left',
        style: { width: '100px' },
        labelStyle: { width: '100px' },
        format: (all) => formatConfirmTime(all)
    }, {
        id: 'all',
        label: 'Transfer Status',
        align: 'left',
        labelAlign: 'left',
        style: { width: '100px' },
        labelStyle: { width: '100px' },
        format: (all) => formatTransferStatus(all)
    }, {
        id: 'all',
        label: <Typography variant='subtitle2'>Blockchain Update</Typography>,
        align: 'center',
        style: { width: '100px' },
        labelStyle: { width: '100px' },
        format: (all) => <ThemeButton
            onClick={() => updateWalletFromBlockchain(all)}
            size='small' variant='outlined' className='p-0 cursor-pointer' color='info'><MDBIcon icon='sync'></MDBIcon></ThemeButton>
    }, {
        id: 'all',
        label: 'Transfer Result',
        collapse: true,
        align: 'left',
        style: {
            overflow: 'hidden',
            padding: '0 100px !important',
            whiteSpace: 'break-spaces !important'
        },
        format: (all) => formatTransferResult(all)
    }];

    const getTransactions = (setRows, filters) => {
        axios.post(process.env.REACT_APP_API_HOST + '/accountant/users/getWithdrawRequests',
            filters,
            {
                headers: { 'x-auth-token': getBearer() }
            })
            .then(apiRes => {
                apiRes = apiRes.data;
                if (apiRes.success) {
                    setRows({
                        count: apiRes.result.count,
                        transactions: apiRes.result.withdrawRequest.map(row => ({ ...row, all: row }))
                    });
                } else {
                    setRows({
                        count: 0,
                        transactions: []
                    });
                }
            })
            .catch(err => {
                console.log(err)
            })
            .then(() => {
            })
    }


    const formatTransferStatus = (all) => {
        const { transferStatus, status, currency, blockchain } = all;
        if (status == 'rejected') return <small className='text-danger text-shadow-danger'>
            <MDBIcon className='me-1' icon='times-circle'></MDBIcon>Not Approved
        </small>

        return <span>
            {transferStatus == 'confirmed' ?
                <ThemeFormatCurrency
                    buttonColor='success'
                    icon={false} {...all} mode='transaction' text={<><MDBIcon className='me-1' icon='check-circle'></MDBIcon>Confirmed</>} value={all.transferResult.transaction.transactionId} />
                :
                transferStatus == 'rejected' ?
                    <small className='text-danger text-shadow-danger'>
                        <MDBIcon className='me-1' icon='times-circle'></MDBIcon>Rejected
                    </small>
                    :
                    <small className='text-warning text-shadow-warning'>
                        {transferStatus ?
                            <>{
                                transferStatus && all.transferResult && all.transferResult.transaction ?
                                    <ThemeFormatCurrency
                                        buttonColor='warning'
                                        icon={false} {...all} mode='transaction' text={<><MDBIcon className='me-1' icon='exclamation'></MDBIcon>{transferStatus}</>} value={all.transferResult.transaction.transactionId} />
                                    :
                                    <span>{transferStatus}</span>
                            }</>
                            :
                            <span onClick={() => updateWalletFromBlockchain(all)} className='cursor-pointer small px-1 rounded-pill border border-neon box-shadow-neon text-neon py-1'>
                                <MDBIcon className='me-1' icon='sync'></MDBIcon> Update to See
                            </span>
                        }
                    </small>
            }
        </span>
    }

    const updateWalletFromBlockchain = (all) => {
        axios.post(process.env.REACT_APP_API_HOST + '/accountant/wallets/transferResult',
            {
                code: all.code,
            },
            {
                headers: { 'x-auth-token': getBearer() }
            })
            .then(apiRes => {
                apiRes = apiRes.data;
                if (apiRes.success) {
                    enqueueSnackbar(apiRes.message, { variant: 'success', autoHideDuration: 2500 })
                } else {
                    enqueueSnackbar(apiRes.message, { variant: 'warning', autoHideDuration: 2500 })
                }
            })
            .catch(err => {
                enqueueSnackbar('Api error.', { variant: 'danger', autoHideDuration: 2500 })
            })

    }

    const handlePagination = (e, page) => {
        setFilter({
            ...filter,
            skip: (page - 1) * filter.limit
        });
    }

    const callApproveWithdrawRequest = (params) => {
        params = _.pick(params, 'comment', 'code', 'status');
        console.log('params', params)
        axios.post(process.env.REACT_APP_API_HOST + '/accountant/users/approveWithdrawRequests',
            params,
            {
                headers: { 'x-auth-token': getBearer() }
            })
            .then(apiRes => {
                apiRes = apiRes.data;
                if (apiRes.success) {
                    enqueueSnackbar(apiRes.message, { variant: 'success', autoHideDuration: 2500 })
                } else {
                    enqueueSnackbar(apiRes.message, { variant: 'warning', autoHideDuration: 2500 })
                }
            })
            .catch(err => {
                enqueueSnackbar('Error!', { variant: 'error', autoHideDuration: 2500 })
            })

    }

    useEffect(() => {
        let updateInterval = setInterval(() => {
            getTransactions(setRows, filter);
        }, 5000);
        interval.current = updateInterval;
        return () => {
            clearInterval(interval.current)
        }
    }, [filter])

    const [tableColumns, setTableColumns] = useState(columns);

    return (
        <>
            <WithdrawRequestsFilter query={useParams()} setRows={setRows} getTransactions={getTransactions} count={rows.count} setFilter={setFilter} filter={filter}></WithdrawRequestsFilter>
            <ResponsiveTable collapsible rows={rows.transactions} columns={tableColumns}></ResponsiveTable>

            {Math.floor(rows.count / filter.limit) > 0 &&
                <MDBRow className='m-0'>
                    <MDBCol size='12' className='text-center py-4'>
                        <Pagination onChange={handlePagination} className='d-inline-block' count={Math.ceil(rows.count / filter.limit)} variant="outlined" shape="rounded" />
                    </MDBCol>
                </MDBRow>}

            <Modal
                open={values.open}
                disableScrollLock={true}
                onClose={() => setValues({ ...values, open: false })}>
                <Box>
                    <Box className='box-shadow-neon bg-black rounded-6 overflow-scroll noScrollbar' sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '95vw',
                        height: '95vh'
                    }}>
                        <MDBRow className='m-0 bg-black py-2'>
                            <MDBCol size='12'>
                                <Typography className='text-neon' variant='body2'>
                                    <strong className='text-neon'>User Id: {values.all.userId}</strong>
                                    <MDBIcon onClick={() => setValues({ ...values, open: false })} className='float-end pt-1 cursor-pointer' icon='times'></MDBIcon>
                                </Typography>
                                <hr className='my-2' />
                                <Typography variant='subtitle2'>
                                    Request Code: {values.all.code}
                                    <CopyToClipboard
                                        onCopy={() => { enqueueSnackbar('Copied.', { variant: 'warning', autoHideDuration: 2500 }) }}
                                        text={values.all.code}>
                                        <MDBIcon className='text-warning ps-1 cursor-pointer' icon='copy'></MDBIcon>
                                    </CopyToClipboard>
                                </Typography>
                                <hr className='my-2' />
                            </MDBCol>
                            <MDBCol size='12' className='mb-3'>
                                <div className='d-flex justify-content-between small pt-3 opacity-60'>
                                    <div className='text-center mb-4'>
                                        <Typography variant='subtitle2'>
                                            <div className='opacity-70 small'>
                                                Requested At
                                            </div>
                                            <ThemeFormatTime timestamp={values.all.createdAt} showFromNow></ThemeFormatTime>
                                        </Typography>
                                    </div>
                                    <div className='text-center mb-4'>
                                        <Typography variant='subtitle2'>
                                            <div className='opacity-70 small'>
                                                Updated At
                                            </div>
                                            <ThemeFormatTime timestamp={values.all.updatedAt} showFromNow></ThemeFormatTime>
                                        </Typography>
                                    </div>
                                </div>
                                <div className='text-center'>
                                    <Typography variant='h6'>
                                        User <UserQuickViewButton userId={values.all.userId} /> wants to withdraw
                                    </Typography>
                                </div>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <div className='text-center my-4'>
                                        <Typography variant='subtitle2' className='opacity-40'>
                                            Requested Withdraw Amount
                                        </Typography>
                                        <Typography variant='h5' className='text-danger'>
                                            <ThemeFormatCurrency iconSize={1.5} text={values.all.amount} currency={values.all.currency} unit />
                                        </Typography>
                                        <Typography variant='body1' className='opacity-40'>
                                            <ThemeFormatCurrency icon={false} inUSD text={values.all.amount} currency={values.all.currency} unit />
                                        </Typography>
                                    </div>
                                </div>
                                <div className='text-center my-2 mb-4'>
                                    <Typography variant='subtitle2' className='opacity-40'>
                                        Withdraw Fee for User
                                    </Typography>
                                    <Typography variant='h6' className='text-success'>
                                        <ThemeFormatCurrency iconSize={1} text={values.all.fee} currency={values.all.currency} unit />
                                    </Typography>
                                    <div className='opacity-40 small'>
                                        <ThemeFormatCurrency icon={false} inUSD text={values.all.fee} currency={values.all.currency} unit />
                                    </div>
                                </div>



                                <MDBRow className='m-0 text-center justify-content-center align-items-end'>
                                    <MDBCol size='12' md='5' className='p-0 text-md-end'>
                                        {/* <small className='opacity-40 d-block'>User Wallet</small> */}
                                        <ThemeFormatCurrency mode='address' text={values.all.treasuryWalletAddress} blockchain={values.all.blockchain} currency={values.all.currency}></ThemeFormatCurrency>
                                        <CopyToClipboard
                                            onCopy={() => { enqueueSnackbar('Copied.', { variant: 'warning', autoHideDuration: 2500 }) }}
                                            text={values.all.treasuryWalletAddress}>
                                            <small>
                                                <MDBIcon className='small text-warning ps-1 cursor-pointer' icon='copy'></MDBIcon>
                                            </small>
                                        </CopyToClipboard>
                                    </MDBCol>
                                    <MDBCol size='12' md='1' className='p-0 '>
                                        <MDBIcon className='d-none d-md-block px-3 py-2' icon='arrow-right'></MDBIcon>
                                        <MDBIcon className='d-md-none px-3 py-2' icon='arrow-down'></MDBIcon>
                                    </MDBCol>
                                    <MDBCol size='12' md='5' className='p-0 text-md-start'>
                                        <ThemeFormatCurrency mode='address' text={values.all.destinationWalletAddress} blockchain={values.all.blockchain} currency={values.all.currency}></ThemeFormatCurrency>
                                        <CopyToClipboard
                                            onCopy={() => { enqueueSnackbar('Copied.', { variant: 'warning', autoHideDuration: 2500 }) }}
                                            text={values.all.destinationWalletAddress}>
                                            <small>
                                                <MDBIcon className='small text-warning ps-1 cursor-pointer' icon='copy'></MDBIcon>
                                            </small>
                                        </CopyToClipboard>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>

                            <MDBCol size='12' className='mb-3'>
                                <Typography variant='body2'>
                                    <ThemeInput
                                        label='Comment'
                                        placeholder='Type your commente here ...'
                                        multiline
                                        rows={4}
                                        type='text'
                                        className='mt-2'
                                        value={values.comment}
                                        onChange={e => setValues({ ...values, comment: e.currentTarget.value })}
                                    ></ThemeInput>
                                </Typography>
                            </MDBCol>
                            <MDBCol size='12' className='mb-3 text-center'>
                                <ThemeButton onClick={() => callApproveWithdrawRequest({ ...values, status: 'confirmed' })} variant='outlined' className='mx-2 px-3' color='success'><MDBIcon className='me-1' icon='check'></MDBIcon> Confirm</ThemeButton>
                                <ThemeButton onClick={() => callApproveWithdrawRequest({ ...values, status: 'rejected' })} variant='outlined' className='px-3' color='danger'><MDBIcon className='me-1' icon='times'></MDBIcon> Reject</ThemeButton>
                            </MDBCol>
                            {/* <MDBCol size='12'>
                                {values.open && <UserReport userId={values.all.userId}></UserReport>}
                            </MDBCol> */}
                        </MDBRow>
                    </Box>
                </Box>
            </Modal>
        </>)
}

export default WithdrawRequests