import { Grid, Modal, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import numeral from 'numeral';
import React, { Fragment, useState } from 'react'
import { gameProviders, getColorByTitle, getCashbackPercentByLostTier } from '../../../helpers/reportHelper';
import { MDBIcon } from 'mdb-react-ui-kit';
import ThemeButton from '../../../layout/components/ThemeButton';
import RenderService from '../../Transaction/Renders/RenderService';
import ReactJson from 'react-json-view';

const PlayerReportTable = (props) => {
    const { data, currentBalance } = props;
    const report = data;
    const [showJson, setShowJson] = useState(false)
    return <Box sx={{
        textAlign: 'center',
        '&  .MuiGrid-item': {
            border: '1px solid #666',
        },
    }}>
        <Grid container columns={20} sx={{
            '& .MuiGrid-item.gridLabel': {
                alignItems: 'center',
                justifyContent: 'center',
                display: ['none', 'flex'],
                fontSize: '0.6rem'
            }
        }}>
            <Grid item md={2} className='gridLabel p-1 fw-bold text-success'>Deposit</Grid>
            <Grid item md={2} className='gridLabel p-1 fw-bold text-teal'>Deposit Bonus</Grid>
            <Grid item md={2} className='gridLabel p-1 fw-bold text-danger'>Withdraw</Grid>
            <Grid item md={2} className='gridLabel p-1 fw-bold text-orange'>Withdraw Rollback</Grid>
            <Grid item md={2} className='gridLabel p-1 fw-bold text-yellow'>Withdraw Pending</Grid>
            {/* <Grid item md={2} className='gridLabel p-1 fw-bold text-yellow'>Current Balance</Grid> */}
            {/* <Grid item md={2} className='gridLabel p-1 fw-bold text-neon'>Topup Total</Grid> */}
            <Grid item md={2} className='gridLabel p-1 fw-bold text-neon'>Topup DailyCashback</Grid>
            <Grid item md={2} className='gridLabel p-1 fw-bold text-neon'>Topup WeeklyCashback</Grid>
            <Grid item md={2} className='gridLabel p-1 fw-bold text-success'>Topup Deposit</Grid>
            <Grid item md={2} className='gridLabel p-1 fw-bold text-danger'>Topup Withdraw</Grid>
            <Grid item md={2} className='gridLabel p-1 fw-bold text-teal'>Topup Bonus</Grid>
            <FinancialReport financial={report.financial} currentBalance={currentBalance} />
        </Grid>

        <PlayReports play={report.play} financial={report.financial} />
        <Box className='text-start p-3'>
            <ThemeButton onClick={() => setShowJson(!showJson)} variant='outlined' className='px-3 py-1' size='small'>Show JSON</ThemeButton>
            {showJson && <ReactJson collapsed={2} name={null} style={{ padding: '0.5rem' }} theme="monokai" src={report} />}
        </Box>
    </Box>
}

const FinancialReport = (props) => {
    const { financial, currentBalance } = props;


    return <Fragment>
        <StyledGrid xs={20} md={2} title='Deposit' amount={financial.deposit.deposit.total.totalAmountInUSD} count={financial.deposit.deposit.total.count} />
        <StyledGrid xs={20} md={2} title='Deposit Bonus' amount={financial.deposit.bonus.total.totalAmountInUSD} count={financial.deposit.bonus.total.count} />
        <StyledGrid xs={20} md={2} title='Withdraw' amount={financial.withdraw.withdraw.total.totalAmountInUSD} count={financial.withdraw.withdraw.total.count} />
        <StyledGrid xs={20} md={2} title='Withdraw Rollback' amount={financial.withdraw?.rollback?.total?.totalAmountInUSD} count={financial?.withdraw?.rollback?.total?.count} />
        <StyledGrid xs={20} md={2} title='Withdraw Pending' amount={financial.withdraw.pending?.total.totalAmountInUSD} count={financial.withdraw.pending?.total?.count} />
        {/* <StyledGrid 20={18} md={2} title='Current Balance' amount={currentBalance} /> */}
        {/* <StyledGrid xs={20} md={2} title='Topup Topup' amount={financial.topup.topup.total.totalAmountInUSD} count={financial.topup.topup.total.count} /> */}
        <StyledGrid xs={20} md={2} title='Topup Daily Cashback' amount={financial.topup.dailyDiscount.total.totalAmountInUSD} count={financial.topup.dailyDiscount.total.count} />
        <StyledGrid xs={20} md={2} title='Topup Weekly Cashback' amount={financial.topup.weeklyDiscount.total.totalAmountInUSD} count={financial.topup.weeklyDiscount.total.count} />
        <StyledGrid xs={20} md={2} title='Topup Deposit' amount={financial.topup.deposit.total.totalAmountInUSD} count={financial.topup.deposit.total.count} />
        <StyledGrid xs={20} md={2} title='Topup Withdraw' amount={financial.topup.withdraw.total.totalAmountInUSD} count={financial.topup.withdraw.total.count} />
        <StyledGrid xs={20} md={2} title='Topup Bonus' amount={financial.topup.bonus.total.totalAmountInUSD} count={financial.topup.bonus.total.count} />
    </Fragment>
}

const PlayReports = (props) => {
    const { play, financial } = props;
    const totalBetAmount = play.total.total.bet.betAmountInUSD;
    const totalWinAmount = play.total.total.bet.winAmountInUSD;
    const totalOpenAmount = play.total.total.open.betAmountInUSD;
    const totalCashbackAmount = play.total.total.cashback.betAmountInUSD;
    const B_W_O = totalBetAmount - totalWinAmount - totalOpenAmount;

    // const aff = (0.75 * B_W_O) - financial.topup.weeklyDiscount.total.totalAmountInUSD - financial.topup.dailyDiscount.total.totalAmountInUSD;
    return <>
        <Grid container columns={12} sx={{
            display: ['none', 'flex'],
            '& .MuiTypography-subtitle1': {
                fontWeight: 'bold'
            },
            '& .gridLabel': {
                fontSize: '0.7rem'
            }
        }}>
            <Grid item xs={2} className='gridLabel fw-bold justify-content-center align-items-center d-flex'>Providers</Grid>
            <Grid item xs={12} md={2} className='gridLabel p-1 fw-bold text-danger'>Bet</Grid>
            <Grid item xs={12} md={2} className='gridLabel p-1 fw-bold text-success'>Win</Grid>
            <Grid item xs={12} md={2} className='gridLabel p-1 fw-bold text-yellow'>Open</Grid>
            <Grid item xs={12} md={2} className='gridLabel p-1 fw-bold text-warning'>Cashback</Grid>
            <Grid item xs={12} md={2} className='gridLabel p-1 fw-bold text-neon'>Bet - Win - Open</Grid>
        </Grid>

        {/* Totals */}
        <Grid container columns={12} sx={{
            '& .MuiTypography-subtitle1': {
                fontWeight: 'bold'
            }
        }}>
            <Grid item xs={12} md={2} className='justify-content-start align-items-center d-flex small px-1'><strong>Total</strong></Grid>
            <StyledGrid xs={12} md={2} title='Bet' amount={totalBetAmount} count={play.total.total.bet.count} />
            <StyledGrid xs={12} md={2} title='Win' amount={totalWinAmount} count={play.total.total.win.count} />
            <StyledGrid xs={12} md={2} title='Open' amount={totalOpenAmount} count={play.total.total.open.count} />
            <StyledGrid xs={12} md={2} title='Cashback' amount={totalCashbackAmount} count={play.total.total.cashback.count} />
            <StyledGrid xs={12} md={2} title='Bet - Win - Open' className={B_W_O > 0 ? '- text-success' : B_W_O == 0 ? '- text-light' : '- text-danger'} amount={B_W_O} countType='text' count={B_W_O > 0 ? '✅' : B_W_O == 0 ? '' : '❌'} />
            {/* <StyledGrid xs={12} md={12} title='Aff' amount={aff} count={0} /> */}

            {/* <StyledGrid xs={2} title='Bet_Win' className={`text-${Bet_Win == 0 ? 'light' : Bet_Win > 0 ? 'success' : 'danger'}`} amount={Bet_Win} /> */}
            {/* Per Provider */}
        </Grid>
        <Grid container columns={12} sx={{
            '& .MuiTypography-subtitle1': {
                fontSize: '0.6rem',
                opacity: 0.9
            },
        }}>
            {gameProviders.map((provider, i) => <PlayReport key={i} play={play[provider]} provider={provider} />)}
        </Grid>
    </>
}

const PlayReport = (props) => {
    const { play, provider } = props;
    const totalBetAmount = play.total.bet.betAmountInUSD;
    const totalWinAmount = play.total.bet.winAmountInUSD;
    const totalOpenAmount = play.total.open.betAmountInUSD;
    const totalCashbackAmount = play.total.cashback.betAmountInUSD;
    const B_W_O = totalBetAmount - totalWinAmount - totalOpenAmount;
    return <Fragment>
        <Grid xs={12} md={2} className='justify-content-start align-items-center d-flex small py-2 py-md-0 px-1' item ><span><RenderService service={provider} full /></span></Grid>
        <StyledGrid xs={12} md={2} title='Bet' amount={totalBetAmount} count={play.total.bet.count} />
        <StyledGrid xs={12} md={2} title='Win' amount={totalWinAmount} count={play.total.win.count} />
        <StyledGrid xs={12} md={2} title='Open' amount={totalOpenAmount} count={play.total.open.count} />
        <StyledGrid xs={12} md={2} title='Cashback' amount={totalCashbackAmount} count={play.total.cashback.count} />
        <StyledGrid xs={12} md={2} title='Bet - Win - Open' className={B_W_O > 0 ? '- text-success' : B_W_O == 0 ? '- text-light' : '- text-danger'} amount={B_W_O} countType='text' count={B_W_O > 0 ? '✅' : B_W_O == 0 ? '' : '❌'} />
    </Fragment >
}

// const StyledGrid = (props) => {
//     const { title, amount, sx, className, count, description, amount2, xs } = props;
//     const [open, setOpen] = useState(false)
//     return <Grid className={`text-end text-${(count !== 0) ? getColorByTitle(title) : ' text-light'} ${className}`} sx={{ padding: '3px', ...sx }} item xs={xs || 1}>
//         <ClickAwayListener onClickAway={() => setOpen(false)}>
//             <Tooltip
//                 open={open}
//                 TransitionComponent={Zoom}
//                 PopperProps={{ sx: { zIndex: 1 } }}
//                 classes={{ tooltip: 'bg-black box-shadow-neon p-0 m-0 w-100' }}
//                 placement="bottom" title={
//                     <Box className='cursor-pointer py-1 d-flex flex-column align-items-start'>
//                         Des
//                     </Box>
//                 }>
//                 <Box className='d-flex justify-content-between text-center px-0 m-0' onClick={() => { if (description) setOpen(true) }}>
//                     <Typography className='d-md-none'>
//                         {title}
//                     </Typography>
//                     <Typography variant='subtitle1' className='d-flex justify-content-between align-items-center w-100'>
//                         {count !== undefined ? <small className='ps-1 opacity-80'>({numeral(count).format('0,0')})</small> : ''}
//                         {amount2 !== undefined ? <small className='ps-1 opacity-80'>{amount2}</small> : ''}
//                     </Typography>
//                 </Box>
//             </Tooltip>
//         </ClickAwayListener>

//     </Grid>
// }

const StyledGrid = (props) => {
    const { title, amount, value, sx, className, type, xs, count, countType, md } = props;
    return <Grid item xs={xs || 12} md={md || 12} className={`px-1 d-flex justify-content-between align-items-center text-end text-${(count !== 0) ? getColorByTitle(title) : ' text-light'}${className ? className : ''}`} sx={{ padding: '3px', ...sx }}>
        <Box sx={{
            display: ['block', 'none'],
            fontSize: '0.7rem',
            textAlign: 'left',
            flex: 2
        }}>
            {title}
        </Box>
        <Box className='d-flex justify-content-between align-items-center' sx={{ flex: 2 }}>
            <Typography variant='subtitle1' component='span' >
                {type == 'text' ? amount :
                    <span className=''>{numeral(amount).format('$0,0.00')}</span>
                }
            </Typography>
            <Typography variant='subtitle1' className='d-flex justify-content-between align-items-center'>
                {count && (countType !== 'text') && <small className='ps-1'>({numeral(count).format('0,0')})</small>}
                {count && (countType == 'text') && <small className='ps-1'>{count}</small>}
                {/* {type == 'time' && <small className='ps-1'>{moment.utc(value).format('YYYY/MM/DD HH:mm:ss z')}</small>}
            {type == 'time' && <small className='ps-1 text-neon'>{moment(value).format('YYYY/MM/DD HH:mm:ss')}</small>}
        {type == 'text' && <small className='ps-1'>{value}</small>} */}
            </Typography>
        </Box>
    </Grid>
}

export default PlayerReportTable