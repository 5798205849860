import React, { useContext } from 'react'
import { Box, Breadcrumbs, Typography } from '@mui/material';
import { MDBIcon } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
import { Context } from '../../context/store';

const ThemeBreadCrumb = (props) => {
    let { data, active } = props;
    const [state, dispatch] = useContext(Context);

    active = active == undefined ? 'Active' : active;
    data = data || [
        {
            href: '/',
            text: 'Home'
        },
        {
            href: '/',
            text: 'Level1'
        },
        {
            href: '/',
            text: 'Level2'
        },
    ]
    const color = state?.serverEnv == 'prod' ? 'neon' : state?.serverEnv == 'stage' ? 'warning' : 'danger';

    return <Box sx={{
        width: ['100vw'],
    }} className='d-flex- align-items-center justify-content-center'>
        <Breadcrumbs
            separator={<MDBIcon style={{ fontSize: '0.7rem' }} className='' icon='angle-right'></MDBIcon>}
            className={`py-1 bg-black d-flex align-items-center box-shadow-${color}`}
            sx={{
                paddingLeft: ['0.5rem'],
                width: '100vw',
                fontSize: '0.7rem !important',
                zIndex: 5
            }}>
            {
                data.map((item, i) => {
                    if (i < (data.length - 1)) return <span key={i}>
                        <Link className='text-neon' to={item.href}>{item.text}</Link>
                    </span>
                    // return <span key={i}>
                    //     <Link className='text-neon' to={item.href}>{item.text}</Link>
                    // </span>
                })
            }

            <Typography sx={{ fontSize: '0.7rem !important', color: '#fff' }}>{data[data.length - 1].text}</Typography>
        </Breadcrumbs>
    </Box>
}

export default ThemeBreadCrumb