// import React from 'react';
// import { getBearer } from '../helpers/publicHelper';
// // client-side
// const io = require("socket.io-client");

// // let url = process.env.REACT_APP_CHAT_HOST;

// // export const apiHost = url + '/';
// export const socket = io.connect(process.env.REACT_APP_CHAT_HOST, {
//     transports: ["websocket"],
//     query: {
//         "bearer": getBearer()
//     }
// });

// // export const chatSocket = io(process.env.REACT_APP_CHAT_HOST, { transports: ["websocket"] });
// export const SocketContext = React.createContext();


import React from 'react';
// client-side
const io = require("socket.io-client");

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

const getBearer = () => {
    let token = getCookie("bearer");
    token = token ? token : '';
    return token
}

export const socket = io.connect(process.env.REACT_APP_CHAT_HOST, {
    transports: ["websocket"],
    query: {
        "bearer": getBearer()
    }
});

// export const chatSocket = io(process.env.REACT_APP_CHAT_HOST, { transports: ["websocket"] });
export const SocketContext = React.createContext();
